import ClassAbsenceService from 'services/ClassAbsence';

(function ($, window, undefined) {"use strict";

    var DynamicClassAttendance = {
        rel: 'dynamic-class-attendance',

        loadDynamicData: function(elems) {
            var self = this;
            var paramArr = self.getRequestParams(elems);

            self.bulkLoadData(elems, paramArr);
        },

        /**
         * @param  jQuery elems that have our dynamic data rel
         * @return an array of requests to pass to our bulk API
         */
        getRequestParams: function(elems) {
            var self = this;
            var allParams = {};

            // make one request per start_date/end_date/course_ids combo
            elems.each(function() {
                var elem = $(this);
                var params = self.getRequestParamsForElem(elem);
                var key = params.key;
                
                elem.html('<i class="icon-spinner icon-spin"></i>'); // loading...

                // if we've already seen this key, just add the new students to the request
                if (allParams[key]) {
                    allParams[key]['student_ids'] = allParams[key]['student_ids'].concat(params['student_ids']);
                } else {
                    allParams[key] = params;
                }
            });

            // uniqify student_id lists just in case
            var paramArr = [];
            _.each(allParams, function(params) {
                params['student_ids'] = _.uniq(params['student_ids']).join(',');
                paramArr.push(params);
            });

            return paramArr;
        },

        /**
         * Create a key that represents what our API can take. In this case,
         * it's a date range (start date / end date) and optionally courseIds.
         * All cells that have that same key (e.g. for different studentIds) can
         * be loaded in one call to the API.
         * 
         * @param  jQuery elem
         * @return Object
         */
        getRequestParamsForElem: function(elem) {
            var data = $(elem).data();
            var startDate = data['start_date'];
            var endDate = data['end_date'];
            var rawStudentIds = data['student_ids'];
            var courseIds = data['course_ids'];
            var studentIds = rawStudentIds ? (rawStudentIds + '').split(',') : [];
            var key = startDate + '_' + endDate + '_' + courseIds;

            return {
                key,
                start_date: startDate,
                end_date: endDate,
                student_ids: studentIds,
                course_ids: courseIds,
            };
        },

        bulkLoadData: function(elems, paramArr) {
            var self = this;

            ClassAbsenceService.getBulkAttendancePct(paramArr)
                .then(_.partial(_.bind(self.handleBulkData, self), elems, paramArr));
        },

        handleBulkData: function(elems, paramArr, responses) {
            var self = this;
            var responsesByKey = self.getResponsesByKey(responses, paramArr);
            var tables = $();

            elems.each(function() {
                var elem = $(this);

                self.displayElemResult(elem, responsesByKey);
                tables = tables.add(elem.closest('table'));
            });

            $.unique(tables)
                .each(tablesorter)
                .each(simpleCalculateTotals)
                .each(self.hideColumnIfNoData)
                .trigger('update');
        },

        hideColumnIfNoData: function() {
            var table = $(this);
            var columns = $(table.find('thead [rel="hide-if-no-data"]').get().reverse());

            columns.each(function() {
                var headerCell = $(this);

                if (!headerCell.data('count')) {
                    // the subtotaler has a count of zero so no data in this column.
                    // hide it.
                    var nthChild = getCellIndex(headerCell);

                    table.find('tr').each(function() {
                        var row = $(this);
                        
                        if (!row.find('th[colspan], td[colspan]').length) {
                            // simple case, hide all the cells in column n
                            row.find(`td:not([colspan]):nth-child(${nthChild}), th:not([colspan]):nth-child(${nthChild})`).hide();
                        } else {
                            // complicated case, some cells have colspans so we
                            // have to get fancy and sum up colspans to find which
                            // cell is covering the one we're trying to hide.
                            // once we find it, either decrement the colspan
                            // or just hide the cell is colspan is already 
                            var columnCount = 0;

                            row.children().each(function() {
                                var cell = $(this);
                                var colspan = parseInt(cell.attr('colspan'), 10);
                                var numColumns = colspan || 1;

                                columnCount += numColumns;

                                if (columnCount >= nthChild) {
                                    if (numColumns > 1) {
                                        cell.attr('colspan', numColumns - 1);
                                    } else {
                                        cell.hide();
                                    }

                                    return false; // break
                                }
                            })
                        }
                    });
                }
            });
        },

        displayElemResult: function(elem, responsesByKey) {
            var self = this;
            var td = elem.closest('td, .cell-to-format');
            var data = elem.data();
            var params = self.getRequestParamsForElem(elem);
            var response = responsesByKey[params.key];
            var attendanceDataByStudent = _.get(response, ['results', 'pct']);
            var studentIds = params['student_ids'];
            var computedResult = self.computeAttendancePct(attendanceDataByStudent, studentIds);
            var pctFormatted = computedResult.pctFormatted;
            var tooltip = computedResult.hasData
                ? computedResult.numClassesPresent + "/" + computedResult.numClasses + " (" + computedResult.numClassesAbsent + " class absences this period)"
                : '';
            var startDate = data['start_date'];
            var endDate = data['end_date'];
            var sectionPeriodId = data['section_period_ids'];
            var courseId = data['course_ids'];
            var sectionPeriodFilter = sectionPeriodId ? `&section_period_id=${sectionPeriodId}` : '';
            var courseFilter = courseId ? `&course_id=${courseId}` : '';
            var tooltipUrl = `/class-absences/history/?history_only=1&summary_only=1&in_school=0&student_id=${studentIds}&min_date=${startDate}&max_date=${endDate}&printable=0${sectionPeriodFilter}${courseFilter}`;
            var tooltipAttrs = `tooltip='${tooltipUrl}' data-tooltiponce=1`;
            var analyticsAttrs = 'data-vertical="Class Attendance" data-module="Dynamic Pct" data-feature="Drilldown"';
            var hoverLink = `<a ${tooltipAttrs} ${analyticsAttrs} href='/class-absences'>${pctFormatted}</a>`;

            elem.html(computedResult.hasData ? hoverLink : pctFormatted)
                .attr('title', tooltip);

            // update subtotaling info and conditional formatting
            td.addClass('average pct aoutofb')
                .attr('name', 'attendance_pct')
                .data('total', computedResult.numClassesPresent)
                .data('count', computedResult.numClasses);
            td.metadata().rag = response.results.settings.attendance_pct;
            addConditionalFormatting(td, myParseFloat(pctFormatted), td.metadata().rag);
        },

        /**
         * Sum up num class and absences for each student to calculate an
         * attendance percentage for the group on the front end.
         * 
         * @param  Object attendanceDataByStudent student_id => {total_absences, total_classes}
         * @param  array studentIds
         * @return Object with computed values
         */
        computeAttendancePct: function(attendanceDataByStudent, studentIds) {
            var numClasses = 0;
            var numClassesAbsent = 0;

            _.each(studentIds, function(studentId) {
                var totalAbsences = _.get(attendanceDataByStudent, [studentId, 'total_absences']) || 0;
                var totalClasses = _.get(attendanceDataByStudent, [studentId, 'total_classes']) || 0;

                numClasses += parseInt(totalClasses);
                numClassesAbsent += parseInt(totalAbsences);
            });

            var numClassesPresent = numClasses - numClassesAbsent;
            var hasData = Boolean(numClasses);
            var pct = hasData ? numClassesPresent / numClasses : undefined;
            var pctFormatted = hasData ? Math.round(pct * 100, 0) + '%' : 'n/a';

            return {
                numClasses,
                numClassesAbsent,
                numClassesPresent,
                hasData,
                pct,
                pctFormatted,
            }
        },

        /**
         * Transform responses into a map so we can do an easy lookup from each
         * cell to where it's data will have been returned by the server.
         * 
         * @param  array responses
         * @param  array paramArr
         * @return Object
         */
        getResponsesByKey: function(responses, paramArr) {
            var responsesByKey = {};

            for (var i = 0; i < paramArr.length; i++) {
                var params = paramArr[i];

                responsesByKey[params.key] = responses[i];
            }

            return responsesByKey;
        },
    };

    window.Tss = window.Tss || {};
    window.Tss.DynamicClassAttendance = DynamicClassAttendance;

})(jQuery, window);

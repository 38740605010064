;(function ($, window, document, undefined) {

    function editGradingMethodologies(options) {

        var $submitButton,
            $addButton,
            $bucketRow,
            $weightInputs,
            $totalWeight,
            $form,
            $selectDropdown,
            $selectedOptions,
            currentLabel,
            $subHeader,
            total,
            cloneRow,
            // Default options:
            defaults = {
                courseId: null,
                redirectUrl: null,
                termBinId: null,
                termId: null,
                schoolId: null,
                sbg: null,
                selectors: {
                    submitButton: 'div[name="submit-grading-methodologies"]',
                    addButton: 'div[name="add-grading-methodology"]',
                    bucketRow: '.bucket-row',
                    weightInputs: '.weight',
                    totalWeight: '.total-weight',
                    selectDropdown: '.bucket-row select',
                    methodologyForm: '.grading-methodology-form',
                    subHeader: '.edit-methodologies h5',
                }
            };

        /**
         * Adds alert
         */
        function addAlert(message, type) {
            $subHeader.prepend(getStatusBox(null, message, type));
        };

        /**
         * Removes all alerts
         */
        function removeAlerts() {
            $subHeader.find('.alert-error').remove();
            $subHeader.find('.alert-success').remove();
            $subHeader.find('.alert-info').remove();
        }

        /**
         * Add new row to html form for a Grading Methodology
         */
        function addMethodology(evt) {
            evt.preventDefault();
            $bucketRow = $(options.selectors.bucketRow);
            var id = $bucketRow.length;

            if (id == 0) {
                $subHeader.after(cloneRow);
                $bucketRow = $(options.selectors.bucketRow);
            } else {
                $bucketRow.last().after(cloneRow);
            }

            // grab new row and set name attribute
            var $newRow = $(options.selectors.bucketRow).last();
            var inputAttrs = $newRow.find('input');
            inputAttrs.each(function() {
                var attr = $(this).attr('name');
                $(this).attr('name', attr + '[post' + id + ']');
            });
            var selectAttr = $newRow.find('select').attr('name');
            $newRow.find('select').attr('name', selectAttr + '[post' + id + ']');
            $bucketRow.each(setupUI);

            //create event listeners for new elements
            $weightInputs = $('.weight');
            $weightInputs.on('keyup', updateTotalWeight);
            $(options.selectors.selectDropdown).last().nextAll().find('.icons').hide();
            $(options.selectors.selectDropdown).on('change', disableOptGroups);
        };

        /**
        * AJAX Request
        */
        function ajax(ajaxData) {
            $.ajax(ajaxData)
            .done(function(data) {
                //alerts
                removeAlerts();
                addAlert(data.info[0], 'success');

                $submitButton.data('processing', 1);
                setTimeout(function() {
                    //recalculate grades
                    recalculateGrades(options.termBinId, options.courseId, $submitButton);
                }, 1000);
            })
            .fail(function(jqXHR, textStatus, errorThrown) {
                //alerts
                var alert = _.get(jqXHR.responseJSON, 'errors[0]')
                    || 'Error saving grading methodologies';
                removeAlerts();
                addAlert(alert, 'error');
            });
        };

        /**
         * Disable termbins opt group if selected optgroup == assessmentTypes
         * and vice versa.  Enable all opt groups if nothing is selected
         */
        function disableOptGroups(elem) {
            $selectedOptions = $(this).find(':selected').length;
            if ($selectedOptions == 0) {
                $(this).closest('select').html("");
                $(this).closest('select').append(options.optionsAssessments);
                $(this).closest('select').append(options.optionsTermBins);
            } else {
                currentLabel = $(this).closest('select').find(':selected').closest('optgroup').attr('label');
                $(this).closest('select').find('optgroup:not([label="' + currentLabel + '"])').remove();
            }
            $(this).nextAll().remove();
            $(this).tssMultiSelectSearch({displayOptionText: true});
            $(this).next().trigger('click');
            if ($selectedOptions == 0) {
                $(this).nextAll().find('.icons').hide();
            }
            checkStandards();
        };

        /**
         * check selected options for different content types if course uses
         * semester based grading
         */
        function checkStandards() {
            if (!options.sbg) { return; }

            var selected,
                assessments = 0,
                termBins = 0;

            selected = $('.bucket-row select').find(':selected');

            selected.each(function() {
                var type = $(this).val().split(/\[(.*?)\]/)[0];
                if (type == "assessment_type_id") { assessments++ };
                if (type == "term_bin_id") { termBins++ };
           });

           $subHeader.find('.alert-warning').remove();

           if ((assessments !== 0) && (termBins !== 0)) {
               addAlert('You are using Standards-Based Grading with both assessment types and term bins in your methodology. We will average your assessment scores rather than standard scores with this configuration!', 'warning');
           }
        }

        /**
        * Set up all events and handlers for this view.
        */
        function initBindings() {
            $submitButton.on('click', $.proxy(saveMethodologies));
            $addButton.on('click', $.proxy(addMethodology));
            $weightInputs.on('keyup', $.proxy(updateTotalWeight));
            $selectDropdown.each(disableOptGroups);
            $selectDropdown.last().next().trigger('click');
            $selectDropdown.on('change', disableOptGroups);
            $form.on('click', '.icon-remove[name="cancel"]', removeMethodology);
            updateTotalWeight();

            //garbage clone work
            cloneRow = '<section class="bucket-row post"><div class="row gutters"><div class="col span-3"><label class="label">Weight (%)</label><input type="number" step=".01" class="weight" name="weight" value="" autofocus></div><div class="col span-9"><label class="label">Grading Methodology Name</label><input type="text" name="name" value=""></div><div class="col span-12"><label class="label">Contents</label><select class="nohyjack" rel="make-tss-multiselect-search" name="grading-methodology-bucket-contents" multiple>' + options.optionsAssessments + options.optionsTermBins + '</select></div><i class="icon-remove" name="cancel"></i></div></section>';
        };

        /**
        * Set variables as jQuery elements from options.selectors
        */
        function locateElements() {
            $submitButton = $(options.selectors.submitButton);
            $addButton = $(options.selectors.addButton);
            $bucketRow = $(options.selectors.bucketRow);
            $weightInputs = $(options.selectors.weightInputs);
            $totalWeight = $(options.selectors.totalWeight);
            $selectDropdown = $(options.selectors.selectDropdown);
            $form = $(options.selectors.methodologyForm);
            $subHeader = $(options.selectors.subHeader);
        };

        /**
         * Fire off grade recalculate for the params provided.
         * If provided an element, replaces html content with spinner
         * and reprocessing copy, and manages 'processing' semaphore.
         * @param  array termBinIds
         * @param  array courseIds
         * @param  Element $elem (optional) Typically a button.
         */
        function recalculateGrades(termBinId, courseId, $elem) {
            //alerts
            removeAlerts();
            addAlert('Recalculating grades... please wait', 'info');

            $.ajax({
                type: 'POST',
                url: '/api/v1/course-grades/calculate',
                data: {
                    course_ids: courseId,
                    school_id: options.schoolId,
                    term_bin_ids: termBinId
                }
            }).done(function(data) {
                //alerts
                removeAlerts();
                addAlert('Grade recalculation complete', 'success');

                if ($elem) {
                    window.setTimeout(function() {
                        $elem.data('processing', 0);
                        window.location.href = options.redirectUrl;
                    }, 1000);
                }
            }).fail(function(err) {
                //alerts
                removeAlerts();
                addAlert('Grade recalculation failed', 'error');
            });

        };

        /**
         * Remove methodology row from form
         */
        function removeMethodology() {
            var row, msg;
            row = $(this).parents().eq(1);
            msg = 'Are you sure you want to deactivate this methodology bucket?';
            showConfirmDialog('icon-remove', msg, function() {
                row.remove();
                // recalc weight total
                $weightInputs = $(options.selectors.weightInputs);
                updateTotalWeight();
                checkStandards();
            });
        }

        /**
        * Submit data to API.
        */
        function saveMethodologies(evt){
            removeAlerts();

            if (total !== 100) {
                addAlert('Total weight does not equal 100%.', 'error');
                return;
            }

            var postData = {};
            var data = $form.serializeArray();
            for (var input in data) {
                var bucket = data[input].name.split(/\[(.*?)\]/);
                if (bucket == "") { continue;}
                var name = bucket[0];
                var id = bucket[1];
                postData[id] = postData[id] || {};
                postData[id]['contents'] = postData[id]['contents'] || {};
                if (name == 'grading-methodology-bucket-contents') {
                    var content = data[input].value.split(/\[(.*?)\]/);
                    var type = content[0];
                    var typeId = content[1];
                    postData[id]['contents'][type] = postData[id]['contents'][type] || [];
                    postData[id]['contents'][type].push(typeId);

                //break if weight is left null
                } else if (name == 'weight' && data[input].value == "") {
                    addAlert('Weight cannot be empty', 'error');
                    return;

                // insert into postData
                } else {
                    postData[id][name] = data[input].value;
                }
            }

            //break if no bucket contents
            for (var id in postData) {
                if (_.isEmpty(postData[id]['contents'])) {
                    addAlert('Contents cannot be empty', 'error');
                    return;
                }
            }

            var ajaxData = {
                type : 'POST',
                url : '/api/v1/grading-methodologies/manage',
                data : {
                    form_data : postData,
                    course_id : options.courseId,
                    term_bin_id : options.termBinId,
                    term_id : options.termId
                },
                dataType : 'json'
            };
            $submitButton.data('processing', 1);
            ajax(ajaxData);

        };

        /**
         * Update total weight based on user input
         */
        function updateTotalWeight(evt) {
            total = 0;
            $weightInputs.each(function (index, elem) {
                total += Math.round($(elem).val()*100);
            });
            total /= 100;
            var borderColor = total == 100 ? '#d9d9d9' : '#E15A49';

            if (total == 100) {
                removeAlerts();
            }

            $totalWeight.css('border-color', borderColor);
            $totalWeight.val(total);
        }

        options = $.extend({}, defaults, options);

        if (!options.courseId || !options.termBinId || !options.termId) {
            $.error('Config issue: courseId, termId, and termBinId must be provided in options');
            return;
        }

        locateElements();
        initBindings();
    };

    window.Tss = window.Tss || {};
    window.Tss.editGradingMethodologies = editGradingMethodologies;
})(jQuery, window, document);

(function ($, window, undefined) {"use strict";

	var GradesRecalculate = window.GradesRecalculate = {

        config: {
            api: {
                preview: '/api/v1/grade/preview/recalculate',
                recalculate: '/grades/store/'
            }
        },
        
        /**
         * Init
         */
        init: function() {
            var self = GradesRecalculate;
            self.$container = $('#grades-recalculate');
            if(self.$container.length) {
                self.setBindings();
            }
        },
               
        /**
         * Initializes preview request
         * @param {array} termBinShortNames
         * @param {array} courseIds
         */
        getPreview: function(termBinShortNames, courseIds) {
            var self = GradesRecalculate;
            var data = {
                term_bins: termBinShortNames.join(),
                course_ids: courseIds.join()
            };
            
            //remove alerts
            this.removeAlerts();

            $.getJSON(this.config.api.preview, data)
                .done(this.handlePreviewDone)
                .fail(function(jqXHR, textStatus, errorThrown) {
                    //alerts
                    var alert = _.get(jqXHR.responseJSON, 'errors[0]')
                        || 'Error recalculating grades';
                    self.removeAlerts();
                    self.addAlert(alert, 'error');
                });
        },
        
        /**
         * Handles change on form container to initialize preview
         */
        handleChange: function() {
            var self = GradesRecalculate,
                termBinShortNames = self.$termBin.val(),
                courseIds = self.$course.val();

            var lockedTermPresent = $(this).find(':selected').data('locked');

            var lockedTermShortNames = [];
            $(this).find(':selected').map(function() {
                if($(this).data("locked")) {
                    lockedTermShortNames.push($(this).val());
                }
            });

            self.removeAlerts()

            if (lockedTermPresent){
                var formattedWarningMessage = lockedTermShortNames + ((lockedTermShortNames.length > 0) ? ' is ' : ' are ') + " locked. Recalculating will not affect course grades. Unlock " + ((lockedTermShortNames.length > 0) ? ' this term ' : ' these terms ') + " or contact your Schoolrunner administrator in order to recalculate.";
                self.addAlert( formattedWarningMessage, 'warning') ;
                $("#recalculate-button").attr("disabled",true);
                $('[rel="btn-recalculate"]').removeClass('btn-green').addClass('btn-gray');
            } else {
                $("#recalculate-button").removeAttr("disabled");
                $('[rel="btn-recalculate"]').removeClass('btn-gray').addClass('btn-green');
            }
            
            if(termBinShortNames && courseIds && !lockedTermPresent) {
                self.getPreview(termBinShortNames, courseIds);
            } 
        },
        
        /**
         * Handles preview request done event
         * @param {object} data
         */        
        handlePreviewDone: function(data) {
            var self = GradesRecalculate;
            
            //add alert
            self.addAlert(data.results.num_affected_message, 'warning');
        },
        
        /**
         * Handles recalculate button click
         */
        handleRecalculateBtnClick: function() {
            var self = GradesRecalculate,
                termBinShortNames = self.$termBin.val(),
                courseIds = self.$course.val();

            var lockedTermPresent = $('[name="term_bin"').find(':selected').data('locked');            

            if(termBinShortNames && courseIds && !lockedTermPresent) {
                self.recalculate(termBinShortNames, courseIds);
            }
        },
        
        /**
         * Initializes recalculation request
         * @param {array} termBinShortNames
         * @param {array} courseIds
         */
        recalculate: function(termBinShortNames, courseIds) {
            var self = GradesRecalculate;
            var data = {
                term_bin_short_names: termBinShortNames.join(),
                course_ids: courseIds.join()
            };
    
            //remove alerts and add processing alert
            this.removeAlerts();
            this.addAlert('Recalculating grades... please wait', 'info');
            
            $.post(this.config.api.recalculate, data, $.noop, 'json')
                .done(this.handleRecalculateDone)
                .fail(function(jqXHR, textStatus, errorThrown) {
                    //alerts
                    var alert = _.get(jqXHR.responseJSON, 'errors[0]')
                        || 'Error recalculating grades';
                    self.removeAlerts();
                    self.addAlert(alert, 'error');
                });
        },
                
        /**
         * Handles recalculation request done event
         */
        handleRecalculateDone: function() {
            var self = GradesRecalculate;
            
            //alerts
            self.removeAlerts();
            self.addAlert('Grade recalculation complete', 'success');
            
            //reset values
            self.$termBin.val([]).change();
            self.$course.val([]).change();
        },
            
        /**
         * Adds alert
         * @param {string} message
         * @param {string} type
         */
        addAlert: function(message, type) {
            this.$container.before(getStatusBox(null, message, type));
        },

        /**
         * Removes all alerts
         */
        removeAlerts: function() {
            this.$container.parent().find('.alert').remove();
        },
        
        /**
         * Sets bindings
         * @returns {undefined}
         */
        setBindings: function() {
            this.$termBin = this.$container.find('[name="term_bin"]');
            this.$course = this.$container.find('[name="course_id"]');
            this.$formElements = this.$container.find('.row').first();
            this.$recalculateButton = $('[rel="btn-recalculate"]');

            this.$container.on('change', _.debounce(this.handleChange, 1000));
            this.$recalculateButton.on('click', _.debounce(this.handleRecalculateBtnClick, 1000, {leading: true, trailing: false}));
        }
        
    };
    
    //init
    $(GradesRecalculate.init);
    
})(jQuery, window);
(function ($, window, undefined) {"use strict";
    
    var defaultOptions = {
        duration: 5000
    };

    /**
     * Merges options and creates growl alert
     * @param object options
     * @param string type
     */
    var createGrowl = function(options, type) {
        var mergedOptions = $.extend({}, defaultOptions, options);

        // display forever until the user X-es it out manually
        if (!mergedOptions.duration) {
            mergedOptions.fixed = true;
        }

        var message = _.get(mergedOptions, 'message');
        if (message && isMessageAlreadyShowing(message)) {
            console.log(`not showing message because it's already showing: ${message}`);
            return;
        }

        if (type) {
            $.growl[type](mergedOptions);
        } else {
            $.growl(mergedOptions);
        }
    };

    var isMessageAlreadyShowing = function(message) {
        var visibleMessages = $('#growls .growl').map(function() {
            return $(this).text().trim();
        }).get();

        return visibleMessages.contains((message + '').trim());
    }

    var Growl = {
        
        /**
         * Creates info growl alert
         * @param object options
         */
        info: function(options) {
            createGrowl(options);
        },
        
        /**
         * Creates success growl alert
         * @param object options
         */
        success: function(options) {
            createGrowl(options, 'notice');
        },
        
        /**
         * Creates warning growl alert
         * @param object options
         */
        warning: function(options) {
            createGrowl(options, 'warning');
        },
        
        /**
         * Creates error growl alert
         * @param object options
         */
        error: function(options) {
            createGrowl(options, 'error');
        },

        clearAll: function() {
            $('#growls').empty();
        },
    };
    
    window.Growl = Growl;
    
})(jQuery, window);
(function($, window) { "use strict";
    var SectionPeriods = {
        courseDefinitionApi: '/api/v1/course-definitions',
        sectionApi: '/api/v1/sections',
        periodApi: '/api/v1/periods',
        sectionPeriodApi: '/api/v1/section-periods',
        sectionStudentApi: '/api/v1/section-students',
        bulkSectionStudentApi: '/api/v1/bulk/section-students',
        bulkSectionMappingApi: '/api/v1/bulk/section-mappings',
        newStudentsAddedCount: 1,

        initAddEdit: function(action, id, opts) {
            var self = this;

            self.action = action;
            self.$form = $('#main-form');
            self.$table = $('#types-table');

            self.$form.on('FillInForm', self.fillInForm);
            self.$table.on('click', '[rel="toggle-active"]', self.handleToggleActiveClick);
            self.$form.submit(self.saveSectionPeriodAndRelatedRecords);

            self.defaultRowData = {
                default_start_date: dateStringToDisplay(opts.term.start_date),
                default_end_date: dateStringToDisplay(opts.term.end_date),
            };

            Tss.Types.initAddEdit('SectionPeriod', 'section.number', action, id, {
                extraApiParams: {
                    expand: 'section_mappings.course,period,section.section_students.student,staff_member'
                },
            });
            self.$form.off('.save'); // cancel default behavior

            $('body').on('change', '[name="student_id"]', self.addStudent);
            $('body').on('change', '[name="copy_from_section_id"]', self.addStudentsBySection);
            $('body').on('click', '.download-template', self.downloadSingleSectionTemplate);

            $('.dndtarget').each(function() {
                this.addEventListener('dragover', fileDragHover, false);
                this.addEventListener('dragleave', fileDragHover, false);
                this.addEventListener('drop', fileSelectHandler(self.handleDroppedFile), false);
            });
        },

        initList: function(opts) {
            var self = this;
            var dropZoneTemplate = 'drop-zone';
            var dropZoneElem = $(Handlebars.renderTemplate(dropZoneTemplate))
                .attr('template', dropZoneTemplate);
            var mainContent = $('.mainBody section');
            var dndTarget = dropZoneElem.find('.dndtarget')[0];

            // if somebody drags a file before toggling the drop zone visible,
            // just show it for them
            $('body').get(0).addEventListener('dragover', function() {
                dropZoneElem.show();
            });

            dndTarget.addEventListener('dragover', fileDragHover, false);
            dndTarget.addEventListener('dragleave', fileDragHover, false);
            dndTarget.addEventListener('drop', fileSelectHandler(_.bind(self.handleData, self)), false);
            mainContent.first().before(dropZoneElem.hide());

            self.opts = opts;
            Tss.Types.initList("SectionPeriod", "section.number", false, {
                extraApiParams: {
                    term_ids: opts.termId,
                    expand: 'section_mappings.course,period,section.section_students,staff_member'
                },
                templates: {
                    emptyState: dropZoneTemplate,
                },
            });
            Tss.Types.$table.on("click", "[rel=\"toggle-active\"]", this.handleToggleActiveClickList);

            $('body').on('click', '.download-template', self.downloadAllSectionsTemplate);

            $('#toggle-show-empty-state').hide().click(() => dropZoneElem.toggle());
            Tss.Types.$table.on('TableRendered', () => $('#toggle-show-empty-state').show());

            self.asyncTaskManager = new AsyncTaskManager('Uploading Students');
        },

        handleToggleActiveClickList: function () {
            var self = SectionPeriods;
            var sectionId = $(this).data('sectionId');
            var active = $(this).data('active');
            var options = {
                type: "PUT",
                url: `/api/v1/sections/${sectionId}`,
                data: _.extend({
                    active: active == 1 ? 0 : 1
                })
            };

            $.ajax(options)
                .done(_.noop)
                .fail(Tss.Types.handleToggleActiveClickError);
        },

        handleDroppedFile: async function(data, target) {
            var self = SectionPeriods;
            var url = `${self.sectionStudentApi}/parse-section-students`;
            var formData = getFormData(self.$form, true);

            // ignore internal enrollment IDs (section_student_id's) in the file
            // if the section_id of the page we're on doesn't match the
            // section_id of those section_student records. That way if I
            // download from section_id 1 and upload to section_id 2, we'll
            // create new section_student records with section_id 2 rather than
            // moving the original section_student records from section 1 to 2.
            var response = await Api.post(url, {
                school_id: Tss.Env.get('school_id'),
                section_id: formData.section_id,
                import_data: data,
            });

            $('#growls').empty();
            if (response.success) {
                $('tr[data-name-key="new_section_students"]').remove();
                self.fillInSectionStudents(response.results.section_students);
            } else {
                var warningMsg = _.get(response, 'warnings').join('<br/>');
                var errorMsg = _.get(response, 'errors').join('<br/>');

                if (warningMsg) {
                    Growl.warning({ message: warningMsg, fixed: true });
                }

                if (errorMsg) {
                    Growl.error({ message: errorMsg, fixed: true });
                }
            }
        },

        /**
         * Create/update sections, periods, section_periods, course_definitions,
         * section_mappings and section_students.
         *
         * @param  Event e Submit event
         */
        saveSectionPeriodAndRelatedRecords: async function(e) {
            var self = SectionPeriods;

            e.preventDefault(); // so the default form submit doesn't happen and refresh the page

            if (!validateForm(self.$form, e)) {
                Tss.Types.hideSavingIndicator();
                return false;
            }

            const formData = getFormData(self.$form, true);
            const courseName = $('select[name="course_id[]"] option:selected').first().text();

            const courseDefinitionId = await self.getOrCreateCourseDefinition(courseName);
            const sectionId = await self.createOrUpdateSection(formData, courseDefinitionId);
            const periodId = await self.getOrCreatePeriod(formData);
            const { sectionPeriodId, sectionMappings } = await self.createOrUpdateSectionPeriod(formData, sectionId, periodId);
            await self.createOrUpdateSectionMappings(formData, sectionPeriodId, sectionMappings);
            await self.updateSectionStudents(formData, sectionId);

            Tss.Types.redirectToReferrer();
        },

        /**
         * Get or create course_definition_id based on selected course name.
         *
         * @param  string courseName
         * @return int course_definition_id
         */
        getOrCreateCourseDefinition: async function(courseName) {
            var self = this;

            if (!courseName) {
                return null;
            }

            var courseDefinitionData = {
                name: courseName
            };

            var response = await Api.get(self.courseDefinitionApi, courseDefinitionData);
            var courseDefinitionId = await self.findActiveOrActivateInactive(_.get(response, 'results.course_definitions'), self.courseDefinitionApi, 'course_definition_id');

            if (!courseDefinitionId) {
                response = await Api.post(self.courseDefinitionApi, courseDefinitionData);
                courseDefinitionId = _.get(response, 'results.course_definition.course_definition_id');
            }

            return courseDefinitionId;
        },

        /**
         * Create or update section.
         *
         * @param  Object formData
         * @param  int courseDefinitionId
         * @return int section_id
         */
        createOrUpdateSection: async function(formData, courseDefinitionId) {
            var self = this;
            var sectionData = {
                school_id: formData.school_id,
                term_id: formData.term_id,
                number: formData.section.number,
                active: 1,
            };
            var sectionId = formData.section_id;

            // if we're editing an existing section and they un-select all
            // courses then don't update the course definition to null. if we're
            // creating a new section, we won't get here with a null
            // courseDefinitionId because the course_id[] dropdown is required
            // for new sections.
            if (courseDefinitionId) {
                sectionData.course_definition_id = courseDefinitionId;
            }

            if (sectionId) {
                await Api.put(`${self.sectionApi}/${sectionId}`, sectionData);
            } else {
                var response = await Api.post(self.sectionApi, sectionData);

                sectionId = _.get(response, 'results.section.section_id');
            }

            return sectionId;
        },

        /**
         * Get or create period.
         *
         * @param  Object formData
         * @return int period_id
         */
        getOrCreatePeriod: async function(formData) {
            var self = this;
            var response = await Api.get(self.periodApi, {
                term_id: formData.term_id,
                school_ids: formData.school_id,
                number: formData.period.number,
            });
            var periodId = await self.findActiveOrActivateInactive(_.get(response, 'results.periods'), self.periodApi, 'period_id');

            if (!periodId) {
                var periodData = {
                    school_id: formData.school_id,
                    term_id: formData.term_id,
                    number: formData.period.number,
                    short_name: 'P' + formData.period.number,
                    long_name: 'Period ' + formData.period.number,
                    order_key: formData.period.number * 1000,
                };

                response = await Api.post(self.periodApi, periodData);
                periodId = _.get(response, 'results.period.period_id');
            }

            return periodId;
        },

        /**
         * Create or update section period.
         *
         * @param  Object formData
         * @param  int sectionId
         * @param  int periodId
         * @return array [section_period_id, section_mappings]
         */
        createOrUpdateSectionPeriod: async function(formData, sectionId, periodId) {
            var self = this;
            var sectionPeriodData = {
                school_id: formData.school_id,
                term_id: formData.term_id,
                staff_member_id: formData.staff_member_id,
                calendar_day_type_id: formData.calendar_day_type_id,
                section_id: sectionId,
                period_id: periodId,
                active:  formData.active,
            };
            var sectionPeriodId = formData.section_period_id;
            var sectionMappings = [];

            if (sectionPeriodId) {
                var response = await Api.put(`${self.sectionPeriodApi}/${sectionPeriodId}?expand=section_mappings`, sectionPeriodData)

                sectionMappings = _.get(response, 'results.section_period.section_mappings');
            } else {
                var response = await Api.post(self.sectionPeriodApi, sectionPeriodData);

                sectionPeriodId = _.get(response, 'results.section_period.section_period_id');
            }

            return { sectionPeriodId, sectionMappings };
        },

        /**
         * Create or update section mapping. End and start new if changed.
         *
         * @param  Object formData
         * @param  int sectionPeriodId
         * @param  array sectionMappings
         */
        createOrUpdateSectionMappings: async function(formData, sectionPeriodId, sectionMappings) {
            var self = this;
            var oldSectionMappings = _.filter(sectionMappings, m => m.active == 1); // only care about active mappings
            var oldCourseIds = _.map(oldSectionMappings, m => parseInt(m.course_id)); // parseInt so we don't have to worry about string vs int
            var newCourseIds = _.map(formData.course_id, i => parseInt(i));
            var sectionMappingsToDeactivate = _.chain(oldSectionMappings)
                .filter(m => !_.includes(newCourseIds, parseInt(m.course_id))) // get all old course_ids that aren't in newCourseIds
                .map(m => _.extend(m, { active: 0 })) // deactivate old mapping
                .value();
            var sectionMappingsToCreate = _.chain(newCourseIds)
                .filter(newCourseId => !_.includes(oldCourseIds, newCourseId)) // get all new course_ids that aren't in oldCourseIds
                .map(courseId => ({ // add new mapping
                    term_id: formData.term_id,
                    section_period_id: sectionPeriodId,
                    course_id: courseId,
                }))
                .value();
            var newSectionMappings = sectionMappingsToDeactivate
                .concat(sectionMappingsToCreate);

            if (newSectionMappings.length) {
                await Api.post(self.bulkSectionMappingApi, {
                    bulk_data: newSectionMappings,
                });
            }
        },

        /**
         * Bulk update section students.
         *
         * @param  Object formData
         * @param  int sectionId
         */
        updateSectionStudents: async function(formData, sectionId) {
            var self = this;
            var defaultSectionStudentData = {
                school_id: formData.school_id,
                term_id: formData.term_id,
                section_id: sectionId,
            };
            var sectionStudentsToUpdate = _.concat(
                _.map(formData.section_students, x => _.extend(x, defaultSectionStudentData)),
                _.map(formData.new_section_students, x => _.extend(x, defaultSectionStudentData)),
            );

            if (sectionStudentsToUpdate.length) {
                await Api.post(self.bulkSectionStudentApi, {
                    bulk_data: sectionStudentsToUpdate
                });
            }
        },

        handleToggleActiveClick: function() {
            var menuAction = $(this).closest('.menu-action');
            var row = $(this).closest('tr');
            var activeInput = row.find('[name$="\[active\]"]');
            var oldVal = activeInput.val();
            var newVal = oldVal == 1 ? 0 : 1; // toggle

            if (!menuAction.data('id')) {
                return row.remove();
            }

            activeInput.val(newVal);
            row.find('input[type="text"]').attr('disabled', newVal ? null : 'disabled');
            row[newVal ? 'removeClass' : 'addClass']('deactivated');

            // otherwise you see it change right when you click the link and that's a weird UX
            setTimeout(function() {
                menuAction.find('i').toggleClass('icon-remove').toggleClass('icon-ok');
                menuAction.get(0).childNodes[1].nodeValue = newVal ? 'Deactivate' : 'Activate';
            }, 100);
        },

        downloadSingleSectionTemplate: function(e) {
            var self = SectionPeriods;
            var url = `${self.sectionStudentApi}/download-section-students`;
            var data = getFormData(self.$form, true);

            data.section_students = _.map(data.new_section_students, x => _.omit(x, 'section_student_id')).concat(_.values(data.section_students));
            delete data['new_section_students'];

            return genericDownload(url, data)(e);
        },

        downloadAllSectionsTemplate: function(e) {
            var self = SectionPeriods;
            var url = `${self.sectionPeriodApi}/download-section-periods`;
            var extraParams = {
                school_ids: Tss.Env.get('school_id'),
                term_ids: self.opts.termId,
            };

            if (Tss.Types.$activeFilter.val() == 1) {
                extraParams['active'] = 1;
            }

            return genericDownload(url, null, extraParams)(e);
        },

        handleData: async function(data, target) {
            var self = this;
            var idx = (moment().format('YYYYMMDDHHmmssSSS') + Math.random()).replace('0.', '_');
            var postData = {
                school_id: Tss.Env.get('school_id'),
                term_id: self.opts.termId,
                import_data: JSON.stringify(data), // gets around php max_input_vars of 10000
                idx: idx,
            };
            var request = $.post('/import/data?expand=section_period', postData);
            var callbacks = {
                progress: elem => self.checkProgress(idx, elem),
                done: response => self.importDataDone(response),
                error: console.log,
            };
            var task = new AsyncTask('Upload Sections', request, callbacks);

            self.asyncTaskManager.add(task);
        },

        checkProgress: async function(idx, elem) {
            var response = await Api.get('/import/data/ping?idx=' + idx);

            elem.trigger('update', response);
        },

        importDataDone: function(response) {
            var self = this;

            $('#growls').empty();

            if (response.success) {
                var sectionsAdded = self.getAdded(response, 'sections');
                var sectionsUpdated = self.getUpdated(response, 'sections');
                var sectionPeriodsAdded = self.getAdded(response, 'section_periods');
                var sectionPeriodsUpdated = self.getUpdated(response, 'section_periods');
                var sectionMappingsAdded = self.getAdded(response, 'section_mappings');
                var sectionMappingsUpdated = self.getUpdated(response, 'section_mappings');
                var sectionStudentsAdded = self.getAdded(response, 'section_students');
                var sectionStudentsUpdated = self.getUpdated(response, 'section_students');

                var mergedSectionsAdded = _.chain(sectionPeriodsAdded)
                    .keyBy('section_id')
                    .extend(_.keyBy(sectionsAdded, 'section_id'))
                    .values()
                    .value();
                var mergedSectionsUpdated = _.chain(sectionPeriodsUpdated)
                    .keyBy('section_id')
                    .extend(_.keyBy(sectionsUpdated, 'section_id'))
                    .extend(_.keyBy(sectionMappingsAdded, 'section_period.section_id'))
                    .extend(_.keyBy(sectionMappingsUpdated, 'section_period.section_id'))
                    .values()
                    .value();

                var message = "Success!"
                    + (mergedSectionsAdded.length
                        ? ' ' + displayItems(mergedSectionsAdded.length, 'new section') + ' added.'
                        : '')
                    + (mergedSectionsUpdated.length
                        ? ' ' + displayItems(mergedSectionsUpdated.length, 'section') + ' updated.'
                        : '')
                    + (sectionStudentsAdded.length
                        ? ' ' + displayItems(sectionStudentsAdded.length, 'new student enrollment') + ' added.'
                        : '')
                    + (sectionStudentsUpdated.length
                        ? ' ' + displayItems(sectionStudentsUpdated.length, 'student enrollment') + ' updated.'
                        : '')
                    + (!mergedSectionsAdded.length
                            && !mergedSectionsUpdated.length
                            && !sectionStudentsAdded.length
                            && !sectionStudentsUpdated.length
                        ? " No changes detected."
                        : '')
                    ;
                Growl.success({ message, fixed: true });
                Tss.Types.getAll(); // reload all rows
            } else {
                var warningMsg = _.chain(response).get('warnings').values().join('<br/>').value().replace(/\n/g, '<br/>');
                var errorMsg = _.chain(response).get('errors').values().join('<br/>').value().replace(/\n/g, '<br/>');

                if (warningMsg) {
                    Growl.warning({ message: warningMsg, fixed: true });
                }
                
                if (errorMsg) {
                    Growl.error({ message: errorMsg, fixed: true });
                }
            }
        },

        getAdded: function(response, clazz) {
            return _.chain(response)
                .get(`meta.changes.${clazz}`)
                .filter(x => _.get(x, 'change_logs[0].type') == 0)
                .value();
        },

        getUpdated: function(response, clazz) {
            return _.chain(response)
                .get(`meta.changes.${clazz}`)
                .filter(x => _.get(x, 'change_logs[0].type') == 1)
                .value();
        },

        addStudent: function() {
            var self = SectionPeriods;
            var select = $(this);
            var studentId = select.val();

            if (!studentId) {
                return;
            }

            var studentName = select.find('option:selected').text();
            var formData = getFormData(self.$form, true);
            var sectionStudent = {
                active: 1,
                student_id: studentId,
                student: {
                    display_name: studentName,
                },
            };
            var rowData = _.extend({}, self.defaultRowData, sectionStudent, {
                name_key: 'new_section_students',
                row_id: self.newStudentsAddedCount++,
            });
            self.renderRow(rowData);

            $('#types-table tbody').each(setupUI);
            setTimeout(function() { select.val('').change() }, 250);
        },

        addStudentsBySection: async function() {
            var self = SectionPeriods;
            var select = $(this);
            var sectionId = select.val();

            if (!sectionId) {
                return;
            }

            var sectionName = select.find('option:selected').text();
            var formData = getFormData(self.$form, true);
            var response = await Api.get(`${self.sectionApi}/${sectionId}?expand=section_students.student`);
            var sectionStudents = _.chain(response)
                .get('results.section.section_students')
                .filter(x => x.active == 1)
                .map(x => _.pick(x, ['student_id', 'start_date', 'end_date', 'active', 'student'])) // filter to only the keys we want to copy
                .value();

            if (!sectionStudents.length) {
                return Growl.warning({message: `No students found for section ${sectionName}.`});
            }

            var existingRows = self.$table.find('tbody tr:not(.deactivated)');
            var addStudents = () => {
                self.addSectionStudents(sectionStudents);
                setTimeout(function() { select.val('').change() }, 500);
            };

            if (existingRows.length) {
                var template = 'confirm-add-or-replace-section-students';
                var modal = new Tss.Modal({
                    template,
                    launchTarget: '[name="don\'t worry about it"]',
                    remove: true,
                    getData: () => ({
                        num_students: sectionStudents.length,
                    }),
                    go: function() {
                        var options = $('.' + template).serializeJSON();

                        this.closeModal();

                        if (options.replace_existing) {
                            existingRows.find('.icon-remove').each(self.handleToggleActiveClick);
                        }

                        addStudents();
                    },
                });

                modal.showModal();
            } else {
                addStudents();
            }
        },

        addSectionStudents: function(sectionStudents) {
            var self = this;
            var sortedSectionStudents = self.sortSectionStudents(sectionStudents);

            _.each(sortedSectionStudents.reverse(), sectionStudent => {
                var rowData = _.extend({}, self.defaultRowData, sectionStudent, {
                    name_key: 'new_section_students',
                    row_id: self.newStudentsAddedCount++,
                    section_student_id: null,
                });

                self.renderRow(rowData);
            });

            $('#types-table tbody').each(setupUI);
        },

        findActiveOrActivateInactive: async function(records, api, idCol) {
            var self = this;
            var activeRecords = _.filter(records, x => x.active == 1);

            if (activeRecords.length) {
                return activeRecords[0][idCol];
            }

            if (records.length) {
                var id = records[0][idCol];

                await Api.put(`${api}/${id}`, { active: 1 });

                return id;
            }

            return null;
        },

        fillInForm: function(e, record) {
            var self = SectionPeriods;
            var sectionNumber = _.get(record, 'section.number')
                + (self.action == 'duplicate' ? ' (copy)' : '');
            var courseIds = _.chain(record)
                .get('section_mappings')
                .filter(x => x.active == 1)
                .map('course_id')
                .value()

            $('[name="section.number"]').val(sectionNumber);
            $('[name="period.number"]').val(_.get(record, 'period.number'));
            $('[name="course_id[]"]').val(courseIds).change();

            return self.fillInSectionStudents(record.section.section_students, true);
        },

        sortSectionStudents: function(sectionStudents) {
            var sortKeys = [
                sectionStudent => sectionStudent.active == 1 ? 0 : 1, // sort active before inactive
                'student.display_name',
                sectionStudent => sectionStudent.start_date || '0000-00-00', // sort empty start dates to the beginning
                sectionStudent => sectionStudent.end_date || '9999-99-99', // sort empty end dates to the end
            ];

            return _.sortBy(sectionStudents, sortKeys);
        },

        fillInSectionStudents: function(sectionStudents, append) {
            var self = this;
            var sortedSectionStudents = self.sortSectionStudents(sectionStudents);

            _.each(sortedSectionStudents, sectionStudent => {
                var id = sectionStudent.section_student_id;
                var rowData = _.extend({}, self.defaultRowData, sectionStudent, {
                    name_key: id ? 'section_students' : 'new_section_students',
                    row_id: id || self.newStudentsAddedCount++,
                });

                if (self.action == 'duplicate') {
                    rowData.section_student_id = '';
                }

                self.renderRow(rowData, append);
            });

            $('#types-table tbody').each(setupUI);
            $('#types-table tbody tr.deactivated input[type="text"]').attr('disabled', 'disabled'); // do this after setupUI so the datepicker stuff works.
        },

        renderRow: function(sectionStudent, append) {
            var self = this;

            if (!sectionStudent.student_id) {
                return;
            }

            var $row = $(Handlebars.renderTemplate('setup/configure/section-student-table-row', sectionStudent));
            var rowId = $row.attr('id');

            $row.find('.options').tssDropdown({
                content: Handlebars.renderTemplate('setup/configure/section-student-table-row-options', sectionStudent),
                icon: 'icon-cog',
                right: true
            });

            if ($('#' + rowId).length) {
                $('#' + rowId).replaceWith($row);
            } else {
                self.$table.find('tbody')[append ? 'append' : 'prepend']($row);
            }
        },
    };

    window.Tss = window.Tss || {};
    window.Tss.SectionPeriods = SectionPeriods;

})(jQuery, window);

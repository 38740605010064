var Assessment = {
    courseId: $('select[name="course_id[]"]').val(),
    asof: $('input[name="date"]').val(),
    sectionMultiSelect: null,
    fillingInForm: false,
    multiselectBindingDelay: 1500,
    sectionEnrollmentEndpoint: '/api/v1/section-enrollments',
    courseEnrollmentEndpoint: '/course/enrollment/',
    resultsExportEndpoint: '/assessment/results/export/',
    courseSectionsEndpoint: '/course/sections',
    assessmentDefinitionConfig: {},
    sectionPeriodDisplayMap: {},
    pointsPossible: null,
    curve: 0,
    curveMethodId: null,
    config: {
        groupStudentsBySection: false,
        sectionHeaderName: 'Section',
        sectionClass: 'text section',
    },
    questions: [],
    results: [],

    init: function() {
        if($('#assessment-results-tab').length) {
            Assessment.setBindings();
            Assessment.updateDisplay();
        }
    },

    /**
     *
     * @param {object} data
     */
    setDefinitionConfig: function(data) {
        this.assessmentDefinitionConfig = data;
    },

    getQuestionTagData: function() {
        //get assessment quetsions from last transaction
        if(!lastData.results) {
            return {};
        }
        var data = lastData.results.assessmentQuestions ? lastData.results.assessmentQuestions : null;

        //empty objects to hold tag data
        var questionTags = [],
            questionTagObjects = {};

        if(data) {
            //let's get all tags
            _.each(data, function(question) {
                if(question.questionTags.length && question.questionTags[0].tag.length) {
                    questionTags.push(question.questionTags);
                }
            });

            //now flatten the array since its nested
            questionTags = _.flatten(questionTags);

            //now lets elminiate duplicates and make sure weights are where they belong
            _.each(questionTags, function(questionTag) {
                if(!questionTagObjects[questionTag.tag]) {
                    questionTagObjects[questionTag.tag] = questionTag;
                } else {
                    if(!questionTagObjects[questionTag.tag].weight) {
                        questionTagObjects[questionTag.tag] = questionTag;
                    }
                }
            });
        }

        return {'tags': _.toArray(questionTagObjects)};
    },

    setSectionMultiselectBindings: function(sectionPeriodDisplayMap) {
        this.sectionMultiSelect = $('select[name="section_id[]"]');

        this.selectCourseSections(sectionPeriodDisplayMap);

        var change = delayFunction(Assessment.multiselectBindingDelay, this.handleSectionChange);

        //event binding
        this.sectionMultiSelect.on('change', function() {
            Functions.executeCallback(change, this.sectionMultiSelect);
        });
    },

    handleSectionChange: function(el) {
        var sectionPeriodIds = Assessment.getSelectedSections(el);
        var asof = $('[name="date"]').val();
        var url = Assessment.sectionEnrollmentEndpoint + (asof ? '?asof=' + dateStringToServer(asof) : '');
        var courseIds = $('[name="course_id[]"]').map(function() { return $(this).val() }).get();
        var params = {
            section_period_ids: sectionPeriodIds ? sectionPeriodIds.join(',') : '',
            course_ids: courseIds ? courseIds.join(',') : '',
        };
        var request = sectionPeriodIds
            ? $.get(url, params)
            : null;

        $.when(request).done(Assessment.handleEnrollmentUpdate);
    },

    getSelectedSections: function(el) {
        el = el || Assessment.sectionMultiSelect;
        return el.val();
    },

    getAllSelectedCourses: function() {
        var courseIds = $('[name="course_id[]"]').map(function() { return $(this).val() }).get();

        return _.filter(courseIds);
    },

    forceEnrollmentUpdate: function() {
        Assessment.sectionMultiSelect.change();
    },

    handleEnrollmentUpdate: function(data, textStatus, jqXHR) {
        data = data ? data : {};

        //handle no data returned
        if (!data.success || !data.results || !data.results.students) {
            var students = [];
        } else {
            var students = data.results.students;
        }

        Assessment.setSectionPeriodDisplayMap(_.get(data, 'results.sectionPeriodDisplayMap', {}));

        //set enrolled students (only for summary-mode)
        $('input[name="enrolled_students"]').val(students.length);

        //handle sections
        Assessment.handleSectionUpdate(data);

        //set asof for next time
        Assessment.asof = $('input[name="date"]').val();
    },


    handleSectionUpdate: function(data) {
        //store students for manual assessment import, get picklist, render template
        ManualAssessment.saveEnrollment(data);
    },

    triggerMainFormSubmit: function() {
        //submit the main form
        $('#main-form').trigger('submit',
            {
                'whatIf': 1,
                'callback': handleResponse
            }
        );
    },

    fetchAndSelectCourseSections: function() {
        var payload = {
            course_id: this.getAllSelectedCourses(),
            staff_member_id: $('[name="staff_member_id"]').val(),
        };

        if (!this.fillingInForm
                && (!_.isEqual(this.courseId, payload.course_id)
                    || this.staffMemberId !== payload.staff_member_id)) {
            this.sectionMultiSelect.next().addClass('loading');

            $.post(this.courseSectionsEndpoint, payload, (data) => {
                this.selectCourseSections(data.results.course_section_periods);
                this.sectionMultiSelect.next().removeClass('loading');

                this.forceEnrollmentUpdate(); // load students based on these sections
            });
        }

        this.staffMemberId = payload.staff_member_id;
        this.courseId = payload.course_id;
    },

    selectCourseSections: function(sectionPeriodDisplayMap) {
        var self = this;

        try {
            var sectionPeriodIds = Object.keys(sectionPeriodDisplayMap);
            var addedNewOptions = false;

            self.setSectionPeriodDisplayMap(sectionPeriodDisplayMap);

            // add in new section options if missing
            _.each(sectionPeriodDisplayMap, (m, sectionPeriodId) => {
                if (!self.sectionMultiSelect.find(`option[value="${sectionPeriodId}"]`).length) {
                    self.sectionMultiSelect.append(`<option value="${sectionPeriodId}">${m.section_display_name}</option>`);
                    addedNewOptions = true;
                }
            });

            if (addedNewOptions) {
                self.sectionMultiSelect.trigger('refresh-options');
            }

            fillInForm('section_id', sectionPeriodIds);
        } catch(e) {
            console.log(e); // what is this for?
        }
    },

    setSectionPeriodDisplayMap: function(sectionPeriodDisplayMap) {
        var self = this;

        _.extend(self.sectionPeriodDisplayMap, sectionPeriodDisplayMap);

        var loggedInStaffMemberSections = _.filter(
            sectionPeriodDisplayMap,
            m => m.staff_member_id == ManualAssessment.opts.loggedInStaffMemberId
        );
        var otherStaffMemberSections = _.filter(
            sectionPeriodDisplayMap,
            m => m.staff_member_id != ManualAssessment.opts.loggedInStaffMemberId
        );

        // only enable the "Show My Sections Only" checkbox if I actually
        // have some sections and somebody else also has some sections
        var checkbox = $('input[type="checkbox"][name="teacher_sections_only"]');
        if (loggedInStaffMemberSections.length && otherStaffMemberSections.length) {
            checkbox.siblings().andSelf().removeAttr('disabled');
        } else {
            checkbox.siblings().andSelf().attr('disabled', 'disabled');
            checkbox.prop('checked', false).change();
        }
    },

    initDownloadResults: function(e, fullResults) {
        var sectionPeriods = Assessment.getSelectedSections(),
            courseId = $('select[name="course_id[]"]').val(),
            asof = $('[name="date"]').val(),
            url = Assessment.sectionEnrollmentEndpoint,
            sectionPeriodIds = (sectionPeriods ? sectionPeriods.join(',') : ''),
            urlParams = {section_period_ids: sectionPeriodIds, asof: asof};

        // If no sections, use course
        if(!sectionPeriods || sectionPeriods.length === 0) {
            url = Assessment.courseEnrollmentEndpoint + courseId + (asof ? '/' + dateStringToServer(asof) : '');
            urlParams = {sections: sectionPeriods, date: asof};

            //if no course, set url to null for later
            if (!courseId.length) {
                url = null;
            }
        }

        //determine download method
        var downloadMethod = fullResults ? Assessment.downloadFullResults : Assessment.downloadResults;

        if(url) {
            $.get(url, urlParams, downloadMethod);
        }
        //pass call download result method without data for sorting
        else {
            downloadMethod();
        }
    },

    downloadResults: function(sortData) {
        Assessment.displayAnswers(true);
        var data = lastData,
            url = Assessment.resultsExportEndpoint,
            headers = [[
                'Student',
                'Student ID',
                'SR Student Number',
                'SIS Student Number',
                'State ID',
                Assessment.homeRoomStr,
                Assessment.config.sectionHeaderName,
                'Raw Score',
                'Raw Grade',
                'Gradebook Score',
                'Gradebook Grade'
            ]],
            dataArray = [],
            keyedDataArray = {},
            finalData = [];

            // we only want a column for section if we group students by section
            if (!Assessment.config.groupStudentsBySection) {
                var sectionHeaderIndex = headers[0].indexOf(Assessment.config.sectionHeaderName);
                headers[0].splice(sectionHeaderIndex, 1);
            }

        //get all rows into an array
        // this data is already filtered by canISeeSchool
        dataArray = rowsToValues($('tbody tr', $('#assessment-results-table-container')), function(row, values) {
            var studentId = row.find('td:first a').attr('href').replace(/^.*\/([^\/]+)$/, '$1'),
                assessmentAnswer = _.find(data.results.assessmentAnswers, {student_id: studentId}),
                schoolIdCode = assessmentAnswer.school_id_code,
                sisId = assessmentAnswer.sis_id,
                stateId = assessmentAnswer.state_id,
                homeroom = homeroomMap[studentId] || {};

            values.splice(1, 0, studentId, schoolIdCode, sisId, stateId, homeroom.display_name || '');

            //key by studentId for sorting if needed
            keyedDataArray[studentId] = values;

            return values;
        });

        //sort
        if(sortData) {
            sortData = sortData.results.students;
            var sortedDataArray = [],
                orderedStudentIds = [];

            //get students in section order based on enrollment
            _.each(sortData, function(v) {
                if(keyedDataArray[v.student_id]) {
                    sortedDataArray.push(keyedDataArray[v.student_id]);
                    orderedStudentIds.push(v.student_id);
                }
            });

            //now let append students to the end who aren't in the enrollment
            var differences = _.difference(_.keys(keyedDataArray), orderedStudentIds);
            _.each(differences, function(studentId) {
                sortedDataArray.push(keyedDataArray[studentId]);
            });

            finalData = headers.concat(sortedDataArray);
        }
        //no sorting
        else {
            finalData = headers.concat(dataArray);
        }

        Assessment.completeResultDownload(finalData, url);
    },

    downloadFullResults: function(sortData) {
        var data = lastData,
            url = Assessment.resultsExportEndpoint,
            dataArray = [],
            keyedDataArray = {},
            finalData = [],
            gradingScale = getGradingScale(),
            noPct = isNoPct(data),
            header1 = [
                'Name',
                'Student ID',
                'SR Student Number',
                'SIS Student Number',
                'State ID',
                Assessment.homeRoomStr,
                Assessment.config.sectionHeaderName,
                'Raw Score',
                'Grade'
            ],
            header2 = ['Standard', '', '','','','','','',''],
            header3 = ['Correct Answer/Point Value', '', '','','','','','',''];

        // we only want a column for section if we group students by section
        if (!Assessment.config.groupStudentsBySection) {
            var sectionHeaderIndex = header1.indexOf(Assessment.config.sectionHeaderName);
            header1.splice(sectionHeaderIndex, 1);
        }

        //get all data
        _.each(data.results.studentResults, function(studentResult, studentId) {
            var assessmentAnswer = _.find(data.results.assessmentAnswers, {student_id: studentId}),
                name = $(assessmentAnswer.name).text(),
                schoolIdCode = assessmentAnswer.school_id_code,
                sisId = assessmentAnswer.sis_id,
                stateId = assessmentAnswer.state_id,
                avg = assessmentAnswer.avg_score,
                homeroom = homeroomMap[studentId] || {},
                sectionName = assessmentAnswer.sectionName || 'N/A';

            if (avg == null) return true; // continue
            if (!ManualAssessment.canISeeSchool(assessmentAnswer.school_id)) return true; // continue

            var testResult = [
                name,
                studentId,
                schoolIdCode,
                sisId,
                stateId,
                homeroom.display_name || '',
                sectionName,
                (noPct ? avg : parseFloat(avg).toFixed(0) + '%'),
                scoreToGradeLevel(noPct ? avg : parseFloat(avg).toFixed(0), gradingScale).name,
            ];

            // we only want data for section if we group students by section
            if (!Assessment.config.groupStudentsBySection) {
                var sectionNameIndex = testResult.indexOf(sectionName);
                testResult.splice(sectionNameIndex, 1);
            };

            _.each(studentResult, function(a, q) {
                var question = data.results.assessmentQuestions[q],
                    obj = question.objective,
                    objDesc = objectiveToDesc(obj),
                    points = a[0],
                    answer = a[1] || 'blank';

                if (dataArray.length === 0) {
                    header1.push("Question " + q);
                    header2.push(objDesc);
                    header3.push(question.correct_answer || question.point_value);
                }

                testResult.push(question.correct_answer ? answer : points);
            });

            //key by studentId for sorting if needed
            keyedDataArray[studentId] = testResult;
            dataArray.push(testResult);
        });

        //sort
        if(sortData) {
            sortData = sortData.results.students;
            var sortedDataArray = [],
                orderedStudentIds = [];

            //get students in section order based on enrollment
            _.each(sortData, function(v) {
                if(keyedDataArray[v.student_id]) {
                    sortedDataArray.push(keyedDataArray[v.student_id]);
                    orderedStudentIds.push(v.student_id);
                }
            });

            //now let append students to the end who aren't in the enrollment
            var differences = _.difference(_.keys(keyedDataArray), orderedStudentIds);
            _.each(differences, function(studentId) {
                sortedDataArray.push(keyedDataArray[studentId]);
            });

            finalData = sortedDataArray;
        }
        //no sorting
        else {
            finalData = dataArray;
        }

        //add headers
        finalData.unshift(header3);
        finalData.unshift(header2);
        finalData.unshift(header1);

        Assessment.completeResultDownload(finalData, url);
    },

    completeResultDownload: function(data, url) {
        var jsonStr = JSON.stringify(data);
        var data = Handlebars.renderTemplate('temporary-form', {url: url});
        var tempform = $('body').append(data).find('#tempform');
        tempform.find('input[name="title"]').val($('input[name="name"]').val());
        tempform.find('input[name="data"]').val(jsonStr); // avoid single quote issues
        tempform.submit().remove();
        pauseOnBeforeUnload();
    },

    getSectionPeriodDisplayMapForSectionId: function(sectionId) {
        return _.find(this.sectionPeriodDisplayMap, x => x.section_id == sectionId);
    },

    getSectionForStudent: function(studentId) {
        var section = {},
            sectionStudents = ((lastData || {}).results || {}).sectionStudents || {};

        _.each(sectionStudents, function(arr, sectionId) { // get this from gradeTest not from /section/enrollment/?
            var students = arr.students;

            if ($.inArray(studentId + "", students) >= 0) {
                arr.section_id = sectionId;
                section = arr;

                return false; // break;
            }
        });

        return section;
    },

    getAndValidateTemplateNumQuestions: function(defaultVal) {
        var numQuestions = parseInt($('[name="number_of_questions"]:visible').val()),
            defaultVal = defaultVal || 1; //defaults to only one question

        return (_.isNaN(numQuestions) || numQuestions < 1) ? defaultVal : numQuestions;
    },

    updateDisplay: function() {
        var showAnalysis = _.keys(this.questions).length !== 0 || !isEdit,
            showResults = this.results.length !== 0 || !isEdit;

        if(showAnalysis) {
            this.showAnalysis();
        } else {
            this.hideAnalysis();
        }

        if(showResults) {
            this.showResults();
        } else {
            this.hideResults();
        }

        if(showAnalysis || showResults) {
            $('#import-assessment').find('.info').addClass('minimized');
        } else {
            $('#import-assessment').find('.info').removeClass('minimized');
        }

    },

    /**
     * Loads a standard response into this instance to be processed later.
     * @param  object data
     * @return void
     */
    loadData: function(data) {
        var that = this;
        this.data = data;

        // Load the points possible by looping over the questions:
        this.pointsPossible = 0;
        this.questions = data.results.assessmentQuestions;
        _.each(this.questions, function(q, i) {
            that.pointsPossible += parseFloat(q.point_value) || 0;
            that.questions[i].question_name = q.question_name || q.question_number;
        });

        // Default to the tss-switch default in the view:
        this.curveMethodId = data.results.ar.curve_method_id ?
            parseInt(data.results.ar.curve_method_id, 10) :
            2;
        this.curve = data.results.ar.curve ?
            parseFloat(data.results.ar.curve) :
            0;

        // This mutation must also happen in the data instance
        // for the magical fillInForm() that gets called later!
        if (this.curve == 0 && this.curveMethodId == 2) {
            this.curveMethodId = 1;
            data.results.ar.curve = this.curve;
            data.results.ar.curve_method_id = this.curveMethodId;
        }
    },

    displayQuestions: function(force) {
        if(!$('#assessment-analysis-tab').is(':visible') && !force) { return; }

        var data = this.data || {},
            results = data.results || {},
            questions = results.assessmentQuestions || [],
            ar = results.ar || {},
            numStudents = ar.present_students || 0,
            rows = [];

        Assessment.questions = questions;
        if (!Object.keys(questions).length) {
            return Assessment.updateDisplay();
        }

        _.each(questions, function(q) {
            rows.push([
                {val: q.question_name && q.question_name.length ? q.question_name : parseInt(q.question_number),
                 attrs: (q.comment ? 'title="' + q.comment + '"' : '')},
                {val: q.correct_answer || ''},
                {val: q.point_value || ''},
                {val: q.objective.code || ''},
                {val: q.objective.description || ''},
                {val: ManualAssessment.getQuestionTags(q)},
                {val: ManualAssessment.getQuestionWeight(q)},
                q.rawScore === ''
                    ? {val: ''}
                    : (q.point_value && q.point_value != '0'
                        ? {val: (q.rawScore).toFixed(0) + '%',
                           attrs: 'data-total="' + (100 * q.studentPoints) + '" data-count="' + (q.point_value * numStudents) + '" '
                                  + 'title="' + q.studentPoints + ' / ' + (q.point_value * numStudents) + '"'}
                        : {val: (q.rawScore).toFixed(2)}),
                q.distractorAnswer === ''
                    ? {val: '', attrs: 'sortval=""'}
                    : {val: '<span class="td-margin-right">' + q.distractorAnswer + ' </span>' + (q.distractorPct).toFixed(0) + '%',
                       attrs: 'sortval="' + q.distractorCount + '" data-distractors=\'' + JSON.stringify(q.distractors) + '\''}
                ]);
        });

        // console.profile('render assessment questions');
        var noPct = isNoPct(data),
            classes = [
                'text question-number',
                'correct-answer',
                'total point-value',
                'total uniq text objective-code',
                'total uniq text objective max objective-description',
                'text tags',
                'text weight',
                'no-export average ' + (noPct ? 'rawscore' : 'pct') + ' raw-score',
                'no-export distractor'
            ],
            table = Handlebars.renderTemplate(this.assessmentDefinitionConfig.templates.definitionTable, {
                rows: rows,
                classes: classes
            }),
            wrapper = $('#assessment-definition-table-container').html(table);
        // console.profileEnd();

        wrapper.find('table.tablesorter').trigger('update');
        wrapper.find('table.tablesorter').each(simpleCalculateTotals);
        $('td[data-distractors]').tipsy({gravity: 's', title: displayDistractorsTooltip(), html: true, className: 'white'});
        wrapper.find('table.tablesorter').each(tablesorter);

        return Assessment.updateDisplay();
    },

    displayAnswers: function(tableOnly, force) {
        if(!$('#assessment-results-tab').is(':visible') && !force) { return; }
        var data = this.data || {},
            results = data.results || {},
            answers = this.setStudentSectionNames(results.assessmentAnswers || []),
            rows = [],
            noPct = isNoPct(data),
            ar = data.results.ar,
            rawScore = parseFloat(ar.avg_score) || 0,
            gradebookScore = parseFloat(ar.gradebook_score) || 0;

        if (results['assessment_answers']) {
            $('input[name="assessment_answers"]').val(results['assessment_answers']);
        }

        _.each(answers, function(assessmentStudent) {
            if (!ManualAssessment.canISeeSchool(assessmentStudent.school_id)
                    || assessmentStudent.missing == 1) {
                return true; // continue
            }

            // PHP treats score_override == 0 as "none", so mimic the behavior here.
            var score = parseFloat(assessmentStudent.avg_score || 0),
                gradebookScore = parseFloat(assessmentStudent.gradebook_score || 0);

            var sectionNameObj = {val: assessmentStudent.sectionName};

            var cols = [
                {val: assessmentStudent.name},
                sectionNameObj,
                {val: noPct ? (score).toFixed(2) : (score).toFixed(0) + '%'},
                {val: ''},
                {val: noPct ? (gradebookScore).toFixed(2) : (gradebookScore).toFixed(0) + '%', attrs: 'data-gradebook_score="' + gradebookScore + '"'},
                {val: ''}
            ]

            // we only want data for section if we group students by section
            if (!Assessment.config.groupStudentsBySection) {
                var sectionNameIndex = cols.indexOf(sectionNameObj);
                cols.splice(sectionNameIndex, 1);
            };

            rows.push({
                student: assessmentStudent,
                cols: cols,
                hasOverride: !_.isNull(assessmentStudent.score_override)
            });
        });

        Assessment.results = rows;
        if (!rows.length) {
            return Assessment.updateDisplay();
        }

        var cols = [
                'Student',
                Assessment.config.sectionHeaderName,
                'Raw Score',
                'Raw Grade',
                'Gradebook Score',
                'Gradebook Grade'
            ],
            classes = [
                'text student',
                Assessment.config.sectionClass,
                'average ' + (noPct ? 'rawscore' : 'pct') + ' raw-score',
                'raw-grade',
                'average ' + (noPct ? 'rawscore' : 'pct') + ' curved-score',
                'curved-grade'
            ];

            // we only want columns and classes for section if we group students by section
            if (!Assessment.config.groupStudentsBySection) {
                var sectionColIndex = cols.indexOf(Assessment.config.sectionHeaderName);
                var sectionClassIndex = classes.indexOf(Assessment.config.sectionClass);
                cols.splice(sectionColIndex, 1);
                classes.splice(sectionClassIndex, 1);
            };

            var table = Handlebars.renderTemplate(this.assessmentDefinitionConfig.templates.resultsTable, {
                headers: cols,
                rows: rows,
                classes: classes,
                averages: {
                    numRows: rows.length,
                },
                groupBySection: Assessment.config.groupStudentsBySection,
            }),
            wrapper = $('#assessment-results-table-container').html(table);

        wrapper.find('table.tablesorter').trigger('update');
        wrapper.find('table.tablesorter').each(simpleCalculateTotals);

        if (gradingScales.length) {
            AssessmentGraphing.drawResultsGraph(data, getGradingScale());
        }
        wrapper.find('table.tablesorter').each(tablesorter);

        return Assessment.updateDisplay();
    },

    displayMissing: function(data) {
        var data = this.data,
            missing = this.getMissing(data),
            row = [],
            rows = [];
        //let's break them out into rows...
        _.each(missing, function(student, i) {
            row.push(student);
            if((i + 1) % 4 === 0) {
                rows.push(row);
                row = [];
            }
        });

        if(row.length) {
            rows.push(row);
        }

        var missingList = Handlebars.renderTemplate('assessment-missing-students', {
                missing: rows,
                length: missing.length,
                schoolStudentStr: Assessment.schoolStudentStr
            });
        $('#assessment-missing-students-container').html(missingList);
    },

    setStudentSectionNames: function(assessmentAnswers) {
        var indexedAssessmentAnswers = _.indexBy(assessmentAnswers, 'student_id');

        _.each(this.data.results.sectionStudents, function(section) {
            _.each(section.students, function(studentId) {
                if(indexedAssessmentAnswers[studentId]) {
                    if (Assessment.config.groupStudentsBySection) {
                        indexedAssessmentAnswers[studentId].sectionName = section.display_name;
                    } else {
                        indexedAssessmentAnswers[studentId].sectionName = '';
                    }
                }
            });
        });

        return indexedAssessmentAnswers;
    },

    getMissing: function(data) {
        var assessmentAnswers = data.results && data.results.assessmentAnswers ? data.results.assessmentAnswers : [];
        return _.filter(assessmentAnswers, function(student) {
            //we normally could use shorthand but we don't want to assume that missing is an int
            return student.missing == 1
                && ManualAssessment.canISeeSchool(student.school_id);
        });
    },

    toggleStudentActiveStatus: function() {
        var $container = $(this),
            $manualAssessmentRow = $container.closest('.manual-assessment-row'),
            studentId = $container.data('student-id'),
            $button = $container.find('[rel="assessment-student-toggle-active"]'),
            $input = $container.find('input[name$="\[active\]"]'),
            active = $input.val() == 1 ? 0 : 1;

        // manual assessment row could be in results table OR in manual input tab.
        $manualAssessmentRow.toggleClass('enabled disabled');

        Assessment.setAssessmentAnswerValue(studentId, 'active', active);
        ManualAssessment.setAssessmentAnswerValue(studentId, 'active', active);
        $input.val(active);

        $button.toggleClass('icon-ok icon-remove');
        if (active) {
            $button.attr('title', 'Exclude');
            $container.removeClass('disabled');
        } else {
            $button.attr('title', 'Include');
            $container.addClass('disabled');
        }
    },

    /**
     * Gets student active value from assessmentAnswers.
     * @param int studentId
     * @returns boolean
     */
    getStudentActive: function(studentId) {
        if(!this.data) {
            return true;
        }
        var student = _.find(this.data.results.assessmentAnswers, function(student) {
            return student.student_id == studentId;
        });

        return student && student.active == 1 ? true : (student ? false : true);
    },

    /**
     * Sets any value for a student in assessmentAnswers.
     * @param int studentId
     * @param string key
     * @param mixed value
     */
    setAssessmentAnswerValue: function(studentId, key, value) {
        if (!this.data) {
            return;
        }

        _.each(this.data.results.assessmentAnswers, function(student, index) {
            if(student.student_id == studentId) {
                student[key] = value;
            }
        });
    },

    handleAllowDivisionOnChange: function() {
        var $this = $(this),
            val = $this.val(),
            index = val.indexOf('/');

        if (val && index > 0) {
            try {
                $this.val(eval(val));
                ManualAssessment.saveInput.apply(this);
            } catch (err) {
                log(err);
            }
        }
    },

    showAnalysis: function() {
        $('.no-questions-show').hide();
        $('.no-questions-hide').show();
    },
    showResults: function() {
        $('.no-results-show').hide();
        $('.no-results-hide').show();
    },
    hideAnalysis: function() {
        $('.no-questions-show').show();
        $('.no-questions-hide').hide();
    },
    hideResults: function() {
        $('.no-results-show').show();
        $('.no-results-hide').hide();
    },

    setBindings: function() {
        var self = this;
        $('[data-tab="assessment-results-tab"]').click(function() {
            self.displayAnswers(false, true);
        });
        $('[data-tab="assessment-analysis-tab"]').click(function() {
            self.displayQuestions(true);
        });

        $(document).on('click', '[rel="assessment-student-toggle-active"]', function() {
            var $container = $(this).closest('[assessment-student-active-status]'),
                studentId = $container.data('student-id'),
                $instances = $('[assessment-student-active-status][data-student-id="' + studentId + '"]');

            $.each($instances, self.toggleStudentActiveStatus);
        });
        $(document).on('change', 'input.allow-division', this.handleAllowDivisionOnChange);
    }
};

$(Assessment.init);

window.Assessment = Assessment;

/*
 * Main js function repository.
 *
 * @author ccoglianese
 */
// add a generic log() function that won't error out in IE
window.log = function(){log.history=log.history||[];log.history.push(arguments);if(window.console){console.log(Array.prototype.slice.call(arguments));}};

// add a serializeJSON method to jQuery to create a simple object from a form $('#main-form').serializeJSON()'
(function($) {
    $.fn.serializeJSON = function() {
        var json = {};
        jQuery.map($(this).serializeArray(), function(n, i) {
            var name = n['name'],
                value = n['value'];

            if (name in json) {
                if (!$.isArray(json[name])) {
                    json[name] = [json[name]];
                }
                json[name].push(value);
            } else {
                json[name] = value;
            }
        });
        return json;
    };
})(jQuery);

$.ajaxSetup({
    cache: false
});

$(document).ajaxError(function(event, jqXHR, ajaxSettings, thrownError) {
    // TODO
    // robustify this to handle new api and legacy calls
    if (jqXHR.status == '422' || jqXHR.statusText == "abort" || jqXHR.statusText == "canceled") {
        return;
    } else if (jqXHR.status == '403' && isOnArchiveSite()) {
        window.location.href = '/forbidden';
        return;
    } else if (!jqXHR.responseText) {
        return;
    }

    // if we have text and its a custom HTML error message
    if (jqXHR.responseText && !isJson(jqXHR.responseText)) {
        document.open();
        document.write(jqXHR.responseText); // this is our custom 500/404 error doc
        document.close();
    }
});

$(document).ajaxSuccess(function(e, jqXHR, settings) {
     // we got redirected when trying to load an ajax sub-page
    if (jqXHR.responseText && jqXHR.getResponseHeader('IsAjaxRedirect') === '1') {
        document.open();
        document.write(jqXHR.responseText);
        document.close();
    }
});

function isOnArchiveSite() {
    var location = window.location.href;
    return location.match(/.+.\d{4}-\d{4}\.schoolrunner\.org.+/);
}

function isJson(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

// tablesorter init
$.metadata.setType('attr', 'metadata');
$.tablesorter.defaults.cancelSelection = true;
$.tablesorter.defaults.textExtraction = tableSorterExtraction;

// add autocomplete with categories as $().catcomplete();
$.widget("custom.catcomplete", $.ui.autocomplete, {
    _renderMenu: function(ul, items) {
        var that = this,
            currentCategory = "";
        $.each(items, function(index, item) {
            if (item.category != currentCategory) {
                ul.append("<li class='ui-autocomplete-category'>" + item.category + "</li>");
                currentCategory = item.category;
            }
            that._renderItemData(ul, item);
        });
    },

	_renderItem: function(ul, item) {
		return $('<li tss-tooltip-side></li>')
			.data( "item.autocomplete", item)
            .attr('title', item.tooltip)
			.append($('<a></a>')[this.options.html ? "html" : "text"](item.label).attr('href', $(item.link).attr('href')))
			.appendTo(ul);
	}
});

var loadOnce = null; // when using ajax back button, only load each div once
var poppingState = false; // when using ajax back button, only save session state once and don't pushState

$(function() {
    setupUI.apply($(document));

	//set all bindings
	Bindings.setAllBindings();

    //show a spinner icon whenever we're doing ajax calls
    var ajaxWaitingSymbol = document.getElementById('waiting_symbol'),
        $ajaxWaitingSymbol = $('#waiting_symbol');

    $('body').mousemove(function(e) {
        if (!ajaxWaitingSymbol) return;
        ajaxWaitingSymbol.style.left = e.pageX + 10 + "px"; // put the spinner in the same spot chrome does
        ajaxWaitingSymbol.style.top = e.pageY - 10 + "px";

        $.cookie('spinnerLeft', ajaxWaitingSymbol.style.left, {path: '/'}); // FIXME add domain .example.com
        $.cookie('spinnerTop', ajaxWaitingSymbol.style.top, {path: '/'});
    });

    $(document).ajaxStart(function() {
        if (ajaxWaitingSymbol.style.left == "0px" && $.cookie('spinnerLeft') != '') {
            ajaxWaitingSymbol.style.left = $.cookie('spinnerLeft');
            ajaxWaitingSymbol.style.top = $.cookie('spinnerTop');
        }

        $ajaxWaitingSymbol.show();
    });

    $(document).ajaxStop(function() {
        $ajaxWaitingSymbol.hide();
    });

    $('body').on('loaded', 'div', setupUI);
    $('body').on('tooltip-loaded', '.ajaxtooltip', setupUI);
    $('body').on('loaded', 'select[multiple!="multiple"]', hyjackSelect);

    // iOS bug! doesn't fire change when selecting a date using the native datepicker widget. it does fire blur so pass
    // it on to any change handlers
    if (isIOS()) {
        $('body').on('blur', 'input[type="date"]', function() { $(this).change(); });
    }

    $(document).bind('mousedown', function(e) {
//        log('mousedown: ' + e.which);
        var elem = $(e.target).closest('[iframeHref]');
        var href = elem.attr('iframeHref');

        if (!href) return true;

        stopPropagation(e);

        if ($(e.target).hasClass('ui-state-disabled')) return false;

        var width = elem.attr('iframeWidth');
        var height = elem.attr('iframeHeight');
        var callback = window[elem.attr('iframeCallback')]; // named callback or could use eval
        var data = JSON.parse(elem.attr('iframeData') || '{}');

        if (shouldOpenTab(e)) {
            window.open(href, '_blank');
        } else {
//edit(width, height, getURL, opacity, callback, data, disposeOnClose, size)
            edit(width, height, href, null, callback, data, null, elem.data('facebox-size'));
        }

        return false;
    });

    // quote init
    $('#quote').fadeTo(0, .01);
    load('quote', '/quote/');
    $('body').on('loaded', '#quote', function() {
        $('#quote').fadeTo(600, 1);
    });

    $('#searchBox').catcomplete({
        appendTo: '.quick-search-wrapper',
        source: '/search/quick/',
        html: true, // allow inactive to be line-through'ed
        open: function() {
            $(this).catcomplete('widget').addClass('z-index-top');
            return false;
        },
        select: function(event, ui) {
            if (event.keyCode == 13) {
                window.location = $(ui.item.link).attr('href');
            }
        }
    });

    $('#schoolSpan select').change(function() {
        var select = $(this);
        var key = select.attr('name');
        var value = select.val();

        if (!value) {
            return;
        }

        var state = {'student_id': '', 'student_group_id': ''};
        var requiredSchoolIds = ($('input[name="required_school_id"]').val() || '').split(',').filter(x => x);

        state[key] = value;
        $.post('/session/set/', state, function() {
            var newHref = requiredSchoolIds.length && !_.includes(requiredSchoolIds, state.school_id)
                ? '/'
                : (window.location + '')
                    .replace(/student_id=[^&]+&?/, '')
                    .replace(/student_group_id=[^&]+&?/, '')
                    .replace(/course_id=[^&]+&?/, '')
                    .replace(/section_ids=[^&]+&?/, '')
                    .replace(/term_bin_id=[^&]+&?/, '')
                    .replace(/\?$/, '');

            if (window.location == newHref) {
                window.location.reload();
            } else {
                window.location = newHref;
            }
        });
    });

    $('#termSpan select').change(function() {
        var select = $(this),
            key = select.attr('name'),
            $option,
            value = select.val(),
            state = {},
            archiveUrl,
            requiredTermId = $('input[name="required_term_id"]').val();

        $option = select.find('option[value="' + value + '"]');
        if ($option) {
            archiveUrl = $option.data('archive-url');
        }

        if (archiveUrl) {
            select.val(select.data('previous-value'));
            window.open(archiveUrl + '/login?term_id=' + value + '&_ref=' + encodeURIComponent(window.location.pathname), '_archive_' + archiveUrl);
        } else {
            state[key] = value;
            select.data('previous-value', value);
            $.post('/session/set/', state, function() {
                if (requiredTermId) {
                    window.location = '/';
                } else {
                    window.location.reload();
                }
            });
        }
    });

    var $studentGroupList = $('#student_group_list');
    if ($studentGroupList.length) {
        var state = getInitialState(),
            extras = state['extra_student_group_list_params'],
            studentExtras = state['extra_student_list_params'],
            selectDefault = !$studentGroupList.hasClass('no-default-selection'),
            selIdParam = selectDefault ? '&selid=' + state['student_group_id'] : '';

        load('student_group_list', '/studentgroup/all/?group_type=default&' + (extras ? '&' + extras : '') + selIdParam);
        var once = false;
        $('#student_group_list').bind('loaded', function() {
            var currentState = getCurrentState();

            //keep it..
            if(!selectDefault) {
                currentState['student_group_id'] = state['student_group_id'];
            }

            if (!once && (once = true)) {
                currentState['student_id'] = state['student_id']; // use initial state once
            }

            replaceState(currentState, document.title, getStatefulURL(currentState));
            $studentGroupList.trigger('refresh-options').change();
        });
    }

    // ajax back button!
    window.onpopstate = function (event) {
        var state = event.state;

        if (!state) {
            return;
        }

        $.each(getStateKeys(), function(i, key) {
            var selector = getSelectorForStateKey(key);

            $(selector).val(state[key]);
            $('input[for=' + selector.replace('#', '') + ']').val($(selector + ' option[value="' + state[key] + '"]').text());
        });

        loadOnce = {};
        poppingState = true;
        $.each(getStateKeys(), function(i, key) {
            var selector = getSelectorForStateKey(key);

            $(selector).change();
        });
        load('student_list', '/student/all/?header=select&student_group_id='+state['student_group_id']+'&selid='+state['student_id']);
        loadOnce = null;
        poppingState = false;

        setSessionState(state);
    };
});

function getStateKeys() {
    return ['student_group_id', 'student_id', 'week_of', 'printable',
            'asof', 'date', 'start_date', 'end_date', 'b_start_date',
            'lunch', 'detention_type_id', 'staff_member_id', 'demerit_type_id', 'group_id'];
}

function getStatefulURL(state, baseURL, keys) {
    var url = '';

    baseURL = baseURL || getBaseURL();
    keys = keys || getStateKeys();

    if (state) {
        $.each(keys, function(i, key) {
            if (!_.isUndefined(state[key])) {
                url = (!url ? baseURL + "?" : url + "&");
                url += key + "=" + (key.match(/asof|start_date|end_date/) ? dateStringToServer(state[key]) : (state[key] === null ? "": state[key]));
            }
        });
    } else {
        url = baseURL;
    }

    return url;
}
// useful for auto-populating the url from initial filters and updating the url on filter change 
// To use this pattern you must have --<div id="load_div"></div> present in the dom (note if you have two everything breaks)
// You also must define your own function getBaseURL(), function getLoadURL(state), and in some cases function getInitialState()
// Refer to demerits_student.php and dashboards.php for implementation examples and demerits_new.php for examples of the select element set up and initialization 
// This is a finicky pattern and has some rigid naming conventions. 
// The select elements of the filters must have an id like  id="demerit_type_list" i.e. ends in "list"
// Furthermore, you must go to getStateKeys() in this file and add the name of the id of the select element 
// however _list needs to be removed entirely or swapped out with _id
// When setting up select elements make sure that the $noEmptyOption = true as an empty value will break this 
// Once all of this is set up you then need to bind loadDivChange as an event handler on all of the watched filters 
// i.e. let formSelectors = ['#asof', '#staff_member_list', '#group_list', '#demerit_type_list']
//          formSelectors.forEach(function(selector) {
//          $(selector).change(loadDivChange).first().change();})
// note student_group_id and asof have additional logic inside of tss which can cause unexpected behavior so be aware of this 


function loadDivChange() {
    $('.status-banner').fadeOut(100);
    var state = getCurrentState();
    $('#load_div').tssLoad(getLoadURL(state));
    setSessionState(state);
    pushState(state, document.title, getStatefulURL(state));
}

function studentGroupChange() {
    $('.status-banner').fadeOut(100);

    var state = getCurrentState();

    if ($("#student_list").length) {
        load('student_list', '/student/all/?header=select&student_group_id=' + state['student_group_id'] + '&selid=' + state['student_id']);
    }

    load('load_div', getLoadURL(state));

    setSessionState(state);
    pushState(state, document.title, getStatefulURL(state));
}

function pushState(state, title, url) {
    if (poppingState) return;
    if (history.pushState) {
        history.pushState(state, title, url);
    }
}

function replaceState(state, title, url) {
    if (history.replaceState) {
        history.replaceState(state, title, url);
    }
}

function getSelectorForStateKey(key) {
    return "#" + key.replace("_id", "") + "_list";
}

function getCurrentState(state) {
    state = state || {};

    $.each(getStateKeys(), function(i, key) {
        var selector = getSelectorForStateKey(key);
        if (!state[key] && $(selector).length) {
            state[key] = $(selector).val();
        }
    });

    $('.state').each(function() {
        var elem = $(this);

        state[elem.attr('name')] = elem.val();
    });

    return state;
}

function setSessionState(state) {
    if (poppingState) return;
    $.post('/session/set/', state);
}

function isNumber(x) {
    return !isNaN(x - 0);
}

/**
 * NB: No knowledge of holidays here!
 * Might be ok for certain circumstances but use the server /addbusdays/3 if you need to know about holidays.
 */
function addBusDays(startDate, numDays) {
    var sign = numDays == 0 || !isNumber(numDays) ? 1 : numDays / Math.abs(numDays);
    var oneDay = 1000 * 60 * 60 * 24 * sign;
    var endDate = new Date(startDate);

    if (!isNumber(numDays)) {
        return endDate;
    }

    if (numDays == 0) {
        // skip weekends
        // FIXME skip holiays too
        while (endDate.getDay() == 6 || endDate.getDay() == 0) {
            endDate.setTime(endDate.getTime() + oneDay);
        }
    } else {
        for (var i = 0; i < Math.abs(numDays); i++) {
            endDate.setTime(endDate.getTime() + oneDay);

            // skip weekends
            // FIXME skip holiays too
            while (endDate.getDay() == 6 || endDate.getDay() == 0) {
                endDate.setTime(endDate.getTime() + oneDay);
            }
        }
    }

    return endDate;
}

function cleanElementId(elementId) {
    return elementId.replace(/(\[|\])/g, "\\$&");
}

function load(elementId, loadUrl, skipConfirm) {
    if(!loadUrl) { return; }

    if (!skipConfirm && 'load_div' == elementId) {
        confirmSave(window.onbeforeunload, function() { load(elementId, loadUrl, true); });
        return;
    }

    if (!(loadOnce === null)) { // when popping state, only load each div once
        if (loadOnce[elementId]) return;
        loadOnce[elementId] = true;
    };

    elementId = '#' + cleanElementId(elementId);
    var element = $(elementId);
    if (element.is('iframe')) {
        $.get(loadUrl, function(html) {
            var iframe = $(elementId).get(0);
            iframe.contentWindow.contents = html;
            iframe.src = 'javascript:window["contents"]';
            element.trigger('loaded');
        });
    } else if (element.length) {
        $(elementId).tssLoad(loadUrl);
    }
}

function deleteRow(tableId, tr, deleteUrl) {
    $.get(deleteUrl, function(data) {
        displayNotifications(data);

        if (!data.success) {
            return;
        }

        var row = tr.parentNode.parentNode.rowIndex;
        document.getElementById(tableId).deleteRow(row);
    });
}

function insertRowBefore(tableId, sel, studentGroupId) {
    var selectedIndex = sel.selectedIndex;
    var selectedValue = sel.options[selectedIndex].value;
    var selectedText = sel.options[selectedIndex].text;

    if (selectedIndex == 0) {
        return;
    }

    $.get("/studentgroup/student/add/" + studentGroupId + "/" + selectedValue, function(data) {
        displayNotifications(data);

        if (!data.success) {
            sel.selectedIndex = 0;
            return;
        }

        var row = sel.parentNode.parentNode.rowIndex;
        var x = document.getElementById(tableId).insertRow(row);
        var rowId = "insrow_" + selectedValue;
        // do better jquery duplication logic here
        var rowHTML = "\
<td>\n\
<div class='icon-button-small icon-button ui-state-default ui-corner-all' \n\
     title='Remove' onClick='deleteRow(\"student_group_members\", this, \"/studentgroup/student/remove/" + studentGroupId + "/" + selectedValue + "\")'>\n\
    <span class='ui-icon ui-icon-close'></span>\n\
</div>\n\
</td>\n\
<td><a href='/student/" + selectedValue + "'>" + selectedText + "</a></td>";

        x.id = rowId; // set the ID of the row so we can look it up below using jQuery
        $("#" + rowId).html(rowHTML);
        addHover();
        sel.selectedIndex = 0;
    });
}

function insertRowAfter() {
    var $this = $(this),
        myRow = $this.closest('.demeritRow');

    if ($this.hasClass('disabled')) return;

    var parent = myRow.parent(),
        newId = parent.find('.demeritRow').length,
        clonedRow = myRow.clone(),
        name = clonedRow.find('[name*=_]:first').attr('name'),
        matches = name.match(/^(a|ytgb|yagb)\[(\d+)\].*$/),
        prefix = matches && matches.length ? matches[1] : '';

    if(!matches || !matches.length) { return; }
    var oldId = matches[2];

    // double-check the newId in case rows got deleted
    while (parent.find('[name*="' + prefix + '\\[' + newId + '\\]"]').length) {
        newId++;
    }

    clonedRow.html(clonedRow.html().replace(new RegExp(prefix + "\\[" + oldId + "\\]", "g"), prefix + '[' + newId + ']'));
    clonedRow.find('input[type!=hidden]').each(function() {$(this).val($(this).attr('default'));});
    clonedRow.find('option[selected]').prop('selected', false);

    if (clonedRow.find('select[name*=student_id]').val() == 0) {
        clonedRow.find('td[id]').html(''); // remove fields that get filled in on demand
    }

//    clonedRow.find('div:first a:first').html(''); // remove student name
    clonedRow.find('.ui-state-hover').removeClass('ui-state-hover');
    clonedRow.find('div.hjsel_select').remove();
    clonedRow.find('div.hjsel_output').remove();
    clonedRow.find('button.ui-multiselect').remove();
    resetTssSelects(clonedRow);
    myRow.css('border-bottom', 'none');
    myRow.after(clonedRow);
    clonedRow.each(setupUI);

    if ($('form[name="demerits"]').length) {
        clonedRow.find('select[name*="demerit_type_id"]').each(showDemeritAttrs);
    }

    addHover();
}

function resetTssSelects (row) {
    var containers = row.find(".tss-select-search, .tss-multiselect-search");
    containers.each(function (i, container) {
        var select, container = $(container);
        select = container.find("select");
        select.removeClass("is-tss-select-search is-tss-multiselect-search");
        container.replaceWith(select);
    });
}

function selectStudentName(event, ui) {
    $(event.target).closest('.demeritRow').find('[name*="student_id"]').val(ui.item.id);
}

function showDemeritAttrs() {
    var $this = $(this);
    var val = $this.val();
    var myRow = $this.closest('.demeritRow');
    var prevVals = myRow.data('vals');
    var newVals = myRow.find('input[type="hidden"][name*="\\[demerit_type_id\\]\\[\\]"]').map(function() { return $(this).val(); }).get();

    if (val) {
        newVals.push(val);
    }

    // get a sorted array of all previously selected demerit types
    // plus the one they just chose. if there are any differences from the last
    // time we were here then update the UI, else just return.
    newVals = _.sortBy(newVals);
    if (_.isEqual(prevVals, newVals)) {
        return; // no changes. short-circuit.
    }

    // cache for next time so we can detect changes
    // store undefined instead of empty array so we make sure to handle the
    // starting state properly
    if (newVals.length) {
        myRow.data('vals', newVals);
    } else {
        myRow.removeData('vals');
    }

    var hasOneShowMult = false;

    _.each(newVals, function(oneVal) {
        hasOneShowMult = hasOneShowMult
            || $this.find('option[value="' + oneVal + '"][showmult]').length;
    });

    if (hasOneShowMult) {
        myRow.find('[name*="multiplier"], label[for="multiplier"]').fadeIn(500);
    } else {
        myRow.find('[name*="multiplier"], label[for="multiplier"]').val(1).hide();
    }

    myRow.find("[attrtype]").each(function() {
        var attrSelect = $(this);

        if (attrSelect.is('[multiple]')) {
            try { attrSelect.multiselect('getButton').hide(); } catch(ex) {}
        } else {
            var attrKey = attrSelect.attr('name').replace(/]/g, '\\]').replace(/\[/g, '\\[');
            myRow.find('.hjsel_select[for="' + attrKey + '"]').hide();
        }
    });
    myRow.find('[attrtype="' + val + '"][multiple]').multiselect('getButton').hide().fadeIn(500);
    myRow.find('[attrtype="' + val + '"][multiple!="multiple"]').prevAll('.hjsel_select:first').hide().fadeIn(500);
}

function removeRow() {
    var myRow = $(this).closest('.demeritRow');

    if (!myRow.hasClass('deactivated')) {
        myRow.addClass('deactivated');
        myRow.find('input[type!=hidden]').each(function() {$(this).val($(this).attr('default'));});
        myRow.find('option[selected]').prop('selected', false);

        if (myRow.find('.ui-icon-closethick').length) {
            myRow.find('input, select, button').attr('disabled', 'disabled');
            myRow.find('.ui-icon:not(.ui-icon-closethick)').addClass('ui-state-disabled').parent().removeAttr('tabindex').addClass('ui-state-disabled');
            myRow.find('.ui-icon-closethick').removeClass('ui-icon-closethick').addClass('ui-icon-check').each(function () {
                $(this).parent().attr('title', $(this).parent().attr('title').replace('Remove Row', 'Re-add Row'));
            });
        } else {
            myRow.find('input, select, button:not(.remove)').attr('disabled', 'disabled');
            myRow.find('button:disabled:not(.remove)').addClass('disabled');

            var removeBtn = myRow.find('.btn.remove');
            removeBtn.find('i').removeClass('icon-remove').addClass('icon-ok');
            removeBtn.attr('title', removeBtn.attr('title').replace('Remove Row', 'Re-add Row'));
        }

        myRow.find('select[multiple="multiple"]').each(function() {
            $(this).multiselect('uncheckAll');
            $(this).multiselect('disable');
        });
    } else {
        myRow.removeClass('deactivated');

        if (myRow.find('.ui-icon-check').length) {
            myRow.find('input, select, button').removeAttr('disabled');
            myRow.find('.ui-icon:not(.ui-icon-check)').removeClass('ui-state-disabled').parent().attr('tabindex', '0').removeClass('ui-state-disabled');
            myRow.find('.ui-icon-check').removeClass('ui-icon-check').addClass('ui-icon-closethick').each(function () {
                $(this).parent().attr('title', $(this).parent().attr('title').replace('Re-add Row', 'Remove Row'));
            });
        } else {
            myRow.find('input, select, button:not(.remove)').removeAttr('disabled');
            myRow.find('button:not(.remove)').removeClass('disabled');

            var removeBtn = myRow.find('.btn.remove');
            removeBtn.find('i').removeClass('icon-ok').addClass('icon-remove');
            removeBtn.attr('title', removeBtn.attr('title').replace('Re-add Row', 'Remove Row'));
        }

        myRow.find('select[multiple="multiple"]').each(function() {
            $(this).multiselect('enable');
        });
    }
}

function fillDown() {
    var elem = this;
    var myRow = $(elem).closest('.demeritRow');
    if (myRow.hasClass('deactivated')) return;

    var inputs = myRow.find('[name^="a\\["], input[for^="a\\["]');
    var all = myRow.parent().parent().find('.demeritRow:not(.deactivated)');
    var index = all.index(myRow);
    var next = all.eq(index + 1);
    var rows = next.nextAll(':not(.deactivated)').andSelf();
    var myId = myRow.find('input[type="hidden"][name*="student_id"]');
    var allIds = myRow.parent().parent().find('input[type="hidden"][name*="student_id"][value="' + myId.val() + '"]');
    var myIndex = allIds.index(myId);
    var d = false; // debug, turn on logging

    if (myIndex == -1) {
        myId = myRow.find('select[name*="student_id"]');
        allIds = myRow.parent().parent().find('select[name*="student_id"]');
        myIndex = allIds.index(myId);
    }

    if (d) log(myId);
    if (d) log(allIds);
    if (d) log("my index: " + allIds.index(myId));
    if (myIndex > 0) {
        rows.each(function() {
            var thisId = $(this).find('input[type="hidden"][name*="student_id"]');
            var rowIds = $(this).parent().parent().find('input[type="hidden"][name*="student_id"][value="' + thisId.val() + '"]');

            if (d) log("allIds.length: " + allIds.length);
            if (rowIds.length < allIds.length) {
                $(this).find('.add').click();
            }
        });
    }

    all = myRow.parent().parent().find('.demeritRow:not(.deactivated)');
    index = all.index(myRow);
    rows = $();

    for (var i = index + 1; i < all.length; i++) {
        var thisRow = all.eq(i);
        var thisId = thisRow.find('input[type="hidden"][name*="student_id"]');
        var rowIds = thisRow.parent().parent().find('input[type="hidden"][name*="student_id"][value="' + thisId.val() + '"]');
        var thisIndex = rowIds.index(thisId);

        if (d) log("thisIndex: " + thisIndex);
        if (thisIndex == myIndex) {
            rows = $(rows).add(thisRow);
        }
    }

    if (d) log(rows);
    inputs.each(function() {
        var name = $(this).attr('name');
        var attr = '[name';

        if (!name) {
            return true; // continue;
            name = $(this).attr('for');
            attr = 'input[for';
        }

        var matches = name.match(/^a\[\d+\](?:\[attrs\])?\[([^\]]*)\](?:\[\])?$/);
        var field = matches[1];
        var v = $(this).val();
        var rowInputs = rows.find(attr + '*="' + field + '"][type!=hidden]');

        if (d && rowInputs.length) log('setting ' + attr + '*="' + field + '"][type!=hidden] = ' + v);

        rowInputs.each(function() {
            if (v != $(this).val()) {
                $(this).val(v).change();
            }
            if ($(this).is('select') && $(this).attr('multiple')) {
                $(this).multiselect('refresh');
            }
        });
    });
}

function setupAddAndClear(name) {
    name = name || 'student_id';
    $('body')
        .addClass('addAndClear')
        .off('change', 'select[name*="' + name + '"]:not(.no-add-and-clear)')
        .on('change', 'select[name*="' + name + '"]:not(.no-add-and-clear)', addAndClear)
        .off('blur', 'select[name*="' + name + '"]:not(.no-add-and-clear)')
        .on('blur', 'select[name*="' + name + '"]:not(.no-add-and-clear)', function() { $(this).val([]); }); // for mobile

    $('body')
        .off('keydown', '.hjsel_select[for*="' + name + '"] input')
        .on('keydown', '.hjsel_select[for*="' + name + '"] input', function(e) {
            if (e.keyCode == 13) {
                return stopPropagation(e);
            }
        });
    $('select[name*="' + name + 'id"] option[value=""]:not(:nth-child(1))').remove();

    if (name == 'student_id') {
        $('select[name*="' + name + '"] optgroup:not(:nth-child(1)) option').each(function() {
            var option = $(this);
            option.val('g_' + option.val());
        });
    }
}

/**
 * Support for creating a multi-select from a hyjacked select. Sweet.
 */
function addAndClear(e, data) {
    var $this = $(this),
        $tssSelectSearch = $this.closest('.tss-select-search'),
        inTssSelectSearch = !!$tssSelectSearch.length,
        hjSelect = $this.prevAll('.hjsel_select'),
        input = hjSelect.find('input:first'),
        id = $this.val(),
        output = inTssSelectSearch
            ? $tssSelectSearch.parent().find('.hjsel_output')
            : $this.nextAll('.hjsel_output'),
        selectedOption = getSelectedOption($this),
        floatFill = $this.metadata()['float'],
        asof = $('.studentgroupMembers.date').val(),
        path = $this.data('path') || 'student',
        skipAbsences = $('input[name="skip_absences"]').val() == 1, // for the search page, don't care about absences so don't load them
        deactivateAutoCreated = $('input[name="auto_created_deactivated"]').val() == 1, // for the absences page so we will not update auto-created (OSS) kids by default
        deactivateAbsent = $('input[name="absent_active"]').val() != 1; // for the absences page so we will update absent kids by default

    if ((id == '' || id == '0') && !data) return false;

    if (!output.length) {
        output = $('<div/>').addClass('hjsel_output');
        if (inTssSelectSearch) {
            $tssSelectSearch.parent().append(output);
        } else {
            $this.after(output);
        }
    }

    var style = (floatFill && !isMobile() ? 'float: left' : 'display: block; clear: both')
            + "; margin-right: 27px; margin-bottom: 2px;",
        name = selectedOption.text(),
        removeButton = '<div tabindex="0" class="icon-button icon-button-small ui-state-default ui-corner-all removeParent" '
            + 'title="Remove" style="margin-right: 3px;"><span class="ui-icon ui-icon-close">'
            +'</span></div>';
    if (data) {
        addGroup('', data.name, data.students, $this, removeButton, style, deactivateAbsent, deactivateAutoCreated, output);
    } else if (id.length > 2 && id.substr(0, 2) == 'g_') { // this is a group/grade level
        id = id.replace('g_', '');

        $.post('/studentgroup/members/' + id + (asof ? '/' + dateStringToServer(asof) : ''), function(data) {
            addGroup(id, name, data, $this, removeButton, style, deactivateAbsent, deactivateAutoCreated, output);
        });
    } else if (path == 'nolink') {
        output.append('<span class="linkBox clearChildren" '
            + 'style="' + style + '">'
            + removeButton
            + '<input type="hidden" name="' + $this.attr('name') + '" value="' + id + '">'
            + '<a href="#" onclick="return false;" style="vertical-align: middle">'
            + name + '</a></span>');
        addHover();

        $this.trigger('studentChange');
    } else if (!skipAbsences && path == 'student') {
        $.post('/student/info/' + id + (asof ? '/' + dateStringToServer(asof) : ''), function(data) {
            if (!data || !data.success) return;

            var res = data.results,
                absence = res.absence,
                outOfSchool = (absence && res.in_school == 0),
                autoCreated = (absence && res.auto_created),
                deactivated = outOfSchool && (deactivateAbsent || (autoCreated && deactivateAutoCreated)),
                showAbsence = absence && (outOfSchool || !deactivateAbsent);

            output.append('<span class="linkBox clearChildren' + (deactivated ? ' deactivated' : '') + '" '
                + 'style="' + style + '">'
                + (deactivated
                    ? removeButton.replace('-close', '-check').replace('removeParent', 'addParent').replace('Remove', 'Include')
                    : removeButton)
                + '<input type="hidden" name="' + $this.attr('name') + '" ' + (deactivated ? 'data-' : '') + 'value="' + id + '">'
                + '<a href="/student/' + id + '" tooltip style="vertical-align: middle">'
                + name + (showAbsence ? ' [' + absence + ']' : '') + '</a></span>');
            addHover();

            $this.trigger('studentChange');
        });
    } else {
        output.append('<span class="linkBox clearChildren" '
            + 'style="' + style + '">'
            + removeButton
            + '<input type="hidden" name="' + $this.attr('name') + '" value="' + id + '">'
            + '<a href="/' + path + '/' + id + '" tooltip style="vertical-align: middle">'
            + name + '</a></span>');
        addHover();

        $this.trigger('studentChange');
    }

    $this.val('');
    input.val('');
    hjSelect.find('li').removeClass('hjsel_options_hover');

    return stopPropagation(e);
}

function addGroup(id, name, data, select, removeButton, style, deactivateAbsent, deactivateAutoCreated, output) {
    var expandButton = '<div tabindex="0" class="icon-button icon-button-small ui-state-default ui-corner-all expandGroup" '
            + 'title="Explode" style="margin-right: 3px;"><span class="ui-icon ui-icon-plus">'
            +'</span></div>',
        str = '<span class="linkBox clearChildren" style="' + style + '">' + removeButton + expandButton;

    $.each(data, function(i, v) {
        var outOfSchool = (v.absence && v.in_school == 0),
            autoCreated = (v.absence && v.auto_created),
            deactivated = ((outOfSchool && (deactivateAbsent || (autoCreated && deactivateAutoCreated))) || v.deactivated);

        str += '<input type="hidden" '
            + 'name="' + select.attr('name') + '" '
            + (deactivated ? 'data-' : '') + 'value="' + v.student_id + '" '
            + 'data-deactivated="' + (v.deactivated || '') + '" '
            + 'data-absence="' + (v.absence || '') + '" '
            + 'data-in_school="' + (v.in_school || '') + '" '
            + 'data-auto_created="' + (v.auto_created || '') + '" '
            + 'data-display_name="' + v.display_name + '">';
    });

    str += '<a href="/studentgroup/' + id + '" style="vertical-align: middle">' + name + '</a></span>';
    output.append(str);
    addHover();

    $('select[name*="student_id"]').trigger('studentChange');
}

function expandGroup(callback) {
    return function() {
        var button = $(this),
            parent = button.parent(),
            parentIndex = parent.index(),
            elem = parent,
            row = parent.closest('tr'),
            deactivateAutoCreated = $('input[name="auto_created_deactivated"]').val() == 1,
            deactivateAbsent = $('input[name="absent_active"]').val() != 1;

        parent.find('input').each(function() {
            var input = $(this),
                removeButton = input.prevAll('.removeParent:first'),
                buttonHtml = removeButton[0].outerHTML,
                id = input.val() || input.data('value'),
                displayName = input.data('display_name'),
                absence = input.data('absence'),
                autoCreated = input.data('auto_created'),
                outOfSchool = (absence && input.data('in_school') == 0),
                deactivated = ((outOfSchool && (deactivateAbsent || (autoCreated && deactivateAutoCreated))) || input.data('deactivated') == 1),
                showAbsence = absence && (outOfSchool || !deactivateAbsent), // only on the absence page or if they're absent
                elemIndex = elem.index(),
                newElem = $('<span>' + (deactivated
                    ? buttonHtml.replace('-close', '-check').replace('removeParent', 'addParent').replace('Remove', 'Include')
                    : buttonHtml)
                    + input[0].outerHTML
                    + '<a href="/student/' + id + '" tooltip style="vertical-align: middle">'
                    + displayName + (showAbsence ? ' [' + absence + ']' : '') + '</a></span>')
                    .attr('style', parent.attr('style'))
                    .attr('class', parent.attr('class') + (deactivated ? ' deactivated' : ''));

            input.remove();
            elem.after(newElem);
            elem = newElem;

            if (callback) {
                callback.call(null, id, row, parentIndex, elemIndex);
            }
        });

        parent.find('.removeParent').click();
    }
}

function removeParent() {
    $(this).parent().fadeOut(200, function() {
        $(this).remove();
        $('select[name*="student_id"]').trigger('studentChange', 'remove');
    });
}

function addParent() {
    var input = $(this).removeClass('addParent').addClass('removeParent')//.attr('title', 'Remove')
        .find('.ui-icon-check').removeClass('ui-icon-check').addClass('ui-icon-close')
        .closest('.deactivated').removeClass('deactivated')
        .find('input[name*="student_id"]');

    input.attr('value', input.data('value'));
    $('select[name*="student_id"]').trigger('studentChange');
}

function getFilters(container, full) {
    container = container || $('.filterContainer');

    var filters = {},
        ignoreFilter = function(i, v) { return $(v).closest('.filterIgnore').length == 0; };

    $('input[name]:not(:radio), input[name]:radio:checked, select', container).filter(ignoreFilter).each(function() {
        var input = $(this),
            key = input.attr('name'),
            value = input.val();

        if (input.parents('.multi').length) {
            if ((!input.is(':checkbox') || input.is(':checked')) && value !== '') {
                if (!(key in filters)) filters[key] = [];
                filters[key].push(value);
            }
        } 
        else if (input.parents('.slider[multiple]').length) {
            filters[key] = value.split(',');
        } 
        else if (input.is('[multiple]')) {
            filters[key] = value || input.find('option').map(function() { return $(this).val(); }).get() || [];
        } 
        else if (value && input.attr('type') == 'date') {
            filters[key] = dateStringToServer(value); // is this right?
        } 
        else if (value) {
            filters[key] = value;
        } 
        // Handling data-include="1"
        // If a <select> has this attribute, it forces selection of the first available option
        // even if the user has not selected anything.
        else if (input.is('select[data-include="1"]')) { 
            filters[key] = input.find('option[value!=""]').first().attr('value');
        }
    });

    if (full) return filters;

    $('.multi', container).filter(ignoreFilter).each(function() {
        var multi = $(this),
            checkboxes = $(":checkbox", multi),
            numCheckboxes = checkboxes.length,
            numSelected = $(":checkbox:checked", multi).length,
            name = $(':checkbox:first', multi).attr('name');

        // If there is another multi with the same name that also has
        // checkboxes selected, don't do the check to remove the filter
        if ($("[name=\"" + name + "\"]:checked").not(checkboxes).length) {
            return;
        }

        // If multi is select none or all, no need to apply filter
        if (!numSelected || numSelected == numCheckboxes) {
            delete filters[name];
        }
    });

    $('select[multiple][data-include!="1"]', container).filter(ignoreFilter).each(function() {
        var select = $(this),
            totalOptions = $('option', select).length,
            vals = select.val(),
            numSelected = vals ? vals.length : 0,
            name = select.attr('name'),
            includeAllOnly = select.data('include') == 'all-only';

        // Handling data-include="all-only"
        // If a <select multiple> does NOT have data-include="1", it follows these rules:
        // - If no options are selected, the filter is removed.
        // - If all options are selected, the filter is removed UNLESS data-include="all-only" is present.
        if (!numSelected || (numSelected == totalOptions && !includeAllOnly)) {
            delete filters[name];
        }
    });

    $('.slider[multiple]', container).filter(ignoreFilter).each(function() {
        var slider = $(this),
            min = slider.slider('option', 'min'),
            max = slider.slider('option', 'max'),
            name = slider.find('input[name]').attr('name'),
            vals = filters[name],
            staticRange = slider.data("staticRange"),
            ignoreStart = staticRange ? false : vals[0] == min,
            ignoreEnd = staticRange ? false : vals[1] == max;

        if (ignoreStart && ignoreEnd) {
            delete filters[name];
            return true; // continue
        } 
        else if (slider.data('filter_type') == 'date') {
            filters[name][0] = dateStringToServer(new Date(parseInt(filters[name][0])));
            filters[name][1] = dateStringToServer(new Date(parseInt(filters[name][1])));
        }

        if (ignoreStart) filters[name][0] = '';
        if (ignoreEnd) filters[name][1] = '';
    });

    return filters;
}

window.filteringOff = false;

function filter(filterFunctions, serverSide, parent, container, requireFilters) {
    if (window.filteringOff) return null;

    parent = parent || $('body');
    container = container || '.filterContainer';
//    time('filter');

    var resultSet = [],
        notResultSet = [],
        uppered = {},
        filterContainer = parent.find(container),
        filters = getFilters(filterContainer),
        filterState = getFilterState(filters),
        filterStr = filterState.join('&');

    if (!filterContainer.length) return;

    //figure out if we have any worthwhile filters
    if(requireFilters) {
        var numFilters = _.keys(filters).length;

        if(filters['school_id']) {
            numFilters--;
        }
        if(!_.isUndefined(filters['active']) && filters['active'] == '1') {
            numFilters--;
        }

        if(numFilters < 1) { return; }
    }

    $('input[name="filter"]').val(filterStr);
//        pushState(filterState, title, getStatefulURL(state, NULL, )); // pop isn't going to work

    if (serverSide) {
        var knownIds = parent.find('.result').map(function() { return rowIdToId($(this)); }).get().join(',');
        $.post(serverSide, {filter: filterStr, known_ids: knownIds}, function(data) {
            handleFilterResponse(data, parent);
        });

        return;
    }

    $('#results_div .result').each(function() {
        var result = $(this);
        var display = '';

        $.each(filters, function(name, filter) {
            var exclude = false;
            var value = result.data(name);
            var input = $('input[name="' + name + '"]');

            value = safeUpper(value);

            if (filterFunctions && name in filterFunctions) {
                exclude = filterFunctions[name].call(result, filters, name);
            } else if ($.isArray(filter)) {
                if ($.isPlainObject(value)) {
                    value = Object.keys(value); // simple filter based on keys array
                }

                if ($.isArray(value)) { // set intersect, used for groups, can be in multiple, filter by multiple
                    var testMap = {};
                    $.each(value, function(i, v) {
                        testMap[v] = 1;
                    });
                    exclude = true;
                    $.each(filter, function(i, v) {
                        if (testMap[v]) {
                            exclude = false;
                            return false;
                        }
                    });
                } else if (input.parents('.slider').length) {
                    exclude = !((!filter[0] || (value || 0) >= filter[0]) && (!filter[1] || (value || 0) <= filter[1])); // not between
                } else {
                    if (!uppered[name]) {
                        filter = filters[name] = $.map(filter, safeUpper);
                        uppered[name] = true;
                    }
                    exclude = $.inArray((value || 0) + '', filter) < 0;
                }
            } else if ($.isArray(value)) {
                exclude = $.inArray(parseInt(filter), value) < 0;
            } else {
                var re = new RegExp(filter, 'i');

                exclude = (name.match(/(_id$|^has_|active)/) || value === undefined || !value.match ? (value || 0) != filter : !value.match(re));
            }

            if (exclude) {
                display = 'none';
                return false;
            }
        });

        if (display == '') {
            resultSet.push(this);
        } else {
            notResultSet.push(this);
        }
    });

    resultSet = $(resultSet);
    notResultSet = $(notResultSet);

    $('.numResults').html(resultSet.length);

    resultSet.show();
    resultSet.first().parents().show();
    notResultSet.hide();

//    timeEnd('filter');

    return resultSet;
}

function handleFilterResponse(data, parent, replace) {
    if (!data || !data.results || !data.results.ids) return;

    var resultSet = [],
        clazz = data.results['class'] || 'result',
        ids = data.results.ids,
        rows = data.results.rows,
        lastElem = null,
        table = parent.find('.resultsContainer'),
        template = table.data('template');
    table = table.length ? table : parent.find('.filtered-results');

    $.each(ids, function(i, id) {
        var row = rows ? rows[i] : null,
            elemId = '#' + clazz + '_' + id;

        if (row) {
            if (!$(elemId).length) {
                if (lastElem) {
                    lastElem.after(row);
                } else {
                    table.prepend(row);
                }
            } else if (replace) {
                $(elemId).replaceWith(row);
            }
        }
        if ($(elemId).length) {
            lastElem = $(elemId);
            resultSet.push(lastElem.get(0));
        } else if (template) {
            lastElem = $(Handlebars.renderTemplate(template, {
                id: id,
                results: data.results
            }));
            resultSet.push(lastElem.get(0));
        } else {
            log('trying to show an id that\'s not here: ' + elemId);
        }
    });

    parent.find('.result').remove(); // remove all
    $(resultSet).appendTo(table); // display results
    parent.find('.numResults').data('results', data.results).html(resultSet.length).first().trigger('filtered', data);
    parent.find('.empty-state')[resultSet.length > 0 ? 'hide' : 'show']();
    table.show().parent('table').each(tablesorter).trigger('update');
    addHover();

//    timeEnd('filter');
}

function rowIdToId(row) {
    return row.attr('id').replace(/^[^_]+_/, '');
}

function safeUpper(s) {
    return s && s.toUpperCase ? s.toUpperCase() : s;
}

function clearFilters() {
    var container = $('.filterContainer'), oldFilteringOff = window.filteringOff, multiSelects;

    window.filteringOff = true;

    $('input:not(:radio):not(:checkbox):not([data-noclear]), select:not([multiple]):not(.fixed)', container).val('');
    $('.hjsel_output', container).empty();

    multiSelects = $('select[multiple] option', container).prop('selected', true).parents('select');
    multiSelects.each(function () {
        var select = $(this);
        if (select.data("multiselect")) {
            select.multiselect("refresh");
        } else if (select.is(".is-tss-multiselect-search")) {
            select.trigger("refresh-options");
        }
    });

    $('input:checkbox', container).prop('checked', true).button('refresh');
    $('.slider').each(function() {
        var slider = $(this);
        var vals = [slider.slider('option', 'min'), slider.slider('option', 'max')];

        slider.find('input').val(vals);
        slider.slider('values', vals).slider('option', 'slide').call(slider, null, {values: vals}); // update the label but don't fire a filter event
    });

    var inputs = $('input:radio', container).prop('checked', false).filter('[value=""]').prop('checked', true).button('refresh');

    $('#main-form input[name="student_group_id"]').val('');
    $('#main-form input[name="group_name"]').val('');
    $('.editLabel').html('');
    $('#saveCopy').hide();

    window.filteringOff = oldFilteringOff;

    return inputs;
}

function getFilterState(filters) {
    filters = filters || getFilters();

    var state = [],
        filterMap = getFilterMap(filters);

    $.map(filterMap, function(v, k) { state.push(k + '=' + v); });

    return state;
}

function getFilterMap(filters, toObj) {
    var map = {};

    $.each(filters, function(name, filter) {
        var value = ($.isArray(filter) ? JSON.stringify(filter) : filter);

        if (toObj) {
            map[cleanKey(name)] = {value: value, key: name};
        } else {
            map[name] = value;
        }
    });

    return map;
}

function getFilterString(container) {
    container = container || $('.filterContainer');
    var filterState = getFilterState(getFilters(container)),
        cleanFilterState = $.map(filterState, cleanKey);

    return cleanFilterState.join('&')
}

function cleanKey(key) {
    return key.replace(/^(\d+_)*/, '');
}


/**
 * for finding dirty key
 */
function nameFilter(key) {
    var pattern = new RegExp("^(\\d+_)*" + replaceBrackets(key), 'i');

    return function() { return pattern.test(this.name); }
}

function getFilterDisplay(filterState, defaultFilterMap) {
    defaultFilterMap = defaultFilterMap || {};

    var seenKeys = {},
        parts = [];

    $.each(filterState, function(i, v) {
        var keyAndValue = v.split('='),
            key = keyAndValue[0],
            value = keyAndValue[1],
            cleanKeyStr = cleanKey(key);

        seenKeys[cleanKeyStr] = true;
        if (cleanKeyStr in defaultFilterMap && value === defaultFilterMap[cleanKeyStr].value) return true; // continue

        parts.push(getFilterDisplayValue(key, value));
    });

    $.each(defaultFilterMap, function(cleanKey, o) {
        if (cleanKey in seenKeys) return true; // continue;

        // we found a default that wasn't in the filters so make it show up
        parts.push(getFilterDisplayValue(o.key, 'All'));
    });

    return _.filter(parts);
}

function findLabelForInput(input) {
    var labelSelectors = '.hjsel_title, label:not(.ui-button), .label';
    var elem = input;
    var label = $();

    while (!label.length) {
        elem = elem.parent();

        if (!elem.length) {
            break;
        }

        label = elem.find(labelSelectors).first();
    }

    return label;
}

function getFilterDisplayValue(key, value, filterContainer) {
    if (!key) return;

    if (value && value.length && value[0] == '[') {
        value = JSON.parse(value);
    }

    if (key.match(/date/)) { // FIXME should be based on filter_type, no?
        value = $.map(value, function(v) { return dateStringToInput(v); });
    }

    filterContainer = filterContainer || $('.filterContainer');
    var input = $('[name="' + key + '"]', filterContainer).first();
    var label = findLabelForInput(input);
    var hiddenInputDisplayValue = input.is('[type="hidden"][value="' + value + '"]') ? input.data('display_name') : null;
    var selectedOption = input.find('option[value="' + value + '"]');
    var displayValue = hiddenInputDisplayValue || selectedOption.text();

    if (!displayValue && $.isArray(value)) {
        if (input.parents('.slider').length && value.length == 2) {
            if (value[0] == value[1]) {
                displayValue = value[0];
            } else if (value[0] === '') {
                displayValue = 'no more than ' + value[1];
            } else if (value[1] === '') {
                displayValue = 'at least ' + value[0];
            } else {
                displayValue = 'between ' + value[0] + ' and ' + value[1];
            }
        } else if (input.find('option[value="' + value[0] + '"]').length) {
            var hasComma = false;
            displayValue = $.map(value, function(v) {
                var opt = input.find('option[value="' + v + '"]'), str = opt.length ? opt.text() : v;
                hasComma |= (str.indexOf(',') != -1);
                return str;
            }).filter(function(v) { return v; }).join((hasComma ? ';' : ',') + ' ');
        } else {
            displayValue = $.map(value, function(v) {
                return $('[name="' + key + '"][value="' + v + '"]:first').next('label').text() || v;
            }).join(', ');
        }
    }

    if (!displayValue) {
        var valueInput = $('[name="' + key + '"][value="' + value + '"]', filterContainer);

        displayValue = $('label[for="' + valueInput.attr('id') + '"]', filterContainer).text();
    }

    if (!displayValue) {
        displayValue = $.isArray(value) ? value.join(', ') : value;
    }

    return {label: (label.length ? label.text() : key), value: displayValue};
}

function updateVisibleGradeLevels(gradeLevelNameToSchoolIds, elem) {
    return function() {
        var gradeLevels = elem.find('.grade_level_name .ui-button'),
            schoolIds = $(this).val(),
            toHide = [];

        gradeLevels.each(function() {
            var label = $(this),
                gradeLevelName = label.text(),
                schoolIdsForGradeLevel = gradeLevelNameToSchoolIds[gradeLevelName],
                visible = !schoolIds || _.intersection(schoolIds, schoolIdsForGradeLevel).length;

            if (label.is(':visible') && !visible) {
                toHide.push(gradeLevelName);
            }

            label[visible ? 'show' : 'hide']();
        });

        if (toHide.length) {
            var selectedGradeLevels = getFilters(elem, /*full = */true)['grade_level_name'];

            if (_.intersection(selectedGradeLevels, toHide).length) {
                fillInForm('grade_level_name', _.difference(selectedGradeLevels, toHide), elem);
            }
        }

        updateEqualWidthFields();
    };
}

function time(key) {
    if (console && console.time) {
        console.time(key);
    }
}

function timeEnd(key) {
    if (console && console.timeEnd) {
        console.timeEnd(key);
    }
}

function setupSlider(slider, sliderLabel, name, data, displayFunc) {
    var filterType = slider.data('filter_type'),
        isDateSlider = filterType == 'date',
        isCashSlider = filterType == 'cash';

    if (!data) {
        var range = slider.data('range');
        if (range) {
            if (isDateSlider) {
                data = $.map(range, function(v) { return dateStringToUTCDate(v); });
            } else {
                data = range;
            }
        } else {
            if (isDateSlider) {
                data = $('.result').map(function() { return dateStringToUTCDate($(this).data(name)); });
            } else {
                data = $('.result').map(function() { return parseFloat($(this).data(name)); });
            }

            data = $.grep(data.get(), function(n) { return n; }); // array filter, remove false values
        }
    }

    if (!displayFunc) {
        if (isDateSlider) {
            displayFunc = function (v1, v2) {
                $(this).find("input.slider-input.slider-min").val($.datepicker.formatDate("yy-mm-dd", new Date(v1)));
                $(this).find("input.slider-input.slider-max").val($.datepicker.formatDate("yy-mm-dd", new Date(v2)));
            }
        }
        // else if (isCashSlider) {
        //     // TODO: cash is really just a number.
        //     // is there a way to display this better in an input
        //     displayFunc = function (v1, v2) {
        //         $(this).find("input.slider-input.slider-min").val(Tss.Number.toMoney(parseFloat(v1), 0));
        //         $(this).find("input.slider-input.slider-max").val(Tss.Number.toMoney(parseFloat(v2), 0));
        //     }
        // }
        else {
            displayFunc = function (v1, v2) {
                $(this).find("input.slider-input.slider-min").val(v1);
                $(this).find("input.slider-input.slider-max").val(v2);
            }
        }
    }

    var min = Math.min.apply(Math, data),
        max = Math.max.apply(Math, data),
        rangeVals = [min || 0, max || 0],
        input = slider.find('input[name="' + name + '"]'),
        origVal = input.val(),
        vals = rangeVals;

    if (origVal) {
        if (isDateSlider) {
            vals = $.map(origVal.split(','), function(v) { return dateStringToUTCDate(v).getTime(); });
        } else {
            vals = origVal.split(',');
        }
    }

    // this == sliderLabel == div.sliderLabel which contains inputs
    // v1, v2 = [...vals] which is min,max
    displayFunc.apply(sliderLabel, vals);
    input.val(vals);

    var opts = {
        range: true,
        min: rangeVals[0],
        max: rangeVals[1],
        values: vals,
        slide: function(e, ui) {
            var vals = ui.values;

            displayFunc.apply(sliderLabel, vals);
        },
        change: function(e, ui) {
            var vals = ui.values;

            if (input.val() != vals) {
                input.val(vals).change();
                displayFunc.apply(sliderLabel, vals);
            }
        }
    };

    if (isDateSlider) {
        opts['step'] = 1000 * 60 * 60 * 24;
    } else if (slider.data('step')) {
        opts['step'] = parseFloat(slider.data('step'));
    }

    slider.attr('multiple', 'true').slider(opts);

    var widget = slider.slider('widget'),
        parent = widget.wrap('<div class="sliderWrapper"/>').parent();

    if (isDateSlider) {
        var minDate = $.datepicker.formatDate("yy-mm-dd", new Date(opts.values[0]));
        var maxDate = $.datepicker.formatDate("yy-mm-dd", new Date(opts.values[1]));

        var minInput = `<input class="slider-input slider-min date" type="date" value="${minDate}" title="${minDate}" data-index="0"/>`;
        var separator = `<span>&nbsp;to&nbsp;</span>`;
        var maxInput = `<input class="slider-input slider-max date" type="date" value="${maxDate}" title="${maxDate}" data-index="1"/>`;
        Bindings.setupUIBindings();

    } else {
        var minInput = `<input class="slider-input slider-min" type="number" value="${opts.values[0]}" title="${opts.values[0]}" data-index="0"/>`;
        var separator = `<span>&nbsp;to&nbsp;</span>`;
        var maxInput = `<input class="slider-input slider-max" type="number" value="${opts.values[1]}" title="${opts.values[1]}" data-index="1"/>`;
    }

    sliderLabel.append(minInput, [separator, maxInput]);

    // input makes dates really hard
    // $('.slider-input').off('input').on('input', (function (e) {
    $('.slider-input').off('change').on('change', (function (e) {
        var $this = $(this);

        let newVal = $this.val();
        if ($this.attr('type') == 'date') {
            const newDate = new Date(newVal);
            newVal = parseInt(newDate.getTime() + newDate.getTimezoneOffset() * 60000);
        }

        // inputs are inside a sliderLabel which is a sibling of a sliderWrapper which holds a .ui-slider
        let slider = $this.parent().next().find('.ui-slider');
        let index = $this.data("index");
        if (index == 0 && newVal < slider.slider("option", "min")) {
            slider.slider("option", "min", newVal);
        }
        if (index == 1 && newVal > slider.slider("option", "max")) {
            slider.slider("option", "max", newVal);
        }
        slider.slider("values", index, newVal);
    }));


    if (isDateSlider) {
        Dates.loadTermBins();
    }
}

function addHover() {
    $('.icon-button').hover(
        function() {
            if ($(this).hasClass('ui-state-disabled')) return;
            $(this).addClass('ui-state-hover');
            $(this).children('.ui-icon').addClass('ui-state-hover');
        },
        function() {
            $(this).removeClass('ui-state-hover');
            $(this).children('.ui-icon').removeClass('ui-state-hover');
        }
    );

    $('.newnav > li').hover(
        function() {$(this).addClass('state-hover');},
        function() {$(this).removeClass('state-hover');}
    );

    $('.newnav ul').parents('li').hover(
        function() {
            $(this).addClass('state-hover');
            if ($(this).find('ul').css('min-width') == "0px") {
                $(this).find('ul').css('min-width', $(this).width());
                $(this).find('ul').find('li span').append('&nbsp;&nbsp;&nbsp;'); // super hack because width=100% doesn't play nice with padding'
            }
        },
        function() {$(this).removeClass('state-hover');}
    );

    $('.tablesorter th').hover(
        function() {
            if ($(this).metadata().sorter != false) {
                $(this).addClass('state-hover');
            } else {
                $(this).css('cursor', 'default !important');
            }
        },
        function() {$(this).removeClass('state-hover');}
    );
}

function grabFocus(elem) {
    $('input[type="text"]:visible, input[type="number"]:visible', elem).first().focus();
}

function getStatusBox(title, msg, type) {
    var templateData = {
        prefix: title,
        message: msg,
        type: type
    };

    return Handlebars.renderTemplate('alert', templateData);
}

function serializeObject() {
    var arrayData, objectData;
    arrayData = $('#main-form').serializeArray();
    objectData = {};

    $.each(arrayData, function() {
        var value;

        if (this.value != null) {
            value = this.value;
        } else {
            value = '';
        }

        if (objectData[this.name] != null) {
            if (!objectData[this.name].push) {
                objectData[this.name] = [objectData[this.name]];
            }

            objectData[this.name].push(value);
        } else {
            objectData[this.name] = value;
        }
    });

    return objectData;
}

function serialize(elem, ignoreFields) {
    return $.param($(elem).serializeArray().sort(function(a, b) {
        return a.name == b.name ? 0 : (a.name < b.name ? -1 : 1);
    }).filter(function(v) { return $.inArray(v.name, ignoreFields) < 0; }));
}

function isChangedFrom(elem, orig, ignoreFields) {
    var current = serialize(elem, ignoreFields);
    var equals = (!current || orig === current);//(JSON.stringify(orig) === JSON.stringify(formAsObject));

    return !equals;
}

function removeSimpleChangeListener(elem) {
    elem = elem || '#main-form';

    $(elem).unbind('DOMSubtreeModified');
}

function setupSimpleChangeListener(elem) {
    elem = elem || '#main-form';

    // this fires A LOT! Doesn't just fire on move, also fires on click and other things
    $(elem).bind('DOMSubtreeModified', function() { // elements moved/inserted
        $(elem).data('dirty', true);
    });
}

function simpleSnapshotForChanges(elem) {
    elem = elem || '#main-form';

    $(elem).delegate('input, select', 'change', function() {
        $(elem).data('dirty', true);
    });
    setupSimpleChangeListener(elem);
    window.onbeforeunload = function() {
        if ($(elem).data('dirty')) {
            return "It looks like you have unsaved edits!";
        }
    };
}

/**
 * Ignore the next call to our onbeforeunload function. Used when clicking links that download files.
 */
function pauseOnBeforeUnload() {
    $(window).data('pauseOnBeforeUnload', true);
}

function snapshotForChanges(elem, ignoreFields) {
    elem = elem || '#main-form';

    if (!$(elem).length) return;

    var snapshot = serialize(elem, ignoreFields);

    window.onbeforeunload = function() {
        if ($(this).data('pauseOnBeforeUnload')) {
            $(this).data('pauseOnBeforeUnload', false);
            return null;
        }

        if (isChangedFrom(elem, snapshot, ignoreFields)) {
            return "It looks like you have unsaved edits!";
        }
    };

    // the only reason I'm not using this is that it gives a weird user experience
    // that sometimes you see this message and sometimes you see the browser-based one
//    $('a[href]').mousedown(function(e) {
//        var link = $(this);
//
//        if (!link.data('goahead') || isChangedFrom(snapshot)) {
//            if (!$('#saveConfirm').length) {
//                $('body').append('<div id="saveConfirm" style="display: none" '
//                    + 'title="Are you sure?"><p>'
//                    + '<span class="icon ui-icon-alert" style="float:left; margin:0 7px 4em 0;"></span>'
//                    + 'It looks like you have unsaved edits! Are you sure you want to leave this page?</p></div>');
//                $('#saveConfirm').dialog({
//                    modal: true,
//                    autoOpen: false,
//                    buttons: {
//                        'Go ahead': function() {
//                            $(this).dialog('close');
//                            window.onbeforeunload = null;
//                            window.location.assign(link.attr('href'));
//                        },
//                        'Cancel': function() {
//                            $(this).dialog('close');
//                        }
//                    }
//                });
//            }
//
//            // if the user navigates away from this page via an anchor link,
//            //    popup a confirmation dialog.
//            $('#saveConfirm').dialog('open');
//
//            return stopPropagation(e);
//        }
//
//        return true;
//    });
}

function confirmSave(onBeforeUnload, onSuccess) {
    var str = null;

    try {
        if (onBeforeUnload) {
            str = onBeforeUnload();
        }
    } catch (err) {}

    if (!str) {
        onSuccess();

        return true; // success
    }

    showConfirmDialog('saveConfirm', str + ' Are you sure you want to leave this page?', onSuccess);

    return false;
}

function showConfirmDialog(id, msg, onSuccess, options) {
    var elem = $('#' + id);

    if (!elem.length) {
        $('body').append('<div id="' + id + '" style="display: none" title="Are you sure?"><p>'
            + '<span class="icon ui-icon-alert" style="float:left; margin:0 7px .3em 0;"></span>'
            + '<span class="msg">' + msg + '</span>'
            + '</p></div>');
        elem = $('#' + id);
    } else if (msg) {
        elem.find('.msg').html(msg);
    }

    var windowWidth = $(window).width();
    elem.dialog($.extend({
        modal: true,
        autoOpen: false,
        width: windowWidth > 640 ? 600 : (windowWidth * 0.9),
        buttons: {
            'Cancel': function() {
                $(this).dialog('close');
            },
            'Go ahead': function() {
                $(this).dialog('close');
                onSuccess.call(this);
            }
        },
        open: function() {
            $(this).siblings('.ui-dialog-buttonpane').find('button:eq(1)').focus();
        }
    }, options));

    elem.dialog('open');
}

// set this as the click function for your submit button
// so it can be outside the form element if need be
function formSubmit(container) {
    return function(e) {
        e.preventDefault();
        var form = $(e.target, container).closest('form');
        if (!form.length) form = $('form:first', container);
        form.trigger('submit');
        return false;
    };
}

/**
 * If form has validation errors, highlight the fields with errors and scroll
 * to find the first visible one,
 * @return boolean valid
 */
function validateForm(form, e) {
    var valid = form.get(0).checkValidity() && !form.hasClass('invalid');

    if (!valid) {
        form.find('input').addClass('touched');
        form.find('select[required]').change();
        var invalidInputs = form.find('input:invalid, .error, .invalid');
        invalidInputs.closest('.is-tss-expandable').removeClass('closed'); // expand expandables
        invalidInputs.first().scrollintoview({
            yoffset: 250
        });

        if (e) {
            e.validationFailed = true;
        }

        Growl.error({message: 'Please fix validation errors and try again.'});
    }

    return valid;
}

/**
 * turns inputs like:
 *   <input name="section.number" value="10">
 *   <input name="attrs[ell_status][attr_id]" value="1234">
 * into an object like: {
 *   section: {
 *     number: '10'
 *   },
 *   attrs: {
 *     ell_status: {
 *       attr_id: '1234',
 *     }
 *   }
 * }
 */
function getFormData(form, includeDisabled) {
    var self = this;
    var formData = {};

    form.find('input,select').each(function() {
        var input = $(this);
        var name = input.attr('name');

        if (!name) {
            return true; // continue;
        }

        var value = input.val();

        // handle values that should be arrays
        if (name.endsWith('[]')) {
            name = name.substring(0, name.length - 2);

            var existingValue = formData[name];

            if (!existingValue) {
                value = _.isArray(value)
                    ? value
                    : [value];
            } else {
                value = existingValue.concat(value);
            }
        }

        if (input.hasClass('date')) {
            value = dateStringToServer(value);
        } else if (input.is('[type="checkbox"]')) {
            value = input.is(':checked') ? 1 : 0;
        }

        if (!input.is('[disabled]') || includeDisabled) {
            _.setWith(formData, name, value, Object);
        }
    });

    return formData;
}

// set this as the submit function of your form
// $('#main-form').submit(onSubmit('/communication/add'));
function onSubmit(postURL, callback, restAPIMethod, isCRUD) {
    return _.debounce(function(e, extraArgs) {
        var submitButtonSelector = 'input:submit, [rel="submit-form"]';
        $(submitButtonSelector).attr("disabled", "true").addClass('disabled');
        e.preventDefault();

        var url = postURL,
            cbFunc = callback,
            form = $(this);

        if (!form.is('form')) {
            form = form.closest('form');
            if (!form.length) form = $('#main-form'); // just in case
        }

        if (!validateForm(form, e)) {
            $(submitButtonSelector).removeAttr("disabled").removeClass('disabled');
            return false;
        }

        //remove errors
        Tss.Alerts.removeAllFormAlerts(form);
        form.find('.error').removeClass('error');

        // if a disabled form element has [data-include] then we want to
        // make sure its values get sent to the server. we have to do this
        // extra magic because by default disabled elements are ignored by
        // serialize().
        var disabledElemsToInclude = form.find('[disabled][data-include]').removeAttr('disabled');
        var data = form.serialize();
        disabledElemsToInclude.attr('disabled', 'true');

        // otherwise multi-selects with no selection don't get included in the
        // serialized params
        form.find('select[multiple]').each(function() {
            if (!$(this).val()) {
                data += '&' + encodeURIComponent($(this).attr('name')) + '=';
            }
        });

        if (extraArgs && extraArgs['XDEBUG_SESSION_START']) {
            url = url + (url.indexOf('?') >= 0 ? '&' : '?') +
                'XDEBUG_SESSION_START=' + extraArgs['XDEBUG_SESSION_START'];
        }

        if (extraArgs && extraArgs['whatIf']) {
            url = url + (url.indexOf('?') >= 0 ? '&' : '?') + 'what_if=1';
        }

        if (extraArgs && extraArgs['callback']) {
            cbFunc = extraArgs['callback'];
        }

        if (!url.match(/[&?]redirect=[01]/) || isInIFrame()) {
            url = url + (url.indexOf('?') >= 0 ? '&' : '?') + 'redirect=' + (isInIFrame() ? 0 : 1);
        }

        $(window).data('beforeunload', window.onbeforeunload);
        window.onbeforeunload = null;

        $.ajax({
            type: isCRUD ? restAPIMethod : "POST",
            global: !restAPIMethod,
            url: url,
            data: restAPIMethod && !isCRUD ? data + "&_METHOD=" + restAPIMethod : data,
            complete: async function(jqXHR, textStatus) {
                var data = $.parseJSON(jqXHR.responseText);
                $(submitButtonSelector).removeAttr("disabled").removeClass('disabled');
                if (cbFunc && typeof(cbFunc) === "function") {
                    await cbFunc(data, form);
                } else {
                    handlePost(data, textStatus, jqXHR, form);
                }
                window.onbeforeunload = $(window).data('beforeunload');
                form.trigger('submitComplete', data);
            }
        });

        return stopPropagation(e);
    }, 1000, {leading: true, trailing: false});
}

function handlePost(data, textStatus, jqXHR, form, timeout, useGrowl) {
    var w = window.parent || window;

    //use new form message handling?
    //
    //this will eventually go away, or it will check the
    //class of the form instead of finding children
    if (form && form.children && form.children('.tss-form').length) {
        TssForm.handleUpdateResponse(data, form);
    } else if (parent && parent.$('.tss-form').length) {
        Tss.Alerts.handleFormAlerts(data, parent.$('.tss-form'));
    } else {
        displayNotifications(data, form, timeout, useGrowl);
    }

    if (!data || data.success) {
        $(window).data('beforeunload', null); // don't complain that we're leaving, we're done here
        if (data.results && data.results.url) {
            if (data.results.url == '#') {
                w.location.reload();
            } else {
                w.location.assign(data.results.url);
            }
        }

        handleInPlaceEdit(data);
    }
}

// replace row when we've done an edit in a popup or from a button
function handleInPlaceEdit(data) {
    if (data && data.success && data.results) {
        var w = window.parent;

        TssForm.handleInPlaceEdit(data.results);

        w.$("#facebox .close").trigger('click');

        if (data.results.url) {
            if (data.results.url == '#') {
                w.location.reload();
            } else {
                w.location.assign(data.results.url);
            }
        } else if ('row' in data.results || 'id' in data.results) {
            var newRow = data.results.row,
                klass = data.results['class'],
                id = data.results.id,
                rowId = klass + '_' + id,
                row = $('#' + rowId, w.document);

            if (!row.length) {
                rowId = _.capitalize(klass) + '_' + id;
                row = $('#' + rowId, w.document);
            }

            let table = w.$('#' + rowId).closest('table.tablesorter');
            if (table.hasClass('filtering-init')) {
                table.trigger('filtering.handleInlineEdit', [rowId, data.results]);
            } else {
                row.replaceWith(newRow);
                table.trigger('update');
            }
//            w.$('#' + id).closest('table.tablesorter').trigger('update');
            flashElem($('#' + rowId, w.document));

            $('.icon-button', w.document).hover(
                function() {$(this).addClass('ui-state-hover');},
                function() {$(this).removeClass('ui-state-hover');}
            );
        }

        w.$('body').trigger('saved', [data]);
    } else if (data && !data.success) {
        displayNotifications(data, null, undefined, true);
    }
}

function flashElem(elem) {
    elem.css('background-color', 'yellow');
    elem.animate({backgroundColor: 'white'}, 2000, function() {
        elem.css('background-color', '');
    });
}

function clearNotifications() {
    $('.status_box').hide(); // hide the old ones
    $('.inner-form-status-box').hide(); // hide the old ones
    $('.status-banner').hide(); // hide any old banner too
}

window.scrolledElem = null; // ugly. too bad.

function displayNotifications(data, parentElem, timeout, useGrowl) {
    // we want the notifications in reverse order when not growl so they are
    // added to the page in the order they come back from the server.
    var info = formatNotifications(useGrowl || !_.isArray(data.info) ? data.info : data.info.reverse()),
        warning = formatNotifications(useGrowl || !_.isArray(data.warnings) ? data.warnings : data.warnings.reverse()),
        error = formatNotifications(useGrowl || !_.isArray(data.errors) ? data.errors : data.errors.reverse());

    useGrowl = !!useGrowl;
    if (useGrowl) {
        _.each(info, function (value) {
            var growlData = {
                message: 'Hey! ' + value
            };
            if (timeout !== undefined) {
                growlData.duration = timeout;
            }
            Growl.info(growlData);
        });

        _.each(warning, function (value) {
            var growlData = {
                message: 'Warning: ' + value
            };
            if (timeout !== undefined) {
                growlData.duration = timeout;
            }
            Growl.warning(growlData);
        });

        _.each(error, function (value) {
            var growlData = {
                message: value.startsWith('Error:') ? value : 'Error: ' + value
            };
            if (timeout !== undefined) {
                growlData.duration = timeout;
            }
            Growl.error(growlData);
        });
    } else {
        clearNotifications();

        window.scrolledElem = null; // ugly. too bad.

        $.each(info, function (key, value) {
            displayInfo(key, value, parentElem, timeout);
        });

        $.each(warning, function (key, value) {
            displayWarning(key, value, parentElem, timeout);
        });

        $.each(error, function (key, value) {
            displayError(key, value, parentElem, timeout);
        });

        if (window.scrolledElem && window.scrolledElem.length) {
            window.scrolledElem.scrollintoview();
            window.scrolledElem = null;
        } else if (parentElem) {
            parentElem.find('.alert:first').scrollintoview();
        }
    }
}

function formatNotifications (notifications) {
    var formatted = {};
    $.each(notifications, function (notificationKey, notification) {
        if ($.type(notification) === "object") {
            $.each(notification, function (key, value) {
                formatted[notificationKey + "[" + key + "]"] = value;
            });
        } else {
            formatted[notificationKey] = notification;
        }
    });
    return formatted;
}

function replaceBrackets(key) {
    return key ? key.toString().replace(/([\]\[])/g, '\\$1') : key;
}

function displayNotification(key, value, parentElem, title, style, timeout) {
    var lookupKey = replaceBrackets(key),
        notificationElem = $('#' + lookupKey + '_status_box', parentElem),
        elem;

    // in case value already starts with title,
    // don't show "Error: Error: You don't have access."
    value = value && title && value.startsWith(title)
        ? value.substring(title.length).trim()
        : value
    parentElem = parentElem && parentElem instanceof jQuery ? parentElem : $('#main-form');

    // if we can find the input field on the page
    if ((elem = $('[name="' + lookupKey + '"]', parentElem)).length
            || notificationElem.length) {
        var notificationContent = getStatusBox(title, value, style, 'status-small');

        if (notificationElem.length) {
            notificationElem.html(notificationContent); // replace existing notification
        } else {
            notificationElem = $('<div id="' + key + '_status_box">' // add a new notification status box
                + notificationContent + '</div>');

            if (elem.is(':radio')) {
                elem = elem.last();
                if (elem.next().is('label')) {
                    elem = elem.next();
                }
            }

            if (elem.hasClass('is-tss-select-search') || elem.hasClass('is-tss-multiselect-search')) {
                elem.closest('.tss-select-search').length
                ? elem.closest('.tss-select-search').after(notificationElem)
                : elem.closest('.tss-multiselect-search').after(notificationElem);
            } else {
                elem.after(notificationElem);
            }
        }

        notificationElem.addClass('inner-form-status-box').show();
    } else {
        notificationElem = $(getStatusBox(title, value, style));

        if (parentElem.length) {
            parentElem.prepend(notificationElem);
        } else if (style in Growl) {
            Growl[style]({ message: title + ' ' + value });
        }
    }

    //is it in a tss-expandable?
    var $tssExpandable = elem.closest('section.is-tss-expandable');
    if($tssExpandable.length) {
        $tssExpandable.removeClass('closed');
    }

    if (!window.scrolledElem) window.scrolledElem = elem;

    //if not sticky, hide after timeout/1000 seconds
    if(timeout) {
        setTimeout(function(){
            notificationElem.fadeOut('slow', function() {
                this.remove();
            });
        }, timeout);
    }

    return elem;
}

function displayError(key, value, parentElem, timeout) {
    return displayNotification(key, value, parentElem, 'Error:', 'error', timeout);
}

function displayWarning(key, value, parentElem, timeout) {
    return displayNotification(key, value, null, 'Warning:', 'warning', timeout);
}

function displayInfo(key, value, parentElem, timeout) {
    return displayNotification(key, value, null, 'Hey!', 'info', timeout);
}

function updateSeatingChart(data, date) {
    if (!data || !data.success || !data.results) return;

    var demerits = data.results.demerits || data.results.behaviors;
    var studentIds = {};

    if (demerits) {
        for (var i = 0; i < demerits.length; i++) {
            var demerit = demerits[i];
            var studentId = demerit.student_id;

            studentIds[studentId] = null;
        }
    } else if (data.results.obj && data.results.obj.student_id) {
        studentIds[data.results.obj.student_id] = null;
    }

    studentIds = Object.keys(studentIds);

    if (!studentIds.length) return;

    $.get('/detention/student/summary/' + dateStringToServer(date), {student_id: studentIds}, function(data) {
        $("#facebox .close").trigger('click');

        if (!data || !data.success || !data.results) return;

        var fadeSpeed = 'slow',
            zeroColor = 'hiddenWhite',
            prefix = $('input[name="detention_prefix"]').val(),
            detentionExcludeTodays = $('input[name="detention_exclude_todays"]').val() == '1',
            projectorMode = $('.projectorMode').length;

        for (var studentId in data.results) {
            var studentElem = $('#result_' + studentId);

            if (!studentElem.length) continue;

            var detentionInfo = data.results[studentId],
                negsElem = studentElem.find('[name="negsToday"]'),
                creditsElem = studentElem.find('[name="creditsToday"]'),
                detentionsToServeElem = studentElem.find('[name="detentionsToServe"]'),
                detentionsServedTodayElem = studentElem.find('[name="detentionsServedToday"]'),
                detentionsEarnedTodayElem = studentElem.find('[name="detentionsEarnedToday"]'),
                demeritsToday = detentionInfo['demerits_today'] || 0,
                debitsToday = detentionInfo['debits_today'] || 0,
                creditsToday = detentionInfo['credits_today'] || 0,
                negsData = negsElem.data(),
                hasDemerits = (negsData.has_demerits == '1'),
                negsColor = negsData.color || 'none',
                negsToday = (hasDemerits ? demeritsToday : Math.abs(debitsToday)),
                creditsData = creditsElem.data(),
                creditsColor = creditsData.color || 'none',
                detentionColor = detentionColor || 'none',
                detentionsServedToday = prefix + 'sServedToday',
                detentionsLeftToServeToday = prefix + 'sLeftToServeToday',
                detentionsEarnedToday = prefix + 'sEarnedToday',
                detentionsToServeToday = prefix + 'sToServeToday',
                numDetentionsServedToday = detentionInfo[detentionsServedToday] || 0,
                numDetentionsLeftToServeToday = detentionInfo[detentionsLeftToServeToday] || 0,
                numDetentionsEarnedToday = detentionInfo[detentionsEarnedToday] || 0,
                numDetentionsToServeToday = detentionInfo[detentionsToServeToday] || 0,
                numDetentionsBig = detentionExcludeTodays ? numDetentionsLeftToServeToday : numDetentionsToServeToday,
                showNumDetentionsBig = numDetentionsBig || numDetentionsEarnedToday || numDetentionsServedToday,
                detentionsEarnedTodayChanged = (numDetentionsEarnedToday != detentionsEarnedTodayElem.html()),
                detentionsServedTodayChanged = (numDetentionsServedToday != detentionsServedTodayElem.html()),
                someDetentionChanged = (detentionsEarnedTodayChanged || detentionsServedTodayChanged),
                groupsBackground = $(detentionInfo.groups_background),
                allClasses = negsColor + ' ' + zeroColor + ' ' + creditsColor;

            detentionsToServeElem.parent().data({detention_info: detentionInfo, siteconfig: (data.meta || {}).siteconfig});

            updatePoints(detentionsEarnedTodayElem, numDetentionsEarnedToday, fadeSpeed,
                !numDetentionsEarnedToday ? 'r' : 'foo', numDetentionsEarnedToday ? 'r' : '');
            updatePoints(detentionsServedTodayElem, numDetentionsServedToday, fadeSpeed,
                !numDetentionsServedToday ? 'g' : 'foo', numDetentionsServedToday ? 'g' : '');
            updatePoints(detentionsToServeElem, numDetentionsBig, fadeSpeed,
                !showNumDetentionsBig ? detentionColor : zeroColor, showNumDetentionsBig ? detentionColor : zeroColor, someDetentionChanged);
            updatePoints(negsElem, negsToday, fadeSpeed,
                allClasses, negsToday > 0 ? negsColor : (negsToday < 0 ? creditsColor : zeroColor));
            updatePoints(creditsElem, creditsToday, fadeSpeed,
                !creditsToday ? creditsColor : zeroColor, creditsToday ? creditsColor : zeroColor);

            var oldGroupsBackgroundHtml = studentElem.find('.groupsBackground').map(function() {return this.outerHTML;}).get().join(''),
                groupsBackgroundHtml = groupsBackground.length ? groupsBackground[0].outerHTML : null; // normalize quotes

            if (projectorMode) {
                if (oldGroupsBackgroundHtml != groupsBackgroundHtml) {
                    groupsBackground.css('opacity', .00001);

                    if (oldGroupsBackgroundHtml.length) {
                        studentElem.find('.groupsBackground').fadeTo(fadeSpeed, .00001, function() {
                            studentElem.find('.groupsBackground').remove();
                            if (groupsBackgroundHtml) {
                                studentElem.prepend(groupsBackground).find('.groupsBackground').fadeIn(fadeSpeed);
                            }
                        });
                    } else {
                        studentElem.prepend(groupsBackground).find('.groupsBackground').fadeIn(fadeSpeed);
                    }
                }
            } else {
                studentElem.find('.groupsBackground').remove();
                if (groupsBackgroundHtml) {
                    studentElem.prepend(groupsBackground);
                }
            }
        }
    });
}

function updatePoints(elem, value, fadeSpeed, removeClass, addClass, forceShow) {
    var aElem = elem.find('a'),
        valueElem = aElem.length ? aElem : elem;

    ajaxtooltip.removetip(aElem);

    if (value != valueElem.html() || forceShow) {
        elem.fadeOut(fadeSpeed, function() {
            elem.removeClass(removeClass).addClass(addClass).fadeIn(fadeSpeed);
            valueElem.html(value);
        });
    }
}

function multiplierWarning() {
    var input = $(this);
    var row = input.closest('tr');
    var multiplier = row.find('input[name*="multiplier"]');

    return fieldWarning.call(multiplier, "Multiplying the behavior by 0 results in NO behavior. Are you sure you want to do this?");
}

function numDaysWarning() {
    return fieldWarning.call(this, "A suspension of 0 days results in NO suspension. Are you sure you want to do this?");
}

function negativeDetentionsWarning() {
    var input = $(this);
    var row = input.closest('tr');
    var multiplier = row.find('input[name*="multiplier"]');
    var detentionValue = getSelectedOption(row.find('select[name*="demerit_type_id"]')).data('this_detention_value');
    var detentionsToServeTodayCell = row.find('td[id*="etentionsToServeToday"]');
    var children = detentionsToServeTodayCell.find('.bam');
    var maxToGive = Math.min.apply(Math, children.map(function() {
        return parseFloat($(this).text());
    }));

    return fieldWarning.call(multiplier, "Removing this many detentions will give some "
            + $('th:visible:first', row.closest('table')).text().toLowerCase()
            + "s negative detentions to serve. Are you sure you want to do this?",
        undefined, detentionValue < 0 ? -maxToGive / detentionValue : Number.POSITIVE_INFINITY);
}

function negativeMultiplierWarning() {
    var input = $(this);
    var row = input.closest('tr');
    var multiplier = row.find('input[name*="multiplier"]');
    var detentionValue = getSelectedOption(row.find('select[name*="demerit_type_id"]')).data('this_detention_value');

    return fieldWarning.call(multiplier, "The multiplier must be greater than zero.",
        detentionValue ? 0 : Number.NEGATIVE_INFINITY);
}

function fieldWarning(msg, minOk, maxOk) {
    var input = $(this);
    var val = parseInt(input.val());
    var name = input.attr('name');
    var showError = (minOk !== undefined ? val < minOk : (maxOk !== undefined ? val > maxOk : val === 0));

    if (showError) {
        displayWarning(name, msg);
    } else {
        $('#' + replaceBrackets(name) + '_status_box').hide();
    }

    return showError;
}

function edit(width, height, getURL, opacity, callback, data, disposeOnClose, size) {
    //set size
    $.facebox.settings.size = size;

    if (screen.width < width) {
        window.location = getURL.replace('printable=1', 'printable=0');
    } else {
        if (!opacity) opacity = .2;
        $.facebox.settings.opacity = opacity;
        $.facebox.settings.disposeOnClose = disposeOnClose;
        var options = {rev: 'iframe|' + height + '|' + width};

        if (getURL.indexOf('#') == 0) {
            if (getURL[1] == '/') {
                // really a URL, load into a div, then show the popup
                var divId = getURL.substring(2).replace(/[^a-zA-Z0-9_]/g, '_');
                if (!$('#' + divId).length) {
                    $('body').append($('<div/>').attr('id', divId).css('display', 'none'));
                }
                $(':submit').attr('disabled', 'disabled');
                $(document).unbind('afterClose.facebox').bind('afterClose.facebox', function() {
                    $(':submit').removeAttr('disabled');
                });

                //use ajax instead of load
                //it proves to be more stable
                $('#' + divId).tssLoad(getURL.substring(1), function() {
                    edit(width, height, '#' + divId, opacity, callback, data, /*disposeOnClose*/true, size);
                    setupUI.apply($(this));
                });
//                $.ajax({
//                   url: getURL.substring(1),
//                   success: function(markup) {
//                        var el = $('#' + divId).html(markup);
//                        edit(width, height, '#' + divId, opacity, callback, data, /*disposeOnClose*/true);
//                        setupUI.apply(el);
//                   }
//                });
                return;
            }
            options.div = getURL;
            $.facebox.settings.speed = 0;
        } else {
            getURL += (getURL.indexOf('?') >= 0 ? '&' : '?') + 'printable=1';
            options.iframe = getURL;
            $.facebox.settings.speed = 200;
        }

        if ($('#facebox[src="' + getURL + '"]').length && data && data.reuse) {
            if (callback) callback.call(null, data);
            $.facebox.reveal(); // just re-show the existing one
        } else {
            $.facebox(options);
            $('#facebox').attr('src', getURL);
            if (callback) callback.call(null, data);
        }
    }
}

function addRowDetail(row, name, index, text, add, htmlArg) {
    var cell = row.find('td[id*="' + name + '\\]"]');

    if (!cell.length) return;

    var html = htmlArg || '<div' + (add != undefined ? ' name="' + add + '"' : '') + ' style="height: 23px">' + text + '</div>';

    if (index == undefined) { // not expanding a group
        cell.append(html);
    } else {
        if (add != undefined) {
            var prev = cell.find('div[name="' + (add - 1) + '"]');

            if (!prev.length) {
                cell.find('div:eq(' + index + ')').after(html); // parent
            } else {
                prev.after(html);
            }
        } else if (htmlArg) {
            cell.find('div[name="' + index + '"]').replaceWith(htmlArg);
        } else {
            cell.find('div[name="' + index + '"]').html(text).removeAttr('name');
        }
    }
}

function loadStudentInfo(val, row, parentIndex, index) {
    var select = $(this);
    val = (typeof(val) == 'object' ? select.val() : val);
    row = row || select.parents('tr:first');

    if (0 == val) return;
    var url = val.length > 2 && val.substr(0, 2) == 'g_'
        ? '/studentgroup/info/' + val.substr(2)
        : '/student/info/' + val;

    if (parentIndex >= 0) { // for groups, add a cell to be filled in later
        addRowDetail(row, 'grade_level', parentIndex, '', index);
        addRowDetail(row, 'homeroom', parentIndex, '', index);
    }

    $.get(url, function(data) {
        if (!data || !data.success) return;

        var res = data.results;
        var homeroom = res.homeroom || res;
        var gradeLevelName = res.gradeLevel.grade_level_name || '';

        addRowDetail(row, 'homeroom', index, homeroom && homeroom.link ? homeroom.link : '(none)');
        addRowDetail(row, 'grade_level', index, gradeLevelName);
    }, "json");
}

function loadStudentDetentions(val, row, parentIndex, index) {
    var select = $(this);
    val = (typeof(val) == 'object' ? select.val() : val);
    row = row || select.parents('tr:first');

    if (0 == val) return;

    var detentionTypeId = $('input[name="detention_type_id"]').val(),
        detentionExcludeTodays = $('input[name="detention_exclude_todays"]').val() == '1',
        prefix = $('input[name="detention_prefix"]').val(),
        fields = [prefix + 'sToServeToday'],
        date = dateStringToServer($('div.mainBody').find('input.date').val()),
        id,
        url = val.length > 2 && val.substr(0, 2) == 'g_'
            ? '/detention/studentgroup/summary/' + date + '/' + (id = val.substr(2))
            : '/detention/student/summary/' + date + '/' + (id = val);

    if (parentIndex >= 0) { // for groups, add a cell to be filled in later
        $.each(fields, function(i, v) {
            addRowDetail(row, v, parentIndex, '', index);
        });
    }

    $.get(url, function(data) {
        var detentionInfo = data && data.success && id in data.results ? data.results[id] : {},
            detentionsLeftToServeToday = detentionInfo[prefix + 'sLeftToServeToday'] || 0,
            detentionsToServeToday = detentionInfo[prefix + 'sToServeToday'] || 0,
            detentionsBig = detentionExcludeTodays ? detentionsLeftToServeToday : detentionsToServeToday,
            cell = $("<div class='bam' style='height: 23px' data-student-id=\"" + id + "\" "
                        + "tooltip href=\"/detention/explain/" + id + "?date=" + date + "&detention_type_id=" + detentionTypeId + "\" "
                        + ">"
                        + detentionsBig
                    + '</div>');

        cell.data({siteconfig: ((data || {}).meta || {}).siteconfig});
        addRowDetail(row, fields[0], index, detentionsBig, undefined, cell);
        row.find('input[name*="multiplier"]').change();
    }, "json");
}

function activate(e, postURL) {
    if ($(e.target).hasClass('ui-state-disabled')) return false;

    $.post(postURL, {}, handleInPlaceEdit, "json");

    return true;
}

function tableSorterExtraction(node) {
    if ($(node).is('[sortval]')) {
        return node.getAttribute('sortval');
    }

    var t = $.trim($(node).text());

    if (t) {
        return t.replace(/[,%$]/g, '');
    }

    var title = '';

    $(node).find('[title]').each(function() {title += $(this).attr('title');});

    return title;
}

function defaultFromJson(json, defaults, key) {
    var arr = JSON.parse(json),
        defaultHeader = $('[name="editHeader"]').html(),
        f = function() {
            var interventionType = $(this),
                foundObj,
                selectedText = $('label[for=' + interventionType.attr('id') + ']').text(),
                comments = $('textarea[name="comments"]'),
                moreComments = $('[name="more_comments"]').closest('.commentsRow'),
                commentsHider = $('textarea[name="commentsHider"]'),
                typeIsMentalHealth = interventionType.data('is_mental_health'),
                hasMentalHealthPermission = comments.data('has_mental_health_permission');

            $('[name="editHeader"]').html(defaultHeader + ': ' + selectedText);

            for (var i = 0; i < arr.length; i++) {
                var obj = arr[i];

                if (obj[key] == interventionType.val()) {
                    $('[name="editHeader"]').html('Edit ' + selectedText);
                    foundObj = obj;
                    break;
                }
            }

            moreComments[foundObj ? 'show' : 'hide']();
            foundObj = foundObj || defaults;

            for (var prop in foundObj) {
                if (prop != key) {
    //                log('setting ' + prop + ' to ' + foundObj[prop]);
                    fillInForm(prop, foundObj[prop]);
                }
            }

            if (typeIsMentalHealth && !hasMentalHealthPermission) {
                comments.add(moreComments).hide();
                commentsHider.show();
                $('[name="attachments"]').empty();
            } else {
                comments.show();
                commentsHider.hide();
            }

            addHover();
        };

    $('[name="' + key + '"]').change(f);
    fillInForm(key, arr.length ? arr[0][key] : defaults[key]);
    addHover();
}

function fillInForm(key, value, elem, exactMatch) {
    elem = elem || $('.mainBody');

    var rb, dp, df, tf, s, ms, mss, slider, cb, p, div, def;

    //handle booleans
    value = !_.isBoolean(value) ? value : (value ? 1 : 0);

    if ((rb = value && typeof value.indexOf === "function" && (value.indexOf(' ') >= 0 || value.indexOf('"') >= 0)
            ? $() : $('[name="' + key + '"][type="radio"][value="' + value + '"]', elem)).length) {
        rb.prop('checked', true).change();
    } else if ((s = $('select[name' + (exactMatch ? '' : '*') + '="' + key + '"][multiple!="multiple"]', elem)).length) {
        if ($.isArray(value)) {
            $.each(value, function(i, val) {
                s.val(val);
                if (s.hasClass('addAndClear')) addAndClear.call(s, {});
            });
            s.change();
        } else {
            s.val(value).change();
        }
    } else if((mss = $('select[name' + (exactMatch ? '' : '*') + '="' + key + '"].is-tss-multiselect-search', elem)).length) {
        if (!$.isArray(value)) {
            value = [value];
        }
        mss.val(value).change();
    } else if ((ms = $('select[name' + (exactMatch ? '' : '*') + '="' + key + '"][multiple="multiple"]', elem)).length) {
        try {
            ms.val(value).multiselect('selectchanged');
        } catch(e) {} // we may not be able to refresh if the select hasn't had .multiSelect() called on it yet
    } else if ((slider = $('.ui-slider [name="' + key + '"]', elem)).length == 1) {
        var uiSlider = slider.closest('.ui-slider'),
            handles = uiSlider.find('.ui-slider-handle'),
            isDateSlider = uiSlider.data('filter_type') == 'date',
            min = uiSlider.slider('option', 'min'),
            max = uiSlider.slider('option', 'max');

        if (isDateSlider) {
            value = $.map(value, function(v) { return v ? dateStringToUTCDate(v).getTime() : ''; });
        }

        if(handles.length > 1) {
            if (value[0] == '' || value[0] < min) {
                value[0] = min;
            }

            if (value[1] == '' || value[1] > max) {
                value[1] = max;
            }
        }
        uiSlider.slider('values', value);
        slider.val(value).trigger('slide');
    } else if ((cb = $('input[type="checkbox"][name="' + key + '"]', elem)).length) {
        var valueStr = '';
        value = _.isArray(value) ? value : [value];
        $.each(value, function(i, v) {
            valueStr += (valueStr ? ', ' : '') + '[value="' + v + '"]';
        });
        cb.prop('checked', false).filter(valueStr).prop('checked', true);
        cb.change();
    } else if ((dp = $('[name="' + key + '"].hasDatepicker', elem)).length) {
        dp.val($.datepicker.formatDate(dp.datepicker('option', 'dateFormat'), dateStringToDate(value)));
    } else if ((df = $('[name="' + key + '"][type="date"]', elem)).length) {
        df.val(dateStringToInput(value));
    } else if ((tf = $('[name="' + key + '"][type="time"]', elem)).length) {
        if (value) {
            var timeParts = value.split(':');
            if (timeParts.length == 3 && timeParts[2] != '00') {
                tf.attr('step', 1); // allow seconds for validation of time input
            }
            tf.val(timeStringToInput(value));
        }
    } else if ((p = $('p[name="' + key + '"]', elem)).length == 1) {
        p.text(value);
    } else if ((div = $('div[name="' + key + '"]', elem)).length == 1) {
        div.html(value);
    } else if ((def = $('[name="' + key + '"]', elem)).length == 1) {
        def.val(value).change();
    }
}
function fillInFilters(filterStr, filterContainer) {
    window.filteringOff = true;

    var currentFilterMap = getFilterMap(getFilters(filterContainer), true);

    $.each(filterStr.split('&'), function(i, str) {
        if (!str) return;

        var parts = str.split('='),
            key = parts[0],
            exactKey = filterContainer.find('[name$="' + key + '"]').attr('name'), // get the exact name including the 0_0_ nonsense
            value = parts[1];

        if (value && value.length && value[0] == '[') {
            value = JSON.parse(value);
        }

        if (key.match(/date/)) { // FIXME should be based on filter_type, no?
            value = $.map(value, function(v) { return dateStringToDate(v); });
        }

        fillInForm(exactKey, value, filterContainer);
        delete currentFilterMap[cleanKey(key)];
    });

    for ([key, value] of Object.entries(currentFilterMap)) {
        fillInForm(key, ['', ''], filterContainer);  // unset any old filters 
    }

    window.filteringOff = false;
}

function dateStringToUTCDate(value) {
    if (!value) return value;

    var d = dateStringToDate(value);

    d.setTime(d.getTime() + d.getTimezoneOffset() * 60000);

    return d;
}

function dateStringToDate(value) {
    if (/\d{4}-\d{2}-\d{2}/.test(value)) {
        return $.datepicker.parseDate("yy-m-d", value);
    }
    if (/\d+\/\d+\/\d+/.test(value)) {
        return $.datepicker.parseDate("m/d/yy", value);
    }
    if (/^\d{8}$/.test(value)) {
        return $.datepicker.parseDate("yymmdd", value);
    }
    if (/^\d{12,}$/.test(value)) {
        return new Date(parseInt(value));
    }

    return value;
}

function getDateInputFormat() {
    return shouldAddDatepicker() && !browserFormatsDates() ? "m/d/yy" : "yy-mm-dd";
}

function dateStringToInput(value) {
    return $.datepicker.formatDate(getDateInputFormat(), dateStringToDate(value));
}

function dateStringToDisplay(value) {
    return $.datepicker.formatDate('m/d/yy', dateStringToDate(value));
}

function dateStringTo1stString(serverDate) {
    return serverDate.substr(0,4) + '-' + serverDate.substr(5,2) + '-01';
}

function dateStringToMondayString(serverDate) {
    var date = serverToDate(serverDate);

    date = new Date(date.getTime() - ((date.getDay() - 1) * 24 * 60 * 60 * 1000))

    return dateToServer(date);
}

function serverToDate(s) {
    return new Date(parseInt(s.substr(0,4)), parseInt(s.substr(5,2)) - 1, parseInt(s.substr(8,2)));
}

function dateToServer(d) {
    var month = d.getMonth() + 1,
        day = d.getDate();

    return d.getFullYear() + '-' + (month < 10 ? '0' : '') + month + '-' + (day < 10 ? '0' : '') + day;
}

function quickDateStringToDisplay(serverDate) {
    return parseInt(serverDate.substr(5,2)) + '/' + parseInt(serverDate.substr(8,2)) + '/' + serverDate.substr(0,4);
}

function timeStringToInput(value) {
    // if the browser formats times then we'll set the input type="time" value
    // to be 13:45 and it will display as 1:45 pm. if not, we'll have to do
    // the formatting ourselves.
    if (browserFormatsTimes()) {
        return value;
    }

    var timeParts = value ? value.split(':') : [];
    var hours = timeParts.length > 0 ? timeParts[0] : '0';
    var minutes = timeParts.length > 1 ? timeParts[1] : '00';
    var seconds = timeParts.length > 2 ? timeParts[2] : '00';
    var suffix = "am";

    if (minutes.length < 2) {
        minutes = "0" + minutes;
    }

    if (hours >= 12) {
        suffix = "pm";
        hours -= 12;
    }

    if (hours == 0) {
        hours = 12;
    }

    return hours + ':' + minutes + (seconds != '00' ? ':' + seconds : '') + ' ' + suffix;
}

function getNowString(withSeconds) {
    var d = new Date(),
        hours = d.getHours(),
        minutes = d.getMinutes(),
        seconds = d.getSeconds();

    return timeStringToInput((hours < 10 ? '0' + hours : hours)
        + ':' + (minutes < 10 ? '0' + minutes : minutes)
        + (withSeconds ? ':' + (seconds < 10 ? '0' + seconds : seconds) : ''));
}

function dateToSort(d) {
    return $.datepicker.formatDate("yymmdd", d);
}

function datetimeSortToServer(d) {
    var dateRegex = /^(\d{4})(\d{2})(\d{2}) ?(?:(\d{2})(\d{2})(\d{2}))?$/;
    var matches = dateRegex.exec(d);

    if (!matches) return d;

    return matches.slice(1, 4).join('-') + (matches[4] != undefined ? ' ' + matches.slice(4, 7).join(':') : '');
}

function dateStringToServer(value) {
    return $.datepicker.formatDate("yy-mm-dd", dateStringToDate(value));
}

function fixPropertyDivs() {
    var container = this;
    var lastDiv = $('div.propertyInfo:last', container);

    if (!lastDiv.length) return;

    // set all headers to be the same width as the widest
    $('.propertyInfo', container).each(function() {
        var propertyInfo = $(this);
        var propertyInfos = propertyInfo.nextUntil(':not(.propertyInfo)').add(
            propertyInfo.prevUntil(':not(.propertyInfo)')).add(propertyInfo);

        propertyInfo.find('.propertyHeader').css('width',
            Math.max.apply(Math, propertyInfos.find('.propertyHeader span').map(function() {
                return $(this).width() + 1;
            }).get())
        );
    });

    if (lastDiv.closest('.ajaxtooltip').length) return;

    // make sure the property divs don't wrap
    var totalWidth = 0;
    lastDiv.css('margin-right', '0px');
    $('div.propertyInfo', container).each(function() {
        totalWidth += $(this).outerWidth(true);
    });
    lastDiv.css('max-width', parseInt(lastDiv.parent().css('max-width')) - totalWidth + lastDiv.width() - 10);
    lastDiv.next('div').css('clear', 'both');
}

function isFirefox() {
    return !!navigator.userAgent.match(/firefox/i);
}

function isChrome() {
    return !!navigator.userAgent.match(/chrome/i);
}

function browserFormatsDates() {
    return isChrome();
}

function browserFormatsTimes() {
    return isChrome() || isFirefox() || isMobile();
}

function fixDateInputs() {
    var showTermBinPanel = function(inst) {
            var panel = Dates.commonDateLinks,
                forGradeOnly = inst.input.hasClass('forGradeOnly');

            if (forGradeOnly) {
                panel = panel.clone();
                panel.find('a:not(.forGrade)').remove();
                panel.find('.dateSection').each(function() {
                    if (!$(this).find('a').length) {
                        $(this).remove();
                    }
                });
            }

            inst.dpDiv.find('.ui-datepicker-buttonpane').replaceWith(panel);
        },
        fixDatepickerPosition = function(input) {
            var $input = $(input),
                $widget = $input.datepicker("widget"),
                $ajaxtooltip = $input.closest('.ajaxtooltip');

            if ($ajaxtooltip.length) {
                var inputPos = $input.parent().position(),
                    widgetPos = {top: inputPos.top + $input.parent().outerHeight(), left: inputPos.left};

                $widget.css(widgetPos);
                $widget.appendTo($ajaxtooltip);
            } else {
                $widget.appendTo($('body'));
            }
        },
        opts = {
            dateFormat: browserFormatsDates() ? "yy-mm-dd" : "m/d/yy",
            nextText: '', // '\f105'; //icon-angle-right // '\u25B6' black-triangle-right
            prevText: '', // '\f104'; //icon-angle-left // '\u25C0' black-triangle-left
            beforeShowDay: function(date) {
                if (date in Dates.termBinDates) {
                    return [true, 'termbinDates', Dates.termBinDates[date]]; // highlight termbin dates in blue and add a tooltip
                }

                return [true, ''];
            },
            beforeShow: function(input, inst) {
                setTimeout(function () { fixDatepickerPosition(input); }, 1);
            }
        };

    if (shouldAddDatepicker()) {
        var elems = $('input.date:not(.hasDatepicker)');

        if (elems.length) {
            Dates.loadTermBins();

            if (elems.hasClass('showButtonPanel')) {
                opts['showButtonPanel'] = true;
            } else if (elems.hasClass('showTermBinPanel')) {
                $.extend(opts, {
                    showButtonPanel: true,
                    onClose: function(dateText, inst) {
                        setTimeout(function() { showTermBinPanel(inst); }, 1);
                    },
                    onSelect: function(dateText, inst) {
                        setTimeout(function() { showTermBinPanel(inst); }, 1);
                        inst.input.trigger('change'); // NOTE: this normally happens in jqueryui unless you set your own onSelect
                    },
                    onChangeMonthYear: function(year, month, inst) {
                        setTimeout(function() { showTermBinPanel(inst); }, 1);
                    },
                    beforeShow: function(input, inst) {
                        setTimeout(function() { showTermBinPanel(inst); fixDatepickerPosition(input); }, 1);
                    },
                });
            }

            elems.each(function() {
                var $elem = $(this),
                    elemOpts = $elem.data('datepickerOpts'),
                    myOpts = $.extend({}, opts, elemOpts);

                if (isFirefox()) {
                    this.type = 'text';
                }

                // if we have a special dateFormat for our input[type=text] field,
                // convert the value from some default format (i.e. yyyy-mm-dd) into the desired format on the fly (e.g. DD, MM d, yy)
                if (elemOpts && 'dateFormat' in elemOpts) {
                    $elem.val($.datepicker.formatDate(elemOpts['dateFormat'], dateStringToDate($elem.val())));
                }

                $elem.datepicker(myOpts);
            });

            elems.click(function() {
                var elem = $(this);

                setTimeout(function() {
                    // if datepicker not visible, we clicked on the background/calendar icon so show it
                    if (!elem.datepicker('widget').is(':visible')) {
                        elem.datepicker('show');
                    }
                }, 100);
            });
        }

        if (!browserFormatsDates()) {
            $('input.date').each(function() {
                var v = $(this).val(),
                    resetVal = $(this).data('reset-to');
                if (v && v.length && v.match(/\d{4}-\d{2}-\d{2}/i) != null) {
                    log('parsing date: ' + v);
                    var newValue = dateStringToInput(v);
                    $(this).val(newValue);
                }

                //handle data-reset-to for the tss-form
                if (resetVal && resetVal.length && resetVal.match(/\d{4}-\d{2}-\d{2}/i) != null) {
                    log('parsing data-reset-to: ' + resetVal);
                    var newValue = dateStringToInput(resetVal);
                    $(this).data('reset-to', newValue);
                }
            });
        }
    }
}

function setupUI(e) {
//    log('setupUI: ');
//    log(this);

    if (e && e.target && !$(e.target).is('div')) {
//        log('shortcircuiting setupUI on non-div:');
//        log(e.target);
        return;
    }

//    time('setupUI');
    var container = $(this),
        buttons = $('button, input[type="button"], :submit, a.button', container)
                        .filter(':not(.btn)')
                        .filter(':not(.tss-select-search-btn)')
                        .filter(':not(.tss-multiselect-search-btn)');

    addHover.apply(container);
    fixPropertyDivs.apply(container);
    labelShortcutKeys.apply(container);
    addChangeListeners.apply(container);

    if (buttons.length) {
        buttons.button().filter('[data-icon*="ui"], [data-icon2*="ui"]').each(function() {
            var button = $(this);

            button.button({icons: {primary: button.data('icon'), secondary: button.data('icon2')}});
        });
    }

    // Namespace this event and off before binding so we don't have events bound twice
    $(':submit', container).off('click.submit.form').on('click.submit.form', formSubmit(container));

    //try catch because it throws an error on the student page
    try {
        $('.radio', container).buttonset();
    } catch(e) {}

    $('textarea.autogrow').tssAutogrow();
    $('.resizable').resizable({handles: 's', alsoResize: '.alsoResize', zIndex: 4}).each(function() {
        if ($(this).find('.ui-icon-gripsmall-diagonal-se').length) return;

        $(this).append('<div class="ui-resizable-handle ui-resizable-se ui-icon ui-icon-gripsmall-diagonal-se" style="z-index: 3"></div>');
    });
    $('select[multiple!="multiple"]', container).each(hyjackSelect);
    $('select[multiple]', container).each(multiSelect);
    $('.multi :checkbox', container).button().change(updateMultiButtonDisplay).each(updateMultiButtonDisplay);
    $('.multi a.selectAll', container).click(function(e) {
        stopPropagation(e);
        $(this).parents('.multi').find(':checkbox').next('label:visible').prev(':checkbox').prop('checked', true).button('refresh').first().change();
    });
    $('.multi a.clear', container).click(function(e) {
        stopPropagation(e);
        $(this).parents('.multi').find(':checkbox').prop('checked', false).button('refresh').first().change();
    });
    $(':checkbox', container).change(function() { $(this).blur(); }); // fix Firefox bug
    $(container).on('click', '.outof a', function(e) {
        stopPropagation(e);
        clearFilters().first().change();
    });

    updateEqualWidthFields();

    $('.numResults', container).bind('filtered', createDefaultSummaryTable);
    $('body').bind('saved', createDefaultSummaryTable);

    // only init ones that haven't been done already
    $('.slider:not(.ui-slider)', container).each(function() {
        var slider = $(this);
        var name = slider.find('input').attr('name');
        var sliderLabel;

        slider.parents().each(function(i) { return !(sliderLabel = $(this).find('.sliderLabel')).length; }); // short circuit
        setupSlider(slider, sliderLabel, name);
    });

    // fixDateInputs AFTER sliders are setup ^^
    // because some date inputs get created dynamically if the slider is a date type
    // and therefore won't exist if this gets called earlier
    fixDateInputs.apply(container);

    // make sure the visible submit button is the one that gets the shortcut key
    $('.ui-tabs', container).bind('tabsshow', function(event, ui) { labelShortcutKeys(); });

//    $('.fixedTableHeader table', container).each(function () { updateTableHeaderWidths.call(this, true); });
//    setTimeout(function() { $('.fixedTableHeader table', container).each(updateTableHeaderWidths) }, 300);

    if ($('#main-form', container).length) {
        grabFocus($('#main-form', container));
    }

    $('td.left-cash', container).each(function() {
        var elem = $(this);
        var text = elem.text();
        if (text && text[0] == '$') { // if it's postive cash, add left
            var paddingLeft  = (elem.innerWidth()  - elem.width()) / 2; // divide by two since otherwise it's paddingLeftAndRight

            elem.css('padding-left', (paddingLeft + 6) + 'px');
        }
    });

//        log('target of loaded div');log(e.target);
    container.find('table.tablesorter').each(tablesorter);

    Bindings.setupUIBindings();

    // make sure "e" is a jQuery.Event
    if (e && e.cancelBubble) stopPropagation(e);

//    timeEnd('setupUI');
}

function updateEqualWidthFields() {
    if ($('.equalWidths label:not(.equaled)').length) {
        var width = Math.max.apply(Math, $('.equalWidths label:not(.equaled)').map(function() { return $(this).width() + 1; }));

        if (width > 10) {
            var labels = $('.equalWidths label').width(width).addClass('equaled');

            $('.equalWidths[data-wrap]').css('max-width', labels.first().outerWidth(true) * $('.equalWidths').data('wrap') + 10);
        }
    }
}

function createDefaultSummaryTable() {
    var summaryTable = $('#filterSummary'),
        resultsTable = $('th.filterSummary').closest('table');

    createSummaryTable(summaryTable, resultsTable);
}

function createSummaryTable(summaryTable, resultsTable) {
    if (!resultsTable.length) return [];

    if (!summaryTable.data('once')) {
        summaryTable.data('once', 1).data('has_cols', summaryTable.find('tr').length > 0);
    }

    var ths = resultsTable.find('th.filterSummary'),
        valueFilter = summaryTable.data('filter_summary_value'),
        hasCols = summaryTable.data('has_cols'),
        allTotals = [];

    if (!hasCols) {
        summaryTable.empty();
    } else {
        summaryTable.find('td:nth-child(2)').empty(); // remove the values, not the headers
    }

    ths.each(function(j) {
        var th = $(this),
            totals = {},
            index = th.index() + 1;

        resultsTable.find('.result:visible').find('td:nth-child(' + index + ')').each(function() {
            var td = $(this),
                tr = td.parent(),
                keys = td.data('filter_summary_values'),
                key,
                i;

            if (tr.hasClass('deactivated')) return true; // continue;

//            console.log(keys);

            if (!keys) {
                key = td.text();
                if(!_.isEmpty(key)) {
                    totals[key] = ((totals[key] || 0) + 1);
                }
            } else if ($.isArray(keys)) {
                for (i = 0; i < keys.length; i += 2) {
                    if (valueFilter && valueFilter != keys[i]) continue; // only want a sub-set of the values

                    key = keys[i + 1];
                    if (_.isObject(key)) {
                        $.each(key, function(k, v) {
                            totals[k] = ((totals[k] || 0) + parseInt(v));
                        });
                    } else {
                        totals[key] = ((totals[key] || 0) + 1);
                    }
                }
            } else if (_.isObject(keys)){
                $.each(keys, function(key, val) {
                    totals[key] = ((totals[key] || 0) + parseFloat(val));
                });
            } else {
                if(!_.isEmpty(keys)) {
                    totals[keys] = ((totals[keys] || 0) + 1);
                }
            }
        });

        var orderedKeys = Object.keys(totals);

        orderedKeys.sort();

        $.each(orderedKeys, function(i, key) {
            if (hasCols) {
                var elem = summaryTable.find('#' + key);

                elem.html(formatByClass(totals[key], elem));
            } else {
                summaryTable.append('<tr' + (i == orderedKeys.length - 1 && j != ths.length - 1 ? ' class="sectionEnd"' : '')
                    + '><td class="label">' + key + ':</td><td>' + totals[key] + '</td></tr>');
            }
        });

        allTotals.push(totals);
    });

    return allTotals;
}

function addChangeListeners() {
    var studentGroupSelect = $(this).find('#student_group_list'),
        loadDivSelects = $(this).find('#student_list, #week_of_list');

    // Namespace this event and off before binding so we don't have events bound twice
    studentGroupSelect.off('change.setupUI').on('change.setupUI', studentGroupChange);
    loadDivSelects.off('change.setupUI').on('change.setupUI', loadDivChange);
}

function tablesorter() {
    var table = $(this),
        rowOne = table.find('tbody tr:eq(0)'),
        options = {
            widgets: ["zebra"]
        };

    if (rowOne.length) {
        table.tablesorter(options);
    }

    if (table.find('.table-actions, .table-actions-three, .table-actions-four').length) {
        table.addClass('actionsTable');
    }

    if (table.hasClass('autoTotal') && (table.find('tr.averages-row') || table.find('tr.totals-row'))) {
        calculateTotals.apply(table);
    }
}

function forceLayout() {
    $('body').append('<style class="foobar"></style>').find('style.foobar').remove();
}

function isInIFrame() {
    return window.top != window.self;
}

function updateMultiButtonDisplay() {
    var multi = $(this).closest('.multi');
    var totalCheckboxes = $(':checkbox', multi).length;
    var numSelected = $(':checkbox:checked', multi).length;

    if (numSelected == totalCheckboxes) {
        $('.selectAll', multi).addClass('disabled');
        $('.clear', multi).removeClass('disabled');
    } else if (numSelected == 0) {
        $('.clear', multi).addClass('disabled');
        $('.selectAll', multi).removeClass('disabled');
    } else {
        $('a', multi).removeClass('disabled');
    }
}

/**
 * Label as Ctrl+Option on Macs for accesskeys.
 */
function getShortcutLabelPrefix() {
    return isMac() ? 'Ctrl+Option+' : 'Alt+Shift+';
}

function getSimpleShortcutLabelPrefix() {
    return isMac() ? 'Meta+' : 'Ctrl+';
}

function labelShortcutKeys() {
    labelShortcutKey('a:contains("edit")', 'E', 'Edit');
    labelShortcutKey('a:contains("Print")', 'P', 'Print', null, true/*noSimple*/);
    labelShortcutKey('input[type="submit"]', 'S', 'Save', 'form, .submitDiv');
    labelShortcutKey('button[type="submit"]', 'S', 'Save', 'form, .submitDiv');

    $(this)
        .on('keydown', '.icon-button[tabindex]', 'Return', function (e) {
            if (e.keyCode == 13 || e.keycode == 32) {
                clickThis(e);
            }
        });
}

function clickThis(e) {
    if (e.cancelBubble) return false;

    $(this)[0].click();

    return stopPropagation(e);
}

function labelShortcutKey(selector, letter, defaultDesc, parent, noSimple) {
    selector = parent ? $(selector, parent) : $(document).find(selector);

    // make sure we're not greedy matching random other links
    if (defaultDesc == 'Edit' || defaultDesc == 'Print') {
        let startsWith = new RegExp('^' + defaultDesc, 'i');
        selector = selector.filter(function() {
            return $(this).text().match(startsWith);
        });
    }

    if (selector.length) {
        var visibleSelector = selector.filter(':visible:first').filter('a, input:enabled'),
            invisibleSelector = selector.not(visibleSelector),
            title = visibleSelector.attr('title'),
            accessKeyShortcut = getShortcutLabelPrefix() + letter,
            simpleShortcut = getSimpleShortcutLabelPrefix() + letter,
            shortcut = (noSimple ? accessKeyShortcut : simpleShortcut),
            shortcutDisplay = shortcut.replace('Meta+', 'Command+');

        invisibleSelector.removeAttr('accesskey');
        visibleSelector.attr('accesskey', letter);
        if (visibleSelector.length) {
            $('body').unbind('keydown', shortcut).bind('keydown', shortcut, function(e) {
                if (!visibleSelector.is(':visible')) return true; // continue. I don't think unbind works

                return clickThis.call(visibleSelector, e);
            });
        }

        if (title && title.length) {
            if (title.indexOf(shortcutDisplay) >= 0) {
                return;
            }

            title = title + " (" + shortcutDisplay + ")";
        } else {
            title = defaultDesc + " (" + shortcutDisplay + ")";
        }

        visibleSelector.attr('title', title);

        // Look for elements with 'removeaccesskey' class and remove accesskey & title
        var elements = document.getElementsByClassName("removeaccesskey");
        for(var i = 0; i < elements.length; i++) { 
           var element = elements[i];
           element.removeAttribute("accesskey");
           element.removeAttribute("title");
        }
    }
}

function addShortcutKey(selector, shortcut, callback) {
    var accesskey = shortcut.substr(shortcut.length - 1, 1);

    selector.on('keydown', shortcut, callback);

    if (!$('[accesskey=' + accesskey + ']').length)
        $('div:not([accesskey]):first, span:not([accesskey]):first, form:not([accesskey]):first, table:not([accesskey]):first',
            $('.mainBody')).first().attr('accesskey', accesskey);
}


function getSelectedOption(s) {
    return s.find('option[value="' + s.val() + '"]'); // because option:selected is slow for some reason!
}

function hyjackSelect() {
    var select = $(this);

    if (select.attr('multiple')) return;

    if (select.is('[rel="make-tss-select-search"]')) return;

    var initialSelection = select.data('sel');
    if (initialSelection) {
        select.val(initialSelection).data('sel', null); // first time only
    }

    if (shouldHyjack() && !select.hasClass('nohyjack')) {
        $.hyjack_select.dispose(select);
        select.hyjack_select(getHyjackDefaults());
    } else if (!select.hasClass('noplaceholder')) {
        var placeholder = select.attr('placeholder');

        if (placeholder) {
            if (select.find('option:first').text()) {
                select.prepend('<option value="">' + placeholder + '</option>');
            } else {
                select.find('option:first').html(placeholder)
            }
        }
    }
}

function multiSelect() {
    var select = $(this);

    if (!select.attr('multiple') || select.is('[rel="make-tss-multiselect-search"]')) return;

    try {
        select.multiselect('getButton');
        return; // if the above doesn't throw an exception, we've already multi-selected it.
    } catch(e) {}

//    log('multiselecting ' + select.attr('name'));
    select.find('option[value=""]').remove();

    var initialSelection = select.data('sel');
    if (initialSelection) {
        select.val(initialSelection).data('sel', null); // first time only
    }

    if (select.hasClass('nohyjack')) return;

//    select.multiselect("destroy");
    select.multiselect(getMultiSelectDefaults(select)).multiselectfilter({
        label: '',
        placeholder: 'filter',
        filter: delayFunction(500, multiSelectAutocomplete),
    });

    var button = select.multiselect('getButton');

    $(button).attr('for', select.attr('name')).button();

    var menu = select.multiselect('widget');
    $(menu).keydown(function(e) {
        if (e.keyCode == 9) { // tab
            var all = $('input,button:visible');
            var index = all.index(button);
            var next = all.eq(index + 1);

            next.focus();
        }
    });
}

function multiSelectSingleStudent() {
    var select = $(this);

    try {
        select.multiselect('getButton');
        return; // if the above doesn't throw an exception, we've already multi-selected it.
    } catch(e) {}

    select.multiselect({
        multiple: false,
        noneSelectedText: 'Select',
        selectedList: 1
    }).multiselectfilter();
}

/**
 * Wait delay milliseconds after the last calling of this function in order to actually call f. In other words,
 * if you're typing in an input field that's firing on every keyup just wait until they stop typing to fire the handler.
 */
function delayFunction(delay, f) {
    var oldId,
        wrapperFunction = function(e) { // when this gets called, kill the old interval, set a new one
            var that = this;

            if (oldId) clearTimeout(oldId);

            oldId = setTimeout(function() { f.call(that, e); }, delay);
        };

    return wrapperFunction;
}

function multiSelectAutocomplete(event, matches) {
    var select = $(this),
        autocomplete = select.hasClass('autocomplete'),
        term;

    try {
        term = select.multiselect('widget').find('input:first').val();
    } catch(e) {
        return;
    }

    var allOptions = [],
        filterContainer = select.closest('.filterContainer, [rel="filter-container"]'),
        filters = getFilters(filterContainer),
        filterState = getFilterState(filters),
        filterStr = filterState.join('&'),
        addOneOption = function(select, parent) {
            parent = parent || select;

            return function() {
                var opt = $(this),
                    val = opt.val(),
                    existingOption = select.find('option[value="' + val + '"]');

                if (existingOption.length) {
                    allOptions.push(existingOption[0]);
                    return true; // continue
                }

                allOptions.push(this);

                opt.addClass('ext');
                parent.append(opt);
            }
        },
        updateSelect = function() {
//            select.find('option.ext').not($(allOptions)).remove();
            select.multiselect('refresh');
        };

    if (!autocomplete || select.data('last_term') == term) return;

    select.data('last_term', term);
    if (!term) {
        select.find('option.ext').not($(allOptions)).remove();
        updateSelect();
        return;
    }

    $.get('/options/filter/', {field: select.attr('name'), term: term, filter: filterStr}, function(data) {
        var res = $(data.results.options),
            optgroups = res.filter('optgroup');

        if (optgroups.length) {
            optgroups.each(function() {
                var optgroup = $(this),
                    parent = select.find('optgroup[label="' + optgroup.attr('label') + '"]');

                if (!parent.length) {
                    parent = optgroup;
                    select.append(parent);
                }

                optgroup.find('option').each(addOneOption(select, parent));
            });
        } else {
            res.filter('option').each(addOneOption(select));
        }

        updateSelect();
    });
}

import arrowDown from '../../images/arrow_down.png';
import cancel from '../../images/cancel.png';
function getHyjackDefaults() {
    return {
        emptyMessage: 'No matches',
        restrictSearch: true,
        filter: 'like',
        offset: 4,
        ddImage: arrowDown,
        ddCancel: cancel
    };
}

function multiSelectTextAdvanced(numChecked, numTotal, checkedElems) {
    var sep = this.options.sep || '<br/>';

    return numChecked == numTotal ? 'All' : $.map($(checkedElems), function(e) { return e.title; }).join(sep);
}

function getMultiSelectDefaults(select) {
    return $.extend({
        noneSelectedText: "Select " + select.attr('placeholder'),
        selectedText: multiSelectTextAdvanced,
        sep: ', ',
        height: 'auto',
        checkAllText: 'all',
        uncheckAllText: 'none',
        multiple: select.attr('single') !== '1',
        open: function() {
//            $(this).multiselect('widget').find('input[type="search"]').select(); // do this once we figure out the multiselect key handlers
        },
        position: {
            my: 'left top',
            at: 'left bottom'
        }
    }, select.metadata());
}

function camelizeMap(map) {
    var newMap = {}

    $.each(map, function(k, v) {
        newMap[camelize(k)] = v
    });

    return newMap;
}

function camelize(str, upperCaseFirst) {
    var camelized = str.replace(/(_[a-z])/g, function(match) { return match[1].toUpperCase(); });

    return upperCaseFirst ? camelized.replace(/^(.)/, function(match) { return match[0].toUpperCase(); }) : camelized;
}

function removeUserVoice() {
    $('#uvwClose').hide();
    $('#uvTab').animate({left: -50}, 500, "linear", function() {$('#uvTab, #uvw-overlay').hide();});
}

function handleRowEvent(rowClass, actionClass) {
    return function (e) {
    //    log((e.ctrlKey ? "CTRL+" : "") + (e.shiftKey ? "SHIFT+" : "") + e.keyCode + " " + e.cancelBubble);
        if (e.cancelBubble) return false;

        var myRow = $(this).closest(rowClass);
        var button = myRow.find(actionClass);
    //    log('clicking button(s): ' + button.length);
        button.click();

        return stopPropagation(e);
    };
}

function stopPropagation(e) {
    if (!e) {
        e = window.event;
    }

    if (!e) {
        return false;
    }

    e.cancelBubble = true; // for IE
    e.returnValue = false; // for IE

    if (e.stopPropagation) {
        e.stopPropagation();
    }

    if (e.preventDefault) {
        e.preventDefault();
    }

    if (e.stop) {
        e.stop();
    }

    return false;
}

function styleCourseGradesHeaders(rag) {
    $('.courseGradesStudent .averages-row td:not(:first-child)').each(function() {
        var gpaCell = $(this),
            text = gpaCell.text().trim();

        if (text !== '') {
            addConditionalFormatting(gpaCell, parseFloat(text), rag);
        }
    });

    $('.courseGradesStudent tbody tr td:not(:first-child)').each(function() {
        var scoreCell = $(this),
            t = scoreCell.text();

        if (t) {
            var color = Functions.getColor(scoreCell, null, scoreCell.data('alpha')),
                css = {'background-color': color, '-webkit-print-color-adjust': 'exact'};

            scoreCell.css(css);
        }
    });
}

function rowsToValues(rows, callback) {
    var allValues = [],
        $rows = $(rows),
        length = $rows.length,
        i;

    for (i = 0; i < length; i++) {
        var $row = $(rows[i]),
            values = [];
            $('td, th', $row).map(function() {
                var cell = $(this);

                if(cell.hasClass('no-export')) { return; }

                if (cell.hasClass('date') && cell.attr('sortval')) {
                    let val = datetimeSortToServer(cell.attr('sortval'));

                    return values.push(val == '0000-00-00' || val == '0000-00-00 00:00:00' ? '' : val);
                }

                values.push($.trim(cell.text()));
            }).get();

        allValues[allValues.length] = (callback ? callback($row, values) : values);
    }

    return allValues;
}

function headerRowsToValues(table) {
    var i = 0,
        arr = [];

    $('thead tr:first th', table).map(function() {
        var th = $(this),
            colspan = parseInt(th.attr('colspan') || 1),
            maxCol = i + colspan,
            text = $.trim(th.text());

        if(th.hasClass('no-export')) { return; }

        for (i; i < maxCol; i++) {
            var subText = $.trim($('thead tr:eq(1) th:eq(' + i + ')', table).text());

            arr.push(text && subText ? text + ' ' + subText : text || subText);
        }
    });

    return arr;
}

function valuesToRows(rows, classes) {
    return $.map(rows, function(row) {
        return '<tr>' + $.map(row, function(v, i) {
            var val = ($.isArray(v) ? v[0] : v);
            var attrs = ($.isArray(v) ? ' ' + v[1] : '');
            var clazz = (classes && i < classes.length) ? classes[i] : '';

            return '<td' + attrs + ' class="' + clazz + '">' + val + '</td>';
        }).join('') + '</tr>';
    }).join('');
}

function getVisibleRows(container) {
    container = container || $('body');

    var rows = {};

    container.find('.result:visible').each(function() {
        rows[rowIdToId($(this))] = {};
    });

    return {'rows': rows};
}

function genericDownload(url, data, extraParams) {
    return function(e) {
        var tempform = $('body').append(
            "<form name='tempform' id='tempform' action='" + url + "' method='post'>"
                + "</form>").find('#tempform');

        if (data) {
            tempform.append("<input type='hidden' name='data'>").find('input[name="data"]').val(JSON.stringify(data));
        }

        $.each(extraParams || {}, function(k, v) {
            tempform.append("<input type='hidden' name='" + k + "'>").find('input[name="' + k + '"]').val(v);
        });

        console.log(url);
        console.log(data);
        console.log(extraParams);
        tempform.submit().remove();

        pauseOnBeforeUnload();

        return stopPropagation(e);
    };
}

function genericExportTable(e, title, table, callback) {
    var rows = table.find('thead tr.totals-row, thead tr.averages-row, tbody tr, tfoot tr'),
        dataArray = [headerRowsToValues(table)].concat(rowsToValues(rows, callback))

    return genericExport(e, title, dataArray);
}

function genericExport(e, title, dataArray) {
    var url = '/generic/export/',
        jsonStr = JSON.stringify(dataArray),
        tempform = $('body').append(
            "<form name='tempform' id='tempform' action='" + url + "' method='post'>"
            + "<input type='hidden' name='title' value='' />"
            + "<input type='hidden' name='data' value='' />"
            + "</form>").find('#tempform');

    tempform.find('input[name="title"]').val(title);
    tempform.find('input[name="data"]').val(jsonStr); // avoid single quote issues
    tempform.submit().remove();

    pauseOnBeforeUnload();

    return stopPropagation(e);
}

function getDropTarget(target) {
    if ($(target).hasClass('dndtarget')) {
        return $(target);
    } else if($(target).closest('.dndtarget').length) {
        return $(target).closest('.dndtarget');
    }
//    else if($(target).next('.dndtarget'))
}

function getRelatedTarget(e) {
    if (e.relatedTarget === undefined) {
        e.relatedTarget = e.fromElement || e.toElement
    }

    return e.relatedTarget;
}

function fileDragHover(e) {
    e.stopPropagation();
    e.preventDefault();

    e.dataTransfer.dropEffect = 'copy';

    var target = getDropTarget(e.target),
        relatedTarget = getRelatedTarget(e);
    if ('dragover' == e.type || 'mouseover' == e.type) {
        target.data('over', 1);
        if (!target.hasClass('hover'))
            target.addClass('hover');
    } else if (!relatedTarget || !$(relatedTarget).parents('.dndtarget').length) {
        target.data('over', 0);
        setTimeout(function() {
            if (!target.data('over')) {
                target.removeClass('hover');
            }
         }, 300);
    }
}

function initializeInputFileUpload(getParams){
    
    $('.drop-file').each(function() {
        this.addEventListener('click', function (){
            $("#file-input").click();
        });
    });

    $("#file-input").change(uploadAttachment(handleUploadResponse, getParams, false));

}

function uploadAttachment(handleResponse, paramsInput, isDragAndDrop = true) {
    return function (e) {
        if(!isDragAndDrop && e.target.files.length === 0) return;
        var dataObj = e.dataTransfer || e.clipboardData,
            fileList = e.target.files || dataObj.files,
            data = [],
            target = getDropTarget(e.target),
            attachmentTypeId = target.data('attachment_type_id'),
            params;

        if(isDragAndDrop) fileDragHover(e); // cancel event and hover styling

        // Allow a function to be passed in to preserve synchronousness.
        // Previously, params weren't getting set because view specific js to
        // get them was firing before certain page elements had populated.
        // This way we wait until the drag and drop action to go fetch params.
        if (typeof paramsInput === "function") {
            params = paramsInput();
        } else {
            params = paramsInput;
        }

        if (attachmentTypeId) {
            params = (params ? params + '&' : '?') + 'attachment_type_id=' + attachmentTypeId;
        }

        if (fileList.length) {
            var fd = new FormData();

            for (var i = 0, f; f = fileList[i]; i++) {
                fd.append('files' + i, f);
            }

            $.ajax({
                url: '/file/upload/' + params,
                data: fd,
                processData: false,
                contentType: false,
                type: 'POST',
                success: function(data) {
                    handleResponse(data, target);
                }
            });
        }
    };
}

function handleUploadResponse(data, target) {
    //if target has child dnd-inner-container, set target to that container
    var dndInnerContainer = target.children('.dnd-inner-container');
    if (dndInnerContainer.length) {
        target = dndInnerContainer;
    }

    if(target.hasClass('tss-form')) {
        TssForm.addAttachment(data.results.obj, target);
        return;
    }

    //this is a hack...will go away...
    if(target.closest('section').length) {
        Attachments.add(data.results.obj, target);
        return;
    }

    var obj = data.results.obj,
        dataElem = $(Handlebars.renderTemplate('old-form-attachment', {name: obj.display_name}));

    dataElem.find('input').val(JSON.stringify(obj));
    target.append(dataElem);
//    addHover();
}

function fileSelectHandler(handleData, useHtml) {
    return function(e) {
        var dataObj = e.dataTransfer || e.clipboardData,
            fileList = e.target.files || dataObj.files,
            t, rows, data = [],
            target = getDropTarget(e.target);

        fileDragHover(e); // cancel event and hover styling

        if (fileList.length) {
            if (typeof FileReader !== "undefined" && $.inArray(fileList[0].name.split('.').pop(), ['xls', 'xlsx']) === -1) {
                for (var i = 0, j = 0, f; f = fileList[i]; i++) {
                    var reader = new FileReader();
                    reader.onload = function(evt) {
                        data = data.concat(CsvToArray(this.result));
                        if (++j == fileList.length) {
                            handleData(data, target);
                        }
                    }
                    reader.readAsText(f);
                }
            } else {
                // send files to the server to get back their contents
                var fd = new FormData();

                for (var i = 0, f; f = fileList[i]; i++) {
                    fd.append('files' + i, f);
                }

                $.ajax({
                    url: '/file/contents/',
                    data: fd,
                    processData: false,
                    contentType: false,
                    type: 'POST',
                    success: function(data){
                        var dataArray = CsvToArray(data.results.contents);
                        handleData(dataArray, target);
                    }
                });
            }
        } else if (useHtml && dataObj.getData && (t = dataObj.getData('text/html'))) {
            t = $(t);
            rows = t.filter('tr'); // Excel

            if (!rows.length) {
                rows = t.find('tr'); // LibreOffice
            }

            // doesn't handle row/colspans, but very useful for newlines in cells
            data = rows.map(function() {
                return [$(this).find('td').map(function() { return $(this).text(); }).get()]
            }).get();
            handleData(data, target);
        } else if (dataObj.getData && (t = dataObj.getData('text/plain'))) {
//            rows = $.trim(t).split(/[\r\n]+/);
//            $.each(rows, function() { data.push(this.split(/\t/)); });
            data = CsvToArray(t, /\t/g); // handles newlines in cells now!
            handleData(data, target);
        }
    };
}

function serverLog(data) {
    $.post('/log/', {data: data});
}

function fixPhone(val) {
    var phone = val.match(/\d/g);

    if (phone && phone.length == 10) {
        phone = phone.join("");
        phone = phone.substr(0, 3) + "-" + phone.substr(3, 3) + "-" + phone.substr(6);
        return phone;
    }

    return val;
}

function isAndroid() {
    return navigator.userAgent.match(/Android/i) != null;
}

function isIOS() {
    return navigator.userAgent.match(/iPad/i) != null
        || navigator.platform.match(/iPhone|iPod/i) != null;
}

function isMobile() {
    return isAndroid() || isIOS();
}

function isMac() {
    return navigator.platform.match(/Mac/i) != null;
}

function isWebkit() {
    return $.browser && $.browser.webkit;
}

//function updateTableHeaderWidths(setMinWidths) {
//    var table = $(this);
//    var firstRow = $('tbody tr:first', table);
//
//    $('thead tr:last-child th, thead tr.headerRow th', table).each(function() {
//        var th = $(this);
//        var td = $('td:eq(' + th.index() + ')', firstRow);
//        var thWidth = th.innerWidth();
//        var tdWidth = td.innerWidth();
//        var adj = (th.is(':last-child') && isWebkit() ? -1 : 0);
//
//        if (setMinWidths) {
//            if (thWidth > tdWidth) {
//                td.css('min-width', thWidth);
//            } else {
//                th.innerWidth(tdWidth + adj);
//            }
//        } else {
//            th.innerWidth(tdWidth + adj);
//        }
//    });
//}

function shouldAddDatepicker() {
    return !isIOS();
}

function shouldHyjack() {
    return !isMobile();
}

function shouldOpenTab(e) {
    return e.which == 2 || e.ctrlKey || e.metaKey || e.shiftKey; // middle click or ctrl+click
}

function singularize(s) {
    if (s && s.length > 1 && s[s.length - 1] == 's') {
        return s.substr(0, s.length - 1);
    }

    return s;
}

function displayItems(i, name, pluralName) {
    if (name && name.endsWith('s')) {
        name = name.substring(0, name.length - 1);
    }

    return i + ' ' + (i == 1 ? name : (pluralName || name + 's'));
}

function drilldown(e) {
    e.preventDefault();

    var id = $(this).attr('groupid');

    if (shouldOpenTab(e)) {
        window.open('/dashboards?student_group_id=' + id, '_blank');
        return false;
    }

    $('input[for=student_group_list]').val($(this).html());
    $('#student_group_list').val(id).change();

    return false;
}

function displayLegendTooltip() {
    var th = $(this);
    var td = $(th.closest('table').find('tbody tr:first-child td').get(th.index()));
    var originalTitle = th.attr('original-title');
    var metadata = th.metadata();
    var rag = metadata.rag;
    var str = '<div>';
    var lastSign = '';
    var lastVal = 0;

    str += '<div class="clearChildren"><div style="float: left">';

    for (var i = 0; i < rag.length; i++) {
        var conf = rag[i];
        var hasMax = conf.hasOwnProperty('max');
        var hasMin = conf.hasOwnProperty('min');

        if (hasMax) {
            lastVal = parseFloat(conf.max);
            lastSign = lastVal ? '&le;' : '='; // don't say &le; 0, it looks weird
        } else if (hasMin) {
            lastVal = parseFloat(conf.min);
            lastSign = '&ge;';
        } else if (lastSign == '&ge;') {
            lastSign = '&lt;';
        } else {
            lastSign = '&gt;';
        }

        var valStr = formatByClass(lastVal, td);
        str += '<div class="' + conf.c + '" style="padding: 5px">' + lastSign + ' ' + valStr + '</div>';
    }

    str += '</div></div>';

    if (originalTitle) {
        str += '<div style="text-align: left; padding-top: 10px">' + originalTitle + '</div>';
    }

    return str + '</div>';
}

function simpleCalculateTotals() {
    return calculateTotals.apply(this); // ignore args that would otherwise get passed by $.each
}

function getAllRows(table) {
    var $table = $(table),
        tablesorterData = $table.data('tablesorter'),
        normalizedRows = tablesorterData && tablesorterData.cache && tablesorterData.cache.length > 0 ? tablesorterData.cache[0].normalized : [],
        rows = normalizedRows ? $.map(normalizedRows, function(arr) { return arr[arr.length - 1].$row.get(); }) : [];

    if (!rows || !rows.length) {
        rows = $table.find('> tbody > tr');
    }

    return $(rows);
}

function getCellIndex(cell, includeSelf = true) {
    var prevCells = cell.prevAll();

    if (includeSelf) {
        prevCells = prevCells.add(cell);
    }

    return prevCells
        .map(function() { return parseInt($(this).attr('colspan')) || 1; })
        .get()
        .reduce((total, num) => total + num, 0);
}

/**
 *  calculateTotals
 *  Will color code a table (green yellow red redstripe) given the acceptable ranges in columnMap.
 *  If a title is present on the table headers, will generate tipsy tooltip with both description and color code legend.
 * 
 * @param {*} columnMap maps column names to color coded ranges. Generated with--SiteConfig::getDashboardColumns();
 * @param {*} averageDigits 
 * @param {*} tipsyCSSWidth a size class contained in tipsy.scss to give to the tipsy tooltip 
 */
function calculateTotals(columnMap, averageDigits, tipsyCSSWidth = `narrow`) {
    averageDigits = averageDigits || 0;

    var parent = this == window ? undefined : this,
        totalsRow = $('tr.totals-row:first', parent),
        averagesRow = $('tr.averages-row:first', parent),
        bothRows = totalsRow.add(averagesRow),
        countRow = bothRows.first(),
        table = countRow.closest('table'),
        body = table.children('tbody'),
//        cells = body.find('tr > td.total, tr > td.average'),
        rows = body.children(),
        numRows = rows.length,
        numCols = rows.first().children().length,
        totals = [],
        counts = [],
        digits = [],
        indexToCell = {},
        rowCache = getAllRows(table); // for tablesorter pager tables. this is the full list of rows.

    if (rowCache.length) {
        numRows = rowCache.length;
//        cells = rowCache.children('td.total, td.average');
        rows = rowCache;
    }

    if (bothRows.parent().is('tfoot')) {
        bothRows.first().css('border-top', '3px double black');
    } else {
        bothRows.last().css('border-bottom', '3px double black');
    }

    if (columnMap) {
        $.each(columnMap, function(k, v) {
            var metadata = JSON.parse(v),
                cells = $('td[name="' + k + '"]'),
                indexArr = cells.map(function() { return $(this).index(); }),
                th = $('thead tr:last-child th, thead tr.headerRow th', cells.closest('table')).filter(function() {
                    return $.inArray($(this).index(), indexArr) >= 0;
                });

            cells.data('metadata', metadata);
            if (th.length) {
                th.data('metadata', metadata).each(function() {
                    $(this).tipsy({gravity: 's', className: `white ${tipsyCSSWidth}`, title: displayLegendTooltip, html: true, opacity: 1});
                });
            }
        });
    }

    if (numCols) totalsRow.append(Array(Math.max(0, numCols - getCellIndex(totalsRow.find('td:last')) + 1)).join('<td></td>'))
    if (numCols) averagesRow.append(Array(Math.max(0, numCols - getCellIndex(averagesRow.find('td:last')) + 1)).join('<td></td>'))

    var countCell = countRow.children('td:first'),
        oldText = countCell.text() || 'Totals',
        paren = oldText.indexOf('(');

    if (paren != -1) {
        oldText = oldText.substr(0, paren);
    }

    if (!countCell.data('nocount')) {
        countCell.text(oldText + ' (' + numRows + ')');
    }

    rows.each(function() {
        var $row = $(this);

        $row.children('.total, .average').each(function() {
            var $td = $(this),
                j = $td.index(),
                text,
                numVals = 1;

            if ($td.hasClass('uniq')) {
                if (!totals[j]) { totals[j] = {}; }
                totals[j][text || (text = $td.text())] = 1;
            } else {
                var data = $td.data(),
                    sortval,
                    val = myParseFloat(data.ragval !== undefined ? data.ragval
                                       : ((sortval = $td.attr('sortval')) !== undefined ? sortval : (text = $td.text()))),
                    totalVal = data.total !== undefined ? parseFloat(data.total) : val;

                numVals = data.count !== undefined ? parseFloat(data.count) : (totalVal !== '' ? 1 : 0);
                totals[j] = (totals[j] || 0) + (totalVal || 0);
                addConditionalFormatting($td, val, $td.metadata().rag);
            }

            // Only count rows with grade data. Standards Based view shows all
            // assessments regardless of whether or not grade data is present.
            if (!isNaN(numVals)) {
                counts[j] = (counts[j] || 0) + numVals;
            }

            if ($td.hasClass('sigfig')) {
                digits[j] = Math.max(digits[j] || 0, numDigits(text || (text = $td.text())));
            }

            if (!(j in indexToCell)) {
                indexToCell[j] = $td;
            }
        });
    });

    $.each(indexToCell, function(j, $td) {
        var totalCell = totalsRow.find('td[data-column="' + j + '"], td:eq(' + j + ')').first(),
            averageCell = averagesRow.find('td[data-column="' + j + '"], td:eq(' + j + ')').first(),
            total = totals[j] || 0,
            count = counts[j] || 0,
            numDigits = $td.data('digits');

        if ($td.hasClass('sigfig')) {
             numDigits = digits[j];
        }

        if (!averageCell.length && $td.hasClass('average')) {
            averageCell = totalCell;
        }

        if (!totalCell.length && $td.hasClass('total')) {
            totalCell = averageCell;
        }

        if ($td.hasClass('uniq')) {
            totalCell.html(Object.keys(total).length + ' / ' + count);
            totalCell.attr('title', Object.keys(total).length + ' unique out of ' + count + ' total');
            totalCell.attr('class', $td.attr('class'));
        } else {
            if ($td.hasClass('total') && totalCell.length) {
                if ($td.hasClass('aoutofb')) {
                    totalCell.html(total + ' / ' + count);
                    totalCell.attr('title', total + ' out of ' + count + ' total');
                    totalCell.attr('class', $td.attr('class')).removeClass('deactivated');
                } else {
                    totalCell.html(formatByClass(total, $td, numDigits !== undefined ? numDigits : 0));
                    totalCell.attr('class', $td.attr('class')).removeClass('deactivated');
                    addConditionalFormatting(totalCell, total, $td.metadata().rag);
                }
            }

            if (averageCell.length && ($td.hasClass('average') || totalCell != averageCell)) {
                var average = count ? ($td.hasClass('aoutofb') ? 100 : 1) * total / count : 0,
                    formattedAverage = formatByClass(average, $td, numDigits !== undefined ? numDigits : averageDigits),
                    roundedAverage = myParseFloat(formattedAverage);

                averageCell.html('' + formattedAverage + ($td.data('ragtext') ? ' ' + $td.data('ragtext') : ''));
                averageCell.attr('title', ($td.hasClass('aoutofb') ? total : formatByClass(total, $td, 0)) + " / " + count);
                averageCell.attr('class', $td.attr('class')).removeClass('deactivated');
                averageCell.data('total', total);
                averageCell.data('count', count);

                addConditionalFormatting(averageCell, roundedAverage, $td.metadata().rag);

                if ($td.hasClass('total') && totalCell.length) {
                    // if there's an average and a total, use the average color to determine
                    // the total's coloring since the average is in the same space as the individual rows.
                    addConditionalFormatting(totalCell, roundedAverage, $td.metadata().rag);
                }
            }
        }
    });

    // clean up gpa columns
    $('thead tr:last-child th.gpa').each(function() {
        var th = $(this);
        var index = th.index();
        if (!$('.averages-row td:eq(' + index + ')').data('total')) {
            $('tbody tr, tfoot tr').each(function() {
                var tr = $(this);
                $('td:eq(' + index + ')', tr).remove();
            });
            th.remove();
        }
    });
    $('#gpaParent').attr('colspan', $('thead tr:last-child th.gpa').length);
}

function numDigits(str) {
    return str ? ('' + str).replace(/^[^.]*\.?/, '').replace(/[$%,]/g, '').length : 0;
}

function myParseFloat(str) {
    if (typeof(str.replace) === 'function') {
        str = str.replace(/[$%,]/g, '');
    }

    return str === '' ? '' : parseFloat(str);
}

function addConditionalFormatting($elem, val, rag) {
    if (!rag || isNaN(val)) return null;

    $elem.removeClass('g y o r redstripe'); // reset

    for (var i = 0; i < rag.length; i++) {
        var conf = rag[i];
        var hasMax = conf.hasOwnProperty('max');
        var hasMin = conf.hasOwnProperty('min');

        if ((hasMax && val !== '' && val <= conf.max) || (hasMin && val !== '' && val >= conf.min) || (!hasMax && !hasMin)) {
            $elem.addClass(conf.c);
            return conf.c;
        }
    }

    return null;
}

function formatByClass(value, elem, digits) {
    var $elem = $(elem);

    if ($elem.hasClass('gpa')) {
        return Tss.Number.toCommas(value, 2);
    }

    if ($elem.hasClass('cash')) {
        return Tss.Number.toMoney(value, $elem.data('digits') || 0, undefined, undefined, $elem.hasClass('nosymbol') ? '' : undefined);
    }

    if ($elem.hasClass('pct')) {
        return (value).toFixed(0) + '%';
    }

    if ($elem.hasClass('rawscore')) {
        return Tss.Number.toCommas(value, 2);
    }

    if ($elem.hasClass('minutes')) {
        return Math.floor(value / 60).toFixed(0) + ':' + (value % 60).toFixed(0).replace(/^(\d)$/, "0$1");
    }

    if ($elem.hasClass('frac')) {
        $elem.attr('title', value);
        return toFraction(value, $elem.data('den'), true);
    }

    return Tss.Number.toCommas(value, (digits || $elem.data('digits') || 0));
}

function toFraction(decimal, denominator, hideLeadingZero) {
    var intPart = ~~decimal, // double bitwise not = cast to int
        numerator = Math.round((decimal - intPart) * denominator);

    if (numerator == 0) {
        return intPart;
    }

    if (numerator == denominator) {
        return intPart + 1;
    }

    return (hideLeadingZero && intPart == 0 ? '' : intPart + ' ') + `<sup>${numerator}</sup>/<sub>${denominator}</sub>`.trim();
}

function getOffset(e, elem) {
    var posx = 0;
    var posy = 0;
    var offset = $(elem).offset();

    if (!e) var e = window.event;

    if (e.pageX || e.pageY) {
        posx = e.pageX;
        posy = e.pageY;
    } else if (e.clientX || e.clientY) {
        posx = e.clientX + document.body.scrollLeft + document.documentElement.scrollLeft;
        posy = e.clientY + document.body.scrollTop + document.documentElement.scrollTop;
    }

    return {x: posx - offset.left, y: posy - offset.top};
}

function sortAssessmentSlips(w) {
    var slips = $('.assessmentSlip', w.document),
        sort1 = $('select[name="sort1"]', w.document).val(),
        sort2 = $('select[name="sort2"]', w.document).val(),
        row = $('.row:first', w.document),
        slipsPerRow = 4,
        i = 0,
        newSlips = slips.sort(function(a, b) {
            var $a = $(a),
                $b = $(b),
                aVal1 = $a.data(sort1),
                bVal1 = $b.data(sort1),
                aVal2 = $a.data(sort2),
                bVal2 = $b.data(sort2);

            if (aVal1 != bVal1) {
                return aVal1 < bVal1 ? -1 : 1;
            }

            if (aVal2 != bVal2) {
                return aVal2 < bVal2 ? -1 : 1;
            }

            return 0;
        });

    slips.remove();
    newSlips.each(function() {
        row.append(this);

        if (++i == slipsPerRow) {
            i = 0;
            row = row.next();
        }
    });
}

/**
 * delete arr[index] only sets arr[index] = undefined.
 * Use this function if you actually want your array to be one element smaller.
 */
function arrayRemove(arr, value) {
    if (!arr) return;

    var index = arr.indexOf(value);

    if (index < 0) return;

    arr.splice(index, 1);
}

/**
 * Return an array of arrays of strings.
 */
function CsvToArray(result, sep) {
    var data = [],
        rows = $.trim(result).split(/[\r\n]+/),
        bad = [],
        i,
        m,
        b,
        len,
        hasOddQuotes,
        inQuotes;

    for (i = rows.length - 1; i > 0; i--) {
        // find all the unescaped quotes on the line:
        m = rows[i].match(/[^\\"]?\"/g);

        // if there are an odd number of them, this line, and the line after it is bad:
        hasOddQuotes = (m ? m.length : 0) % 2 == 1;

        if (hasOddQuotes) {
            if (!inQuotes) {
                inQuotes = true;
                bad.push(i); // append row i to row i - 1 in the next loop below
            } else {
                inQuotes = false; // we found the matching odd quote
            }
        } else if (inQuotes) {
            bad.push(i); // keep going until we find the matching odd quote
        }
    }

    // starting at the bottom of the list, merge lines back, using \r\n
    for (b = 0, len = bad.length; b < len; b++) {
        rows.splice(bad[b] - 1, 2, rows[bad[b] - 1] + "\n" + rows[bad[b]]);
    }

    $.each(rows, function() {
        var row = this;

        if (!sep) { // guess tab or comma separated
            var tabSep = /\t/g,
                tabs = row.match(tabSep),
                numTabs = tabs ? tabs.length : 0,
                commas = row.match(/,/g),
                numCommas = commas ? commas.length : 0;

            sep = (numTabs > numCommas ? tabSep : ',');
        }

        var arr = splitCSV(row, sep);
        data.push(arr);
    });

    return data;
}

function splitCSV(row, sep) {
    sep = sep || ',';

    for (var arr = row.split(sep), x = arr.length - 1, tl; x >= 0; x--) {
        if (arr[x].replace(/"\s+$/, '"').charAt(arr[x].length - 1) == '"') {
            if ((tl = arr[x].replace(/^\s+"/, '"')).length > 1 && tl.charAt(0) == '"') {
                arr[x] = arr[x].replace(/^\s*"|"\s*$/g, '').replace(/""/g, '"');
            } else if (x) {
                arr.splice(x - 1, 2, [arr[x - 1], arr[x]].join(sep));
            } else {
                arr = arr.shift().split(sep).concat(arr);
            }
        } else {
            arr[x].replace(/""/g, '"');
        }
    }

    return arr;
}

function getStudentSelect(destination) {
    $(destination).tssLoad('/get/student/select/');
}

// . ($withCourseName ?  "cdef.name,    ', ', " : '') // course name,
// . ($withTeacherName ? "sm.last_name, ' ',  " : '') // teacher name
// . "(CASE WHEN sec.number IS NOT NULL THEN CONCAT('[', sec.number, '] ') ELSE '' END), " // [section number]
// . $periodSql
function getSectionDisplayName(section, wantsHtml) {
    var sectionPeriods = _.chain(section)
        .get('section_periods')
        .filter(x => x.active == 1)
        .value();
    var mappedCourseNames = _.chain(sectionPeriods)
        .map(sp => _.chain(sp.section_mappings || [])
            .filter(m => m.active == 1)
            .map(m => (wantsHtml ? `<a href="/setup/configure/section-periods/edit/${sp.section_period_id}" target="_blank">` : '')
                    + `${m.course.name}, ${sp.staff_member.last_name} [${section.number}] (${sp.period.short_name})`
                    + (wantsHtml ? '</a>' : '')
            )
            .value()
        )
        .flatten()
        .filter()
        .value();
    var courseDefinitionNames = _.map(sectionPeriods,
        sp => (wantsHtml ? `<a href="/setup/configure/section-periods/edit/${sp.section_period_id}" target="_blank">` : '')
            + `${section.course_definition.name}, ${sp.staff_member.last_name} [${section.number}] (${sp.period.short_name})`
            + (wantsHtml ? '</a>' : '')
        );
    var namesArray = mappedCourseNames.length
        ? mappedCourseNames
        : courseDefinitionNames;
    var names = _.chain(namesArray)
        .sort()
        .uniq()
        .join('<br/>')
        .value();

    return names;
}

/**
 * Return array of string values, or NULL if CSV string not well formed.
 */
//function CsvRowToArray(text) {
//    var reValid = /^\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*(?:,\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*)*$/;
//    var reValue = /(?!\s*$)\s*(?:'([^'\\]*(?:\\[\S\s][^'\\]*)*)'|"([^"\\]*(?:\\[\S\s][^"\\]*)*)"|([^,'"\s\\]*(?:\s+[^,'"\s\\]+)*))\s*(?:,|$)/g;
//    var a = [];
//
//    if (!reValid.test(text)) return null;
////    text = text.replace(/,,/g, ',"",').replace(/,,/g, ',"",');
//    text.replace(reValue, // "Walk" the string using replace with callback.
//        function(m0, m1, m2, m3) {
//            if      (m1) a.push(m1.replace(/\\'/g, "'")); // Remove backslash from \' in single quoted values.
//            else if (m2) a.push(m2.replace(/\\"/g, '"')); // Remove backslash from \" in double quoted values.
//            else if (m3) a.push(m3);
//            else a.push('');
//
//            return ''; // Return empty string.
//        }
//    );
//
//    if (/,\s*$/.test(text)) a.push(''); // Handle special case of empty last value.
//
//    return a;
//}

window.getStateKeys = getStateKeys;
window.getStatefulURL = getStatefulURL;
window.loadDivChange = loadDivChange;
window.studentGroupChange = studentGroupChange;
window.pushState = pushState;
window.replaceState = replaceState;
window.getSelectorForStateKey = getSelectorForStateKey;
window.getCurrentState = getCurrentState;
window.setSessionState = setSessionState;
window.isNumber = isNumber;
window.addBusDays = addBusDays;
window.cleanElementId = cleanElementId;
window.load = load;
window.deleteRow = deleteRow;
window.insertRowBefore = insertRowBefore;
window.insertRowAfter = insertRowAfter;
window.resetTssSelects  = resetTssSelects ;
window.selectStudentName = selectStudentName;
window.showDemeritAttrs = showDemeritAttrs;
window.removeRow = removeRow;
window.fillDown = fillDown;
window.setupAddAndClear = setupAddAndClear;
window.addAndClear = addAndClear;
window.addGroup = addGroup;
window.expandGroup = expandGroup;
window.removeParent = removeParent;
window.addParent = addParent;
window.getFilters = getFilters;
window.filter = filter;
window.handleFilterResponse = handleFilterResponse;
window.rowIdToId = rowIdToId;
window.safeUpper = safeUpper;
window.clearFilters = clearFilters;
window.getFilterState = getFilterState;
window.getFilterMap = getFilterMap;
window.getFilterString = getFilterString;
window.cleanKey = cleanKey;
window.replaceBrackets = replaceBrackets;
window.nameFilter = nameFilter;
window.getFilterDisplay = getFilterDisplay;
window.getFilterDisplayValue = getFilterDisplayValue;
window.updateVisibleGradeLevels = updateVisibleGradeLevels;
window.time = time;
window.timeEnd = timeEnd;
window.setupSlider = setupSlider;
window.addHover = addHover;
window.grabFocus = grabFocus;
window.getStatusBox = getStatusBox;
window.serializeObject = serializeObject;
window.serialize = serialize;
window.isChangedFrom = isChangedFrom;
window.removeSimpleChangeListener = removeSimpleChangeListener;
window.setupSimpleChangeListener = setupSimpleChangeListener;
window.simpleSnapshotForChanges = simpleSnapshotForChanges;
window.pauseOnBeforeUnload = pauseOnBeforeUnload;
window.snapshotForChanges = snapshotForChanges;
window.confirmSave = confirmSave;
window.showConfirmDialog = showConfirmDialog;
window.formSubmit = formSubmit;
window.validateForm = validateForm;
window.getFormData = getFormData;
window.getSectionDisplayName = getSectionDisplayName;
window.onSubmit = onSubmit;
window.handlePost = handlePost;
window.handleInPlaceEdit = handleInPlaceEdit;
window.flashElem = flashElem;
window.clearNotifications = clearNotifications;
window.displayNotifications = displayNotifications;
window.formatNotifications  = formatNotifications ;
window.displayNotification = displayNotification;
window.displayError = displayError;
window.displayWarning = displayWarning;
window.displayInfo = displayInfo;
window.updateSeatingChart = updateSeatingChart;
window.updatePoints = updatePoints;
window.multiplierWarning = multiplierWarning;
window.numDaysWarning = numDaysWarning;
window.negativeDetentionsWarning = negativeDetentionsWarning;
window.negativeMultiplierWarning = negativeMultiplierWarning;
window.fieldWarning = fieldWarning;
window.edit = edit;
window.addRowDetail = addRowDetail;
window.loadStudentInfo = loadStudentInfo;
window.loadStudentDetentions = loadStudentDetentions;
window.activate = activate;
window.tableSorterExtraction = tableSorterExtraction;
window.defaultFromJson = defaultFromJson;
window.fillInForm = fillInForm;
window.fillInFilters = fillInFilters;
window.dateStringToUTCDate = dateStringToUTCDate;
window.dateStringToDate = dateStringToDate;
window.getDateInputFormat = getDateInputFormat;
window.dateStringToInput = dateStringToInput;
window.dateStringToDisplay = dateStringToDisplay;
window.dateStringTo1stString = dateStringTo1stString;
window.dateStringToMondayString = dateStringToMondayString;
window.serverToDate = serverToDate;
window.dateToServer = dateToServer;
window.quickDateStringToDisplay = quickDateStringToDisplay;
window.timeStringToInput = timeStringToInput;
window.getNowString = getNowString;
window.dateToSort = dateToSort;
window.datetimeSortToServer = datetimeSortToServer;
window.dateStringToServer = dateStringToServer;
window.fixPropertyDivs = fixPropertyDivs;
window.browserFormatsDates = browserFormatsDates;
window.browserFormatsTimes = browserFormatsTimes;
window.fixDateInputs = fixDateInputs;
window.setupUI = setupUI;
window.updateEqualWidthFields = updateEqualWidthFields;
window.createDefaultSummaryTable = createDefaultSummaryTable;
window.createSummaryTable = createSummaryTable;
window.addChangeListeners = addChangeListeners;
window.tablesorter = tablesorter;
window.forceLayout = forceLayout;
window.isInIFrame = isInIFrame;
window.updateMultiButtonDisplay = updateMultiButtonDisplay;
window.getShortcutLabelPrefix = getShortcutLabelPrefix;
window.getSimpleShortcutLabelPrefix = getSimpleShortcutLabelPrefix;
window.labelShortcutKeys = labelShortcutKeys;
window.clickThis = clickThis;
window.labelShortcutKey = labelShortcutKey;
window.addShortcutKey = addShortcutKey;
window.getSelectedOption = getSelectedOption;
window.hyjackSelect = hyjackSelect;
window.multiSelect = multiSelect;
window.multiSelectSingleStudent = multiSelectSingleStudent;
window.delayFunction = delayFunction;
window.multiSelectAutocomplete = multiSelectAutocomplete;
window.getHyjackDefaults = getHyjackDefaults;
window.multiSelectTextAdvanced = multiSelectTextAdvanced;
window.getMultiSelectDefaults = getMultiSelectDefaults;
window.camelizeMap = camelizeMap;
window.camelize = camelize;
window.removeUserVoice = removeUserVoice;
window.handleRowEvent = handleRowEvent;
window.stopPropagation = stopPropagation;
window.styleCourseGradesHeaders = styleCourseGradesHeaders;
window.rowsToValues = rowsToValues;
window.headerRowsToValues = headerRowsToValues;
window.valuesToRows = valuesToRows;
window.getVisibleRows = getVisibleRows;
window.genericDownload = genericDownload;
window.genericExportTable = genericExportTable;
window.genericExport = genericExport;
window.getDropTarget = getDropTarget;
window.getRelatedTarget = getRelatedTarget;
window.fileDragHover = fileDragHover;
window.uploadAttachment = uploadAttachment;
window.handleUploadResponse = handleUploadResponse;
window.fileSelectHandler = fileSelectHandler;
window.serverLog = serverLog;
window.fixPhone = fixPhone;
window.isAndroid = isAndroid;
window.isIOS = isIOS;
window.isMobile = isMobile;
window.isMac = isMac;
window.isWebkit = isWebkit;
window.isFirefox = isFirefox;
window.isChrome = isChrome;
window.shouldAddDatepicker = shouldAddDatepicker;
window.shouldHyjack = shouldHyjack;
window.shouldOpenTab = shouldOpenTab;
window.singularize = singularize;
window.displayItems = displayItems;
window.drilldown = drilldown;
window.displayLegendTooltip = displayLegendTooltip;
window.getCellIndex = getCellIndex;
window.simpleCalculateTotals = simpleCalculateTotals;
window.getAllRows = getAllRows;
window.calculateTotals = calculateTotals;
window.numDigits = numDigits;
window.myParseFloat = myParseFloat;
window.addConditionalFormatting = addConditionalFormatting;
window.formatByClass = formatByClass;
window.toFraction = toFraction;
window.getOffset = getOffset;
window.sortAssessmentSlips = sortAssessmentSlips;
window.arrayRemove = arrayRemove;
window.CsvToArray = CsvToArray;
window.splitCSV = splitCSV;
window.getStudentSelect = getStudentSelect;
window.initializeInputFileUpload = initializeInputFileUpload;

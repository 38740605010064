(function($, window) { "use strict";
    var Student = {
        init: function($options) {
            var $student = this;

            if ($options) {
                _.extend($student, $options);
            }

            $student.setupUI();
            $student.wrapSections();
            $student.setupPrintHandler();
            $student.setupPrintLayout();
            $student.setupAssessments();

            if (!$student.tooltip) {
                $student.setSessionState();
            }

            $student.setBindings();
        },

        setBindings: function() {
            var $showHistoricButton = $("[id='show-historic']"),
                $showCurrentButton = $("[id='show-current']");

            $showHistoricButton.on("click", this.toggleAssessments);
            $showCurrentButton.on("click", this.toggleAssessments);
        },

        // add show historic/current buttons to assessments block and hide historic by default
        setupAssessments: function() {
            var $assessmentHeader = $("[name='assessments'] .propertyHeader"),
                $assessments = $("[name='assessments']"),
                $buttonsContainer = '<div class="actions">' +
                    '<a id="show-historic">show historical</a>' +
                    '<a class="hidden" id="show-current">show current</a>' +
                    '</div>',

            $historicAssessmentsTable = $("[name='historic assessments'] table"),
            $assessmentsTable = $("[name='assessments'] table");

            $historicAssessmentsTable.addClass('hidden');
            $historicAssessmentsTable.attr('name', 'historic');
            $assessmentsTable.attr('name', 'current');

            $assessmentHeader.after($buttonsContainer);
            $assessments.append($historicAssessmentsTable);

            $("[name='historic assessments']").remove();
        },
        // We have to explicitly apply setupUI in order to get the table sorter
        // to work. This is because the normal set of events required to trigger
        // setupUI do not exist if in tooltip view.
        setupUI: function() {
            var $student = this;

            if ($student.tooltip) {
                // tss.js setupUI method call
                setupUI.apply($('[rel="student-' + $student.id + '-course-grades-table"]'));
            }
            
            var Thumb = new Tss.Thumb({
                type: 'student',
                title: $student.studentTitle,
                recordId: $student.id
            });
        },

        /**
         * Setup print button handler to grab user-requested filters and
         * sections to add to the print url query string. After creating the url,
         * redirect to the print page in a new window.
         */
        setupPrintHandler: function() {
            var $student = this;

            new Tss.Modal({
                launchTarget: '.student-print-options',
                template: 'student-print-options-modal',
                settingName: $student.settingName,
                getData: function() {
                    return $student.initialSettings || {withPageBreaks: true}; // default
                },
                getSettings: function(template) {
                    // order of the sections is important since we force
                    // all_properties and grades to be the first two sections
                    // in updateFromFilters. So force those two to be first
                    // in the array so that page breaks go in the right places
                    var selectedSections = template.find('.sections input[type="checkbox"]:checked').map((i, x) => $(x).attr('name')).get();
                    var firstTwoSections = _.intersection(selectedSections, ['all_properties', 'grades']);
                    var restOfTheSections = _.pullAll(selectedSections, ['all_properties', 'grades']);
                    var sections = firstTwoSections.concat(selectedSections);
                    
                    return {
                        sections,
                        withPageBreaks: Object.keys(template.find('input[name="with-page-breaks"]').serializeJSON()).length
                    };
                },
                getUrl: function(settings, template) {
                    return $student.getPrintUrl(settings);
                }
            });
        },

        getPrintUrl: function(settings) {
            // slip end date defaults to today if you haven't rendered the slips tab
            var $student = this,
                sections = settings.sections.join(','),
                withPageBreaks = settings.withPageBreaks,
                endDate = $('#slips-date').length > 0 ? $('#slips-date').datepicker('getDate') : new Date(),
                filters = {
                    demerits_filter: getFilters($('#demerits-history').find('.filterContainer')),
                    absences_filter: getFilters($('#absences-history').find('.filterContainer')),
                    'class-absences_filter': getFilters($('#class-absences-history').find('.filterContainer')),
                    incidents_filter: getFilters($('#incidents-history').find('.filterContainer')),
                    communications_filter: getFilters($('#communications-history').find('.filterContainer')),
                    slips_filter: {'end_date': $.datepicker.formatDate('yy-mm-dd', endDate)}
                    // Format slips filter params so that it is easiliy digestible
                    // current /slips/print endpoint
                },
                url = '/student/' + $student.id + '?printable=1&show_logo=1'
                    + '&sections=' + (sections || '')
                    + '&filters=' + encodeURIComponent(JSON.stringify(filters))
                    + (withPageBreaks ? '&with_page_breaks=1' : '');
            
            return url;
        },

        /**
         * Based on the filters and sections in the print url's query params,
         * create the layout & content of the print page.
         */
        setupPrintLayout: function() {
            var $student = this,
                sections = $student.sections ? $student.sections.split(',') : [],
                filters = $student.filters;

            // print setup!
            if (Object.keys(filters).length) {
                $student.updateFromFilters(sections, filters);
            } else {
                $('#tabs').tabs({
                    beforeLoad: function(event, ui) {
                        if (ui.panel.children().length) { // already loaded
                            event.preventDefault();
                        }
                    }
                });
            }
        },

        /**
         * Sets session state based on whether or not tooltip is requested
         * in the url query string.
         */
        setSessionState: function() {
            var $student = this,
                state = {'student_id': $student.studentId};

            if ($student.studentGroupId) {
                state['student_group_id'] = $student.studentGroupId;
            }

            // tss.js method
            setSessionState(state);

        },

        toggleAssessments: function() {
            var $historicAssessmentsTable = $("[name='historic']"),
                $currentAssessmentsTable = $("[name='current']"),
                $showHistoricButton = $("[id='show-historic']"),
                $showCurrentButton = $("[id='show-current']");

            $historicAssessmentsTable.toggleClass('hidden');
            $currentAssessmentsTable.toggleClass('hidden');

            $showHistoricButton.toggleClass('hidden');
            $showCurrentButton.toggleClass('hidden');
        },

        /**
         * Load section content
         */
        updateFromFilters: function(sections, filterMap) {
            var $student = this,
                tabs = $('#tabs'),
                newDivKey = 'output_div',
                newDiv = $('#' + newDivKey);
            
            filterMap = filterMap || {};

            if (!newDiv.length) {
                newDiv = $('<div/>').attr('id', newDivKey);
                $.each(sections, function(i, section) {
                    if (section == 'all_properties' || section == 'grades') {
                        return true; // continue
                    }

                    var elem = tabs.find(`[name="${section}"]`); // for class-attendance
                    newDiv.append(elem.length ? elem : $('<div/>').attr('name', section));
                });
                tabs.after(newDiv);
            }

            tabs.hide();

            var allPropertiesIndex = $.inArray('all_properties', sections),
                allProperties = $('.studentInfo > .propertyInfo[name!="grades"], .studentAlerts'),//.wrap('<div/>'),
                gradesIndex = $.inArray('grades', sections),
                grades = $('.studentInfo > .propertyInfo[name="grades"]'),//.wrap('<div/>'),
                numSections = sections.length;
            
            $student.setupSection(allPropertiesIndex, allProperties, numSections);
            $student.setupSection(gradesIndex, grades, numSections);

            newDiv.children().hide();
            $.each(sections, function(i, section) {
                var existingDiv = $('div[name="' + section + '"]').show(),
                    a = tabs.find('li a[href^="/' + section + '/"]'),
                    url = a.attr('href'),
                    key = section + '_filter';

                if (!a.length || !url || url[0] == '#') {
                    $student.setupSection(i, existingDiv, numSections);

                    return true; // continue;
                }

                url += (url.indexOf('?') >= 0 ? '&' : '?');

                if (key in filterMap) {
                    // Do not add filter param to query string unless it is the slips section.
                    // The slips/print url is the only one that does not need the filter param.
                    if (section === 'slips') {
                        url += 'end_date=' + encodeURIComponent(filterMap[key]['end_date']);
                    } else {
                        url += 'filter=' + encodeURIComponent(JSON.stringify(filterMap[key]));
                    }
                }

                existingDiv.tssLoad(url, () => { $student.setupSection(i, existingDiv, numSections); });
            });
        },
        
        setupSection: function(i, section, numSections) {
            var $student = this;

            // section doesn't exist on the page or has already been set up
            if (!section.length || section.data('setup-once')) {
                return;
            }

            if (i >= 0) {
                section.data('setup-once', true).show();
                
                if ($student.withPageBreaks) {
                    if (i != 0) {
                        // insert Schoolrunner logo and student name on all but
                        // the first page
                        section.before($('.header_image:first').clone());
                        section.before($('.photo-header [name="student-name"]:first').clone());
                    }
                    
                    if (i != numSections - 1) {
                        // insert a page break after all but the last page.
                        // .last() is in case there are multiple items in the
                        // section jQuery object like there are for
                        // all_properties.
                        section.last().addClass('pageBreakAfter');
                    }
                }
            } else {
                section.remove();
            }
        },

        /**
         * Adds the classes sectionBegin and sectionEnd to the sections
         * Scholar, Home, Assessments, School, and Grades
         */
        wrapSections: function() {
            var $student = this;
            var spanId = 'student_' + $student.id;
            var wrapper = $('#' + spanId);
            var contactNames = $('tr[name^="contact_"]').map(function() {
                return $(this).attr('name').replace(/^(contact_-?[0-9]+).*$/, '$1');
            }).get();

            $('tr[name*="LA_504"]:first', wrapper).addClass('sectionBegin');
            $('tr[name*="LA_504"]:last', wrapper).addClass('sectionEnd');

            _.chain(contactNames)
                .sort()
                .uniq()
                .map(name => {
                    $('tr[name^="' + name + '_"]:first:not(:first-child)', wrapper).addClass('sectionBegin');
                    $('tr[name^="' + name + '_"]:last:not(:last-child)', wrapper).addClass('sectionEnd');
                })
                .value();
        },
    };

    window.Tss = window.Tss || {};
    window.Tss.Student = Student;

})(jQuery, window);

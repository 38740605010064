/**
 * 
 * @author cmitchell
 */
(function($) {
    $.fn.tssIconToggle = function(options){
        //set default options
        var defaultOptions = {
            onIcon: 'ban-circle',
            offIcon: 'ok',
            onValue: 1,
            offValue: 0,
            onTooltip: 'Deactivated - click to activate',
            offTooltip: 'Active - click to deactivate',
            flipIcons: false
        };
        
        //merge with passed options
        options = $.extend(defaultOptions, (typeof options == 'object' ? options : {}));
        
        return this.each(function(){
            //get input into scoped variable
            var inputElement = $(this);
            
            //check if has already had this plugin applied or is not hidden input
            if (inputElement.hasClass('is-tss-icon-toggle') || !inputElement.is('input[type="hidden"]')) {
                return inputElement;
            }
            
            //process flip if needed
            if(options.flipIcons) {
                var originalOptions = $.extend(options);
                options.offIcon = originalOptions.onIcon;
                options.onIcon = originalOptions.offIcon;
            }
            
            //add class so we know that we've converted it to a tss select
            inputElement.addClass('is-tss-icon-toggle');

            //wrap input
            inputElement.wrap('<div class="tss-icon-toggle" />');

            //create icon
            var icon = $('<i tss-tooltip/>');

            //add icon
            inputElement.before(icon);

            //update function
            var update = function() {
                icon.attr('class', 'icon-' + (inputElement.val() == options.onValue ? options.offIcon + ' on' : options.onIcon));
                icon.attr('title', (inputElement.val() == options.onValue ? options.offTooltip : options.onTooltip));
            };
            
            var mouseEnter = function() {
                icon.attr('class', 'icon-' + (inputElement.val() == options.onValue ? options.onIcon : options.offIcon + ' on'));
            };
            
            var mouseLeave = function() {
                icon.attr('class', 'icon-' + (inputElement.val() == options.onValue ? options.offIcon + ' on' : options.onIcon));
            };

            //select switch
            update();

            //set bindings
            icon.click(function(){
                var val = inputElement.val();
                inputElement.val((val == options.onValue ? options.offValue : options.onValue)).trigger('change');
            });
            inputElement.change(update);
            icon.hover(mouseEnter, mouseLeave);
        });
    };
}(jQuery));
import io from 'socket.io-client';

class Notifications {
    constructor() {
        this.getApi = '/api/v1/me/notification-users?expand=notification';
        this.updateApi = '/api/v1/bulk/notification-users';
        this.socketNamespace = '/';
    }

    async init() {
        $('body').append('<div class="notification-updates"></div>');

        this.menu = $('.notifications-menu');
        this.badge = $('.notifications-badge-count');
        this.popover = $('.notifications-popover');
        this.fullList = $('.v_notifications .notifications-list');
        this.updates = $('.notification-updates');
        this.userId = this.menu.data('user-id');
        this.token = this.menu.data('token');
        this.manuallyMarkedUnread = []

        if (!this.token) {
            return;
        }

        let onFullNotificationsPage = this.isOnFullNotificationsPage();

        let notificationUserParams = {
            unread_only: onFullNotificationsPage ? 0 : 1,
        };

        // we only care about web-configured notifications unless we're on the
        // View All page in which case we want to show all notifications
        if (!onFullNotificationsPage) {
            notificationUserParams['web'] = 1;
        }

        this.notificationUsers = await this.getNotificationUsers(notificationUserParams);

        this.setupBindings();
        this.setupPushNotifications();

        this.renderPopover();
        this.renderFullList();
        this.updateUnreadCount();
    }

    setupBindings() {
        // why doesn't it fire with the $('body').on('click') pattern?
        $('.notifications-menu').click(e => this.markVisibleAsRead(e));
        if (this.isOnFullNotificationsPage()) {
            $(document).scroll(e => this.markVisibleAsRead(e));
        }
        $('body').on('click', '.notifications-mark-all-as-read', event => this.markAllRead(event));
        $('body').on('click', '.notification-unread .notification-status', event => this.markRead(event));
        $('body').on('click', '.notification-read .notification-status', event => this.markUnread(event));
        $('body').on('click', '.notification', event => this.handleClick(event));

        $('body').on('mouseenter', '.notification-updates', event => this.notificationUpdatesMouseEnter(event));
        $('body').on('mouseleave', '.notification-updates', event => this.notificationUpdatesMouseLeave(event));
        $('body').on('click', '.notification-update .remove', event => this.notificationUpdateRemove(event));
        $('body').on('click', '.notification-update', event => this.notificationUpdateClick(event));
    }

    renderPopover() {
        // in the popover, only show web-configured notifications
        // e.g. could be mobile-only alerts if subscribed to student group
        // changes for mobile only. full page will still show all.
        let notificationUsers = _.filter(this.notificationUsers,{ timestamp_read: null, web: '1' });
        let notificationsHtml = Handlebars.renderTemplate('notification-users', { notificationUsers });
        let popover = Handlebars.renderTemplate('notifications-popover', { notificationsHtml });
        this.popover.html(popover);
        // has to be bound after handlebars template rendered
        $('.notifications-list').scroll(e => this.markVisibleAsRead(e));
    }

    renderFullList() {
        if (!this.isOnFullNotificationsPage()) {
            return;
        }

        let notificationUsers = this.notificationUsers;
        let notificationsHtml = Handlebars.renderTemplate('notification-users', { notificationUsers });
        this.fullList.html(notificationsHtml);
    }

    async getNotificationUsers(options = {}) {
        let notificationUserResponse = await Api.get(this.getApi, options);
        let notificationUsers = _.get(notificationUserResponse, 'results.notification_users');

        return _.map(notificationUsers, notificationUser => {
            return _.extend({}, notificationUser, { notification_user_id: parseInt(notificationUser.notification_user_id) });
        });
    }

    async markVisibleAsRead(event) {
        // :visible isn't enough because they are on the page, they
        // just aren't in the viewport. there are jquery plugins for this
        // but this works to find notifications that are actually seen
        let notificationUserIds = $('.notification-unread:visible')
            .filter((i, notification) => {
                let elementTop = $(notification).offset().top;
                let elementBottom = elementTop + $(notification).outerHeight();
                let viewportTop
                let viewportBottom

                if (this.isOnFullNotificationsPage()) {
                    viewportTop = $(window).scrollTop();
                    viewportBottom = viewportTop + $(window).height();
                } else {
                    viewportTop = $('.notifications-popover').scrollTop();
                    viewportBottom = viewportTop + $('.notifications-popover').height();
                }

                let inViewport = elementBottom > viewportTop && elementTop < viewportBottom;
                // maybe you wanted to manually keep one unread
                // also, this fn gets called on click anywhere in the list
                // so this prevents you from re-marking unread when you
                // manually click to mark another notification read
                let markedUnread = this.manuallyMarkedUnread.includes($(notification).data('notification-user-id'));

                return inViewport && !markedUnread;
            })
            .map((i, notification) => $(notification).data('notification-user-id'));

            if (notificationUserIds.length) {
                setTimeout(async () => {
                    await this.markNotificationUsersRead(
                        notificationUserIds,
                        moment.utc().format('YYYY-MM-DD HH:mm:ss')
                    );
                    this.manuallyMarkedUnread = _.uniq([...this.manuallyMarkedUnread, ...notificationUserIds])
                }, 2000);
            }
    }

    async markRead(event) {
        event.stopPropagation();
        let markReadBtn = $(event.currentTarget);
        let notification = markReadBtn.parents('.notification');
        let notificationUserId = notification.data('notification-user-id');

        await this.markNotificationUsersRead(
            [notificationUserId],
            moment.utc().format('YYYY-MM-DD HH:mm:ss')
        );

        // did we click the notification-status btn in the full list?
        if (markReadBtn.parents('.notification-full-list').length) {
            this.renderPopover();
        }
    }

    async markUnread(event) {
        event.stopPropagation();
        let markReadBtn = $(event.currentTarget);
        let notification = markReadBtn.parents('.notification');
        let notificationUserId = notification.data('notification-user-id');

        await this.markNotificationUsersRead(
            [notificationUserId],
            null
        );
        this.manuallyMarkedUnread = _.uniq([...this.manuallyMarkedUnread, notificationUserId])

        // did we click the notification-status btn in the full list?
        if (markReadBtn.parents('.notification-full-list').length) {
            this.renderPopover();
        }
    }

    async markAllRead(event) {
        let notificationElements = $('.notifications-list .notification-unread');
        let notificationUserIds = _.chain(notificationElements)
            .map(notification => $(notification).data('notification-user-id'))
            .uniq()
            .value();

        await this.markNotificationUsersRead(
            notificationUserIds,
            moment.utc().format('YYYY-MM-DD HH:mm:ss')
        );
    }

    async handleClick(event) {
        let notification = $(event.currentTarget);
        let notificationUrl = notification.data('url');
        let isRelativeUrl = this.isRelativeUrl(notificationUrl);
        let notificationUserId = notification.data('notification-user-id');

        // if opening link in new tab, that can happen at the same time that
        // we mark the notification unread
        if (notificationUrl && !isRelativeUrl) {
            window.open(notificationUrl, '_blank');
        }

        // otherwise, this needs to be awaited and happen before another url is loaded
        // in case the next request involves changing session variables (e.g. redirecting a guardian to a different student page)
        // otherwise there will be a race condition where we write different versions of the session to cache and aren't guaranteed the accurate one on next page load
        await this.markNotificationUsersRead(
            [notificationUserId],
            moment.utc().format('YYYY-MM-DD HH:mm:ss')
        );

        if (notificationUrl && isRelativeUrl) {
            window.open(notificationUrl, '_self');
        }
    }

    isRelativeUrl(url) {
        return _.startsWith(url, '/') || _.includes(url, window.location.hostname);
    }

    async markNotificationUsersRead(notificationUserIds, timestampRead) {
        let selectors = _.chain(notificationUserIds)
            .map(notificationUserId => {
                return `.notifications-list [data-notification-user-id="${notificationUserId}"]`;
            })
            .join(',')
            .value();

        // update visual
        if (timestampRead == null) {
            $(selectors)
                .removeClass('notification-read')
                .addClass('notification-unread')
                .find('.notification-status')
                    .attr('title', 'Mark as read')
                    .removeClass('has-tss-tooltip')
                .find('i')
                    .removeClass('icon-circle-blank')
                    .addClass('icon-circle');
        } else {
            $(selectors)
                .removeClass('notification-unread')
                .addClass('notification-read')
                .find('.notification-status')
                    .attr('title', 'Mark as unread')
                    .removeClass('has-tss-tooltip')
                .find('i')
                    .removeClass('icon-circle')
                    .addClass('icon-circle-blank');
        }

        // update local object so if we re-render notifications are displayed properly
        this.notificationUsers = _.map(this.notificationUsers, notificationUser => {
            if (_.includes(notificationUserIds, notificationUser.notification_user_id)) {
                notificationUser = _.extend({}, notificationUser, { timestamp_read: timestampRead });
            }
            return notificationUser;
        });

        // update title and badge
        this.updateUnreadCount();

        // build notification user objects for api call
        let notificationUserObjects = _.map(
            notificationUserIds,
            notificationUserId => ({ notification_user_id: notificationUserId, timestamp_read: timestampRead })
        );

        return $.ajax({
            type: 'PUT',
            url: this.updateApi,
            data: {
                bulk_data: notificationUserObjects
            }
        });
    }

    setupPushNotifications() {
        const socket = io(`${this.socketNamespace}`)
            .on('connect', () => socket.emit('authenticate', { token: this.token }))
            .on('authorized', () => this.handleSocketAuthorized(socket))
            .on('unauthorized', () => this.handleSocketUnauthorized(socket));
    }

    handleSocketAuthorized(socket) {
        socket
            .on(`notification-user.${this.userId}.created`, data => this.handleNewPushNotification(data))
            .on(`notification-user.${this.userId}.updated`, data => this.handleUpdatedPushNotification(data));
    }

    handleSocketUnauthorized(socket) {
        socket.close();
    }

    handleNewPushNotification(data) {
        let notificationUser = JSON.parse(data);

        this.sendNotification(notificationUser);
        this.addNotificationUserToList(notificationUser);
    }

    handleUpdatedPushNotification(data) {
        let notificationUser = JSON.parse(data);
        notificationUser.notification_user_id = parseInt(notificationUser.notification_user_id);

        // update other inactive tabs
        if (document.hidden) {
            let index = _.findIndex(this.notificationUsers, { notification_user_id: notificationUser.notification_user_id });
            this.notificationUsers.splice(index, 1, notificationUser);

            this.renderPopover();
            this.renderFullList();
            this.updateUnreadCount();
        }
    }

    addNotificationUserToList(notificationUser) {
        this.notificationUsers = [notificationUser].concat(this.notificationUsers);

        this.renderPopover();
        this.renderFullList();
        this.updateUnreadCount();
    }

    sendNotification(notificationUser) {
        // if tab is hidden or not a web-configured notification
        // dont show notification update in bottom right
        if (document.hidden || !notificationUser.web) {
            return;
        }

        let notificationUpdate = $(Handlebars.renderTemplate('notification-update', notificationUser));
        this.updates.prepend(notificationUpdate);

        setTimeout(() => {
            if (!notificationUpdate.parents('.notification-updates').hasClass('over')) {
                notificationUpdate.fadeOut();
            }
            notificationUpdate.addClass('elapsed');
        }, 5000);
    }

    updateUnreadCount() {
        let unreadCount = _.chain(this.notificationUsers)
            .filter({ timestamp_read: null, web: '1' })
            .size()
            .value();

        this.renderTitle(unreadCount);
        this.renderBadge(unreadCount);
    }

    renderTitle(number) {
        let title = document.title.replace(/\(\d+\)\s/, '');
        document.title = number ? `(${number}) ${title}` : title;
    }

    renderBadge(number) {
        this.badge.html(number || '');
    }

    notificationUpdatesMouseEnter(event) {
        let notificationUpdates = $(event.currentTarget);
        notificationUpdates.addClass('over');
    }

    notificationUpdatesMouseLeave(event) {
        let notificationUpdates = $(event.currentTarget);
        notificationUpdates.removeClass('over');

        notificationUpdates.find('.notification-update').each((i, element) => {
            let notificationUpdate = $(element);
            if (notificationUpdate.hasClass('elapsed')) {
                notificationUpdate.fadeOut();
            }
        });
    }

    notificationUpdateRemove(event) {
        event.preventDefault();
        event.stopPropagation();
        let notificationUpdateRemoveBtn = $(event.currentTarget);
        let notificationUpdate = notificationUpdateRemoveBtn.parents('.notification-update');
        notificationUpdate.remove();
    }

    notificationUpdateClick(event) {
        this.handleClick(event);

        let notificationUpdate = $(event.currentTarget);
        notificationUpdate.remove();
    }

    isOnFullNotificationsPage() {
        return $('body').hasClass('v_notifications');
    }

    isUserLoggedIn() {
        return $('body').hasClass('logged-in');
    }
}

let notifications = new Notifications();
$(document).ready(() => notifications.isUserLoggedIn() ? notifications.init() : () => {});

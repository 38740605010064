/**
 * 
 * @author cmitchell
 * This is a simple switch between two options which default to yes and no. 
 * 
 * If you want to modify the switch you must first select the input element using jQuery 
 * 
 *      const switch = $('input[name="mySwitch"]')
 * 
 * If you want to change the value to false and are using default values 
 *      switch.val(0);
 * 
 * If you want to change an attribute of the switch such as it being disabled 
 *      switch.attr("disabled", true)            
 * 
 * After modifying trigger a change event for the dom to register 
 *      enterGradeByLevelSwitch.trigger("change");
 * 
 * 
 * For Angular you can complement the use of this component with the Angular variable like this:
 *                 <tss-switch
 *                   ng-model="assessment.enter_grades_by_level"
 *                  ng-class="{disabled: assessment.external_source || isDefaultGradingScalePct}">
 *                </tss-switch>
 * 
 * In this way we use the isDefaultGradingScalePct variable to activate/deactivate 
 * the component from the js of the angular component
 * 
 */
(function($) {
    $.fn.tssSwitch = function(baseOptions){
        //set default options
        var defaultOptions = {
            onLabel: 'Yes',
            offLabel: 'No',
            onValue: 1,
            offValue: 0,
            onTooltip: null,
            offTooltip: null,
            overflowEllipsis: false,
        };
        
        //merge with passed options
        baseOptions = $.extend(defaultOptions, (typeof baseOptions == 'object' ? baseOptions : {}));
        
        return this.each(function(){
            //get input into scoped variable
            var inputElement = $(this);
            
            //check if has already had this plugin applied or is not hidden input
            if (inputElement.hasClass('is-tss-switch') || !inputElement.is('input[type="hidden"]')) {
                return inputElement;
            }

            //handle plugin options in data attrs
            var options = handleOptionAttrs(inputElement, baseOptions);
            
            //add class so we know that we've converted it to a tss select
            inputElement.addClass('is-tss-switch');

            //wrap input
            var overflowClass = options.overflowEllipsis ? ' overflow-ellipsis' : '';
            inputElement.wrap('<div class="tss-switch' + overflowClass + '" />');

            //if tooltip options are passed in, put button label within tooltip span
            //otherwise button contents are just the button label
            var onButtonContents = options.onTooltip
                ? ' <span title="' + options.onTooltip + '" tss-tooltip>' + options.onLabel + '</span>'
                : options.onLabel;
            var offButtonContents = options.offTooltip
                ? ' <span title="' + options.offTooltip + '" tss-tooltip>' + options.offLabel + '</span>'
                : options.offLabel;

            //create switch components
            var onButton = $('<div class="on">' + onButtonContents + '</div>'),
                offButton = $('<div class="off">' + offButtonContents + '</div>');

            //add switch components
            inputElement.after(offButton).after(onButton);

            //update function
            var update = function() {
                if (inputElement.attr('disabled')) {
                    inputElement.parent().addClass('disabled').attr('title', inputElement.attr('title'));
                } else {
                    inputElement.parent().removeClass('disabled').attr('title', inputElement.attr('title'));
                }

                if (inputElement.val() == options.onValue) {
                    onButton.addClass('selected');
                    offButton.removeClass('selected');
                } else {
                    inputElement.val(options.offValue);
                    onButton.removeClass('selected');
                    offButton.addClass('selected');
                }
            };

            //select switch
            update();

            //set bindings
            onButton.click(function(){
                if (!inputElement.attr('disabled')) {
                    inputElement.val(options.onValue).trigger('change');
                }
            });
            offButton.click(function(){
                if (!inputElement.attr('disabled')) {
                    inputElement.val(options.offValue).trigger('change');
                }
            });
            inputElement.change(update);
        });
        
        function handleOptionAttrs(el, baseOptions) {
            var options = _.clone(baseOptions),
                optionKeyMap = {};

            // chrome lower-cases all data attributes:
            _.each(options, function(value, key) {
                optionKeyMap[key.toLowerCase()] = key;
            });

            _.each(el.data(), function(value, key) {
                if (optionKeyMap[key] != undefined) {
                    options[
                        optionKeyMap[key]
                    ] = value;
                } else {
                    options[key] = value;
                }
            });

            return options;
        }
    };
}(jQuery));

(function($, window) { "use strict";

    var Grammar = {
        
        /**
         * Joins array of strings
         * (['one', 'two', 'three']) returns one, two and three
         * See fGrammer::joinArray
         * @param array strings
         * @param string|'and' type
         * @returns string
         */
        joinArray: function(strings, type) {
            type = type || 'and';
            
            switch (strings.length) {
                case 0:
                    return '';

                case 1:
                    return strings[0];

                case 2:
                    return strings[0] + ' ' + type + ' ' + strings[1];

                default:
                    var lastString = strings.pop();
                    return strings.join(', ') + ' ' + type + ' ' + lastString;
            }
        }
        
    };
        
    window.Tss = window.Tss || {};
    window.Tss.Grammar = Grammar;
    
})(jQuery, window);
/**
 *
 * @author cmitchell
 */
(function($) {
    $.fn.tssSelectSearch = function(baseOptions){
        //set default options
        var defaultOptions = {
            placeholder: 'No Option Selected',
            displayLimit: 0,
            displayOptionText: false,
            inputPlaceholder: 'Filter',
            caseSensitive: false,
            openFocusSelected: true,
            allowEmptyValue: false,
            tooltip: '',
        };

        //merge with passed options
        baseOptions = $.extend(defaultOptions, (typeof baseOptions === 'object' ? baseOptions : {}));

        //close on body click
        $('body').off('click.tss-select-search')
                 .on('click.tss-select-search', function(e) {
            if($(e.target).hasClass('no-tss-select-search-hide') || $(e.target).hasClass('no-tss-multiselect-search-hide')) {
                return;
            }
            hideAll();
        });

        return this.each(function(){
            //get input into scoped variable
            var inputElement = $(this),
                lastEvent = {};

            //check if has already had this plugin applied
            if (inputElement.siblings('.tss-select-search-btn').length !== 0) {
                return inputElement;
            }
            inputElement.addClass('is-tss-select-search');

            //handle plugin options in data attrs
            var options = handleOptionAttrs(inputElement, baseOptions);

            //options
            var allOptions = [];
            getAllOptions();

            //wrap input
            inputElement.wrap('<div class="tss-select-search" />');
            var wrapper = inputElement.parent();

            if (inputElement.hasClass('force-min-width')) {
                wrapper.addClass('force-min-width');
            }

            //so body click will close only if not within the unordered list
            inputElement.parent().click(function(e){
                e.stopPropagation();
            });

            //add button
            var toggleButton = $("<div>", { tabindex: 0 })
                .addClass("tss-select-search-btn")
                .attr('for', inputElement.attr('name'));
            if(options.showButtonTooltip) {
                toggleButton.attr('tss-tooltip', true);
            }
            inputElement.after(toggleButton);

            var spinner = $('<i class="icon-spinner icon-spin show-when-loading"></i><span></span>');
            toggleButton.append(spinner);

            //create options list
            var $optionsListWrapper = $('<div class="options-list-wrapper" />'),
                $optionsList = $('<ul />'),
                searchDataFeature = inputElement.data('feature') + ' Filter',
                searchClearDataFeature = inputElement.data('feature') + ' Clear',
                searchField = $('<div class="search-field"><input placeholder="' + options.inputPlaceholder + '"data-feature="' + searchDataFeature + '"/><div class="icons"><i class="icon-remove"></i></div></div>').appendTo($optionsListWrapper),
                searchFieldHeight = searchField.outerHeight(),
                clearAllIcon = searchField.find('i.icon-remove').data({
                    'feature': searchClearDataFeature,
                });
            searchField = searchField.find('input');
            buildOptionsList();
            $optionsList.appendTo($optionsListWrapper);
            toggleButton.after($optionsListWrapper);

            //get options list
            var listItems = $optionsList.find('li');

            setBindings();
            
            if (inputElement.attr('disabled')) {
                disable();
            }

            var validate = function() { 
                var invalid = inputElement.is('[required]') && !inputElement.get(0).checkValidity();

                toggleButton[invalid ? 'addClass' : 'removeClass']('error');
                toggleButton.attr('title', invalid
                    ? 'Please fill out this field.'
                    : toggleButton.data('title'));
            };
            
            inputElement.change(validate);
            searchField.blur(validate);

            searchField.on('keydown', function(e) {
                if (e.keyCode == 13) { // enter
                    return false; // if we're inside a form, don't submit it
                }
            });

            function updateSelectedValue() {
                //set button text
                setButtonText();

                //remove selected class from all list items
                listItems.removeClass('selected');

                //style selected value
                $optionsList.find('[data-value="' + inputElement.val() + '"]').addClass('selected');

                //close list
                close();
            }

            function setButtonText() {
                var option = $optionsList.find('[data-value="' + inputElement.val() + '"]'),
                    text = options.displayOptionText ? option.text() : inputElement.val(),
                    icon = option.data("icon"),
                    hasPlaceholder = !text.length;

                // if nothing selected, use placeholder
                toggleButton.removeAttr('tss-tooltip');
                toggleButton.removeAttr('title');
                toggleButton.data('title', '');
                if (hasPlaceholder) {
                    text = options.placeholder;
                } else if (options.showButtonTooltip) {
                    toggleButton.attr('tss-tooltip', true);
                    toggleButton.attr('title', text);
                    toggleButton.data('title', text);
                }

                toggleButton.toggleClass("has-placeholder", hasPlaceholder)
                    .find('span').text(text).prepend(getIcon(icon));
            }

            function clearAll() {
                inputElement.val('').trigger('change');
                close();
            }

            function searchOptions(searchBy) {
                var toSearchBy = options.caseSensitive ? searchBy : searchBy.toLowerCase();

                var results = $.grep(allOptions, function(option) {
                    var optionText = $(option).text();
                    var optgroupText = $(option).closest('optgroup').attr('label') || '';

                    if (!options.caseSensitive) {
                        optionText = optionText.toLowerCase();
                        optgroupText = optgroupText.toLowerCase();
                    }

                    var optionContainsText = optionText.indexOf(toSearchBy) !== -1;
                    var optgroupContainsText = optgroupText.indexOf(searchBy) !== -1;

                    return optionContainsText || optgroupContainsText;
                });
                buildOptionsList(results);
            }

            function buildOptionsList(data) {
                data = data || allOptions;

                //empty options
                emptyOptionsList();

                //populate options list
                var lastOptGroup = null,
                    optionCount = 0;
                $.each(data, function() {
                    var option = $(this),
                        value = option.attr('value'),
                        classes = option.attr("class");

                    if(option.is('option')) {
                        if((!value || !value.length) && !options.allowEmptyValue) { return; }
                        var text = option.text().trim(),
                            title = option.data('title') || option.attr('title') || text,
                            icon = option.data('icon'),
                            selected = option.prop('selected') || value == inputElement.data('value');

                        if (!option.parent().is('optgroup')) {
                            lastOptGroup = null;
                        }

                        var li = $('<li/>')
                            .attr('data-key', optionCount)
                            .attr('data-value', option.attr('value'))
                            .attr('title', title)
                            .addClass(classes)
                            .text(text);

                        if (icon) {
                            li.attr('data-icon', icon)
                                .prepend(getIcon(icon));
                        }

                        if (options.tooltip) {
                            li.attr(options.tooltip, '1');
                        }

                        if (lastOptGroup) {
                            li.attr('data-group', lastOptGroup);
                        }

                        li.appendTo($optionsList);
                        optionCount++;
                        
                        if(selected) {
                            inputElement.val(option.attr('value'));
                        }
                    } else {
                        lastOptGroup = option.attr('label');
                        $('<li class="optgroup">' + lastOptGroup + '</li>').appendTo($optionsList);
                    }
                });

                //get new options list
                listItems = $optionsList.find('li').not('.search-field');

                //if only one, focus
                if (listItems.length === 1) {
                    listItems.first().addClass('focused');
                }
            }

            function emptyOptionsList() {
                if (listItems) {
                    listItems.remove();
                }
            }

            function traverseList(event) {
                //get currently focused option
                var $focusedOption = $('li.focused', $optionsList);
                
                $focusedOption.removeClass('focused');

                if ($focusedOption.length === 0) {
                    listItems.first().addClass('focused');
                } 
                //up
                else if (event.keyCode === 38) {
                    //get prev option
                    var prevOption = $focusedOption.prev();

                    //if prev option, add focused class
                    if (prevOption.length) {
                        $focusedOption.removeClass('focused');
                        prevOption.addClass('focused');
                    }
                    //end of list
                    else {
                        listItems.last().addClass('focused');
                    }
                }
                //down
                else {
                    //get next option
                    var nextOption = $focusedOption.next();

                    //if next option, add focused class
                    if (nextOption.length) {
                        $focusedOption.removeClass('focused');
                        nextOption.addClass('focused');
                    }
                    //end of list
                    else {
                        listItems.first().addClass('focused');
                    }
                }

                if($optionsList.find('li.focused').hasClass('optgroup')) {
                    traverseList(event);
                } else {
                    scrollOptionIntoView();
                }
            }

            function scrollOptionIntoView() {
                var $focusedOption = $optionsList.find('li.focused');
                
                if(!$focusedOption.length) {
                    $optionsList.scrollTop(0);
                    return;
                }
                
                var top = $focusedOption.position().top,
                    optionHeight = $focusedOption.outerHeight(),
                    optionPosition = $focusedOption.data('key'),
                    listHeight = $optionsList.height() - searchFieldHeight;

                if(top > listHeight || top < optionHeight) {
                    $optionsList.scrollTop(optionPosition * optionHeight);
                }
            }

            function getAllOptions() {
                allOptions = $(inputElement.find('option, optgroup'));
            }

            function getIcon(iconClass) {
                return iconClass
                    ? "<i class=\"" + iconClass + " inline-block margin-right-xsm\" style=\"width:20px\"></i>"
                    : '';
            }

            function refreshOptions() {
                var oldVal = inputElement.val();
                
                inputElement.val('');
                getAllOptions();
                buildOptionsList();
                listItems.removeClass('focused').removeClass('selected');
                searchField.val('');
                close();
                inputElement.val($optionsList.find('[data-value="' + oldVal + '"]').length ? oldVal : null);
                updateSelectedValue();
            }
            
            function disable() {
                wrapper.addClass('disabled').removeClass('open');
                toggleButton.addClass('disabled');
            }
            
            function enable() {
                wrapper.removeClass('disabled');
            }

            function handleClickFocus(e) {
                if(wrapper.hasClass('disabled') || (e.type === 'click' && lastEvent.type === 'focus')) {
                    lastEvent = {};
                    return;
                }
                lastEvent = e;

                if (wrapper.hasClass('open')) {
                    close();
                } else {
                    //first hide all others
                    hideAll(inputElement);

                    //then show
                    open();
                }
            }
            
            function scrollToFirstSelected() {
                var $firstSelected = $optionsList.find('li.selected').first();
                
                //nothing selected
                if(!$firstSelected.length) { return; }
                
                var optionHeight = $firstSelected.outerHeight(),
                    optionPosition = $firstSelected.data('key');
                
                //scroll to top of first selected
                $optionsList.scrollTop(optionHeight * optionPosition);
            }
            
            function open() {
                if(wrapper.hasClass('open')) { return; }
                
                $optionsList.find('li').removeClass('focused');
                wrapper.addClass('open');
                    
                //scroll to first selected
                if(!options.openFocusFirstSelected) {
                    scrollToFirstSelected();
                } else {
                    $optionsList.scrollTop(0);
                }
                
                searchField.focus().selText().select();
                inputElement.trigger('tss.select.is.open');
            }
            
            function close() {
                if(wrapper.hasClass('open')) {
                    wrapper.removeClass('open');
                    inputElement.trigger('tss.select.is.closed');
                }
            }

            function setBindings() {
                //update selected value
                inputElement.on('change.tss-select-search', updateSelectedValue);
                updateSelectedValue();

                //toggle show/hide options list
                toggleButton.on('click focus', function (e) {
                    if (e.type == 'click') {
                        //put this trigger here instead of on close function
                        //because close is called multiple times on page load
                        inputElement.trigger('gaEvent');
                    }
                    handleClickFocus(e);
                });

                //select all text when clicking the input
                searchField.on('click', function(){
                    searchField.trigger('gaEvent');
                    searchField.selText().select();
                });

                //click an option
                $optionsList.on('click', 'li:not(.optgroup)', function(e){
                    e.preventDefault();
                    var item = $(this);

                    //ignore the search field
                    if ($(this).hasClass('search-field')) {
                        return false;
                    }

                    //remove if has class selected
                    if (item.hasClass('selected')) {
                        inputElement.val('');
                    }
                    //add if doesn't
                    else {
                        inputElement.val(item.data('value'));
                    }

                    inputElement.change();
                    wrapper.removeClass('open');

                });
                
                searchField.on('keyup', function(e){
                    switch (e.keyCode) {
                        case 37: return; //left
                        case 39: return; //right
                        case 9: return; //tab
                        case 13: //enter
                            //trigger click
                            $optionsList.find('.focused').trigger('click', false);
                            return;
                        case 38: //up
                            //traverse
                            traverseList(e);
                            return;
                        case 40: //down
                            //traverse
                            traverseList(e);
                            return;
                        case 27: //escape
                            close();
                            return;
                    };

                    //search
                    searchOptions($(this).val());
                });

                //bind to select all
                clearAllIcon.on('click', function () {
                    $(this).trigger('gaEvent');
                    clearAll();
                });

                //refresh options
                inputElement.on('refresh-options', refreshOptions);
                
                //open & close
                inputElement.on('tss.select.open', function () {
                    $(this).trigger('gaEvent');
                    open();
                });
                inputElement.on('tss.select.close', close);
                
                //disable and enable
                inputElement.on('disable', disable);
                inputElement.on('enable', enable);
            }
        });

        function hideAll(inputElement) {
            if(inputElement) {
                $('.tss-select-search.open, .tss-multiselect-search.open').find('select').not(inputElement).trigger('tss.select.close');
            } else {
                $('.tss-select-search.open, .tss-multiselect-search.open').find('select').trigger('tss.select.close');
            }
        }

        function handleOptionAttrs(el, baseOptions) {
            var options = _.clone(baseOptions);
            _.each(el.data(), function(value, key) {
                options[key] = value;
            });

            return options;
        }
    };
}(jQuery));

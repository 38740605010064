(function ($, window) {
    "use strict";
    var TermBins = {
        initAddEdit: async function (type, nameKey, action, id, options) {
            Tss.Types.initAddEdit(type, nameKey, action, id, options);

            var form = $('#main-form');
            var saveButton = $('[rel="submit-form"]');

            /**
             * Check form validity and if all fields are filled correctly, then enable/disable the "Save" button
             * @return {void}
             */
            function checkFormValidity() {
                const allFieldsFilled = TermBins.checkAllFieldsFilled();
                const shortNameValid = TermBins.validateShortName();
                const longNameValid = TermBins.validateLongName();
                const datesValid = TermBins.validateDates();

                 // Call the toggleSaveButton to handle the save button state
                toggleSaveButton(allFieldsFilled && shortNameValid && longNameValid && datesValid);
            }

            checkFormValidity(); // To disable "Save" button on start

            /**
             * Toggle the state of the "Save" button based on form validity
             * @param {boolean} isValid - True if the form is valid
             * @return {void}
             */
            function toggleSaveButton(isValid) {
                if (isValid) {
                    saveButton.prop('disabled', false).removeClass('disabled');
                } else {
                    saveButton.prop('disabled', true).addClass('disabled');
                }
            }

            /**
             * Mark a field as touched
             * @param {object} field - The form field to mark as touched
             * @return {void}
             */
            function markFieldAsTouched(field) {
                $(field).addClass('touched');
            }

            // Attach event listeners to input fields for validation
            form.on('input change', 'input, select', function () {
                markFieldAsTouched(this);
                checkFormValidity();
            });

            saveButton.on('click', function (e) {
                if (saveButton.prop('disabled')) {
                    e.preventDefault();
                    return;
                }
                form.submit();
            });
        },

        /**
         * Check if all required fields are filled
         * @return {boolean} - True if all fields are filled
         */
        checkAllFieldsFilled: function() {
            const fields = [
                $('#short_name_term_bin_add').val().trim(),
                $('#long_name_term_bin_add').val().trim(),
                $('#start_date_term_bin').val().trim(),
                $('#end_date_term_bin').val().trim(),
            ];

            return fields.every(value => value !== ''); // Ensure no field is empty
        },

        /**
         * Set error message and field state (valid/invalid)
         * @param {object} field - The field to apply error state
         * @param {string} message - The error message to display
         * @param {boolean} isError - Whether the field has an error
         * @return {boolean} - True if no error
         */
        setError: function(field, message, isError) {
            field.css('border', isError ? '1px solid red' : '').next('.error-message').text(message).toggle(isError);
            return !isError;
        },

        /**
         * Validate Short Name field with pattern matching
         * @return {boolean} - True if valid
         */
        validateShortName: function() {
            const shortName = $('#short_name_term_bin_add').val().trim();
            const validPattern = [
                /^((Y|SS|IA|A)[1-9])$/,
                /^(S[1-2])$/,
                /^((T|Q)[1-4])$/,
            ];

            return TermBins.setError(
                $('#short_name_term_bin_add'),
                'Invalid Short Name. e.g. Q2',
                !validPattern.some(pattern => pattern.test(shortName)) && $('#short_name_term_bin_add').hasClass('touched')
            );
        },

        /**
         * Validate Long Name field to ensure it is filled
         * @return {boolean} - True if valid
         */
        validateLongName: function() {
            const longNameField = $('#long_name_term_bin_add');
            const longName = longNameField.val().trim();

            // Separate validation logic
            const isTouched = longNameField.hasClass('touched');
            const isEmpty = longName === '';
            const exceedsMaxLength = longName.length > 30;

            // Determine error message based on validations
            let errorMessage = '';
            if (isEmpty) {
                errorMessage = 'Long Name is required. e.g. Quarter 1';
            } else if (exceedsMaxLength) {
                errorMessage = 'Long name exceeds maximum allowed';
            }

            // Only set the error if the field has been touched and an error exists
            const hasError = errorMessage !== '' && isTouched;

            return TermBins.setError(longNameField, errorMessage, hasError);
        },

        /**
         * Validate Start and End Dates
         * @return {boolean} - True if dates are valid
         */
        validateDates: function() {
            const startDateField = $('#start_date_term_bin');
            const endDateField = $('#end_date_term_bin');
            const startDate = startDateField.val().trim();
            const endDate = endDateField.val().trim();

            if (!TermBins.setError(startDateField, 'Start Date is required.', !startDate && startDateField.hasClass('touched'))) {
                return false;
            }

            if (!TermBins.setError(endDateField, 'End Date is required.', !endDate && endDateField.hasClass('touched'))) {
                return false;
            }

            if (!TermBins.setError(endDateField, 'End Date can\'t be before Start Date.', new Date(startDate) > new Date(endDate))) {
                return false;
            }

            return true;
        }
    };

    window.Tss = window.Tss || {};
    window.Tss.TermBins = TermBins;

})(jQuery, window);
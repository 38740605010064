(function ($, window, undefined) {"use strict";

    var defaults = {
        label: false,
        selected: false,
        tooltip: false,
        onChange: function (selected) {}
    };

    function TssCheckbox(element, options) {
        let marginLeft = options && options["marginLeft"] ? options["marginLeft"] : '';
        this._options = $.fn.tssOptions("tss-checkbox", element, defaults, options);
        this._options.selected = this._options.selected === true
            || this._options.selected === 'true'
            || this._options.selected == '1';
        this.checkbox = element;
        this.$checkbox = $(element);
        this.$icon = $("<span>").addClass(`tss-checkbox ${marginLeft}`);
        this.$label = $("<label>", { text: this._options.label || "" }).addClass("tss-checkbox-label");
        setup.call(this);
    };

    function setup() {
        var self = this, attrs;
        this.$checkbox.css({ position: "absolute", top: -9999, left: -9999 }).before(this.$icon);
        if (this._options.label) {
            this.$label.click(function () {
                self.$icon.click();
            });
            this.$icon.after(this.$label);
        }
        if (this._options.tooltip) {
            attrs = { title: this._options.tooltip, "tss-tooltip": true };
            if (this._options.label) {
                this.$label.attr(attrs);
            } else {
                this.$icon.attr(attrs);
            }

        }
        this.$icon.click(function (event) {
            event.stopPropagation();
            toggle.call(self);
        });

        var disabled = this.$checkbox.attr('disabled');
        this.$checkbox.removeAttr('disabled');
        this.toggle(this._options.selected);
        this.$checkbox.attr('disabled', disabled);
        this.$icon.attr('disabled', disabled);
        this.$label.attr('disabled', disabled);

        this.$checkbox.change(_.bind(handleChange, this));
    };

    function toggle(silent) {
        if (this.$checkbox.attr('disabled')) {
            return;
        }
        var isSelected = this.isSelected();
        this.$checkbox.prop("checked", !isSelected);
        this.$icon.toggleClass("checked", !isSelected);
        if (silent) {
            return;
        }

        this.$checkbox.change();
        this._options.onChange.call(this.checkbox, !isSelected);
    };

    function handleChange() {
        var isSelected = this.isSelected();
        if (this.$icon.hasClass("checked") != isSelected) {
            this.$icon.toggleClass("checked", isSelected); // make icon match input state
        }
    }

    TssCheckbox.prototype.isSelected = function () {
        return this.$checkbox.is(":checked");
    };

    TssCheckbox.prototype.toggle = function (selected, silent) {
        var isSelected = this.isSelected();
        if (selected === isSelected) {
            return;
        }
        toggle.call(this, silent);
    };

    TssCheckbox.prototype.on = function (silent) {
        this.toggle(true, silent);
    };

    TssCheckbox.prototype.off = function (silent) {
        this.toggle(false, silent);
    };

    $.fn.tssCheckbox = $.fn.tssExtend(TssCheckbox);

})(jQuery, window);

(function ($, window, undefined) {

    var defaults = {
        content: "",
        icon: "icon-angle-down",
        klass: '',
        hover: false,
        right: false,
        onOpen: function () {},
        onClose: function () {}
    };

    function TssDropdown(element, options) {
        this._options = $.fn.tssOptions("tss-dropdown", element, defaults, options);
        this.dropdown = element;
        this.$dropdown = $(element).addClass(
            "dropdown" + (this._options.right ? " dropdown-right" : "") + ' ' + (this._options.klass || '')
        );      
        setup.call(this);
        setBindings.call(this);
    };

    function setup() {
        var self = this, data = { content: this._options.content, options: this._options };
        this.$dropdown.append(Handlebars.renderTemplate("tss-dropdown", data));
        this.$dropdownToggle = this.$dropdown.find(".dropdown-toggle");
        this.$dropdownMenu = this.$dropdown.find(".dropdown-menu");
    };

    function setBindings() {
        var self = this;
        if (this._options.hover) {
            this.$dropdown.hoverIntent({
                timeout: 200,
                selector: ".dropdown-toggle .dropdown-menu",
                over: function () {
                    self.open();
                },
                out: function () {
                    self.close();
                }
            });
        } else {
            this.$dropdownToggle.click(function (e) {
                self.toggle();
                e.stopPropagation();
            });
        }
//      this.$dropdownToggle.add(this.$dropdownMenu).click(function (e) {
////            e.stopPropagation();
//      });
        this.$dropdownMenu.find(".menu-action").add(document).click(function () {
            self.close();
        });
    };

    TssDropdown.prototype.isOpen = function () {
        return this.$dropdown.hasClass("open");
    };

    TssDropdown.prototype.toggle = function (open) {
        var isOpen = this.isOpen();
        if (isOpen === open) {
            return;
        }
        if (isOpen) {
            this.close();
            return;
        }
        this.open();
    };

    TssDropdown.prototype.open = function () {
        var other = $(".dropdown.open").not(this.$dropdown);
        other.removeClass("open");
        this.$dropdown.addClass("open");
        this._options.onOpen.call(this.dropdown);
    };

    TssDropdown.prototype.close = function () {
        this.$dropdown.removeClass("open");
        this._options.onClose.call(this.dropdown);
    };

    $.fn.tssDropdown = $.fn.tssExtend(TssDropdown);

})(jQuery, window);
(function ($, window, undefined) {"use strict";

    let GuardianTopNav = {
        guardianId: null,

        init: function(opts) {
            this.guardianId = opts.guardianId;
            this.loggedInStudentId = opts.loggedInStudentId;
            this.addStudentModal = null;

            this.setBindings();
            this.setupAddStudentModal();
            if (!this.loggedInStudentId) {
                this.addStudentModal.showModal();
            }
        },

        setBindings: function() {
            $('#guardian-student-selector').on('change', GuardianTopNav.handleGuardianStudentSelector);
        },

        setupAddStudentModal: function () {
            this.addStudentModal = new Tss.Modal({
                launchTarget: '.add-student',
                template: 'add-guardian-student',
                go: function (e) {
                    $('button.go').text('Pairing...');
                    $('button.go').addClass('disabled');

                    var dialog = $(e.target).closest('form');
                    var data = {};

                    data.student_access_code = dialog.find("[name=\"student_access_code\"]").val();
                    data.guardian_id = GuardianTopNav.guardianId;

                    $.post("/api/v1/guardian-students/", data)
                        .success(handleSuccess)
                        .fail(handleFailure);
                }
            });
            function handleSuccess(data) {
                $('button.go').text('Success!');
                var studentId = _.get(data, 'results.guardian_student.student_id');
                GuardianTopNav.changeStudent(studentId);
            }
            function handleFailure(xhr) {
                handleErrors(_.get(xhr, 'responseJSON.errors', []));
            }
            function handleErrors(errors) {
                var errors = errors || ['Something went wrong. Please try again.'];

                $('button.go').text('Try again');
                $('button.go').removeClass('disabled');

                displayErrors(errors);
            }
            function displayErrors(errors) {
                var errorContainer = $('.error-container');
                errorContainer.html('');

                _.each(errors, function (error) {
                    errorContainer.append((Handlebars.renderTemplate("alert", { type: "error", message: error, removable: true })));
                });
            }
        },

        handleGuardianStudentSelector: function() {
            var studentId = $(this).val();
            GuardianTopNav.changeStudent(studentId);
        },

        changeStudent: function (studentId) {
            var data = {
                student_id: studentId,
            };

            $.post('/guardian/change/student', data)
                .then(function () {
                    if (window.location.pathname == '/guardian/add-student') {
                        window.location.href = '/'
                    } else {
                        window.location.reload();
                    }
                });
        }
    }

    window.Tss = window.Tss || {};
    window.Tss.GuardianTopNav = GuardianTopNav;

})(jQuery, window);

(function() {
    'use strict';
    
    function AsyncTask(name, request, callbacks) {
        this._progress = null;
        this.name      = name;
        this.request   = request;
        this.callbacks = _.extend({}, {
            progress: _.noop,
            done: _.noop,
            error: _.noop
        }, callbacks);
    }
    
    _.extend(AsyncTask.prototype, {
        start: asyncTaskStart,
        stop: asyncTaskStop
    });
    
    function asyncTaskStart(elem) {
        this._progress = setInterval(_.bind(this.callbacks.progress, this, elem), 2500);
        
        return this.request.always(_.bind(_destroyProgressChecker, this));
    }
    
    function asyncTaskStop() {
        return this.request.abort();
    } 
    
    function _destroyProgressChecker() {
        clearInterval(this._progress);
    }
    
    window.AsyncTask = AsyncTask;
}());
/**
 *
 * @author cmitchell
 */
(function($) {
    $.fn.tssTabs = function(options) {
        // merge with passed options
        var opts = $.extend({}, {
            onTabChange: function() {}
        }, options);

        return this.each(function() {
            var $this = $(this), active, content, tabLinks = $this.find('li');

            if($this.hasClass('is-tss-tabs')) { return; }
            $this.addClass('is-tss-tabs');

            //set active as first tab
            active = $(tabLinks[0]);
            active.addClass('active');
            content = $('#' + active.data('tab'));

            //hide other tabs
            tabLinks.not(active).each(function() {
                $('#' + $(this).data('tab')).hide();
            });

            tabLinks.each(function() {
                $(this).attr('title', $(this).text().trim());
            });

            //bindings
            tabLinks.on('click', function(e) {
                e.preventDefault();

                if (!$(this).hasClass('disabled') && $(this).data('tab') != active.data('tab')) {
                    // remove old active
                    active.removeClass('active');
                    content.hide();

                    // trigger on change
                    opts.onTabChange(this);

                    // mark new active
                    active = $(this);
                    active.addClass('active');
                    content = $('#' + active.data('tab'));
                    content.show();

                    if (active.data('hash')) {
                        window.location.hash = 'tab-' + active.data('tab');
                    }
                }
            });

            let matches = (window.location.hash || '').match(/^#tab-(.+)$/)
            if (matches) {
                let tab = matches[1];

                $(`li[data-tab="${tab}"]`).click();
            }
        });
    };
}(jQuery));
(function ($, window, undefined) {"use strict";

    $.fn.tssFilter = function (baseOptions) {
        var defaultOptions = {
            icon: false,
            caseSensitive: false,
            debounce: 250
        };

        if ($.isFunction(baseOptions)) {
            baseOptions = { filter: baseOptions };
        }

        baseOptions = $.extend(defaultOptions, ($.type(baseOptions) === "object" ? baseOptions : {}));

        return this.each(function () {
            var input = $(this), options;
            if (input.data("tss-filter")) {
                return;
            }

            options = handleDataOptions(input, baseOptions);
            
            initFilter();

            function initFilter () {
                input.data("tss-filter", options);
                input.on("keyup", _.debounce(handleFilter, options.debounce));
                input.wrap($("<div>").addClass("tss-filter"));
                if (options.icon) {
                    input.after($("<i>").addClass(options.icon));
                    input.css("padding-right", 40);
                }
            }

            function returnFilter () {
                var filter = (options.caseSensitive ? input.val() : input.val().toLowerCase());
                return filter;
            }

            function handleFilter () {
                if (options.filter && $.isFunction(options.filter)) {
                    options.filter.call(this, returnFilter());
                }
            }

            function handleDataOptions(input, baseOptions) {
                var options = _.clone(baseOptions);
                _.each(input.data(), function(value, key) {
                    options[key] = value;
                });
                return options;
            }

        });

    };

})(jQuery, window);

(function ($, window, undefined) {"use strict";

    var DynamicData = {
        handlers: [
            Tss.DynamicClassAttendance,
        ],
        defaults: {
        },

        init: function(opts) {
            this.config = $.extend({}, this.defaults, opts);

            this.setBindings();
        },

        setBindings: function() {
            var self = this;

            self.loadDataIfNecessary();
            $('body').off('loaded.DynamicData').on('loaded.DynamicData', _.bind(self.loadDataIfNecessary, self));
        },

        loadDataIfNecessary: function() {
            var self = this;

            _.each(self.handlers, self.loadDataByHandler);
        },

        loadDataByHandler: function(handler) {
            var elems = $('[rel="' + handler.rel + '"]:not(.dynamic-data-loaded)');

            if (elems.length) {
                elems.addClass('dynamic-data-loaded'); // do once
                handler.loadDynamicData(elems);
            }
        },
    };

    window.Tss = window.Tss || {};
    window.Tss.DynamicData = DynamicData;

})(jQuery, window);

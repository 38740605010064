/**
 * 
 * @author cmitchell
 */
(function($) {
    $.fn.tssTags = function(options){
        //set default options
        var defaultOptions = {
            placeholder: 'No Tags Selected',
            displayLimit: 0,
            idKey: 'question_tag_id',
            tagKey: 'tag',
            objectKey: 'question_tag_id'
        };
        
        //merge with passed options
        options = $.extend(defaultOptions, (typeof options == 'object' ? options : {}));
        
        //close on body click
        $('html').click(function() {
            $('.tss-tags ul').hide();
        });
        
        //get data
        getData();
        
        //if no data, return
        if (!options.tagsData) {
            return false;
        }
        
        return this.each(function(){
            
            //get input into scoped variable
            var inputElement = $(this);
            
            //check if has already had this plugin applied or is missing multiple attribute
            if (inputElement.siblings('div.tss-tags-btn').length !== 0 || !inputElement.is(':hidden')) {
                return inputElement;
            }
            
            //get values as array
            var selectedValues = inputElement.val() ? JSON.parse(inputElement.val()) : [];

            //wrap input
            inputElement.wrap('<div class="tss-tags" />');
            
            //so body click will close only if not within the unordered list
            inputElement.parent().click(function(e){
                e.stopPropagation();
            });
            
            //add button
            var toggleButton = $('<div class="tss-tags-btn" />');
            inputElement.after(toggleButton);
            
            //create options list
            var optionsList = $('<ul />');
            var searchField = $('<li class="search-field"><input /><div class="icons"><i class="icon-remove hide"></i></div></li>').appendTo(optionsList);
            var clearBtn = searchField.find('i.icon-remove');
            searchField = searchField.find('input');
            buildTagsList();
            toggleButton.after(optionsList);
            
            //get options list
            var listItems = optionsList.find('li').not('.search-field');
            
            //update selected values
            updateSelectedValues();
            
            //bindings
            //toggle show/hide options list
            toggleButton.click(function(e){
                e.preventDefault();
                if (optionsList.is(':visible')) {
                    optionsList.hide();
                } else {
                    //first hide all others
                    $('.tss-tags ul').hide();
                    
                    //then show
                    optionsList.show();
                    
                    //focus on search
                    searchField.focus().selText().select();
                    searchField.selText().select();
                }
            });
            //select all text when clicking the input
            searchField.on('click', function(){
                searchField.selText().select();
            });
            //clear search field and trigger rebuild of list
            clearBtn.on('click', function(){
                searchField.val('').trigger('keyup');
            });
            //click an option
            optionsList.on('click', 'li', function(e){
                e.preventDefault();
                var item = $(this);
                
                //ignore the search field
                if ($(this).hasClass('search-field')) {
                    return false;
                }
                
                //remove if has class selected
                if (item.hasClass('selected')) {
                    removeFromSelectValuesArray(item.data('value'));
                }
                //add if doesn't
                else {
                    addValueToSelectValuesArray(item.data('value'));
                }
                
                //update
                updateSelectedValues();
            });
            searchField.on('keyup', function(e){
                //tab, enter, up, down
                if (e.keyCode === 9 || e.keyCode === 13 || e.keyCode === 38 || e.keyCode === 40) {
                    return false;
                }
                
                //get value
                var searchBy = searchField.val();

                //show/hide clear btn
                if (searchBy.length === 0) {
                    hideClearBtn();
                } else {
                    showClearBtn();
                }

                //search
                searchTags(searchBy);
            });
            //bind to tab and enter
            optionsList.on('keydown', 'li', function(e){
                switch (e.keyCode) {
                    //tab
                    case 9:
                        if (e.target.nodeName === 'INPUT') {
                            addNewTag();
                            return false;
                        }
                        break;
                    //enter
                    case 13:
                        //get focused option
                        var option = optionsList.find('.focused');
                        
                        //make sure there is a focused option
                        if(option.length !== 0) {
                            //trigger click
                            optionsList.find('.focused').trigger('click');
                            //clear filtering
                            clearBtn.trigger('click');
                        }
                        //else, try to add a new tag
                        else {
                            addNewTag();
                        }
                        break;
                    //up
                    case 38:
                        //traverse
                        traverseList(e);
                        return false;
                        break;
                    //down
                    case 40:
                        //traverse
                        traverseList(e);
                        return false;
                        break;
                };
            });
            
            //functions
            function updateSelectedValues() {
                //set button text
                setButtonText();
                
                //remove selected class from all list items
                listItems.removeClass('selected');
                
                //iterate through all list items and add select class if in selectedValues array
                listItems.each(function(i, item){
                    item = $(item);
                    if ($.inArray(item.data('value'), selectedValues) !== -1) {
                        item.addClass('selected');
                    } 
                });
            }
            
            function setButtonText() {
                var buttonTextValues = [];
                $.each(selectedValues, function(i, id){
                    var optionText = getOptionTextById(id)
                    buttonTextValues.push(optionText);
                });
                
                //concat
                var text = buttonTextValues.join(', ');
                
                //if display limit set
                if (options.displayLimit !== 0) {
                    var tempSelectedValues = [];
                    $.each(buttonTextValues, function(i, value){
                        if (value.length - 3 > options.displayLimit) {
                            tempSelectedValues.push(
                                value.substr(0, options.displayLimit - 3) + '...'
                            );
                        } else {
                            tempSelectedValues.push(value);
                        }
                    });
                    text = tempSelectedValues.join(', ');
                }
                
                //if nothing selected, use placeholder
                if (text.length === 0) {
                    text = options.placeholder;
                }
                
                //set it
                toggleButton.text(text);
            }
            
            function removeFromSelectValuesArray(value) {
                selectedValues.splice(selectedValues.indexOf(value), 1);
                inputElement.val(selectedValues);
            }
            
            function addValueToSelectValuesArray(value) {
                selectedValues.push(value);
                inputElement.val(selectedValues);
            }
        
            function searchTags(searchBy) {
                var results = $.grep(options.tagsData, function(tag) {
                    //make sure the property exists
                    if (!tag || !tag[options.tagKey]) {
                        return false;
                    }
                    
                    //find the index of the search string
                    return String(tag[options.tagKey]).toLowerCase().indexOf(searchBy) != -1;
                });
                buildTagsList(results);
            }
        
            function buildTagsList(data) {
                data = data || options.tagsData;

                //empty tags
                emptyTagsList();

                //populate options list
                for(var tagId in data) {
                    if(options.tagsData[tagId]) {
                        $('<li data-value="' + options.tagsData[tagId][options.idKey] + '">' + options.tagsData[tagId][options.tagKey] + '</li>').appendTo(optionsList);
                    }
                }

                //get new options list
                listItems = optionsList.find('li').not('.search-field');

                //if only one, focus
                if (listItems.length === 1) {
                    listItems.first().addClass('focused');
                }

                updateSelectedValues();
            }

            function emptyTagsList() {
                if (listItems) {
                    listItems.remove();
                }
            }
            
            function showClearBtn() {
                clearBtn.removeClass('hide');
            }
            
            function hideClearBtn() {
                clearBtn.addClass('hide');
            }
            
            function traverseList(event) {
                //get currently focused option
                var focusedOption = $('li.focused', optionsList);
                focusedOption.removeClass('focused');
                
                if (focusedOption.length === 0) {
                    listItems.first().addClass('focused');
                }
                //up
                else if (event.keyCode === 38) {
                    //get prev option
                    var prevOption = focusedOption.prev();
                    
                    //if prev option, add focused class
                    if (prevOption.length !== 0 && !prevOption.hasClass('search-field')) {
                        focusedOption.prev().addClass('focused');
                    }
                    //else start at end
                    else {
                        listItems.last().addClass('focused');
                    }
                }
                //down
                else {
                    //get next option
                    var nextOption = focusedOption.next();
                    
                    //if next option, add focused class
                    if (nextOption.length !== 0) {
                        focusedOption.next().addClass('focused');
                    }
                    //else start at beginning
                    else {
                        listItems.first().addClass('focused');
                    }
                }
            }
            
            function addNewTag() {
                //get text
                var newTagText = searchField.val();
                
                //if empty, there are list items that may match, or no endpoint defined, return false
                if(newTagText.length === 0 || listItems.length !== 0 || !options.newTagEndpoint) {
                    return false;
                }
            
            //get request
            $.ajax({
                url: options.newTagEndpoint + newTagText,
                success: function(data) {
                    //append to tags data
                    options.tagsData.push(data);
                    
                    //append to selected values
                    selectedValues.push(data[options.idKey]);
                    
                    //build tags list
                    buildTagsList();
                    
                    //clear search field
                    searchField.val('');
                    
                    return;
                }
            });
            }
        });
            
        function getData() {
            $.ajax({
                url: options.dataSrc,
                success: function(data) {
                    options.tagsData = data;
                },
                async: false
            });
        }
        
        function getOptionTextById(optionId) {
            for(var i = 0, count = options.tagsData.length; i < count; i++) {
                if (options.tagsData[i][options.idKey] == optionId) {
                    return options.tagsData[i][options.tagKey];
                }
            }
            return false;
        }
    };
}(jQuery));


//<div>
//    <input class="make-tss-tags" type="hidden" />
//</div>
//<script>
//$(document).ready(function() {
//    $('input.make-tss-tags').tssTags({
//        dataSrc: 'get/all/question/tags',
//        newTagEndpoint: '/add/question/tag/'
//    });
//});
//</script>
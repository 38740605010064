(function ($, window, undefined) {"use strict";

    var CourseGrade = {

        // Config
        config: {
            tables: {
                options: {
                    sortList: [[0,0]],
                    widgets: ["zebra"]
                }
            },
            dialog: {
                options: {
                    dialogClass: "minimal-dialog minimal-dialog-fixed",
                    position: {
                        my: "center",
                        at: "center",
                        of: "#facebox"
                    },
                    autoOpen: false,
                    draggable: false,
                    resizable: false,
                    modal: true,
                    zIndex: 10000
                }
            },
            api: {
                save: "/studentgrades/save/"
            },
            templates: {
                row: "student-course-grade-row",
                rowOptions: "student-course-grade-row-options",
                dialog: "student-course-grade-dialog"
            },
        },

        /**
         * Initializes edit view
         * @param int courseId
         * @param int studentId
         * @param array courseGrades
         */
        initEdit: function (courseId, studentId, courseGrades) {
            var self = this;
            this.courseId = courseId;
            this.studentId = studentId;
            this.courseGrades = courseGrades;
            this.$container = $("#student-course-grades-" + this.studentId);
            this.$table = this.$container.find("table");
            this.$dialog = $("<div>");
            this.$override = this.$dialog.find("input[type=\"text\"]");
            this.setEditBindings();
        },

        /**
         * Sets edit bindings
         */
        setEditBindings: function () {
            var self = this;
            this.$container.on("click", "[rel=\"close-modal\"]", function () {
                $(document).trigger("close.facebox");
            });
            this.$table.on("click", "[rel=\"edit-override\"]", this.handleEditOverrideClick);
            this.$table.on("click", "[rel=\"remove-override\"]", this.handleRemoveOverrideClick);
            this.$table.on("click", "[rel=\"toggle-active\"]", this.handleToggleActiveClick);
            this.$dialog.on("click", "[rel=\"close-override\"]", this.handleCloseOverrideClick);
            this.$dialog.on("click", "[rel=\"save-override\"]", this.handleSaveOverrideClick);
            $(document).off("reveal.facebox").on("reveal.facebox", function () {
                $("#facebox").css({ height: "auto" });
                self.renderTable();
                self.renderDialog();
            });
        },

        /**
         * Renders table
         */
        renderTable: function () {
            var self = this,
            $tbody = this.$table.find("tbody");

            // Build Rows
            _.each(this.courseGrades, function (courseGrade) {
                var $row = self.getRow(courseGrade);
                $row.appendTo($tbody);
            });

            // Init Tablesorter
            this.$table.tablesorter(this.config.tables.options);
        },

        /**
         * Renders table row
         * @param object courseGrade
         */
        renderRow: function (courseGrade) {
            // Remove tooltips
            $(".tss-tooltip").remove();
            
            // Replace with new row
            var $row = this.getRow(courseGrade);
            this.$table.find("#course-grade-" + courseGrade.student_id + "-" + courseGrade.term_bin_id).replaceWith($row);
            
            // Update Tablesorter
            this.$table.trigger("update");
        },

        /**
         * Renders dialog
         */
        renderDialog: function () {
            // Remove dialog
            $("#facebox .ui-dialog").remove();

            this.$dialog.dialog(this.config.dialog.options);
        },

        /**
         * Gets table row
         * @param object courseGrade
         * @return jQuery $row
         */
        getRow: function (courseGrade) {
            var self = this,
            $row = $(Handlebars.renderTemplate(this.config.templates.row, courseGrade));
            $row.find(".row-options").tssDropdown({
                content: Handlebars.renderTemplate(this.config.templates.rowOptions, courseGrade),
                icon: "icon-cog",
                right: true
            });
            return $row;
        },

        /**
         * Gets course grade
         * @param int studentId
         * @param int termBinId
         * @return object
         */
        getCourseGrade: function (studentId, termBinId) {
            return _.find(this.courseGrades, function (courseGrade) {
                return courseGrade.student_id == studentId && courseGrade.term_bin_id == termBinId;
            });
        },

        /**
         * Gets course grade data
         * @param int studentId
         * @param int termBinId
         * @return object data
         */
        getCourseGradeData: function (studentId, termBinId) {
            var $row = $("#course-grade-" + studentId + "-" + termBinId),
            $inputs = $row.find("input[type=\"hidden\"]"),
            data = {
                course_id: this.courseId,
                student_id: this.studentId
            };
            $inputs.each(function () {
                var name = $(this).attr("name");
                data[name] = $(this).val();
            });
            return data;
        },

        /**
         * Handles rel="edit-override" click
         */
        handleEditOverrideClick: function () {
            var self = CourseGrade,
            $row = $(this).parents("tr"),
            $input = $row.find("[name*=\"score_override\"]"),
            studentId = $row.data("studentId"),
            termBinId = $row.data("termBinId"),
            courseGrade = self.getCourseGrade(studentId, termBinId);

            // Render dialog
            self.$dialog.html(Handlebars.renderTemplate(self.config.templates.dialog, courseGrade));
            self.$dialog.dialog("open");
        },

        /**
         * Handles rel="remove-override" click
         */
        handleRemoveOverrideClick: function () {
            var self = CourseGrade,
            $row = $(this).parents("tr"),
            $input = $row.find("[name*=\"score_override\"]"),
            studentId = $row.data("studentId"),
            termBinId = $row.data("termBinId");

            // Remove override value
            $input.val("");
            
            // Submit values
            self.save(studentId, termBinId, function () {
                var courseGrade = self.getCourseGrade(studentId, termBinId);
                Growl.success({ message: courseGrade.term_bin.short_name + " override was removed" });
            });
        },

        /**
         * Handles rel="toggle-active" click
         */
        handleToggleActiveClick: function() {
            var self = CourseGrade,
            $row = $(this).parents("tr"),
            $input = $row.find("[name*=\"active\"]"),
            studentId = $row.data("studentId"),
            termBinId = $row.data("termBinId");

            // Set override value
            $input.val($input.val() == 1 ? 0 : 1);

            // Submit values
            self.save($row.data("studentId"), $row.data("termBinId"), function () {
                var courseGrade = self.getCourseGrade(studentId, termBinId);
                Growl.success({ message: courseGrade.term_bin.short_name + " score was " + (courseGrade.active ? "activated" : "deactivated") });
            });
        },

        /**
         * Handles rel="close-override" click
         */
        handleCloseOverrideClick: function () {
            var self = CourseGrade;
            self.$dialog.dialog("close");
        },

        /**
         * Handles rel="save-override" click
         */
        handleSaveOverrideClick: function () {
            var self = CourseGrade,
            studentId = $(this).data("studentId"),
            termBinId = $(this).data("termBinId"),
            $row = $("#course-grade-" + studentId + "-" + termBinId),
            $input = $row.find("[name*=\"score_override\"]"),
            $override = self.$dialog.find("input[type=\"text\"]");

            // Validate override value
            if (self.validateOverride($override.val())) {
                
                // Set override value
                $input.val($override.val());

                // Submit values
                self.save(studentId, termBinId, function () {
                    var courseGrade = self.getCourseGrade(studentId, termBinId);
                    Growl.success({ message: courseGrade.term_bin.short_name + " override was set to " + courseGrade.score_override + "%" });
                }); 
            } else {
                $override.addClass("error");
                Growl.error({ message: "Override must be a whole number between 0 and 9999" });
            }
        },

        /**
         * Validates score override value
         * Allow any whole number 0 to 9999
         * @param int|string value
         * @return boolean
         */
        validateOverride: function (value) {
            return /^[0-9]{1,4}$/.test(value);
        },

        /**
         * Handles save
         * @param int studentId
         * @param int termBinId
         */
        save: function (studentId, termBinId, callback) {
            var self = this,
            courseGradeData = this.getCourseGradeData(studentId, termBinId),
            mappedCourseGrades = _.indexBy(this.courseGrades, function (courseGrade) {
                return courseGrade.student_id + "_" + courseGrade.term_bin_id;
            });

            // Make request
            $.post(self.config.api.save, courseGradeData)
                .done(function (data) {
                    var courseGradeSaved; 
                    if (data.success) {
                        _.each(data.results.records, function (record) {
                            var courseGrade = mappedCourseGrades[record.student_id + "_" + record.term_bin_id];
                            self.renderRow(_.merge(courseGrade, record, { score: Math.round(record.score) }));
                        });
                        self.courseGrades = _.values(mappedCourseGrades);
                        self.$dialog.dialog("close");
                        $(document.body).trigger("saved", data);
                        if (_.isFunction(callback)) {
                            callback();
                        }
                    } else {
                        displayNotifications(data, null, undefined, true);
                    }
                })
                .fail(function(jqXHR, textStatus, errorThrown) {
                    if (jqXHR.responseJSON) {
                        displayNotifications(jqXHR.responseJSON);
                    } else {
                        Growl.error({ message: "Something went wrong." });
                    }
                });
        },

        styleHeaders: function() {
            $(".courseBox .color[data-num_levels]").each(function () {
                var scoreCell = $(this);
                var color = Functions.getColor(scoreCell);
                var css = {'background-color': color, '-webkit-print-color-adjust': 'exact'};

                scoreCell.css(css);

                if (scoreCell.parent('h1').length) {
                    getPointsCells(scoreCell).css(css);
                }
            });
        }
    };

    window.Tss = window.Tss || {};
    window.Tss.CourseGrade = CourseGrade;

})(jQuery, window);

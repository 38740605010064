var Bindings = {
    setAllBindings: function() {
        //rel change-window-location click
        $(document).on('click', '[rel="change-window-location"]', function(e) {
            var elem = $(this);

            e.preventDefault();

            //kill action if has class disabled
            if (elem.hasClass('disabled')) {
                return false;
            }

            elem.addClass('disabled');
            
            var url = elem.data('url');
            var confirmMsg = elem.data('confirm');

            if (confirmMsg && url && window.confirm(confirmMsg)) {
                window.location.href = url;
            } else if (url && !confirmMsg) {
                window.location.href = url;
            }

            setTimeout(function() {
                elem.removeClass('disabled');
            }, 2000);
        });

        //rel ajax-request click
        $(document).on('click', '[rel="ajax-request"]', function(e) {
            e.preventDefault();

            //kill action if has class disabled
            if ($(this).hasClass('disabled')) {
                return false;
            }

            var url = $(this).data('url'),
                destination = $($(this).data('destination'));

            if (url) {
                if (destination.length == 0) {
                    $.ajax(url);
                } else {
                    destination.tssLoad(url);
                }
            }
        });

        $(document).on('click', '[rel="toggle-visible"]', function(e) {
            e.preventDefault();

            var link = $(this);
            var target = $(link.data('target'));

            if (target.length == 0) {
                target = link.closest('[rel="make-toggle-visible"]').find('.toggle-contents');
            }

            target.toggle();

            var toggleText = link.data('toggleText');
            if (toggleText) {
                link.data('toggleText', link.text());
                link.html(toggleText);
            }
        });

        // date change from term_bin
        $(document).on('click', '[rel="date-change"]', function(e) {
            e.preventDefault();

            var a = $(this),
                dates = a.data('dates'),
                sliderWrapper = a.closest('.sliderWrapper');

            if (sliderWrapper.length) {
                fillInForm(sliderWrapper.find('input').attr('name'), dates, sliderWrapper);
            } else {
                $('.showTermBinPanel[name*="start"]').val(dates[0]);
                $('.showTermBinPanel[name*="end"]').val(dates[1]).change();
            }
            $.datepicker._hideDatepicker();
            $(".ui-datepicker-buttonpane").addClass("termBinClicked")
        });
        $(document).on('mouseover', '.sliderWrapper:has(.ui-datepicker-buttonpane)', function(e) {
            $(".ui-datepicker-buttonpane").removeClass("termBinClicked")
        })

        //class overlay-remove i click
        $(document).on('click', '.overlay-remove > i', function(e) {
            e.preventDefault();

            //remove
            $(this).parent().parent().remove();

            //hide overlay curtain
            $('#overlay-curtain').addClass('hide');
        });

        //toggle inactive list items
        $(document).on('click', '[rel="toggle-inactive-list-items"]', function() {
            var lists = $('ul.toggle-inactive');

            if(!lists.length) {
                return;
            }

            lists.toggleClass('show-deactivated');

            //button text
            $(this).html('<i class="icon-eye-open"></i>&nbsp;Show Inactive');
            if(lists.eq(0).hasClass('show-deactivated')) {
                $(this).html('<i class="icon-eye-close"></i>&nbsp;Hide Inactive');
            }
        });

        //datepicker with icon
        $(document).on('click', '[rel="tss-icon-input"] i', function() {
            var $input = $(this).prev('input.hasDatepicker');

            if ($input.length) {
                $input.datepicker('show');
            }
        });

        //datepicker with icon
        $(document).on('click', '[rel="help-click"]', function() {
            var href = $(this).data('href');

            window.open(href, 'help');
        });

        //submit form button
        $(document).on('click', '[rel="submit-form"]', _.debounce(function() {
            if ($(this).attr('disabled') || $(this).hasClass('disabled')) {
                return;
            }

            if ($(this).attr('totango-action-name') && $(this).attr('totango-module-name')) {
                fetch(`/totango/${$(this).attr('totango-action-name')}/${$(this).attr('totango-module-name')}`)
            }

            var formSelector = $(this).data('form') || 'main-form';
            if(formSelector) {
                $('#' + formSelector).trigger('submit');
            }
        }, 1000, {leading: true, trailing: false}));

        $(document).on('click', '[tooltip][noclick]', function(e) {
            e.preventDefault();
        });

        $(window).resize(function() {
            var parentIframe = $(window.frameElement),
                childIframe = $('[name="faceboxIFrame"]');
            if(parentIframe.length) {
                parent.$('body').trigger('loaded');
            }
            if(childIframe.length) {
                childIframe.height(childIframe[0].contentWindow.$('html').height());
            }
        }).resize();

        $('body').on('loaded', function() {
           $(window).resize();
        });

        $('body').bind('keydown', '/', function(e) {
            if (e && e.target && $(e.target).is('input, textarea')) {
                return; // allow me to type slashes in inputs & textarea boxes
            }

            var searchSection = $('.search');
            var searchIcon = $(".search .search-icon");
            var searchBox = $(".search #searchBox");

            if (searchSection.hasClass('active')) {
                searchBox.focus().select();
            } else {
                searchIcon.click();
            }

            window.scrollTo(0, 0); // scroll to the top
            stopPropagation(e); // don't actually type the slash into the search box
        });

        $(document).on('click', '[data-behavior-info]', function(e) {
            var detail = $(this).data('behavior-info');
            stopPropagation(e);
            ajaxtooltip.cancelAll();
            $('.ajaxtooltip').hide();
            document.dispatchEvent(new CustomEvent('VueEvent.BehaviorModal', { detail }));
        });

        $(document).on('click', '[data-absence-info]', function(e) {
            var detail = $(this).data('absence-info');
            stopPropagation(e);
            document.dispatchEvent(new CustomEvent('VueEvent.AbsenceModal', { detail }));
        });

        $(document).on('click', '[data-class-absence-info]', function(e) {
            var detail = $(this).data('class-absence-info');
            
            stopPropagation(e);
            document.dispatchEvent(new CustomEvent('VueEvent.ClassAbsenceModal', { detail }));
        });
    },

    setupUIBindings: function() {
        $('input.make-tss-icon-toggle').tssIconToggle();
        $('[rel="make-tss-popover"]').tssPopover();
        $('select[rel="make-tss-multiselect-search"]').tssMultiSelectSearch({displayOptionText: true});
        $('select[rel="make-tss-select-search"]').tssSelectSearch({displayOptionText: true});
        $('select[rel="manual-assessment-add-student"]').tssSelectSearch({displayOptionText: true});
        $('ul.tabs').tssTabs();
        $('section.options, [rel="make-tss-expandable"]').tssExpandable();
        $('input[rel="make-tss-switch"]').tssSwitch();
        $('input[type="text"][name="weight"]').inputmask('999', {placeholder: ''});
        $('[rel="tss-sticky"]').tssSticky();
        $('[tss-make-mood-select]').tssMoodSelect();
        $('[rel="make-tss-comments"]').tssComments();
        $('[rel="make-toggle-visible"]').tssToggleVisible();
        $('[tss-date]').tssDate();
        $('form.validate-touched-only').tssFormValidation();
        Functions.setTwoWayBindings();
        $('body').trigger('setupUIBindings');
        Tss.DynamicData.init();
    }
};

window.Bindings = Bindings;
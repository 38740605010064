(function (s,r) {"use strict";

    var a,b,c = {

        init: function (b) {
            a = s(atob(b));
        },

        get: function (b) {
            return a[btoa(b)];
        }
    };

    r.Tss = r.Tss || {};
    r.Tss.Env = c;

})(JSON.parse,window);

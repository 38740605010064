var PivotTable = {
    showSubtotalsCols: true,
    showSubtotalsRows: true,

    create: function(results, dataCache) {
        // 1. Add each record's value to the correct bucket
        // 2. Output table
        this.bucketValues(results, dataCache);
        this.createTable(results);
    },

    // init for Analysis page--does this belong here or somewhere else?
    init: function(filterColumn, pivotDiv) {
        this.pivotDiv = pivotDiv;
        this.showSubtotalsCols = true;
        this.showSubtotalsRows = true;
        this.datasetVal = getDatasetOption(filterColumn).val();
        this.isAssessmentStudentData = this.datasetVal == 'assessment_students',
        this.isGradeData = (this.datasetVal == 'assessments' || this.isAssessmentStudentData
                || this.datasetVal == 'course_grades' || this.datasetVal == 'objectives');
        this.isDemeritData = (this.datasetVal == 'demerits');
        this.topOption = getPivotTableTopOption(pivotDiv);
        this.top2Option = getPivotTableTop2Option(pivotDiv);
        this.leftOption = getPivotTableLeftOption(pivotDiv);
        this.left2Option = getPivotTableLeft2Option(pivotDiv);

        var growthKey = this.top2Option.val() === '' ? this.topOption.val() : '';

        this.sortColumnsDesc = (pivotDiv.find('[name="sort_columns"]:checked').val() == 'desc');
        this.showLevel = (pivotDiv.find('[name="score_display"]:checked').val() == 'level');
        this.canShowGrowth = (growthKey == 'assessment_id'
                || growthKey == 'termbin.term_bin_name'
                || growthKey.substr(0, 5) == 'date.');
        this.showCurved = pivotDiv.find('[name="raw_or_curved"]:checked').val() == 'curved';
        this.masteryFunc = pivotDiv.find('[name="mastery_results"]:checked').val();
        this.behaviorValue = pivotDiv.find('[name="behavior_value"]:checked').val();
        this.selectedFunc = pivotDiv.find('[name="func"]:checked');
        this.func = this.selectedFunc.val() || 'count';
        this.funcText = pivotDiv.find('[for="' + this.selectedFunc.attr('id') + '"]').text() || 'Count';
        this.showGrowth = (this.canShowGrowth && pivotDiv.find('[name="show_growth"]:checked').val() == 1);
        this.gradingScale = getGradingScale();
        this.isAbsenceData = (this.datasetVal == 'absences');
        this.isPct = (this.isGradeData && this.gradingScale.is_pct == 1 || this.func == 'pct' || this.isAbsenceData);
        this.gradeModel = new GradeModel();
        this.breakout = getBreakoutOption(filterColumn);
        this.seriesBucket = getSeriesBucketOption(filterColumn);
        this.decimals = this.behaviorValue.match(/detention_value/) ? 3 : 0;
        this.minValue = this.isGradeData ? getSelectedOption($('select[name="pivot"]')).data('min_value') : 0;
    },

    bucketValues: function(results, dataCache) {
        var that = this,
            hasPct = false,
            hasNoPct = false,
            seenKeys = {};

        if (that.topOption.val() === "" && that.top2Option.val() !== "") {
            var temp = that.topOption;
            that.topOption = that.top2Option;
            that.top2Option = temp;
        }

        if (that.leftOption.val() === "" && that.left2Option.val() !== "") {
            var temp = that.leftOption;
            that.leftOption = that.left2Option;
            that.left2Option = temp;
        }

        this.topBucketModel = new BucketModel(this.topOption);
        this.top2BucketModel = new BucketModel(this.top2Option);
        this.leftBucketModel = new BucketModel(this.leftOption);
        this.left2BucketModel = new BucketModel(this.left2Option);
        this.pivotTableWrapper = this.pivotDiv.find('.pivotTableWrapper');
        this.totalsKey = '_totals';
        this.totals = {};
        this.allTopKeys = {};

        if (this.isGradeData) this.gradeModel.init('bar', this.breakout, this.seriesBucket, results);

        $.each(results.records, function(i, record) {
            var avgScore = (that.isGradeData ? parseFloat(record.avg_score || 0) : 0),
                curve = (record.assessment_id ? parseFloat(dataCache.assessment[record.assessment_id].curve) || 0 : 0),
                curvedScore = (that.isGradeData ? ('gradebook_score' in record ? record.gradebook_score : Math.min(100, avgScore + curve)) : 0),
                thisGradingScale = (that.isGradeData ? getGradingScaleForSet(record) : {}),
                val = (that.isGradeData ? (that.showCurved ? curvedScore : avgScore)
                       : (that.isDemeritData ? (that.behaviorValue == 'amount' ? record.multiplier : (record[that.behaviorValue] || 0))
                       : (record.value || 1))),
                isLevelOrAbove = that.isGradeData && (thisGradingScale.is_pct != 0 ? Math.round(val) : val) >= that.minValue ? 1 : 0,
                isTaken = that.isAssessmentStudentData && record.missing == 0 ? 1 : 0,
                leftVal = that.leftBucketModel.getBucketVal(record, val, that.gradingScale),
                left2Val = that.left2BucketModel.getBucketVal(record, val, that.gradingScale),
                topVal = that.topBucketModel.getBucketVal(record, val, that.gradingScale),
                top2Val = that.top2BucketModel.getBucketVal(record, val, that.gradingScale);

            if (that.masteryFunc == 'last') {
                var key = record.student_id + '_' + record.objective_id;

                if (key in seenKeys && seenKeys[key] !== record.assessment_id) return true; //continue;

                seenKeys[key] = record.assessment_id;
            }

            // make sure to clone so we don't modify the existing dataset
            leftVal = $.isArray(leftVal) ? leftVal.slice(0) : [leftVal];
            topVal = $.isArray(topVal) ? topVal.slice(0) : [topVal];
            left2Val = $.isArray(left2Val) ? left2Val.slice(0) : [left2Val];
            top2Val = $.isArray(top2Val) ? top2Val.slice(0) : [top2Val];

            val /= leftVal.length * left2Val.length * topVal.length * top2Val.length;

            if (!_.includes(topVal, that.totalsKey)) topVal.push(that.totalsKey);
            if (!_.includes(top2Val, that.totalsKey)) top2Val.push(that.totalsKey);
            if (!_.includes(leftVal, that.totalsKey)) leftVal.push(that.totalsKey);
            if (!_.includes(left2Val, that.totalsKey)) left2Val.push(that.totalsKey);

            $.each(topVal, function(j, tv1) {
                if (!(tv1 in that.allTopKeys)) {
                    that.allTopKeys[tv1] = {};
                }

                $.each(top2Val, function(k, tv2) {
                        that.allTopKeys[tv1][tv2] = {};

                    $.each(leftVal, function(l, lv1) {
                        $.each(left2Val, function(m, lv2) {
                            that.totals[lv1] = that.totals[lv1] || {};
                            that.totals[lv1][lv2] = that.totals[lv1][lv2] || {};
                            that.totals[lv1][lv2][tv1] = that.totals[lv1][lv2][tv1] || {};
                            that.totals[lv1][lv2][tv1][tv2] = that.totals[lv1][lv2][tv1][tv2]
                                    || {t: 0,
                                        c: 0,
                                        cLevelAndAbove: 0,
                                        cTaken: 0,
                                        gradingScale: thisGradingScale};

                            if (that.isGradeData && that.totals[lv1][lv2][tv1][tv2].gradingScale.is_pct !== thisGradingScale.is_pct) {
                                // if old is pct, ignore new
                                // if old is not pct, replace with new
                                if (that.totals[lv1][lv2][tv1][tv2].gradingScale.is_pct != 1) {
                                    that.totals[lv1][lv2][tv1][tv2].t = val;
                                    that.totals[lv1][lv2][tv1][tv2].c = 1;
                                    that.totals[lv1][lv2][tv1][tv2].cLevelAndAbove = isLevelOrAbove;
                                    that.totals[lv1][lv2][tv1][tv2].cTaken = isTaken;
                                    that.totals[lv1][lv2][tv1][tv2].gradingScale = thisGradingScale;
                                }
                            } else {
                                that.totals[lv1][lv2][tv1][tv2].t += val;
                                that.totals[lv1][lv2][tv1][tv2].c += 1;
                                that.totals[lv1][lv2][tv1][tv2].cLevelAndAbove += isLevelOrAbove;
                                that.totals[lv1][lv2][tv1][tv2].cTaken += isTaken;
                            }

                            hasPct = hasPct || (thisGradingScale.is_pct == 1);
                            hasNoPct = hasNoPct || (thisGradingScale.is_pct != 1);
                        });
                    });
                });
            });
        });

        if (hasPct && hasNoPct) {
            that.canShowGrowth = that.showGrowth = false;
        }

        if (!that.left2BucketModel.isValid() && '' in that.totals) delete that.totals['']['']; // None rows
        if (!that.leftBucketModel.isValid()) delete that.totals['']; // None rows
        if (!that.top2BucketModel.isValid() && '' in that.allTopKeys) delete that.allTopKeys['']['']; // None columns
        if (!that.topBucketModel.isValid()) delete that.allTopKeys['']; // None columns
    },

    createTable: function(results) {
        var that = this,
            gradeModels = {},
            rows = {headers: '', rows: ''},
            sortedLeftKeys = that.getSortedVals(that.leftBucketModel, that.totals),
            sortedTopKeys = that.getSortedVals(that.topBucketModel, that.allTopKeys, true, that.sortColumnsDesc),
            headerStr = '',
            headerStr2 = '',
            subHeaderStr = '',
            maxLen = 68;

        if (!that.top2BucketModel.isValid()) {
            that.showSubtotalsCols = false; // can't show subtotal cols if there's no sub-headers
        }

        if (!that.left2BucketModel.isValid()) {
            that.showSubtotalsRows = false; // can't show subtotal rows if there's no row headers
        }

        $.each(sortedTopKeys, function(i, topVal) {
            if (topVal === that.totalsKey) return true; // continue

            var displayName = that.getDisplayName(that.topBucketModel, topVal),
                tooLong = displayName.length > maxLen,
                name = tooLong ? displayName.substr(0, maxLen) + '...' : displayName,
                title = tooLong ? displayName : '',
                sortedTop2Keys = that.getSortedVals(that.top2BucketModel, that.allTopKeys[topVal], true, that.sortColumnsDesc),
                subCols = sortedTop2Keys.length - (!that.showSubtotalsCols || !that.top2BucketModel.isValid() ? 1 : 0),
                cols = (that.showGrowth && i > 0 ? 2 : 1) * subCols,
                colspan = cols > 1 ? ' colspan="' + cols + '"' : '',
                clazz = colspan || that.showGrowth ? ' class="section-start section-end"' : '';

            headerStr += '<th' + colspan + clazz + ' title="' + title + '">' + name + '</th>';

            $.each(sortedTop2Keys, function(k, top2Val) {
                if (!that.showSubtotalsCols && top2Val === that.totalsKey) return true; // continue

                var displayName = that.getDisplayName(that.top2BucketModel, top2Val),
                    tooLong = displayName.length > maxLen,
                    name = tooLong ? displayName.substr(0, maxLen) + '...' : displayName,
                    title = tooLong ? displayName : '',
                    cols = (that.showGrowth && i > 0 ? 2 : 1),
                    colspan = cols > 1 ? ' colspan="' + cols + '"' : '',
                    clazz = k === 0 ? ' class="section-start"' : (k === sortedTop2Keys.length - 1 ? ' class="section-start section-end total"' : '');

                if (that.top2BucketModel.isValid()) {
                    headerStr2 += '<th' + colspan + clazz + ' title="' + title + '">' + name + '</th>';
                }

                if (that.showGrowth) {
                    subHeaderStr += (i > 0
                        ? '<th class="section-start">Growth</th><th class="section-end">Value</th>'
                        : '<th class="section-start section-end">Value</th>');
                }
            });
        });

        sortedLeftKeys.splice(0, 0, that.totalsKey);

        $.each(sortedLeftKeys, function(i, leftVal) {
            var leftTotal = that.totals[leftVal],
                sortedLeft2Keys = that.getSortedVals(that.left2BucketModel, that.totals[leftVal], true),
                isTotalsRow = (leftVal === that.totalsKey),
                key = (isTotalsRow ? 'headers' : 'rows'),
                lastVal,
                totalDaysInPeriod = 0;

            if (isTotalsRow) {
                rows[key] += '<tr class="totals-row"><td class="text">' + that.funcText + '</td>';
                sortedLeft2Keys = [that.totalsKey];
            } else {
                var displayName = that.getDisplayName(that.leftBucketModel, leftVal),
                    tooLong = displayName.length > maxLen,
                    name = tooLong ? displayName.substr(0, maxLen) + '...' : displayName,
                    title = tooLong ? displayName : '',
                    numRows = sortedLeft2Keys.length - (that.showSubtotalsRows ? 0 : 1);

                rows[key] += (that.left2BucketModel.isValid() ? '<tbody>' : '') + '<tr><td class="text" data-val="' + leftVal + '" title="' + title + '"'
                        + (that.left2BucketModel.isValid() ? (' rowspan="' + numRows + '"') : '') + '>'
                    + that.valueToLink(that.leftBucketModel.getLastBucketField(), leftVal, name)
                    + '</td>';
            }

            $.each(sortedLeft2Keys, function(k, left2Val) {
                var isSubtotalsRow = (leftVal === that.totalsKey ^ left2Val === that.totalsKey);

                if (!that.showSubtotalsRows && isSubtotalsRow) return true; //continue;

                var left2Total = ((leftTotal && left2Val in leftTotal) ? leftTotal[left2Val] : null);

                if (that.left2BucketModel.isValid()) {
                    if (k !== 0) {
                        rows[key] += '<tr' + (isSubtotalsRow ? ' class="totals-row"' : '') + '>';
                    }

                    if (isTotalsRow) {
                        rows[key] += '<td class="text"></td>';
                    } else {
                        var displayName = that.getDisplayName(that.left2BucketModel, left2Val),
                            tooLong = displayName.length > maxLen,
                            name = tooLong ? displayName.substr(0, maxLen) + '...' : displayName,
                            title = tooLong ? displayName : '';

                        rows[key] += '<td class="text" data-val="' + left2Val + '" title="' + title + '">'
                            + that.valueToLink(that.left2BucketModel.getLastBucketField(), left2Val, name)
                            + '</td>';
                    }
                }

                $.each(sortedTopKeys, function(j, topVal) {
                    var topTotal = ((left2Total && topVal in left2Total) ? left2Total[topVal] : null),
                        isTotalsCol = (topVal === that.totalsKey),
                        sortedTop2Keys = isTotalsCol ? [that.totalsKey] : that.getSortedVals(that.top2BucketModel, that.allTopKeys[topVal], true, that.sortColumnsDesc);

                    $.each(sortedTop2Keys, function(m, top2Val) {
                        var isSubtotalsColumn = (topVal === that.totalsKey ^ top2Val === that.totalsKey);

                        if ((!that.showSubtotalsCols || !that.top2BucketModel.isValid()) && isSubtotalsColumn) return true; //continue;

                        var total = ((topTotal && top2Val in topTotal) ? topTotal[top2Val] : null),
                            thisGradingScale = (total ? total.gradingScale : {}),
                            thisIsPct = thisGradingScale.is_pct,
                            gradingScaleToUse = thisGradingScale,
                            isPctToUse = ((that.isGradeData ? thisIsPct : that.isPct) == 1),
                            isTotalsColumn = (topVal === that.totalsKey),
                            rowCount = Functions.get(that.totals, [leftVal, left2Val, that.totalsKey, that.totalsKey, 'c']),
                            val = (total ? (that.func == 'avg' ? Math.round(total.t / total.c * (isPctToUse ? 1 : 10)) / (isPctToUse ? 1 : 10) + (isPctToUse ? '%' : '')
                                         : (that.func == 'pct' ? Math.round(100 * total.c / rowCount) + '%'
                                         : (that.func == 'pct_level_and_above' ? Math.round(100 * total.cLevelAndAbove / total.c) + '%'
                                         : (that.func == 'pct_taken' ? Math.round(100 * total.cTaken / total.c) + '%'
                                         : (that.func == 'sum' ? Tss.Number.toCommas(total.t, that.decimals)
                                         : Tss.Number.toCommas(total.c)))))) : ''),
                            displayVal = val,
                            css = (isTotalsColumn || isSubtotalsColumn ? ' class="avgCol"' : '');

                        if (that.func === 'pct') {
                            css += ' title="' + (total ? (total.c + " / " + rowCount) : '') + '"';
                        } else if (that.isGradeData && that.func == 'avg' && val) {
                            var score = parseFloat(val.replace('%', '')),
                                id = thisGradingScale.grading_scale_id,
                                modelExists = (id in gradeModels),
                                thisGradeModel = gradeModels[id];

                            if (!modelExists) {
                                gradeModels[id] = thisGradeModel = new GradeModel();
                                thisGradeModel.gradingScale = thisGradingScale;
                                thisGradeModel.init('bar', that.breakout, that.seriesBucket, results);
                            }

                            var level = scoreToGradeLevel(score, gradingScaleToUse),
                                color = thisGradeModel.colorScale(gradingScaleToUse.levels.length - 1 - level.index),
                                title = level.name;

                            if (that.showLevel) { // swap value for level.name
                                displayVal = title;
                                title = val;
                            }

                            css += ' title="' + title + '" style="background-color: ' + color + ';"';
                        }

                        if (that.isAbsenceData && (that.func == 'avg' || that.func == 'sum') && val) {
                            var daysInPeriod = getDaysInTimePeriod(topVal, that.topBucketModel.bucketParts[that.topBucketModel.bucketParts.length - 1]);

                            totalDaysInPeriod += (daysInPeriod || 0);
                            val = 100 * total.t / (daysInPeriod || totalDaysInPeriod);
                            val = Math.round(val * 100) / 100 + '%';
                            displayVal = val;
                        }

                        if (that.showGrowth && j > 0 && !isTotalsColumn) {
                            var digits = Math.max(numDigits(val), numDigits(lastVal));

                            rows[key] += '<td>' + (val && lastVal ?
                                Tss.Number.toCommas(myParseFloat(val) - myParseFloat(lastVal), digits)
                                    + (lastVal.indexOf('%') >= 0 ? '%' : '') :
                                '') + '</td>';
                        }

                        rows[key] += '<td' + css + '>' + displayVal + '</td>';
                        lastVal = val || lastVal;
                    });
                });

                rows[key] += '</tr>';
            });

            rows[key] += (that.left2BucketModel.isValid() ? '</tbody>' : '');
        });

        var blankHeaderColumns = '<th></th>'
                    + (that.leftBucketModel.isValid() && that.left2BucketModel.isValid() ? '<th></th>' : ''),
            table = $('<table class="tablesorter demerit_table dashboard autoTotal pivot-table">'
                + '<thead>'
                + '<tr' + (subHeaderStr ? '' : ' class="headerRow"') + '>' + blankHeaderColumns
                    + headerStr + '<th class="avgCol">' + that.funcText + ' (' + (sortedTopKeys.length - 1) + ')</th></tr>'
                + (headerStr2 ? ('<tr' + (subHeaderStr ? '' : ' class="headerRow"') + '>' + blankHeaderColumns
                    + headerStr2 + '<th class="avgCol"></th></tr>') : '')
                + (subHeaderStr ? '<tr class="headerRow">' + blankHeaderColumns
                    + subHeaderStr + '<th class="avgCol">Value</th></tr>' : '')
                + rows.headers
                + '</thead>'
                + '<tbody>'
                + rows.rows
                + '</tbody>'
                + '</table>');

        this.pivotDiv.find('.showGrowth')[that.canShowGrowth ? 'show' : 'hide']();
        this.pivotDiv.find('.scoreDisplay')[that.isGradeData ? 'show' : 'hide']();
        this.pivotDiv.find('[for="func_pct_level_and_above"]')[that.isGradeData ? 'show' : 'hide']();
        this.pivotDiv.find('[for="func_pct_taken"]')[that.isAssessmentStudentData ? 'show' : 'hide']();
        this.pivotTableWrapper.empty().append(table);
        calculateTotals.apply(table);
        table.tablesorter();
        addHover();
    },

    getDisplayName: function(bucketModel, val) {
        return val === this.totalsKey ? 'Totals' : bucketModel.getBucketDisplay(val).display_name;
    },

    getSortedVals: function(bucketModel, obj, withTotals, reverse) {
        var that = this,
            filterTotals = function(v) { return v !== that.totalsKey; },
            vals = bucketModel.getSortedVals(Object.keys(obj).filter(filterTotals));

        if (reverse) {
            vals.reverse();
        }

        if (withTotals) {
            vals.push(that.totalsKey);
//        } else if (withTotals < 0) {
//            vals.splice(0, 0, that.totalsKey);
        }

        return vals;
    },

    valueToLink: function(field, value, text) {
        if (value === this.totalsKey) {
            return text;
        }

        if (field == 'student_id') {
            text = '<a href="/student/' + value + '" tooltip>' + text + '</a>';
        } else if (field == 'staff_member_id') {
            text = '<a href="/staffmember/' + value + '" tooltip>' + text + '</a>';
        } else if (field == 'demerit_type_id') {
            text = '<a href="/demerit/edit/' + value + '">' + text + '</a>';
        } else if (field == 'assessment_id') {
            text = '<a href="/assessment/' + value + '">' + text + '</a>';
        } else if (field == 'course_id') {
            text = '<a href="/course/' + value + '">' + text + '</a>';
        } else if (field == 'incident_id') {
            text = '<a href="/incident/' + value + '">' + text + '</a>';
        } else if (field == 'absence_id') {
            text = '<a href="/absences?absence_id=' + value + '">' + text + '</a>';
        } else if (field == 'communication_id') {
            text = '<a href="/communications?communication_id=' + value + '">' + text + '</a>';
        }

        return text;
    },
};

window.PivotTable = PivotTable;

(function ($, window, undefined) { "use strict";
    var GradeCam = {
        // Config
        config: {
            studentsApi: '/api/v1/students?active=1',
            student: 'Student',
            maxMcLetter: 'D',
            isCompact: 1,
            idMaxLength: 9,
        },

        scannedStudents: [],

        uploadData: {},

        /**
         * Reset obj used to store upload data
         */
        resetUploadData: function () {
            this.uploadData = {
                success: 0,
                failed: 0,
                errors: []
            };
        },

        /**
         * Clear results
         */
        clearResults: function () {
            this.$results.find('tbody').empty();
            this.$update.hide();
        },

        /**
         * Create observer
         */
        createObserver: function () {
            var observer;

            // Watch for changes on the class attribute and position viewer on change
            observer = new MutationObserver(_.debounce(this.positionViewer, 100));
            observer.observe(document, {
                subtree: true,
                attributes: true,
                attributeFilter: ['class']
            });

            // Position viewer on window resize
            $(window).resize(_.debounce(this.positionViewer, 100));
        },

        /**
         * Gets choices
         * @param string min
         * @param string max
         * @return string choices
         */
        getChoices: function (min, max) {
            var choices = '';
            var value;

            // Uppercase min and max
            min = String(min).toUpperCase();
            max = String(max).toUpperCase();

            value = min;

            while (value <= max) {
                choices += value;
                value = String.fromCharCode(value.charCodeAt(0) + 1);
            }

            return choices;
        },

        /**
         * Gets school_id_code max length for GradeCam form
         * @param array students
         * @return int idMaxLength
         */
        getIdMaxLength: function (students = []) {
            // Gets max length possible based on given set of students on assessment
            if (students.length) {
                var idMaxLength = GradeCam.getIdMaxLengthFromStudents(students);
                return $.when(idMaxLength);
            }

            // Gets max length possible based on all active students
            // Used when printing blank bubble sheets without a known set of students
            return Api.get(GradeCam.config.studentsApi)
                .then(_.property('results.students'))
                .then(GradeCam.getIdMaxLengthFromStudents);
         },

        /**
         * Gets school_id_code max length for GradeCam form
         * @param array students
         * @return int idMaxLength
         */
        getIdMaxLengthFromStudents: function (students) {
            var studentWithMaxId = _.maxBy(students, function (student) {
                return student.school_id_code.length;
            });

            var idMaxLength = _.get(studentWithMaxId, 'school_id_code', '').length;

            // if all else fails, default to preconfigured digit count
            return idMaxLength || GradeCam.config.idMaxLength;
        },

        /**
         * Gets mode
         * @return object
         */
        getMode: function (examLength = 0) {
            return {
                type: 'exam',
                exam_length: examLength,
                output_version: 1
            };
        },

        /**
         * Gets scan callback
         * @param object data
         */
        getScanCallback: function (data) {
            var self = GradeCam;
            var studentId = (data.gradecam_id + '').replace(/^0+/, '');

            self.renderScanOverlay();

            // Check if student is already on the assessment
            if (self.getStudent(ManualAssessment.getAllStudents(), studentId)) {
                self.handleStudentAnswers(studentId, data.answers);
            } else {
                // Check if student is in the dropdown
                if (self.getStudentOption(self.$studentSelect, studentId)) {
                    ManualAssessment.addStudent(self.$studentSelect, studentId);
                    self.handleStudentAnswers(studentId, data.answers);
                } else {
                    Growl.error({ message: self.config.student + ' is not valid (ID #' + studentId + ')' });
                }
            }
        },

        /**
         * Gets a student by school_id_code
         * @param array students
         * @param string schoolIdCode
         * @return object student
         */
        getStudent: function (students, schoolIdCode) {
            var student = _.find(students, function (student) {
                return schoolIdCode === student.school_id_code;
            });
            return student;
        },

        /**
         * Gets a student option by school_id_code
         * @param jQuery $select
         * @param string schoolIdCode
         * @return null|object student
         */
        getStudentOption: function ($select, schoolIdCode) {
            var $student = $select.find('option').filter(function (i, option) {
                return schoolIdCode === $(option).val();
            });
            return $student.length ? $student : null;
        },

        /**
         * Handles API load
         */
        handleAPILoad: function () {
            var self = GradeCam;

            self.setup();
            self.setBindings();
            self.clearResults();
        },

        /**
         * Handles form render error
         * @param object error
         */
        handleFormRenderError: function (error) {
            if (error && error.data) {
                _.each(error.data, function (message) {
                    if (message === 'Form too big') {
                        message = 'Bubble sheet is too large to render.<br>Reduce the number of assessment questions to reduce the form size.';
                    }
                    Growl.error({ message: 'Error: ' + message, duration: 7000 });

                    // Render error message to the console
                    $.error(message);
                });
            }
        },

        /**
         * Handles form render success
         * @param object urls
         */
        handleFormRenderSuccess: function (urls) {
            GradeCam.newWindow.location.href = urls.html;
        },

        /**
         * Handles plugin load
         */
        handlePluginLoad: function () {
            var self = GradeCam;

            window.gradecam = gradecam;

            // Manage CPU load be deactivating GradeCam when the "Scan" tab isn't active
            self.$tabs.on('click', '[data-tab]', function () {
                gradecam.setActive($(this).is(self.$scan));
            });
        },

        /**
         * Handles print click
         * @param object event
         */
        handlePrintClick: function (event) {
            var self = GradeCam;

            self.newWindow = window.open('', '_blank');
            self.newWindow.document.write('Generating Gradecam Forms....');

            var courseOption = getSelectedOption($('select[name="course_id"]'));
            var name = (courseOption.val() ? courseOption.text() + ' | ' : '') + $('input[name="name"]').val();

            event.preventDefault();

            var students = ManualAssessment.getAllStudentsICanSee();
            var questions = ManualAssessment.questions;
            var renderForm = _.partial(self.renderForm, name, students, questions);

            self.getIdMaxLength(students)
                .then(renderForm);
        },

        /**
         * Handles scan click
         */
        handleScanClick: function () {
            var self = GradeCam;
            var row = self.$section.find('.row');

            if (self.rendered) {
                return;
            }

            row.show();
            self.renderViewer();
        },

        /**
         * Handles update click
         */
        handleUpdateClick: function () {
            var self = GradeCam;

            // Render input tab
            ManualAssessment.renderTemplateFromData(true);

            // Trigger update
            Assessment.triggerMainFormSubmit();

            self.clearResults();
        },

        /**
         * Handles upload click
         */
        handleUploadClick: function () {
            var self = GradeCam;

            self.resetUploadData();

            gradecam.stopCamera();
            gradecam.scanFilesAsync(null, self.handleScanFilesAsync);
        },

        /**
         * Handles upload complete
         * @param int successCount
         * @param int errorCount
         */
        handleScanFilesAsync: function (err, scanData, next) {
            var self = GradeCam;

            if (err) {
                self.uploadData.errors.push(err.message);
                self.uploadData.failed++;
                return next();
            } else if (!next) {
                gradecam.startCamera();
                self.uploadScanNotification(self.uploadData);
                return;
            }

            self.getScanCallback(scanData);
            self.uploadData.success++;
            next();
        },

        uploadScanNotification: function(uploadData) {
            var type = uploadData.failed > 0 ? 'error' : 'success';
            var message;

            // dont send notification if nothing scanned
            if (uploadData.failed == 0 && uploadData.success == 0) {
                return;
            }

            // Check if errors exist
            if (uploadData.failed > 0) {
                message = '<strong>Upload Complete with errors</strong><br>Successfully scanned ' + uploadData.success + ' of ' + (uploadData.success + uploadData.failed) + ' files<br><br>Please re-upload all files.';
            } else {
                message = '<strong>Upload Complete</strong><br>Successfully scanned ' + uploadData.success + ' files';
            }

            Growl[type]({ message: message, duration: 7000 });
        },

        /**
         * Handles student answers
         * @param string schoolIdCode
         * @param array answers
         */
        handleStudentAnswers: function (schoolIdCode, answers) {
            var data = { answers: [] };
            var student;

            _.each(ManualAssessment.questions, function (question, i) {
                var response = answers[i].value;

                if (i >= answers.length) {
                    return true;
                }

                data.answers.push(question.correct_answer && question.correct_answer.length ? response : GradeCam.specialParseInt(response));
            });

            student = ManualAssessment.setStudentAnswers(schoolIdCode, data.answers);

            if (student) {
                data.name = student.display_name;
                this.$results.find('tbody').append(Handlebars.renderTemplate('gradecam-answers', data));
                this.$update.show();
                this.scannedStudents.push(student);
                this.$wrapper.trigger('student-scanned');
            }
        },

        updateScanStats: function() {
            var self = GradeCam;

            while (self.scannedStudents.length) {
                let student = self.scannedStudents.pop();

                self.postScanStats(student);
            }
        },

        postScanStats: function(student) {
            let data = {
                student_id: student.student_id,
                school_id: Tss.Env.get('school_id'),
                date: moment().format('YYYY-MM-DD'),
            };
            Api.post('/api/v1/grade-cam-scan-stats', data);
        },

        specialParseInt: function(response) {
            var parsed = parseInt(response);
            if (_.isNaN(parsed)) {
                parsed = response.charCodeAt(0) - 'A'.charCodeAt(0);
            }
            return parsed;
        },

        /**
         * Positions viewer
         */
        positionViewer: function () {
            var self = GradeCam;
            var visible = $('[data-tab="scan-assessment"]').is('.active');
            var width = self.$container.width();
            var height = (3 / 4) * self.$container.width();

            self.$section.height(height + 60);
            self.$wrapper.css({ width: width, height: height });
            self.$wrapper.css(visible ? Functions.getPosition(self.$container) : { top: -9999, left: -9999 });
        },

        /**
         * Renders form
         * @param string name
         * @param array students
         * @param array questions
         */
        renderForm: function (name, students, questions, idMaxLength) {
            var self = GradeCam;
            var form = gradecam.forms.createBlankForm();
            var idLabel = 'SR ' + self.config.student + ' Number';
            var sections = [];
            var section = null;

            form.addTopRightNumericSection(idLabel, idMaxLength);
            form.setFormCode(true);

            _.each(questions, function (question) {
                var correctAnswer = question.correct_answer;
                var pointValue = question.point_value;
                var isMc = !!correctAnswer;
                var value = (isMc ? correctAnswer.toUpperCase() : pointValue); // Uppercase so that value comparisons are accurate

                if (section === null || section.isMc !== isMc) {
                    section = { isMc: isMc, questions: [], maxValue: isMc ? self.config.maxMcLetter : 0};
                    sections.push(section);
                }

                section.questions.push(question);
                section.maxValue = (section.maxValue > value ? section.maxValue : value);
            });

            _.each(sections, function (section) {
                var minValue = section.isMc ? 'A' : '0';
                var choices = self.getChoices(minValue, section.maxValue);
                var mc = form.addMultipleChoiceSection(section.questions.length, choices);

                if (self.config.isCompact) {
                    mc.setCompact();
                }

                // Separates out one free response section where each are 8 chars wide
                _.each(section.questions, function (question, i) {
                    var correctAnswer = question.correct_answer;
                    var pointValue = question.point_value;
                    var value = (section.isMc ? correctAnswer : pointValue);

                    mc.override(i, 'label', question.question_name || question.question_number);

                    if (!section.isMc && value !== section.maxValue) {
                        mc.override(i, 'choices', self.getChoices(minValue, value));
                    }
                });
            });

            // Add students to form
            _.each(students, function (student) {
                var top = self.config.student + ': ' + student.display_name;
                var bottom = '<i>' + name + '</i>';
                var id = {};

                id[idLabel] = student.school_id_code;
                // Populate form info
                form.addInstance({
                    top: top,
                    bottom: bottom,
                    ids: id
                });
            });

            // Render form
            gradecam.forms.renderForm(
                form,
                self.handleFormRenderSuccess,
                self.handleFormRenderError
            );
        },

        /**
         * Renders scan overlay
         * @param boolean error
         */
        renderScanOverlay: function (error) {
            var $icon = $('<i>').addClass('icon-' + (error ? 'remove' : 'ok'));
            var $overlay = $('<div>').addClass('gradecam-overlay ' + (error ? 'error' : 'success')).html($icon);

            // Add overlay
            this.$wrapper.append($overlay);

            // Remove overlay after delay
            _.delay(function () {
                $overlay.fadeOut(1000, function () {
                    $(this).remove();
                });
            }, 500);
        },

        /**
         * Renders viewer
         */
        renderViewer: function () {
            // Set GradeCam mode and callback
            gradecam.setMode(this.getMode(ManualAssessment.questions.length));
            gradecam.bind('scan', this.getScanCallback);

            // Append GradeCam Html
            this.$wrapper.append(gradecam.getElement()).appendTo($('body'));

            // Handle position
            this.createObserver();
            this.positionViewer();

            // Mark as rendered
            this.rendered = true;
        },

        /**
         * Setup
         */
        setup: function () {
            this.$wrapper = $('<div>', { id: 'gradecam-wrapper' }).css({ position: 'absolute' });
            this.$container = $('#gradecam');
            this.$section = $('#scan-assessment');
            this.$results = $('#scan-results');
            this.$tabs = $('.tabs');
            this.$scan = $('[rel="gradecam-scan"]');
            this.$update = $('[rel="gradecam-update"]');
            this.$studentSelect = $('[rel="manual-assessment-add-student"]');
            this.$input = $('[data-tab="input-assessment"]');
        },

        gradeCamScanReady: function () {
            GradeCam.$scan.removeClass('disabled').on('click', GradeCam.handleScanClick);
        },

        /**
         * Sets bindings
         */
        setBindings: function () {
            var self = this;

            // if we are in edit mode & have questions gradecam scan is ready
            if (ManualAssessment.questions.length) {
                self.gradeCamScanReady();
            }

            // when we click on input tab, we auto create an assessment
            // question so we are officially ready for gradecam scan
            self.$input.on('click', self.gradeCamScanReady);

            // handle on upload of assessment definition
            $('body').on('gradecam-scan-ready', self.gradeCamScanReady);

            // setup handlers to other gradecam actions
            $('[rel="gradecam-print"]').on('click', self.handlePrintClick);
            $('[rel="gradecam-update"]').on('click', self.handleUpdateClick);

            var updateScanStats = _.debounce(
                self.updateScanStats,
                5000,
                {
                    trailing: true,
                    leading: false,
                }
            );
            self.$wrapper.on('student-scanned', updateScanStats)

            new Tss.Modal({
                launchTarget: '[rel="gradecam-upload"]',
                template: 'gradecam-upload-warning-modal',
                go: function() {
                    this.closeModal();
                    self.handleUploadClick();
                },
            });
        }
    };

    window.gradeCamOnAPILoad = GradeCam.handleAPILoad;
    window.gradeCamOnPluginLoad = GradeCam.handlePluginLoad;

    window.Tss = window.Tss || {};
    window.Tss.GradeCam = GradeCam;

})(jQuery, window);

/**
 *
 * @author cmitchell
 */
(function($) {
    $.fn.tssAutogrow = function() {
        return this.each(function(){
            var $el = $(this);

            //have we already applied autogrow?
            if($el.attr('tss-autogrow')) { return; }

            //make hidden div
            var fontSize = $el.css('font-size'),
                lineHeight = $el.css('line-height'),
                hiddenDiv = $('<div class="tss-autogrow-hidden-div" />');
            hiddenDiv.css({'font-size': fontSize, 'line-height': lineHeight}).appendTo('body');


            //add attribute signifying that we've already applied autogrow
            $el.attr('tss-autogrow', '');

            //functions
            var resizeTextarea = function() {
                hiddenDiv.width($el.width()).html($el.val().replace(/\n/g, '<br />') + '<br /><br />');
                $el.height(hiddenDiv.height());
            };

            //bindings
            $el.off('keyup.tss-autogrow').on('keyup.tss-autogrow', resizeTextarea)
               .off('focus.tss-autogrow').on('focus.tss-autogrow', resizeTextarea);

           if ($el.val()) {
               $el.trigger('keyup');
           }
        });
    };
}(jQuery));
var Dates = {
    termBins: [],
    common: [],
    termBinDates: {},
    commonDateLinks: $('<div class="ui-datepicker-buttonpane ui-widget-content common-date-links"/>'),
    loaded: false,
    loadTermBins: function() {
        var that = this;

        // only once
        if (that.loaded) {
            this.appendDateLinks();
            return;
        };
        that.loaded = true;

        $.post('/termbins/all/?for_grade=0&include_common=1', function(data) {
            that.termBins = data.results.termbins;
            that.common = data.results.common;

            $.each(that.termBins, function(i, termbin) {
                var key;

                that.termBinDates[key = serverToDate(termbin.start_date)] =
                    (that.termBinDates[key] ? that.termBinDates[key] + ', ' : '') + termbin.display_name + ' Start';
                that.termBinDates[key = serverToDate(termbin.end_date)] =
                    (that.termBinDates[key] ? that.termBinDates[key] + ', ' : '') + termbin.display_name + ' End';
            });

            var rows = [],
                allRanges = [].concat(that.termBins),
                today = dateToServer(new Date());

            $.each(that.common, function(i, arr) {
                $.each(arr, function(j, termbin) {
                    termbin.key = (1000 * (i + 1));
                    allRanges.push(termbin);
                });
            });

            $.each(allRanges, function(i, termbin) {
                var key = termbin.term_bin_type_id || termbin.key,
                    startDate = termbin.start_date,
                    endDate = termbin.end_date,
                    startDateStr = $.datepicker.formatDate('m/d/y', dateStringToDate(startDate)),
                    endDateStr = $.datepicker.formatDate('m/d/y', dateStringToDate(endDate)),
                    row = rows[key] || [];

                if (startDate <= today) {
                    row.push("<a rel='date-change' "
                        + "class='" + (termbin.for_grade == 1 ? 'forGrade' : '') + "' "
                        + "title='" + startDateStr + ' - ' + endDateStr + "' "
                        + "data-dates='[\"" + startDate + "\",\"" + endDate + "\"]'>"
                        + termbin.display_name + '</a>');
                } else {
                    row.push('');
                }

                rows[key] = row;
            });

            $.each(rows, function(i, row) {
                if (!row) return true; // continue

                var bucketSize = 6,
                    subRows = [],
                    sectionDiv = $('<div class="dateSection"></div>'),
                    table = $('<table></table>');
                if (row.length >= bucketSize) {
                    while (row.length) {
                        subRows.push(row.splice(0, bucketSize));
                    }
                } else {
                    subRows = [row];
                }

                $.each(subRows, function(j, subRow) {
                    if (!subRow.filter(function(v) { return v; }).length) return true; //continue;

                    var width = j == 0 ? ' style="width: ' + Math.round(100 / subRow.length) + '%"' : '',
                        td = '<td' + width + '>';

                    table.append('<tr>' + td + subRow.join('</td>' + td) + '</td></tr>');
                });

                sectionDiv.append(table);
                that.commonDateLinks.append(sectionDiv);
            });

            that.appendDateLinks();
        });
    },

    appendDateLinks: function() {
        var that = this,
            dateSliderWrappers = $('.sliderWrapper').find('[data-filter_type="date"]').closest('.sliderWrapper');

        dateSliderWrappers.each(function(i) {
            var $this = $(this);
            $this.remove('.common-date-links');
            $this.append(that.commonDateLinks[0].outerHTML);
        });
    }
};
window.Dates = Dates;
(function($, window) { "use strict";
    var Terms = {
        termApi: '/api/v1/terms',
        termBinApi: '/api/v1/term-bins',
        bulkTermBinApi: '/api/v1/bulk/term-bins',
        modal: null,
        modalData: {},
        launchTarget: '#launch-manage-term-bins',
        template: 'manage-term-bins-modal',
        oneTermBinRowTemplate: 'manage-term-bins-row',

        initAddEditTerm: function(action, id, opts) {
            var self = this;
            var defaultOpts = {
                submitCallback: _.bind(self.updateOrCreateY1TermBinFromTerm, self),
            };
            Tss.Types.initAddEdit("Term", "short_name", action, id, _.extend(defaultOpts, opts));
        },

        initTermBinsList: function(opts) {
            var self = this;

            self.opts = opts;

            Tss.Types.initList("TermBin", "short_name", false, {
                extraApiParams: {
                    term_ids: self.opts.term_id,
                    expand: 'term,term_bin_type', // doesn't work right now
                }
            });
            
            self.modal = new Tss.Modal({
                launchTarget: self.launchTarget,
                template: self.template,
                remove: true, // recreate from scratch each time you click
                getData: () => self.modalData,
                go: _.bind(self.saveTermAndTermBins, self),
            });

            Tss.Types.$table.on('TableRendered', _.bind(self.getModalData, self));
            $('body').on('TssModalShow', `.${self.template}`, _.bind(self.renderModalRows, self));
            $('body').on('change', 'select[name="new_term_bin_type_id"]', self.renderNewTermBinRows);
        },

        updateOrCreateY1TermBinFromTerm: async function(data, form) {
            var term = _.get(data, 'results.term');
            var response = await Api.get('/api/v1/term-bins', {
                termIds: term.term_id,
                shortNames: 'Y1',
                active: 1,
            });
            var termBins = _.get(response, 'results.term_bins');

            if (!termBins.length) {
                response = await Api.post('/api/v1/term-bins', {
                    term_id: term.term_id,
                    term_bin_type_id: 1,
                    school_id: term.school_id,
                    short_name: 'Y1',
                    long_name: 'Year 1',
                    start_date: term.start_date,
                    end_date: term.end_date,
                    active: 1,
                });
            } else {
                var termBin = termBins[0];

                if (term.start_date != termBin.start_date
                        || term.end_date != termBin.end_date) {
                    response = await Api.put(`/api/v1/term-bins/${termBin.term_bin_id}`, {
                        start_date: term.start_date,
                        end_date: term.end_date,
                    });
                }
            }

            var referrer = form.data('referrer');
            referrer = referrer && referrer != window.location
                ? referrer
                : "/setup/configure/" + Tss.Types.config.type.endpoint;
            window.location.assign(referrer);
        },

        saveTermAndTermBins: async function() {
            var self = this;
            var form = $(`.${self.template}`);
            var saveButton = form.find('.go');
            var includeDisabled = true;

            if (!validateForm(form)) {
                return false;
            }

            saveButton.attr('disabled', 'disabled').addClass('disabled').find('span').text('Saving...');
            saveButton.find('i').show();

            var yearTermBin = getFormData(form.find('section[name="year_term_bin"] .row'), includeDisabled);
            var termId = await self.createOrUpdateTermFromY1TermBin(yearTermBin);
            var termBinDefaults = {
                term_id: termId,
            };
            var allTermBins = [
                _.extend(yearTermBin, termBinDefaults),
            ];

            form.find('section[data-termBinTypeId] .row, #new_one .row').each(function() {
                var row = $(this);
                var termBin = getFormData(row, includeDisabled);

                allTermBins.push(_.extend(termBin, termBinDefaults));
            })

            await self.bulkUpdateTermBins(allTermBins);
            self.modal.closeModal();
            window.location.reload();
        },

        createOrUpdateTermFromY1TermBin: async function(termBin) {
            var self = this;
            var termId = termBin.term_id;
            
            if (!termId) {
                var termShortName = $.datepicker.formatDate('y', dateStringToDate(termBin.start_date))
                    + '-' + $.datepicker.formatDate('y', dateStringToDate(termBin.end_date));
                var termLongName = $.datepicker.formatDate('yy', dateStringToDate(termBin.start_date))
                    + '-' + $.datepicker.formatDate('yy', dateStringToDate(termBin.end_date));
                var sisYearId = parseInt($.datepicker.formatDate('yy', dateStringToDate(termBin.start_date)))
                    - 1990;
                var response = await Api.post(self.termApi, {
                    school_id: self.opts.school_id,
                    short_name: termShortName,
                    long_name: termLongName,
                    start_date: termBin.start_date,
                    end_date: termBin.end_date,
                    sis_year_id: sisYearId,
                    active: 1,
                });

                termId = _.get(response, 'results.term.term_id');
            } else {
                var response = await Api.get(`${self.termApi}/${termId}`);
                var term = _.get(response, 'results.term');

                if (term.start_date != termBin.start_date
                        || term.end_date != termBin.end_date) {
                    // update term dates to match
                    await Api.put(`${self.termApi}/${termId}`, {
                        start_date: termBin.start_date,
                        end_date: termBin.end_date,
                    });
                }
            }

            return termId;
        },

        bulkUpdateTermBins: async function(termBins) {
            var self = this;
            var bulkTermBinData = {
                bulk_data: termBins
            };

            await Api.put(self.bulkTermBinApi, bulkTermBinData); // create or update
        },

        getModalData: function(e, data) {
            var self = this;
            var termBins = _.chain(data)
                .get(`results.term_bins`)
                .filter(x => x.active == 1)
                .value();
            var termBinMap = _.groupBy(termBins, 'term_bin_type_id');
            var yearTermBin = _.get(termBinMap, `1.[0]`); // year term bin

            if (!yearTermBin) {
                var defaultStartDate = $.datepicker.formatDate('yy', new Date()) + '-08-15';
                var defaultEndDate = (parseInt($.datepicker.formatDate('yy', new Date())) + 1) + '-05-31';

                yearTermBin = {
                    term_bin_type_id: 1,
                    start_date: defaultStartDate,
                    end_date: defaultEndDate,
                    short_name: 'Y1',
                    long_name: 'Year 1',
                    term_id: self.opts.term_id,
                    school_id: self.opts.school_id,
                };
            }

            self.modalData.yearTermBin = yearTermBin;
            self.modalData.termBinTypes = _.chain(termBins)
                .pluck('term_bin_type_id')
                .uniq()
                .sort()
                .filter(x => x != 1)
                .map(termBinTypeId => {
                    var obj = {
                        term_bin_type_id: termBinTypeId,
                        display_name_plural: _.chain(termBinMap)
                            .get(`${termBinTypeId}[0].term_bin_type.display_name`)
                            .pluralize()
                            .value(),
                        term_bins: _.chain(termBinMap)
                            .get(termBinTypeId)
                            .sortBy('start_date')
                            .filter(x => x.active == 1)
                            .value(),
                    };
                    
                    return obj;
                })
                .value();
            self.modalData.supportedTermBinTypes = _.filter(
                self.opts.supported_term_bin_types,
                x => !(x.term_bin_type_id in termBinMap)
            );

            $(self.launchTarget).show();

            if (!self.modalData.yearTermBin.term_bin_id) {
                self.modal.showModal();
            }
        },

        renderModalRows: function() {
            var self = this;
            var form = $(`.${self.template}`);

            form.on('change', 'input', function() {
                var input = $(this);
                var date = input.val();
                var row = input.closest('.row');

                if (row.find('[name="term_bin_type_id"][value="1"]').length) {
                    // if you're updating the school year, update the start/end
                    // dates of all the other term bins
                    if (input.attr('name') == 'start_date') {
                        form.find('section').each(function() {
                            $(this).find('input.date').first().val(date);
                        });
                    } else if (input.attr('name') == 'end_date') {
                        form.find('section').each(function() {
                            $(this).find('input.date').last().val(date);
                        });
                    }
                } else {
                    // if you're updating the end date of one quarter/etc.
                    // then update the start date of the next quarter/etc. to be
                    // one day after.
                    var nextStartDate = dateStringToDisplay(addBusDays(date, 1));

                    row.next().find('input.date').first().val(nextStartDate);
                }
            });

            form.find(`.${self.oneTermBinRowTemplate}`).remove();
            form.find('section[name="year_term_bin"]').append(Handlebars.renderTemplate(self.oneTermBinRowTemplate, _.extend({}, self.modalData.yearTermBin, {
                enableFirst: true,
                isFirst: true,
                short_name: 'Year',
            })));

            _.each(self.modalData.termBinTypes, termBinType => {
                _.each(termBinType.term_bins, (termBin, i) => {
                    var isFirst = i == 0;
                    var isLast = i == termBinType.term_bins.length - 1;
                    form.find(`#tab_${termBinType.term_bin_type_id}`).append(Handlebars.renderTemplate(self.oneTermBinRowTemplate, _.extend({}, termBin, {
                        isFirst,
                        isLast,
                    })));
                })
            })

            form.each(setupUI);
        },

        renderNewTermBinRows: function() {
            var self = Terms;
            var select = $(this);
            var termBinTypeId = select.val();
            var selectedOption = select.find(`option:selected`);
            var count = termBinTypeId; // 2 = 2 Semesters, 3 = 3 Trimesters, etc.
            var shortName = selectedOption.attr('short-name');
            var longName = selectedOption.text();
            var newOne = $('#new_one');
            
            newOne.find(`.${self.oneTermBinRowTemplate}`).remove();
            
            if (!termBinTypeId) {
                return;
            }
            
            var yearStartDate = $('section[name="year_term_bin"] input[name="start_date"]').val();
            var yearEndDate = $('section[name="year_term_bin"] input[name="end_date"]').val();
            var yearStartTime = new Date(yearStartDate).getTime();
            var yearEndTime = new Date(yearEndDate).getTime();
            var lastEndDate = null;
            for (var i = 0; i < count; i++) {
                var isFirst = i == 0;
                var isLast = i == count - 1;
                var bucketNumber = i + 1;
                var startDate = isFirst || !yearStartDate
                    ? yearStartDate
                    : dateStringToDisplay(addBusDays(lastEndDate, 1));
                lastEndDate = isLast || !yearEndDate
                    ? yearEndDate
                    : dateStringToDisplay(new Date(yearStartTime + ((yearEndTime - yearStartTime) * (i + 1) / count)))
                var termBin = {
                    isFirst,
                    isLast,
                    term_id: self.opts.term_id,
                    school_id: self.opts.school_id,
                    term_bin_type_id: termBinTypeId,
                    short_name: `${shortName}${bucketNumber}`,
                    long_name: `${longName} ${bucketNumber}`,
                    start_date: startDate,
                    end_date: lastEndDate,
                }
            
                newOne.append(Handlebars.renderTemplate(self.oneTermBinRowTemplate, termBin));
            }

            newOne.each(setupUI);
        },
    };

    window.Tss = window.Tss || {};
    window.Tss.Terms = Terms;

})(jQuery, window);

(function ($, window) {"use strict";

    var Courses = {
        bulkSectionMappingApi: '/api/v1/bulk/section-mappings',

        config: {
            tables: {
                options: {
                    headers: {
                        1: {
                            sorter: false
                        }
                    },
                    widgets: ["zebra"]
                }
            },
            // BEGIN
            // All tables will probably have this code. Put in a central file?
            pagers: {
                options: {
                    output: "{page} of {totalPages}"
                }
            },
            // END
        },

        init: function () {
            this.config.tables.courses = $("#courses-table");
            this.config.pagers.courses = $("#courses-table-pager");
            this.initTable(this.config.tables.courses, [[0,0]], this.config.pagers.courses);
        },

        initTable: function (table, sortList, pager) {
            var self = this,
                tableOptions = $.extend({}, { sortList: sortList || [] }, this.config.tables.options),
                pagerOptions;

            // BEGIN
            // This code is repeated from edit-users.js
            // We should create a file that allows for DRY code
            table.tablesorter(tableOptions);
            table.on("pageMoved", function () {
                var currentPage = (courses.config.tables.courses.data("pagerLastPage") || 0) + 1;
                self.updatePageDisplay(currentPage);
            });
            if (pager) {
                pagerOptions = $.extend({}, { container: pager }, this.config.pagers.options);
                table.tablesorterPager(pagerOptions);
            }
            // END
        },

        // BEGIN
        // This code is repeated from edit-users.js
        // Should also be in the default code file
        updatePageDisplay: function (currentPage) {
            var pagerData = courses.config.tables.courses.data("tablesorter").pager,
                pageDisplay = pagerData.container.find(pagerData.cssPageDisplay),
                totalPages = pagerData.filteredPages;
            currentPage = currentPage > totalPages ? totalPages : currentPage;
            pageDisplay.text(currentPage + " of " + totalPages);
        },
        // END

        initAddEdit: function(action, id, opts) {
            var self = this;
            var defaultOpts = {
                submitCallback: _.bind(self.createOrUpdateSectionMappings, self),
                extraApiParams: {
                    expand: 'section_mappings',
                },
            };

            Tss.Types.initAddEdit("Course", "name", action, id, _.extend(defaultOpts, opts));
        },

        /**
         * Create or update section mapping. End and start new if changed.
         *
         * @param  Object formData
         * @param  int sectionPeriodId
         * @param  array sectionMappings
         */
        createOrUpdateSectionMappings: async function(data, form) {
            var self = this;
            const courseId = _.get(data, 'results.course.course_id');
            const sectionMappings = _.get(data, 'results.course.section_mappings');
            const formData = getFormData(form, true);
            const oldSectionMappings = _.filter(sectionMappings, m => m.active == 1); // only care about active mappings
            const oldSectionPeriodIds = _.map(oldSectionMappings, m => parseInt(m.section_period_id)); // parseInt so we don't have to worry about string vs int
            const newSectionPeriodIds = _.map(formData.section_period_id, i => parseInt(i));
            const sectionMappingsToDeactivate = _.chain(oldSectionMappings)
                .filter(m => !_.includes(newSectionPeriodIds, parseInt(m.section_period_id))) // get all old course_ids that aren't in newSectionPeriodIds
                .map(m => _.extend(m, { active: 0 })) // deactivate old mapping
                .value();
            const sectionMappingsToCreate = _.chain(newSectionPeriodIds)
                .filter(newSectionPeriodId => !_.includes(oldSectionPeriodIds, newSectionPeriodId)) // get all new course_ids that aren't in oldSectionPeriodIds
                .map(sectionPeriodId => ({ // add new mapping
                    term_id: formData.term_id,
                    section_period_id: sectionPeriodId,
                    course_id: courseId,
                }))
                .value();
            const newSectionMappings = sectionMappingsToDeactivate
                .concat(sectionMappingsToCreate);

            if (newSectionMappings.length) {
                await Api.post(self.bulkSectionMappingApi, {
                    bulk_data: newSectionMappings,
                });
            }

            Tss.Types.redirectToReferrer();
        },
    };

    window.Tss = window.Tss || {};
    window.Tss.Courses = Courses;

})(jQuery, window);

(function ($, window, undefined) {

    var defaults = {
        position: { top: 0, right: 0 },
        autoAttach: true,
        autoDetach: true,
        attachClass: "attached",
        detachClass: "detached",
        noHide: false,
        offset: undefined,
        initialPosition: undefined,
        onAttach: function () {},
        onDetach: function () {}
    };

    function TssSticky(element, options) {
        defaults.position.top = $('body').css('marginTop');
        this._options = $.fn.tssOptions("tss-sticky", element, defaults, options);
        this.sticky = element;
        this.$sticky = $(element);
        setup.call(this);
    };

    function setup() {
        var self = this, hideSticky, offset;
        if (this.$sticky.is(":hidden") && !this._options.noHide) {
            this.$sticky.show();
            hideSticky = true;
        }
        if (hideSticky) {
            this.$sticky.hide();
        }
        $(window).scroll(function (e) {
            // ignore the first scroll event since it provides an unreliable position
            if (!self.once) {
                self.once = true;
                return;
            }
            
            // get the offset and initialPosition on the second scroll event to
            // get reliable position values.
            if (!self.offset) {
                self.offset = self.$sticky.offset();
                self.initialPosition = getPosition.call(self);
            }
            
            var isAttached = self.isAttached();
            if ($(this).scrollTop() > self.offset.top) {
                if (!isAttached) {
                    self.attach();
                }
            } else {
                if (isAttached) {
                    self.detach();
                }
            }
        });
    };

    function getPosition() {
        var self = this, position = { position: this.$sticky.css("position") };
        _.each(this._options.position, function (value, key) {
            position[key] = self.$sticky.css(key);
        });
        return position;
    };

    TssSticky.prototype.isAttached = function () {
        return getPosition.call(this).position === "fixed";
    };

    TssSticky.prototype.toggle = function (attach) {
        this.$sticky.css(attach ? $.extend({ position: "fixed" }, this._options.position) : this.initialPosition);
    };

    TssSticky.prototype.attach = function () {
        this._options.onAttach.call(this.$sticky);
        this.$sticky.removeClass(this._options.detachClass);
        this.$sticky.addClass(this._options.attachClass);
        if (this._options.autoAttach) {    
            this.toggle(true);
        }
    };

    TssSticky.prototype.detach = function () {
        this._options.onDetach.call(this.$sticky);
        this.$sticky.removeClass(this._options.attachClass);
        this.$sticky.addClass(this._options.detachClass);
        if (this._options.autoDetach) {
            this.toggle(false);
        }
    };

    $.fn.tssSticky = $.fn.tssExtend(TssSticky);

})(jQuery, window);

/**
 * @author ccoglian
 */
(function($) {
    $.fn.tssFormValidation = function(passedOptions) {
        var defaultOptions = {};
        var baseOptions = $.extend({}, defaultOptions, passedOptions || {});

        return this.each(function() {
            var $form = $(this);
            var options = $.extend({}, baseOptions, $form.data());

            validate($form); // do this every time this gets called

            // make sure the plugin only gets applied once
            if ($form.hasClass('is-tss-form-validation')) { return; }
            $form.addClass('is-tss-form-validation');

            init($form, options);
        });

        /**
         * @param {object} $form
         * @param {object} options
         */
        function init($form, options) {
            setupBindings($form, options);
        }

        /**
         * @param {object} $form
         * @param {object} options
         */
        function setupBindings($form, options) {
            var doValidation = function() {
                validate($form);
            };

            $form.on('blur keydown', 'input', markAsTouched);
            $form.on('change', 'input,select', doValidation);
            $form.on('validate', doValidation);
        }

        function markAsTouched() {
            $(this).addClass('touched');
        }

        function validate($form) {
            var valid = $form.get(0).checkValidity()
                    && !$form.find('input:invalid, .error, .invalid').length;

            $form.find('input.date').each(function() {
                var dateValid = validateDate(this);

                $(this)[dateValid ? 'removeClass' : 'addClass']('invalid');
                $(this).attr('title', dateValid ? '' : 'Please enter a valid date');

                valid = valid && dateValid;
            });

            $form[valid ? 'removeClass' : 'addClass']('invalid');
        }

        function validateDate(input) {
            var dateStr = $(input).val();

            // null is ok or else make sure we could parse it
            // if you want null to NOT be ok, set the required attr on the input
            return !dateStr
                || dateStringToDate(dateStr) instanceof Date;
        }
    };
}(jQuery));
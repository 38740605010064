$(document).ready(function(){
    // Get selected school name and term
    var schoolName = $("#schoolSpan select option[selected]").length
                        ? $("#schoolSpan select option[selected]").html()
                        : $("#schoolSpan p").html();
    var studentName = $("#studentSpan select option[selected]").html();
    var schoolTerm = $("#termSpan select option[selected]").html();

    // Set the name and term
    $(".school-term-menu .student-name").html(studentName);
    $(".school-term-menu .school-name").html(schoolName);
    $(".school-term-menu .school-term").html(schoolTerm);

    // make search box work
    $(".search .search-icon").on("click",function(event){
        // Toggle search box
        $(this).parent().toggleClass("active").promise().done(function() {
            // Clear the search & focus
            $("#searchBox").focus().val("");
        });
        $(this).closest('header').toggleClass('search-active');
    });

    // open mobile menu
    $(".menu-trigger").on("click",function(event) {
        event.preventDefault();
        $('.main-nav').toggleClass('mobile-menu-open');
    });

    // handle submenu toggle on mobile
    $('.main-nav').on('click', 'a', function(event) {
        var nextElem = $(this).next();
        var nextParent = $(this).parent();
        var windowWidth = $(window).width();
        if (nextElem.hasClass('menu') && windowWidth <= 1080) {
            event.preventDefault();
            $('.main-nav').find('ul.menu .with-submenu').not(nextParent).removeClass('open');
            $('.main-nav').find('ul.menu ul.menu').not(nextElem).hide();
            nextParent.toggleClass('open');
            nextElem.toggle();
        }
    });

    // Style the selects
    $("select[rel=make-tss-select-header]").tssSelectSearch({displayOptionText: true});
});

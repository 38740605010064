(function($, window) { "use strict";
    var GradingScales = {
        config: {
            textInputSelector: 'input.color-input',
            colorInputSelector: 'input[type="color"]',
        },

        initAddEdit: function(action, id) {
            var self = this;

            self.action = action;
            self.$form = $('#main-form');
            self.$table = $('#types-table');
            self.$pager = $("#types-table-pager");
            self.$toggleShowInactive = $("#toggle-show-inactive");
            self.showActiveOnly = true;

            if (action == 'add') {
                self.fillInGradingScaleLevels();
            } else {
                self.$form.on('FillInForm', self.fillInGradingScaleLevels);
            }

            self.$table.on("click", "[rel=\"toggle-active\"]", self.handleToggleActiveClick);
            self.$table.on('keyup blur', 'input', self.handleListKeyup);
            self.$table.on('change', 'input[name="min_value"]', self.reColor);
            
            self.$toggleShowInactive.on("click", self.handleToggleShowInactiveClick);

            // show the system color picker when you click on the text field
            self.$table.on('click', self.config.textInputSelector, self.showColorPicker);

            // update the text field when you choose something in the system
            // color picker
            self.$table.on('change', self.config.colorInputSelector, self.updateColorTextInput);

            $(document).on('click', '[rel="sort-levels"]', self.sortLevels);

            // update the system color picker if you change the text field manually.
            // update the background color of the text field to display the
            // contents of the field in visual form.
            self.$table.on('keyup change input paste', self.config.textInputSelector, self.updateTextInputBackground);

            Tss.Types.initAddEdit("GradingScale", "name", action, id, {
                extraApiParams: {
                    expand: 'grading_scale_levels'
                },
                submitCallback: self.storeGradingScaleLevels
            });
        },

        initList: function(opts) {
            var self = this;

            self.opts = opts;

            Tss.Types.initList("GradingScale", "name", false, {
                extraApiParams: {
                    expand: 'grading_scale_levels',
                },
                extraTemplateParams: {
                    defaultGradingScaleIds: self.opts.defaultGradingScaleIds,
                }
            });
        },

        handleToggleActiveClick: function(e) {
            var menuAction = $(this).closest('.menu-action');
            var row = $(this).closest('tr');
            var oldVal = row.find('[name="active"]').val();
            var newVal = oldVal == 1 ? 0 : 1; // toggle

            row.find('[name="active"]').val(newVal);
            row.find('input[type="text"],input[type="number"]').attr('disabled', newVal ? null : 'disabled');

            // otherwise you see it change right when you click the link and that's a weird UX
            setTimeout(function() {
                menuAction.find('i').toggleClass('icon-remove').toggleClass('icon-ok');
                menuAction.find('span').text(newVal ? 'Deactivate' : 'Activate');
            }, 100);
        },

        handleListKeyup: function(e) {
            // change this to: on keyup, delete all blank rows that don't exist that I'm not in
            var self = GradingScales;
            var input = $(this);
            var inputRow = input.closest('tr');
            var allRows = self.$table.find('tbody tr');
            var lastRowIsEmpty = self.rowIsEmpty(allRows.last());
            var lastRowExists = allRows.last().find('[name="grading_scale_level_id"]').val();
            var madeChanges = false;

            if (!lastRowIsEmpty || lastRowExists) {
                // if no empty rows, add one
                self.renderRow({active: 1});
                madeChanges = true;
            } else {
                // remove all blank rows that aren't already in the database
                // as long as I'm not currently typing in that row
                // and it's not the one last empty row we always want to have
                allRows.each(function(i) {
                    var row = $(this);
                    var rowIsEmpty = self.rowIsEmpty(row);
                    var rowExists = row.find('[name="grading_scale_level_id"]').val();
                    var inThisRow = inputRow.get(0) == row.get(0) && input.is(':focus');
                    var isLastRow = i == allRows.length - 1;

                    if (rowIsEmpty && !rowExists && !inThisRow && !isLastRow) {
                        row.remove();
                        madeChanges = true;
                    }
                });
            }

            if (madeChanges) {
                self.$table.trigger("applyWidgets");
                self.reColor();
            }
        },

        rowIsEmpty: function($tr) {
            var arr = $tr.find('input[type="text"],input[type="number"],input[name="grading_scale_level_id"]').map(function() { return _.trim($(this).val()); });

            return _.filter(arr, x => x !== "").length == 0;
        },

        reColor: function() {
            var self = GradingScales;

            // TODO: actually sort the rows?
            // use a value getter thing with the regular tablesorter?
            // update colors whenever someone changes a min_value input
            var sortedNonEmptyRows = self.getSortedNonEmptyRows();
            var numLevels = sortedNonEmptyRows.length;

            _.each(sortedNonEmptyRows, (row, i) => {
                var colorInput = $(row).find(self.config.colorInputSelector);
                var textInput = $(row).find(self.config.textInputSelector);
                var color = Functions.getBackgroundColorByIndex(i, numLevels, textInput.val(), false);

                textInput.data('default-color', color).change();
            });
        },

        getSortedNonEmptyRows: function() {
            var self = this;
            var allRows = self.$table.find('tbody tr').get();
            var sortedNonEmptyRows = _.chain(allRows)
                .filter(row => !self.rowIsEmpty($(row)))
                .sortBy(row => {
                    var minValue = $(row).find('[name="min_value"]').val();
                    return minValue == ''
                        ? 9999999999
                        : -parseFloat(minValue)
                })
                .value();

            return sortedNonEmptyRows;
        },

        sortLevels: function() {
            var self = GradingScales;
            var sortedNonEmptyRows = self.getSortedNonEmptyRows();
            var emptyRows = self.$table.find('tbody tr').not(sortedNonEmptyRows);

            $(sortedNonEmptyRows).each(function(index) {
                $(this).appendTo(self.$table);
            });
            $(emptyRows).each(function(index) {
                $(this).appendTo(self.$table);
            });
        },

        handleToggleShowInactiveClick: function () {
            var self = GradingScales;
            var $icon = $(this).find("i");
            var $span = $(this).find("span");

            self.showActiveOnly = !self.showActiveOnly;

            self.$table.find('tr.deactivated')[self.showActiveOnly ? 'hide' : 'show']();
            $icon.attr("class", "icon-eye-" + (self.showActiveOnly ? "open" : "close"));
            $span.text((self.showActiveOnly ? "Show" : "Hide") + " inactive");
        },

        fillInGradingScaleLevels: function(e, record) {
            var self = GradingScales;
            let gradingScaleLevels = _.chain(record)
                .get('grading_scale_levels')
                .sortBy(level => {
                    var minValue = level.min_value;
                    return minValue == ''
                        ? 9999999999
                        : -parseFloat(minValue);
                })
                .value();

            _.each(gradingScaleLevels.concat({active: 1}), function(attr) {
                self.renderRow(
                    attr,
                    self.action == 'duplicate'
                        ? ''
                        : attr.grading_scale_level_id
                );
            });

            self.$table.tablesorter( _.extend({ sortList: [], tabIndex: false }, Tss.Types.config.table));
        },

        renderRow: function(type, id) {
            let record = _.extend({}, type, {
                grading_scale_level_id: id, // override to blank on duplicate
            });
            let $row = $(Handlebars.renderTemplate('setup/configure/grading-scale-level-table-row', record));

            if (id) {
                $row.find(".options").tssDropdown({
                    content: Handlebars.renderTemplate('setup/configure/grading-scale-level-table-row-options', type),
                    icon: 'icon-cog',
                    right: true
                });
            }

            if (type.active != 1) {
                $row.find('input[type="text"]').attr('disabled', 'disabled');
                $row.hide();
            }

            this.$table.find("tbody").append($row);
        },

        storeGradingScaleLevels: async function(data) {
            var self = GradingScales;
            let gradingScale = _.get(data, 'results.grading_scale');
            let gradingScaleId = _.get(gradingScale, 'grading_scale_id');
            let isPct = _.get(gradingScale, 'is_pct') == 1;
            let successUrl = self.$form.data('referrer')
                || "/setup/configure/grading-scales";

            if (!gradingScaleId) {
                Growl.error({message: 'Save failed.'});
                return;
            }

            // convert this to edit instead of add in case the below validation
            // fails and we hit save again
            Tss.Types.config.action = 'edit';
            Tss.Types.config.id = gradingScaleId;
            Tss.Types.setSubmitHandler();

            let sortedNonEmptyRows = self.getSortedNonEmptyRows();
            var lastMinValue = null;
            var dupeLevels = {};
            var errString = '';
            let bulkData = _.filter($(sortedNonEmptyRows).map(function() {
                var row = $(this);
                var data = {};

                row.find('input').map(function() {
                    data[$(this).attr('name')] = $(this).val();
                });

                if (data.active != '1' && !data.grading_scale_level_id) {
                    return null; // ignore blank rows that don't already exist in the db
                }

                data.grading_scale_id = gradingScaleId;

                // normal case: A >= 90, so max value for B = 89.49
                // which gives the student the largest value that will
                // still round down when displayed as an integer.
                // weird case: A >= 90.2, B >= 90 so make sure max value
                // for B isn't less than min value because that's weird
                // so just make the max value equal to the min value.
                data.max_value = isPct && lastMinValue !== null
                    ? Math.max(parseInt(lastMinValue) - .51, data.min_value)
                    : null;

                // catch duplicate names or abbreviations
                let name = data.name || '';
                let upperCaseName = name.toUpperCase();
                if (upperCaseName in dupeLevels) {
                    errString += `Level names and abbreviations must be unique! Duplicate level name '${upperCaseName}'. `;
                }
                
                let abbreviation = data.abbreviation;
                if (abbreviation) {
                    let upperCaseAbbreviation = abbreviation.toUpperCase();
                    if (upperCaseAbbreviation in dupeLevels) {
                        errString += `Level names and abbreviations must be unique! Duplicate level abbreviation '${upperCaseAbbreviation}'. `;
                    }
                    dupeLevels[upperCaseAbbreviation] = true;
                }
                dupeLevels[upperCaseName] = true; // do this after so a level with name == abbreviation doesn't cause an error

                let lastMinValueIsBlank = lastMinValue === null
                    || lastMinValue === '';
                let minValueIsBlank = data.min_value === null
                    || data.min_value === '';
                if (lastMinValueIsBlank && minValueIsBlank) {
                    errString += "Only one level can have a blank min. value! ";
                }

                lastMinValue = data.min_value;

                return data;
            }));

            if (errString) {
                Growl.clearAll();
                Growl.error({
                    message: errString,
                    duration: 0,
                });
            } else if (bulkData.length) {
                const url = '/api/v1/bulk/grading-scale-levels';
                const params = {
                    bulk_data: bulkData,
                };
                const responses = await Api.put(url, params);
                const errorMessage = _.chain(responses)
                    .map(x => _.values(x.errors))
                    .flatten()
                    .uniq()
                    .value()
                    .join('<br/>');

                if (!errorMessage) {
                    window.location.assign(successUrl);
                } else {
                    Growl.error({ message: errorMessage });
                }
            }
        },

        showColorPicker: function() {
            var self = GradingScales;
            var textInput = $(this);
            var colorInput = textInput.closest('tr').find(self.config.colorInputSelector);

            colorInput.click();
        },

        updateColorTextInput: function() {
            var self = GradingScales;
            var colorInput = $(this);
            var textInput = colorInput.closest('tr').find(self.config.textInputSelector);

            textInput.val(colorInput.val()).change();
        },

        updateTextInputBackground: function() {
            var self = GradingScales;
            var textInput = $(this);
            var colorInput = textInput.closest('tr').find(self.config.colorInputSelector);
            var val = textInput.val();
            var color = val || textInput.data('default-color') || '#ffffff';

            colorInput.val(color);
            textInput.attr('style', `background: ${color} !important`);
        },
    };

    window.Tss = window.Tss || {};
    window.Tss.GradingScales = GradingScales;

})(jQuery, window);

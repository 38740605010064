(function($, window) { "use strict";
    var Users = {

        canReplaceIds: 0,

        initAddEditUser: function(action, id, opts) {
            var self = this;
            var defaultOpts = {
                extraApiParams: {
                    expand: 'staff_member,user_group_memberships,allowed_schools',
                }
            };

            Tss.Types.initAddEdit('User', 'email', action, id, _.merge(defaultOpts, opts));

            // default allowed_schools to the current session school
            if (action == 'add') {
                $('[name^="allowed_school_ids"]').val(opts.schoolId).change();
            }

            Tss.Types.$form.on('FillInForm', self.fillInFormUser);
        },

        fillInFormUser: function(e, record) {
            var staffMemberId = _.get(record, 'staff_member.staff_member_id');
            var userGroupIds = _.chain(record)
                .get('user_group_memberships')
                .filter(x => x.active == 1)
                .map('user_group_id')
                .value();
            var allowedSchoolIds = _.chain(record)
                .get('allowed_schools')
                .filter(x => x.active == 1)
                .map('school_id')
                .value();

            $('[name="staff_member_id"]').val(staffMemberId).change();
            $('[name^="user_group_ids"]').val(userGroupIds).change();
            $('[name^="allowed_school_ids"]').val(allowedSchoolIds).change();
        },

        initAddEditUserGroup: function(action, id, opts) {
            var self = this;
            var defaultOpts = {
                extraApiParams: {
                    expand: 'user_group_memberships,permissions',
                }
            };

            Tss.Types.initAddEdit('UserGroup', 'name', action, id, _.merge(defaultOpts, opts));
            Tss.Types.$form.on('FillInForm', self.fillInFormUserGroup);
        },

        fillInFormUserGroup: function(e, record) {
            var userIds = _.chain(record)
                .get('user_group_memberships')
                .filter(x => x.active == 1)
                .map('user_id')
                .value();
            var permissionTypeIds = _.chain(record)
                .get('permissions')
                .filter(x => x.active == 1)
                .map('permission_type_id')
                .value();

            $('[name^="user_ids"]').val(userIds).change();
            $('[name^="permission_type_ids"]').val(permissionTypeIds).change();
        },

        initListUser: function(opts) {
            var self = this;
            var dropZoneTemplate = 'drop-zone';
            var dropZoneElem = $(Handlebars.renderTemplate(dropZoneTemplate))
                .attr('template', dropZoneTemplate);
            var mainContent = $('.mainBody section');
            var dndTarget = dropZoneElem.find('.dndtarget')[0];
            var defaultOpts = {
                extraApiParams: {
                    staff_only: 1,
                    expand: 'user_group_memberships.user_group,allowed_schools.school,permissions.permission_type',
                    school_ids: null,
                },
                templates: {
                    emptyState: dropZoneTemplate,
                },
            };

            if (!opts.multiSchool) {
                defaultOpts.extraApiParams['allowed_school_ids'] = opts.allowedSchoolIds;
            }

            // if somebody drags a file before toggling the drop zone visible,
            // just show it for them
            $('body').get(0).addEventListener('dragover', function() {
                dropZoneElem.show();
            });

            dndTarget.addEventListener('dragover', fileDragHover, false);
            dndTarget.addEventListener('dragleave', fileDragHover, false);
            dndTarget.addEventListener('drop', fileSelectHandler(_.bind(self.handleData, self)), false);
            mainContent.first().before(dropZoneElem.hide());

            Tss.Types.initList('User', 'email', false, _.merge(defaultOpts, opts));

            $('body').on('click', '.download-template', self.downloadTemplate);

            let warningPopup = $('#upload_csv_locked_warning_popup');
            if (!(warningPopup.length == 0)) {
                warningPopup.hide();
                $('body').on('click', '.btn-warning-upload', _.bind(self.handleWarnigClick, self));
            }

            $('#toggle-show-empty-state').hide().click(() => dropZoneElem.toggle());
            Tss.Types.$table.on('TableRendered', () => $('#toggle-show-empty-state').show());

            self.modal = new Tss.Modal({
                launchTarget: '.launch-bulk-activate-users',
                template: 'bulk-activate-users-modal',
                getData: () => opts,
                go: self.bulkActivateUsers,
            });

            Tss.Types.$table.on('click', '.menu-action.password-reset', self.sendPasswordResetEmail);

            self.asyncTaskManager = new AsyncTaskManager('Uploading Users');
        },

        handleWarnigClick: function(e){
            var self = this;
            let warningPopup = $('#upload_csv_locked_warning_popup');
            
            if (e.target.value == 'confirm') {
                self.canReplaceIds = true;
            }
            warningPopup.hide();
        },

        downloadTemplate: function(e) {
            var url = '/setup/export/staff-members/download';
            var extraParams = {
                user_ids: Tss.Types.$table.find('tbody tr').map(function() { return $(this).data('id'); }).get(),
                school_ids: Tss.Env.get('school_id'),
                file_name: 'users.csv',
            };

            if (Tss.Types.$activeFilter.val() == 1) {
                extraParams['active'] = 1;
            }

            return genericDownload(url, null, extraParams)(e);
        },

        handleData: async function(data, target) {
            var self = this;
            var idx = (moment().format('YYYYMMDDHHmmssSSS') + Math.random()).replace('0.', '_');
            var postData = {
                school_id: Tss.Env.get('school_id'),
                can_create_and_update: 1,
                can_replace_ids : self.canReplaceIds,
                import_data: JSON.stringify(data), // gets around php max_input_vars of 10000
                idx: idx,
            };
            var request = $.post('/import/data?expand=staff_member', postData); // expand for staff_member_attrs so we can get which user_id changed
            var callbacks = {
                progress: elem => self.checkProgress(idx, elem),
                done: response => self.importDataDone(response),
                error: console.log,
            };
            var task = new AsyncTask('Upload Users', request, callbacks);

            self.asyncTaskManager.add(task);
            self.canReplaceIds = 0;
        },

        checkProgress: async function(idx, elem) {
            var response = await Api.get('/import/data/ping?idx=' + idx);

            elem.trigger('update', response);
        },

        importDataDone: function(response) {
            var self = this;

            $('#growls').empty();

            if (response.success) {
                var usersAdded = self.getUsersAdded(response);
                var usersUpdated = self.getUsersUpdated(response);

                _.pull(usersUpdated, ...usersAdded);

                var message = "Success!"
                    + (usersAdded.length
                        ? ' ' + displayItems(usersAdded.length, 'new user') + ' added.'
                        : '')
                    + (usersUpdated.length
                        ? ' ' + displayItems(usersUpdated.length, 'user') + ' updated.'
                        : '')
                    + (!usersAdded.length && !usersUpdated.length
                        ? " No changes detected."
                        : '');
                Growl.success({ message, fixed: true });
                Tss.Types.getAll(); // reload all rows
            } else {
                var warningMsg = _.get(response, 'warnings').join('<br/>');
                var errorMsg = _.get(response, 'errors').join('<br/>');

                if (warningMsg) {
                    Growl.warning({ message: warningMsg, fixed: true });
                }
                
                if (errorMsg) {
                    Growl.error({ message: errorMsg, fixed: true });
                }

                if (warningMsg.includes('Errors for User--')){
                    $('#upload_csv_locked_warning_popup').show();
                }
            }
        },

        getUsersAdded: function(response) {
            return _.chain(response)
                .get('meta.changes.users')
                .filter(x => _.get(x, 'change_logs[0].type') == 0)
                .map(x => parseInt(x.user_id))
                .value();
        },

        getUsersUpdated: function(response) {
            return _.chain(response)
                .get('meta.changes')
                .map(x => _.values(x)) // map/reduce turns nested data structure into one array of change_logs
                .reduce((result, value) => _.concat(result, value), [])
                .map(x => parseInt(x.user_id) || parseInt(x.staff_member.user_id)) // second case is for staff_member_attr.staff_member.user_id
                .filter()
                .uniq()
                .value();
        },

        initListUserGroup: function(opts) {
            var defaultOpts = {
                extraApiParams: {
                    expand: 'user_group_memberships.user,permissions.permission_type',
                },
            };

            Tss.Types.initList('UserGroup', 'email', false, _.merge(defaultOpts, opts));
        },

        bulkActivateUsers: function(e) {
            var form = $(e.target).closest('form');

            $.post('/bulk/create/users/', getFormData(form))
                .success(() => window.location.reload());
        },

        sendPasswordResetEmail: function(e) {
            var self = Users;
            var icon = $(this);
            var username = icon.data("username");

            e.preventDefault();

            $.post('/password/reset/attempt', {username})
                .success(self.handlePasswordResetSuccess)
                .fail(self.handlePasswordResetErrors);
        },

        handlePasswordResetSuccess: function(data) {
            console.log(data);
            if (!data.success) {
                self.handlePasswordResetErrors(data);
                return;
            }

            Growl.success({message: "Password reset email sent!", duration: 3000});
        },

        handlePasswordResetErrors: function(data) {
            var message = 'Failed to send password reset email: ' + _.get(data, 'errors.0');

            Growl.error({message, duration: 3000});
        },
    };

    window.Tss = window.Tss || {};
    window.Tss.Users = Users;

})(jQuery, window);

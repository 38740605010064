var Settings = {
    $currentDashboardTemplateOriginContainer: null,
    form: null,
    wrapper: null,
    saveApiEndpoint: '/setup/settings/save',
    fileUploadApiEndpoint: '/file/upload/',
    startingData: '',
    imagePlaceholder: '/dist/settings-no-image.jpg',

    errors: {
        dupVal: 'You can only have one rule with a given value'
    },

    stringifyData: function() {
        return Settings.form.jsonSerialize();
    },

    snapShotData: function() {
        this.startingData = this.stringifyData();
    },

    setBindings: function() {
        var self = this;

        //rel edit-dashboard-columns click
        $(document).on('click', '[rel="edit-dashboard-columns"]', function(e) {
            e.preventDefault();

            self.renderAndAppendDashboardEditTemplate(this);

            //show overlay curtain
            $('#overlay-curtain').removeClass('hide');
        });

        //.edit-dashboard-template .btn[rel="add-new-rule"] click
        $(document).on('click', '.edit-dashboard-template .btn[rel="add-new-rule"]', function(e) {
            e.preventDefault();

            self.addNewDashboardRule();
        });

        //.edit-dashboard-template .btn[rel="edit-rule"]
        $(document).on('change', '.edit-dashboard-template .form.edit', function(e) {
            e.preventDefault();

            self.modifyDashboardRule(e, $(this), 'edit');
        });

        //.edit-dashboard-template .btn[rel="delete-rule"]
        $(document).on('click', '.edit-dashboard-template .btn[rel="delete-rule"]', function(e) {
            e.preventDefault();

            if(window.confirm('Are you sure?')) {
                self.modifyDashboardRule(e, $(this), 'delete');
            }
        });

        //rel save-app-settings
        $(document).on('click', '#view-actions .btn[rel="save-app-settings"]', function(e) {
            e.preventDefault();

            self.initSaveSettings();
        });

        //rel setting-group-accordion
        $(document).on('click', '#app-settings div[rel="setting-group-accordion"]', function(e) {
            e.preventDefault();

            // Settings.showHideSettingGroups(this);
        });

        //save hotkey
        $(document).on('keydown', null, getSimpleShortcutLabelPrefix() + 's', function(e) {
            e.preventDefault();

            self.initSaveSettings();
        });

        //image upload
        $('div.settings-image-upload').find('.btn.edit').on('click', function(e) {
            e.preventDefault();

            //trigger click on file input element
            $(this).closest('div.settings-image-upload').find('input[type="file"]').trigger('click');
        });

        //file uploader change
        $(document).on('change', '#app-settings div.settings-image-upload input[type="file"]', function(e) {
            self.previewImageUpload(this);
        });

        //image remove
        $(document).on('click', '#app-settings div.settings-image-upload .btn.remove', function(e) {
            e.preventDefault();

            if(confirm('Are you sure you want to remove this image?')) {
                self.removeImage(this);
            }
        });

        //flag form as dirty when there are changes
        self.form.on('change', function() {
            $(this).addClass('dirty');
        });

        //if form has dirty flag on before unload, show alert
        window.onbeforeunload = function() {
            if (self.form.hasClass('dirty')) {
                return "It looks like you have unsaved edits!";
            }
        };
    },

    renderAndAppendDashboardEditTemplate: function(element, error) {
        //remove all edit dashbaord templates
        this.removeAllDashboardEditTemplates();

        //get container
        var $container;
        if (element) {
            Settings.$currentDashboardTemplateOriginContainer = $container = $(element).closest('.col');
        } else {
            $container = Settings.$currentDashboardTemplateOriginContainer;
        }

        //get coords and data
        var coords = $container.parent().offset(),
            contInputVal = $container.find('input').val() || null,
            data = $.parseJSON(contInputVal) || {};

        //set global operator (min vs max)
        data.globalOperator = $container.data('meta');

        //add coordinates and width to the data
        data.coordinates = {top: String(coords['top'] + 70) + 'px', left: '25%'};
        data.width = '50%';

        //add setting title
        data.settingTitle = $container.data('title');

        //set error if applicable
        if (error) {
            data.error = error;
        }
        //render and append template
        $('body').append(Handlebars.renderTemplate('edit-dashboard-columns', data));

        //make tss select
        $('.edit-dashboard-template select.make-tss-select').tssSelect();
    },

    renderAndAppendDashboardColumnsLegend: function(element) {
        //get container
        var $container;
        if (element) {
            Settings.$currentDashboardTemplateOriginContainer = $container = $(element).closest('div');
        } else {
            $container = Settings.$currentDashboardTemplateOriginContainer;
        }

        //get data
        var input = $container.find('input'),
            data = $.parseJSON(input.val()) || {};

        data.meta = input.data('meta');

        //remove current legend
        $container.find('.dashboard-columns-legend').remove();

        //render and append template
        $container.find('input[type="hidden"]').before(Handlebars.renderTemplate('dashboard-columns-legend', data));
    },

    removeAllDashboardEditTemplates: function() {
        $('.edit-dashboard-template').remove();
    },

    getChangedValues: function() {
        //get current data
        var currentData = this.stringifyData(),
            changes = {};

        //if the strinigified version are the same, return empty object
        if (currentData == this.startingData) {
            return false;
        }

        //parse json to compare objects
        var currentDataObj = $.parseJSON(currentData),
            startingDataObj = $.parseJSON(this.startingData);

        //iterate
        for(var prop in startingDataObj) {
            //since we're comparing a number of data types, we'll stringify them first before we compare
            if (JSON.stringify(currentDataObj[prop]) !== JSON.stringify(startingDataObj[prop])) {
                changes[prop] = currentDataObj[prop];

                //join to comma delimited string if is an array
                if (typeof currentDataObj[prop] === 'object') {
                    changes[prop] = currentDataObj[prop] ? currentDataObj[prop].join() : '';
                }
            }
        }
        return changes.length !== 0 ? changes : false;
    },

    addNewDashboardRule: function() {
        //get current setting value
        var $currentSettingInput = Settings.$currentDashboardTemplateOriginContainer.find('input[type="hidden"]');
        
        // only grab a current value if there is one
        var currentSettingValue = null;
        if( $currentSettingInput.val() ) {
            currentSettingValue = $.parseJSON($currentSettingInput.val());
        }

        //get new values
        var $inputs = $('.edit-dashboard-template .form.add-new').find('input, select'),
            ruleExists = this.ruleExists($inputs, currentSettingValue),
            values = this.getDashboardRuleValues($inputs, currentSettingValue);
        if (!ruleExists && values) {
            //get global operator
            var globalOperator = Settings.$currentDashboardTemplateOriginContainer.data('meta');

            //set
            if (currentSettingValue) {
                if (currentSettingValue.rag) {
                    currentSettingValue.rag.push(values);
                } else {
                    currentSettingValue.rag = [values];
                }
            } else {
                currentSettingValue = {
                    rag: [values]
                };
            }

            //sort
            currentSettingValue.rag = this.sortDashboardData(currentSettingValue.rag, globalOperator);

            //save
            $currentSettingInput.val(JSON.stringify(currentSettingValue)).trigger('change');

            //render
            this.renderAndAppendDashboardEditTemplate();
            this.renderAndAppendDashboardColumnsLegend();
        } else {
            this.renderAndAppendDashboardEditTemplate(null, ruleExists);
        }
    },

    ruleExists: function($inputs, currentSettingValue) {
        var value = parseFloat($inputs.filter('[name="value"]').val()),
            returnValue = false;

        if(currentSettingValue && currentSettingValue.rag) {
            var rag = currentSettingValue.rag,
                matchedValue = _.find(rag, function(r) {
                    var min = parseFloat(r.min),
                        max = parseFloat(r.max);

                    console.log(value, min, max);

                    return (!_.isNaN(min) && min === value)
                            || (!_.isNaN(max) && max === value)
                            || (_.isNaN(min) && _.isNaN(max) && _.isNaN(value));
                }),
            returnValue = matchedValue ? this.errors.dupVal : false;
        }

        return returnValue;
    },

    getDashboardRuleValues: function($inputs) {
        //make sure we have inputs to traverse
        if (!$inputs || $inputs.length === 0) {
            return null;
        }

        //set vars
        var data = {},
            operator;

        //iterate through inputs
        $inputs.each(function(i, input) {
            input = $(input);

            //get input name and value
            var name = input.attr('name'),
                value = input.val();

            //class - set propery c
            if (name === 'class') {
                data.c = value;
            }
            //operator - create new object property min or max
            else if (name === 'operator') {
                operator = value;
                data[operator] = '';
            }
            //value - set operator property from above with value
            //if empty, remove operator property or return false if already an empty value present
            else if (name === 'value') {
                if (value.length !== 0) {
                    data[operator] = value;
                } else {
                    delete data[operator];
                }
            }
        });

        return data;
    },

    modifyDashboardRule: function(e, element, action) {
        //get index
        var index = element.closest('.form').data('index'),
            currentSettingInput = Settings.$currentDashboardTemplateOriginContainer.find('input[type="hidden"]'),
            currentSettingValue = $.parseJSON(currentSettingInput.val()),
            globalOperator = Settings.$currentDashboardTemplateOriginContainer.data('meta'),
            isSelect = $(e.target).is('select'),
            ruleExists = null;

        //make sure that index exists in the array
        if (!currentSettingValue || !currentSettingValue.rag || !currentSettingValue.rag[index]) {
            return false;
        }
        //delete
        else if (action == 'delete') {
            //set
            currentSettingValue.rag = Functions.removeArrayItemByIndex(index, currentSettingValue.rag);
        }
        //edit
        else if (action == 'edit') {
            //is there already a rule with this value?
            //get values
            var inputs = element.closest('.form')   .find('input, select'),
                ruleExists = isSelect ? false : this.ruleExists(inputs, currentSettingValue),
                values = this.getDashboardRuleValues(inputs, currentSettingValue);
            if (!ruleExists && values) {
                //set
                currentSettingValue.rag[index] = values;
            }
        }

        //sort, save and render
        currentSettingValue.rag = this.sortDashboardData(currentSettingValue.rag, globalOperator);
        currentSettingInput.val(JSON.stringify(currentSettingValue)).trigger('change');
        this.renderAndAppendDashboardEditTemplate();
        this.renderAndAppendDashboardColumnsLegend();
    },

    sortDashboardData: function(ragArray, operator) {
        if (!(operator == 'min' || operator == 'max')) return ragArray;

        var dir = operator == 'min' ? -1 : 1;
        var sortFunc = function(a, b) {
            var aVal = parseFloat(a[operator]);
            var bVal = parseFloat(b[operator]);

            // always sort the blank one to the end
            if (_.isNaN(aVal)) return 1;
            if (_.isNaN(bVal)) return -1;

            // normal ascending sort. gets flipped by dir for descending.
            return aVal == bVal ? 0 : dir * (aVal < bVal ? -1 : 1);
        };

        return ragArray.sort(sortFunc);
    },

    showHideSettingGroups: function(element) {
        //get clicked group name
        var selectedGroup = $(element).find('h5').text();

        //hide other groups
        this.form.find('div.group-header').each(function(){
            var group = $(this).find('h5').text();
            if (group !== selectedGroup) {
                Settings.form.find('div.row[data-group="'+ group +'"]').addClass('hide');
            }
        });

        //get elements to work on
        var $elements = this.form.find('div.row[data-group="'+ selectedGroup +'"]');

        //if no elements, return
        if ($elements.length === 0) {
            return;
        }

        //show if hidden
        if ($elements.first().hasClass('hide')) {
            $elements.removeClass('hide');
        }
        //hide if visible
        else {
            $elements.addClass('hide');
        }
    },

    scrollTo: function(settingKey) {
        // make sure we do this after the browser tries to return you to your previous scroll position
        $(window).load(function() {
            var settingElem = $(`[name$="::${settingKey}"]:first`);

            if (!settingElem.length) {
                return;
            }

            // expand the setting group accordion
            var settingGroup = settingElem.closest('.is-tss-expandable');
            if (settingGroup.hasClass('closed')) {
                settingGroup.removeClass('closed');
            }

            // set scroll so that the desired setting is at the top of the page
            // do it inside a setTimeout so we have time for the accordion to expand and the children to become visible
            setTimeout(() => {
                window.scroll(0, window.scrollY + settingElem.closest('.row').get(0).getBoundingClientRect().top);
            }, 10);
        })
    },

    previewImageUpload: function(el) {
        if (el.files && el.files[0]) {
            var filename = el.files[0].name,
                reader = new FileReader(),
                $target = $(el).siblings('img'),
                hiddenField = $(el).siblings('input:hidden');

            if(!Functions.isImage(filename)) {
                return;
            }

            this.postFileUpload(el.files[0], hiddenField);

            reader.onload = function (e) {
                $target.attr('src', e.target.result);
            };

            reader.readAsDataURL(el.files[0]);
        }
    },


    /*
     * Saving
     */
    initSaveSettings: function() {
        //get changed values
        var changes = Settings.getChangedValues();

        //if changes found
        if (changes) {
            //get data in correct format
            var data = {
                changes: changes
            }

            //send changes
            this.sendChanges(data);
        }
    },

    sendChanges: function(data, callback) {
        $.ajax({
            type: 'POST',
            url: this.saveApiEndpoint,
            data: data,
            success: function(data) {
                //remove dirty flag on form
                Settings.form.removeClass('dirty');

                //notifications
                displayNotifications(data, null, 4000);

                //remove edit dashboard interface and hide overlay curtain
                Settings.removeAllDashboardEditTemplates();
                $('#overlay-curtain').addClass('hide');

                if (!data.errors || data.errors.length === 0) {
                    //store serialized data for comparison later
                    Settings.snapShotData();

                    //redirect if needed
                    if(data.results && data.results.url) {
                        window.location = data.results.url;
                    }
                }
            }
        });
    },

    postFileUpload: function(file, el) {
        var form_data = new FormData();
        form_data.append('files', file);
        form_data.append('subdir', 'uploads/settings');
        form_data.append('web_accessable', true);

        $.ajax({
            type: 'POST',
            url: this.fileUploadApiEndpoint,
            data: form_data,
            processData: false,
            contentType: false,
            success: function(data) {
                if(data.success) {
                    if(data.results && data.results.url) {
                        el.val(data.results.url).trigger('change');
                    }
                }
            },
            error: function() {
                alert('File Upload Failed. Please try again.');
            }
        });
    },

    removeImage: function(el) {
        el = $(el);

        var container = el.closest('div.settings-image-upload'),
            img = container.find('img'),
            input = container.find('input[type="hidden"]');

        img.attr('src', this.imagePlaceholder);
        input.val('').trigger('change');
    }
};

window.Settings = Settings;
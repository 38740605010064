/**
 * @author ccoglian
 */
(function($) {
    $.fn.tssToggleVisible = function(passedOptions) {
        var defaultOptions = {
            max: 3,
        };
        var baseOptions = $.extend({}, defaultOptions, passedOptions || {});

        return this.each(function() {
            var $parent = $(this);
            var options = $.extend({}, baseOptions, $parent.data());

            // make sure the plugin only gets applied once
            if ($parent.hasClass('is-tss-toggle-visible')) { return; }
            $parent.addClass('is-tss-toggle-visible');

            var max = options.max;
            var children = $parent.children();
            var numChildren = children.length;
            if (numChildren > max + 1) {
                var num = numChildren - max;

                $(children.get().slice(max)).addClass('toggle-contents').hide();
                $parent.append(`<div><a href="#" rel="toggle-visible" data-toggle-text="hide">... and ${num} more</a></div>`);
            }            
        });
    };
}(jQuery));
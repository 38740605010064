(function ($, window, undefined) {
    "use strict";

    var ReportCards = {
        postEndpoint: '/academics/report-cards/comments/save',
        hideMessageTimeout: 5000,
        saveEvents: 'keydown cut paste input',
        savingMessage: 'Saving comment...',
        savedMessage: 'Comment saved',
        errorMessage: 'Could not save - try again',
        saveDeboundInterval: 1000,
        saveMessageHideTimeout: 4000,
        publishModal: null,
        publishedReportCards: null,
        allStudentIdsInView: null,
        studentIdsToPublish: null,
        studentIdsToGenerate: null,

        init: function (opts) {
            this.setBindings(opts);
            this.asyncTaskManager = new AsyncTaskManager('Generating Report Cards');
            this.$reportCardCommentsHeader = $('#report-cards .comments-header');
            this.initialCommentHeader = this.$reportCardCommentsHeader.html();
            this.$reportCardCommentsContainer = $('#report-card-comments');
            this.$reportCardCommentsContainer.html(Handlebars.renderTemplate('empty-state', {
                title: this.$reportCardCommentsContainer.data('empty-state-title'),
            }));
        },

        initComments: function (defaultComments) {
            this.defaultComments = defaultComments;
            this.setCommentBindings();
        },

        saveComment: function () {
            var self = ReportCards,
                $this = $(this),
                $container = $this.closest('.comment-wrapper');

            self.postComments({ comments: [self.getCommentData($container)] }, [$container]);
        },

        postComments: function (data, containers) {
            //show saving text
            ReportCards.setMessages(containers, ReportCards.savingMessage);

            return $.post(ReportCards.postEndpoint, data)
                .then(_.partial(ReportCards.postSuccess, containers))
                .then(setSaveOrErrorMessages)
                .fail(_.partial(ReportCards.setMessages, containers, ReportCards.errorMessage));

            function setSaveOrErrorMessages(data) {
                return ReportCards.setMessages(containers, data.errors.length ? ReportCards.errorMessage : ReportCards.savedMessage);
            }
        },

        postSuccess: function (containers, data) {
            _.each(containers, function (container) {
                var historyLink = $(container).find("[data-feature='comment-history']");

                // add force-refresh tooltip
                historyLink.attr('force-refresh', '');

                // update comment history href if it changes
                historyLink.attr('href', '/changelog/object/ReportCardComment/' + data.results[0].report_card_comment_id);
            });

            return data;
        },

        setMessages: function (containers, message) {
            _.each(containers, function (container) {
                container.find('.message').text(message);
            });

            setTimeout(function () {
                _.each(containers, function (container) {
                    $(this).find('.message').text('');
                });
            }, this.saveMessageHideTimeout);
        },

        fillDownComments: function (e) {
            var self = ReportCards,
                $this = $(this),
                $rows = self.$reportCardCommentsContainer.find('.comment-wrapper.row'),
                $row = $this.closest('.comment-wrapper.row'),
                rowIndex = $row.index(),
                val = $row.find('textarea').val(),
                data = [],
                containers = [], rowNum;

            e.preventDefault();
            for (rowNum = rowIndex + 1; rowNum < $rows.length; rowNum++) {
                var $input = $rows.eq(rowNum).find('textarea'),
                    $container = $input.closest('.comment-wrapper');

                $input.val(val);
                data.push(self.getCommentData($container));

                // Add container
                containers.push($container);
            }

            self.postComments({ comments: data }, containers);
        },

        getCommentData: function ($container) {
            return {
                comment: $container.find('textarea').val(),
                student_id: $container.data('student-id'),
                term_bin_id: $container.data('term-bin-id'),
                course_id: $container.data('course-id')
            };
        },

        filter: function (e) {
            var self = ReportCards;

            if (e && $(e.target).is('[type="hidden"]')) {
                return;
            }

            var filters = getFilters($('#report-card-filters')),
                filterState = getFilterState(filters),
                filterStr = filterState.join('&');

            $('#view-actions.outer').show();
            self.shouldEnablePublishButton(false);
            $('#report-card-comments')
                .html('')
                .tssLoad('/academics/report-cards/comments/get?tooltip=1&' + filterStr);
        },

        setBindings: function (opts) {
            var self = this;
            var template = 'publish-report-cards-modal';

            $('select[name*="student_id"]').bind('studentChange', self.filter);
            $('#report-card-filters').change(self.filter);
            $('[name="term_bin_name"]').change(self.shouldEnablePublishButton);
            $('[name="term_bin_name"]').change(self.setTermBinHeader.bind(self));
            $('#report-card-comments').on('loaded', self.shouldEnablePublishButton);
            self.shouldEnablePublishButton();

            self.publishModal = new Tss.Modal({
                template,
                launchTarget: '[rel="generate-report-cards"]',
                remove: true,
                getData: () => ({
                    settings: opts.settings,
                    permissions: opts.permissions,
                    formData: _.extend(
                        getFormData($('form[name="generate-report-cards"]')),
                        {
                            term_bin_id: $('select[name="term_bin_name"] :selected').data('term_bin_id'),
                            term_bin_name: $('input[name="term_bin_name"]:checked').val(),
                        }
                    ),
                    spinnerTemplateData: {
                        classes: 'mini',
                    },
                }),
                go: async function () {
                    var options = getFormData($('.' + template));
                    fetch(`/totango/generateReportCards/Academics`)

                    this.closeModal();

                    self.generateReportCardsClick(_.extend(options, {
                        'student_ids': self.studentIdsToGenerate,
                    }));
                },
            });
            // $('body').on('click', '[rel="publish-report-cards"]', this.publishReportCardsClick);
            $('#report-card-comments').bind('loaded', function () {
                $('#view-actions.outer')[$('#view-actions.inner:visible').length ? 'hide' : 'show']();
            });

            // Add report-card specific 'undo' callback for the report-card comments history
            UndoService.addClickHandler('ReportCardComment', self.onAfterUndo);
        },

        shouldEnablePublishButton: function (force) {
            var enablePublishButton = force !== false
                && $('input[name="term_bin_name"]:checked').length;

            if (enablePublishButton) {
                $('[rel="publish-report-cards"]')
                    .removeClass('disabled')
                    .removeAttr('disabled');
            } else {
                $('[rel="publish-report-cards"]')
                    .addClass('disabled')
                    .attr('disabled', 'disabled');
            }
        },

        // prepend the selected term bin short name to "Report Card Comments"
        setTermBinHeader: function (e) {
            let selectedTermBin = e.target.value;
            this.$reportCardCommentsHeader.html(`${selectedTermBin} ${this.initialCommentHeader}`);
        },

        onAfterUndo: function (data) {
            $('[data-student-id="' + data.student_id + '"] [data-feature="comment-history"]')
                .attr('force-refresh', '');
            ReportCards.filter(this);
        },

        setCommentBindings: function () {
            var self = ReportCards;

            // Add force-refresh to all comment history tooltips
            $('[data-feature="comment-history"]').attr('force-refresh', '');

            // Auto-save comment keyup
            self.$reportCardCommentsContainer
                .off(self.saveEvents)
                .on(
                    self.saveEvents,
                    '.comment-wrapper textarea',
                    _.debounce(self.saveComment, self.saveDeboundInterval)
                );

            // Fill down comments icon click
            $('[rel="fill-down-report-card-comments"]')
                .off('mousedown.fill-down-report-card-comments')
                .on('mousedown.fill-down-report-card-comments', this.fillDownComments);

            // Replace empty comments with default
            $('.comment-wrapper').not('[data-course-id]').keyup(function () {
                var $this = $(this),
                    $textarea = $this.find('textarea'),
                    text = $textarea.val();

                if (!text || !text.length) {
                    $textarea.val(self.defaultComments);
                }
            });

            $('.options[data-id]').each(function () {
                $(this).tssDropdown({
                    content: Handlebars.renderTemplate('publish-report-card-dropdown', $(this).data()),
                    icon: 'icon-cog',
                    right: true,
                });
            });

            // Deactivate published report card
            $('[rel="toggle-active-status"]')
                .off('click.toggle-active-status')
                .on('click.toggle-active-status', async function () {
                    const response = await self.toggleActiveStatus($(this));

                    if (response.success) {
                        $(this).closest('.published-report-card').remove();

                        Growl.success({ message: "Successfully un-published the report card" });
                    } else {
                        displayNotifications(response);
                    }
                });

            // Setup UI
            $('.comment-wrapper').find('textarea.autogrow').tssAutogrow();
            $('input[type="checkbox"]').tssCheckbox();
        },

        generateReportCardsClick: function (params) {
            var self = this;
            var form = $('form[name="generate-report-cards"]');
            var task = self.buildTask(form, params);

            self.asyncTaskManager.add(task);
        },

        buildTask: function (form, params) {
            var self = this;
            var uniqueId = _.uniqueId();
            var taskName = self.buildTaskName();
            var data = _.extend(getFormData(form), params);
            var isPublish = data.is_publish == 1;
            var taskDisplayName = (isPublish ? 'Publishing ' : '') + taskName;
            var url = form.attr('action');
            var request = $.ajax({
                type: 'POST',
                url: `${url}?report_card_file_name=${taskName}&idx=${uniqueId}`,
                dataType: "json",
                data,
            });
            var callbacks = {
                progress: _.partial(self.reportCardPing, uniqueId),
                done: _.partial(self.reportCardDownload, uniqueId, taskName, isPublish),
                error: self.reportCardError
            };

            self.asyncTaskManager.setName(isPublish
                ? 'Publishing Report Cards'
                : 'Generating Report Cards');

            return new AsyncTask(taskDisplayName, request, callbacks);
        },

        buildTaskName: function (isPublish) {
            var filters = {
                'group': $('select[name="student_group_id"] :selected').html(),
                'section': $('select[name="section_period_id"] :selected').html(),
                'course': $('select[name="course_id"] :selected').html(),
                'student': $('select[name="student_id"] :selected').html(),
            };

            // remove null values from filters object,
            // convert to an array,
            // and then get the first value
            return _.chain(filters)
                .pickBy(_.identity)
                .values()
                .first()
                .value();
        },

        reportCardError: function (jqXHR, error, errorMessage) {
            var customHeaderMessage = jqXHR.getResponseHeader('X-App-Custom-Status') || "";
            // Eliminate the first three characters from the message, which contain the status code.
            var clientFacingMessage = customHeaderMessage.substr(3).trim() || errorMessage;
            Growl.error({ message: clientFacingMessage, static: true });
        },

        reportCardPing: function (uniqueId, elem) {
            return $.get('/academics/report-cards/ping?idx=' + uniqueId)
                .done(_.bind(elem.trigger, elem, 'update'));
        },

        reportCardDownload: function (uniqueId, fileName, isPublish) {
            var self = ReportCards;
            var iframe = $('<iframe class="hidden"></iframe>');

            iframe.appendTo('body')
                .contents()
                .find('html')
                .html('<form action="/academics/report-cards/download"> \
                        <input type="hidden" name="report_card_file_name" value="' + fileName + '" /> \
                        <input type="hidden" name="idx" value="' + uniqueId + '" /> \
                    </form>');

            iframe.contents().find('form').submit();
            setTimeout(function () {
                iframe.remove();
            }, 300000);

            if (isPublish) {
                Growl.success({ message: "Report cards published!", duration: 0 });
                self.filter();
            }
        },

        initStudentList: function (opts) {
            let loadingState = $(Handlebars.renderTemplate('spinner'))
                .attr('template', 'custom-spinner');
            let emptyStateArgs = {
                title: 'No report cards found',
            };
            let emptyState = $(Handlebars.renderTemplate('empty-state', emptyStateArgs))
                .attr('template', 'custom-empty-state');
            $('[name="report_card_student"]')
                .before(loadingState)
                .before(emptyState.hide());
            Tss.Types.initList("PublishedReportCard", null, false, _.extend(opts, {
                templates: {
                    loadingState: 'custom-spinner',
                    emptyState: 'custom-empty-state',
                },
                extraApiParams: {
                    active: opts.active,
                    status: opts.status,
                    student_ids: opts.student_id,
                    expand: 'term_bin.term',
                    transfer: opts.transfer
                },
                extraTemplateParams: {
                    permissions: opts.permissions,
                    loggedInStudent: opts.loggedInStudent,
                },
                table: {
                    sortList: [[0, 1]], // sort in descending term_bin order
                },
            }));
            Tss.Types.$table.on("click", "[rel=\"toggle-publish-status\"]", async function () {
                const self = Tss.Types;
                const response = await Tss.ReportCards.toggleActiveStatus(
                    $(this),
                    self.config.extraApiParams.expand
                );

                if (response.results) {
                    let type = response.results[self.config.type.underscored];
                    let id = type[self.config.type.underscored + "_id"];

                    // Render row
                    self.renderRow(type, id);

                    // Add alert
                    Growl.success({ message: "Successfully " + (type.active == 1 ? "published" : "un-published") + " the report card" });

                    self.$table
                        .each(setupUI)
                        .trigger('update')
                        .trigger('SingleRowRendered', response);
                } else {
                    displayNotifications(response);
                }
            });
        },

        toggleActiveStatus: async function ($elem, expand) {
            const id = $elem.data('id');
            const active = $elem.data('active');
            const data = {
                active: active == 0
                    ? 1
                    : 0,
                published_date: active == 0
                    ? moment().format('YYYY-MM-DD HH:mm:ss')
                    : null,
            };
            const urlParams = expand
                ? `?expand=${expand}`
                : '';

            return await Api.put(`/api/v1/published-report-cards/${id}${urlParams}`, data);
        },

        initPublish: async function (formData, permissions) {
            var self = this;
            let modalForm = $('[name="publish_report_cards"]');
            let studentIds = formData.student_ids;
            // this will get updated in updatePublishStats but only if you have publish perms
            self.studentIdsToGenerate = studentIds;

            if (permissions.publish_report_cards) {
                let response;

                if (!studentIds) {
                    const schoolId = Tss.Env.get('school_id');
                    response = await Api.get(`/api/v1/students?active=1&school_ids=${schoolId}`);
                    const students = _.get(response, 'results.students');
                    studentIds = _.map(students, 'student_id');
                }

                response = await Api.get(`/api/v1/published-report-cards?active=1&term_bin_ids=${formData.term_bin_id}`);
                self.publishedReportCards = _.chain(response)
                    .get('results.published_report_cards')
                    .filter(result => studentIds.contains(result.student_id))
                    .value();

                let alreadyPublishStudentIds = _.map(self.publishedReportCards, 'student_id');
                self.allStudentIdsInView = studentIds;
                self.studentIdsToPublish = _.filter(studentIds, studentId => !alreadyPublishStudentIds.contains(studentId));

                if (self.publishedReportCards.length) {
                    let numStudentsDisplay = displayItems(self.publishedReportCards.length, 'student');
                    let alertOptions = {
                        type: 'warning',
                        className: 'growl',
                        message: `Report cards have already been published for
                            ${numStudentsDisplay}.
                            If you'd like to replace the currently published version
                            with a new one you must un-publish the existing report
                            cards first.`,
                    };

                    modalForm.find('.published-report-cards-alert')
                        .html(Handlebars.renderTemplate('alert', alertOptions));
                    modalForm.find('button.unpublish')
                        .click(_.bind(self.unpublish, self));
                }

                self.updatePublishStats();

                $('[name="is_publish"]').change(function () {
                    self.updatePublishStats();

                    if ($(this).is(':checked')) {
                        $('[name="send_notifications"]').removeAttr('disabled');
                        $('[name="send_notifications"]').prevAll('.tss-checkbox').last().removeAttr('disabled');
                        $('[name="send_notifications"]').prevAll('.tss-checkbox-label').last().removeAttr('disabled');
                    } else {
                        $('[name="send_notifications"]').attr('disabled', 'disabled');
                        $('[name="send_notifications"]').prevAll('.tss-checkbox').last().attr('disabled', 'disabled');
                        $('[name="send_notifications"]').prevAll('.tss-checkbox-label').last().attr('disabled', 'disabled');
                    }
                })
            }

            modalForm.find('.loading-state').hide();
            modalForm.find('#publish_options').show();
        },

        unpublish: async function () {
            var self = this;
            let modalForm = $('[name="publish_report_cards"]');

            modalForm.find('button.unpublish')
                .addClass('disabled')
                .attr('disabled', 'disabled');
            modalForm.find('button.unpublish i').toggle();

            await Api.post('/api/v1/bulk/published-report-cards', {
                bulk_data: self.publishedReportCards.map(record => _.extend(record, { active: 0 })),
            });

            modalForm.find('button.unpublish i').toggle();
            modalForm.find('.published-report-cards-alert').hide();

            self.publishedReportCards = [];
            self.studentIdsToPublish = self.allStudentIdsInView;
            self.updatePublishStats();
            self.filter();
            self.publishModal.closeModal();
        },

        updatePublishStats: function () {
            var self = this;
            let modalForm = $('[name="publish_report_cards"]');
            const isPublish = $('[name="is_publish"]').is(':checked');

            self.studentIdsToGenerate = isPublish
                ? self.studentIdsToPublish
                : self.allStudentIdsInView;

            modalForm.find('.num-students-to-generate')
                .html(`(${self.studentIdsToGenerate.length})`);

            modalForm.find('.num-students-to-unpublish')
                .html(`(${self.publishedReportCards.length})`);

            modalForm.find('button.publish .button-text').html(isPublish
                ? 'Publish'
                : 'Generate'
            );

            if (self.studentIdsToGenerate.length) {
                modalForm.find('button.publish')
                    .removeClass('disabled')
                    .removeAttr('disabled');
            } else {
                modalForm.find('button.publish')
                    .addClass('disabled')
                    .attr('disabled', 'disabled');
            }

            if (self.publishedReportCards.length) {
                modalForm.find('button.unpublish')
                    .removeClass('disabled')
                    .removeAttr('disabled');
            }
        },
    };

    window.Tss = window.Tss || {};
    window.Tss.ReportCards = ReportCards;

})(jQuery, window);
;(function ($, window, document, undefined) {

    function gaDataLayer(options) {

        var defaults = {
            dataAttrs: [
                'vertical',
                'module',
                'feature',
                'identifier'
            ],
        };

        /**
        * Set up all events and handlers for this view.
        */
        function initBindings() {
            $(document).ready(clearDataLayer);
            $(document).on('click gaEvent', updateDataLayer);
        };

        /**
         * reset these data layer variables so that tags (clicks, pageview)
         * are not tied to incorrect information
         */
        function clearDataLayer() {
            var dataLayerObj = {'event': 'clearDataAttrs'};
            options.dataAttrs.forEach(function (attr) {
                dataLayerObj[attr] = undefined;
            });
            dataLayer.push(dataLayerObj);
        }

        /**
         * find the closest parent element that has the given attribute set
         * and return the value of that attribute
         */
        function findClosestAttr(el, attr) {
            return $(el).parents().filter(function() {
                return $(this).data(attr);
            }).eq(0).data(attr);
        }

        /**
        * Push to dataLayer and send to GA for tag firing
        * undefined needed to clear DL variables on all clicks that aren't set up to send values
        * otherwise, all events (clicks, pageviews) will be sent with the most recent DL values
        */
        function updateDataLayer(e) {
            var target = $(e.target);
            var targetDataset = target.data();
            var dataLayerObj = {'event' : 'pushDataAttrs'};
            options.dataAttrs.forEach(function (attr) {
                dataLayerObj[attr] = targetDataset[attr] || findClosestAttr(target, attr);
            })
            dataLayer.push(dataLayerObj);
        }

        options = $.extend({}, defaults, options);

        initBindings();
    };

    window.Tss = window.Tss || {};
    window.Tss.gaDataLayer = gaDataLayer;
})(jQuery, window, document);

(function ($, window) {
    "use strict";
    var Students = {
        studentAttrsApi: '/api/v1/student-attrs',
        studentAttrTypesApi: '/api/v1/student-attr-types',
        decodesApi: '/api/v1/decodes',
        sectionStudentsApi: '/api/v1/section-students',
        bulkSectionStudentApi: '/api/v1/bulk/section-students',
        sectionsApi: '/api/v1/sections',
        studentAttrTemplate: 'setup/configure/student-attrs-single-student-edit',
        sectionStudentTemplate: 'setup/configure/section-student-single-student-table-row',
        decodesCache: {},
        newStudentsAddedCount: 1,
        canReplaceIds: 0,

        initList: function () {
            var self = this;
            var dropZoneTemplate = 'drop-zone';
            var dropZoneElem = $(Handlebars.renderTemplate(dropZoneTemplate))
                .attr('template', dropZoneTemplate);
            var mainContent = $('.mainBody section');
            var dndTarget = dropZoneElem.find('.dndtarget')[0];

            // if somebody drags a file before toggling the drop zone visible,
            // just show it for them
            $('body').get(0).addEventListener('dragover', function () {
                dropZoneElem.show();
            });

            dndTarget.addEventListener('dragover', fileDragHover, false);
            dndTarget.addEventListener('dragleave', fileDragHover, false);
            dndTarget.addEventListener('drop', fileSelectHandler(_.bind(self.handleData, self)), false);
            mainContent.first().before(dropZoneElem.hide());

            Tss.Types.initList("Student", "first_name", false, {
                extraApiParams: {
                    expand: 'school,grade_level,student_detail',
                },
                templates: {
                    emptyState: dropZoneTemplate,
                },
            });

            let warningPopup = $('#upload_csv_locked_warning_popup');
            if (!(warningPopup.length == 0)) {
                warningPopup.hide();
                $('body').on('click', '.btn-warning-upload', _.bind(self.handleWarnigClick, self));
            }

            $('body').on('click', '.download-template', self.downloadTemplate);

            $('#toggle-show-empty-state').hide().click(() => dropZoneElem.toggle());
            Tss.Types.$table.on('TableRendered', () => $('#toggle-show-empty-state').show());

            self.asyncTaskManager = new AsyncTaskManager('Uploading Students');
        },

        initAddEdit: async function (action, id, opts) {
            var self = this;

            self.options = opts;
            self.form = $('form[name="student_form"]');
            self.studentId = id;
            self.$table = $('#types-table');
            self.$table.tablesorter({ sortList: [] });
            self.setBindings();

            if (!id) {
                self.createAttrRows(null);
            }

            self.defaultRowData = {
                default_start_date: dateStringToDisplay(self.options.term.start_date),
                default_end_date: dateStringToDisplay(self.options.term.end_date),
                student_id: self.studentId,
                active: 1,
            };

            Tss.Types.initAddEdit('Student', 'first_name', action, id, {
                extraApiParams: {
                    expand: 'student_detail',
                },
                submitCallback: _.bind(self.saveDetailsAttrsAndEnrollments, self),
            });
        },

        handleWarnigClick: function(e){
            var self = this;
            let warningPopup = $('#upload_csv_locked_warning_popup');
            
            if (e.target.value == 'confirm') {
                self.canReplaceIds = true;
            }
            warningPopup.hide();
        },

        saveDetailsAttrsAndEnrollments: async function (response, form) {
            var self = this;
            var studentId = _.get(response, 'results.student.student_id');
            var formData = getFormData(self.form);

            formData.student_id = studentId;

            await self.createOrUpdateStudentDetails(formData);
            await self.updateStudentAttrs(formData); // updates contacts from attrs too
            await self.updateSectionStudents(formData);

            Tss.Types.redirectToReferrer();
        },

        createOrUpdateStudentDetails: async function (formData) {
            var self = this;
            var studentDetailId = formData.detail.student_detail_id;
            delete formData.detail['student_detail_id'];

            var studentDetailData = _.extend(formData.detail, {
                student_id: formData.student_id,
            });

            if (studentDetailId) {
                await Api.put(`/api/v1/student-details/${studentDetailId}`, studentDetailData);
            } else {
                await Api.post('/api/v1/student-details', studentDetailData);
            }
        },

        updateStudentAttrs: async function (formData) {
            var self = this;
            var studentAttrData = _.chain(formData.attrs)
                .map((attr, studentAttrTypeId) => ({
                    student_attr_id: attr.student_attr_id,
                    student_id: formData.student_id,
                    student_attr_type_id: studentAttrTypeId,
                    value: attr.value,
                }))
                .filter(m => m.student_attr_id || m.value !== '') // exists or has value
                .value();

            await Api.post('/api/v1/bulk/student-attrs', {
                bulk_data: studentAttrData
            });
        },

        /**
         * Bulk update section students.
         */
        updateSectionStudents: async function (formData) {
            var self = this;
            var bulkSectionStudentData = {
                bulk_data: formData.section_students
            };

            await Api.put(self.bulkSectionStudentApi, bulkSectionStudentData);

            var bulkNewSectionStudentData = {
                bulk_data: _.map(formData.new_section_students, x => _.extend(x, {
                    student_id: formData.student_id,
                    school_id: self.options.schoolId,
                    term_id: self.options.term.term_id,
                }))
            };

            await Api.post(self.bulkSectionStudentApi, bulkNewSectionStudentData);
        },

        downloadTemplate: function (e) {
            var url = '/setup/export/student-attrs/download';
            var extraParams = {
                school_id: Tss.Env.get('school_id'),
                can_create_and_update: 1,
                students_active: Tss.Types.$activeFilter.val(),
            };

            return genericDownload(url, null, extraParams)(e);
        },

        handleData: async function (data, target) {
            var self = this;
            var idx = (moment().format('YYYYMMDDHHmmssSSS') + Math.random()).replace('0.', '_');
            var postData = {
                school_id: Tss.Env.get('school_id'),
                can_create_and_update: 1,
                can_replace_ids : self.canReplaceIds,
                import_data: JSON.stringify(data), // gets around php max_input_vars of 10000
                idx: idx,
            };
            var request = $.post('/import/data', postData);
            var callbacks = {
                progress: elem => self.checkProgress(idx, elem),
                done: response => self.importDataDone(response),
                error: console.log,
            };
            var task = new AsyncTask('Upload Students', request, callbacks);

            self.asyncTaskManager.add(task);
            self.canReplaceIds = 0;
        },

        checkProgress: async function (idx, elem) {
            var response = await Api.get('/import/data/ping?idx=' + idx);

            elem.trigger('update', response);
        },

        importDataDone: function (response) {
            var self = this;

            $('#growls').empty();

            if (response.success) {
                var studentsAdded = self.getStudentsAdded(response);
                var studentsUpdated = self.getStudentsUpdated(response);

                _.pull(studentsUpdated, ...studentsAdded);

                var message = "Success!"
                    + (studentsAdded.length
                        ? ' ' + displayItems(studentsAdded.length, 'new student') + ' added.'
                        : '')
                    + (studentsUpdated.length
                        ? ' ' + displayItems(studentsUpdated.length, 'student') + ' updated.'
                        : '')
                    + (!studentsAdded.length && !studentsUpdated.length
                        ? " No changes detected."
                        : '');
                Growl.success({ message, fixed: true });
                Tss.Types.getAll(); // reload all rows
            } else {
                var warningMsg = _.chain(response).get('warnings').values().join('<br/>').value();
                var errorMsg = _.chain(response).get('errors').values().join('<br/>').value();

                if (warningMsg) {
                    Growl.warning({ message: warningMsg, fixed: true });
                }

                if (errorMsg) {
                    Growl.error({ message: errorMsg, fixed: true });
                }

                if (warningMsg.includes('Errors for User--')){
                    $('#upload_csv_locked_warning_popup').show();
                }
            }
        },

        getStudentsAdded: function (response) {
            return _.chain(response)
                .get('meta.changes.students')
                .filter(x => _.get(x, 'change_logs[0].type') == 0)
                .map(x => parseInt(x.student_id))
                .value();
        },

        getStudentsUpdated: function (response) {
            return _.chain(response)
                .get('meta.changes')
                .map(x => _.values(x)) // map/reduce turns nested data structure into one array of change_logs
                .reduce((result, value) => _.concat(result, value), [])
                .map(x => parseInt(x.student_id))
                .uniq()
                .value();
        },

        createAttrRows: async function (student) {
            var self = this;
            var studentAttrTypeArgs = {
                active: 1,
                school_ids: self.options.schoolId,
                expand: 'decode_type.decodes,contact_attr',
            };
            var spinner = $(Handlebars.renderTemplate('spinner'));

            self.form.find('section').last().before(spinner);

            const studentAttrTypesResponse = await Api.get(self.studentAttrTypesApi, studentAttrTypeArgs);
            const studentAttrTypes = _.chain(studentAttrTypesResponse)
                .get('results.student_attr_types')
                .filter(x => !x.contact_attr)
                .groupBy('display_group')
                .value();
            const contactAttrTypes = _.chain(studentAttrTypesResponse)
                .get('results.student_attr_types')
                .filter('contact_attr')
                .sortBy('contact_attr.order')
                .groupBy('contact_attr.group_num')
                .value();
            const groupKeys = _.union(['student', 'home', 'contacts', 'school', 'college', 'accommodations', 'null'], _.keys(studentAttrTypes));

            var studentAttrs = [];
            var sectionStudents = [];
            var newStudent = true;
            if (self.studentId) {
                newStudent = false;
                var studentAttrArgs = {
                    active: 1,
                    student_ids: self.studentId,
                };

                const studentAttrsResponse = await Api.get(self.studentAttrsApi, studentAttrArgs);
                studentAttrs = _.get(studentAttrsResponse, 'results.student_attrs');

                var sectionStudentsArgs = {
                    active: 1,
                    student_ids: self.studentId,
                    term_ids: self.options.term.term_id,
                    expand: 'section.section_periods.staff_member,section.section_periods.period,section.section_periods.section_mappings.course,section.course_definition',
                };

                const sectionStudentsResponse = await Api.get(self.sectionStudentsApi, sectionStudentsArgs);
                sectionStudents = _.chain(sectionStudentsResponse)
                    .get('results.section_students')
                    .filter(sectionStudent => sectionStudent.section.active == 1)
                    .value();
            }

            var attrMap = _.keyBy(studentAttrs, 'student_attr_type_id');
            var sections = self.addSection({
                title: 'Parent / Guardian Contact Info',
                classes: 'attr-group-mapped-contacts',
                attr_map: attrMap,
                subsections: _.map(contactAttrTypes, (attrTypes, i) => ({
                    subtitle: `Contact ${i}`,
                    student_attr_types: attrTypes,
                })),
            }, newStudent);

            _.each(groupKeys, (groupKey, i) => {
                sections += self.addSection({
                    title: 'Attributes: ' + _.humanize(groupKey === 'null' ? 'other' : groupKey),
                    classes: `attr-group-${groupKey}`,
                    attr_map: attrMap,
                    subsections: [
                        {
                            student_attr_types: _.get(studentAttrTypes, groupKey) || [],
                        },
                    ],
                }, newStudent);
            });

            if (self.studentId) {
                self.fillInSectionStudents(sectionStudents, true);
            }

            var sectionsArgs = {
                active: 1,
                term_ids: self.options.term.term_id,
                expand: 'section_periods.staff_member,section_periods.period,course_definition',
            };

            const sectionsResponse = await Api.get(self.sectionsApi, sectionsArgs);
            self.sections = _.chain(sectionsResponse)
                .get('results.sections')
                .each(section => section.display_name = $('<span/>').append(getSectionDisplayName(section)).text())
                .sortBy('display_name')
                .value();

            spinner.remove();
            self.form.find('#section-students').show().before(sections);
            self.form.each(setupUI);

            Tss.Types.disableSyncedFields(_.get(student, 'external_id'));
            if (self.options.editStudentContactsOnly) {
                $('section.options:not(.attr-group-mapped-contacts)')
                    .addClass('closed')
                    .find('input,select')
                    .attr('disabled', 1)
                    .addClass('disabled')
                    .attr('title', "You don't have permission to edit this field.")
                    .change()
                    .filter('select')
                    .trigger('disable');
                $('section.options.attr-group-mapped-contacts').removeClass('closed');
            }
        },

        addSection: function (templateData, newStudent) {
            var self = this;
            _.each(templateData.subsections, function (subsection) {
                _.each(subsection.student_attr_types, function (studentAttrType) {
                    studentAttrType.syncedFieldClass = studentAttrType.editable == '0' ? 'synced-field' : '';

                    if (Object.keys(templateData.attr_map).length !== 0) {
                        studentAttrType.value = _.get(templateData.attr_map, [studentAttrType.student_attr_type_id, 'value'])
                    } else if ((studentAttrType.hide_default_value == 0) && newStudent) {
                        studentAttrType.value = _.get(studentAttrType, 'default_value');
                    }

                });
            });

            return _.get(templateData, 'subsections[0].student_attr_types.length')
                ? Handlebars.renderTemplate(self.studentAttrTemplate, templateData)
                : '';
        },

        fillInSectionStudents: function (sectionStudents, append) {
            var self = this;

            _.each(sectionStudents, sectionStudent => {
                var id = sectionStudent.section_student_id;
                var rowData = _.extend({}, self.defaultRowData, sectionStudent, {
                    name_key: id ? 'section_students' : 'new_section_students',
                    row_id: id || self.newStudentsAddedCount++,
                });

                self.renderSectionStudentRow(rowData, append);
            });

            self.$table.trigger('update').each(setupUI);
        },

        renderSectionStudentRow: function (record, append) {
            var self = this;
            var isSyncedField = record.external_id
                && _.includes(self.options.syncedFields, 'section_student[start_date]');
            var $row = $(Handlebars.renderTemplate(self.sectionStudentTemplate, _.merge({}, record, {
                syncedFieldClass: isSyncedField ? 'synced-field' : '',
            })));
            var rowId = $row.attr('id');

            if (!record.external_id) {
                $row.find('.options').tssDropdown({
                    content: Handlebars.renderTemplate(`${self.sectionStudentTemplate}-options`, record),
                    icon: 'icon-cog',
                    right: true
                });
            }

            if ($('#' + rowId).length) {
                $('#' + rowId).replaceWith($row);
            } else {
                self.$table.find('tbody')[append ? 'append' : 'prepend']($row);
            }
        },

        setBindings: function () {
            var self = this;

            self.$table.on('click', '[rel="toggle-active"]', self.handleToggleActiveClick);
            self.form.on('FillInForm', function (e, student) {
                var studentDetail = student.student_detail;

                if (_.isObject(studentDetail)) {
                    _.each(studentDetail, (value, key) => {
                        fillInForm(`detail[${key}]`, value, undefined, true);
                    });
                }

                self.createAttrRows(student);
            });

            self.form.on('click', '[rel="add-section-student-row"]', function () {
                self.fillInSectionStudents([{
                    sections: self.sections,
                }]);
            });
        },

        handleToggleActiveClick: function () {
            var menuAction = $(this).closest('.menu-action');
            var row = $(this).closest('tr');
            var activeInput = row.find('[name$="\[active\]"]');
            var oldVal = activeInput.val();
            var newVal = oldVal == 1 ? 0 : 1; // toggle

            if (!menuAction.data('id')) {
                return row.remove();
            }

            activeInput.val(newVal);
            row.find('input[type="text"]').attr('disabled', newVal ? null : 'disabled');
            row[newVal ? 'removeClass' : 'addClass']('deactivated');

            // otherwise you see it change right when you click the link and that's a weird UX
            setTimeout(function () {
                menuAction.find('i').toggleClass('icon-remove').toggleClass('icon-ok');
                menuAction.get(0).childNodes[1].nodeValue = newVal ? 'Deactivate' : 'Activate';
            }, 100);
        },
    };

    window.Tss = window.Tss || {};
    window.Tss.Students = Students;

})(jQuery, window);

(function($, window) { "use strict";
    var BehaviorTypes = {
        initAddEdit: function(action, id) {
            var self = this;
            var expand = 'demerit_default_attribute_values'
                + (action == 'duplicate' ? '' : ',demerit_attribute_types.demerit_attribute_values');

            // fill in default core value, etc.
            self.isEdit = (action == "edit");
            self.form = $('#main-form');
            self.form.on('FillInForm', self.fillInAttributeDefaults);
            self.form.on('click', '[rel="deactivate-attribute-type"]', self.deactivateDemeritAttributeType);
            self.form.on('click', '#add-new, .edit-details', _.bind(self.saveAndAddEditDetails, self));
            self.defaultSuccessUrl = self.form.data('referrer') || "/setup/configure/behavior-types";

            Tss.Types.initAddEdit("DemeritType", "name", action, id, {
                type: {
                    endpoint: 'behavior-types',
                    reorderEndpoint: 'behavior-types/reorder',
                    resultsKey: 'behavior_types',
                    humanized: 'Behavior type',
                },
                extraApiParams: {
                    expand
                },
                submitCallback: self.storeAttributeDefaults
            });
        },

        initList: function(options) {
            var self = this;

            self.options = options;

            Tss.Types.initList("DemeritType", "name", true, {
                type: {
                    endpoint: 'behavior-types',
                    reorderEndpoint: 'behavior-types/reorder',
                    resultsKey: 'behavior_types',
                    humanized: 'Behavior types',
                },
                extraApiParams: {
                    expand: 'behavior_type_group,demerit_default_attribute_values.demerit_attribute_value.demerit_attribute_type,demerit_attribute_types.demerit_attribute_values',
                    school_ids: options.schoolId,
                    system: 0, // only non-system behaviors
                }
            });

            $('#types-table').on('SingleRowRendered TableRendered', self.onRender);
        },

        onRender: function(e, data) {
            var self = BehaviorTypes;
            var table = $(this);

            // show hide columns and make fractions of things
            if (!self.options.hasDemerits) {
                table.find('td:nth-child(' + (table.find('th[name="demerit_value"]').hide().index() + 1) + ')').hide();
            }

            self.displayDetentionValues(table, 'detention_value', self.options.hasEodDetention, self.options.detentionScale, 'isFullDetention');
            self.displayDetentionValues(table, 'lunch_detention_value', self.options.hasLunchDetention, self.options.lunchDetentionScale, 'isFullLunchDetention');
            self.displayDetentionValues(table, 'third_detention_value', self.options.hasThirdDetention, self.options.thirdDetentionScale, 'isFullThirdDetention');
        },

        displayDetentionValues: function(table, columnName, hasDetention, detentionScale, isFullDetentionColumn) {
            if (hasDetention && detentionScale == 1) {
                return;
            }

            var th = table.find('th[name="' + columnName + '"]');
            var tds = table.find('td:nth-child(' + (th.index() + 1) + ')');

            if (!hasDetention) {
                th.hide();
                tds.hide();
            } else {
                tds.each(function() {
                    var td = $(this);
                    var isFullDetention = td.data(isFullDetentionColumn) == 1;
                    var val = parseFloat(td.data('value')) || 0;

                    if (!isFullDetention && val != 0) {
                        td.html(toFraction(val / detentionScale, detentionScale, true));
                    }
                })
            }
        },

        fillInAttributeDefaults: function(e, record) {
            let self = BehaviorTypes;
            let genericAttrDefaults = _.get(record, 'demerit_default_attribute_values'); // e.g. default core value

            _.each(genericAttrDefaults, function(attr) {
                if (attr.active != 1) {
                    return true; // continue; // just making sure
                }

                let attrValueId = attr.demerit_attribute_value_id;
                $(`select.genericAttrDefaults option[value="${attrValueId}"]`).closest('select').val(attrValueId).change();
            });

            self.displayDemeritAttributeType(record);
        },

        displayDemeritAttributeType: function(record) {
            let attrTypes = _.get(record, 'demerit_attribute_types') || [];

            _.each(attrTypes, function(attrType) {
                if (_.get(attrType, 'active') == 1) {
                    let attrValues = _.map(_.get(attrType, 'demerit_attribute_values'), 'value').join(', ');
                    let content = `
                        <div class="menu-action" iframeheight="400" iframewidth="900" iframehref="#/changelog/object/DemeritAttributeType/${attrType.demerit_attribute_type_id}"><i class="icon-time"></i>History</div>
                        <a class="menu-action edit-details" href="/setup/configure/demerit-attribute-types/edit/${attrType.demerit_attribute_type_id}"><i class="icon-pencil"></i>Edit Details</a>
                        <div class="menu-action" data-id="${attrType.demerit_attribute_type_id}" data-active="1" rel="deactivate-attribute-type">
                            <i class="icon-remove"></i>Remove Details
                        </div>
                    `;

                    $('#demerit-attribute-types').find('#add-new').hide().after('<div class="options" style="float: right"></div>');
                    $('#demerit-attribute-types').find('.options').after('<div class="details">' + attrValues + '</div>');
                    $('#demerit-attribute-types').find('.options').tssDropdown({
                        content,
                        icon: 'icon-cog',
                        right: true
                    });

                    return false; // break;
                }
            });
        },

        deactivateDemeritAttributeType: function() {
            var self = BehaviorTypes,
                id = $(this).data("id"),
                options = {
                    type: "PUT",
                    url: "/api/v1/demerit-attribute-types/" + id,
                    data: {
                        active: 0
                    }
                };

            $.ajax(options)
                .done(self.handleDeactivateDemeritAttributeTypeSuccess)
                .fail(self.handleDeactivateDemeritAttributeTypeError);
        },

        saveAndAddEditDetails: function(e) {
            var self = this;
            var successUrl = $(e.currentTarget).attr('href');

            stopPropagation(e);

            self.pushSuccessUrl(successUrl);
            self.form.trigger('submit');

            return false;
        },

        pushSuccessUrl: function(successUrl) {
            var self = this;

            self.tempSuccessUrl = successUrl; // push
        },

        popSuccessUrl: function(demeritTypeId) {
            var self = this;
            var successUrl = self.defaultSuccessUrl;

            if (self.tempSuccessUrl) {
                successUrl = self.tempSuccessUrl;
                if (!successUrl.match(/\d$/) && demeritTypeId) {
                    successUrl += demeritTypeId + '&referrer=/setup/configure/behavior-types/edit/' + demeritTypeId; // add the newly saved ID to the URL
                }
            }

            self.tempSuccessUrl = undefined; // pop

            return successUrl;
        },

        /**
         * Handles toggle active click error
         * @param object jqXHR
         * @param string textStatus
         * @param string errorThrown
         */
        handleDeactivateDemeritAttributeTypeError: function(jqXHR, textStatus, errorThrown) {
            Growl.error({ message: "Error removing details." });
        },

        /**
         * Handles toggle active click success
         * @param object data
         */
        handleDeactivateDemeritAttributeTypeSuccess: function(data) {
            var self = BehaviorTypes;

            Growl.success({ message: "Successfully removed details" });
            $('#demerit-attribute-types').find('.options, .details').remove();
            $('#demerit-attribute-types').find('#add-new').show();
        },

        storeAttributeDefaults: function(data) {
            var self = BehaviorTypes;
            let demeritTypeId = _.get(data, 'results.demerit_type.demerit_type_id');
            
            if (!demeritTypeId) {
                Growl.error({message: 'Save failed.'});
                return;
            }

            let defaultMap = {};
            
            $('select[name^="demerit_default_attribute_values"]').each(function() {
                let demeritAttributeTypeId = $(this).data('demeritAttributeTypeId');
                let demeritAttributeValueId = $(this).val();

                if (demeritAttributeValueId) {
                    defaultMap[demeritAttributeTypeId] = demeritAttributeValueId;
                }
            });

            $.ajax({
                url: '/api/v1/behavior-types/update-default-attributes/' + demeritTypeId,
                type: 'PUT',
                data: {
                    defaults: defaultMap
                },
                success: function(data) {
                    var successUrl = self.popSuccessUrl(demeritTypeId);

                    if (data && data.success) {
                        window.location.assign(successUrl);
                    } else {
                        handlePost(data, undefined, undefined, self.form);
                    }
                }   
            });
        }
    };

    window.Tss = window.Tss || {};
    window.Tss.BehaviorTypes = BehaviorTypes;

})(jQuery, window);

/**
 * 
 * @author cmitchell
 */
(function($) {
    $.fn.tssComments = function(baseOptions){
        //set default options
        var defaultOptions = {
                template: 'comments-list',
                countSelector: '.comments-count',
                countBadgeSelector: '.comments-count-badge',
                textareaSelector: 'textarea',
                postButtonSelector: '.comments-post',
                commentsListContainerSelector: '.comments-list-container',
                scrollToButtonSelector: '[rel="scroll-to-comments"]',
                scrollOnLoad: false,
            },
            postApiUri = '/comment/add/',
            getApiUri = '/comments/get/';

        //merge with passed options
        baseOptions = $.extend(defaultOptions, (typeof baseOptions === 'object' ? baseOptions : {}));
        
        return this.each(function() {
            var $container = $(this),
                options = handleOptionAttrs($container, baseOptions);
            
            //make sure plugin hasn't already been applied and that we have a type and id
            if($container.hasClass('is-tss-comments') || !options.id || !options.type || !options.staffMemberId) { return; }
            $container.addClass('is-tss-comments');
            
            //configure
            var config = {
                    $countContainer: $(options.countSelector, $container),
                    $countBadgeContainer: $(options.countBadgeSelector), // this will most likely be outside of the container...
                    $textarea: $(options.textareaSelector, $container),
                    $postButton: $(options.postButtonSelector, $container),
                    $commentsListContainer: $(options.commentsListContainerSelector, $container),
                    $scrollToButton: $(options.scrollToButtonSelector),
                    postApiUri: preparePostApiUri(options.type),
                    getApiUri: prepareGetApiUri(options.type, options.id),
                    allComments: []
                };
            
            //away we go...
            init($container, options, config);
        
        });
        
        /**
         * @param {object} $container
         * @param {object} options 
         * @param {object} config 
         */
        function init($container, options, config) {
            setupBindings($container, options, config);
            get(options, config);
        }
        
        /**
         * @param {object} $container
         * @param {object} options 
         * @param {object} config 
         */
        function setupBindings($container, options, config) {
            config.$postButton.click(function(e) {
                e.preventDefault();
                post(options, config);
                return;
            });

            config.$textarea.on('keydown', null, getSimpleShortcutLabelPrefix() + 'return', function(e) {
                e.preventDefault();
                post(options, config);
            });

            config.$textarea.on('keyup', function(e) {
                validate(config);
            });

            config.$scrollToButton.click(function(e) {
                scroll($container);
            });
        }
        
        /**
         * @param {object} options
         * @param {object} config
         */
        function get(options, config) {
            var request = $.ajax(config.getApiUri);
            request.done(function(data) {
                if(!data.success) { return handleGetError(config, data); }
                handleGetSuccess(options, config, data);
            });
            request.fail(function(data) {
                handleGetError(config, data);
            });
        }

        /**
         * @param {object} options
         * @param {object} config
         */
        function post(options, config) {
            var comment = config.$textarea.val(),
                valid = validate(config),
                data = {};
                
            if(!valid) { return; }
            
            //prepare data
            data[options.type + '_id'] = options.id;
            data.staff_member_id = options.staffMemberId;
            data.comment = comment;
            
            //post
            $.post(config.postApiUri, data)
            .done(function(data) {
                if(!data.success) { return handlePostError(config, data); }
                handlePostSuccess(options, config, data);
            })
            .fail(function(data) {
                handlePostError(config, data);
            });
        }
        
        /**
         * @param {object} config
         * @return {}
         */
        function validate(config) {
            var comment = config.$textarea.val();
            
            if(!comment || !comment.length) {
                config.$textarea.addClass('error');
                return false;
            }
            
            config.$textarea.removeClass('error');
            return true;
        }
        
        /**
         * @param {object} options
         * @param {object} config
         * @param {object} data
         */
        function handleGetSuccess(options, config, data) {
            //store comments
            config.allComments = data.results.comments;
            
            //render
            renderList(options, config);

            var facebox = window.top.$('#facebox');
            if (facebox.length) {
                facebox.trigger('resize');
                if (options.scrollOnLoad) {
                    setTimeout(
                        () => {
                            // scroll so you can see the newest comment
                            facebox[0].scrollTo(0, config.$textarea[0].getBoundingClientRect().top - 10);
                        },
                        10
                    );
                }
            }
        }
        
        /**
         * @param {object} config
         * @param {object} data
         */
        function handleGetError(config, data) {}
        
        function handlePostSuccess(options, config, data) {
            //empty textarea
            config.$textarea.val(config.$textarea.data('reset-to') || '');
            
            //prepend new post
            config.allComments.unshift(data.results.obj);
            
            //render
            renderList(options, config, true);

            var facebox = window.top.$('#facebox');
            if (facebox.length) {
                facebox.trigger('resize');
                setTimeout(
                    () => {
                        // scroll so you can see the newest comment
                        facebox[0].scrollTo(0, config.$textarea[0].getBoundingClientRect().top - 10);
                    },
                    0
                );
            }
        }
        
        /**
         * @param {object} config
         * @param {object} data
         */
        function handlePostError(config, data) {}
        
        /**
         * @param {object} config
         * @param {number} count
         */
        function updateCount(config, count) {
            config.$countContainer.html(count);
            config.$countBadgeContainer.removeClass('animation-jump');
            
            //we have to have 1ms timeout to "reset" the animation...
            setTimeout(function(){ config.$countBadgeContainer.addClass('animation-jump').html(count); }, 1);
        }
        
        /**
         * @param {object} $container
         */
        function scroll($container) {
            $container.scrollintoview();
        }
        
        /**
         * @param {object} options
         * @param {object} config
         * @param {boolean} isAppending
         */
        function renderList(options, config, isAppending) {
            //update count
            updateCount(config, config.allComments.length);

            if (config.allComments.length) {
                config.$commentsListContainer.html(
                    Handlebars.renderTemplate(options.template, {
                        comments: config.allComments, isAppending: isAppending
                    })
                ).show();
            } else {
                config.$commentsListContainer.hide();
            }
        }

        /**
         * @param {object} el
         * @param {object} baseOptions
         * @return {object}
         */
        function handleOptionAttrs(el, baseOptions) {
            var options = _.clone(baseOptions);
            _.each(el.data(), function(value, key) {
                options[key] = value;
            });

            return options;
        }
        
        /**
         * @param {string} type
         * @return {string}
         */
        function preparePostApiUri(type) {
            return '/' + type + postApiUri;
        }
        
        /**
         * @param {string} type
         * @param {number} id
         * @return {string}
         */
        function prepareGetApiUri(type, id) {
            return '/' + type + getApiUri + id;
        }
    };
}(jQuery));
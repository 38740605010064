(function ($, window, undefined) {"use strict";

	var defaults = {
		minLength: 3,
		delay: 250,
		url: "/api/v1/student-finder",
		behaviorData: false,
		absenceData: false,
		iconClass: "icon-search",
		asof: false,
		cache: false,
		onSearch: function () {},
		onSelect: function (result) {},
		onComplete: function (students) {}
	};

	function TssStudentFinder(element, options) {
		this._options = $.fn.tssOptions("tss-student-finder", element, defaults, options);
		this.input = element;
		this.$input = $(element);
		this.$icon = $("<i>").addClass(this._options.iconClass);
		this.$studentFinder = $("<div>").addClass("tss-student-finder");
		if (this._options.asof) {
			this.$asof = this._options.asof instanceof jQuery ? this._options.asof : $(this._options.asof);
		}
		setupWidget();
		setup.call(this);
	};

	function setupWidget() {
		$.widget("custom.tssStudentFinderAutocomplete", $.ui.autocomplete, {
			_create: function () {
				this._super();
      			this.widget().menu("option", "items", "> :not(.category)");
			},
			_renderMenu: function (ul, results) {
				var widget = this, type;
				ul.addClass("tss-student-finder-menu");
				_.each(results, function (result) {
					if (!results.notFound && result.type != type) {
						ul.append($("<li>").addClass("category").append($("<span>", { text: _.titleize(result.type.replace("_", " ")) })));
						type = result.type;
					}
					widget._renderItemData(ul, result);
				});
			},
			_renderItem: function (ul, result) {
				return $("<li>").append(result.notFound ? $("<em>", { text: "No results found" }) : $("<a>", { text: result.label, tabindex: -1 })).appendTo(ul);
			}
		});
	};

	function setup() {
		var self = this, cache = this.getCache();
		this.$input.add(this.$icon).wrapAll(this.$studentFinder);
		this.$input.tssStudentFinderAutocomplete({
			minLength: this._options.minLength,
			delay: this._options.delay,
			source: function (search, result) {
				var request, data = { search_term: search.term };
				if (self._options.asof) {
					data.asof = $(self._options.asof).val();
				}
				request = $.get(self._options.url, data);
				request.done(function (data) {
					result(handleResults.call(self, data));
				});
			},
			search: function (event, ui) {
				self._options.onSearch.call(this);
			},
			select: function (event, ui) {
				var result = $.extend({ search: self.$input.val() }, ui.item);
				self._result = result;
				self._options.onSelect.call(this, result);
				self.find(result.student_ids);
				if (self._options.cache) {
					self.setCache(result);
				}
				return false;
			},
			focus: function () {
				return false;
			}
		});
		if (this._options.cache && cache) {
			handleCache.call(this, cache);
		}
		if (this._options.asof) {
			handleAsof.call(this);
		}
	};

	function handleResults(data) {
		var results = [];
		_.each(data.results, function (list, type) {
			_.each(list, function (result) {
				results.push($.extend({ type: type }, result));
			});
		});
		if (!results.length) {
			results.push({ notFound: true });
		}
		return results;
	};

	function handleCache(cache) {
		var self = this, request, data = { search_term: cache.search };
		if (this._options.asof) {
			data.asof = $(this._options.asof).val();
		}
		request = $.get(this._options.url, data);
		request.done(function (data) {
			var result;
			if (data.results && data.results[cache.type]) {
				result = _.find(data.results[cache.type], function (obj) {
					var key = cache.type.substring(0, cache.type.length - 1) + "_id";
					return obj[key] === cache[key];
				});
				if (result) {
					self._result = $.extend(cache, result);
					self.find(result.student_ids);
					self.$input.val(cache.search);
				}
			}
		});
	};

	function handleAsof() {
		var self = this;
		this.$asof.change(function () {
			if (!self._result) {
				return;
			}
			self.$input.val(self._result.search);
			self.find(self._result.student_ids);
		});
	};

	function getCacheObject() {
		return $.parseJSON(localStorage.TssStudentFinder || '{}');
	};

	TssStudentFinder.prototype.find = function (studentIds) {
		var self = this, request, data = { student_ids: studentIds, behavior_data: this._options.behaviorData, absence_data: this._options.absenceData || [] };
		if (this._options.asof) {
			data.asof = $(this._options.asof).val();
		}
		studentIds = $.isArray(studentIds) ? studentIds : [studentIds];
		request = $.post(this._options.url, data);
		request.done(function (data) {
			self._options.onComplete.call(self.$input, data.results.students);
		});
	};

	TssStudentFinder.prototype.search = function (term) {
		this.$input.tssStudentFinderAutocomplete("search", term);
	};

	TssStudentFinder.prototype.close = function () {
		this.$input.tssStudentFinderAutocomplete("close");
	};

	TssStudentFinder.prototype.setCache = function (value) {
		var cache = getCacheObject() || {};
		if (!this._options.cache) {
			return;
		}
		cache[this.input.id] = value;
		return localStorage.setItem("TssStudentFinder", JSON.stringify(cache));
	};

	TssStudentFinder.prototype.getCache = function () {
		var cache = getCacheObject();
		if (!this._options.cache || !cache) {
			return;
		}
		return cache[this.input.id];
	};

	TssStudentFinder.prototype.removeCache = function () {
		var cache = getCacheObject();
		if (!this._options.cache || !cache) {
			return;
		}
		delete cache[this.input.id];
		if ($.isEmptyObject(cache)) {
			return localStorage.removeItem("TssStudentFinder");
		}
		return localStorage.setItem("TssStudentFinder", JSON.stringify(cache));
	};

	$.fn.tssStudentFinder = $.fn.tssExtend(TssStudentFinder);

})(jQuery, window);

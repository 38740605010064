/**
 * 
 * @author cmitchell
 */
(function($) {
    $.fn.tssMultiSelect = function(options){
        //set default options
        var defaultOptions = {
            placeholder: 'No Options Selected',
            displayLimit: 0,
            displayOptionText: false
        };
        
        //close on body click
        $('html').click(function() {
            $('.tss-multiselect ul').hide();
        });
        
        return this.each(function(){
            //get input into scoped variable
            var inputElement = $(this);
            
            //merge with passed options
            var options = $.extend(defaultOptions, inputElement.metadata(), (typeof options == 'object' ? options : {}));
            
            //check if has already had this plugin applied or is missing multiple attribute
            if (inputElement.siblings('div.tss-multiselect-btn').length !== 0 || !inputElement.attr('multiple')) {
                return inputElement;
            }
            
            //get all select option tags
            var optionTags = inputElement.find('option');
            
            //get values as array and string
            var selectedValues = inputElement.val() || [];

            //wrap input
            inputElement.wrap('<div class="tss-multiselect" />');
            
            //so body click will close only if not within the unordered list
            inputElement.parent().click(function(e){
                e.stopPropagation();
            });

            //hide input
            inputElement.hide();
            
            //add button
            var toggleButton = $('<div class="tss-multiselect-btn" />');
            inputElement.after(toggleButton);
            
            //create options list
            var optionsList = $('<ul />');
            
            //populate options list
            optionTags.each(function(i, option) {
                option = $(option);
                $('<li data-value="' + option.val() + '">' + option.text() + '</li>').appendTo(optionsList);
            });
            toggleButton.after(optionsList);
            
            //get options list
            var listItems = optionsList.find('li');
            
            //update selected values
            updateSelectedValues();
            
            //bindings
            toggleButton.click(function(e){
                e.preventDefault();
                if (optionsList.is(':visible')) {
                    optionsList.hide();
                } else {
                    //first hide all others
                    $('.tss-multiselect ul').hide();
                    
                    //then show
                    optionsList.show();
                }
            });
            optionsList.find('li').click(function(e){
                e.preventDefault();
                var item = $(this);
                
                if (item.hasClass('selected')) {
                    removeFromSelectValuesArray(item.data('value'));
                } else {
                    addValueToSelectValuesArray(item.data('value'));
                }
                
                //update
                updateSelectedValues();
            });
            
            //functions
            function updateSelectedValues() {
                //set button text
                setButtonText();
                
                //remove selected class from all list items
                listItems.removeClass('selected');
                
                //iterate through all list items and add select class if in selectedValues array
                listItems.each(function(i, item){
                    item = $(item);
                    if ($.inArray(item.data('value'), selectedValues) !== -1) {
                        item.addClass('selected');
                    } 
                });
            }
            
            function setButtonText() {
                var buttonTextValues = [];
                $.each(selectedValues, function(i, value){
                    //show text value
                    if(options.displayOptionText) {
                        buttonTextValues.push(inputElement.find('[value="'+value+'"]').text());
                    }
                    //show raw option value
                    else {
                        buttonTextValues.push(value);
                    }
                });
                
                //concat
                var text = buttonTextValues.join(', ');
                
                //if display limit set
                if (options.displayLimit !== 0) {
                    var tempSelectedValues = [];
                    $.each(buttonTextValues, function(i, value){
                        if (value.length - 3 > options.displayLimit) {
                            tempSelectedValues.push(
                                value.substr(0, options.displayLimit - 3) + '...'
                            );
                        } else {
                            tempSelectedValues.push(value);
                        }
                    });
                    text = tempSelectedValues.join(', ');
                }
                
                //if nothing selected, use placeholder
                if (text.length === 0) {
                    text = options.placeholder;
                }
                
                //set it
                toggleButton.text(text).attr('title', text);
            }
            
            function removeFromSelectValuesArray(value) {
                selectedValues.splice(selectedValues.indexOf(value), 1);
                inputElement.val(selectedValues).trigger('change');
            }
            
            function addValueToSelectValuesArray(value) {
                selectedValues.push(value);
                inputElement.val(selectedValues).trigger('change');
            }
        });
    };
}(jQuery));
(function($, window) { "use strict";
    var SetupSchool = {
        spinner: null,
        modal: null,
        modalData: {},
        lastSchoolId: null,
        lastTermId: null,
        launchTarget: '#launch-copy-between-schools',
        template: 'copy-between-schools-modal',

        init: function() {
            var self = this;
            var links = $('.viewer-sidebar-list a');
            var linkToSelect = links.first();

            self.spinner = Handlebars.renderTemplate('spinner');

            self.modal = new Tss.Modal({
                launchTarget: self.launchTarget,
                template: self.template,
                getData: () => self.modalData,
                go: _.bind(self.copyBetweenSchools, self),
            });

            // could do this instead which would allow us to effectively run
            // a router for anything /setup/configure and load it all in our
            // viewer content pane instead of redirecting around
            // $('.mainBody').on('click', 'a[href*="/setup/configure/"]', self.loadContent);
            links.on('click', self.loadContent);

            // use the window hash to store currently selected tab/page
            // this way we can come back to the right dataset after editing a record
            if (window.location.hash) {
                var foundLink = links.filter('[href="' + window.location.hash.replace('#','') + '"]');

                if (foundLink && foundLink.length) {
                    linkToSelect = foundLink;
                }
            }

            linkToSelect.click();

            $(self.launchTarget).attr('disabled', 'disabled').addClass('disabled');
            self.getModalData();
        },

        loadContent: function(e) {
            var self = SetupSchool;
            var a = $(this);
            var href = a.attr('href');

            if (!a.closest('.viewer-sidebar-list').length) {
                a = $('.viewer-sidebar-list').find(`a[href="${href}"]`);
            }

            if (a.closest('li').hasClass('disabled')) {
                return stopPropagation(e);
            }

            // highlight selected link
            a.closest('ul').find('li').removeClass('active');
            a.closest('li').addClass('active');
            window.location.hash = href;

            // load types page in the content pane
            $('.viewer-content').html(self.spinner);
            $('.viewer-content').tssLoad(href + '?tooltip=1');

            return stopPropagation(e);
        },

        getModalData: async function() {
            var self = this;
            var meResponse = await Api.get('/api/v1/me?expand=permissions,allowed_schools');
            var hasMultiSchool = _.get(meResponse, 'results.me.permissions.multi_school');
            var allowedSchoolIds = _.chain(meResponse)
                .get('results.me.allowed_schools')
                .filter(x => x.active == 1)
                .map('school_id')
                .value() || [];

            if (!hasMultiSchool && !allowedSchoolIds.length) {
                $(self.launchTarget).attr('title', 'Not possible, no allowed schools.');
                return;
            }

            var schoolsOptions = {
                active: 1,
            };

            if (!hasMultiSchool) {
                schoolsOptions['school_ids'] = allowedSchoolIds.join(',');
            }

            var schoolsResponse = await Api.get('/api/v1/schools', schoolsOptions);
            var schools = _.get(schoolsResponse, 'results.schools');
            var schoolIds = _.map(schools, 'school_id');

            self.modalData = {
                schools: schools,
                terms: [],
                termBins: [],
                courses: [],
                copy_between_schools: {},
            };
            $(self.launchTarget).removeAttr('disabled').removeClass('disabled');

            $('body').on('change', `.${self.template} select[name$="src_school_id"]`, self.loadTermsAndCourses)
            $('body').on('change', `.${self.template} select[name="src_term_id"]`, self.loadTermBins);
            $('body').on('change', `.${self.template} select[name="src_term_id"], .${self.template} input[name="copy_grading_methodologies"]`, self.updateRequiredFields);
        },

        loadTermsAndCourses: async function() {
            var self = SetupSchool;
            var schoolSelect = $(this);
            var schoolId = schoolSelect.val();
            var modal = schoolSelect.closest(`.${self.template}`);
            var termSelects = modal.find('[name$="term_id"]');
            var courseSelects = modal.find('[name="src_course_ids"]');

            if (schoolId == self.lastSchoolId) {
                return;
            }

            self.lastSchoolId = schoolId;

            if (!schoolId) {
                termSelects.html('')
                    .trigger('refresh-options')
                    .change();
                courseSelects.html('')
                    .trigger('refresh-options')
                    .change();
                return;
            }

            termSelects.val('').change().next().addClass('loading');
            courseSelects.val('').change().next().addClass('loading');

            var termsApiOptions = {
                active: 1,
                school_ids: schoolId,
            }
            var termsResponse = await Api.get('/api/v1/terms', termsApiOptions);
            self.allTerms = _.get(termsResponse, 'results.terms');

            var termOptions = _.chain(self.allTerms)
                .sortBy('start_date')
                .reverse()
                .map(t => `<option value="${t.term_id}">${t.long_name}</option>`)
                .join('')
                .value();
            termSelects.next().removeClass('loading');
            termSelects.html(termOptions)
                .trigger('refresh-options')
                .change();

            var coursesApiOptions = {
                active: 1,
                school_ids: schoolId,
            }
            var coursesResponse = await Api.get('/api/v1/courses', coursesApiOptions);
            self.allCourses = _.get(coursesResponse, 'results.courses');

            var courseOptions = _.chain(self.allCourses)
                .sortBy('display_name')
                .map(t => `<option value="${t.course_id}">${t.display_name}</option>`)
                .join('')
                .value();
            courseSelects.next().removeClass('loading');
            courseSelects.html(courseOptions)
                .trigger('refresh-options')
                .change();
        },

        loadTermBins: async function() {
            var self = SetupSchool;
            var termSelect = $(this);
            var termId = termSelect.val();
            var modal = termSelect.closest(`.${self.template}`);
            var termBinSelects = modal.find('[name$="term_bin_ids"]');

            if (termId == self.lastTermId) {
                return;
            }

            self.lastTermId = termId;

            if (!termId) {
                termBinSelects.html('')
                    .trigger('refresh-options')
                    .change();
                return;
            }

            termBinSelects.val('').change().next().addClass('loading');

            var termBinsApiOptions = {
                active: 1,
                term_ids: termId,
            }
            var termBinsResponse = await Api.get('/api/v1/term-bins', termBinsApiOptions);
            self.allTermBins = _.get(termBinsResponse, 'results.term_bins');

            var termBinOptions = _.chain(self.allTermBins)
                .sortBy(['term_bin_type_id', 'short_name'])
                .map(t => `<option value="${t.term_bin_id}">${t.short_name}</option>`)
                .join('')
                .value();
            termBinSelects.next().removeClass('loading');
            termBinSelects.html(termBinOptions)
                .trigger('refresh-options')
                .change();
        },

        updateRequiredFields: function() {
            var form = $('form[name="copy_between_schools"]');
            var copyGradingMethodologies = $('[name="copy_grading_methodologies"]').is(':checked');
            var copyTermBins = $('[name="copy_term_bins"]').is(':checked');
            var srcTerm = form.find('select[name="src_term_id"]');
            var wasRequired = srcTerm.is('[required]');
            var shouldBeRequired = copyGradingMethodologies || copyTermBins;
            var makeRequired = !wasRequired && shouldBeRequired;
            var makeNotRequired = wasRequired && !shouldBeRequired;

            if (makeRequired) {
                srcTerm.attr('required', 'required').change();
            } else if (makeNotRequired) {
                srcTerm.removeAttr('required').change();
            }
        },

        copyBetweenSchools: async function() {
            var self = this;
            var form = $(`.${self.template}`);
            var saveButton = form.find('.go');

            if (!validateForm(form)) {
                return false;
            }

            saveButton.attr('disabled', 'disabled').addClass('disabled').find('span').text('Saving...');
            saveButton.find('i').show();

            var schoolAndTermData = getFormData(form.find('section').first());
            var courseData = getFormData(form.find('#course_tab'));
            if (courseData.courses) {
                if (!courseData.src_course_ids) {
                    courseData.src_course_ids = _.map(self.allCourses, 'course_id');
                }
                var courseResponse = await Api.post('/api/v1/courses/copy-to-schools', _.extend({}, schoolAndTermData, courseData));
                self.showCopyToSchoolsResponse(courseResponse);
            }

            var termBinData = getFormData(form.find('#term_bins_tab'));
            if (termBinData.term_bins) {
                if (!termBinData.src_term_bin_ids) {
                    termBinData.src_term_bin_ids = _.map(self.allTermBins, 'term_bin_id');
                }
                var termBinResponse = await Api.post('/api/v1/term-bins/copy-to-schools', _.extend({}, schoolAndTermData, termBinData));
                self.showCopyToSchoolsResponse(termBinResponse);
            }

            setTimeout(() => {
                saveButton.removeAttr('disabled').removeClass('disabled').find('span').text('Save');
                saveButton.find('i').hide();
                self.modal.closeModal();
                window.location.reload();
            }, 5000);
        },

        showCopyToSchoolsResponse: function(response) {
            // results: {
            //   Term: {
            //     created: [
            //       {display_name}
            //     ],
            //     updated: [
            //       {display_name}
            //     ],
            //     deactivated: [
            //       {display_name}
            //     ]
            //   },
            //   Objective: {
            //     ...
            //   }
            // }
            var numToShow = 3;
            var message = _.chain(response)
                .get('results')
                .map((m, clazz) => {
                    return _.chain(m)
                        .map((recordsMap, type) => {
                            var records = _.values(recordsMap);
                            return _.humanize(type)
                                + ' '
                                + displayItems(records.length, _.humanize(clazz));
                                // + ': '
                                // + _.chain(records)
                                //     .slice(0, 3)
                                //     .map('display_name')
                                //     .join(', ')
                                //     .value()
                                // + (records.length > numToShow
                                //     ? ' and ' + (records.length - numToShow) + ' more.'
                                //     : '');
                        })
                        .join('<br/>\n')
                        .value();
                })
                .join('<br/>\n')
                .value()
                || 'Success!';

            if (response.success) {
                Growl.success({ message });
            } else {
                Growl.error({message: _.get(response, 'errors[0]') || 'Copy failed.'});
            }
        },
    };

    window.Tss = window.Tss || {};
    window.Tss.SetupSchool = SetupSchool;

})(jQuery, window);

/* globals moment: false */
(function($, window) { "use strict";

    var ScheduledTask = {
        
        cronJobs: [],
        
        // Config
        config: {
            showInactive: false,
            tables: {
                options: {
                    sortList: [0,0],
                    widgets: ["zebra", "filter"],
                    widgetOptions: {
                        filter_columnFilters: false,
                        filter_saveFilters: false
                    }
                }
            },
            api: {
                activate: '/activate/',
                get: '/api/v1/scheduled-tasks/',
                pause: '/api/v1/scheduled-task/pause/',
                postPut: '/api/v1/scheduled-task/'
            },
            templates: {
                row: 'scheduled-task-row',
                editOptions: 'scheduled-task-edit-options',
                rowEditOptions: 'scheduled-task-row-edit-options'
            },
        },
        
        /**
         * Initializes list view
         */
        initList: function() {
            // Get elements
            this.$table = $('#scheduled-tasks-table');
            this.$pager = $('#scheduled-tasks-table-pager');
            this.$toggleShowInactive = $('#toggle-show-inactive');
            
            // Fetch rows
            this.fetchRows();
        },
        
        /**
         * Fetches rows
         */
        fetchRows: function() {
            $.getJSON(this.config.api.get)
                .done(this.renderTable);
        },

        /**
         * Renders table
         * @param object cronJob
         */
        renderTable: function(data) {
            var self = ScheduledTask,
                cronJobs = data.results.cron_jobs || [],
                $tbody = self.$table.find('tbody');
            
            // Cache
            self.cronJobs = cronJobs;
            
            // Build rows
            _.each(cronJobs, function(cronJob) {
                var $row = $(Handlebars.renderTemplate(self.config.templates.row, cronJob));
                $row.find('.behaviors-table-select-options').tssDropdown({
                    content: Handlebars.renderTemplate(self.config.templates.rowEditOptions, cronJob),
                    icon: 'icon-cog',
                    right: true
                });
                $row.appendTo($tbody);
            });
            
            // Initialize table
            self.initTable();
        },

        /**
         * Renders single row
         * @param int cronJobId
         * @param object cronJob
         */
        renderRow: function(cronJobId, cronJob) {
            // Remove tooltips
            $('.tss-tooltip').remove();
            
            // Replace with new row
            var $newRow = $(Handlebars.renderTemplate(this.config.templates.row, cronJob));
            $newRow.find('.behaviors-table-select-options').tssDropdown({
                content: Handlebars.renderTemplate(this.config.templates.rowEditOptions, cronJob),
                icon: 'icon-cog',
                right: true
            });
            this.$table.find('#cron-job-' + cronJobId).replaceWith($newRow);
            
            // Update filtering
            this.updateTableFiltering();
        },

        /**
         * Initializes list view table
         */
        initTable: function () {
            this.$table.tablesorter(this.config.tables.options);
            this.$table.tablesorter().tablesorterPager({
                container: this.$pager,
                output: '{page} of {totalPages}',
                size: $('select.pagesize', this.$pager).val(),
                page: 0
            });
            
            // Set bindings
            this.setListBindings();

            // Reorder to before we updated
            this.$table.trigger('sorton', this.config.tables.options.sortOn);
            
            // Update filtering
            this.updateTableFiltering();
        },
        
        /**
         * Updates table filtering
         */
        updateTableFiltering: function() {
            // Update table contents
            this.$table.trigger('update');
            
            // Reorder to before we updated
            this.$table.trigger('search', [['', this.config.showInactive ? '' : '1']]);
        },

        /**
         * Initializes add/edit/dup view
         * @param string action (add, edit or duplicate)
         * @param object activeRecord
         */
        initAddEdit: function (action, activeRecord) {
            // Get elements
            this.activeRecord = activeRecord;
            this.$form = $('#main-form');
            this.$frequencyDescriptionContainer = $('#frequency-description');
            this.$cronJobType = $('[name="cron_job_type_id"]');
            this.$cronJobTypeForms = $('section.cron-job-type-form');
            this.$cronJobTimeFormat = $('#scheduled-task-time-format');
            this.$cronJobDateFormat = $('#scheduled-task-date-format');
            this.$cronJobTimeOnceCols = $('.task-time-once');
            this.$cronJobTimeIntervalCols = $('.task-time-interval');
            this.$cronJobDateOnceCols = $('.task-date-once');
            this.$cronJobDateWeekdaysCols = $('.task-date-weekdays');
            this.form = {
                $time: $('[name="time"]'),
                $weekdays: $('[name="weekdays[]"]'),
                $date: $('[name="date"]'),
                $start: $('[name="start_time"]'),
                $end: $('[name="end_time"]'),
                $interval: $('[name="interval"]')
            };
            
            // Set other variables
            this.isEdit = action === 'edit',
            this.config.api.postPut = this.config.api.postPut + (this.isEdit ? activeRecord.cron_job_id : ''),
            this.method = this.isEdit ? 'PUT' : 'POST';
            
            // Set bindings and fill in form (if action isn't add)
            if(action !== 'add') {
                this.fillInForm(action, activeRecord);
            }
            this.setAddEditBindings();
        },
          
        /**
         * Fills in form
         * @param string action
         * @param object activeRecord
         */
        fillInForm: function (action, activeRecord) {
            var self = this,
                isDuplicate = action === 'duplicate',
                cronJobType = this.getCronJobType();
    
            // Fill in 
            _.each(isDuplicate ? this.getDuplicateValues(activeRecord) : activeRecord, function(value, key) {
                var prefixedKey = self.getPrefixedKey(cronJobType, key);

                key = self.$form.find(`[name="${prefixedKey}"]`).length
                    ? prefixedKey
                    : key;

                fillInForm(key, value, self.$form);
            });
            
            // Fill in expression
            this.fillInExpression(activeRecord.cron_datetime_expression);
        },

        /**
         * Gets the selected cron job type
         * @return string cronJobType
         */
        getCronJobType: function () {
            var value = this.$cronJobType.val(),
                $option = this.$cronJobType.find("[value=\"" + value + "\"]"),
                cronJobType = $option.data("cronJobType");

            return cronJobType;
        },
        
        /**
         * Gets value to fill in form on duplicate view
         * @param object activeRecord
         * @returns object
         */
        getDuplicateValues: function(activeRecord) {
            delete activeRecord.cron_job_id;
            delete activeRecord.from_date;
            delete activeRecord.thru_date;
            activeRecord.name = activeRecord.name && activeRecord.name.length
                                    ? activeRecord.name + ' (copy)'
                                    : activeRecord.name;
                                    
            return activeRecord;
        },

        /**
         * Gets prefixed key
         * @param string prefix
         * @param string key
         * @returns string
         */
        getPrefixedKey: function (prefix, key) {
            return prefix + "-" + key;
        },

        /**
         *  Fills in date and time fields
         * @param object expression
         */
        fillInExpression: function(expression) {
            var parts = this.parseExpression(expression);
            
            // Fill in date fields
            // Weekdays
            if(parts.day === '*' && parts.month === '*') {
                this.form.$weekdays.val(_.isArray(parts.weekday) ? parts.weekday : [parts.weekday]).change();
                this.$cronJobDateFormat.val('weekdays').change();
            }
            // Specific date
            else {
                this.form.$date.val(moment(parts.month + '/' + parts.day + '/' + moment().format('YYYY')).format('YYYY-MM-DD'));
                this.$cronJobDateFormat.val('once').change();
            }
            
            // Fill in time fields
            // Interval
            if(_.isObject(parts.hour)) {
                this.form.$start.val(parts.hour.start).change();
                this.form.$end.val(parts.hour.end).change();
                this.form.$interval.val(parts.hour.interval).change();
                this.$cronJobTimeFormat.val('interval').change();
            }
            // Once daily
            else {
                var time = parts.hour + ':' + Tss.Number.padZeros(parts.minute, 2);
                this.form.$time.val(time).change();
                this.$cronJobTimeFormat.val('once').change();
            }
        },

        /**
         * Parses cron expression
         * @param string expression
         * @returns object
         */
        parseExpression: function(expression) {
            // Split on spaces and create object of important pieces
            var parts = expression.split(' '),
                partsObject = {
                    minute: this.parseRange(parts[0]),
                    hour: this.parseRange(parts[1]),
                    day: this.parseRange(parts[2]),
                    month: this.parseRange(parts[3]),
                    weekday: this.parseRange(parts[4])
                };
            
            return partsObject;
        },
        
        /**
         * Parses value range (1-12 or 1,2,3,4 or 1-12/2)
         * @param string value
         * @returns object|array|int
         */
        parseRange: function(value) {
            value = String(value);
            
            var isRange = value.indexOf('/') !== -1 && value.indexOf('-') !== -1,
                commaDelimited = value.split(','),
                dashDelimited = value.split('-');
        
            if(isRange) {
                var values = value.split('/'),
                    range = values[0].split('-');
                return {
                    start: range[0],
                    end: range[1],
                    interval: values[1]
                };
            } else if(dashDelimited.length > 1) {
                return _.range(dashDelimited[0], dashDelimited[1]);
            } else {
                return commaDelimited.length === 1 ? commaDelimited[0] : commaDelimited;
            }
        },
        
        /**
         * Handles cron job type change
         * Shows correct form for cron job type and hides others
         * @returns {undefined}
         */
        handleScheduledTaskTypeChange: function() {
            var self = ScheduledTask,
                cronJobType = self.getCronJobType();
            
            self.$cronJobTypeForms.hide();
            $("#cron-" + cronJobType + "-form").show();
        },
          
        /**
         * Handles schedule input change (times, weekdays)
         * Clears date value if weekdays has a value
         * @param object e
         * @param boolean|null ignoreChange
         */
        handleScheduleInputChange: function(e, ignoreChange) {
            if(ignoreChange) { return; }
            var self = ScheduledTask;
        
            // Clear date if weekday has a value
            if(!_.isEmpty(self.form.$weekdays.val())) {
                self.form.$date.val('');
            }
            
            // Show description
            self.showHumanizedExpression();
        },
        
        /**
         * Handles date input change
         * Clears weedays value if date has value
         */
        handleDateInputChange: function() {
            var self = ScheduledTask,
                $this = $(this);
            
            // Clear weekday if date has a value
            if(!_.isEmpty($this.val())) {
                self.form.$weekdays.val([]).trigger('change', true);
            }
            
            // Show description
            self.showHumanizedExpression();
        },
        
        /**
         * Handles time format change
         */
        handleTimeFormatChange: function() {
            var self = ScheduledTask,
                $this = $(this);
        
            if($this.val() === 'once') {
                self.$cronJobTimeIntervalCols.hide();
                self.$cronJobTimeOnceCols.show();
            } else {
                self.$cronJobTimeOnceCols.hide();
                self.$cronJobTimeIntervalCols.show();
            }
            
            // Show description
            self.showHumanizedExpression();
        },
        
        /**
         * Handles date format change
         */
        handleDateFormatChange: function() {
            var self = ScheduledTask,
                $this = $(this);
        
            if($this.val() === 'once') {
                self.$cronJobDateWeekdaysCols.hide();
                self.$cronJobDateOnceCols.show();
            } else {
                self.$cronJobDateOnceCols.hide();
                self.$cronJobDateWeekdaysCols.show();
            }
            
            // Show description
            self.showHumanizedExpression();
        },
        
        /**
         * Creates and shows humanized cron expression
         */
        showHumanizedExpression: function() {
            var self = this,
                timeFormat = this.$cronJobTimeFormat.val(),
                dateFormat = this.$cronJobDateFormat.val(),
                time = this.form.$time.val(),
                start = this.form.$start.val(),
                end = this.form.$end.val(),
                interval = this.form.$interval.val(),
                weekdays = this.form.$weekdays.val(),
                date = this.form.$date.val(),
                humanizedWeekdays = [],
                timesString = '';
        
            // Clear description container
            this.$frequencyDescriptionContainer.hide();
            
            // Days or date
            if(dateFormat === 'once') {
                if(_.isEmpty(date)) { return; }
                timesString += (moment(date).format('MMMM Do'));
            } else {
                if(_.isEmpty(weekdays)) {
                    timesString += 'Every day';
                } else {
                    // Get humanized weekdays
                    humanizedWeekdays = _.map(weekdays || [], function(weekday) {
                        return self.form.$weekdays.find('option[value="' + weekday + '"]').text();
                    });
                    
                    timesString += ('Every ' + Tss.Grammar.joinArray(humanizedWeekdays));
                }
            }
            
            // Time(s)
            if(timeFormat === 'once') {
                if(!time) { return; }
                timesString += (' at ' + this.form.$time.find('option[value="' + time + '"]').text());
            } else {
                if(!start || !end || !interval) { return; }
                var humanizedStart = this.form.$start.find('option[value="' + start + '"]').text(),
                    humanizedEnd = this.form.$end.find('option[value="' + end + '"]').text(),
                    hourStr = interval == 1 ? 'hour' : interval + ' hours';
                timesString += (' every ' + hourStr + ' starting at ' + humanizedStart + ' and ending at ' + humanizedEnd);
            }
            
            // Set description container text
            this.$frequencyDescriptionContainer.show();
            this.$frequencyDescriptionContainer.find('span').text(timesString);
        },
        
        /**
         * Handles rel="toggle-active" click
         */
        handleToggleActiveClick: function() {
            var self = ScheduledTask,
                mappedCronJobs = _.indexBy(self.cronJobs, 'cronJobId'),
                $this = $(this),
                cronJobId = String($this.data('id')),
                cronJob = mappedCronJobs[cronJobId],
                options = {
                    url: self.config.api.activate,
                    data: {
                        id: cronJobId,
                        type: 'CronJob',
                        active: cronJob.active == 1 ? 0 : 1
                    }
                };
                
            // Make request
            $.ajax(options)
                .done(function(data) {
                    cronJob = _.merge(cronJob, data.results.obj);
                    self.cronJobs = _.values(mappedCronJobs);
                    self.renderRow(cronJobId, cronJob);
                });
        },
        
        /**
         * Handles rel="toggle-paused" click
         */
        handleTogglePausedClick: function() {
            var self = ScheduledTask,
                mappedCronJobs = _.indexBy(self.cronJobs, 'cronJobId'),
                $this = $(this),
                cronJobId = String($this.data('id')),
                cronJob = mappedCronJobs[cronJobId],
                options = {
                    type: 'PUT',
                    url: self.config.api.pause + cronJobId,
                    data: {
                        id: cronJobId,
                        type: 'CronJob',
                        paused: cronJob.paused == 1 ? 0 : 1
                    }
                };

            // Make request
            $.ajax(options)
                .done(function(data) {
                    cronJob = _.merge(cronJob, data.results.cron_job);
                    self.cronJobs = _.values(mappedCronJobs);
                    self.renderRow(cronJobId, cronJob);
                });
        },
                
        handleToggleShowInactiveClick: function() {
            var self = ScheduledTask,
                $this = $(this),
                $icon = $this.find('i'),
                $span = $this.find('span');
            
            $icon.attr('class', 'icon-eye-' + (self.config.showInactive ? 'open' : 'close'));
            $span.text((self.config.showInactive ? 'Show' : 'Hide') + ' inactive');
            self.config.showInactive = !self.config.showInactive;
            
            // Update filtering
            self.updateTableFiltering();
        },
                
        /**
         * Sets list bindings
         */
        setListBindings: function() {
            this.$table.on('click', '[rel="toggle-active"]', this.handleToggleActiveClick);
            this.$table.on('click', '[rel="toggle-paused"]', this.handleTogglePausedClick);
            this.$toggleShowInactive.on('click', this.handleToggleShowInactiveClick);
        },
        
        /**
         * Sets bindings for add/edit/dup view
         */
        setAddEditBindings: function() {
            this.$cronJobTimeOnceCols.hide();
            this.$cronJobTimeIntervalCols.hide();
            this.$cronJobTypeForms.hide();
            
            this.$cronJobType.on('change.tss.cron-job.type', this.handleScheduledTaskTypeChange).change();
            this.form.$time.on('change.tss.cron-job.time', this.handleScheduleInputChange);
            this.form.$start.on('change.tss.cron-job.time', this.handleScheduleInputChange);
            this.form.$end.on('change.tss.cron-job.time', this.handleScheduleInputChange);
            this.form.$interval.on('change.tss.cron-job.time', this.handleScheduleInputChange);
            this.form.$weekdays.on('change.tss.cron-job.weekday', this.handleScheduleInputChange);
            this.form.$date.on('change.tss.cron-job.date', this.handleDateInputChange);
            this.$cronJobTimeFormat.on('change.tss.cron-job.time.format', this.handleTimeFormatChange).change();
            this.$cronJobDateFormat.on('change.tss.cron-job.date.format', this.handleDateFormatChange).change();
                        
            // Form submit
            this.$form.submit(onSubmit(this.config.api.postPut, function (data, form) {
                if (data.success) {
                    window.location.assign("/setup/scheduled-tasks/");
                } else {
                    handlePost(data, undefined, undefined, form);
                }
            }, this.isEdit ? 'PUT' : 'POST', true));
        }
        
    };
    
    window.Tss = window.Tss || {};
    window.Tss.ScheduledTask = ScheduledTask;
    
})(jQuery, window);

(function($, window) { "use strict";
    var Schools = {
        gradeLevelApi: '/api/v1/grade-levels',

        initAddEdit: function(action, id) {
            Tss.Types.initAddEdit("School", "long_name", action, id, {
                submitCallback: _.bind(this.onAfterSave, this),
            });
        },

        onAfterSave: async function(data, form) {
            var self = this;
            var school = _.get(data, 'results.school');
            var schoolId = _.get(school, 'school_id');

            await Api.post(`/session/set/`, {
                school_id: schoolId
            });

            // if has min_level and max_level
            // get or create each one
            if (school.min_grade_number && school.max_grade_number) {
                const response = await Api.get(self.gradeLevelApi, {
                    school_ids: schoolId,
                });
                const allGradeLevels = _.get(response, 'results.grade_levels');
                const minGradeNumber = parseInt(school.min_grade_number);
                const maxGradeNumber = parseInt(school.max_grade_number);

                for (let i = minGradeNumber; i <= maxGradeNumber; i++) {
                    var existingGradeLevel = _.filter(allGradeLevels, x => x.order_key == i);

                    await self.createOrUpdateGradeLevel(schoolId, i, existingGradeLevel);
                }

                _.chain(allGradeLevels)
                    .filter(x => x.order_key < minGradeNumber || x.order_key > maxGradeNumber)
                    .each(async x => await self.deactivateGradeLevel(x))
                    .value();
            }

            // on add or duplicate: go set up term-bins next
            if (Tss.Types.config.action != 'edit') {
                window.location = '/setup/school#/setup/configure/term-bins';
            } else {
                Tss.Types.redirectToReferrer();
            }
        },

        createOrUpdateGradeLevel: async function(schoolId, gradeNumber, existingGradeLevel) {
            var self = this;
            var gradeLevelId = await self.findActiveOrActivateInactive(existingGradeLevel, self.gradeLevelApi, 'grade_level_id');

            if (!gradeLevelId) {
                var gradeLevelData = {
                    school_id: schoolId,
                    order_key: gradeNumber,
                    grade_level_name: Tss.Number.toOrdinal(gradeNumber),
                };

                const response = await Api.post(self.gradeLevelApi, gradeLevelData);
                gradeLevelId = _.get(response, 'results.grade_level.grade_level_id');
            }

            return gradeLevelId;
        },

        findActiveOrActivateInactive: async function(records, api, idCol) {
            var self = this;
            var activeRecords = _.filter(records, x => x.active == 1);

            if (activeRecords.length) {
                return activeRecords[0][idCol];
            }

            if (records.length) {
                var id = records[0][idCol];

                await Api.put(`${api}/${id}`, { active: 1 });

                return id;
            }

            return null;
        },

        deactivateGradeLevel: async function(gradeLevel) {
            var self = this;

            await Api.put(`${self.gradeLevelApi}/${gradeLevel.grade_level_id}`, { active: 0 });
        },
    };

    window.Tss = window.Tss || {};
    window.Tss.Schools = Schools;

})(jQuery, window);

(function ($, window, undefined) {"use strict";

    let StudentGroups = {
        userId: null,
        schoolId: null,
        initialId: null,
        mySubscriptions: {},
        allStudentGroups: {},
        allSubscriptions: {},
        allStars: {},
        allSubscribedAndStarredUsers: {},
        allUsers: [],
        allUserGroups: [],
        keyedUserGroups: {},

        init: function(opts) {
            this.userId = opts.userId;
            this.schoolId = opts.schoolId;
            this.initialId = opts.initialId;
            this.allStudentGroups = opts.allStudentGroups;

            this.setBindings();
            this.getMySubscriptions().then(this.setMySubscriptions);
        },

        getStudentGroupObject: function(elem) {
            var studentGroupId = $(elem).closest('[data-student-group-id]').data('studentGroupId');
            var studentGroupObj = this.allStudentGroups[studentGroupId];

            return studentGroupObj;
        },

        setBindings: function() {
            var self = this;

            $('.student-group-subscriptions-popover')
                .tssPopover({
                    onAfterClick: StudentGroups.openPopover
                });

            $('body').off('click', '.removeParent').on('click', '.removeParent', removeParent);

            $('select[name*="student_id"]').bind('studentChange', StudentGroups.filterPage);

            $('.ui-icon-info').each(function() { $(this).tipsy({opacity: 1, gravity: 'w', html: true, className: 'white left'}) });

            $('.showSave').click(function() {
                $('.savePanel').slideToggle('slow');
            });

            $('.download').click(function(e) {
                var studentIds = $('.result:visible').map(function(i, v) { return v.id.replace('result_', ''); }).get();

                return genericDownload('/ps/export/students/', null, {student_id: studentIds})(e);
            })

            $('body').on('click', '.editGroup', function(e) {
                stopPropagation(e);
                var link = $(this);
                var dynamicGroup = StudentGroups.getStudentGroupObject(link);
                var filter = dynamicGroup.filter || '';
                var isPublic = dynamicGroup['public'] != 0;
                var hasSchool = !_.isNull(dynamicGroup.school_id);
                var groupName = dynamicGroup.group_name;
                var filterParts = filter.split('&');
                var container = $('.filterContainer');

                window.filteringOff = true;
                var inputs = clearFilters();

                $.each(filterParts, function(i, v) {
                    var keyAndValue = v.split('='),
                        key = keyAndValue[0],
                        value = keyAndValue[1];

                    if (value && value.length && value[0] == '[') {
                        value = JSON.parse(value);
                    }

                    fillInForm(key, value, container, true);
                });

                $.each(dynamicGroup, function(k, v) { fillInForm(k, v, '#main-form', true); });

                $('select[name="share"]').trigger('refresh-options');

                $('.editLabel').html(' (' + groupName + ')');
                $('#submit').attr('value', 'Update');
                $('#saveCopy').show();

                window.filteringOff = false;
                inputs.first().change();
            });

            $('#saveCopy').click(function(e) {
                stopPropagation(e);
                $('#main-form input[name="created_by_id"]').val(StudentGroups.userId);
                $('#main-form input[name="student_group_id"]').val('');
                $('#main-form').submit();
            });
            $('.dynamicGroup a').tipsy({gravity: 'w', opacity: 1, title: StudentGroups.showDynamicTooltip, html: true, className: 'white'});

            $('.accordion').accordion({
                collapsible: true,
                autoHeight: false
            });

            $('.sub-accordion').accordion({
                collapsible: true,
                active: false, // start collapsed
                autoHeight: false
            });

            $('#main-form').submit(onSubmit('/studentgroup/save/?redirect=0', (function (data) {
                let errors = _.get(data, 'errors');
                if (!_.isEmpty(errors)) {
                    _.each(errors, (error) => Growl.error({ message: error }));
                } else {
                    Growl.success({ message: 'You have successfully saved the group.' });
                    fetch("/totango/saveStudentGroup/Analysis")
                    // if creating a new group, also create star and subscriptions
                    if ($('#main-form input[name="student_group_id"]').val() == '') {
                        let results = _.get(data, 'results');
                        let studentGroup = _.get(results, 'obj');
                        let studentGroupId = _.get(studentGroup, 'student_group_id');
                        let newRowHTML = _.get(results, 'row');
                        if (studentGroupId) {
                            self.allStudentGroups[studentGroupId] = studentGroup; // add to cache

                            return StudentGroups.starAndSubscribeToNewGroup(newRowHTML, studentGroupId);
                        }
                    }
                }
            })));

            $('body').bind('saved', function(e, data) {
                if (!data.results.row) return;

                var row = $(data.results.row),
                    id = row.attr('id');

                if (!$('#' + id).length) {
                    $('.existingFilters').prepend(row);
                    $('#' + id + ' a').tipsy({gravity: 'w', opacity: 1, title: StudentGroups.showDynamicTooltip, html: true});
                }

                // click the group name so it loads the students and filters
                $('#' + id + ' .editGroup a').click();
            });

            $('input[name="date"]').change(StudentGroups.filterPage);
            var input = $('input[name], select', $('.filterContainer')).change(StudentGroups.filterPage).first();

            if (StudentGroups.initialId) {
                $('#student_group_' + StudentGroups.initialId + ' a').click();
                $('.accordion:eq(1)').accordion('option', 'active', 'false');
            } else {
                $('.accordion:not(:last)').accordion('option', 'active', 'false');
                input.change();
            }

            $('.filterColumn').css('width', $('.filterColumn').width() + 6);

            $('body').on('click', '.add-star', function(e) {
                stopPropagation(e);

                let button = $(this);
                let dynamicGroup = StudentGroups.getStudentGroupObject(button);
                let studentGroupId = dynamicGroup.student_group_id;

                let withSubscriptions = true;
                StudentGroups.addStar(studentGroupId, withSubscriptions);
            });

            $('body').on('click', '.remove-star', async function(e) {
                stopPropagation(e);

                let button = $(this);
                let dynamicGroup = StudentGroups.getStudentGroupObject(button);
                let studentGroupId = dynamicGroup.student_group_id;
                let myStarId = button.data('starId');

                button.find('.icon-star').removeClass('icon-star').addClass('icon-star-empty');
                button.removeClass('remove-star').addClass('add-star');

                $.ajax({
                    method: 'PUT',
                    url: `/api/v1/student-group-stars/${myStarId}`,
                    data: { active: 0 }
                })

                // default to unsubscribed when unstarring
                let row = $(`#student_group_${studentGroupId}`);
                let popover = row.find('.student-group-subscriptions-popover');
                // load contents to get data but don't open
                await StudentGroups.loadPopoverContents(popover, false);
                let activating = 0;
                StudentGroups.createOrUpdateSubscription(studentGroupId, activating);
            });

            $('body').on('click', '.deactivate-group', (function (e) {
                stopPropagation(e);

                let link = $(this);
                let row = link.parents('.dynamicGroup');
                let dynamicGroup = StudentGroups.getStudentGroupObject(link);
                let studentGroupId = dynamicGroup.student_group_id;
                let putData = { active: 0 };
                let starredAndSubscribed = StudentGroups.allSubscribedAndStarredUsers[studentGroupId];
                let starredAndSubscribedCount = starredAndSubscribed.length;
                let subscribedUsers = StudentGroups.allSubscriptions[studentGroupId];
                let subscribedUsersCount = subscribedUsers.length;
                let confirmation = null;

                if (starredAndSubscribedCount) {
                    let confirmationMessage = `Are you sure? This will remove the group from all dropdown menus for everyone, and ${subscribedUsersCount} user(s) will stop receiving notifications about the members of this group. To remove it for just yourself, unstar it.`
                    confirmation = confirm(confirmationMessage);
                }

                // if no one else is starred/subscribed, or you've confirmed you don't care that they are, then go ahead with the deactivate
                if (!starredAndSubscribedCount || confirmation) {
                    return $.ajax({
                        url: `/api/v1/student-groups/${studentGroupId}`,
                        type: 'PUT',
                        data: putData,
                        success: (results) => {
                            // this group won't be loaded on the next page load, but for now just hide it
                            row.hide();
                        }
                    });
                }
            }));

            $('body')
                .on('click', '.share-to-popover .share-cancel', StudentGroups.cancelShare)
                .on('click', '.share-to-popover .share-submit', StudentGroups.submitShare)
                .on('click', '.share-to-popover .share-unshare', StudentGroups.submitUnshare)
                .on('click', '.share-to-popover .share-unshare-all', StudentGroups.submitUnshareAll)
                .on('keyup', '.share-to-popover [name="message"]', StudentGroups.countCharsLeft);

            // select users/groups to share to and create notification message
            $('body').on('click', '.share-more', (function (e) {
                stopPropagation(e);
                $('.subscription-options').hide();
                $('.share-options').show("slide", { direction: "right" }, 250);
            }));
        },

        cancelShare: function() {
            $('.share-options').hide();
            $('.subscription-options').show("slide", { direction: "left" }, 250);
        },

        setupMaxCharsAllowed: function() {
            var maxLength = $('.share-to-popover [name="message"]').attr('maxlength');
            $('.share-to-popover .chars-left').text(maxLength);
            $('.share-to-popover .chars-allowed').text(maxLength);
        },

        countCharsLeft: function(event) {
            var length = $(this).val().length;
            var left = $(this).attr('maxlength') - length;
            $('.share-to-popover .chars-left').text(left);
            if (left == 0) {
                $('.share-to-popover .chars-left').addClass('text-red');
            } else {
                $('.share-to-popover .chars-left').removeClass('text-red');
            }
        },

        submitUnshare: function() {
            let $this = $(this);
            let parentLi = $this.parents('li').first();
            let subscriptionId = parentLi.data('subscription_id');
            let starId = parentLi.data('star_id');
            let userId = parentLi.data('user_id');
            let studentGroupId = $this.parents('.share-options').data('student_group_id');
            let userName = parentLi.find('.user-name').text();

            let unstarPromise = starId ? $.ajax({
                method: 'PUT',
                url: `/api/v1/student-group-stars/${starId}`,
                data: { active: 0 }
            }) : $.when();
            let unsubscribePromise = subscriptionId ? $.post(
                `/api/v1/subscriptions`,
                { active: 0, subscription_id: subscriptionId }
            ) : $.when();

            return Promise.all([unstarPromise, unsubscribePromise])
                .then(_.partial(StudentGroups.displaySuccessUnshareMessage, userName))
                .then(_.partial(StudentGroups.removeFromSharedToList, studentGroupId, userId, parentLi))
                .catch(StudentGroups.displayUnshareErrorMessage);
        },

        submitUnshareAll: function() {
            let $this = $(this);
            let studentGroupId = $this.parents('.share-options').data('student_group_id');
            let subscriptionIds = [];
            let starIds = [];
            let userIds = [];

            $this.parents('.share-options').find('.shared-to-list li')
                .each(function() {
                    let subscriptionId = $(this).data('subscription_id');
                    let starId = $(this).data('star_id');
                    let userId = $(this).data('user_id');
                    subscriptionIds.push(subscriptionId);
                    starIds.push(starId);
                    userIds.push(userId);
                });

            let bulkSubscriptionData = _.chain(subscriptionIds)
                .compact()
                .map(id => {
                    return { subscription_id: id, active: 0 }
                })
                .value();

            let bulkStarData = _.chain(starIds)
                .compact()
                .map(id => {
                    return { student_group_star_id: id, active: 0 }
                })
                .value();

            let unsubscribePromise = subscriptionIds.length ? $.ajax({
                method: 'PUT',
                url: `/api/v1/bulk/subscriptions`,
                data: { bulk_data: bulkSubscriptionData }
            }) : $.when();

            let unstarPromise = subscriptionIds.length ? $.ajax({
                method: 'PUT',
                url: `/api/v1/bulk/student-group-stars`,
                data: { bulk_data: bulkStarData }
            }) : $.when();

            return Promise.all([unstarPromise, unsubscribePromise])
                .then(StudentGroups.displaySuccessUnshareAllMessage)
                .then(_.partial(StudentGroups.removeAllFromSharedToList, studentGroupId, userIds))
                .catch(StudentGroups.displayUnshareErrorMessage);
        },

        // remove from list in UI and from stored list so it won't be there
        // the next time you open the popover
        removeFromSharedToList: function(studentGroupId, userId, listItem) {
            let row = $(`#student_group_${studentGroupId}`);
            listItem.remove();
            StudentGroups.allSubscribedAndStarredUsers[studentGroupId] = _.filter(StudentGroups.allSubscribedAndStarredUsers[studentGroupId], (function (user) {
                return user.user_id != userId
            }));

            let newCount = StudentGroups.allSubscribedAndStarredUsers[studentGroupId].length;
            StudentGroups.updateSharedToCount(studentGroupId, newCount);
        },

        // remove from list in UI and from stored list so they won't be there
        // the next time you open the popover``
        removeAllFromSharedToList: function(studentGroupId, userIds) {
            let row = $(`#student_group_${studentGroupId}`);
            let listItems = row.find('.shared-to-list li');
            listItems.remove();
            row.find('.shared-to-list').hide();
            userIds = _.map(userIds, _.toString); //otherwise 2 won't match "2"
            StudentGroups.allSubscribedAndStarredUsers[studentGroupId] = _.filter(StudentGroups.allSubscribedAndStarredUsers[studentGroupId], (function (user) {
                return !_.includes(userIds, user.user_id);
            }));

            let newCount = StudentGroups.allSubscribedAndStarredUsers[studentGroupId].length;
            StudentGroups.updateSharedToCount(studentGroupId, newCount);
        },

        // update shared user count so it will be accurate if user clicks Cancel
        updateSharedToCount: function(studentGroupId, newCount) {
            let row = $(`#student_group_${studentGroupId}`);
            row.find('.share-more').text(`Share (${newCount})`);
        },

        submitShare: async function() {
            $(this).html('Sharing...').prop('disabled', true);
            let container = $(this).parents('.share-options');
            let shareToErrorAlert = container.find('.share-error');
            let shareTo = container.find('[name="shareto"]').val();
            let message = container.find('[name="message"]').val();
            let details = container.siblings('.subscription-options').find('.student-group-subscription-details');
            let studentGroupObj = StudentGroups.getStudentGroupObject(details);
            let studentGroupId = studentGroupObj.student_group_id;
            let inputs = details.find('.subscription-methods input');
            let subscriptionMethods = {};
            _.each(inputs, (input) => {
                let thisMethod = input.name;
                let val = input.checked == true ? 1 : 0;
                subscriptionMethods[thisMethod] = val;
            });

            // verify input
            shareToErrorAlert.hide();
            if (!shareTo || !shareTo.length) {
               shareToErrorAlert.show();
               $(this).html('Share').prop('disabled', false);
               return;
            }

            let userIds = _.chain(shareTo)
               .filter(val => _.startsWith(val, 'user.'))
               .map(val => _.replace(val, 'user.', ''))
               .value();

            let userGroupIds = _.chain(shareTo)
               .filter(val => _.startsWith(val, 'user_group.'))
               .map(val => _.replace(val, 'user_group.', ''))
               .value();

            let userGroupUserIds = StudentGroups.getAllUserIdsForUserGroupIds(userGroupIds);
            let subscribedUserIds = _.map(StudentGroups.allSubscribedAndStarredUsers[studentGroupId], 'user_id');
            let allUserIdsToShareTo = _.chain(_.merge(userIds, userGroupUserIds))
                .filter(userId => !_.includes(subscribedUserIds, userId))
                .uniq()
                .value();

            let params = {student_group_id: studentGroupId};
            let parameters = JSON.stringify(params);
            let bulkSubscriptionData = _.chain(allUserIdsToShareTo)
                .map(id => {
                    return _.extend({}, subscriptionMethods, {
                        user_id: id,
                        subscription_type_id: 1, // student group subscription
                        parameters: parameters,
                        active: 1
                    });
                })
                .value();
            let bulkStarData = _.chain(allUserIdsToShareTo)
                .map(id => {
                    return _.extend({}, {
                        user_id: id,
                        student_group_id: studentGroupId,
                        active: 1
                    });
                })
                .value();

            let bulkSubscribePromise = bulkSubscriptionData.length ? $.ajax({
                method: 'PUT',
                url: '/api/v1/bulk/subscriptions',
                data: { bulk_data: bulkSubscriptionData }
            }) : $.when();

            let bulkStarPromise = bulkStarData.length ? $.ajax({
                method: 'PUT',
                url: '/api/v1/bulk/student-group-stars',
                data: { bulk_data: bulkStarData }
            }) : $.when();

            return Promise.all([bulkSubscribePromise, bulkStarPromise])
                .then(_.partialRight(StudentGroups.addUsersToSharedList, studentGroupId))
                .then(StudentGroups.displaySuccessMessage)
                .then(_.partial(StudentGroups.sendNotifications, studentGroupId, allUserIdsToShareTo, message))
                .then(fetch("/totango/shareStudentGroup/Analysis"))
                .catch(StudentGroups.displayErrorMessage)
                .finally(StudentGroups.completeShare);
        },

        // filter to active users with active staff members with access
        // to this school, based on what we populated into allUsers
        filterUserGroups: async function(userGroups) {
            let groupsWithFilteredMemberships = _.mapValues(userGroups, (userGroup) => {
                userGroup.user_group_memberships = _.chain(userGroup.user_group_memberships)
                    .filter((membership) => _.find(StudentGroups.allUsers, { user_id: membership.user_id }))
                    .map('user_id')
                    .value();

                return userGroup;
            });

            return _.filter(groupsWithFilteredMemberships, (group) => group.user_group_memberships.length);
        },

        getAllUserIdsForUserGroupIds: function (userGroupIds) {
            let userGroups = _.pickBy(StudentGroups.keyedUserGroups, (val, key) => {
                return _.includes(userGroupIds, key);
            });

            return _.chain(userGroups)
                .map('user_group_memberships')
                .flatten()
                .uniq()
                .value();
        },

        addUsersToSharedList: async function(newData, studentGroupId) {
            let [newSubscriptionData, newStarData] = newData;

            // merge all subscriptions and stars into one list of users we care about
            // some will have subscription_id and star_id; some will have one or the other
            let newSubscriptions = _.chain(newSubscriptionData)
                .map('results.subscription')
                .filter('subscription_id') // if validation fails id will be null
                .value();
            let newStars = _.chain(newStarData)
                .map('results.student_group_star')
                .filter('student_group_star_id') // if validation fails id will be null
                .value();
            let combined = _.chain(_.union(newSubscriptions, newStars))
                .groupBy('user_id')
                .map(userObjects => {
                    let merged = _.merge.apply(_, userObjects);
                    let user = _.find(StudentGroups.allUsers, { user_id: merged.user_id });
                    return _.extend(user, { subscription_id: merged.subscription_id, star_id: merged.student_group_star_id });
                })
                .value();

            // update so they will be in the list the next time you open the popover
            StudentGroups.allSubscribedAndStarredUsers[studentGroupId] = _.union(StudentGroups.allSubscribedAndStarredUsers[studentGroupId], combined);

            // pass along count of newly shared users
            return combined.length;
        },

        sendNotifications: async function(studentGroupId, userIds, message) {
            // e.g. if you tried to subscribe someone that was already in the shared list
            if (!userIds) {
                return;
            }

            // notification creation will fail without a message
            if (message == '') {
                message = 'A student group has been shared with you. Click here to check it out!';
            }

            // create notification
            let notificationResults = await $.post('/api/v1/notifications', {
                text: message,
                link: `/groups?student_group_id=${studentGroupId}`
            });

            let notificationId = _.get(notificationResults, 'results.notification.notification_id');
            let bulkData = _.map(userIds, (userId) => {
                return { notification_id: notificationId, user_id: userId }
            });

            // create notification users
            let notificationUsersResults = await $.post('/api/v1/bulk/notification-users', {
                bulk_data: bulkData
            });
        },

        completeShare: function() {
            // reset form elements and trigger click on share button to toggle popover
            $('.share-to-popover').find('select').trigger('clear');
            $('.share-to-popover').find('textarea').val('');
            $('.share-submit').html('Share').prop('disabled', false);
            $('.share-cancel').trigger('click'); // go back to first "page"
            $('body').trigger('click'); // close popover
        },

        displayErrorMessage: function() {
            Growl.error({ message: 'We ran into an issue when trying to share this group. Please try again later.' });
        },

        displaySuccessMessage: function(newCount) {
            Growl.success({ message: 'You have successfully shared the group with ' + _.pluralize('user', newCount, true) + '.' });
        },

        displaySubscribeErrorMessage: function() {
            Growl.error({ message: 'We ran into an issue when trying to subscribe you to this group. Please try again later.' });
        },

        displaySubscribeSuccessMessage: function() {
            Growl.success({ message: 'You have successfully subscribed to this group.' });
        },

        displaySubscribeChangeErrorMessage: function(subscribing, studentGroupName) {
            let action = subscribing ? 'subscribe you to' : 'unsubscribe you from';
            Growl.error({ message: `We ran into an issue when trying to ${action} ${studentGroupName}. Please try again later.` });
        },

        displaySubscribeChangeSuccessMessage: function(subscribing, studentGroupName) {
            let message = subscribing
                ? `You have successfully subscribed to notifications for ${studentGroupName}.`
                : `You have successfully unsubscribed and will no longer receive notifications for ${studentGroupName}.`
            Growl.success({ message: message });
        },

        displaySuccessUnshareMessage: function(name) {
            Growl.success({ message: 'You have successfully unshared the group with ' + name + '.' });
        },

        displaySuccessUnshareAllMessage: function() {
            Growl.success({ message: 'You have successfully unshared the group with all users.' });
        },

        displayUnshareErrorMessage: function() {
             Growl.error({ message: 'We ran into an issue when trying to unshare this group. Please try again later.' });
        },

        getMySubscriptions: async function() {
            return await Api.get('/api/v1/me/subscriptions?subscription_type_id=1');
        },

        // pull out my student group subscriptions and key by student_group_id for lookup
        setMySubscriptions: function(data) {
            let mySubs = _.get(data, 'results.subscriptions');
            StudentGroups.mySubscriptions = _.keyBy(mySubs, (sub) => JSON.parse(sub.parameters).student_group_id);
        },

        getAllSubscriptionsAndStarsForGroup: async function(studentGroupId) {
            let data = await Api.get(`/api/v1/student-groups/${studentGroupId}?expand=subscriptions.user,student_group_stars.user`);
            let subscribedUsers = _.chain(_.get(data, 'results.student_group.subscriptions'))
                .map(subscription => _.extend(subscription.user, { subscription_id: subscription.subscription_id }))
                .filter(user => user.user_id != StudentGroups.userId)
                .orderBy(['active', 'last_name'])
                .value();

            let starredUsers = _.chain(_.get(data, 'results.student_group.student_group_stars'))
                .map(star => _.extend(star.user, { star_id: star.student_group_star_id, star_active: star.active }))
                .filter(user => user.user_id != StudentGroups.userId && user.star_active == 1)
                .orderBy(['active', 'last_name'])
                .value();

            // merge all subscriptions and stars into one list of users we care about
            // some will have subscription_id and star_id; some will have one or the other
            let combined = _.chain(_.union(subscribedUsers, starredUsers))
                .groupBy('user_id')
                .map(userObjects => _.merge.apply(_, userObjects))
                .value();

            StudentGroups.allSubscriptions[studentGroupId] = subscribedUsers;
            StudentGroups.allStars[studentGroupId] = starredUsers;
            StudentGroups.allSubscribedAndStarredUsers[studentGroupId] = combined;

            return combined || [];
        },

        loadUsersAndUserGroups: async function() {
            // Get all users who have access to the school you're looking at groups for.
            // This will prevent errors on trying to share to users who don't have access
            // to the school. Still isn't foolproof because orm/StudentGroupStar checks
            // permission based on student_group.school_id, not student_group.filter
            var userPromise = Api.get(`/api/v1/users?active=1&staff_only=1&allowed_school_ids=${StudentGroups.schoolId}`)
                .then(_.property('results.users'))
                .then(StudentGroups.filterActive);

            var userGroupPromise = Api.get('/api/v1/user-groups/all?expand=user_group_memberships')
                .then(_.property('results.user_groups'))
                .then(StudentGroups.filterActive);

            return Promise.all([userPromise, userGroupPromise])
                .then(StudentGroups.buildUserOptions);
        },

        filterActive: function(collection) {
            return _.filter(collection, { active: '1' });
        },

        buildUserOptions: async function(usersAndUserGroups) {
            let [users, userGroups] = usersAndUserGroups;
            // filter out schoolrunner users so people don't unknowingly subscribe us to things
            // if we need to, we can subscribe ourselves by logging in
            StudentGroups.allUsers = _.filter(users, (user) => !_.includes(user.email, '@schoolrunner.org'));
            StudentGroups.allUserGroups = await StudentGroups.filterUserGroups(userGroups);
            StudentGroups.keyedUserGroups = _.keyBy(StudentGroups.allUserGroups, 'user_group_id');
        },

        openPopover: async function(popover) {
            let popoverContents = popover.find('.student-group-subscriptions-popover-contents');
            // this is the also the handler for when you close the popover, so we
            // don't want any of the rendering etc to happen unless you're opening
            // onAfterClick gets called after open class is toggled so it will be
            // .open before it is actually 'open'
            if((popoverContents.length && popover.hasClass('open')) || !popoverContents.length) {
                return await StudentGroups.loadPopoverContents(popover);
            }
        },

        loadPopoverContents: async function(popover, forceOpen = true) {
            if (_.isEmpty(StudentGroups.mySubscriptions)) {
                let mySubscriptions = await StudentGroups.getMySubscriptions();
                StudentGroups.setMySubscriptions(mySubscriptions);
            }

            // could be hidden if we called manually instead of clicking,
            // which we do when adding a new group/star/sub to avoid race conditions
            if (!popover.hasClass('open') && forceOpen) {
                popover.addClass('open');
            }

            let studentGroup = StudentGroups.getStudentGroupObject(popover);
            let studentGroupId = studentGroup.student_group_id;
            let popoverContents = popover.find('.popover-contents');
            let mySubscription = _.get(StudentGroups.mySubscriptions, studentGroupId) || {};
            // sometimes true sometimes 1 sometimes '1' ???
            _.extend(mySubscription, {
                web: (_.get(mySubscription, 'web') == 1) ? 1 : 0,
                mobile: (_.get(mySubscription, 'mobile') == 1) ? 1 : 0,
                email: (_.get(mySubscription, 'email') == 1) ? 1 : 0
            });
            let subscriptionIsActive = _.get(mySubscription, 'active') == 1 ? true : false;
            let data = {
                user_id: StudentGroups.userId,
                student_group_name: studentGroup.group_name,
                student_group_id: studentGroupId,
                my_subscription: mySubscription,
                subscriptionIsActive: subscriptionIsActive,
                subscribed_users: StudentGroups.allSubscribedAndStarredUsers[studentGroupId],
                all_users: StudentGroups.allUsers,
                all_user_groups: StudentGroups.allUserGroups
            }

            // get all subscribed users for this group once, and then cache it
            if (StudentGroups.allSubscribedAndStarredUsers[studentGroupId] == undefined) {
                let subscribedAndStarredUsers = await StudentGroups.getAllSubscriptionsAndStarsForGroup(studentGroupId);
                data.subscribed_users = subscribedAndStarredUsers;
            }

            // get all users and user groups for the dropdown once, and then cache them
            if (!StudentGroups.allUsers.length || !Object.keys(StudentGroups.allUserGroups).length) {
                await StudentGroups.loadUsersAndUserGroups();
                data.all_users = StudentGroups.allUsers;
                data.all_user_groups = StudentGroups.allUserGroups;
            }

            // figure out how I'm subscribed so you can show me on the next 'page'
            // how I'm subscribing everyone else that I share to
            let mySubscriptionMethods = [];
            if (data.my_subscription && data.my_subscription.active) {
                if (data.my_subscription.web == 1) {
                    mySubscriptionMethods.push('web');
                }
                if (data.my_subscription.mobile == 1) {
                    mySubscriptionMethods.push('mobile');
                }
                if (data.my_subscription.email == 1) {
                    mySubscriptionMethods.push('email');
                }
            }

            let popoverHtml = Handlebars.renderTemplate('student-group-subscriptions-popover', data);
            popoverContents.html(popoverHtml);

            $('form[name="student_group_subscriptions"] input[type="checkbox"]').tssCheckbox({ onChange: _.debounce(StudentGroups.onSubscriptionChange, 1000) });

            StudentGroups.setSharingHelperText(studentGroupId, mySubscriptionMethods);

            Bindings.setupUIBindings(); // tss-mutliselect-search in modal isn't created yet

            // initally setup max chars on message,
            // determined by maxlength prop on textarea
            StudentGroups.setupMaxCharsAllowed();
        },

        starAndSubscribeToNewGroup: function(newRowHTML, studentGroupId) {
            let firstRow = $('.existingFilters .existing-group-row').first();
            firstRow.before(newRowHTML);

            // if existing groups are collapsed, open them
            if (firstRow.is(':hidden')) {
                firstRow.parents('.accordion').find('.ui-accordion-header').click();
            }

            // setup popover on new row`
            Bindings.setupUIBindings();
            $(`#student_group_${studentGroupId}`).find('.student-group-subscriptions-popover')
                .tssPopover({
                    onAfterClick: StudentGroups.openPopover
                });

            // will take of star, subscriptions, and coinciding UI changes
            StudentGroups.addStar(studentGroupId)
        },

        filterPage: function() {
            filter({}, '/studentgroup/dynamic/' + dateStringToServer($('input[name="date"]').val()));
        },

        showDynamicTooltip: function() {
            var link = $(this);
            var group = StudentGroups.getStudentGroupObject(link);
            var filter = group.filter || '';
            var filterState = filter.split('&');

            // hide these values if we don't have anything else selected in
            // those sections of the filters because they're irrelevant in
            // that case
            var defaultFilterMap = {
                behavior_period: { value: $('select[name="behavior_period"] option').first().attr('value') },
                grading_period: { value: $('select[name="grading_period"] option').first().attr('value') },
                absence_period: { value: $('select[name="absence_period"] option').first().attr('value') },
                class_absence_period: { value: $('select[name="class_absence_period"] option').first().attr('value') },
                incident_period: { value: $('select[name="incident_period"] option').first().attr('value') },
            };
            $.each(filterState, function(i, v) {
                var keyAndValue = v.split('=');
                var key = cleanKey(keyAndValue[0]);
                var input = $('.filterContainer [name="' + key + '"]').first();
                var sectionName = input.closest('.sub-accordion').attr('name');

                if (key in defaultFilterMap) { return true; } // continue

                // if we found another filter set under one of these sections,
                // then remove the 'period' key from the defaults map so that
                // it gets displayed in the tooltip properly.
                if (sectionName == 'behavior-filters') {
                    delete defaultFilterMap['behavior_period'];
                } else if (sectionName == 'academic-filters') {
                    delete defaultFilterMap['grading_period'];
                } else if (sectionName == 'absence-filters') {
                    delete defaultFilterMap['absence_period'];
                } else if (sectionName == 'class-absence-filters') {
                    delete defaultFilterMap['class_absence_period'];
                } else if (sectionName == 'incident-filters') {
                    delete defaultFilterMap['incident_period'];
                }
            });

            var filterParts = getFilterDisplay(filterState, defaultFilterMap);
            var str = filterParts.map(function(o) {
                return '<div class="text-left">' + o.label + ": " + o.value + '</div>';
            }).join('');

            return str;
        },

        addStar: async function(studentGroupId, withSubscriptions = true) {
            let row = $(`#student_group_${studentGroupId}`);
            let addStarButton = row.find('.add-star');

            // if there is no addStarButton then you already have a star
            if (!addStarButton.length) {
                return;
            }

            addStarButton.find('.icon-star-empty').removeClass('icon-star-empty').addClass('icon-star');
            addStarButton.removeClass('add-star').addClass('remove-star');

            $.post('/studentgroup/star/add/', { user_id: StudentGroups.userId, student_group_id: studentGroupId }, handlePost)
                .then((data) => {
                    // set star id on the button so we can remove it if you click again
                    let newRow = $(_.get(data, 'results.row'));
                    let rowButton = newRow.find('[data-star-id]');
                    let newStarId = rowButton.data('starId');
                    addStarButton.data('starId', newStarId);
                });

            if (withSubscriptions) {
                // default to subscribed when starring
                let activating = 1;
                let row = $(`#student_group_${studentGroupId}`);
                let popover = row.find('.student-group-subscriptions-popover');
                // close other open popovers and force this one open
                $('.tss-popover.open').removeClass('open');
                await StudentGroups.loadPopoverContents(popover);
                StudentGroups.createOrUpdateSubscription(studentGroupId, activating);
            }
        },

        // if starring, create or activate subscription
        // if unstarring, deactivate subscription
        //TODO: REFACTOR this is almost the same as onSubscriptionChange
        createOrUpdateSubscription: function(studentGroupId, activating = 1) {
            let row = $(`#student_group_${studentGroupId}`);
            let form = row.find('form[name="student_group_subscriptions"]');
            let inputSpans = form.find('.subscription-methods .tss-checkbox');
            let inputs = form.find('.subscription-methods input[type="checkbox"]');
            // "check" or "uncheck" the boxes but don't actually click them because otherwise
            // we will get 2 separate subscriptions created (1 on each click) instead of 1
            if (activating) {
                inputSpans.addClass('checked');
                inputs.prop('checked', true);
                StudentGroups.setSharingHelperText(studentGroupId, ['web', 'mobile', 'email']);
            } else {
                inputSpans.removeClass('checked');
                inputs.prop('checked', false);
                StudentGroups.setSharingHelperText(studentGroupId, []);
            }

            let details = row.find('.student-group-subscription-details');
            let studentGroupObj = StudentGroups.getStudentGroupObject(details);
            let studentGroupName = _.get(studentGroupObj, 'group_name', 'this group');
            let subscriptionObj = details.data('mySubscription');
            let subscriptionId = _.get(subscriptionObj, 'subscription_id');

            // if we're "deactivating" a subscription that we never had, don't do diddly
            if (!(_.get(subscriptionObj, 'subscription_id')) && !activating) {
                return;
            }

            let data = {};
            // if we didn't have any subscriptions before then create one now
            if (!(_.get(subscriptionObj, 'subscription_id')) && activating) {
                let params = {student_group_id: studentGroupObj.student_group_id};
                let parameters = JSON.stringify(params);
                data = {
                    user_id: StudentGroups.userId,
                    subscription_type_id: 1, // student group subscription
                    parameters: parameters,
                    web: 1,
                    mobile: 1,
                    email: 1
                };
            } else {
                // if we already had a subscription and we're starring, then reactivate it
                // if we already had a subscription and we're unstarring, then deactivate it
                if (activating == 1) {
                    data = {
                        active: 1,
                        web: 1,
                        mobile: 1,
                        email: 1
                    }
                } else {
                    data = {
                        active: 0
                    }
                }
            }

            // keep data attr in sync with changes otherwise subsequent
            // changes to this checkbox won't do anything
            // in case you reopen the popover before the save is finished
            _.extend(StudentGroups.mySubscriptions[studentGroupId], data);
            _.extend(subscriptionObj, data);

            // post will create or update based on whether subscription_id is included in data passed
            return $.post(`/api/v1/subscriptions`, subscriptionObj)
                .then((results) => {
                    StudentGroups.mySubscriptions[studentGroupId] = _.get(results, 'results.subscription');
                    details.data('mySubscription', _.get(results, 'results.subscription'));
                })
                .then(_.partial(StudentGroups.displaySubscribeChangeSuccessMessage, activating, studentGroupName))
                .fail(_.partial(StudentGroups.displaySubscribeChangeErrorMessage, activating, studentGroupName));
        },

        setSharingHelperText: function(studentGroupId, subscribedMethods) {
            let row = $(`#student_group_${studentGroupId}`);
            let subscriptionsText = `Star this group for:`;
            if (subscribedMethods.length) {
                subscriptionsText = `Star, set ${subscribedMethods.join(' + ')} notifications for:`;
            }
            row.find('.sharing-subscription-methods').text(subscriptionsText);
        },

        // when you check a box in the subscription popover, create or edit the subscription
        // if you're subscribing but you're not starred, then add a star
        onSubscriptionChange: function(isChecked) {
            let input = $(this).closest('input');
            let method = input.attr('name');
            let newValue = isChecked ? 1 : 0; //make sure we pass bool instead of true/false
            let details = $(this).parents('.student-group-subscription-details');
            let studentGroupObj = StudentGroups.getStudentGroupObject(details);
            let studentGroupId = studentGroupObj.student_group_id;
            let subscriptionObj = details.data('mySubscription');
            let subscriptionId = _.get(subscriptionObj, 'subscription_id', '');

            // if not actually changed, don't try to update!
            // need this because tssCheckbox calls toggle() >> onChange() on setup
            if (subscriptionObj[method] == newValue) {
                return;
            }

            let data = { subscription_id: subscriptionId };
            let inputs = details.find('.subscription-methods input');
            let checked = [];
            _.each(inputs, (input) => {
                let thisMethod = input.name;
                let val = input.checked == true ? 1 : 0;
                if (val) {
                    checked.push(thisMethod);
                }
                data[thisMethod] = val;
            });

            StudentGroups.setSharingHelperText(studentGroupId, checked);

            // if we didn't have any subscriptions before then create one now
            if (!(_.get(subscriptionObj, 'subscription_id')) && newValue) {
                let params = {student_group_id: studentGroupId};
                let parameters = JSON.stringify(params);
                _.extend(data, {
                    user_id: StudentGroups.userId,
                    subscription_type_id: 1, // student group subscription
                    parameters: parameters,
                });
            }

            // if anything is checked then make sure we're active and starred
            if (newValue == 1) {
                data.active = 1;
                let withSubscriptions = false;
                StudentGroups.addStar(studentGroupId, withSubscriptions)
            }

            // in case you reopen the popover before the save is finished
            _.extend(StudentGroups.mySubscriptions[studentGroupId], data);

            // will create or update; subscriptionId may be null
            $.post(`/api/v1/subscriptions`, data)
                .then((results) => {
                    // keep data attr in sync with changes otherwise subsequent
                    // changes to this checkbox won't do anything
                    StudentGroups.mySubscriptions[studentGroupId] = _.get(results, 'results.subscription');
                    details.data('mySubscription', _.get(results, 'results.subscription'));
                });
        },
    }

    window.Tss = window.Tss || {};
    window.Tss.StudentGroups = StudentGroups;

})(jQuery, window);

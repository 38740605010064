;(function ($, window, undefined) {"use strict";

    function manageGradingMethodologies(options) {

        var self = this,
            $container,
            $courseSelection,
            $gradingMethodologyDiv,
            $gradingMethodologySection,
            $gmTable,
            $gmHeaders,
            $title,
            $popovers,

            copyAllElements = {},
            copyOneElements = {},
            addOneElements = {},

            defaults = {
                courseId: null,
                selectors: {
                    copyAllActions: {
                        container: '.grading-methodologies-bulk-container',
                        termBinIds: 'select[name="copy_all_term_bin_ids"]',
                        destCourseIds: 'select[name="copy_all_course_ids"]',
                        submitButton: 'div[rel="submit-copy-all"]'
                    },
                    copyOneActions: {
                        container: '.grading-methodologies-bulk-container',
                        termBinId: 'select[name="copy_one_term_bin_id_from"]',
                        termBinName: 'select[name="copy_one_term_bin_id_from"] option:selected',
                        destTermBinIds: 'select[name="copy_one_term_bin_ids_to"]',
                        submitButton: 'div[rel="submit-copy-one"]'
                    },
                    addOneActions: {
                        container: '.add-grading-methodology',
                        termBinId: 'select[name="add_one_term_bin_id"]',
                        submitButton: 'a[rel="submit-add-one"]'
                    },
                    container: '.manage-grading',
                    gradingMethodologyDiv: '.methodologies',
                    gmTable: 'table.grading-methodology',
                    titleRow: '.manage-methodologies',
                    popovers: '.icon-copy, .popover-contents, .actions'
                },
                schoolId: null,
                termId: null
            };

    /**
     * set up event handlers
     */
    function initBindings() {
        $courseSelection.on('change', $.proxy(handleCourseChange));
        $('[rel="gm-copy-replace"]').on('click', function (e) {
            copyAndReplaceOne($(this));
        });

        // Delete links -- delegated click event:
        $gradingMethodologyDiv.on('click', 'a.action-delete', handleDelete);

        //copy actions container bindings:
        copyAllElements.$container.on('click', 'h5:first-child', function () {
            if (copyAllElements.$container.hasClass('closed')) {
                copyAllElements.$container.css('margin-bottom', '0px');
            } else {
                copyAllElements.$container.css('margin-bottom', '280px');
                window.scrollBy(0,280);
            }
        });

        // copyAll actions bindings:
        copyAllElements.$submitButton.on('click', handleCopyAllSubmit);
        
        // copyOne actions bindings:
        copyOneElements.$submitButton.on('click', handleCopyOneSubmit);

        // addOne actions bindings:
        addOneElements.$submitButton.on('click', handleAddOneSubmit);
        
        $('[rel="gm-cancel"]').on('click', function (e) {
            closeTooltip($(this));
        });
        $popovers.on('click', $.proxy(function (e) {
            $popovers.css('outline', 'none');
            $popovers.css('-webkit-user-select', 'none');
        }));
    };

    /**
     * set jQuery elements from selectors
     */
    function locateElements() {
        $title = $(options.selectors.titleRow);
        $container = $(options.selectors.container);
        $courseSelection = $container.find('select');
        $gradingMethodologyDiv = $(options.selectors.gradingMethodologyDiv);
        $gradingMethodologySection = $gradingMethodologyDiv.find('section');
        $gmTable = $(options.selectors.gmTable);
        $gmHeaders = $gmTable.find('th');

        // copyAll actions elements:
        copyAllElements.$container = $(options.selectors.copyAllActions.container);
        copyAllElements = {
            '$container': copyAllElements.$container,
            '$termBinIds': copyAllElements.$container.find(options.selectors.copyAllActions.termBinIds),
            '$destCourseIds': copyAllElements.$container.find(options.selectors.copyAllActions.destCourseIds),
            '$submitButton': copyAllElements.$container.find(options.selectors.copyAllActions.submitButton)
        };

        // copyOne actions elements:
        copyOneElements.$container = $(options.selectors.copyOneActions.container);
        copyOneElements = {
            '$container': copyOneElements.$container,
            '$termBinId': copyOneElements.$container.find(options.selectors.copyOneActions.termBinId),
            '$termBinName': copyOneElements.$container.find(options.selectors.copyOneActions.termBinName),
            '$destTermBinIds': copyOneElements.$container.find(options.selectors.copyOneActions.destTermBinIds),
            '$submitButton': copyOneElements.$container.find(options.selectors.copyOneActions.submitButton)
        };

        //addOne actions elements:
        addOneElements.$container = $(options.selectors.addOneActions.container);
        addOneElements = {
            '$container': addOneElements.$container,
            '$termBinId': addOneElements.$container.find(options.selectors.addOneActions.termBinId),
            '$submitButton': addOneElements.$container.find(options.selectors.addOneActions.submitButton)
        };
        
        $popovers = $(options.selectors.popovers);
    };

    /**
     * Adds alert
     */
    function addAlert(message, type) {
        $gradingMethodologyDiv.append(getStatusBox(null, message, type));
        $title.after(getStatusBox(null, message, type));
    };

    /**
     * Removes all alerts
     */
    function removeAlerts() {
        $gradingMethodologyDiv.find('.alert').remove();
        $title.siblings('.alert').remove();
    }

    /**
     * sets course dropdown based on provided course_id
     */
    function setCourseSelection() {
        $courseSelection.find('option[value=' + options.courseId +']').prop('selected', true);
        $courseSelection.change();
    };

    /**
     * opens GM section which is closed by default in the component
     */
    function openGradingMethodologySection() {
        $gradingMethodologySection.removeClass('closed');
    };

    /**
     * copies this methodology and replaces all selected term bins with it via api
     */
    function copyAndReplaceOne() {
        if($element) {
            var $popover = $element.closest('.tss-popover'),
                $select = $popover.find('select'),
                termBins = $select.val(),
                $thisTermBin = $element.closest('section').find('th'),
                termBinId = $thisTermBin.data('id');
            if($select) {
                termBins = termBins ? termBins.join(',') : null;
                var ajaxData = {
                    type: 'POST',
                    url:'/api/v1/grading-methodologies/copy-one/',
                    data: {
                        course_id: options.courseId,
                        term_id: options.termId,
                        term_bin_id: termBinId,
                        dest_term_bin_ids: termBins
                    }
                }
                copyOne(ajaxData);
            }
        }
    };

    function closeTooltip($element) {
        var $popover = $element.closest('.tss-popover');
        $popover.removeClass('open');
    };
    
    function copyOne(ajaxData) {
        $.ajax(ajaxData)
        .done(function(data) {
            location.reload();
        })
        .fail(function(jqXHR, textStatus, errorThrown) {
            //alerts
            var alert = _.get(jqXHR.responseJSON, 'errors[0]')
                || 'Error copying grading methodology';
            removeAlerts();
            addAlert(alert, 'error');
        });
    };

    /**
     * copyAll Change Copy to other Courses submit click.
     */
    function handleCopyAllSubmit(evt) {
        var msg,
            termBinIds = copyAllElements.$termBinIds.val(),
            destCourseIds = copyAllElements.$destCourseIds.val();

        evt.preventDefault();

        if (termBinIds && destCourseIds && !copyAllElements.$submitButton.data('processing')) {
            // Confirm action and go!
            msg = 'You are about to copy and replace ' + termBinIds.length + ' grading methodologies' +
                ' for ' + destCourseIds.length + ' courses. Are you sure?';
            showConfirmDialog('submit-copy-all', msg, function() {
                // Send the POST
                copyAllElements.$submitButton.data('processing', 1);
                $.ajax({
                    type: 'POST',
                    url: '/api/v1/grading-methodologies/copy-all',
                    data: {
                        course_id: options.courseId,
                        term_id: options.termId,
                        term_bin_ids: termBinIds.join(','),
                        dest_course_ids: destCourseIds.join(',')
                    }
                }).done(function(data) {
                    if (data.success) {
                        //alerts
                        removeAlerts();
                        addAlert(data.info[0], 'success');

                        setTimeout(function() {
                            // Fire off recalc:
                            recalculateGrades(termBinIds, destCourseIds, false, copyAllElements.$submitButton);
                        }, 1000);
                    }
                })
                .fail(function(jqXHR, textStatus, errorThrown) {
                    //alerts
                    var alert = _.get(jqXHR.responseJSON, 'errors[0]')
                        || 'Error copying grading methodologies';
                    removeAlerts();
                    addAlert(alert, 'error');
                })
                .always(function() {
                    copyAllElements.$submitButton.data('processing', 0);
                });
            });
        }
    };

    /**
     * pass term bin id to edit one view
     * TODO: this, but better
     */
    function handleAddOneSubmit(evt) {
        var termBinId = addOneElements.$termBinId.val(),
            courseId = options.courseId;

        if (termBinId && courseId) {
            var pathName = '/setup/configure/grading-methodologies/edit',
                courseParam = '?course_id=' + courseId,
                termBinParam = '&term_bin_id=' + termBinId,
                ref = '&ref=manage';

            window.location.href = pathName + courseParam + termBinParam + ref
        }

    }

    /**
     * copyOne copy from one term bin to one or multiple within the same course.
     */
    function handleCopyOneSubmit(evt) {
        var msg,
            termBinId = copyOneElements.$termBinId.val(),
            termBinName = $('.grading-methodologies-bulk-container select[name="copy_one_term_bin_id_from"] option:selected').text(),
            destTermBinIds = copyOneElements.$destTermBinIds.val(),
            termBinsForRecalc = destTermBinIds.concat(termBinId);

        evt.preventDefault();

        if (termBinId && destTermBinIds && !copyOneElements.$submitButton.data('processing')) {
            // Confirm action and go!
            msg = 'You are about to copy and replace grading methodologies from ' + termBinName +
                ' into ' + destTermBinIds.length + ' terms within this course. Are you sure?';

            showConfirmDialog('submit-copy-one', msg, function() {
                // Send the POST
                copyOneElements.$submitButton.data('processing', 1);
                $.ajax({
                    type: 'POST',
                    url: '/api/v1/grading-methodologies/copy-one',
                    data: {
                        course_id: options.courseId,
                        term_id: options.termId,
                        term_bin_id: termBinId,
                        dest_term_bin_ids: destTermBinIds.join(',')
                    }
                }).done(function(data) {
                    if (data.success) {
                        //alerts
                        removeAlerts();
                        addAlert(data.info[0], 'success');

                        setTimeout(function() {
                            // Fire off recalc:
                            recalculateGrades(termBinsForRecalc, [options.courseId], true, copyOneElements.$submitButton);
                        }, 1000);
                    }
                })
                .fail(function(jqXHR, textStatus, errorThrown) {
                    //alerts
                    var alert = _.get(jqXHR.responseJSON, 'errors[0]')
                        || 'Error copying grading methodology';
                    removeAlerts();
                    addAlert(alert, 'error');
                })
                .always(function() {
                    copyOneElements.$submitButton.data('processing', 0);
                });
            });
        }
    };

    /**
     * when course selection changes, change course_id in uri and reload
     * grading methodologies will render for the new course
     */
    function handleCourseChange(){
         var newCourse = $courseSelection.val();
         if (newCourse !== "") {
             var search = '?course_id=' + newCourse;
             if (options.courseId != newCourse) {
                  window.location.href = window.location.pathname + search;
             }
         }
    };

    /**
     * Click handler for delete buttons.
     */
    function handleDelete(evt) {
        var msg,
            $elem = $(this),
            $parent = $elem.closest('div.grading-methodology-tile'),
            termBinId = $elem.data('term-bin-id');

        evt.preventDefault();

        msg = 'Are you sure you want to deactivate this grading methodology?';
        showConfirmDialog('action-delete', msg, function() {
            $.ajax({
                type: 'POST',
                url: '/api/v1/grading-methodologies',
                data: {
                    _METHOD: 'DELETE',
                    course_ids: options.courseId,
                    term_id: options.termId,
                    term_bin_ids: termBinId
                }
            }).done(function(data) {
                if (data.success) {
                    //alerts
                    removeAlerts();
                    addAlert(data.info[0], 'success');

                    setTimeout(function() {
                        // Fire off recalc:
                        recalculateGrades([termBinId], [options.courseId], true);
                    }, 1000);

                } else {
                    //alerts
                    removeAlerts();
                    addAlert('Error deactivating grading methodology', 'error');
                }
            })
            .fail(function(jqXHR, textStatus, errorThrown) {
                //alerts
                var alert = _.get(jqXHR.responseJSON, 'errors[0]')
                    || 'Error deactivating grading methodology';
                removeAlerts();
                addAlert(alert, 'error');
            });
        });
    };

    /**
     * Fire off grade recalculate for the params provided.
     * If provided an element, replaces html content with spinner
     * and reprocessing copy, and manages 'processing' semaphore.
     * @param array termBinIds
     * @param array courseIds
     * @param bool reload
     * @param Element $elem (optional) Typically a button.
     */
    function recalculateGrades(termBinIds, courseIds, reload, $elem) {
        //alerts
        removeAlerts();
        addAlert('Recalculating grades... please wait', 'info');

        if ($elem) {
            $elem.data('processing', 1);
        }

        $.ajax({
            type: 'POST',
            url: '/api/v1/course-grades/calculate',
            data: {
                course_ids: courseIds.join(','),
                school_id: options.schoolId,
                term_bin_ids: termBinIds.join(',')
            }
        }).done(function(data) {
            if (data.success) {

                //alerts
                removeAlerts();
                addAlert('Grades recalculated for ' + termBinIds.length + ' terms and ' + courseIds.length + ' courses', 'success');

                window.setTimeout(function() {
                    // reload the page or remove the alerts
                    if (reload) {
                        location.reload();
                    } else {
                        removeAlerts();
                    }
                }, 3000);
            }
        })
        .fail(function(jqXHR, textStatus, errorThrown) {
            //alerts
            var alert = _.get(jqXHR.responseJSON, 'errors[0]')
                || 'Error recalculating grades';
            removeAlerts();
            addAlert(alert, 'error');
        })
        .always(function() {
            if ($elem) {
                $elem.data('processing', 0);
            }
        });
    };


    options = $.extend({}, defaults, options);

    locateElements();
    initBindings();
    setCourseSelection();
    openGradingMethodologySection();

 };

     window.Tss = window.Tss || {};
     window.Tss.manageGradingMethodologies = manageGradingMethodologies;

 })(jQuery, window);

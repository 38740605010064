;(function ($, window, document, undefined) {

    // Constructor:
    function Thumb(options) {
        
        // private properties:
        var $thumbnailDiv,
        
            // Default options:
            defaults = {
                dialog: {
                    height: "auto",
                    width: 397,
                    modal: true,
                    resizable: false,
                    draggable: false,
                    closeOnEscape: false,
                    dialogClass: "minimal-dialog"
                },
                selectors: {
                    pencilContainer: ".icon-container",
                    thumbnailDiv: ".thumbnail-div"
                }
            };
        
        //
        // private methods:
        //
        
        /**
         * Create a div to hold html for the dialog, set variable to jQuery object
         */
        function createAndLocateThumbDiv() {
            $('body').append('<div class="thumbnail-div padding"></div>');
            $thumbnailDiv = $(options.selectors.thumbnailDiv);
        };

        /**
         * Destroy dependencies from the dialog, remove newly created thumbnail div
         */
        function destroyThumbDiv() {
            $thumbnailDiv.dialog("destroy");
            $thumbnailDiv.remove();
            $thumbnailDiv = undefined;
        };

        /**
         * On click, create thumbnail div and load html
         */
        function handleEditClick() {
            //do not create additional divs on double click
            if (!$thumbnailDiv) {
                createAndLocateThumbDiv();
                
                // load html from photo/edit.php into newly created div
                $thumbnailDiv.tssLoad('/photo/edit/?id=' + options.recordId + '&type=' + options.type + '&title=' + options.title + '&tooltip=true');
            }
        };
        
        /**
         * Set up all events and handlers for this view.
         */
        function initBindings() {
            $(options.selectors.pencilContainer).on('click', handleEditClick);
            
            $(document).on("Tss.Thumb.Init", function(event) {
                // open new div into a dialog box
                options.dialog['open'] = function() {
                    window.editThumbnailPlugin.initAreaPlugin();
                };
                
                $thumbnailDiv.dialog(options.dialog);
            });

            $(document).on("Tss.Thumb.SaveComplete", function() {
                $thumbnailDiv.dialog("close");
                destroyThumbDiv();
            });
        };

        //
        // Process this logic in the constructor, following definitions.
        //
        if (options) {
            options = $.extend({}, defaults, options);
        }

        initBindings();
    };

    //
    // Public methods -- modify the prototype
    // myPlugin.prototype.methodName = function () {  }
    //

    // Expose this plugin in the Tss namespace
    window.Tss = window.Tss || {};
    window.Tss.Thumb = Thumb;
})(jQuery, window, document);

(function() {
    'use strict';

    var defaultOpts = {
        remove: false, // remove and recreate each time, don't just hide
        getData: function() { return {}; },
        getUrl: function() { return null; }, // override me
        settingName: null,
        getSettings: _.noop,
        totangoActionName: null,
        totangoModuleName:null,
        go,
    };

    function Modal(opts) {
        this.opts = _.extend({}, defaultOpts, opts);
        let prefix = 'click.tss.modal.' + this.opts.template.replace(/_-/g, '.');

        this.showModal = _.bind(showModal, this);
        this.closeModal = _.bind(closeModal, this);
        this.go = _.bind(this.opts.go, this);

        $('body')
            .off(prefix + '.show').on(prefix + '.show', this.opts.launchTarget, this.showModal)
            .off(prefix + '.close').on(prefix + '.close', '.' + this.opts.template + ' .close, #overlay-curtain', this.closeModal)
            .off(prefix + '.go').on(prefix + '.go', '.' + this.opts.template + ' .go:not(.disabled)', this.go);
    }

    function showModal(e) {
        if (e) {
            var launchTarget = $(e.target).closest(this.opts.launchTarget);

            e.preventDefault();

            if (launchTarget.attr('disabled') || launchTarget.hasClass('disabled')) {
                return;
            }
        }

        $('#overlay-curtain').removeClass('hide');

        if ($('.' + this.opts.template).length) {
            $('.' + this.opts.template).show();
        } else {
            var renderedTemplate = $(Handlebars.renderTemplate(this.opts.template, this.opts.getData()));

            // add modal
            $('body').append(renderedTemplate.addClass(this.opts.template + ' tss-modal'));

            Bindings.setupUIBindings();
            $('.' + this.opts.template).each(setupUI).trigger('TssModalShow');
        }

        // vertically center if there's not enough room to start it at 100px
        // from the top with 100px or more at the bottom. But don't start it any
        // higher than 10px from the top of the page.
        var top = 100;

        if (window.innerHeight < $('.' + this.opts.template).outerHeight() + (2 * top)) {
            top = (window.innerHeight - $('.' + this.opts.template).outerHeight()) / 2;
        }

        $('.' + this.opts.template).css({top: Math.max(top, 10)});
    }

    function closeModal(e) {
        $('#overlay-curtain').addClass('hide');
        $('.' + this.opts.template)[this.opts.remove ? 'remove' : 'hide']();
    }

    function go() {
        var self = this,
            template = $('.' + self.opts.template),
            settings = self.opts.getSettings(template),
            url = self.opts.getUrl(settings, template);

        // Close the previous print preview tab because if the
        // Chrome print preview is still open then window.open
        // just silently fails. This way we close the last tab
        // and our new tab successfully opens. Use setTimeout
        // because otherwise a weird race condition caused the
        // new tab to fail to open.
        if (self.oldWindow) {
            self.oldWindow.close();
        }

        if (self.opts.totangoActionName) fetch(`/totango/${self.opts.totangoActionName}/${self.opts.totangoModuleName}`)
        setTimeout(function() {
            self.oldWindow = window.open(url, "print_" + Date.now());
            
            if (self.opts.settingName) {
                var settingValue = JSON.stringify(settings),
                    data = {};
                
                data[self.opts.settingName] = settingValue;
                
                $.post('/settings/save', data);
            }
        }, 10);
        
        self.closeModal();
    }

    window.Tss = window.Tss || {};
    window.Tss.Modal = Modal;
}());

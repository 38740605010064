(function ($, window, undefined) {"use strict";
	$.fn.extend({
		tssExtend: function (instance) {
			var slice = Array.prototype.slice;
			return function (options) {
	            var value = this, method = $.type(options) === "string", args = arguments;
	            this.each(function () {
	                var data = $.data(this, instance.name);
	                if (method) {
	                	if (!data) {
                        	return $.error("Cannot call methods prior to initialization");
                    	}
	                    if (!$.isFunction(data[options])) {
	                        return $.error("No such method " + options);
	                    }
	                    value = data[options].apply(data, slice.call(args, 1));
	                } else {
	                    if (data) {
	                        return;
	                    } else {
	                        $.data(this, instance.name, new instance(this, options));
	                    }

	                }
	                return value;
	            });
	        };
		},
		tssOptions: function (namespace, element, defaults, options) {
			var data = $(element).data(), merged = $.extend({}, defaults || {}, ($.isPlainObject(options) ? options : {}));
			_.each(data, function (value, key) {
				var sliced = key.slice(namespace.length - 1), option = sliced.charAt(0).toLowerCase() + sliced.substr(1);
				if (!_.includes(key, _.camelCase(namespace)) || !defaults.hasOwnProperty(option)) {
					return;
				}
				merged[option] = value;
			});
			return merged;
		},
		tssSubmit: function (url, callback, type) {
			return this.each(function () {
				var form = $(this), request, options = { url: url };
				if (type) {
					options.type = type;
				}
				form.submit(function (event) {
					event.preventDefault();
					options.data = form.serialize();
					request = $.ajax(options);
					request.always(function (response, status) {
						var data = status === "error" ? $.parseJSON(response.responseText) : response;
						if ($.isFunction(callback)) {
							callback.call(form, data);
							return;
						}
						handlePost(data, undefined, undefined, form);
					});
				});
			});
		}
	});
})(jQuery, window);

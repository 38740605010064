Handlebars.registerHelper('ifCond', function(v1, operator, v2, options) {
    switch (operator) {
        case '==':
            return (v1 == v2) ? options.fn(this) : options.inverse(this);
            break;
        case '!=':
            return (v1 != v2) ? options.fn(this) : options.inverse(this);
            break;
        case '===':
            return (v1 === v2) ? options.fn(this) : options.inverse(this);
            break;
        case '!==':
            return (v1 !== v2) ? options.fn(this) : options.inverse(this);
            break;
        case '<':
            return (v1 < v2) ? options.fn(this) : options.inverse(this);
            break;
        case '<=':
            return (v1 <= v2) ? options.fn(this) : options.inverse(this);
            break;
        case '>':
            return (v1 > v2) ? options.fn(this) : options.inverse(this);
            break;
        case '>=':
            return (v1 >= v2) ? options.fn(this) : options.inverse(this);
            break;
        case '+':
            return (v1 + v2);
            break;
        case '-':
            return (v1 - v2);
            break;
        case '%':
            return (v1 % v2) === 0 ? options.fn(this) : options.inverse(this);
            break;
        case '&&':
            return (v1 && v2) ? options.fn(this) : options.inverse(this);
            break;
        case '||':
            return (v1 || v2) ? options.fn(this) : options.inverse(this);
            break;
        case 'includes':
            return v1.includes(v2) ? options.fn(this) : options.inverse(this);
            break;
        default:
            return options.inverse(this);
            break;
    }
});

Handlebars.registerHelper('add', function(arg1, arg2) {
    return arg1 + arg2;
});

Handlebars.registerHelper('subtract', function(arg1, arg2) {
    return arg1 - arg2;
});

Handlebars.registerHelper('slice', function(arr, start, end) {
    return arr.slice(start, end);
});

/**
 * Simple case:
 *   {{getArrayValue arr 5}}
 *
 * Multi-level map case:
 *   {{getArrayValue students 0=record.student_id 1='display_name'}}
 */
var getArrayValue = function(array, index) {
    if (_.isObject(index)) {
        var i,
            obj = index.hash,
            keys = Object.keys(obj),
            result = array;

        for (i = 0; i < keys.length; i++) {
            if (!result) break;

            result = result[obj[i]];
        }

        return result;
    }

    return array[index];
};
Handlebars.registerHelper('getArrayValue', getArrayValue);

Handlebars.registerHelper('addOptionIfNecessary', function(records, key, value) {
    var valueExists = _.find(records, record => record[key] == value);

    if (value === undefined || value === '' || value === null || valueExists) {
        return '';
    }

    return new Handlebars.SafeString(`<option value="${value}" selected>${value}</option>`);
});

Handlebars.registerHelper('countActive', function(array) {
    return _.filter(array, x => x.active == 1).length;
});

Handlebars.registerHelper('getStudentsAndConsequences',
        function(record, incident_students, incident_suspensions, students, incident_roles, suspension_types) {
    var incidentStudentIds = _.filter((record.incident_student_ids || '').split(',')),
        incidentSuspensionIds = _.filter((record.incident_suspension_ids || '').split(',')),
        incidentSuspensionsByIncidentStudentId = {},
        retval = [],
        roleMap = {},
        sep = '</td><td class="no-wrap">';

    _.each(incidentSuspensionIds, function(incidentSuspensionId) {
        var incidentSuspension = incident_suspensions[incidentSuspensionId];

        incidentSuspensionsByIncidentStudentId[incidentSuspension.incident_student_id] = incidentSuspension;
    });

    // for this incident, grab the list of incident_students
    // foreach incident_student
    // grab the role name, student name
    // look up the suspension info if there is one
    _.each(incidentStudentIds, function(incidentStudentId) {
        var incidentStudent = incident_students[incidentStudentId],
            roleName = incident_roles[incidentStudent.incident_role_id].name,
            studentName = students[incidentStudent.student_id].display_name,
            studentLink = '<a href="/student/' + incidentStudent.student_id + '" tooltip>' + studentName + '</a>',
            incidentSuspension = incidentSuspensionsByIncidentStudentId[incidentStudentId],
            suspensionName = '',
            suspensionTitle = '';

        if (incidentSuspension) {
            suspensionTitle = 'Starting on ' + Handlebars.helpers.getRowFullDisplayDate(incidentSuspension.start_date);
            suspensionName = '<span title="' + suspensionTitle + '">'
                + incidentSuspension.num_days
                + " " + suspension_types[incidentSuspension.suspension_type_id].name
                + '</span>';
        }

        roleMap[roleName] = roleMap[roleName] || [];
        roleMap[roleName].push(studentLink + sep + suspensionName);
    });

    var roles = Object.keys(roleMap).sort();
    _.each(roles, function(roleName) {
        retval.push(roleName + sep);
        retval = retval.concat(roleMap[roleName].sort());
    });

    return '<table><tr><td width="80%">' + retval.join('</td></tr><tr><td>') + '</td></tr></table>';
});

Handlebars.registerHelper('getStudentsAndConsequenceFilterValues',
        function(record, incident_suspensions, suspension_types) {
    var incidentSuspensionIds = _.filter((record.incident_suspension_ids || '').split(',')),
        retval = [];

    _.each(incidentSuspensionIds, function(incidentSuspensionId) {
        var incidentSuspension = incident_suspensions[incidentSuspensionId],
            suspensionTypeId = incidentSuspension.suspension_type_id,
            suspensionTypeName = suspension_types[suspensionTypeId].name,
            studentId = incidentSuspension.student_id;

        retval.push('"' + studentId + '"');
        retval.push('"' + suspensionTypeName + '"');
    });

    return retval.join(',');
});

Handlebars.registerHelper('getMinutesOutOfClass',
        function(record, incident_students) {
    var incidentStudentIds = _.filter((record.incident_student_ids || '').split(',')),
        retval = [];

    _.each(incidentStudentIds, function(incidentStudentId) {
        var incidentStudent = incident_students[incidentStudentId],
            studentId = incidentStudent.student_id;

        if (incidentStudent.minutes_out_of_class) {
            retval.push('"' + studentId + '"');
            retval.push('{"Minutes out of class":"' + incidentStudent.minutes_out_of_class + '"} ');
        }
    });

    return retval.join(',');
});

Handlebars.registerHelper('getQuestionInputTabIndex', function(colIndex, rowIndex) {
    //return tab index starting point
    return (1000 * (rowIndex + 1)) + colIndex;
});

Handlebars.registerHelper('getAnswerInputTabIndex', function(colIndex, rowIndex, type) {
    //return tab index starting point
    if(type == 'enrollment') {
        return (1000 * (3 + rowIndex)) + colIndex;
    } else {
        return (1000 * (3 + rowIndex + ManualAssessment.enrollment.length)) + colIndex;
    }
});

Handlebars.registerHelper('isStudentGroup', function(studentGroupId, options) {
    //return
    return TssForm.isStudentGroupId(studentGroupId) ? options.fn(this) : options.inverse(this);
});


//some static vars we'll need
Handlebars.Settings  = {};
Handlebars.Settings.lastSign = null;
Handlebars.Settings.lastVal = null;

Handlebars.registerHelper('getDashboardTemplateSignAndVal', function(rag, meta, index) {
    if(!rag[index]) {
        return;
    }

    var conf = rag[index],
        hasMax = conf.hasOwnProperty('max'),
        hasMin = conf.hasOwnProperty('min');

    if (hasMax) {
        Handlebars.Settings.lastVal = parseFloat(conf.max);
        Handlebars.Settings.lastSign = Handlebars.Settings.lastVal ? '&le;' : '='; // don't say &le; 0, it looks weird
    } else if (hasMin) {
        Handlebars.Settings.lastVal = parseFloat(conf.min);
        Handlebars.Settings.lastSign = '&ge;';
    } else if (Handlebars.Settings.lastSign == '&ge;') {
        Handlebars.Settings.lastSign = '&lt;';
    } else {
        Handlebars.Settings.lastSign = '&gt;';
    }

    return Handlebars.Settings.lastSign + ' ' + (Handlebars.Settings.lastVal || 0);
});

Handlebars.registerHelper('getAlertPrefix', function(type) {
    switch(type) {
        case 'error':
            return 'Oops!';
        case 'info':
            return 'Hey!';
        case 'warning':
            return 'Heads Up!';
    };
});

Handlebars.registerHelper('getAttachementUrl', function(object) {
    var objectClass = TssForm.getAttachmentClassFromObject(object),
        idKey = objectClass + '_attachment_id';

    return objectClass + '/attachment/' + object[idKey];
});

Handlebars.registerHelper('getAttachmentId', function(object) {
    var objectClass = TssForm.getAttachmentClassFromObject(object),
        idKey = objectClass + '_attachment_id';

    return object[idKey];
});

Handlebars.registerHelper('getAttachmentClass', function(object) {
    return _.capitalize(TssForm.getAttachmentClassFromObject(object)) + 'Attachment';
});

Handlebars.registerHelper('isAttachmentPreview', function(object, options) {
    var objectClass = TssForm.getAttachmentClassFromObject(object),
    idKey = objectClass + '_attachment_id';

    return _.isNull(object[idKey]) ? options.fn(this) : options.inverse(this);
});

Handlebars.registerHelper('notAttachmentPreview', function(object, options) {
    var objectClass = TssForm.getAttachmentClassFromObject(object),
    idKey = objectClass + '_attachment_id';

    return !_.isNull(object[idKey]) ? options.fn(this) : options.inverse(this);
});

Handlebars.registerHelper('getCollectionObjectProp', function(collection, objectKey, objectKeyVal, prop, options) {
    var findParams = {};
    findParams[objectKey] = objectKeyVal;
    var obj = _.find(collection, findParams);
    return obj ? obj[prop] : '';
});

function utcToTz(fromDate, tz) {
    return moment.tz(fromDate, 'UTC').tz(tz);
}

/**
 * Get a moment object or null if there's a valid date & (local) time or fall back
 * to the from_date on the record and convert it from UTC to the local tz.
 *
 * @param  string date
 * @param  string time
 * @param  string fromDate
 * @param  string tz
 * @return moment
 */
function getTimeToUse(date, time, fromDate, tz) {
    return date && _.isString(time)
        ? moment(`${date} ${time}`) // time fields are local time
        : (fromDate && tz
            ? utcToTz(fromDate, tz) // fromDates are in UTC
            : null);
}

function getDateTimeDisplay(date, time, fromDate, tz, dateFormat, timeFormat, defaultDate, defaultTime) {
    var dateStr = date
        ? moment(date).format(dateFormat)
        : (defaultDate || '');
    var timeToUse = getTimeToUse(date, time, fromDate, tz);
    var timeStr = timeToUse
        ? timeToUse.format(timeFormat)
        : (defaultTime || '');

    return `${dateStr} ${timeStr}`.trim();
}

Handlebars.registerHelper('getRowDisplayDate', function(date, time, fromDate, tz) {
    return getDateTimeDisplay(date, time, fromDate, tz, 'ddd, MMM Do', 'h:mma');
});

Handlebars.registerHelper('getRowFullDisplayDate', function(date) {
    return getDateTimeDisplay(date, null, null, null, 'dddd, MMMM Do, YYYY');
});

Handlebars.registerHelper('getRowSortDate', function(date) {
    return getDateTimeDisplay(date, null, null, null, 'YYYYMMDD', null, '00000000');
});

Handlebars.registerHelper('getRowFullDisplayDateTime', function(date, time, fromDate, tz) {
    return getDateTimeDisplay(date, time, fromDate, tz, 'dddd, MMMM Do, YYYY', 'h:mma');
});

Handlebars.registerHelper('getRowSortDateTime', function(date, time, fromDate, tz) {
    return getDateTimeDisplay(date, time, fromDate, tz, 'YYYYMMDD', 'HHmmss', '00000000', '000000');
});

Handlebars.registerHelper('getRowDisplayTimestamp', function(timestamp, options) {
    return timestamp ? moment(timestamp).format('ddd M/D/YY @ h:mma') : '';
});

Handlebars.registerHelper('getRowSortTimestamp', function(timestamp, options) {
    return timestamp ? moment(timestamp).format('YYYY-MM-DD HH:mm:ss') : '';
});

Handlebars.registerHelper('displayItems', function(num, singularItemName) {
    return displayItems(num, singularItemName)
});

Handlebars.registerHelper('getDuration', function(duration) {
    if (!duration || duration == '00:00:00') {
        return '';
    }

    let pieces = duration.split(':').map(x => parseInt(x, 10));
    let displayPieces = [];

    if (pieces[0]) {
        displayPieces.push(displayItems(pieces[0], 'hour'));
    }
    if (pieces[1]) {
        displayPieces.push(displayItems(pieces[1], 'minute'));
    }
    if (pieces[2]) {
        displayPieces.push(displayItems(pieces[2], 'second'));
    }

    return 'Duration: ' + displayPieces.join(', ');
});

Handlebars.registerHelper('getNextPageSize', function(meta, options) {
    return (meta.total - meta.limit) < meta.limit ? meta.total - meta.limit : meta.limit;
});

Handlebars.registerHelper('truncateString', function(string, length, printable) {
    if (!string) {
        return '';
    }

    string = String(string);
    length = parseInt(length);

    var shouldTruncate = string.length > length && !printable;

    return shouldTruncate ? string.substring(0, length - 3) + "..." : string;
});

Handlebars.registerHelper('timeago', function (timestamp) {
    return timestamp ? moment(timestamp).fromNow() : '';
});

Handlebars.registerHelper('timeagoUTC', function (timestamp) {
    return timestamp ? moment.utc(timestamp).fromNow() : '';
});

Handlebars.registerHelper('formatDateTime', function (timestamp, format) {
    return timestamp ? moment(timestamp).format(format) : '';
});

Handlebars.registerHelper('formatDateTimeUTC', function (timestamp, format) {
    return timestamp ? moment.utc(timestamp).format(format) : '';
});

Handlebars.registerHelper('utcToTz', function(fromDate, tz) {
    return fromDate ? utcToTz(fromDate, tz).format('M/D/YY h:mma') : '';
});

Handlebars.registerHelper("join", function (list, sep) {
    if (!list.length || !$.isArray(list)) {
        $.error("Handlebars.js join helper: list is not a valid array");
    }
    return list.join(sep);
});

Handlebars.registerHelper("joinGradeCamResponses", function (answers, sep) {
    if (!$.isArray(answers) || !answers.length) {
        $.error("Handlebars.js joinGradeCamResponses helper: list is not a valid array");
    }
    return answers.join(sep);
});

Handlebars.registerHelper('getCommunicationDesc', function(data) {
    data = data.hash;
    var descriptionAttrs = [];

    if(data.contact) {
        descriptionAttrs.push(data.contact);
    }
    if(data.methods && data.method_id && data.methods[data.method_id]) {
        descriptionAttrs.push(data.methods[data.method_id].display_name);
    }
    if(data.number) {
        descriptionAttrs.push(data.number);
    }

    return descriptionAttrs.join('<br />');
});

Handlebars.registerHelper('getQuestionTags', function(question) {
    return ManualAssessment.getQuestionTags(question);
});

Handlebars.registerHelper('getQuestionWeight', function(question) {
    return ManualAssessment.getQuestionWeight(question);
});

Handlebars.registerHelper('getStudentEditActiveButtons', function(student) {
    return addDeactivateButton(student, true);
});

Handlebars.registerHelper('alertIcon', function (type) {
    var icons = {
        success: "ok",
        notice: "ok",
        info: "info-sign",
        default: "info-sign",
        warning: "warning-sign",
        error: "ban-circle"
    };

    return icons[type];
});

Handlebars.registerHelper('getAssessmentStudentDeactivateBtn', function (assessmentStudent, showShortcut) {
    return window.isEdit ? Handlebars.renderTemplate('assessment-student-deactivate-btn', {
        student: assessmentStudent,
        showShortcut: showShortcut == '1' ? true : false
    }) : '';
});

Handlebars.registerHelper('getAssessmentStudentEditBtn', function (assessmentStudent) {
    return assessmentStudent.assessment_student_id
        ? Handlebars.renderTemplate('assessment-student-edit-btn', {student: assessmentStudent}) : ''
});

Handlebars.registerHelper('renderTemplate', function(templateName, templateData) {
    return Handlebars.renderTemplate(templateName, templateData);
});

Handlebars.registerHelper('ifObjectiveMatch', function (question, iterationId, iterationCode, options) {
    var result = false,
        objective = question.objective,
        result = ((objective && objective.objective_id && objective.code &&
                        (objective.objective_id == iterationId || objective.code == iterationCode))
                    || question.objective_id == iterationId);

    return result ? options.fn(this) : options.inverse(this);
});

Handlebars.registerHelper("bool", function(string, options) {
    var isTrue;
    switch ($.type(string)) {
        case "string":
            isTrue = string === "false" || string === "0" ? false : true;
            break;
        case "number":
            isTrue = string;
            break;
        case "boolean":
            isTrue = string;
            break;
        case "null":
        case "undefined":
            isTrue = false;
            break;
        default:
            throw new Error("Handlebars boolean helper: " + $.type(string) + "s are not supported. Must be a string, number, or boolean");
    }
    if (isTrue) {
        return options.fn(this);
    }
    return options.inverse(this);
});

Handlebars.registerHelper('getObjectiveId', function(question) {
    var objective = ManualAssessment.getObjectiveFromQuestion(question);
    return objective ? objective.objective_id : null;
});

Handlebars.registerHelper('getObjectiveDisplayName', function(question) {
    var objective = ManualAssessment.getObjectiveFromQuestion(question);
    return ManualAssessment.getObjectiveDisplayName(objective);
});

Handlebars.registerHelper('getStudentResponse', function(answers, questionNumber) {
    var answer = (answers || {})[questionNumber];
    return !_.isNull(answer) && !_.isUndefined(answer) ? String(answer) : '';
});

Handlebars.registerHelper('ifAssessmentStudentIsActive', function(studentId, options) {
    return Assessment.getStudentActive(studentId) == 1 ? options.fn(this) : options.inverse(this);
});

Handlebars.registerHelper('shouldFadeIn', function (rowNum, isAppending, options) {
    return (rowNum === 0 && isAppending) ? options.fn(this) : options.inverse(this);
});

Handlebars.registerHelper('arrayIsEmpty', function (array, options) {
    return !array.length ? options.fn(this) : options.inverse(this);
});

Handlebars.registerHelper("moodColor", function (mood) {
    var colors = { positive: "green", neutral: "gray", negative: "red" };
    return colors[mood] || "gray";
});

Handlebars.registerHelper("currency", function (value, prefix) {
    return Tss.Number.toMoney(value, 0, ".", ",", prefix);
});

Handlebars.registerHelper("getScoreGrade", function (score) {
    var gradingScale = getGradingScale();
    return scoreToGrade(score, gradingScale);
});

Handlebars.registerHelper("getScoreColor", function (score) {
    var gradingScale = getGradingScale(),
    colorScale = Functions.getFontColorScale(gradingScale.levels),
    colorIndex = _.findIndex(gradingScale.levels, function (level) {
        return level.name === scoreToGrade(score, gradingScale);
    });
    return colorScale(colorIndex);
});

Handlebars.registerHelper("getGradeColor", function (grade) {
    var gradingScale = getGradingScale(),
    colorScale = Functions.getFontColorScale(gradingScale.levels),
    colorIndex = _.findIndex(gradingScale.levels, function (level) {
        return level.name === grade;
    });
    return colorScale(colorIndex);
});

Handlebars.registerHelper("displayGradingScale", function (gradingScale) {
    var pctDisplay = gradingScale.is_pct == 1
        ? '%'
        : '';
    var str = _.chain(gradingScale.grading_scale_levels)
        .filter(level => level.active == 1)
        .sortBy(level => {
            var minValue = level.min_value;
            return minValue == ''
                ? 9999999999
                : -parseFloat(minValue);
        })
        .map(level => {
            var color = Functions.getBackgroundColorByIndex(level.index, level.num_levels, level.color_override, gradingScale.active == 0 ? .7 : false);
            var name = Handlebars.Utils.escapeExpression(level.abbreviation || level.name);
            var greaterThan = level.min_value !== null
                ? ` &ge; ${level.min_value}${pctDisplay}`
                : '';

            return `<span class="grading-scale-level-display" style="border-left-color: ${color}">${name}${greaterThan}</span>`;
        })
        .join(' ')
        .value();
        
    return new Handlebars.SafeString(str);
});

Handlebars.registerHelper("getBackgroundColorByIndex", function (index, numLevels, colorOverride, shouldAddAlpha) {
    var color = Functions.getBackgroundColorByIndex(index, numLevels, colorOverride, shouldAddAlpha);
    return color ? new Handlebars.SafeString(color) : '';
});

Handlebars.registerHelper('toLowerCase', function(str) {
    return str.toLowerCase();
});

Handlebars.registerHelper('toTitleCase', function(str) {
    return _.startCase(str);
});

Handlebars.registerHelper('filterDetailsTable', function(data) {
    // get filter details from data and remove empty keys
    var filterDetails = _.chain(data)
        .get('[0].filter_details')
        .pickBy(_.size)
        .value();

    function buildSection(data, sectionName) {
        var rows = _.map(data, buildRow);
        return '<thead><tr><th colspan="2">' + sectionName + ' Filters</th></tr></thead><tbody>' + rows.join('') + '</tbody>';
    }

    function buildRow(data, key) {
        var data = _.isArray(data) ? data : [data];
        return '<tr><th width="25%">' + _.startCase(key) + ':</th><td>' + data.join(', ') + '</td></tr>';
    }

    // build <thead><tbody> sections
    var sections = _.map(filterDetails, buildSection);
    return new Handlebars.SafeString('<table>' + sections.join(' ') + '</table>');
});

Handlebars.registerHelper('shareToList', function(list) {
    var names = _.map(list, 'display_name');
    return names.join(', ');
});

function getRagClass(rag, val) {
    var c = '';

    if (val !== null) {
        _.forEach(rag, function(conf) {
            var min = _.get(conf, 'min', null),
                max = _.get(conf, 'max', null),
                hasMin = min !== null,
                hasMax = max !== null;

            if ((hasMax && val <= parseFloat(max)) || (hasMin && val >= parseFloat(min)) || (!hasMax && !hasMin)) {
                c = conf['c'];
                return false; // break;
            }
        });
    }

    return c;
}

Handlebars.registerHelper('getRagClass', getRagClass);
Handlebars.registerHelper('getAttendancePct', function(attMap, courseId, shortName, rag) {
    var m = _.get(attMap, [courseId, shortName]),
        val = _.get(m, 'value'),
        text = _.get(m, 'formatted_value', ''),
        num = _.get(m, 'absences'),
        den = _.get(m, 'days'),
        title = den ? num + ' absences / ' + den + ' days' : '',
        c = den ? getRagClass(rag, val) : '';

    return new Handlebars.SafeString('<td><div class="rounded ' + c + '" title="' + title + '">' + text + '</div></td>');
});

/**
 * If the key is in the map and the value is true then select the checkbox.
 * If there are no settings at all, use the default value.
 *
 * @param  Object|array map      Ex. {comments: "on", withPageBreaks: 0} or ['comments']
 * @param  String key            Ex. 'comments'
 * @param  boolean defaultValue  Ex. true
 * @return String                attribute that will display the checkbox
 *                               as selected or empty string
 */
Handlebars.registerHelper('getTssCheckboxSelected', function(map, key, defaultValue) {
    var selected = false;

    if (!_.isUndefined(map)) {
        if (_.isArray(map)) {
            selected = _.indexOf(map, key) >= 0;
        } else {
            selected = map && key in map && map[key];
        }
    } else {
        selected = defaultValue;
    }

    return selected
        ? new Handlebars.SafeString('data-tss-checkbox-selected="true"')
        : '';
});

Handlebars.registerHelper('jsonEncode', function(obj) {
    return new Handlebars.SafeString(JSON.stringify(obj));
});

Handlebars.registerHelper('toFraction', function(val, denominator) {
    return new Handlebars.SafeString(toFraction(val, denominator || 1, true));
});

Handlebars.registerHelper('each_with_sort_and_filter', function(array, sortKey, opts) {
    var sortKeys = sortKey ? sortKey.split(',') : [];
    _.each(array, o => {
        _.each(sortKeys,  key => {
            var val = _.get(o, key);
            if (val !== undefined && isNumber(val)) {
                _.set(o, key, parseInt(val, 10));
            }
        })
    });

    var data = opts && opts.data ? Handlebars.createFrame(opts.data) : undefined;
    var sortedArray = _.sortBy(array, sortKeys);
    var filterJson = _.get(opts.hash, 'filterJson');
    var filters = filterJson ? JSON.parse(filterJson) : opts.hash;
    var filteredArray = filters ? _.filter(sortedArray, filters) : sortedArray;
    var results = '';

    _.each(filteredArray, function(v, i) {
        if (data) {
            data.index = i;
            data.isFirst = (i == 0);
            data.isLast = (i == filteredArray.length - 1);
        }

        results += opts.fn(v, {data: data});
    });

    return results;
});

Handlebars.registerHelper('each_by_subkey', function(array, key, opts) {
    var data = opts && opts.data ? Handlebars.createFrame(opts.data) : undefined;
    var subArray = _.get(array, key);
    var results = '';

    _.each(subArray, function(v, i) {
        if (data) {
            data.index = i;
        }

        results += opts.fn(v, {data: data});
    });

    return results;
});

Handlebars.registerHelper('getClassAbsenceData', function(record, sectionPeriods) {
    let obj = {
        class_absence_id: record.class_absence_id,
        sectionDisplayName: _.get(sectionPeriods, `${record.section_period_id}.display_name`)
    }
    //escaping single quotes so that we can use single quotes to wrap the DOM element
    //attribute since that also contains double-quotes in the JSON
    return new Handlebars.SafeString(JSON.stringify(obj).replace("'", "&#39;"));
});

Handlebars.registerHelper('getSectionDisplayName', function(section) {
    return new Handlebars.SafeString(getSectionDisplayName(section, true));
});

Handlebars.registerHelper('humanize', function(input) {
    return _.humanize(input);
});

// { perm_key: true/false } => Perm Key
Handlebars.registerHelper('displayPermissions', function(permissions) {
    var rows = _.chain(permissions)
        .keys()
        .filter(k => permissions[k])
        .map(k => _.humanize(k))
        .sort()
        .join('</div><div>')
        .value();

    return new Handlebars.SafeString(`<div>${rows}</div>`);
});

Handlebars.registerHelper('nl2br', function(text) {
    return new Handlebars.SafeString((text || '').replace(/\n/g, '<br/>'));
});

/* globals d3: false, svg: false */
var AssessmentGraphing = {
    duration: 500,
    scoreData: [],
    pieData: [],
    breakdownData: [],
    backgroundColorScale: null,
    fontColorScale: null,
    breakdownColorScale: null,
    arc: null,
    breakdownGraph: {},
    barGraph: {},
    curveVal: null,
    
    calculateScoreData: function(data, gradingScale) {
        var that = this,
            gradeToIndex = {};

        that.scoreData = [];
    
        if (!(gradingScale && gradingScale.levels.length)) return;
        
        that.backgroundColorScale = Functions.getBackgroundColorScale(gradingScale.levels);
        that.fontColorScale = Functions.getFontColorScale(gradingScale.levels);
        
        _.each(gradingScale.levels, function(level, i) {
            var key = level.abbreviation || level.name;
            
            gradeToIndex[key] = i;
            
            return that.scoreData.push({'grade': key, 'gradeName': level.name, 'count': 0, 'curvedCount': 0, 'index': i});
        });

        var curve = this.getCurveValue(),
            showActive = $('[name="show_active"]:checked').val(),
            showMissing = $('[name="show_missing"]:checked').val(),
            courseWide = parseInt($('[name="course_wide"]').val()),
            hasData = false;

        d3.selectAll('#assessment-results-table-container tbody tr')[0].map(function(tr) {
            styleRow(tr, curve, gradeToIndex, gradingScale);
        });

        var assessmentAnswers = data.results ? data.results.assessmentAnswers : [];

        _.each(assessmentAnswers, function(assessmentStudent, i) {
            var isActive = (assessmentStudent.active == 1);
            var isMissing = (assessmentStudent.missing == 1);

            if (showActive !== '' && showActive != isActive) return true; // continue;
            if (showMissing !== '' && showMissing != isMissing) return true; // continue;

            var noPct = $('#assessment-definition-table-container').data('noPct');
            var rawScore = parseFloat(assessmentStudent.avg_score || '0');
            var gradebookScore = parseFloat(assessmentStudent.gradebook_score || '0');
            var grade = scoreToGrade((noPct ? rawScore.toFixed(2) : rawScore.toFixed(0)), gradingScale);
            var curvedGrade = scoreToGrade(gradebookScore, gradingScale);
            var index = gradeToIndex[grade];
            var curvedIndex = gradeToIndex[curvedGrade];

            hasData = true;
            that.scoreData[index].count++;
            that.scoreData[curvedIndex].curvedCount++;
        });

        if (!hasData) {
            return that.scoreData = [];
        }

        d3.selectAll('#assessment-definition-table-container tbody tr')[0].map(function(tr) {
            styleQuestionRow(tr, gradeToIndex, gradingScale);
        });
        $('#assessment-results-table-container').find('table.tablesorter').trigger('update');
        $('table.tablesorter').each(simpleCalculateTotals);
        styleRow($('#assessment-results-table-container tr.averages-row'), curve, gradeToIndex, gradingScale);
        styleQuestionRow($('#assessment-definition-table-container tr.totals-row'), gradeToIndex, gradingScale);

        return that.scoreData;
    },
    
    /**
     * 
     */
    drawResultsGraph: function(data, gradingScale) {
        if (!(gradingScale && gradingScale.levels.length && $('#assessment-results-table-container').length)) {
            return;
        }
        var that = this,
            scoreData = that.calculateScoreData(data, gradingScale);
        
        if (scoreData && scoreData.length) {
            that.drawResultsBarChart(scoreData);
            that.drawResultsPieChart(scoreData);
            
            var breakdownData = that.calculateBreakdownData(data, gradingScale);
            
            that.drawResultsBreakdown(breakdownData, gradingScale);
            
            setTimeout(function() {
                that.transitionGraphs(scoreData);
            }, 1000);
        }
    },
    
    /**
     * 
     */
    drawResultsBarChart: function(scoreData) {
        $('#resultsBarChart').html('');

        var that = this,
            field = this.getCurveValue() ? 'curvedCount' : 'count',
            container = $('#resultsBarChart').closest('.col'),
            margins = {top: 20, right: 20, bottom: 20, left: 200},
            w = Math.max(container.width(), 700) - margins.right - margins.left,
            h = Math.min(700, scoreData.length * 23 + margins.top + margins.bottom),
            x = d3.scale.linear().range([0, w]).domain([0, Math.max(d3.sum(scoreData, function(d) { return d[field]; }) * .5,
                                                                    d3.max(scoreData, function(d) { return d[field]; }))]).nice(),
            y = d3.scale.ordinal().rangeRoundBands([0, h], .1).domain(scoreData.map(function(d) { return d.grade; })),
            xAxis = d3.svg.axis().scale(x).orient('top').tickSize(-h, -h, 0).ticks(5).tickFormat(function(v) { return Tss.Number.toCommas(v, 0); }),
            yAxis = d3.svg.axis().scale(y).orient('left').tickSize(0, 0, 0),
            svg = d3.select('#resultsBarChart')
                .append('svg')
                  .attr('width', w + margins.right + margins.left)
                  .attr('height', h + margins.top + margins.bottom)
                .append('g')
                  .attr('transform', 'translate(' + margins.left + ',' + margins.top + ')');
      
        that.barGraph.svg = svg;
        that.barGraph.x = x;
        that.barGraph.y = y;
        that.barGraph.w = w;
        that.barGraph.h = h;
        that.barGraph.xAxis = xAxis;
        that.barGraph.yAxis = yAxis;
        that.barGraph.margins = margins;

        var bar = svg.selectAll('g.bar')
            .data(scoreData)
            .enter().append('g')
            .attr('class', 'bar')
            .attr('transform', function(d) { return 'translate(0,' + y(d.grade) + ')'; });
//            .attr('transform', function(d) { return 'translate(0,' + y(d.grade) + ')'; });

        bar.append('rect')
            .attr('style', function(d, i) { return 'fill: ' + that.backgroundColorScale(i); })
            .attr('width', function(d) { return x(d[field]); })
            .attr('height', y.rangeBand());

        bar.append('text')
            .attr('class', 'value')
            .attr('x', function(d) { return x(d[field]); })
            .attr('y', y.rangeBand() / 2)
            .attr('dx', -3)
            .attr('dy', '.35em')
            .attr('text-anchor', 'end')
            .text(function(d) { return d[field] ? (d[field]).toFixed(0) : ''; });

        svg.append('g')
            .attr('class', 'x axis')
            .call(xAxis);

        svg.append('g')
            .attr('class', 'y axis label')
            .call(yAxis);
    },

    /**
     * DISABLING transitions b/c they are firing WAY too often
     * and having all sorts of problems with scoping.
     */
    transitionGraphs: function(scoreData) {

        // BAIL NOW!
        return;
        // WE'RE GONE!

        var that = this,
            field = this.getCurveValue() ? 'count' : 'curvedCount',
            svg = that.barGraph.svg,
            x = that.barGraph.x,
            y = that.barGraph.y,
            w = that.barGraph.w,
            xAxis = that.barGraph.xAxis,
            yAxis = that.barGraph.yAxis,
            margins = that.barGraph.margins;

        if (!scoreData || !scoreData.length || !x) {
            return;
        }

        x = d3.scale.linear().range([0, w]).domain([0, Math.max(d3.sum(scoreData, function(d) { return d[field]; }) * .5,
                                                                d3.max(scoreData, function(d) { return d[field]; }))]).nice();

        d3.select('#resultsBarChart').select('g.x.axis')
            .transition().duration(that.duration).delay(function(d, i) { return i * 100; })
            .call(xAxis.scale(x));
        d3.selectAll('g.bar rect')
            .data(scoreData)
            .transition().duration(that.duration).delay(function(d, i) { return i * 100; })
            .attr('width', function(d) { return x(d[field]); });
        d3.selectAll('g.bar text')
            .data(scoreData)
            .transition().duration(that.duration).delay(function(d, i) { return i * 100; })
            .attr('x', function(d) { return x(d[field]); })
            .attr('text-anchor', function(d) { return (d[field]) == 0 ? 'start' : 'end'; })
            .attr('dx', function(d) { return d[field] == 0 ? '5' : '-3'; })
            .tween('text', function(d) {
                var interp = d3.interpolate(this.textContent, d[field]);

                return function(t) { this.textContent = Math.round(interp(t)); };
            });

        var svgPie = d3.select('#resultsPieChart svg').data([scoreData]),
            pie = d3.layout.pie().sort(null).value(function(d) { return d[field]; }),
            newPieData = pie(scoreData),
            oldPieData = _.clone(that.pieData);

        _.each(newPieData, function(d, i) {
            d.oldStartAngle = oldPieData[i].startAngle;
            d.oldEndAngle = oldPieData[i].endAngle;
        });
        that.pieData = newPieData;

        var frozenPie = _.clone(that.pieData);
        var fnCalcDelay = function(d, i) { return i * 100; };

        svgPie.selectAll('g.slice').data(frozenPie, function(d) { return d.grade; });
        svgPie.selectAll('path')
            .transition().duration(that.duration).delay(fnCalcDelay)
            .attrTween('d', function(d, i) {
                // d = that.pieData[i];
                // var interp = d3.interpolate({startAngle: d.oldStartAngle, endAngle: d.oldEndAngle}, d);

                var dClone = frozenPie[i];
                var interp = d3.interpolate({startAngle: dClone.oldStartAngle, endAngle: dClone.oldEndAngle}, d);

                return function(t) { return that.arc(interp(t)); };
            });

        svgPie.selectAll('text')
            .transition()
            .duration(that.duration)
            .delay(fnCalcDelay)
            .attr('transform', function(d, i) { return 'translate(' + that.arc.centroid(frozenPie[i]) + ')'; })
            .attr('opacity', function(d, i) { return frozenPie[i].value == 0 ? 0 : 1; });
    },

    /**
     * 
     */
    drawResultsPieChart: function(scoreData) {
        $('#resultsPieChart').html('');

        var that = this,
            field = this.getCurveValue() ? 'curvedCount' : 'count',
            margins = {top: 10, right: 10, bottom: 10, left: 10},
            r = 100,
            w = r * 2,
            h = r * 2,
            svg = d3.select('#resultsPieChart')
                .append('svg')
                  .data([scoreData])
                  .attr('width', w + margins.right + margins.left)
                  .attr('height', h + margins.top + margins.bottom)
                .append('g')
                  .attr('transform', 'translate(' + (r + margins.left) + ',' + (r + margins.top) + ')');
        
        that.arc = d3.svg.arc().innerRadius(0).outerRadius(r);
        
        var arcOver = d3.svg.arc().innerRadius(0).outerRadius(r + 10),
            pie = d3.layout.pie().sort(null).value(function(d) { return d[field]; });
        
        that.pieData = pie(scoreData);
        
        var arcs = svg.selectAll('g.slice')
                .data(that.pieData, function(d) { return d.grade; })
            .enter()
                .append('g')
                .attr('class', 'slice')
                .on('mouseover', function() {
                    var parent = $(this).parent();
                    var slice = d3.select(this);
                    var path = slice.select('path');

                    d3.select(parent.get(0)).selectAll('path')
                        .transition().duration(that.duration)
                        .attr('d', function(d, i) { d = that.pieData[i]; return path.attr('i') == i ? arcOver(d, i) : that.arc(d, i); })
                        .attr('opacity', function(d, i) { return path.attr('i') == i ? 1 : .6; });
                })
                .on('mouseout', function() {
                    var parent = $(this).parent();

                    d3.select(parent.get(0)).selectAll('path')
                        .transition().duration(that.duration)
                        .attr('opacity', 1)
                        .attr('d', function(d, i) { d = that.pieData[i]; return that.arc(d, i); })
                });

        arcs.append('path')
            .attr('fill', function(d, i) { return that.backgroundColorScale(i); })
            .attr('i', function(d, i) { return i; })
            .attr('opacity', 1)
            .attr('d', that.arc);

        arcs.append('text')
            .attr('transform', function(d) { return 'translate(' + that.arc.centroid(d) + ')'; })
            .attr('text-anchor', 'middle')
            .attr('class', 'label')
            .attr('opacity', 1)
            .style('cursor', 'default')
            .text(function(d, i) { return scoreData[i].grade; });
        $('.slice .label').tipsy({gravity: 's', title: this.displaySliceTooltip, html: true, opacity: 1, className: 'white'});
    },

    /**
     * 
     */
    displaySliceTooltip: function(arcData) {
        var that = AssessmentGraphing; // 'this' refers to the DOM element
        
        arcData = that.pieData[arcData.data.index];
        
        var d = arcData.data,
            pct = Math.round(100 * (arcData.endAngle - arcData.startAngle) / (2 * Math.PI));

        if (pct == 0) {
            return '';
        }

        var totalPct = Math.round(100 * arcData.endAngle / (2 * Math.PI)),
            studentsName = $('label[for="group_by_students"]').text();
    
        return '<div class="label">' + d.gradeName + ' ' + (pct).toFixed(0) + '%</div><div>' + (pct).toFixed(0) + '% of ' + studentsName + ' at ' + d.gradeName + ' level'
            + (d.index == 0 || d.index == getGradingScale().length - 1 ? ''
                : '<br/>' + (totalPct).toFixed(0) + '% of ' + studentsName + ' at or above ' + d.gradeName + '</div>'); //\u2265
    },
            
    /**
     * 
     */
    calculateBreakdownData: function(data, gradingScale) {
        var that = this,
            gradeToIndex = {};
        
        that.breakdownData = [];

        _.each(gradingScale.levels, function(level, i) {
            var x = gradingScale.levels.length - 1 - i;
            
            gradeToIndex[level.name] = x;
            that.breakdownData[x] = [];
        });

        // calculate average score per student per objective
        // then bucket those average scores into grading scale buckets
        var byStudent = isByStudent(),
            byQuestion = isByQuestion(),
            byTag = isByTag(),
            bySection = isBySection(),
            bySchool = isBySchool(),
            assessmentAnswers = data.results && data.results.assessmentAnswers ? data.results.assessmentAnswers : [],
            studentResults = data.results && data.results.studentResults ? data.results.studentResults : [],
            assessmentQuestions = data.results && data.results.assessmentQuestions ? data.results.assessmentQuestions : [],
            showActive = $('[name="show_active"]:checked').val(),
            showMissing = $('[name="show_missing"]:checked').val(),
            courseWide = parseInt($('[name="course_wide"]').val()),
            sectionIds = $('[name="section"]').val(),
            schoolIds = $('[name="school"]').val(),
            tags = $('[name="tag"]').val(),
            gradingScaleLevels = $('select[name="grading_scale_level_filter"]').val(),
            bucketResults = {};
        
        _.each(assessmentAnswers, function(assessmentStudent) {
            var studentId = assessmentStudent.student_id,
                studentResult = studentResults[studentId] || {},
                name = $(assessmentStudent.name).text(),
//                avgScore = assessmentStudent.avg_score || 0,
                curvedScore = assessmentStudent.gradebook_score || 0,
                isActive = assessmentStudent.active,
                isMissing = assessmentStudent.missing,
                section = Assessment.getSectionForStudent(studentId),
                sectionName = section.display_name || 'None',
                schoolName = assessmentStudent.school.display_name,
                sectionId = section.section_id,
                schoolId = assessmentStudent.school_id,
                canISeeSchool = ManualAssessment.canISeeSchool(schoolId),
                bucketValues = {};

            if (showActive !== '' && showActive != isActive) return true; // continue;
            if (showMissing !== '' && showMissing != isMissing) return true; // continue;
            if (sectionIds && $.inArray(sectionId, sectionIds) < 0) return true; // continue;
            if (schoolIds && $.inArray(schoolId, schoolIds) < 0) return true; // continue;
            if (gradingScaleLevels && $.inArray(scoreToGradeLevel(curvedScore, gradingScale).grading_scale_level_id, gradingScaleLevels) < 0) return true; // continue;
            if (isMissing && !courseWide) { return true; } // We don't care about missing students if the assessment isn't required
            
            _.each(assessmentQuestions, function(question, num) {
                var a = studentResult[num] || [0],
                    studentAnswer = a && a.length > 1 && !_.isNull(a[1]) ? a[1] : 'blank',
                    obj = question.objective,
                    objDesc = objectiveToDesc(obj),
                    questionKey = question.question_name || question.question_number,
                    weight = parseFloat(_.get(question.questionTags, '0.weight')) || 1,
                    questionTags = (question.questionTags || [{}]).map(function(o) { return o.tag || 'None'; }),
                    filteredTags = tags ? _.intersection(tags, questionTags) : questionTags,
                    objKeys = (byQuestion ? ['Question ' + questionKey]
                                : (byTag ? filteredTags
                                    : (bySection ? [sectionName]
                                        : (bySchool ? [schoolName]
                                            : [objDesc])))); //obj.objective_id ||
                
                if (tags && !filteredTags.length) return true; // continue;

                _.each(objKeys, function(objKey, i) {
                    if (!(objKey in bucketValues)) bucketValues[objKey] = {points: 0, outof: 0, questions: [], answer: studentAnswer};
                    
                    // either add the number of points the student received or add zero (aka NOT NaN)
                    bucketValues[objKey].points += parseFloat(a[0] || 0) * weight;
                    bucketValues[objKey].outof += parseFloat(question.point_value) * weight;
                    bucketValues[objKey].questions.push(questionKey);
                });
            });

            // for each bucket value, add to results (either by student or by objective)
            _.each(bucketValues, function(v, objKey) {
                var key = byStudent ? studentId : objKey,
                    desc = byStudent ? objKey : name,
                    score = v.points * (v.outof ? 100 / v.outof : 1);

                if (!(key in bucketResults)) bucketResults[key] = {};

                if (!byStudent || canISeeSchool) {
                    bucketResults[key][desc] = {
                        score: score,
                        answer: v.answer,
                        questions: v.questions,
                        can_i_see_school: canISeeSchool,
                    };
                }
            });
        });
        
        _.each(bucketResults, function(resultMap, key) {
            var bucketCounts = {};
            var sum = 0;

            _.each(resultMap, function(scoreMap, name) {
                var score = scoreMap.score,
                    levelName = breakdownScoreToGrade(score, gradingScale);

                if (!(levelName in bucketCounts)) bucketCounts[levelName] = {count: 0, names: {}, questions: []};

                sum++;
                bucketCounts[levelName].count++;
                bucketCounts[levelName].totalScore = (bucketCounts[levelName].totalScore || 0) + score;
                bucketCounts[levelName].questions = _.union(bucketCounts[levelName].questions, scoreMap.questions);

                if (scoreMap.can_i_see_school) {
                    bucketCounts[levelName].names[name] = byQuestion ? scoreMap.answer : Math.round(score);
                }
            });

            if (sum) {
                _.each(gradingScale.levels, function(level, i) {
                    var bucket = bucketCounts[level.name] || {},
                        count = bucket.count || 0,
                        names = bucket.names || [],
                        questions = bucket.questions || [],
                        i = gradeToIndex[level.name],
                        y = (i < getPivot() ? -1 : 1) * 100 * count / sum,
                        ySort = (getPivot() == 0 && i == 0 ? -y : y),
                        j = that.breakdownData[i].length,
                        student = byStudent ?
                                _.find(assessmentAnswers, {student_id: key}) ||
                                _.find(assessmentAnswers, {student_id: parseInt(key)}) :
                            null,
                        x = student ? $(student.name).text() : key;

                    questions.sort(function(a,b) { return a-b; });
                    that.breakdownData[i].push({i: i, j: j, x: x, y: y, names: names, key: key, questions: questions});

                    var k = gradingScale.levels.length - 1;
                    var totalScore = bucket.totalScore || 0;

                    that.breakdownData[k][j].sortval = (that.breakdownData[k][j].sortval || 0) + Math.min(ySort, 0);
                    that.breakdownData[k][j].numscores = (that.breakdownData[k][j].numscores || 0) + count;
                    that.breakdownData[k][j].totalscore = (that.breakdownData[k][j].totalscore || 0) + totalScore;
                });
            }
        });

        var sortBy = $('select[name="sort_by"]').val();
        that.breakdownData[0].sort(function(a, b) {
            var aVal = a.x;
            var bVal = b.x;
            
            if (isByQuestion()) {
                aVal = parseInt(aVal ? aVal.replace('Question ', '') : aVal);
                bVal = parseInt(bVal ? bVal.replace('Question ', '') : bVal);
            }
            
            if (sortBy == 'needs_work' || sortBy == 'mastery' || sortBy == 'avg' || sortBy == 'avg_desc') {
                var i = gradingScale.levels.length - 1;
                var aSortVal = Math.round(that.breakdownData[i][a.j].sortval);
                var bSortVal = Math.round(that.breakdownData[i][b.j].sortval);
                var aAvgVal = that.breakdownData[i][a.j].totalscore / that.breakdownData[i][a.j].numscores;
                var bAvgVal = that.breakdownData[i][b.j].totalscore / that.breakdownData[i][b.j].numscores;

                if (sortBy == 'needs_work' || sortBy == 'mastery') {
                    aVal = aSortVal; bVal = bSortVal;
                    if (aVal == bVal) { aVal = aAvgVal; bVal = bAvgVal; }
                } else {
                    aVal = aAvgVal; bVal = bAvgVal;
                    if (aVal == bVal) { aVal = aSortVal; bVal = bSortVal; }
                }

                i = -1;
                while (aVal == bVal && ++i < gradingScale.levels.length) {
                    aVal = -Math.round(i == 0 ? Math.abs(a.y) : Math.abs(that.breakdownData[i][a.j].y));
                    bVal = -Math.round(i == 0 ? Math.abs(b.y) : Math.abs(that.breakdownData[i][b.j].y));
                }

                if (sortBy == 'mastery' || sortBy == 'avg_desc') {
                    return aVal - bVal;
                }

                return bVal - aVal; // default
            }

            return (sortBy == "ztoa" ? -1 : 1) * (aVal > bVal ? -1 : aVal == bVal ? 0 : 1);
        });

        var indexMap = that.breakdownData[0].map(function(v) { return v.j; });
        for (var i = 1; i < that.breakdownData.length; i++) {
            that.breakdownData[i].sort(function(a, b) {
                return indexMap.indexOf(a.j) - indexMap.indexOf(b.j);
            });
        }

        this.barStack(that.breakdownData);

        return that.breakdownData;
    },
            
    /**
     * 
     */
    barStack: function(breakdownData) {
        for (var j = 0; j < breakdownData[0].length; j++) {
            var posBase = 0, negBase = 0, totalCount = 0, pivotVal = getPivot();

            for (var i = breakdownData.length - 1; i >= 0; i--) {
                var d = breakdownData[i][j];
                var count = Math.max(0, Object.keys(d.names).length); // cumulative pct

                d.size = Math.abs(d.y);

                if (i < pivotVal) {
                    d.y0 = negBase;
                    d.pct = d.y0 - d.size; // cumulative pct
                    negBase -= d.size;
                    d.count = (totalCount += count);
                }
            }

            if(pivotVal.length) {
                totalCount = 0;
                for (var i = pivotVal; i < breakdownData.length; i++) {
                    var d = breakdownData[i][j];
                    var count = Math.max(0, Object.keys(d.names).length); // cumulative pct

                    d.y0 = posBase = posBase + d.size;
                    d.pct = d.y0; // cumulative pct

                    if (i == 0 && getPivot() == 0) {
                        d.count = count;
                    } else {
                        d.count = (totalCount += count);
                    }
                }
            }
        }

        breakdownData.extent = d3.extent(d3.merge(d3.merge(breakdownData.map(function(e) { return e.map(function(f) { return [f.y0, f.y0 - f.size]})}))));

        return breakdownData;
    },

    /**
     * 
     */
    drawResultsBreakdown: function(breakdownData, gradingScale) {
        $('#resultsBreakdown').html('');
        this.breakdownColorScale = Functions.getBackgroundColorScale(gradingScale.levels, true);
        
        var that = this,
            leftMargins = {students: 170, objectives: 250, sections: 250, questions: 150},
            margins = {top: 30, right: 50, bottom: 30, left: leftMargins[getGroupBy()] || 170},
            w = 400 + margins.right,
            h = breakdownData[0].length * 18 + margins.top + margins.bottom,
            x = d3.scale.ordinal().rangeRoundBands([h, 0], .2).domain(breakdownData[0].map(function(d) { return d.x; })),
            y = d3.scale.linear().range([0, w]).domain([-100, 100]),
            xAxis = d3.svg.axis().scale(x).tickSize(0, 0).tickFormat(function(v) {
                return v.length > 39 ? v.substr(0, 39) + '...' : v;
            }),
            yAxis = d3.svg.axis().scale(y).tickSize(-h, -h, 0)
                .tickValues([-100, -75, -50, -25, 0, 25, 50, 75, 100]).tickFormat(function(v) {
                v = Math.abs(v); return v == 0 || v == 100 ? (v).toFixed(0) + '%' : v;
            });
        

        var svg = d3.select('#resultsBreakdown')
            .append('svg')
              .attr('width', w + margins.right + margins.left)
              .attr('height', h + margins.top + margins.bottom)
            .append('g')
              .attr('transform', 'translate(' + margins.left + ',' + margins.top + ')');

        that.breakdownGraph.svg = svg;
        that.breakdownGraph.x = x;
        that.breakdownGraph.y = y;
        that.breakdownGraph.xAxis = xAxis;
        that.breakdownGraph.yAxis = yAxis;
        that.breakdownGraph.margins = margins;

        var series = svg.selectAll(".series")
                    .data(breakdownData, scoreDataJoin(gradingScale)) // data join!
                .enter().append("g")
                    .classed("series", true).style("fill", function(d, i) { return that.breakdownColorScale(i); })
        var rect = series.selectAll("rect.bar")
                    .data(Object, function(d) { return d.x; }) // data join!
                .enter().append("rect").classed('bar', true);

        svg.append("g").attr("class", "axis x");
        svg.append("g").attr("class", "axis y horizontalStackedBar");

        svg.select(".x.axis")
            .attr("transform","translate(" + y(0) + ", 0)")
            .call(xAxis.orient("left"));
        $('#resultsBreakdown .x.axis').children().each(function(i, li) { $(li).parent().prepend(li); }); // reverse order of text elements to make them selectable

        svg.select(".y.axis")
            .call(yAxis.orient("top"));
        svg.selectAll(".x.axis g text")
            .attr('text-anchor', 'start')
            .attr('x', -y(0) - margins.left)
            .on('click', function() {
                if (!isByStudent()) return;

                var elem = this;
                var name = $(elem).text();
                var data = lastData;

                _.each(data.results.assessmentAnswers, function(assessmentAnswer, i) {
                    var studentName = $(assessmentAnswer.name).text();

                    if (name == studentName) {
                        var studentResult = data.results.studentResults[assessmentAnswer.student_id];
                        AssessmentGraphing.showQuestionByQuestionResults(elem, studentResult, assessmentAnswer);
                        return false; // break
                    }
                });
            });
        $('#resultsBreakdown .x.axis g text').tipsy({gravity: 's', html: true, opacity: 1, className: 'white', title: function(v) {
            return v + (isByStudent() ? '<br/>(click for question-by-question results)' : '');
        }});

        svg.selectAll(".series rect.bar")
            .attr("y", function(d) { return x(d.x); })
            .attr("x", function(d) { return y(d.y0 - d.size); })
            .attr("width", function(d) { return y(d.size) - y(0); })
            .attr("height", x.rangeBand())
            .on('click', function() {
                var rect = this;
                var d = d3.select(rect).data()[0];

                pinTooltip(rect, studentTooltip(d, true));
            });
        $('.series rect.bar').tipsy({gravity: 'n', opacity: 1, html: true, className: 'white medium', title: studentTooltip});

        // average scores
        series.filter(function(d, i) { return i == breakdownData.length - 1; }).selectAll("rect.avg")
                .data(Object, function(d) { return d.x; })
            .enter().append("rect").classed('avg', true)
            .style("stroke", function(d) { return that.breakdownColorScale(scoreToIndex(d.totalscore / d.numscores, gradingScale)); })
            .style("fill", 'white')
            .attr("y", function(d) { return x(d.x) - 1; })
            .attr("x", function(d) { return y(d.totalscore / d.numscores) - 1; })
            .attr("width", 3)
            .attr("height", x.rangeBand() + 2)
            .on('click', function() {
                if (!isByStudent()) return;

                var rect = this;
                var data = lastData;
                var d = d3.select(rect).data()[0];
                var name = d.x;

                _.each(data.results.assessmentAnswers, function(assessmentAnswer, i) {
                    var studentName = $(assessmentAnswer.name).text();

                    if (name == studentName) {
                        var studentResult = data.results.studentResults[assessmentAnswer.student_id];
                        AssessmentGraphing.showQuestionByQuestionResults(rect, studentResult, assessmentAnswer);
                        return false; // break
                    }
                });
            });

        // average text
        series.filter(function(d, i) { return i == breakdownData.length - 1; }).selectAll("text.avg")
                .data(Object)
            .enter().append("text").classed('avg', true)
                .style("stroke", 'none')
                .style("fill", 'black')
                .attr("y", function(d) { return x(d.x) + x.rangeBand() / 2; })
                .attr("x", function(d) { return y(d.totalscore / d.numscores) + 3; })
                .attr('dx', '3')
                .attr('dy', '.35em')
                .attr('text-anchor', function(d) { return d.i ? 'start' : 'end'; })
                .text(function(d) {
                    if (!showAverage()) return '';
                    
                    var noPct = getGradingScaleIsNoPct(),
                        avg = d.totalscore / d.numscores;

                    return noPct ? (avg).toFixed(2) : (avg).toFixed(0) + '%';
                });
        $('.series .avg').tipsy({gravity: 's', opacity: 1, html: true, title: avgTooltip});
                
        // count/pct text
        series.filter(function(d, i) { return i == 0 || i == breakdownData.length - 1; }).selectAll("text.countpct")
                .data(Object)
            .enter().append("text").classed('countpct', true)
                .attr('x', function(d) {
                    var xVal = d.i ? d.y0 : (getPivot() == 0 ? 0 : d.y0 - d.size); // outer edge in % terms
                    var x = y(xVal); // outer edge in pixel space
                    return x;
                })
                .attr('y', function(d) { return x(d.x) + x.rangeBand() / 2; })
                .attr('dx', function(d) { return d.i ? 3 : -3; })
                .attr('dy', '.35em')
                .attr('text-anchor', function(d) { return d.i ? 'start' : 'end'; })
                .text(function(d) {
                    var xVal = d.y >= 0 ? (d.i && getPivot() == 0 ? 100 + d.sortval : d.y0) : d.y0 - d.size; // outer edge in % terms
                    var vals = [];
                    if (showPct()) vals.push((Math.abs(xVal)).toFixed(0) + '%');
                    if (showCount()) vals.push(d.count);
                    return vals.join(', ');
                });
        $('.series text.countpct').tipsy({gravity: 's', opacity: 1, html: true, title: barValueTooltip});
        drawLegend(gradingScale);
        setTimeout(forceLayout, 10);
    },

    /**
     * 
     */
    transitionBreakdownGraph: function(breakdownData, gradingScale) {
        var that = this;
        
        if (!breakdownData || !breakdownData.length || !that.breakdownGraph.x) return;

        var svg = that.breakdownGraph.svg,
            x = that.breakdownGraph.x,
            y = that.breakdownGraph.y,
            xAxis = that.breakdownGraph.xAxis,
            yAxis = that.breakdownGraph.yAxis,
            margins = that.breakdownGraph.margins;

        // works
        x = x.domain(breakdownData[0].map(function(d) { return d.x; }));
        svg.select('g.x.axis')
            .transition().duration(that.duration)
            .call(xAxis.scale(x)); // move labels
        svg.selectAll(".x.axis g text")
            .transition().duration(that.duration)
            .attr('text-anchor', 'start')
            .attr('x', -y(0) - margins.left); // stay left

        // works
        var series = svg.selectAll(".series")
            .data(breakdownData, scoreDataJoin(gradingScale)); // data join!
        series.transition().duration(that.duration)
            .style("fill", function(d, i) { return that.breakdownColorScale(i); });
        series.enter().insert("g", '.x.axis') // insert before x axis so we can still see the grid lines
            .classed("series", true).style("fill", function(d, i) { return that.breakdownColorScale(i); })
        series.exit().remove();

        // works
        var rect = series.selectAll("g rect.bar")
            .data(Object, function(d) { return d.x; }); // data join!
        rect.transition().duration(that.duration)
            .attr("y", function(d) { return x(d.x); })
            .attr("x", function(d) { return y(d.y0 - d.size); })
            .attr("width", function(d) { return y(d.size) - y(0); });
        rect.enter().append("rect").classed('bar', true)
                .attr("y", function(d) { return x(d.x); })
                .attr("x", function(d) { return y(d.y0 - d.size); })
                .attr("height", x.rangeBand())
            .transition().duration(that.duration)
                .attr("width", function(d) { return y(d.size) - y(0); });
        rect.exit().remove();
        $('.series rect.bar').tipsy({gravity: 'n', opacity: 1, html: true, className: 'white', title: studentTooltip});

        // average scores, works
        series.filter(function(d, i) { return i != breakdownData.length - 1; }).selectAll("rect.avg").remove(); // exit
        var avgRect = series.filter(function(d, i) { return i == breakdownData.length - 1; }).selectAll("rect.avg")
                .data(Object, function(d) { return d.x; });
        avgRect.enter().append("rect").classed('avg', true)
            .style("stroke", function(d) { return that.breakdownColorScale(scoreToIndex(d.totalscore / d.numscores, gradingScale)); })
            .style("fill", 'white')
            .attr("y", function(d) { return x(d.x) - 1; })
            .attr("x", function(d) { return y(d.totalscore / d.numscores) - 1; })
            .attr("width", 3) //function(d) { return y(5); }
            .attr("height", x.rangeBand() + 2);
        avgRect.transition().duration(that.duration)
            .style("stroke", function(d) { return that.breakdownColorScale(scoreToIndex(d.totalscore / d.numscores, gradingScale)); })
            .attr("y", function(d) { return x(d.x) - 1; })
            .attr("x", function(d) { return y(d.totalscore / d.numscores) - 1; });
        $('.series rect.avg').tipsy({gravity: 's', opacity: 1, html: true, className: 'white', title: avgTooltip});

        series.filter(function(d, i) { return i != 0 && i != breakdownData.length - 1; }).selectAll("text").remove(); // exit

        var text = series.filter(function(d, i) { return i == 0 || i == breakdownData.length - 1; }).selectAll("text")
            .data(Object);
        text.transition().duration(that.duration) // works
            .attr('x', function(d) {
                var xVal = d.i ? d.y0 : (getPivot() == 0 ? 0 : d.y0 - d.size); // outer edge in % terms
                var x = y(xVal); // outer edge in pixel space
                return x;
            })
            .attr('y', function(d) { return x(d.x) + x.rangeBand() / 2; })
            .text(function(d) {
                var xVal = d.y >= 0 ? (d.i && getPivot() == 0 ? 100 + d.sortval : d.y0) : d.y0 - d.size; // outer edge in % terms
                var vals = [];
                if (showPct()) vals.push(Math.abs(xVal).toFixed(0) + '%');
                if (showCount()) vals.push(d.count);
                return vals.join(', ');
            });

        text.enter().append("text") // works
            .attr('x', function(d) {
                var xVal = d.i ? d.y0 : (getPivot() == 0 ? 0 : d.y0 - d.size); // outer edge in % terms
                var x = y(xVal); // outer edge in pixel space
                return x;
            })
            .attr('y', function(d) { return x(d.x) + x.rangeBand() / 2; })
            .attr('dx', function(d) { return d.i ? 3 : -3; })
            .attr('dy', '.35em')
            .attr('text-anchor', function(d) { return d.i ? 'start' : 'end'; })
            .text(function(d) {
                var xVal = d.y >= 0 ? (d.i && getPivot() == 0 ? 100 + d.sortval : d.y0) : d.y0 - d.size; // outer edge in % terms
                var vals = [];
                if (showPct()) vals.push(Math.abs(xVal).toFixed(0) + '%');
                if (showCount()) vals.push(d.count);
                return vals.join(', ');
            });
        $('.series text').tipsy({gravity: 's', opacity: 1, html: true, className: 'white', title: barValueTooltip});
        
        setTimeout(function() { AssessmentGraphing.drawResultsBreakdown(breakdownData, gradingScale); }, 1000);
    },

    /**
     * Get the value of the curve set in the dom.
     * @return number
     */
    getCurveValue: function() {
        if (!this.curveVal) {
            this.curveVal = $('input[name="curve"]');
        }
        return parseFloat(this.curveVal.val() || 0);
    },

    showQuestionByQuestionResults: function(elem, studentResult, assessmentAnswer) {
        var data = lastData;
        var name = $(assessmentAnswer.name).text();
        var avg = assessmentAnswer.avg_score;
        var noPct = isNoPct(data);
        var testResult = '<h2>' + name + '<br/>' + (noPct ? avg : (avg).toFixed(0) + '%') + '</h2>'
            + '<table class="testResults"><tbody>';

        $.each(studentResult, function(q, a) {
            var question = data.results.assessmentQuestions[q];
            var points = a[0];
            var answer = a[1] || 'blank';

            testResult += '<tr><td>' + q + '.</td><td>';
            if (question.point_value == '0' && points == '0') {
                testResult += answer; // opinion
            } else if (question.correct_answer) {
                testResult += answer + (answer.toLowerCase() == question.correct_answer.toLowerCase() ? ''
                    : ' (' + question.correct_answer + ')');
            } else {
                testResult += points + (question.point_value ? ' out of ' + question.point_value : '');
            }
            testResult += '</td></tr>';
        });

        pinTooltip(elem, testResult + '</tbody></table>');
    }
};

window.AssessmentGraphing = AssessmentGraphing;

var Attachments = {
    
    init: function() {
        Attachments.setBindings();
    },
            
    add: function(object) {
        var attachmentContainer = $(Handlebars.renderTemplate('attachment', object));
        $(document).find('.attachments-container').append(attachmentContainer);
        attachmentContainer.find('input').val(JSON.stringify(object));
    },
            
    addMutiple: function(attachments) {
        _.each(attachments, function(attachment) {
            var attachmentContainer = $(Handlebars.renderTemplate('attachment', attachment));
            $(document).find('.attachments-container').append(attachmentContainer);
            attachmentContainer.find('input').val(JSON.stringify(attachment));
        });
    },
            
    remove: function() {
        var $this = $(this),
            id = $this.data('id'),
            attachmentClass = $this.data('class');
        
        $this.closest('.attachment-row').fadeOut(500, function() {
            this.remove();
        });
        
        if(id && String(id).length && attachmentClass && attachmentClass.length) {
            $.post('/activate/?type=' + attachmentClass + '&active=0&id=' + id);
        }
    },
    
    setBindings: function() {
        //remove
        $(document).on('click', '.attachments-container [rel="remove-attachment"]', this.remove);
        
        //download
    }
    
};

$(Attachments.init);

window.Attachments = Attachments;
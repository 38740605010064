var passwordValidator = require('password-validator');
// Create a schema
var validator = new passwordValidator();

// Add properties to it
validator
    .is().min(8)                                    // Minimum length 8
    .has().uppercase()                              // Must have uppercase letters
    .has().lowercase()                              // Must have lowercase letters
    .has().digits()                                 // Must have digits
    .has().symbols()                                // Must have symbols
    .has().not().spaces()                           // Should not have spaces

var Login = {
    api: {
        district: '/api/v1/district',
        guardianSignup: '/api/v1/guardian/signup',
        loginErors: '/api/v1/session/error',
        oauthProviders: '/api/v1/oauth2/providers'
    },
    init: function() {
        this.loginForm = $('form#login-form');
        this.districtNameContainer = $('.login .district-name');
        this.resetPasswordForm = $('form#reset-password-form');
        this.createGuardianAccountForm = $('form#create-guardian-account-form');
        this.guardianPasswordField = $('form#create-guardian-account-form [name="password"]');
        this.submitBtn = $('[rel="submit"]');
        this.submitPasswordResetBtn = $('[rel="submit-reset"]');
        this.submitCreateGuardianAccountBtn = $('[rel="submit-create-guardian-account"]');
        this.usernameField = $('form#login-form [name="username"]');
        this.resetPasswordUsernameField = $('form#reset-password-form [name="username"]');
        this.passwordField = $('form#login-form [name="password"]');
        this.resetPasswordLink = $('[rel="forgot-password"]');
        this.createGuardianAccountLink = $('[rel="create-guardian-account"]');
        this.showLoginFormLink = $('[rel="show-login-form"]');
        this.signInEmailLink = $('[rel="sign-in-email"]');
        this.logInLink = $('[rel="back-to-login"]');

        if(!this.loginForm.length) { return; }

        this.setBindings();
        this.loadDistrictName()
            .then(this.loadSsoProviders)
            .then(this.loadInitialErrors)
            .then(this.deleteErrors);
    },

    setBindings: function() {
        this.submitBtn.click(this.handleSubmitClick);
        this.submitPasswordResetBtn.click(this.handlePasswordSubmitClick);
        this.submitCreateGuardianAccountBtn.click(this.handleCreateGuardianAccountSubmitClick);
        this.resetPasswordLink.click(this.showPasswordResetForm);
        this.createGuardianAccountLink.click(this.showCreateGuardianAccountForm);
        this.showLoginFormLink.click(this.showLoginForm);
        this.signInEmailLink.click(this.showLoginWithEmailForm);
        this.logInLink.click(this.showLoginForm);

        this.usernameField.keyup(function(e) {
            if(e.keyCode === 13) {
                Login.handleSubmitClick(e);
            }
        });

        this.passwordField.keyup(function(e) {
            if(e.keyCode === 13) {
                Login.handleSubmitClick(e);
            }
        });

        this.createGuardianAccountForm.keyup(function(e) {
            if(e.keyCode === 13) {
                Login.handleCreateGuardianAccountSubmitClick(e);
            }
        });

        this.guardianPasswordField.keyup(function(e) {
            Login.checkCapsLock(e.originalEvent);
            Login.validatePassword($(this).val());
        });

        this.resetPasswordUsernameField.keyup(function(e) {
            if(e.keyCode === 13) {
                Login.handlePasswordSubmitClick(e);
            }
        });

        this.loginForm.submit(function() {
            return false;
        });
        this.resetPasswordForm.submit(function() {
            return false;
        });
    },

    loadInitialErrors: function () {
        return $.get(Login.api.loginErors)
            .then(Login.displayErrors);
    },

    deleteErrors: function () {
        return $.ajax({
            url: Login.api.loginErors,
            type: 'DELETE'
        });
    },

    loadDistrictName: function () {
        return $.get(Login.api.district)
            .then(getFirst)
            .then(displayOnPage);

        function getFirst(districts) {
            return _.first(districts);
        }

        function displayOnPage(district) {
            Login.districtNameContainer.text(district.name);
        }
    },

    loadSsoProviders: function () {
        return $.get(Login.api.oauthProviders)
            .then(_.toArray)
            .then(displayLoginElems)
            .then(renderSsoButtons)
            .then(appendToPage)
            .then(hideLoading);

        function displayLoginElems(providers) {
            if (providers.length > 0) {
                $('.show-if-has-sso-providers').show();
            } else {
                $('.show-when-no-sso-providers').show();
            }
            return providers;
        }

        function renderSsoButtons(providers) {
            return Handlebars.renderTemplate('sso-buttons', { providers: providers });
        }

        function appendToPage(html) {
            $('.error-container').after(html);
        }

        function hideLoading() {
            $('.loading-container').hide();
        }
    },

    handleSubmitClick: function(e) {
        e.preventDefault();

        $('input').removeClass('error');

        if(!Login.validate()) { return; }

        Login.submitBtn.text('Working...');

        Login.loginForm.find(".alert").remove();
        Login.resetPasswordForm.find(".alert").remove();

        Login.attemptLogin();
    },

    handlePasswordSubmitClick: function(e) {
        e.preventDefault();

        $('input').removeClass('error');

        Login.submitPasswordResetBtn.text('Working...');

        Login.loginForm.find(".alert").remove();
        Login.resetPasswordForm.find(".alert").remove();

        Login.attemptPasswordReset();
    },

    handleCreateGuardianAccountSubmitClick: function(e) {
        e.preventDefault();

        // if the required attr is there on page load all the inputs will be red
        Login.createGuardianAccountForm
            .find('.required')
            .attr('required','true');

        if (!Login.createGuardianAccountForm[0].checkValidity()) {
            Login.submitCreateGuardianAccountBtn.text('Try again');
            return;
        }

        $('input').removeClass('error');

        Login.submitCreateGuardianAccountBtn.text('Working...');

        Login.loginForm.find(".alert").remove();
        Login.createGuardianAccountForm.find(".alert").remove();

        Login.attemptCreateGuardianAccount();
    },

    // make sure password has:
    // 1+ uppercase letter
    // 1+ lowercase letter
    // 1+ number
    // 1+ special character
    // 8+ characters
    checkPassword: function (password) {
        let reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
        return reg.test(password);
    },

    // make sure password has:
    // 1+ uppercase letter
    // 1+ lowercase letter
    // 1+ number
    // 1+ special character
    // 8+ characters
    validatePassword: function (password) {
        let requirements = ['min', 'uppercase', 'lowercase', 'digits', 'symbols'];
        // check password against requirements and list out anything it fails
        let errors = validator.validate(password, { list: true });
        // consider any requirement not in the errors list as fulfilled
        let fulfilled = _.filter(requirements, req => !_.includes(errors, req));
        _.each(fulfilled, fulfilledReq => {
            $(`.password.${fulfilledReq}`).addClass('fulfilled');
        });
        _.each(errors, error => {
            $(`.password.${error}`).removeClass('fulfilled');
        });
    },

    checkCapsLock: function (e) {
        // possible getModifierState is undefined e.g. if a browser autocomplete was used
        var capsLockState = e.getModifierState ? e.getModifierState("CapsLock") : null;
        if (capsLockState) {
            document.getElementById('caps-lock-indicator').style.visibility = 'visible';
        } else {
            document.getElementById('caps-lock-indicator').style.visibility = 'hidden';
        }
    },

    attemptLogin: function() {
        var data = {
            username: Login.usernameField.val(),
            password: Login.passwordField.val()
        };

        $.post('/login/attempt', data)
            .success(Login.handleLoginSuccess)
            .fail(Login.handleLoginFailure);
    },

    handleLoginSuccess: function(data) {
        if (!data.success) {
            Login.handleLoginErrors(data);
            return;
        }

        const termIdMatch = window.location.search.match(/term_id=(\d+)/);
        const termId = termIdMatch ? termIdMatch[1] : null;
        const partial = data.results.url;

        const url = window.location.origin + partial;

        window.location.href = url + (termId ? (url.match(/\?/) ? '&' : '?') + 'term_id=' + termId : '');
    },

    handleLoginFailure: function(xhr) {
        Login.handleLoginErrors(_.get(xhr, 'responseJSON', {}));
    },

    handleLoginErrors: function(data) {
        var errors = data.errors || ['Something went wrong. Please try again.'];

        Login.submitBtn.text('Try again');

        Login.displayErrors(errors);
    },

    displayErrors: function (errors) {
        var errorContainer = $('.error-container');
        errorContainer.html('');

        _.each(errors, function (error) {
            errorContainer.append((Handlebars.renderTemplate("alert", { type: "error", message: error, removable: true })));
        });
    },

    attemptPasswordReset: function() {
        var data = {
            username: Login.resetPasswordUsernameField.val()
        };
        $.post('/password/reset/attempt', data)
            .success(Login.handlePasswordResetSuccess)
            .fail(Login.handlePasswordResetFailure);
    },

    handlePasswordResetSuccess: function(data) {
        if(!data.success) {
            Login.handlePasswordResetErrors(data);
            return;
        }

        Login.submitPasswordResetBtn.text('Reset Password');

        _.each(data.info, function (info) {
            Login.resetPasswordForm.prepend(Handlebars.renderTemplate("alert", { type: "info", message: info, removable: true }));
        });
    },

    handlePasswordResetFailure: function(xhr) {
        Login.handlePasswordResetErrors(_.get(xhr, 'responseJSON', {}));
    },

    handlePasswordResetErrors: function(data) {
        var errors = data.errors || ['Something went wrong. Please try again.'];

        Login.submitPasswordResetBtn.text('Try again');

        _.each(errors, function (error) {
            Login.resetPasswordForm.prepend(Handlebars.renderTemplate("alert", { type: "error", message: error, removable: true }));
        });
    },

    attemptCreateGuardianAccount: function() {
        var formData = Login.createGuardianAccountForm.serializeJSON();

        var { password, password_confirm } = formData;

        if (password !== password_confirm) {
            return Login.handleCreateGuardianAccountErrors({errors: ['Passwords do not match.']});
        }

        if (!Login.checkPassword(password)) {
            return Login.handleCreateGuardianAccountErrors({errors: ['Password does not meet criteria.']});
        }

        var data = {
            username: _.get(formData, 'email'),
            email: _.get(formData, 'email'),
            password: password,
            first_name: _.get(formData, 'first_name'),
            last_name: _.get(formData, 'last_name'),
            student_access_code: _.get(formData, 'student_access_code')
        };

        $.post(Login.api.guardianSignup, data)
            .success(_.partial(Login.handleCreateGuardianAccountSuccess, password))
            .fail(Login.handleCreateGuardianAccountFailure);
    },

    handleCreateGuardianAccountSuccess: function(password, data) {
        if(!data.success) {
            Login.handleCreateGuardianAccountErrors(data);
            return;
        }

        let newUser = _.get(data, 'results.user');
        let loginData = {
            username: newUser.username,
            password: password
        };

        // log in here using newly created user
        $.post('/login/attempt', loginData)
            .success(Login.handleLoginSuccess)
            .fail(Login.handleLoginFailure);
    },

    handleCreateGuardianAccountFailure: function (xhr) {
        Login.handleCreateGuardianAccountErrors(_.get(xhr, 'responseJSON', {}));
    },

    handleCreateGuardianAccountErrors: function(data) {
        var errors = data.errors || ['Something went wrong. Please try again.'];

        Login.submitCreateGuardianAccountBtn.text('Try again');

        _.each(errors, function (error) {
            Login.createGuardianAccountForm.prepend(Handlebars.renderTemplate("alert", { type: "error", message: error, removable: true }));
        });

        $(".alert").get(0).scrollIntoView();
    },

    showPasswordResetForm: function(e) {
        e.preventDefault();
        Login.createGuardianAccountLink.hide();
        Login.resetPasswordUsernameField.val(Login.usernameField.val());
        Login.loginForm.fadeOut(function() {
            Login.resetPasswordForm.fadeIn();
        });
    },

    showCreateGuardianAccountForm: function(e) {
        e.preventDefault();
        $('.btn-sso').hide();
        Login.createGuardianAccountForm.find('input').val('');
        Login.resetPasswordForm.hide();
        Login.loginForm.hide();
        Login.createGuardianAccountLink.hide();
        Login.signInEmailLink.hide();
        Login.createGuardianAccountForm.fadeIn();
    },

    showLoginForm: function(e) {
        e.preventDefault();
        Login.resetPasswordForm.fadeOut(function() {
            Login.loginForm.fadeIn();
            Login.createGuardianAccountLink.show();
            $('.btn-sso').show();
            Login.resetPasswordUsernameField.val('');
        });
        Login.createGuardianAccountForm.fadeOut(function() {
            $('.btn-sso').show();
            Login.createGuardianAccountLink.show();
            Login.loginForm.fadeIn();
        });
    },

    showLoginWithEmailForm: function(e) {
        e.preventDefault();
        $(this).hide();
        Login.loginForm.fadeIn();
    },

    validate: function() {
        var passed = true;

        if(!this.passwordField.val().length) {
            this.passwordField.addClass('error');

            passed = false;
        }

        if(!this.usernameField.val().length) {
            this.usernameField.addClass('error');

            passed = false;
        }

        return passed;
    }

};

$(function() {
    Login.init();
});

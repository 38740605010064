//Ajax Tooltip script: By JavaScript Kit: http://www.javascriptkit.com
//Last update (July 10th, 08'): Modified tooltip to follow mouse, added Ajax "loading" message.

var ajaxtooltip = {
    fadeeffect: [true, 300], //enable Fade? [true/false, duration_milliseconds]
    useroffset: [0, 0], //additional x and y offset of tooltip from mouse cursor, respectively
    loadingHTML: 'Loading...',
    tooltips: {},

    gettip: function($target) {
        var sourceUrl = $target.data('ajaxUrl'),
            tooltipClass = $target.data('tooltipclass'),
            historySpan = $($target[0]);

        if (!this.tooltips[sourceUrl]) {
            var outerThis = this;
            var $tooltip = $('<div class="ajaxtooltip ui-corner-all ' + tooltipClass + '"></div>').appendTo('body')
                .mouseenter(function(e) { $tooltip.data('keepAlive', 1); })
                .mouseleave(function(e) { $tooltip.data('keepAlive', 0); setTimeout(function() { outerThis.hidetip($tooltip, e); }, 250); });
            this.tooltips[sourceUrl] = $tooltip;
            $tooltip.data({
                'target': $target,
                'once': $target.data('tooltiponce') == 1,  // only show this tooltip once and then remove on mouseexit
            });
        }

        return this.tooltips[sourceUrl];
    },

    removetip: function($target) {
        var sourceUrl = $target.data('ajaxUrl');

        if (this.tooltips[sourceUrl]) {
            this.tooltips[sourceUrl].remove();
            delete this.tooltips[sourceUrl];
        }
    },

    positiontip: function($tooltip, $target, e) {
        var windowWidth = $(window).innerWidth();
        var windowHeight = $(window).innerHeight();
        var targetPos = $target.offset();
        var targetWidth = $target.outerWidth(true);
        var tooltipWidth = $tooltip.outerWidth(true);
        var tooltipHeight = $tooltip.outerHeight(true);
        var left = targetPos.left + targetWidth + 8;
        var top = targetPos.top - 40;//(tooltipHeight / 4);
        var visibleWidthStart = $(window).scrollLeft();
        var visibleWidthEnd = visibleWidthStart + windowWidth;
        var visibleHeightStart = $(window).scrollTop();
        var visibleHeightEnd = visibleHeightStart + windowHeight;
        var marginOfError = 0;

        top = Math.min(top, visibleHeightEnd - tooltipHeight - marginOfError);
        top = Math.max(top, visibleHeightStart + marginOfError);

        // if there's not room to display it to the right and there is room to display it to the left,
        // display it on the left
        if (left + tooltipWidth > visibleWidthEnd - marginOfError
                && targetPos.left - tooltipWidth - marginOfError > visibleWidthStart) {
            left = targetPos.left - tooltipWidth - marginOfError;
        }

        $tooltip.css({
            left: left,
            top: top
        });

//        $tooltip.css('top', Math.min(e.pageY, $tooltip.css('top')));
    },

    showtip: function($tooltip, e) {
        $tooltip.data({pageXOffset: window.pageXOffset, pageYOffset: window.pageYOffset});

        if (this.fadeeffect[0]) {
            $tooltip.hide().fadeIn(this.fadeeffect[1]);
        } else {
            $tooltip.show();
        }
    },

    hidetip: function($tooltip, e) {
        if ($tooltip.data('keepAlive') == 1) return;

        // Go back to where I was on the page when I hovered to get the tooltip.
        // Fixes an issue where I:
        // 1) Hover over a link to show a tooltip
        // 2) Then scroll down past where the bottom of the page was before the
        //    tooltip came into view
        // 3) Then move away from the tooltip (it hides itself)
        // 4) Then hover again over the link to show the tooltip and the browser
        //    scrolls way down the page, but now my mouse isn't over the tooltip
        //    anymore so it just flickers and is hidden again.
        // This scrollTo effectively resets where the browser thinks I want to
        // be scrolled to on the page so it doesn't jump me down the page when
        // I hover over the link a second time.
        if ($tooltip.data('pageXOffset') !== undefined
                && $tooltip.data('pageYOffset') !== undefined) {
            window.scrollTo($tooltip.data('pageXOffset'), $tooltip.data('pageYOffset'));
        }

        if ($tooltip.data('once')) {
            this.removetip($($tooltip.data('target')));
        } else if (this.fadeeffect[0]) {
            $tooltip.fadeOut(this.fadeeffect[1]);
        } else {
            $tooltip.hide();
        }
    },

    cancelAll: function() {
        _.each(this.tooltips, tooltip => tooltip.data('hide', true))
    },
};

jQuery(document).ready(function() {
    function getSourceUrl($target) {
        var sourceUrl = '';

        if ($target.attr('tooltip')) {
            sourceUrl = $target.attr('tooltip');
        } else if ($target.attr('href')) {
            sourceUrl = $target.attr('href');
        } else {
            log("programmer error: can't create tooltip: no url specified");
            return;
        }

        sourceUrl += (sourceUrl.indexOf('?') >= 0 ? '&' : '?');
        if (sourceUrl.indexOf('printable') < 0) {
            sourceUrl += 'printable=1&';
        }
        sourceUrl += 'tooltip=1';
        return sourceUrl;
    }

    $(document).on('mouseenter', '[tooltip]:not(.disabled):not([disabled])', function() { // find all links with "title=ajax:" declaration
        var $target = $(this);

        if ($target.data('ajaxed')) return;
        $target.data('ajaxed', 1);

        $target.hoverIntent({
            sensitivity: 4,
            interval: 250,
            timeout: 250, // delay before showing the tooltip
            over: function(e) {
                var $target = $(this);

                //get source & set on element
                var sourceUrl = getSourceUrl($target);
                $target.data('ajaxUrl', sourceUrl);

                // get tip after ajaxUrl is set
                var $tooltip = ajaxtooltip.gettip($target);

                $tooltip.data('hide', false);

                if ($tooltip.data('loading')) return;

                if (!$tooltip.data('loadsuccess') || $target.attr('force-refresh') == false) { // first time fetching Ajax content for this tooltip?
                    $tooltip.data('loading', true);
//                    $tooltip.html(ajaxtooltip.loadingHTML).show();
                    $tooltip.load(sourceUrl, '', function() {
                        $tooltip.data('loadsuccess', true);
                        $tooltip.data('loading', false);
                        $tooltip.find('[tooltip]').removeAttr('tooltip');
                        $tooltip.bind('tooltip-loaded', function() {
                            ajaxtooltip.positiontip($tooltip, $target, e);
                            ajaxtooltip.showtip($tooltip, e);
                            ajaxtooltip.positiontip($tooltip, $target, e); // hack to make sure it doesn't show up below the page
                            if ($tooltip.data('hide')) {
                                ajaxtooltip.hidetip($tooltip, e);
                            }
                        });
                        if ($tooltip.html() === '') {
                            $tooltip.remove();
                        }
                        $(this).trigger('tooltip-loaded');
                    });

                    // remove 'force-refresh' after we force-refresh the tooltip
                    $target.removeAttr('force-refresh');
                } else {
                    ajaxtooltip.positiontip($tooltip, $target, e);
                    ajaxtooltip.showtip($tooltip, e);
                }
            },
            out: function(e) {
                var $target = $(this);
                var $tooltip = ajaxtooltip.gettip($target);

                ajaxtooltip.hidetip($tooltip, e);
                $tooltip.data('hide', true);
            }
        });
        $(this).trigger('mouseenter'); // make it show up the first time
    })
});

window.ajaxtooltip = ajaxtooltip;

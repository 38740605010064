var StudentAttrs = {
    studentAttrsApi: '/api/v1/student-attrs/',
    studentAttrTypesApi: '/api/v1/student-attr-types/',
    decodesApi: '/api/v1/decodes/',

    init: function() {
        this.schoolId = $('input[name="school_id"]').val();
        this.studentId = $('input[name="student_id"]').val();
        this.editForm = $('#edit-form');
        
        StudentAttrs.setupHtml();
        StudentAttrs.setBindings();
    },
    
    setupHtml: function() {
        var url = this.studentAttrTypesApi,
            studentAttrTypeArgs = {
                active: 1,
                school_ids: this.schoolId
            };
        
        $.ajax({
            type: 'GET',
            url: url,
            data: studentAttrTypeArgs,
            complete: StudentAttrs.handleResponse(StudentAttrs.handleLoadStudentAttrTypes)
        });
    },
    
    handleResponse: function(fn) {
        return function (jqXHR) {
            var data = $.parseJSON(jqXHR.responseText);
            
            return fn.call(StudentAttrs, data);
        };
    },
    
    handleLoadStudentAttrTypes: function(data) {
        var studentAttrTypes = data.results.student_attr_types,
            url = this.studentAttrsApi,
            studentAttrArgs = {
                active: 1,
                student_ids: this.studentId
            };
        
        $.ajax({
            type: 'GET',
            url: url,
            data: studentAttrArgs,
            complete: StudentAttrs.handleResponse(StudentAttrs.handleLoadStudentAttrs(studentAttrTypes))
        });
    },
    
    handleLoadStudentAttrs: function(studentAttrTypes) {
        return function(data) {
            var studentAttrs = data.results.student_attrs,
                studentAttrTypeMap = _.groupBy(studentAttrTypes, 'display_group'),
                groupKeys = _.union(['student', 'home', 'school', 'college', 'accommodations', 'null'], _.keys(studentAttrTypeMap)),
                attrMap = {},
                sections = '',
                j = 0;

            _.each(studentAttrs, function(studentAttr) {
                var key = studentAttr['student_attr_type_id'];

                if (!(key in attrMap)) {
                    attrMap[key] = studentAttr;
                }
            });

            _.each(groupKeys, function(groupKey, i) {
                var groupAttrTypes = studentAttrTypeMap[groupKey],
                    title = (groupKey === 'null' ? '<blank>' : groupKey).toUpperCase(),
                    args = {
                        title: title,
                        color: j % 2 ? 'light-gray' : 'gray',
                        student_attr_types: groupAttrTypes,
                        attr_map: attrMap
                    };

                if (groupAttrTypes) {
                    sections += Handlebars.renderTemplate('student-attrs-single-student-edit', args);
                    j++;
                }
            });

            this.editForm.html(sections);
            this.editForm.find('input').autocomplete({
                minLength: 0,
                source: StudentAttrs.autocompleteSearch,
                select: StudentAttrs.autocompleteSelect
            });
        };
    },
    
    autocompleteSearch: function(request, response) {
        var url = StudentAttrs.decodesApi,
            $input = this.element,
            term = request.term,
            attrKey = $input.data('decode-attr-key'),
            args = {
                attr_keys: attrKey,
                code_or_decode_like: "%" + term + "%"
            };
        
        if (attrKey) {
            if (attrKey.toLowerCase() === 'binary') {
                return response([
                    {value: '0', label: '0: No'},
                    {value: '1', label: '1: Yes'},
                ]);
            } else {
                $.ajax({
                    type: 'GET',
                    url: url,
                    data: args,
                    complete: StudentAttrs.handleResponse(StudentAttrs.handleAutocomplete(response))
                });
            }
        }
    },
    
    handleAutocomplete: function(response) {
        return function(data) {
            var decodes = data.results.decodes,
                arr = [];

            _.each(decodes, function(decode) {
                arr.push({
                    value: decode.code,
                    label: decode.code + ': ' + decode.decode
                });
            });

            return response(arr);
        };
    },
    
    autocompleteSelect: function(event, ui) {
        setTimeout(function() { $(event.target).trigger('change') }, 1);
    },
    
    setBindings: function() {
        // seems to handle all the cases (mouse paste, typing, etc)
        this.editForm.on('input change keyup keydown blur', 'input, select', function(e) {
            var input = $(this),
                changed = input.val() !== "" + input.data('orig');
            
            input.attr('changed', changed ? 1 : 0);
        });
        
        // show the autocomplete options when clicking into a box
        this.editForm.on('focus', 'input, select', function(e) {
            var input = $(this);
            
            input.autocomplete('search', '');
        });
        
        // save changed attributes
        $('[rel="post-all"]').click(function(e) {
            StudentAttrs.editForm.find('[changed="1"]').each(function() {
                var url = StudentAttrs.studentAttrsApi,
                    input = $(this),
                    studentId = $('[name="student_id"]').val(),
                    args = {
                        attr_key: input.attr('name'),
                        student_id: studentId,
                        value: input.val()
                    };

                $.ajax({
                    type: 'POST',
                    url: url,
                    data: args,
                    complete: StudentAttrs.handleResponse(StudentAttrs.handleSave(input))
                });
            });
        });
        
        // save hotkey
        $(document).on('keydown', null, getSimpleShortcutLabelPrefix() + 's', function(e) {
            e.preventDefault();
            $('[rel="post-all"]:first').click();
        });
    },
    
    handleSave: function(input) {
        return function(data) {
            var studentAttr = data.results.student_attrs[0],
                attrLabel = input.attr('title'),
                value = input.val(),
                message = studentAttr.active == 1
                    ? "Updated " + attrLabel + ": " + studentAttr.value + "."
                    : "Removed attribute " + attrLabel + ".";

            Growl.info({message: message});
            input.attr('changed', 0).attr('value', value).attr('data-orig', value).data('orig', value);
        }
    }
};

    
window.Tss = window.Tss || {};
window.Tss.StudentAttrs = StudentAttrs;

import _ from 'tss/lodash'

import Api from './Api'

const ClassAbsenceService = {
    totalsStudentEndpoint: '/api/v1/class-absence-totals/student/',
    bulkTotalsPctEndpoint: '/api/v1/bulk/class-absence-totals/pct/',
    bulkEndpoint: '/api/v1/bulk/class-absences',
    endpoint: '/api/v1/class-absences',

    get: async function(id = null, params = {}) {
        const endpoint = this.endpoint + (id ? '/' + id : '')

        return await Api.get(endpoint, params)
    },

    update: async function(params) {
        return await Api.put(`${this.endpoint}/${params.class_absence_id}`, params)
    },

    getData: async function(id) {
        return await Api.post(`/class-absences/data?class_absence_filter=class_absence_id%3D${id}`)
    },

    deactivate: async function(id) {
        return await Api.post(`/obj/edit?active=0&with_related=1&class=ClassAbsence&id=${id}`)
    },

    getBulkAttendancePct: async function(params = []) {
        return await Api.post(this.bulkTotalsPctEndpoint, { bulk_data: params });
    },

    /**
     * params should be array of student_id, date, absence_type_id, section_period_id, active, class_absence_id = null
     *
     * @param  {Array}  [params=[]] [description]
     * @return {[type]}             [description]
     */
    bulkUpdateClassAbsences: async function(params = []) {
        return await Api.post(this.bulkEndpoint, { bulk_data: params })
    },

    getTotals: async function(studentId, params = {}) {
        const response = await Api.get(this.totalsStudentEndpoint + studentId, params)

        return _.get(response, 'results')
    },

    getGrid: async function(student, params = {}, absenceTypes = []) {
        const results = await this.getTotals(student, params)

        return this.makeGrid(results, absenceTypes)
    },

    makeGrid: function(results, absenceTypes) {
        var rowData = []
        var colData = []
        var cellData = []
        var bulkData = {}
        var courses = results.courses || []

        courses.map((course, i) => {
            cellData[i] = []

            if (!(i in rowData)) {
                rowData[i] = {
                    key: course.section_period_ids,
                    url: `/absences/class?section_period_id=${course.section_period_id}&staff_member_id=`,
                    display_name: course.display_name
                }
            }

            results.dates.map((dateInfo, j) => {
                if (!(j in colData)) {
                    colData[j] = {
                        key: dateInfo.key,
                        clazz: dateInfo.is_requested_date ? 'requested-date' : '',
                        display_name: dateInfo.value,
                        is_holiday: dateInfo.is_holiday,
                        // holidayDescription: dateInfo.is_holiday ? (dateInfo.holiday_description || 'Not a School Day') : '',
                    }
                }

                let cellInfo = this.getClassAbsenceDisplay(
                    course.section_period_ids,
                    dateInfo,
                    results,
                    absenceTypes
                )

                const cellKey = `cell-${rowData[i].key}-${colData[j].key}`
                _.map(cellInfo, c => c.cellKey = cellKey)
                cellInfo.cellKey = cellKey
                cellData[i][j] = cellInfo
                bulkData[dateInfo.key] = (bulkData[dateInfo.key] || []).concat(cellInfo)
            })
        })

        return { rowData, colData, cellData, bulkData }
    },

    getClassAbsenceDisplay: function(sectionPeriodIds, dateInfo, results, absenceTypes) {
        let classAbsenceTotalsByDate = results.class_absence_totals
        let classAbsencesByDate = results.class_absences
        let sectionPeriods = results.section_periods
        let schedules = results.schedules
        let tz = results.settings.tz
        let date = dateInfo.key
        let filterBySectionPeriod = o => _.indexOf(sectionPeriodIds, parseInt(o.section_period_id)) >= 0
        let classAbsences = _.filter(_.get(classAbsencesByDate, date), filterBySectionPeriod)
        let classAbsenceTotals = _.filter(_.get(classAbsenceTotalsByDate, date), filterBySectionPeriod)
        let schedule = _.get(schedules, date) || []
        let thisPeriodSchedule = _.filter(schedule, filterBySectionPeriod) || []
        let onTheSchedule = thisPeriodSchedule.length
        let weDontHaveTheSchedule = !schedule.length
        let filterByForAttendance = sectionPeriodId => _.get(sectionPeriods, [sectionPeriodId, 'no_attendance']) == 0
        let forAttendanceSectionPeriodIds = _.filter(sectionPeriodIds, filterByForAttendance)
        let forAttendance = forAttendanceSectionPeriodIds.length
            && (onTheSchedule || weDontHaveTheSchedule)
        let val = dateInfo.is_holiday
                ? (dateInfo.holiday_description || 'Not a School Day')
                : (forAttendance
                    ? 'Present - Not Taken'
                    : '')
        let sortval = thisPeriodSchedule.length
            ? thisPeriodSchedule[0].start_time
            : this.getFirstStartTime(schedules, filterBySectionPeriod)
        let name = 'SectionPeriod_' + sectionPeriodIds
        let getClassAttendanceDataForClassAbsence = classAbsence => {
            let absenceType = classAbsence.absence_type

            return this.getClassAttendanceData(
                classAbsence.section_period_id,
                date,
                classAbsence.active == 1
                    ? absenceType.code || absenceType.name || 'Present'
                    : 'Present',
                sectionPeriods,
                classAbsence,
                tz
            )
        }
        let getClassAttendanceDataForClassAbsenceTotal = classAbsenceTotal => this.getClassAttendanceData(
            classAbsenceTotal.section_period_id,
            date,
            'Present',
            sectionPeriods,
            classAbsenceTotal,
            tz
        )
        let getClassAttendanceDataForSectionPeriodId = sectionPeriodId => this.getClassAttendanceData(
            sectionPeriodId,
            date,
            val,
            sectionPeriods
        )

        if (classAbsences.length || classAbsenceTotals.length || onTheSchedule) {
            let scheduleDataBySectionPeriod = _.chain(thisPeriodSchedule)
                .map('section_period_id')
                .filter(filterByForAttendance)
                .map(getClassAttendanceDataForSectionPeriodId)
                .keyBy('section_period_id')
                .value()
            let classAbsencesDataBySectionPeriod = _.chain(classAbsences)
                .map(getClassAttendanceDataForClassAbsence)
                .keyBy('section_period_id')
                .value()
            let classAbsenceTotalsDataBySectionPeriod = _.chain(classAbsenceTotals)
                .map(getClassAttendanceDataForClassAbsenceTotal)
                .keyBy('section_period_id')
                .value()

            // overwrite schedule with class absence totals with class absences
            // by section period
            val = _.chain(scheduleDataBySectionPeriod)
                .assign(classAbsenceTotalsDataBySectionPeriod)
                .assign(classAbsencesDataBySectionPeriod)
                .values() // make array from keyed-map
                .sortBy('from_date')
                .value()
        } else if (weDontHaveTheSchedule) { // only show all sections if we don't have a schedule for this date at all
            let sectionPeriodId = _.get(forAttendanceSectionPeriodIds, 0)
                ||  _.get(sectionPeriodIds, 0)

            if (sectionPeriodId) {
                val = getClassAttendanceDataForSectionPeriodId(sectionPeriodId)
            }
        } else {
            val = ''
        }

        val = _.isString(val) ? { display_name: val } : val
        val = _.isArray(val) ? val : [val]
        _.map(val, v => {
            v.sortval = sortval
            v.is_holiday = dateInfo.is_holiday
            v.date = date
        })

        return val
    },

    getFirstStartTime: function(schedules, filterBySectionPeriod) {
        let foundStartTime
        let dates = _.keys(schedules).sort()

        _.each(dates, function(date) {
            let filteredSchedule = _.filter(schedules[date], filterBySectionPeriod)

            if (filteredSchedule.length) {
                foundStartTime = filteredSchedule[0].start_time
                return false // break
            }
        })

        return foundStartTime || '99:99:99'
    },

    getClassAttendanceData: function(sectionPeriodId, date, val, sectionPeriods, obj, tz) {
        let sectionPeriod = _.get(sectionPeriods, sectionPeriodId)
        let noAttendance = _.get(sectionPeriod, 'no_attendance', 0) == 1
        let periodId = _.get(sectionPeriod, 'period_id')
        let classAbsenceId = obj
            ? obj.class_absence_id
            : undefined
        let classAbsenceTotalId = obj
            ? obj.class_absence_total_id
            : undefined
        let canIEdit = obj && obj.hasOwnProperty('can_i_edit')
            ? obj.can_i_edit
            : true
        let key = obj
            ? (classAbsenceId
                ? `ClassAbsence_${classAbsenceId}`
                : `ClassAbsenceTotal_${classAbsenceTotalId}`)
            : `SectionPeriod_${sectionPeriodId}`
        let displayName = val
            || (noAttendance ? `n/a` : val)
        let url = `/absences/class?section_period_id=${sectionPeriodId}&date=${date}&staff_member_id=`
        let active = obj && obj.active == 1
        let absenceTypeId = active
            ? _.get(obj, 'absence_type_id') || null
            : null
        let absenceType = active
            ? _.get(obj, 'absence_type')
            : null
        let absenceTypeCode = active
            ? _.get(obj, 'absence_type.code')
            : ''
        let absenceTypeName = active
            ? _.get(obj, 'absence_type.name')
            : 'Present'
        let absenceDetails = absenceTypeName
            ? ` - ${absenceTypeName}`
            : ''
        let teacherName = _.get(obj, 'staff_member.last_name')
        let staffMemberId = _.get(obj, 'staff_member_id')
        let teacherDisplayName = _.get(obj, 'staff_member.display_name')
        let fromDate = _.get(obj, 'from_date')
        let fromDateDisplay = fromDate && tz
            ? this.utcToTz(fromDate, tz).format('ddd, MMM Do @ h:mma')
            : ''
        let title = teacherName && fromDateDisplay
            ? `${teacherName} on ${fromDateDisplay}${absenceDetails}`
            : noAttendance
            ? `Attendance intentionally isn't taken for this period in this course.`
            : ''
        let comments = classAbsenceId
            ? obj.comments
            : undefined

        return {
            section_period_id: sectionPeriodId,
            period_id: periodId,
            class_absence_id: classAbsenceId,
            class_absence_total_id: classAbsenceTotalId,
            can_i_edit: canIEdit,
            date: date,
            key: key,
            url: url,
            display_name: displayName,
            absence_type_id: absenceTypeId,
            absence_type: absenceType,
            absence_type_code: absenceTypeCode,
            title: title,
            teacher_display_name: teacherDisplayName,
            staff_member_id: staffMemberId,
            comments: comments,
            no_attendance: noAttendance,
            from_date: fromDate,
        }
    },

    utcToTz: function(fromDate, tz) {
        return moment.tz(fromDate, 'UTC').tz(tz)
    },
}

export default ClassAbsenceService

(function ($, window, undefined) {"use strict";

	var behaviors = window.Behaviors = {};

	behaviors.config = {
		tableOptions: {
            widgets: ["zebra","filter"],
            widgetOptions: {
                filter_columnFilters: false,
                filter_saveFilters: false
            },
            sortList: [[1,0],[2,0],[3,0],[4,0],[5,0]],
            headers: {
                1: { sorter: "name" },
                2: { sorter: "attCode" },
                3: { sorter: "behavior" },
                4: { sorter: "behavior" },
                5: { sorter: "behavior" }
            }
        },
        tableParsers: {
            name: {
                id: "name",
                is: function (s) {
                    return false;
                },
                format: function (text, table, cell) {
                    return $(cell).parent().hasClass("absent") ? "zzz" + text : text;
                },
                type: "text"
            },
            attCode: {
            	id: "attCode",
            	is: function (s) {
                    return false;
                },
                format: function (text, table, cell) {
                    return text === "Present" ? "zzz" : text;
                },
                type: "text"
            },
            behavior: {
            	id: "behavior",
            	is: function (s) {
                    return false;
                },
                format: function (text, table, cell) {
                	var mode = behaviors.getTableMode();
                    return $(cell).find(".badge-" + mode).text();
                },
                type: "numeric"
            }
        },
        tablePagerOptions: {
            output: "{page} of {totalPages}"
        },
        resetDialogOptions: {
			width: 600,
			modal: true,
	        autoOpen: false,
	        resizable: false,
	        draggable: false,
	        dialogClass: "minimal-dialog",
	        buttons: [{
	        	text: "Cancel",
	        	click: function () {
	        		$(this).dialog("close");
	        	}
	        },{
	        	text: "Reset",
	        	click: function () {
	        		behaviors.handleReset();
	        	},
	        	"class": "btn-green"
	        }],
	        open: function () {
	        	$(this).dialog("option", "position", { my: "center", at: "center", of: $(window) });
	        },
	        close: function () {
	        	behaviors.config.resetMax.add(behaviors.config.resetTo).tssCheckbox("off");
				behaviors.config.resetMaxValue.add(behaviors.config.resetToValue).val(0);
	        }
    	},
    	text: {},
        api: {
        	resources: "/mobile/behavior/resources",
        	reset: "/cash/reset/",
        	save: "/api/v1/behaviors"
        }
	};

	behaviors.init = function () {
		this.config.staffMemberId = $("input[name=\"staff_member_id\"]").val();
		this.initResources();
		this.initTable();
		this.initTableMode();
		this.initTableSelect();
		this.initTableSearch();
		this.initForm();
		this.initFormTags();
		this.initStudentTags();
		this.initReset();
		this.initStatus();
	};

	behaviors.initResources = function () {
		var self = this, request;
		request = $.get(this.config.api.resources);
		request.done(function (data) {
			self.config.resources = {
				attributeTypes: data.results.demerit_attribute_types,
				attributeTypeValues: data.results.demerit_attribute_values,
				attributeTypeDefaults: data.results.demerit_default_attribute_types
			};
		});
	};

	behaviors.initTable = function () {
		var self = this;
		_.each(this.config.tableParsers, function (parser) {
			$.tablesorter.addParser(parser);
		});
		this.config.table = $("#behaviors-table");
		this.config.tableStatus = $("#behaviors-table-status");
		this.config.tableTitle = $("#behaviors-table-title");
		this.config.tablePager = $("#behaviors-table-pager");
		this.config.table.tablesorter(this.config.tableOptions);
		this.config.table.tablesorterPager($.extend({ container: this.config.tablePager }, this.config.tablePagerOptions));
		this.config.table.find("tbody").on("click", "tr", function () { $(this).find(".student-select input:checkbox").tssCheckbox("toggle") });
		this.config.table.find("tbody").on("click", "tr .student-name a", function (event) { event.stopPropagation() });
	};

	behaviors.initTableMode = function () {
		var self = this, buttons;
		this.config.tableMode = $("#behaviors-table-mode");
		this.config.tableModeButtons = this.config.tableMode.find("div");
		this.config.tableModeButtons.click(function () {
			var button = $(this), mode = button.data("mode");
			if (button.is(".selected")) {
				return;
			}
			button.addClass("selected");
			self.config.tableModeButtons.not(button).removeClass("selected");
			self.config.table.find(".student-behavior .badge").each(function () {
				var badge = $(this);
				badge.toggleClass("badge-hidden", !badge.is(".badge-" + mode));
			});
			self.config.table.trigger("update");
		});
	};

	behaviors.initTableSelect = function () {
		var self = this;
		this.config.tableSelectAll = $("#behaviors-table-select-all");
		this.config.tableSelectAll.tssCheckbox({
			onChange: function (selected) {
				var checkboxes = self.getTableRowCheckboxes();
				checkboxes.tssCheckbox(selected ? "on" : "off");
			}
		});
		this.config.tableSelectOptions = $("#behaviors-table-select-options");
		this.config.tableSelectOptions.tssDropdown({
			content: Handlebars.renderTemplate("behaviors-table-select-options"),
		});
		this.config.tableSelectOptions.find(".menu-action").click(function () {
			self.handleTableSelectOptions($(this));
		});
	};

	behaviors.initTableSearch = function () {
		var self = this;
		this.config.asof = $("#behaviors-asof");
		this.config.tableSearch = $("#behaviors-search");
		this.config.tableSearch.tssStudentFinder({
			behaviorData: true,
			absenceData: true,
			asof: this.config.asof,
			cache: !!this.config.staffMemberId,
			onComplete: function (students) {
				self.renderTable(students);
			}
		});
	};

	behaviors.initForm = function () {
		var self = this;
		this.config.form = $("#behaviors-form");
		this.config.form.tssSubmit(this.config.api.save, function (data) {
			self.handleSave(data);
		}, "POST");
		this.config.formSubmit = $("#behaviors-form-submit");
		this.config.formSubmit.click(function () {
			var disabled = $(this).hasClass("disabled");
			if (disabled) {
				return;
			}
			self.config.form.submit();
		});
		this.config.formBehaviors = $("#behaviors-form-behaviors");
		this.config.formBehaviors.on("change", function () {
			self.handleFormSelectBehavior($(this));
		});
		this.config.formLocation = this.config.form.find("[name=\"location_id\"]");
		this.config.formTime = this.config.form.find("[name=\"time\"]");
		this.config.formComments = this.config.form.find("[name=\"comments\"]");
	};

	behaviors.initFormTags = function () {
		this.config.formTags = $("#behaviors-form-tags");
		this.config.formTags.on("click", ".tag", function (event) {
			var dropdown = $(this).find(".dropdown");
			dropdown.tssDropdown("open");
			event.stopPropagation();
		});
		this.config.formTags.on("click", ".tag .icon-remove", function (event) {
			var tag = $(this).parent();
			tag.remove();
			event.stopPropagation();
		});
	};

	behaviors.initStudentTags = function () {
		var self = this;
		this.config.studentTags = $("#behaviors-student-tags");
		this.config.studentTags.on("click", ".tag .icon-remove", function () {
			var tag = $(this).parent(), id = tag.data("id"), row = $("#behaviors-table-row-" + id);
			if (row.length) {
				row.find(".student-select input:checkbox").tssCheckbox("off");
			} else {
				tag.remove();
				self.renderStudentTagCount();
			}
		});
		this.config.studentTags.find("h5 .icon-remove").click(function () {
			self.getStudentTags().remove();
			self.getTableRowCheckboxes().tssCheckbox("off");
		});
	};

	behaviors.initReset = function () {
		var self = this;
		this.config.reset = $("#behaviors-reset");
		this.config.reset.click(function (e) {
			var studentTagCount = self.getStudentTags().length;
			self.config.resetDialog.find(".student-tag-count").text(studentTagCount ? studentTagCount : "all");
			self.config.resetDialog.dialog("open");
        });
		this.config.resetDialog = $("#behaviors-reset-dialog");
		this.config.resetDialog.dialog(this.config.resetDialogOptions);
		this.config.resetDialog.find("input:checkbox").tssCheckbox({
			onChange: function (selected) {
				var input = $("#" + this.id + "-value");
				input.attr("disabled", !selected);
			}
		});
		this.config.resetMax = $("#behaviors-reset-max");
		this.config.resetMaxValue = $("#behaviors-reset-max-value");
		this.config.resetTo = $("#behaviors-reset-to");
		this.config.resetToValue = $("#behaviors-reset-to-value");
		$(document).on("click", ".ui-widget-overlay", function () {
			self.config.resetDialog.dialog("close");
		});
	};

	behaviors.initStatus = function () {
		var students = this.config.tableStatus.data("students");
		if (this.config.staffMemberId) {
			this.renderTableStatus("icon-search", "Search for " + students + " to apply behaviors");
		} else {
			// this.config.tableSearch.add(this.config.asof).attr("disabled", true);
			this.config.formSubmit.addClass("disabled");
			this.renderTableStatus("icon-exclamation-sign", "You must be associated with a staff member to save new behaviors");
		}
	}; 

	behaviors.renderTable = function (students) {
		var self = this;
		this.config.tableStatus.hide();
		this.config.table.find("tbody").empty();
		this.config.tableTitle.text(this.config.tableSearch.data("TssStudentFinder")._result.value);
		this.config.tableSelectAll.tssCheckbox("off");
 		if (students.length === 1) {
 			this.renderTableRow(students[0], true);
 		} else {
 			_.each(students, function (student, i) {
				self.renderTableRow(student);
			});
 		}
		this.config.table.trigger("update");
		this.config.table.parent().show();
	};

	behaviors.renderTableRow = function (student, selected) {
		var self = this, tbody = this.config.table.find("tbody"), studentData = this.getStudentData(student), row = Handlebars.renderTemplate("behaviors-table-row", studentData), 
		rowId = "#behaviors-table-row-" + student.student_id, exists = !!this.config.studentTags.find("#behaviors-student-tag-" + student.student_id).length;
		tbody.append(row).find(rowId + " .student-select input:checkbox").tssCheckbox({
			selected: selected || exists,
			onChange: function (selected) {
				self.renderStudentTag(student, selected);
				self.renderStudentTagCount();
				self.handleTableSelectAllStatus();
			}
		});
		this.renderTableRowTooltips($(rowId), studentData);
	};

	behaviors.renderTableRowTooltips = function (row, data) {
		var columns = row.find(".student-behavior");
		columns.each(function () {
			var mood = $(this).data("mood"), badges = $(this).find(".badge"),
			behaviorData = _.find(data.behaviors, function (behavior) {
				return behavior.mood === mood;
			});
			badges.each(function () {
				var badge = $(this), mode = badge.data("mode"), behaviors = behaviorData[mode], totalValue = 0, prefix;
				if (behaviors && behaviors.length) {
					_.each(behaviors, function (behavior) {
						totalValue += parseInt(behavior.value);
					});
					prefix = behaviors[0].prefix;
					badge.attr("tss-tooltip", true);
					badge.data({
						tssTooltipTemplate: "behaviors-table-row-tooltip",
						tssTooltipData: {
							behaviors: behaviors,
							totalValue: totalValue,
							prefix: prefix,
							mood: behaviorData.mood
						}
					});
				}
			});
		});
	}; 

	behaviors.renderTableStatus = function (icon, message) {
		var status = Handlebars.renderTemplate("behaviors-table-status", { icon: icon, message: message });
		this.config.tableStatus.html(status).show();
		this.config.table.parent().hide();
	};

	behaviors.renderFormTag = function (data) {
		var self = this, id = "#behaviors-form-tag-" + data.id, exists = this.config.formTags.find(id).length, tag;
		if (exists) {
			return;
		}
		tag = Handlebars.renderTemplate("behaviors-form-tag", data);
		this.config.formTags.append(tag);
		this.config.formTags.find(id + " .tag-options").tssDropdown({
			icon: "icon-pencil",
			right: true,
			content: Handlebars.renderTemplate("behaviors-form-tag-options", data),
			onClose: function () {
				self.renderFormTagAttributes($(this).parent());
			}
		});
		this.config.formTags.find(id + " select").each(function () {
			var select = $(this), selectType = select.data("selectType"), options = { displayOptionText: true };
			if (select.attr("multiple")) {
				select.tssMultiSelectSearch(options);
			} else {
				select.tssSelectSearch(options);
			}
		});
		this.renderFormTagAttributes($(id));
	};

	behaviors.renderFormTagAttributes = function (tag) {
		var multiplier = tag.find(".multiplier").val(), multiplierLabel = tag.find(".multiplier-label span:first"),
		attributes = tag.find(".attribute"), attributeLabels = tag.find(".attribute-labels").empty();
		attributes.each(function () {
			var label = $(this).find("label:first"), selected = $(this).find("select").next("div").not(".has-placeholder");
			if (!selected.length) {
				return;
			}
			attributeLabels.append(Handlebars.renderTemplate("behaviors-form-tag-attribute-label", { name: label.text(), values: selected.text() }));
		});
		multiplierLabel.text(multiplier);
	};

	behaviors.renderStudentTag = function (student, selected) {		
		var id = "#behaviors-student-tag-" + student.student_id, exists = this.config.studentTags.find(id).length,
		tag = selected ? Handlebars.renderTemplate("behaviors-student-tag", student) : $(id);
		if (selected) {
			if (exists) {
				return;
			}
			this.config.studentTags.find(".tags").append(tag);
			return;
		}
		tag.remove();
	};

	behaviors.renderStudentTagCount = function () {
		var studentTagCount = this.getStudentTags().length, isEmpty = !studentTagCount;
		this.config.studentTags.find("h5 > span").text(studentTagCount);
		this.config.studentTags.find("h5 > i").toggle(!isEmpty);
		this.config.studentTags.find(".tags").toggleClass("empty", isEmpty);
	};

	behaviors.renderAlert = function (type, messages) {
		messages = $.isArray(messages) ? messages : [messages];
		_.each(messages, function (message) {
			Growl[type]({ message: message });
		});
	};

	behaviors.getTableMode = function () {
		var selected = this.config.tableMode.find("div.selected"), mode = selected.data("mode");
		return mode || "all";
	};

	behaviors.getTableRows = function (filterClass) {
		var rows = this.config.table.find("tbody tr");
		if (filterClass) {
			return rows.filter(function () {
				return $(this).hasClass(filterClass);
			});
		}
		return rows;
	};

	behaviors.getTableRowCheckboxes = function (filterClass) {
		var rows = this.getTableRows(filterClass);
		return rows.find(".student-select input:checkbox");
	}

	behaviors.getStudentTags = function () {
		var tags = this.config.studentTags.find(".tag");
		return tags;
	};

	behaviors.getStudentTagIds = function () {
		var studentIds = [], tags = this.getStudentTags();
		tags.each(function (i) {
			var studentId = $(this).find("input[type=\"hidden\"]").val();
			studentIds.push(studentId);
		});
		return studentIds;
	};

	behaviors.getStudentData = function (student) {
		var data = {}, moods = ["positive", "neutral", "negative"];
		data.id = student.student_id;
		data.name = student.display_name;
		data.behaviors = [];
		_.each(moods, function (mood) {
			data.behaviors.push({
				mood: mood,
				all: _.filter(student.behavior_data, function (behavior) { return behavior.mood === mood; }),
				my: _.filter(student.my_behavior_data, function (behavior) { return behavior.mood === mood; })		
			});
		});
		data.hasBehaviors = student.behavior_data && !!student.behavior_data.length;
		data.hasMyBehaviors = student.my_behavior_data && !!student.my_behavior_data.length;
		data.showAllBehaviors = this.getTableMode() === "all";
		data.present = !student.absence_data;
		if (student.absence_data) {
			data.absent = !parseInt(student.absence_data.in_school);
			data.other = !!parseInt(student.absence_data.in_school);
			data.attCode = student.absence_data.code;
			data.attName = student.absence_data.absence_type_name;
		}
		return data;
	};

	behaviors.getResetData = function () {
		var data = {
			student_ids: this.getStudentTagIds(),
			staff_member_id: this.config.staffMemberId,
			asof: dateStringToServer(this.config.asof.val()),
			beta: true
		};
		if (this.config.resetMax.is(":checked")) {
			data.value = this.config.resetMaxValue.val();
			data.reset_max = this.config.resetMax.val();
		} else if (this.config.resetTo.is(":checked")) {
			data.value = this.config.resetToValue.val();
			data.reset_to = this.config.resetTo.val();
		}
		return data;
	};

	behaviors.getAttributeTypesById = function (id) {
		var self = this, types, attributeTypes = [];
		types = _.filter(this.config.resources.attributeTypes, function (attributeType) {
			var demeritTypeId = attributeType.demerit_type_id;
			return demeritTypeId === id || demeritTypeId === null;
		});
		_.each(types, function (type, i) {
			var defaultValueIds = _.map((self.config.resources.attributeTypeDefaults[id] || {})[type.attr_key], function (val) {
				return val;
			});
			type.display_name = type.display_name.trim();
			type.values = self.config.resources.attributeTypeValues[type.demerit_attribute_type_id];
			_.each(type.values, function (value, i) {
				value.value = value.value.trim();
				value.isDefault = $.inArray(parseInt(value.demerit_attribute_value_id), defaultValueIds) !== -1;
			});
			attributeTypes.push(type);
		});
		attributeTypes.sort(function (attributeType) {
			return (attributeType.demerit_type_id === null ? -1 : 0);
		});
		return attributeTypes;
	};

	behaviors.handleTableSelectAllStatus = function () {
		var rows = this.getTableRows(), rowCount = rows.length,
		selectedRowCount = rows.filter(function () {
			return $(this).find(".student-select input:checkbox").is(":checked");
		}).length;
		if (rowCount > selectedRowCount) {
			this.config.tableSelectAll.tssCheckbox("off", true);
		} else if (rowCount === selectedRowCount) {
			this.config.tableSelectAll.tssCheckbox("on", true);
		}
	};

	behaviors.handleTableSelectOptions = function (option) {
		var self = this, checkboxes, select = option.data("select");
		switch (select) {
			case "all":
				checkboxes = self.getTableRowCheckboxes();
				break;
			case "present":
				checkboxes = self.getTableRowCheckboxes("present");
				break;
			case "absent":
				checkboxes = self.getTableRowCheckboxes("absent");
				break;
			case "no-behaviors":
				checkboxes = self.getTableRowCheckboxes(this.getTableMode() === "all" ? "no-behaviors" : "no-my-behaviors");
				break;
			case "none":
				break;
		}
		this.getTableRowCheckboxes().tssCheckbox("off");
		if (checkboxes && checkboxes.length) {
			checkboxes.tssCheckbox("on");
		}
	};

	behaviors.handleFormSelectBehavior = function (select) {
		var selected = select.find("option:selected"), data = selected.data(),
		id = selected.val(), name = selected.text(), attributeTypes;
		if (!id) {
			return;
		}
		attributeTypes = this.getAttributeTypesById(id);
		this.renderFormTag($.extend({
			id: id,
			name: name,
			attributeTypes: attributeTypes,
			editable: attributeTypes.length || data.showMultiplier 
		}, data));
		select.nextAll(".options-list-wrapper").find(".icon-remove").click();
	};

	behaviors.handleReset = function () {
		var self = this, request, data = this.getResetData();
		request = $.post(this.config.api.reset, data);
		request.done(function (data) {
			self.handleResetComplete(data);
		});
	};

	behaviors.handleResetComplete = function (data) {
		this.config.resetDialog.dialog("close");
		if (data.errors.length) {
			this.renderAlert("error", data.errors);
			return;
		}
		this.renderAlert("success", data.info);
	};

	behaviors.handleSave = function (data) {
		if (data.success) {
			this.handleSaveSuccess();		
        } else {
            this.renderAlert("error", data.errors);
        }
	};

	behaviors.handleSaveSuccess = function () {
		var studentIds = this.config.tableSearch.data("TssStudentFinder")._result.student_ids, studentCount = this.getStudentTags().length;
		this.config.tableSearch.tssStudentFinder("find", studentIds);
		this.renderAlert("success", "Successfully saved behaviors for " + studentCount + " " + this.config.tableStatus.data(studentCount === 1 ? "student" : "students"));
        this.resetForm();
	};

	behaviors.resetForm = function () {
		this.getStudentTags().remove();
		this.getTableRowCheckboxes().tssCheckbox("off");
		this.resetFormSelect(this.config.formBehaviors);
		this.resetFormSelect(this.config.formLocation);
		this.config.formTime.val(new moment().format("HH:mm"));
		this.config.formComments.val("");
		this.config.formTags.empty();
	};

	behaviors.resetFormSelect = function (select) {
		var searchField = select.nextAll(".options-list-wrapper").find(".search-field");
		searchField.find("input").val("").trigger("keyup");
		searchField.find(".icon-remove").click();
	};

})(jQuery, window);

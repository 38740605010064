(function ($, window, undefined) {"use strict";

    var editUsers = window.editUsers = {};

    editUsers.config = {
        tables: {
            options: {
                widgets: ["zebra", "filter"],
                widgetOptions: {
                    filter_external: '.tss-search input',
                    filter_columnFilters: false,
                    filter_saveFilters: false
                }
            },
        },
        pagers: {
            options: {
                output: "{page} of {filteredPages}"
            }
        },
        filters: {},
        duplicates: [
            { name: "user[active]" },
            { name: "staff_member_id" },
            { name: "user_group_ids[]" },
            { name: "allowed_school_ids[]" },
            { name: "user_group[name]", copy: true },
            { name: "user_group[active]" },
            { name: "user_ids[]" },
            { name: "permission_type_ids[]" }
        ],
        api: "/api/v1/",
    };

    editUsers.init = function (modalData) {
        this.config.tables.users = $("#users-table");
        this.config.tables.userGroups = $("#user-groups-table");
        this.config.pagers.users = $("#users-table-pager");
        this.config.filters.$search = $("#types-search");
        this.initFilters();
        this.initTable(this.config.tables.userGroups, [[0,0]]);
        this.initTable(this.config.tables.users, [[0,0]], this.config.pagers.users);
        this.initTableActions(this.config.tables.users, "user");
        this.initTableActions(this.config.tables.userGroups, "user-group");
        this.initViewActions(modalData);
    };

    editUsers.initAddEdit = function (data, type) {
        var form = $("#main-form");
        var id = form.data("id");
        var action = form.data("action");
        var isEdit = action === "edit";
        var url = isEdit ? this.config.api + type + "/" + id : this.config.api + type;
        var method = isEdit ? "PUT" : "POST";

        this.handleFormValues(action, data);
        form.submit(onSubmit(url, function (data, form) {
            if (data.success) {
                window.location.assign(form.data('referrer') || '/setup/users');
            } else {
                handlePost(data, undefined, undefined, form);
            }
        }, method));
    };

    editUsers.initTable = function (table, sortList, pager) {
        var self = this;

        table.tablesorter($.extend({}, { sortList: sortList || [] }, self.config.tables.options));

        if (pager) {
            table.tablesorterPager($.extend({}, { container: pager }, self.config.pagers.options));
            self.updatePagerInitialState(table, pager);
        }

        self.$activeFilter.val(1).change(); // initial state is active only
    };

    editUsers.initTableActions = function (table, type) {
        var self = this;
        var activeStatusIcon = table.find(".actions .icon-ok, .actions .icon-remove");

        activeStatusIcon.on("click", function (e) {
            var icon = $(this), id = icon.data("id"), isActive = icon.data("active");
            self.handleActiveStatus(table, icon, id, type, isActive);
        });

        table.find(".actions .icon-repeat").on("click", function(e) {
            var icon = $(this);
            var username = icon.data("username");

            self.sendPasswordResetEmail(username);
        });
    };

    editUsers.sendPasswordResetEmail = function (username) {
        $.post('/password/reset/attempt', {username})
            .success(editUsers.handlePasswordResetSuccess)
            .fail(editUsers.handlePasswordResetErrors);
    };

    editUsers.handlePasswordResetSuccess = function (data) {
        if (!data.success) {
            editUsers.handlePasswordResetErrors(data);
            return;
        }

        Growl.success({message: "Password reset email sent!", duration: 3000});
    };

    editUsers.handlePasswordResetErrors = function (data) {
        var message = 'Failed to send password reset email: ' + _.get(data, 'errors.0');

        Growl.error({message, duration: 3000});
    };

    editUsers.initViewActions = function (modalData) {
        var self = this;
        var toggleInactive = $("#toggle-inactive");

        toggleInactive.on("click", _.debounce(function () {
            var icon = $(this).find("i");
            var text = $(this).find("span");
            var showActiveOnly = self.$activeFilter.val() == '';

            self.$activeFilter.val(showActiveOnly ? '1' : '').change();
            icon.toggleClass("icon-eye-open icon-eye-close");
            text.text(showActiveOnly ? "Show inactive" : "Hide inactive");
            self.applyFilters();
        }, 200));

        // easter egg for sstern to set passwords for new users created for
        // training purposes without anyone else seeing it. Just use Cmd+i.
        $('body').bind('keydown', 'Meta+I', function() {
            $('input[type="hidden"][name="password"]').attr('type', 'text');
        });

        new Tss.Modal({
            launchTarget: '.launch-bulk-activate-users',
            template: 'bulk-activate-users-modal',
            getData: function() {
                return modalData; // default
            },
            go: function (e) {
                var dialog = $(e.target).closest('form');
                var data = {};

                data.staff_member_id = dialog.find("[name=\"staff_member_id\"]").val();
                data.password = dialog.find("[name=\"password\"]").val();
                data.user_group_id = dialog.find("[name=\"user_group_id\"]").val();
                $.post("/bulk/create/users/", data, handlePost);
            }
        });
    };

    editUsers.initFilters = function () {
        this.config.filters.$search.on("keyup", this.applyFilters);
        this.$activeFilter = $('<input type="hidden" data-column="1"/>'); // name, active (, group)
        this.config.filters.$search.attr('data-column', 'all'); // make this search field search all columns
        this.config.filters.$search.after(this.$activeFilter);
    };

    editUsers.applyFilters = function () {
        editUsers.config.tables.users.trigger("applyWidgets");
        editUsers.config.tables.userGroups.trigger("applyWidgets");
    };

    editUsers.resetFilters = function () {
        editUsers.config.filters.$search.add(editUsers.config.filters.userGroupName).val("");
        editUsers.config.filters.userGroup.nextAll(".options-list-wrapper").find(".icon-remove").click();
    };

    editUsers.handleFilterResults = function (event, data) {
        var tableContainer = data.$table.parent(), alert = tableContainer.find(".alert"), filters = editUsers.returnFilterValues(data);
        if (data.pager) {
            data.pager.$container.show();
        }
        alert.remove();
        if (!data.filteredRows) {
            if (data.pager) {
                data.pager.$container.hide();
            }
            editUsers.renderAlert(tableContainer, "warning", "No results for \"" + filters.join(", ") + "\"");
        }
    };

    editUsers.returnFilterValues = function (resultsData) {
        var results, values = [];
        if (!resultsData.lastSearch) {
            return;
        }
        results = resultsData.lastSearch.slice(0, resultsData.columns)
        $.each(results, function (i, result) {
            if (result) {
                values.push(result);
            }
        });
        return values;
    };

    editUsers.updatePagerInitialState = function (table, pager) {
        var pageSize = table.data().pagerLastSize;

        table.trigger("pageSize", pageSize);
        pager.find(".pagesize").val(pageSize).change();
    };

    editUsers.handleFormValues = function (action, data) {
        var isDuplicate = action === "duplicate";
        $.each(isDuplicate ? this.returnDuplicateValues(data) : data, fillInForm);
    };

    editUsers.handleActiveStatus = function (table, icon, id, type, isActive) {
        var self = this, options = {};
        options.url = "/activate/";
        options.data = { id: id, type: Functions.convertType(type), active: isActive ? 0 : 1 };
        $.ajax(options).done(function (data) {
            if (data.success && data.results.obj) {
                self.updateActiveStatus(icon, data.results.obj.active);
                table.trigger("update").trigger("pageMoved");
            }
        });
    };

    editUsers.updateActiveStatus = function (icon, activeStatus) {
        var row = icon.parents("tr"), newActiveStatus = parseInt(activeStatus),
        iconTitle = icon.data("title"), activeStatusTitles = ["Activate", "Deactivate"];
        row.toggleClass("deactivated");
        row.find(".active-status").text(newActiveStatus);
        icon.toggleClass("icon-ok icon-remove");
        icon.data({
            title: iconTitle.replace(activeStatusTitles[newActiveStatus ? 0 : 1], activeStatusTitles[newActiveStatus]),
            active: newActiveStatus
        });
    };

    editUsers.returnDuplicateValues = function (data) {
        var values = {};
        $.each(this.config.duplicates, function (i, duplicate) {
            var name = ""
            if (!data[duplicate.name]) {
                return;
            }
            values[duplicate.name] = data[duplicate.name];
            if (duplicate.copy) {
                values[duplicate.name] += " (copy)";
            }
        });
        return values;
    };

    editUsers.renderAlert = function (target, type, message, removable) {
        var data = { type: type, message: message, className: "no-margin", removable: removable },
        alert = Handlebars.renderTemplate("alert", data);
        target.append(alert);
    };

})(jQuery, window);

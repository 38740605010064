/**
 * TSS number lib
 *  - Can't extend native prototypes due to D3 use (and best-practices, in general).
 */
window.Tss = window.Tss || {};
window.Tss.Number = {
    /**
     * Formats the number with commas.
     * @param  number The number to format.
     * @param  int    decimals Number of decimal places to format.
     * @return string
     */
    toCommas: function(num, decimals) {
        if (!num && num !== 0) {
            return 'N/A';
        }

        decimals = decimals || 0;
        // round in integer space
        num = (Math.round(Number.parseFloat(num) * Math.pow(10, decimals)) / Math.pow(10, decimals)).toFixed(decimals);

        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    /**
     * Generate currency -- because this hasn't ever been written before, ever.
     *
     * @see [http://stackoverflow.com/questions/411352/how-best-to-determine-if-an-argument-is-not-sent-to-the-javascript-function]
     *  - The fastest way to check for not defined parameter is to use
     *    typeof value === 'undefined' rather than doing value === undefined.
     * @return string
     */
    toMoney: function(num, decimals, decimalSep, thousandsSep, currencySymbol) {
        var 
            // If decimal is zero we must take it, it means user
            // does not want to show any decimal
            c = isNaN(decimals) ? 2 : Math.abs(decimals),
            
            // If no decimal separetor is passed we use the comma
            // as default decimal separator (we MUST use a decimal separator)
            d = decimalSep || '.', 

            // If you don't want ot use a thousands separator you can pass empty string as thousands_sep value
            t = (typeof thousandsSep === 'undefined') ? ',' : thousandsSep, 

            sign = (num < 0) ? '-' : '',
            
            // Extracting the absolute value of the integer part of the number and converting to string
            i = parseInt(num = Math.abs(num).toFixed(c)) + '', 
            j = ((j = i.length) > 3) ? j % 3 : 0,
            symbol = (typeof currencySymbol === 'undefined') ? '$' : currencySymbol;
        
        return sign + symbol + (j ? i.substr(0, j) + t : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(num - i).toFixed(c).slice(2) : '');
    },
      
    /**
     * Rounds to nearest increment
     * @param float n
     * @param int v
     * @returns int
     */
    toNearest: function(n, v) {
        n = n / v;
        n = Math.round(n) * v;
        return n;
    },

    /**
     * Turns a cardinal number (1, 2, 3) into its ordinal equivalent ('1st',
     * '2nd', '3rd').
     * @param  int i
     * @return string
     */
    toOrdinal: function(i) {
        if (i == 0) {
            return 'K';
        } else if (i == -1) {
            return 'Pre-K';
        } else if (i < -1) {
            return 'PK ' + (5 + i); // PK 3 for 3-year-olds
        }

        const n1 = i % 100; // first remove all but the last two digits
        const n2 = (n1 < 20 ? n1 : i % 10); // remove all but last digit unless the number is in the teens, which all should be 'th'
        const ord = i + (n2 == 1 ? 'st' : (n2 == 2 ? 'nd' : (n2 == 3 ? 'rd' : 'th')));

        return ord;
    },

    /**
     * Pads number with zeros
     * @param int num
     * @param int size
     * @returns string
     */
    padZeros: function(num, size) {
        if(isNaN(num)) { throw num + ' is not a number'; }
        if(isNaN(size)) { throw size + ' is not a number'; }
        
        var s = num + '';
        while (s.length < size) {
            s = '0' + s;
        }
        return s;
    },
    
    /**
     * Test floating point equality with a given epsilon.
     * 
     * @param  float a
     * @param  float b
     * @param  float epsilon
     * @return boolean
     */
    floatsEqual: function(a, b, epsilon) {
        epsilon = epsilon === undefined ? 0.001 : epsilon;

        return Math.abs(a - b) < epsilon;
    }
};

;(function ($, window, document, undefined) {

    // Constructor:
    function editThumbnailPlugin(options) {

        // private properties:
        var areaInstance,
            imgCounter = 0,
            imgNaturalHeight,
            imgNaturalWidth,
            imgHeight,
            imgWidth,
            payload = {},
            ratio,
            self = this,
            squareLength,
            zoomData,
            $cancelButton,
            $previewImg,
            $srcImg,
            $submitButton,
            $newPhotoInput,
            $studentHeaderImg,
            newImgSrc,

            // Default options:
            defaults = {
                autoInitAreaPlugin: true,
                recordId: null,
                type: null,
                selectors: {
                    srcImg: '.original-photo > img',
                    previewImg: '.thumbnail-preview > img',
                    submitButton: 'div[name="submit-new-thumbnail"]',
                    cancelButton: 'div[name="cancel-thumbnail"]',
                    newPhotoInput: 'input[type="file"][name="new_photo"]',
                    studentHeaderImg: '.photo-container > img'
                }
            };

        //
        // private methods:
        //
        
        /**
         * Pauses firing init event until all async stuff is ready.
         */
        function checkImages() {
            imgCounter++;
            if (imgCounter == 2) {
                updateImageSizes();

                $(document).triggerHandler("Tss.Thumb.Init");

                if (options.autoInitAreaPlugin) {
                    self.initAreaPlugin();
                }
            }
        };

        function updateImageSizes() {
            imgHeight = $srcImg.height();
            imgWidth = $srcImg.width();
            squareLength = (imgHeight < imgWidth) ? imgHeight : imgWidth;
            imgNaturalHeight = $srcImg.get(0).naturalHeight;
            imgNaturalWidth = $srcImg.get(0).naturalWidth;
        }

        /**
         * Sets zoom scale for preview img and new thumbnail, changes preview on area select
         */
        function handleThumbnailAreaChange(img, selection) {
            ratio = imgNaturalWidth / imgWidth;
            zoomData = {
                x: parseInt(ratio * selection.x1, 10),
                y: parseInt(ratio * selection.y1, 10),
                height: parseInt(ratio * selection.height, 10),
                width: parseInt(ratio * selection.width, 10)
            };
            var scaleX = 120 / (selection.width || 1);
            var scaleY = 120 / (selection.height || 1);

            $previewImg.css({
                position: 'relative',
                width: Math.round(scaleX * imgWidth) + 'px',
                marginLeft: '-' + Math.round(scaleX * selection.x1) + 'px',
                marginTop: '-' + Math.round(scaleY * selection.y1) + 'px'
            });
        };
        
        function initZoomData() {
            handleThumbnailAreaChange(null, {
                x1: 0,
                y1: 0,
                x2: squareLength,
                y2: squareLength,
                height: squareLength,
                width: squareLength
            });
        };


        /**
         * Set up all events and handlers for this view.
         */
        function initBindings() {
            var isMissingPhoto = $srcImg.attr('src').match(/missingphoto/);

            $previewImg.on('load', checkImages);
            $srcImg.on('load', checkImages);
            // setup a fallback where after 500ms we check for a height on both
            // source and preview image. if we have a  height and our imgCounter
            // is still 0, that means we never called checkImages.
            // In Firefox images loaded via browser cache dont fire "load" event so we
            // must manually call the checkImages method twice. Once for preview and once for source image.
            setTimeout(function () {
                if ($previewImg.height() > 0 && $srcImg.height() > 0 && imgCounter == 0) {
                    $previewImg.trigger('load');
                    $srcImg.trigger('load');
                }
            }, 500);

            $submitButton.on('click', $.proxy(saveThumbnail));
            
            if (isMissingPhoto) {
                $submitButton.addClass('disabled');
            }

            $newPhotoInput.on('change', function(evt) {
                var fileInput = this;

                if (fileInput.files && fileInput.files[0]) {
                    var file = fileInput.files[0],
                        filename = file.name,
                        reader = new FileReader();

                    // we only know how to zoom/thumbnail-ize jpegs so filter here
                    if (!Functions.hasExtension(filename, ['jpeg', 'jpg'])) {
                        alert("Please upload a jpeg image (.jpeg or .jpg)");
                        return;
                    }

                    reader.onload = function (e) {
                        $srcImg.attr('src', e.target.result);
                        $previewImg.attr('src', e.target.result);
                        setTimeout(function () {
                            updateImageSizes();
                            self.initAreaPlugin();
                            initZoomData();
                            $submitButton.removeClass('disabled');
                        }, 10);
                    };

                    reader.readAsDataURL(file);
                }
            });

            $cancelButton.on('click', function() {
                areaInstance.remove(true);
                $(document).triggerHandler("Tss.Thumb.SaveComplete");
            });
        };
        
        /**
         * Set variables as jQuery elements from options.selectors
         */
        function locateElements() {
            $srcImg = $(options.selectors.srcImg);
            $previewImg = $(options.selectors.previewImg);
            $submitButton = $(options.selectors.submitButton);
            $newPhotoInput = $(options.selectors.newPhotoInput);
            $cancelButton = $(options.selectors.cancelButton);
            $studentHeaderImg = $(options.selectors.studentHeaderImg);
        };

        /**
         * Submit new coordinates to API after saving the new file if necessary.
         */
        function saveThumbnail(evt) {
            evt.preventDefault();

            if ($submitButton.hasClass('disabled')) return;

            // first save the photo to the server if they selected a new file
            var imgSrc = $srcImg.attr('src'),
                hasNewPhoto = imgSrc.match(/^data:image/);
            if (hasNewPhoto) {
                var typeUriFragment = (options.type == 'student' ? 'students' : 'staff-members'),
                    imageExtensions = Functions.imageExtensions.join('|'),
                    imageData = imgSrc.replace(new RegExp('^data:image\/(' + imageExtensions + ');base64,'), '').replace(' ', '+'),
                    ajaxData = {
                        type : 'PUT',
                        url : '/api/v1/photos/' + typeUriFragment + '/base64/' + options.recordId,
                        data : {
                            image_data: imageData
                        },
                        dataType : 'json'
                    };

                $.ajax(ajaxData)
                    .done(function(data) {
                        saveThumbnailZoom();
                    })
                    .fail(function(err) {
                        $submitButton.val('Error saving photo...');
                    });
            } else {
                saveThumbnailZoom();
            }
        };

        /**
         * Submit new coordinates to API.
         */
        function saveThumbnailZoom() {
            var typeUriFragment = (options.type == 'student' ? 'students' : 'staff-members'),
                ajaxData = {
                    type : 'POST',
                    url : '/api/v1/photos/' + typeUriFragment + '/thumbnail-zoom?record_id=' + options.recordId,
                    data : {
                        zoom_data: zoomData
                    },
                    dataType : 'json'
                };

            $.ajax(ajaxData)
                .done(function(data) {
                    areaInstance.remove(true);
                    newImgSrc = data.results.thumbnail_uris["120"];
                    $studentHeaderImg.attr('src', newImgSrc);
                    $(document).triggerHandler("Tss.Thumb.SaveComplete");
                })
                .fail(function(err) {
                    $submitButton.val('Error saving thumbnail...');
                });
        };

        // Privileged:
        // this.methodName = function() { ... }

        /**
         * Callback for when the src image has loaded.
         */
        this.initAreaPlugin = function() {
            areaInstance = $srcImg.imgAreaSelect({
                x1: 0,
                y1: 0,
                x2: squareLength,
                y2: squareLength,
                aspectRatio: '1:1',
                handles: true,
                instance: true,
                show: true,
                persistent: true,
                remove: false,
                onInit: initZoomData,
                onSelectChange: handleThumbnailAreaChange
            });
        };

        //
        // Process this logic in the constructor, following definitions.
        //
        options = $.extend({}, defaults, options);

        // Verify options:
        if (!options.recordId || (options.type != 'student' && options.type != 'staff_member')) {
            $.error('Config issue: recordId and type must be provided in options');
            return;
        }

        locateElements();
        initBindings();
    };

    //
    // Public methods -- modify the prototype
    // myPlugin.prototype.methodName = function () {  }
    //

    // Expose this plugin in the Tss namespace
    window.Tss = window.Tss || {};
    window.Tss.editThumbnailPlugin = editThumbnailPlugin;
})(jQuery, window, document);

(function ($, window, undefined) {"use strict";

    var defaults = {
        api: "/date/desc/",
        statusId: "date-status"
    };

    function TssDate(element, options) {
        this._options = $.fn.tssOptions("tss-date", element, defaults, options);
        this.input = element;
        this.$input = $(element);
        this.$container = $("<div>").addClass("date-wrapper");
        this.$status = $("<div>", { id: this._options.statusId });
        setup.call(this);
    };

    function setup() {
        var self = this;
        this.$input.wrap(this.$container).after(this.$status);
        this.$input.change(function () {
            var date = self.date();
            if (date) {
                $.post(self._options.api + dateStringToServer(date), function (data) {
                    if (!data.results || data.results.is_school_day) {
                        self.clear();
                        return;
                    }
                    self.set('<i class="icon-2x icon-calendar" title="' + data.results.holiday_description + '" tss-tooltip></i>');
                });
            }
        }).change();
    };

    TssDate.prototype.date = function () {
        return dateStringToServer(this.$input.val());
    };

    TssDate.prototype.set = function (text) {
        return this.$status.html(text);
    };

    TssDate.prototype.clear = function () {
        return this.$status.empty();
    };

    $.fn.tssDate = $.fn.tssExtend(TssDate);

})(jQuery, window);

/**
 *
 * @author cmitchell
 */
(function($) {
    $.fn.tssPopover = function(options){
        //set default options
        var defaultOptions = {
            onAfterClick: function() {}
        };

        //merge with passed options
        options = $.extend(defaultOptions, (typeof options == 'object' ? options : {}));
        //close on body click
        $('html').click(function(e) {
            var clickedElem = $(e.target);
            var clickedElemInsidePopover = clickedElem.parents('.tss-popover').length;
            if (!clickedElemInsidePopover) {
                $('.tss-popover.open').removeClass('open');
            }
        });

        return this.each(function(){
            var $this = $(this),
                $contents = $(this).find('.popover-contents');

            if($this.hasClass('tss-popover')) { return; }
            $this.addClass('tss-popover');
            positionPopover($contents);
            setupContentsOrigin(options);
            setBindings($this, $contents, options);
        });

        function setupContentsOrigin(options) {
            options.contentsOrigin = $(options.contentsOrigin);
        };

        function positionPopover($contents) {
            //get coordinates of event element
            var popover = $contents,
                coords = $contents.offset(),
                outerWidth = $contents.outerWidth(),
                windowWidth = $(window).width(),
                xCoord = coords.left + outerWidth;

            if ((windowWidth - xCoord) < (windowWidth / 2)) {
                popover.addClass('arrow-right');
            } else {
                popover.addClass('arrow-left');
            }
        }

        function setBindings($this, $contents, options) {
            $this.off('click').on('click', function(e) {
                // did we click the actual trigger or an element inside the popover-contents
                var clickedElem = $(e.target);
                var clickedElemIsPopoverContents = clickedElem.hasClass('popover-contents');
                var clickedElemInPopoverContents = clickedElem.parents('.popover-contents').length;
                if (clickedElemIsPopoverContents || clickedElemInPopoverContents) {
                    return;
                }

                // disabled turn off popovers
                if ($this.hasClass('disabled')) {
                    return;
                }

                $this.trigger('gaEvent');
                e.stopPropagation();

                if(options.contentsOrigin.length) {
                    setContentsFromOrigin($contents, options);
                }

                $('.tss-popover.open').not($this).removeClass('open');
                $this.toggleClass('open');

                // trigger onClick callback
                options.onAfterClick($this);
            });
        }

        function setContentsFromOrigin($contents, options) {
            $contents.html(options.contentsOrigin.html());
        }
    };
}(jQuery));

import '@babel/polyfill';

// come on eileen! 7/15/21!!!!!!!!

// import external js
import moment from 'moment-timezone';
window.moment = moment;

// extended lodash
import _ from './js/tss/lodash.js';

// so we have access to it everywhere
import Api from '../services/Api'
window.Api = Api

// base styles
import './styles/index.scss';

// import vendor plugins
import './js/vendor/jquery-1.12.4.js';
import './js/vendor/jquery-ui-1.9.2.custom.js';
import './js/vendor/ajaxtooltip.js';
import './js/vendor/autoresize.jquery.js';
import './js/vendor/browserdetect.js';
import './js/vendor/colorbrewer.js';
import './js/vendor/d3.v2.10.3.js';
import './js/vendor/facebox.js';
import './js/vendor/handlebars-1.0.rc.1.js';
import './js/vendor/handlebars.extend.js';
import './js/vendor/handlebars.helpers.js';
import './js/vendor/jquery.ajax-polyfill.js';
import './js/vendor/jquery.animate-enhanced.js';
import './js/vendor/jquery.cookie.js';
import './js/vendor/jquery.fileDownload.js';
import './js/vendor/jquery.growl.js';
import './js/vendor/jquery.hideseek.js';
import './js/vendor/jquery.hotkeys.js';
import './js/vendor/jquery.hyjack.select.js';
import './js/vendor/jquery.iframe-auto-height.plugin.js';
import './js/vendor/jquery.imgareaselect.js';
import './js/vendor/jquery.inputmask.bundle.js';
import './js/vendor/jquery.joyride-2.1.js';
import './js/vendor/jquery.match-height.js';
import './js/vendor/jquery.metadata.js';
import './js/vendor/jquery.multiselect-1.13.js';
import './js/vendor/jquery.multiselect.filter.js';
import './js/vendor/jquery.offset-relative.js';
import './js/vendor/jquery.scrollTo.js';
import './js/vendor/jquery.scrollintoview.js';
import './js/vendor/jquery.tablesorter.js';
import './js/vendor/jquery.tablesorter.pager.js';
import './js/vendor/jquery.tablesorter.widgets.js';
import './js/vendor/jquery.tipsy.js';
import './js/vendor/jquery.ui.autocomplete.html.js';
import './js/vendor/nv.d3.js';
import './js/vendor/jquery.hoverIntent.js';
import './js/vendor/modernizr.js';

// import tss plugins
import './js/tss/jquery.tss.js';
import './js/tss/jquery.tss-autogrow.js';
import './js/tss/jquery.tss-checkbox.js';
import './js/tss/jquery.tss-clearable-input.js';
import './js/tss/jquery.tss-comments.js';
import './js/tss/jquery.tss-date.js';
import './js/tss/jquery.tss-dropdown.js';
import './js/tss/jquery.tss-expandable.js';
import './js/tss/jquery.tss-filter.js';
import './js/tss/jquery.tss-form-validation.js';
import './js/tss/jquery.tss-icon-toggle.js';
import './js/tss/jquery.tss-load.js';
import './js/tss/jquery.tss-mood-select.js';
import './js/tss/jquery.tss-multiselect-search.js';
import './js/tss/jquery.tss-multiselect.js';
import './js/tss/jquery.tss-popover.js';
import './js/tss/jquery.tss-select-search.js';
import './js/tss/jquery.tss-select.js';
import './js/tss/jquery.tss-sticky.js';
import './js/tss/jquery.tss-student-finder.js';
import './js/tss/jquery.tss-switch.js';
import './js/tss/jquery.tss-tabs.js';
import './js/tss/jquery.tss-tags.js';
import './js/tss/jquery.tss-toggle-visible.js';
import './js/tss/jquery.tss-tour.js';
import './js/tss/alerts.js';
import './js/tss/async-task-manager.js';
import './js/tss/async-task.js';
import './js/tss/filtering.js';
import './js/tss/grammar.js';
import './js/tss/modal.js';
import './js/tss/number.js';
import './js/tss/scheduled-task.js';
import './js/tss/thumb.js';
import './js/tss/absences.js';
import './js/tss/analysis.js';
import './js/tss/assessment-graphing.js';
import './js/tss/assessment-student.js';
import './js/tss/assessment.js';
import './js/tss/attachments.js';
import './js/tss/behaviors.js';
import './js/tss/bindings.js';
import './js/tss/course-grade.js';
import './js/tss/dates.js';
import './js/tss/dynamic-class-attendance.js';
import './js/tss/dynamic-data.js';
import './js/tss/edit-users.js'; // delete me
import './js/tss/env.js';
import './js/tss/extend-jquery.js';
import './js/tss/extend-prototype.js';
import './js/tss/functions.js';
import './js/tss/ga-datalayer.js';
import './js/tss/grade-cam.js';
import './js/tss/grades-recalculate.js';
import './js/tss/groups.js';
import './js/tss/growl.js';
import './js/tss/guardian-topnav.js';
import './js/tss/homerooms.js';
import './js/tss/incident.js';
import './js/tss/log.js';
import './js/tss/login.js';
import './js/tss/manual-assessment.js';
import './js/tss/notifications.js';
import './js/tss/overlay.js';
import './js/tss/password-change.js';
import './js/tss/pivot-table.js';
import './js/tss/report-cards.js';
import './js/tss/settings.js';
import './js/tss/student-attrs.js';
import './js/tss/student.js';
import './js/tss/tooltips.js';
import './js/tss/topnav.js';
import './js/tss/tss-form.js';
import './js/tss/tss.js';
import './js/tss/types.js';
import './js/tss/ui.js';
import './js/tss/undo-service.js';

import './js/views/photo/edit.js';
import './js/views/setup/configure/grading-methodologies/edit.js';
import './js/views/setup/configure/grading-methodologies/manage.js';

import './js/views/setup/school.js';
import './js/views/setup/configure/behavior-type-groups.js';
import './js/views/setup/configure/behavior-types.js';
import './js/views/setup/configure/courses.js';
import './js/views/setup/configure/demerit-attribute-types.js';
import './js/views/setup/configure/grading-scales.js';
import './js/views/setup/configure/section-periods.js';
import './js/views/setup/configure/students.js';
import './js/views/setup/configure/schools.js';
import './js/views/setup/configure/terms.js';
import './js/views/setup/configure/term-bin.js';
import './js/views/setup/configure/users.js';

// import templates
import './templates/*.html';
import './templates/**/*.html';

var Overlay = {
    
    myOverlay: null,
    
    init: function() {
        Overlay.setBindings();
    },
    
    setBindings: function() {
        $(document).on('click', '[rel="delete-overlay"]', this.deleteOverlay)
            .on('click', '#tss-overlay > .modal', this.stopPropagation)
            .on('click', '#tss-overlay', this.deleteOverlay)
            .on('deleteOverlay', this.deleteOverlay)
            .on('keyup', this.handleKeyup);
    },
            
    appendOverlay: function(options) {
        this.myOverlay = $(Handlebars.renderTemplate('overlay', options)).hide();
        $('body').append(this.myOverlay);
        $('html').addClass('open-modal');
        this.myOverlay.fadeIn('fast');
    },
            
    deleteOverlay: function() {
        if(!Overlay.myOverlay) { return; }
        
        Overlay.myOverlay.fadeOut('fast', function() {
            $(this).remove();
            $('html').removeClass('open-modal');
        });
    },
            
    handleKeyup: function(e) {
        if(e.keyCode === 27) {
            Overlay.deleteOverlay();
        }
    },
            
    stopPropagation: function(e) {
        e.stopPropagation();
    }
    
};

$(Overlay.init);
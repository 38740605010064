(function($, window) { "use strict";
    var DemeritAttributeTypes = {
        initAddEdit: function(action, id) {
            var self = this;

            self.$form = $('#main-form');
            self.$table = $('#types-table');
            self.$pager = $("#types-table-pager");
            self.$toggleShowInactive = $("#toggle-show-inactive");
            self.showActiveOnly = true;

            if (action == 'add') {
                self.fillInAttributeValues();
            } else {
                self.$form.on('FillInForm', self.fillInAttributeValues);
            }

            self.$table.on("click", "[rel=\"toggle-active\"]", self.handleToggleActiveClick);
            self.$table.on('keyup', 'input', self.handleListKeyup);
            self.$toggleShowInactive.on("click", self.handleToggleShowInactiveClick);

            Tss.Types.initAddEdit("DemeritAttributeType", "display_name", action, id, {
                extraApiParams: {
                    expand: 'demerit_type,demerit_attribute_values'
                },
                submitCallback: self.storeAttributeValues
            });
        },

        handleToggleActiveClick: function(e) {
            var menuAction = $(this).closest('.menu-action');
            var row = $(this).closest('tr');
            var oldVal = row.find('[name="active"]').val();
            var newVal = oldVal == 1 ? 0 : 1; // toggle

            row.find('[name="active"]').val(newVal);
            row.find('input[type="text"]').attr('disabled', newVal ? null : 'disabled');
            row.find('td:nth-child(2)').html(newVal);

            // otherwise you see it change right when you click the link and that's a weird UX
            setTimeout(function() {
                menuAction.find('i').toggleClass('icon-remove').toggleClass('icon-ok');
                menuAction.find('span').text(newVal ? 'Deactivate' : 'Activate');
            }, 100);
        },

        handleListKeyup: function(e) {
            // change this to: on keyup, delete all blank rows that don't exist that I'm not in
            var self = DemeritAttributeTypes;
            var input = $(this);
            var inputRow = input.closest('tr');
            var allRows = self.$table.find('tbody tr');
            var lastRowIsEmpty = !allRows.last().find('[name="value"]').val();
            var lastRowExists = allRows.last().find('[name="demerit_attribute_value_id"]').val();
            var madeChanges = false;

            if (!lastRowIsEmpty || lastRowExists) {
                // if no empty rows, add one
                self.renderRow({active: 1});
                madeChanges = true;
            } else {
                // remove all blank rows that aren't already in the database
                // as long as I'm not currently typing in that row
                // and it's not the one last empty row we always want to have
                allRows.each(function(i) {
                    var row = $(this);
                    var rowIsEmpty = !_.trim(row.find('[name="value"]').val());
                    var rowExists = row.find('[name="demerit_attribute_value_id"]').val();
                    var inThisRow = inputRow.get(0) == row.get(0);
                    var isLastRow = i == allRows.length - 1;

                    if (rowIsEmpty && !rowExists && !inThisRow && !isLastRow) {
                        row.remove();
                        madeChanges = true;
                    }
                });
            }

            if (madeChanges) {
                self.$table.trigger("applyWidgets");
            }
        },

        handleToggleShowInactiveClick: function () {
            var self = DemeritAttributeTypes;
            var $icon = $(this).find("i");
            var $span = $(this).find("span");

            self.showActiveOnly = !self.showActiveOnly;

            self.$table.find('tr.deactivated')[self.showActiveOnly ? 'hide' : 'show']();
            $icon.attr("class", "icon-eye-" + (self.showActiveOnly ? "open" : "close"));
            $span.text((self.showActiveOnly ? "Show" : "Hide") + " inactive");
        },

        fillInAttributeValues: function(e, record) {
            var self = DemeritAttributeTypes;
            let demeritAttributeValues = _.sortBy(_.get(record, 'demerit_attribute_values') || [], 'order_key');

            // end with a blank row always
            _.each(demeritAttributeValues.concat({active: 1}), function(attr) {
                self.renderRow(attr, attr.demerit_attribute_value_id);
            });

            self.$table.tablesorter(_.extend({ sortList: [] }, Tss.Types.config.table));
            self.$table.find("tbody").sortable(Tss.Types.config.sortable);
            self.$table.find("tbody").on("sortstart", _.bind(self.handleSortStart, self));
            self.$table.find("tbody").on("sortstop", _.bind(self.handleSortStop, self));
        },

        handleSortStart: function () {
            // Close open dropdowns
            $(".dropdown.open").tssDropdown("close");
        },

        handleSortStop: function () {
            // Update tablesorter striping
            this.$table.trigger("applyWidgets");
            this.$table.find('input[type="text"]:first').trigger('keyup'); // add or remove rows as necessary
        },

        renderRow: function(type, id) {
            let $row = $(Handlebars.renderTemplate('setup/configure/demerit-attribute-value-table-row', type));

            if (id) {
                $row.find(".options").tssDropdown({
                    content: Handlebars.renderTemplate('setup/configure/demerit-attribute-value-table-row-options', type),
                    icon: 'icon-cog',
                    right: true
                });
            }

            if (type.active != 1) {
                $row.find('input[type="text"]').attr('disabled', 'disabled');
                $row.hide();
            }

            this.$table.find("tbody").append($row);
        },

        storeAttributeValues: async function(data) {
            var self = DemeritAttributeTypes;
            var orderKey = 0;
            let demeritAttributeTypeId = _.get(data, 'results.demerit_attribute_type.demerit_attribute_type_id');
            let demeritTypeId = $('[name="demerit_type_id"]').val();
            let successUrl = self.$form.data('referrer')
                || (demeritTypeId
                    ? "/setup/configure/behavior-types"
                    : "/setup/configure/demerit-attribute-types");

            if (!demeritAttributeTypeId) {
                Growl.error({message: 'Save failed.'});
                return;
            }

            // convert this to edit instead of add in case the below validation
            // fails and we hit save again
            Tss.Types.config.action = 'edit';
            Tss.Types.config.id = demeritAttributeTypeId;
            Tss.Types.setSubmitHandler();

            let bulkData = _.filter(self.$table.find('tbody tr').map(function() {
                var row = $(this);
                var data = {};

                row.find('input').map(function() {
                    data[$(this).attr('name')] = $(this).val();
                });

                if (!data.value) {
                    data.active = 0; // if they blanked out a row make sure we report that as inactive
                    delete data.value; // don't send the blanked out value at all
                }

                if (data.active != '1' && !data.demerit_attribute_value_id) {
                    return null; // ignore blank rows that don't already exist in the db
                }

                data.demerit_attribute_type_id = demeritAttributeTypeId;
                data.order_key = (orderKey += 1000);

                return data;
            }));

            if (bulkData.length) {
                const url = '/api/v1/bulk/demerit-attribute-values';
                const params = {
                    bulk_data: bulkData,
                };
                const responses = await Api.put(url, params);
                const uniqueKeyError =
                    "Demerit Attribute Type ID, Value: The values specified must be a unique combination, however the specified combination already exists";
                const uniqueKeyErrorDisplay =
                    "Error: We already have a detail by this name. Please use or modify that. You may need to click \"Show Inactive\" to see it.";
                const errorTranslations = {
                    [uniqueKeyError]: uniqueKeyErrorDisplay
                }
                const errorMessage = _.chain(responses)
                    .map(x => _.values(x.errors))
                    .flatten()
                    .map(error => error in errorTranslations ? errorTranslations[error] : error)
                    .uniq()
                    .value()
                    .join('<br/>');

                if (!errorMessage) {
                    window.location.assign(successUrl);
                } else {
                    Growl.error({ message: errorMessage });
                }
            }
        }
    };

    window.Tss = window.Tss || {};
    window.Tss.DemeritAttributeTypes = DemeritAttributeTypes;

})(jQuery, window);

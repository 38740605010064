(function ($, window) {
    "use strict";
var passwordValidator = require('password-validator');
// Create a schema
var validator = new passwordValidator();

// Add properties to it
validator
    .is().min(8)                                    // Minimum length 8
    .has().uppercase()                              // Must have uppercase letters
    .has().lowercase()                              // Must have lowercase letters
    .has().digits()                                 // Must have digits
    .has().symbols()                                // Must have symbols
    .has().not().spaces()                           // Should not have spaces

var PasswordChange = {
    init: function() {
        this.validator = validator;
        this.form = $('form#main-form');
        this.submitBtn = $('[rel="submit-form"]');
        this.passwordField = $('form#main-form [name="password"]');
        this.passwordConfirmField = $('form#main-form [name="password_confirm"]');

        this.submitBtn.addClass('disabled');

        this.setBindings();
    },

    setBindings: function() {
        this.passwordField.keyup(function(e) {
            e = e || {};
            PasswordChange.checkCapsLock(e.originalEvent);
            PasswordChange.validatePassword($(this).val());
        });

        this.passwordConfirmField.keyup(function(e) {
            e = e || {};
            PasswordChange.checkCapsLock(e.originalEvent);
            if(e.keyCode === 13) {
                PasswordChange.form.submit();
            }
        });
    },


    // make sure password has:
    // 1+ uppercase letter
    // 1+ lowercase letter
    // 1+ number
    // 1+ special character
    // 8+ characters
    checkPassword: function (password) {
        let reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
        return reg.test(password);
    },

    // make sure password has:
    // 1+ uppercase letter
    // 1+ lowercase letter
    // 1+ number
    // 1+ special character
    // 8+ characters
    validatePassword: function (password) {
        let requirements = ['min', 'uppercase', 'lowercase', 'digits', 'symbols'];
        // check password against requirements and list out anything it fails
        let errors = PasswordChange.validator.validate(password, { list: true });
        if (!errors.length) {
            this.submitBtn.removeClass('disabled');
        } else {
            this.submitBtn.addClass('disabled');
        }
        // consider any requirement not in the errors list as fulfilled
        let fulfilled = _.filter(requirements, req => !_.includes(errors, req));
        _.each(fulfilled, fulfilledReq => {
            $(`.password.${fulfilledReq}`).addClass('fulfilled');
        });
        _.each(errors, error => {
            $(`.password.${error}`).removeClass('fulfilled');
        });
    },

    checkCapsLock: function (e) {
        // possible getModifierState is undefined e.g. if a browser autocomplete was used
        var capsLockState = e && e.getModifierState ? e.getModifierState("CapsLock") : null;
        if (capsLockState) {
            document.getElementById('caps-lock-indicator').style.visibility = 'visible';
        } else {
            document.getElementById('caps-lock-indicator').style.visibility = 'hidden';
        }
    },

};

window.Tss = window.Tss || {};
window.Tss.PasswordChange = PasswordChange;

}) (jQuery, window);


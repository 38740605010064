(function ($, window, undefined) {"use strict";

    var incident = window.Tss.Incident = {};

    incident.config = {
        inputs: {},
        alerts: {}
    };

    incident.init = function (id, data, form) {
        this.config.id = id ? id : '';
        this.config.data = data.ar;
        this.config.startDate = data.startDate;
        this.config.incidentStudents = data.incidentStudents;
        this.config.requiredRoles = data.requiredRoles;
        this.config.attachments = data.attachments;
        this.config.form = form;
        this.config.inputs.date = this.config.form.find('input[name="date"]');
        this.config.inputs.studentRow = this.config.form.find('.studentRow').first().clone();
        this.config.inputs.incidentType = this.config.form.find('[name="incident_type_id"]');
        this.config.inputs.incidentSubtype = this.config.form.find('[name="incident_subtype_id"]');
        this.config.inputs.incidentSubtypeOptions = this.config.inputs.incidentSubtype.find('option').detach();
        this.config.inputs.loggedInStaffMemberId = data.loggedInStaffMemberId;
        this.config.alerts = $("#incident-alerts");
        this.config.alerts.missingRole = this.config.alerts.find(".alert-missing-role");
        this.config.alerts.noConsequence = this.config.alerts.find(".alert-no-consequence");
        this.config.alerts.dupe = this.config.alerts.find(".alert-dupe");

        this.setBindings();
        this.setupHtml();
    };

    incident.dateChange = function() {
        var asof = $(this).val();

        if (!asof) return;

        $.post('/date/desc/' + dateStringToServer(asof), function(data) {
            var results = data.results,
                isSchoolDay = results.is_school_day,
                holidayDescription = results.holiday_description;

            $('#date-status').html(!isSchoolDay ? holidayDescription : '');
        });
    }

    incident.filterPage = function() {
        var params = $.makeArray($('[name*="student_id"]')
                .map(function() { return $(this).val(); })
                .filter(function(v) { return v; }))
            .join('&student_id[]=');

        if (params) {
            params = '?student_id[]=' + params;
        }

        filter(null, '/incidents/filter/' + (params || ''));
    }

    incident.getRequiredRoleMessage = function() {
        var self = this,
            retval = '',
            incidentTypeId = self.config.inputs.incidentType.val();

        if (incidentTypeId) {
            // look up incident type id in requiredRoles
            var incidentType = self.config.requiredRoles[incidentTypeId],
                requiredRole = incidentType
                    ? incidentType.required_incident_role
                    : null;

            if (requiredRole) {
                retval = incidentType.display_name + ' requires a '
                    + requiredRole.display_name;
            }
        }

        return retval;
    };

    incident.hasRequiredRoles = function() {
        var self = this,
            retval = true,
            incidentTypeId = self.config.inputs.incidentType.val();

        if (incidentTypeId) {
            // look up incident type id in requiredRoles
            var incidentType = self.config.requiredRoles[incidentTypeId],
                requiredRoleId = incidentType
                    ? incidentType.required_incident_role_id
                    : null;

            if (requiredRoleId) {
                // if found, look for requiredRole in page
                var allRoles = self.config.form.find('[name*="[incident_role_id]"]').map(function() {
                    return $(this).val();
                });

                retval = $.inArray(requiredRoleId + '', allRoles) !== -1;
            }
        }

        return retval;
    };

    incident.numDaysWarning = function() {

        var numDays = $(this);
        var showWarning = false;
        var msg = null;
        var maxSuspensionDays = 365;
        if (numDays.val() === "0") {
            showWarning = true;
            msg = "A suspension of 0 days results in NO suspension. Are you sure you want to do this?";

        } 
        
        if (numDays.val() > 30 && numDays.val() < maxSuspensionDays) {
            showWarning = true;
            msg = "This suspension will be for " + numDays.val() + " days. Are you sure you want to do this?";
        }
        
        if (numDays.val() > maxSuspensionDays) {
            showWarning = true;
            msg = "A suspension cannot be longer than " + maxSuspensionDays + " days. ";
        }

        numDays.nextAll('.numDaysWarning').removeClass('alert alert-warning').addClass(showWarning ? 'alert alert-warning' : '').html(showWarning ? msg : '');
    };

    incident.setBindings = function () {
        var self = this;

        this.config.form.submit(function(e) {
            var submitFunc = onSubmit('/incident/save/' + self.config.id);
            if (!self.hasRequiredRoles()) {
                self.config.alerts.missingRole.toggle(true).scrollintoview().toggle(false).fadeIn();
                return stopPropagation(e);
            }

            return submitFunc.apply(self.config.form, [e]);
        });

        this.config.form.on('change', '[name="incident_type_id"],[name*="[incident_role_id]"]', function() {
            var shouldShow = !self.hasRequiredRoles(),
                message = self.getRequiredRoleMessage();

            self.config.alerts.missingRole.toggle(shouldShow).scrollintoview().find('.msg').text(message);
        });

        this.config.form.on('change', '[name*="[suspension_type_id]"], [name="incident_type_id"]', function(e) {
            var selectedSuspensionTypes = _.filter(self.config.form.find('[name*="[suspension_type_id]"]').map(function() { return $(this).val(); })),
                shouldShow = !selectedSuspensionTypes.length;

            self.config.alerts.noConsequence.toggle(shouldShow).scrollintoview();
        });

        if (!self.config.id) {
            this.config.form.on('change', 'select[name*="student_id"], [name*="date"]', self.showDupeWarning);
        }

        //function getParams
        function getParams() {
            var params = '?subdir=incidents&clazz=IncidentAttachment&staff_member_id=' + self.config.inputs.loggedInStaffMemberId;
            return params;
        }

        this.config.form.find('.dndtarget').each(function() {
            this.addEventListener('dragover', fileDragHover, false);
            this.addEventListener('dragleave', fileDragHover, false);
            this.addEventListener('drop', uploadAttachment(handleUploadResponse, getParams), false);
        });

        initializeInputFileUpload(getParams);

        self.config.inputs.date.change(self.dateChange);
        self.config.form.find('.editForm').on('keyup change blur', 'input[name*="num_days"]', self.numDaysWarning);
        self.config.form.find('.editForm').on('keyup change blur', '[name*="num_days"], [name*="start_date"]', self.updateDateHelp);
        self.config.form.find('.editForm').on('keyup change blur', '#short_description', self.updateCharsLeft);

        $('.editForm').on('click', '[rel="add-student-row"]', function(e) {
            var $row = $(this).closest('.studentRow'),
                $clonedRow = $(self.config.inputs.studentRow).clone(),
                $allStudentRows = $('.studentRow'),
                newRowNum = $allStudentRows.length;

            $clonedRow.find('[name], [id], [for]').each(function() {
                var elem = $(this);

                $.each(['name', 'id', 'for'], function(i, key) {
                    if (elem.attr(key)) {
                        elem.attr(key, elem.attr(key).replace(/\[(\d+)\]/, '[' + newRowNum + ']'));
                    }
                });
            });

            $allStudentRows.last().after($clonedRow);
            $(window).resize(); // force facebox to update its own height
            $clonedRow.each(setupUI);
            self.updateAllWarnings();

            return stopPropagation(e);
        });

        self.config.inputs.incidentType.change(function() {
            var select = $(this),
                incidentTypeId = select.val(),
                options = self.config.inputs.incidentSubtypeOptions.filter('[data-incident_type_id="' + incidentTypeId + '"]');

            self.config.inputs.incidentSubtype.html($('<option/>').add(options)).val('').trigger('refresh-options').closest('.col')[options.length ? 'show' : 'hide']();
        });

        // save hotkey
        $(document).on('keydown', null, getSimpleShortcutLabelPrefix() + 's', function(e) {
            e.preventDefault();
            self.config.form.submit();
        });

        $('body').on('click', '#comment-form [rel="post-comment"]', function(e) {
            var form = $(this).closest('#comment-form'),
                params = {
                        comment: form.find('[name="comment"]').val(),
                        incident_comment_id: form.find('[name="incident_comment_id"]').val()
                    },
                uri = '/incident/comment/add/?incident_id=' + self.config.id
                    + '&staff_member_id=' + self.config.inputs.loggedInStaffMemberId;

            $.post(uri, params, function(data) {
                if (!data.success) {
                    displayNotifications(data);
                    return;
                }

                clearNotifications();
                var obj = data.results.obj,
                    newComment = $(
                        '<div class="comment fullwidth">'
                            + '<span class="editable preLine" title="Click to edit" data-comment_id="' + obj.incident_comment_id + '">'
                                + obj.comment
                            + '</span>'
                            + '<div class="ago" white-space>'
                                + obj.staff_member_link + ' on ' + obj.from_date_formatted
                            + '</div>'
                        + '</div>');

                // add a comment to the page and clear the box
                form.find('textarea[name="comment"]').val('');
                if (form.find('[name="incident_comment_id"]').length) { // edit
                    var elem = newComment;
                    form.closest('.comment').replaceWith(elem);
                    flashElem(elem);
                } else {
                    form.parent().find('.comment-section').append(newComment);
                    flashElem(newComment);
                }
                $(window).resize(); // force facebox to update its own height
            });

            return stopPropagation(e);
        }).on('click', '.editable', function() {
            var form = $('#comment-form:last').clone(),
                comment = $(this);

            form.find('textarea').val(comment.html());
            form.append('<input type="hidden" name="incident_comment_id" value="' + comment.data('comment_id') + '">');

            comment.replaceWith(form);
            form.each(setupUI);
            form.find('textarea').focus().select();
        });
    };

    incident.setupHtml = function () {
        var self = this;

//        setupAddAndClear(); // do this before fillInForm otherwise we might select a group with the same ID as a student
        // FIXME is this still an issue ^^^
        if (self.config.data) {
            _.each(this.config.data, function (value, key) {
                fillInForm(key, value, self.config.form);
            });

            $.each(self.config.incidentStudents, function(i, incidentStudent) {
                var students = incidentStudent.student_ids,
                    incidentRoleId = incidentStudent.incident_student.incident_role_id,
                    incidentSuspension = incidentStudent.incident_suspension;

                $('[name="students\\[' + i + '\\]\\[incident_role_id\\]"]').val(incidentRoleId).change();
                $('[name="students\\[' + i + '\\]\\[minutes_out_of_class\\]"]').val(incidentStudent.incident_student.minutes_out_of_class);
                $('[name="students\\[' + i + '\\]\\[student_id\\]\\[\\]"]').val(students).change();

                if (incidentSuspension) {
                    $('[name="students\\[' + i + '\\]\\[start_date\\]"]').val(incidentSuspension.start_date);
                    fillInForm('students\\[' + i + '\\]\\[suspension_type_id\\]', incidentSuspension.suspension_type_id, self.config.form);
                    $('[name="students\\[' + i + '\\]\\[num_days\\]"]').val(incidentSuspension.num_days).change();
                }
            });
        }

        Attachments.addMutiple(self.config.attachments);

        self.updateAllWarnings();

        snapshotForChanges(this.config.form);
    };

    /*
     * Send a message to the server to see if there's already an incident for this date and any of the student_ids.
     */
    incident.showDupeWarning = function() {
        var self = incident,
            output = self.config.alerts.dupe,
            message = output.find('.msg'),
            date = $('[name*="date"]').val(),
            studentIds = _.filter(
                $('[name*="student_id"]').map(function() { return $(this).val(); }));

        if (studentIds.length && date) {
            $.post('/incidents/lookup/', {date: dateStringToServer(date), student_ids: studentIds}, function(data) {
                var warning = data.results.warning,
                    shouldShow = !!warning;

                output.toggle(shouldShow).scrollintoview().find('.msg').text(warning);
            });
        } else {
            output.toggle(false);
        }
    }

    incident.updateAllWarnings = function() {
        var self = this;

        self.config.form.find('.studentRow').each(self.updateDateHelp);
        self.updateCharsLeft();
        self.config.inputs.date.each(self.dateChange);
        self.config.form.find('[name*="num_days"]').each(self.numDaysWarning);
    };

    incident.updateCharsLeft = function() {
        var charsLeft = $('#chars_left'),
            shortDesc = $('#short_description'),
            maxLength = parseInt(shortDesc.attr('maxlength')),
            value = shortDesc.val() || '';

        charsLeft.html(maxLength + ' characters' + (!value.length ? '' : ', ' + (maxLength - value.length) + ' remaining'));
    }

    incident.updateDateHelp = function() {
        var studentRow = $(this).closest('.studentRow'),
            numDaysInput = studentRow.find('[name*="num_days"]'),
            value = numDaysInput.val(),
            throughDate = studentRow.find('.suspensionThroughDate'),
            startDateInput = studentRow.find('[name*="start_date"]'),
            disable = (value === ''),
            startDate = startDateInput.val();

        if (throughDate.data('num_days') === value && throughDate.data('start_date') === startDate) return;

        throughDate.data('num_days', value);
        throughDate.data('start_date', startDate);

        studentRow.find('.the_s_in_days').fadeTo(1, value == '1' ? 0 : 1);
        studentRow.find(':radio').button({disabled: disable});

        if (disable) {
            startDateInput.attr('disabled', 'disabled').parent().add(throughDate).addClass('dimmed');
        } else {
            startDateInput.removeAttr('disabled').parent().add(throughDate).removeClass('dimmed');
        }

        if (isNumber(value) && value != '' && startDate) {
            var startDate = dateStringToDate(startDate);

            $.post('/addbusdays/' + value + '/' + dateStringToServer(startDate), function(result) {
                var startDate = dateStringToDate(result.asof),
                    endDate = dateStringToDate(result.result),
                    startDateValid = result.asofIsSchoolDay,
                    holidayDesc = result.warning,
                    fmt = 'D, M. d';

                if (startDateValid) {
                    throughDate.removeClass('alert alert-error').html('Starting on: ' + $.datepicker.formatDate(fmt, startDate)
                        + '<br>Returning on: ' + $.datepicker.formatDate(fmt, endDate));
                } else {
                    throughDate.addClass('alert alert-error').html("Start date is not a school day.<br/>"
                        + $.datepicker.formatDate(fmt, startDate) + ': ' + holidayDesc);
                }
            });
        } else {
            throughDate.removeClass('alert alert-error').html('');
        }
    }

})(jQuery, window);

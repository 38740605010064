(function ($, window, undefined) {"use strict";
    
    var Alerts = {
        
        handleFormAlerts: function(data, form) {
            var that = this;
            Alerts.removeAllFormAlerts(form);

            if (data.info) {
                _.each(data.info, function(message, field) {
                    that.addFormAlert('info', field, message, form);
                });
            }
            if (data.warnings) {
                _.each(data.warnings, function(message, field) {
                    that.addFormAlert('warning', field, message, form);
                });
            }
            if (data.errors) {
                _.each(data.errors, function(message, field) {
                    that.addFormAlert('error', field, message, form);
                });
            }

            //fade alerts in and scroll to first
            var allAlerts = form.find('.alert');
            if(allAlerts.length) {
                form.find('.alert').fadeIn('fast');
                form.find('.alert').first().scrollintoview();
            }
        },

        addFormAlert: function(type, fieldName, message, form) {
            //does field exist?
            var field = $('[name="' + fieldName + '"]');
            if(!field.length) {
                form.find('.row').first().before(Handlebars.renderTemplate('alert', {type: type, message: message, removable: true}));
                return;
            }

            //we only want the first field
            //this will ensure we don't added errors to filters, etc.
            field = field.first();

            //if hidden, add humanized field name add to the top
            if(field.attr('type') === 'hidden' && form.length) {
                form.find('.row').first().prepend(Handlebars.renderTemplate('alert', {type: type, message: TssForm.addHumanizedFieldName(fieldName, message), removable: true}));

                return;
            }

            //add error class to parent col
            field.closest('.col').addClass(type);

            // generate alert from template
            var alert = Handlebars.renderTemplate('alert', {type: type, message: message, removable: true});

            // if select or multiselect, append alert after everything else in the div
            if (field.hasClass('is-tss-select-search') || field.hasClass('is-tss-multiselect-search')) {
                field.closest('.col').append(alert);
            } else {
                //append alert after the field
                field.after(alert);
            }

        },

        removeAllFormAlerts: function(form) {
            form = $(form);

            form.find('.alert').remove();
            form.find('.col.error').removeClass('error');
        }
    };
    
    window.Tss = window.Tss || {};
    window.Tss.Alerts = Alerts;

})(jQuery, window);

/* globals d3: false, nv: false */
/*
 * Utility functions for creating graphs/charts.
 *
 * @author ccoglianese
 */
window.oldControls = {};
function addGraph(graphIndex, data, model, numGraphs, redrawAll) {
    var chartId = "#chart" + graphIndex,
        svgId = chartId + ' svg',
        width = Math.max(20, Math.floor(100 / numGraphs) - 1);

    if (!$(chartId).length) {
        var parts = ('' + graphIndex).split('_'),
            div = '<div id="' + chartId.substring(1) + '" class="chart" style="display: inline-block; width: '
                + width + '%;"></div>';

        if (parts.length == 1) {
            $('#chartWrapper').append(div);
        } else {
            if (!$('#chart' + parts[0]).length) {
                var clonedChart = $('#chart0').clone().attr('id', 'chart' + parts[0]);
                clonedChart.find('.chart, .filterColumn').remove();
                $('#chartWrapper').append(clonedChart); // ???
            }

            if (!$('#chart' + parts[0] + ' .breakoutChartWrapper').length) {
                $('#chart' + parts[0] + ' .filterDesc').after('<div class="breakoutChartWrapper"></div>');
            }

            $('#chart' + parts[0] + ' .breakoutChartWrapper').append(div);
        }
    }

    if (!$(svgId).length) {
        if ($(chartId).find('.filterDesc').length) {
            $(chartId).find('.filterDesc').after('<svg></svg>');
        } else {
            $(chartId).prepend('<svg></svg>');
        }
    }

    $(svgId).css('height', Math.min(500, $(svgId).width()));
    $(chartId).data('data', data).data('model', model);

    nv.addGraph(function() {
        var controls = initControls($(svgId)),
            chart = model(data, controls)
                .x(function(d) { return d.key })
                .label(function(d) { return d.label })
                .y(function(d) { return d.value })
                ;

        if (chart.showDataControls) {
            initControl(controls, window.oldControls, 'dataControls');
            chart.showDataControls(controls.dataControls);
        }

        if (chart.showControls) {
            initControl(controls, window.oldControls, 'stackedControls');
            chart.showControls(controls.stackedControls);
        }

        if (chart.showSortControls) {
            initControl(controls, window.oldControls, 'sortControls');
            initControl(controls, window.oldControls, 'ascDescControls');
            chart.showSortControls(controls.sortControls)
                .showAscDescControls(controls.ascDescControls)
        }

        if (window.oldControls.legend) {
            $.each(data, function(i, o) {
                var oldLegend = window.oldControls.legend,
                    key = o.key,
                    val = null;

                if ($.isArray(oldLegend)) {
                    $.each(oldLegend, function(j, p) {
                        if (p.key == o.key && 'disabled' in p) {
                            val = p.disabled;
                            return false; // break;
                        }
                    });
                } else if (key in oldLegend) {
                    val = oldLegend[key]
                }

                if (val !== null) {
                    o.disabled = val;
                }
            });
            window.oldControls.legend = data;
        }

        d3.select(svgId)
                .datum(data)
            .transition().duration(1200)
                .call(chart);

        $(chartId).data('chart', chart);

        nv.utils.windowResize(redrawAllCharts); // maybe only call this once instead of once per chart?
        $('select[name="pivot"]').off('change').on('change', pivotChange);

        if (redrawAll) {
            setTimeout(redrawAllCharts, 2);
        }

        return chart;
    });

//    return $(chartId);
}

function initControl(controls, oldControls, key) {
    var i = null,
        control = controls[key] || [],
        oldControl = oldControls[key],
        model = null;

    if ($.isPlainObject(control)) {
        model = control.model;
        control = control.data;
    }

    if (key === 'legend') {
        $.each(control, function(i, o) {
            var key = o.key,
                val = null;

            if ($.isArray(oldControl)) {
                $.each(oldControl, function(j, p) {
                    if (p.key == o.key && 'disabled' in p) {
                        val = p.disabled;
                        return false; // break;
                    }
                });
            } else if (key in oldControl) {
                val = oldControl[key]
            }

            if (val !== null) {
                o.disabled = val;
            }

//            if (chart) chart.update();
        });
    } else {
        if ($.isArray(oldControl)) {
            oldControl = nv.radioVal(oldControl);
            oldControl = oldControl.key || oldControl.label;
        }

        $.each(control, function(j, o) {
            if (o['key' in o ? 'key' : 'label'] === oldControl) {
                i = j;
                return false; // break;
            }
        });

        if (i !== null) {
            control[i].disabled = true;
            if (model && model.dispatch && model.dispatch.legendClick) {
                model.dispatch.legendClick(null, i); // update the chart
            } else {
                nv.radioify(control, i);
            }
        }
    }

    if (control.length) {
        oldControls[key] = control;
    }
}

function initControls(svg) {
    if (!svg.data('controls')) {
        svg.data('controls', {
            stackedControls: [
                {label: 'Stacked', key: 'stacked'},
                {label: 'Grouped', key: 'grouped'},
              ],
            sortControls: [
                {label: 'Default', sortCallback: sortCallback('breakoutIndex')},
                {label: 'A-Z', sortCallback: sortCallback('label')},
            ],
            ascDescControls: [
                {label: 'Asc', isAsc: true},
                {label: 'Desc'}
            ]
        });
    }

    return svg.data('controls');
}

function sortCallback(key) {
    return function(isAsc, chart) {
        var data = chart.data(),
            fn = sortByKey(key, isAsc, data, chart);

        $.each(data, function(i, v) {
            v.values.sort(fn);
        });
    };
}

function sortByKey(key, isAsc, data, chart) {
    var subKey = key,
        sortFn = function(v) { return v[subKey]; },
        asc = (isAsc ? 1 : -1),
        avgScoreSubKey = $.grep(chart.showDataControls(), function(v) { return !v.disabled; })[0].avgKey,
        valueSubKey = $.grep(chart.showDataControls(), function(v) { return !v.disabled; })[0].key || 'value',
        hasBreakout = $.grep($('[name="breakout"]').map(function() { return $(this).val(); }), function(v) { return v; }).length,
        sortBySeriesAnd = function(sortFn) {
            return function(a, b) {
                var seriesResult = hasBreakout ? cmp(a, b, function(o) { return o.seriesIndex; }) : 0;

                return seriesResult || (cmp(a, b, sortFn) * asc);
            }
        };

    if (key == 'avgScore') {
        subKey = avgScoreSubKey;
    } else if (key == 'pivot') {
        var pivotTotals = {},
            keyToIndex = {};

        for (var i = getPivot(); i < data.length; i++) {
            if (data[i].bar) {
                $.each(data[i].values, function(j, o) {
                    pivotTotals[o.key] = (pivotTotals[o.key] || 0) + o[valueSubKey];
                    keyToIndex[o.key] = j;
                });
            }
        }

        sortFn = function(o) {
            var a = [pivotTotals[o.key]];

            a.push(o[avgScoreSubKey]);
            a.push(o.breakoutIndex);

            return a;
        };
    }

    return sortBySeriesAnd(sortFn);
}

function cmp(a, b, sortFn) {
    var aVal = sortFn(a),
        bVal = sortFn(b);

    if ($.isArray(aVal)) {
        for(var i = 0; i < aVal.length; i++) {
            if (aVal[i] != bVal[i]) {
                return aVal[i] < bVal[i] ? -1 : 1;
            }
        }

        return 0;
    }

    return aVal == bVal ? 0 : (aVal < bVal ? -1 : 1);
}

function setupGradingScales(gradingScales) {
    var select = $('select[name="grading_scale"]'),
        options = select.find('option'),
        newIds = {};

    $.each(gradingScales, function(i, gradingScale) {
        var name = gradingScale.name,
            gradingScaleId = gradingScale.grading_scale_id,
            orderKey = gradingScale.order_key,
            active = gradingScale.active;

        if (!options.filter('[value="' + gradingScaleId + '"]').length) {
            $.each(gradingScale.levels, function(i, level) {
                level.min_value = parseFloat(level.min_value);
            });

            select.append(
                $("<option " + (active == '0' ? "class='deactivated '" : "") + "value='" + gradingScaleId + "' data-order_key='" + orderKey + "'/>")
                    .text(name)
                    .data('grading_scale', gradingScale));
        }

        newIds[gradingScaleId] = 1;
    });

    if (Object.keys(newIds).length) {
        select.closest('.headerWrapper').children().css({display: 'inline-block'});
    }

    options.each(function() {
        var option = $(this),
            gradingScaleId = option.val();
        if (!(gradingScaleId in newIds) && gradingScaleId != select.val()) {
            option.remove();
        }
    });

    select.find('option').sort(function(a, b) {
        var aVal = $(a).data('order_key'),
            bVal = $(b).data('order_key');

        return aVal == bVal ? 0 : (aVal < bVal ? -1 : 1);
    });

    var initVal = select.data('init_val');
    if (initVal !== null && initVal !== undefined) {
        select.val(initVal).data('init_val', null).trigger('change', true);
//        log('grading scale val = ' + select.val() + ' (' + initVal + ')');
    }

    select.css('width', '');
    select.each(hyjackSelect);

    var gradingScale = getGradingScale();

    setupPivotDropdown(gradingScale);

    return gradingScale;
}

function setupPivotDropdown(gradingScale) {
    if (!gradingScale || !gradingScale.levels) return;

    var pivotSelect = $('select[name="pivot"]'),
        oldCount = $('select[name="pivot"] option').length,
        options = '',
        oldVal = pivotSelect.val(),
        numLevels = gradingScale.levels.length;

    for (var i = 0; i < gradingScale.levels.length; i++) {
        var level = gradingScale.levels[i];

        options += '<option data-min_value="' + level.min_value + '" value="' + (numLevels - 1 - i) + '">' + level.name + ' or Above</option>'
    };

    pivotSelect.html(options);
    pivotSelect.trigger('refresh-options');
    var initVal = pivotSelect.data('init_val');
    if (initVal !== null && initVal !== undefined) {
        pivotSelect.val(initVal).data('init_val', null).trigger('change', true);
//        log('pivot val = ' + pivotSelect.val() + ' (' + initVal + ')');
    } else {
        pivotSelect.val(oldVal === null
            ? Math.floor(numLevels / 2)
            : Math.round(oldVal / oldCount * numLevels)); // find the closest option by percentage
    }
    pivotSelect.css('width', '');
    pivotSelect.each(hyjackSelect);
    pivotSelect.trigger('change', true);
    $('body').trigger('pivotUpdated', {gradingScale: gradingScale});
}

function getPivot() {
    return $('select[name="pivot"]').val();
}

function pivotChange(e, noRedraw) {
    if (AnalysisView.suspendRedraw || noRedraw) return;

    // sort all charts
    $('#chartWrapper .chart').each(function() {
        var div = $(this),
            chart = div.data('chart');

        if (chart) {
            chart.sort().update();
        }
    });

    setTimeout(function() {
        $('.pivotTable.filterContainer:first').change();
    }, 1500);
}

/**
 * Offset function for d3.layout.stack() which lets us specify where to offset each stack vertically (or horizontally if the bar
 * chart is horizontal). This lets us move the zero line with all positive bar values. So much easier!
 */
function pivotOffset(data) {
    var j = -1,
        m = data[0].length,
        y0 = [],
        i = 0,
        pivot = Math.min(getPivot(), data.length); // FIXME adjust for hidden series better
    while (++j < m) {
        y0[j] = 0;
        for (i = 0; i < pivot; i++) {
            y0[j] -= Math.abs(data[i][j][1]);
        }
    }
    return y0;
}

/**
 * Doesn't work (yet).
 */
function posNegOffset(data) {
    var j = -1,
        m = data[0].length,
        y0 = [],
        i = 0;
    while (++j < m) {
        y0[j] = 0; // make y0[j] be the sum of all negative values
        for (i = 0; i < data.length; i++) {
            y0[j] += Math.min(data[i][j][1], 0); // add all the negatives
        }
    }
    return y0;
}

function redrawAllCharts() {
    $('#chartWrapper .chart').each(function() {
        var div = $(this),
            chart = div.data('chart'),
            data = div.data('data'),
            svgId = '#' + div.attr('id') + ' svg';

        if (chart) {
            $(svgId).css('height', Math.min(500, $(svgId).width()));
            d3.select(svgId).datum(data).call(chart);
        }
    });
}

function scoreToGradeLevel(rawScore, gradingScale) {
    var numLevels = gradingScale.levels.length;

    rawScore = parseFloat(rawScore);

    if (gradingScale.is_pct == 1) rawScore = Math.round(rawScore); // don't round non-percent-based scales

    for (var i = 0; i < numLevels; i++) {
        var level = gradingScale.levels[i];

        level.index = i; // stash this, useful for coming up with a color later

        if (rawScore >= level.min_value || i == numLevels - 1) {
            return level;
        }
    }

    return null; // won't happen
}

function levelToKey(level) {
    return '_' + level.grading_scale_level_id; // make this a string so that it'll come out in order when we iterate over the object
}

function levelToDisplay(level) {
    return level.abbreviation || level.name;
}

function scoreToGrade(rawScore, gradingScale) {
    var level = scoreToGradeLevel(rawScore, gradingScale);

    return level ? levelToDisplay(level) : null;
}

function scoreToLevelKey(rawScore, gradingScale) {
    var level = scoreToGradeLevel(rawScore, gradingScale);

    return level ? levelToKey(level) : null;
}

function getGradingScale() {
    return getSelectedOption($('select[name="grading_scale"]')).data('grading_scale');
}

function getGradingScaleIsNoPct() {
    return getGradingScale().is_pct == 0;
}

// FIXME: color conversion functions: these may not be necessary in the new d3/jquery UI?
function hexToRgb(color) {
    var c = color.charAt(0) == '#' ? color.substring(1, 7) : color;

    return 'rgb('
        + parseInt(c.substring(0, 2), 16) + ', '
        + parseInt(c.substring(2, 4), 16) + ', '
        + parseInt(c.substring(4, 6), 16) + ')';

}

function removeAlpha(color) {
    var c = color;
    var matches = c.match(/^(rgb|hsl)a\((\d+),\s*(\d+),\s*(\d+),\s*([\d.]+)\)$/);

    if (matches) {
        var alpha = parseFloat(matches[5]);
        var newC = c.replace(/^(rgb|hsl)a\(/, '$1(').replace(/,[^,]+\)$/, ')');

        // blend in the white background
        return d3.interpolateRgb(newC, 'white')(1 - alpha);
    }

    return c;
}

function addAlpha(color, alpha) {
    var c = color;

    if (c) {
        if (c.match(/^#?[0-9A-Fa-f]{6}$/)) {
            c = hexToRgb(c);
        }

        if (c.match(/^(rgb|hsl)a\(/)) {
            return c.replace(/,[^,]+\)$/, ', ' + alpha + ')');
        }

        if (c.match(/^(rgb|hsl)\(/)) {
            return c.replace(/(rgb|hsl)/, '$1a').replace(')', ', ' + alpha + ')');
        }
    }

    log('can\'t add alpha to color: ' + color);

    return undefined;
}

function cleanFind(key, obj) {
    var cleanKeyStr = cleanKey(key),
        found;

    $.each(obj, function(k, v) {
        if (cleanKeyStr == cleanKey(k)) {
            found = k;
            return false; // break;
        }
    });

    return found;
}

function isEqual(a, b) {
    return $.isArray(a) && $.isArray(b) ? Functions.stringifiedValsEqual(a, b) : a == b;
}

function getIntersection(objList) {
    var newObjList = objList.slice(0),
        intersection = $.extend(true, {}, newObjList.splice(0, 1)[0]);

    // start with a clone of the first object and remove anything that's different/does not exist in all future objects
    $.each(intersection, function(k, v) {
        $.each(newObjList, function(i, obj) {
            var key = cleanFind(k, obj),
                value = obj[key];

            if (!isEqual(v, value)) {
                delete intersection[k];
            }
        });
    });

    return intersection;
}

/**
 * Remove all intersections from all objects.
 */
function getDiff(objList, intersection) {
    var newObjList = $.map(objList, function(o) { return $.extend(true, {}, o); }); // copy

    $.each(newObjList, function(i, obj) {
        $.each(intersection, function(k) {
            var key = cleanFind(k, obj);

            if (key in obj) delete obj[key]; // remove
        });
    });

    return newObjList;
}

function isBarChart(chartType) {
    return chartType == 'bar' || chartType == 'horizBar';
}

function GradeModel() {
    return $.extend(this, {
        totals: [],
        counts: [],
        initialData: {},
        data: {},
        breakoutModel: null,
        gradingScale: null,
        colorScale: null,
        chartType: null,
        isBar: null,
        defaults: {},
        options: {},
        dataControls: {},
        valueKeys: {},
        avgKeys: {}
    });
}

GradeModel.prototype = {
    constructor: GradeModel,
    init: function(chartType, breakout, seriesBucket, results, options) {
        var that = this,
            gradingScales = filterGradingScalesForResults(dataCache.grading_scale || [], results.records),
            gradingScale = setupGradingScales(gradingScales);

        that.chartType = chartType;
        that.breakoutModel = new BucketModel(breakout);
        that.isBar = isBarChart(chartType);
        that.gradingScale = (that.gradingScale || gradingScale);
        $.extend(that.options, that.defaults, options);

        var controls = {};
        that.augmentControls(controls);
        that.dataControls = controls.dataControls;
        $.each(that.dataControls, function(i, o) {
            that.valueKeys[o.key] = 0;
            that.avgKeys[o.avgKey] = 0;
        });

        if (that.gradingScale) {
            if (that.isBar) {
                var numLevels = that.gradingScale.levels.length;

                // reverse since we build up the bars from F to A. copy before reversing so that scoreToLevelKey still works.
                gradingScale = $.extend(true, {}, that.gradingScale, {levels: that.gradingScale.levels.slice(0).reverse()});
                that.colorScale = Functions.getBackgroundColorScale(gradingScale.levels, true);
            }

            $.each(gradingScale.levels, function(gradingScaleId, level) { // create the initial buckets in order
                var displayName = levelToDisplay(level),
                    key = levelToKey(level),
                    minValue = level.min_value;

                that.initialData[key] = $.extend({'key': key, 'label': displayName,
                    title: minValue ? level.name + ' ≥ ' + minValue + (getGradingScaleIsNoPct() ? '' : '%') : ''}, that.valueKeys);
            });
        }

        that.data = (that.isBar || !that.breakoutModel.isValid() ? that.initialData : {});
    },
    addValue: function(record) {
        var that = this,
            gradingScale = that.gradingScale;

        // don't mix in non-pct based results with pct based results. Doesn't make sense.
        if (gradingScale.is_pct != getGradingScaleForSet(record).is_pct) return;

        $.each(that.dataControls, function(i, control) {
            var value = that.getValue(record, control),
                breakoutVal = that.breakoutModel.getBucketVal(record, value, gradingScale);

            that.addOneValue(record, breakoutVal, control, value);
        });
    },
    getValue: function(record, control) {
        var avgScore = parseFloat(record.avg_score) || 0;

        if (control.key === 'curvedValue') {
            if ('gradebook_score' in record) {
                return parseFloat(record.gradebook_score) || 0;
            }

            var curve = (record.assessment_id ? parseFloat(dataCache.assessment[record.assessment_id].curve) || 0 : 0),
                curvedScore = Math.min(100, avgScore + curve);

            return curvedScore;
        }

        return avgScore;
    },
    addOneValue: function(record, breakoutVal, control, value) {
        var that = this;

        if (value === null) return;

        var gradingScale = that.gradingScale,
            key = scoreToLevelKey(value, gradingScale),
            seriesData = that.isBar ? that.data[key] : that.data;

        if (that.breakoutModel.isValid()) {
            if (that.isBar) {
                if (!seriesData.displayMap || !(breakoutVal in seriesData.displayMap)) {
                    var breakoutDisplay = $.extend(that.breakoutModel.getBucketDisplay(breakoutVal), that.valueKeys);

                    $.each(that.data, function() {
                        var oneSeriesData = this;

                        if (!(oneSeriesData.displayMap)) oneSeriesData.displayMap = {};

                        oneSeriesData.displayMap[breakoutVal] = $.extend({}, breakoutDisplay);
                    });
                }

                seriesData = seriesData.displayMap[breakoutVal];
            } else {
                if (!(breakoutVal in seriesData)) {
                    seriesData[breakoutVal] = $.extend(true, {}, that.initialData);
                }

                seriesData = seriesData[breakoutVal];
            }
        }

        if (that.isBar) {
            seriesData[control.key]++;
            (seriesData[control.recordsKey] = seriesData[control.recordsKey] || []).push(record);
        } else {
            seriesData[key][control.key]++;
            (seriesData[key][control.recordsKey] = seriesData[key][control.recordsKey] || []).push(record);
        }

        (that.totals[breakoutVal] || (that.totals[breakoutVal] = $.extend({}, that.avgKeys)))[control.avgKey] += value;
        (that.counts[breakoutVal] || (that.counts[breakoutVal] = $.extend({}, that.avgKeys)))[control.avgKey] += 1;
    },
    augmentValue: function(value, v) {
        var that = this;

        if (that.isBar) {
            var breakoutVal = (value.breakoutVal || ''),
                total = that.totals[breakoutVal],
                count = that.counts[breakoutVal];

            $.each(that.dataControls, function(i, control) {
                value[control.key] = 100 * v[control.key] / (count[control.avgKey] || 1);
                value[control.countKey] = v[control.key];
                value[control.totalCountKey] = count[control.avgKey];
                value[control.avgKey] =  total[control.avgKey] / (count[control.avgKey] || 1);
                value[control.recordsKey] = v[control.recordsKey];
            });
        }

        return value;
    },
    augmentSeries: function(series) {
        var that = this;

        if (that.isBar) {
            series.color = that.colorScale(series.seriesIndex);
        } else {
            var breakoutVal = (series.breakoutVal || ''),
                total = that.totals[breakoutVal],
                count = that.counts[breakoutVal];

            $.each(that.dataControls, function(i, control) {
                series[control.avgKey] = total[control.avgKey] / count[control.avgKey];
            });
        }

        return series;
    },
    addLineSeries: function(allSeries, index) {
        var that = this,
            lineSeries = $.extend(true, {}, allSeries[0]),
            label = 'Avg. Score';

        $.each(lineSeries.values, function(j, o) {
            $.each(that.dataControls, function(i, control) {
                o[control.colorKey] = that.colorScale(that.gradingScale.levels.length - 1 - scoreToGradeLevel(o[control.avgKey], that.gradingScale).index);
            });
        });

        lineSeries.bar = false;
        lineSeries.key = index;
        lineSeries.label = label;
        lineSeries.color = '#1f77b4';

        allSeries.push(lineSeries);
    },
    augmentChart: function(chart, data) { // data is the whole data for the chart whereas this.data is just the results we were passed for this Series
        var pctFormat = function(v) { return Math.abs(v).toFixed(0) + '%'; },
            numericFormat = function(v) { return Tss.Number.toCommas(Math.abs(v), 1); },
            y2Format = function(v) { return v < 0 || !getGradingScaleIsNoPct()
                    ? '' : (getGradingScaleIsNoPct() ? numericFormat : pctFormat)(v); };

        if (this.isBar) {
            chart.legend.reverse(true); // since we build up the data from F to A but want to show the legend A to F
            chart.multibar.dispatch.on('elementClick', chartElementClick(chart));
            chart.lines.dispatch.on('elementClick', chartElementClick(chart));
            chart.offset(pivotOffset)
                .yDomain(function() { return chart.stacked() ? [-100, 100] : null; })
                .y2(function(d) { return d[nv.radioVal(chart.showDataControls()).avgKey]; })
                .scatterFillColor(function(d) { return d[nv.radioVal(chart.showDataControls()).colorKey]; })
                .tooltipContent(function(key, x, y, e, graph) {
                    var data = graph.data(),
                        point = e.point,
                        series = e.series || e.point,
                        seriesLabel = graph.label()(series),
                        dc = nv.radioVal(graph.showDataControls()),
                        datasetDisplay = getDatasetOption(getFilterColumn(point.seriesIndex)).text(),
                        formatter = (getGradingScaleIsNoPct() ? numericFormat : pctFormat),
                        pctAndAbove = 0;

                    if (e.seriesIndex > 0 && e.seriesIndex != data.length - 1) { // don't show 100% F and above or %A and above
                        for (var i = e.seriesIndex; i < data.length; i++) {
                            if (!data[i].bar) continue;
                            pctAndAbove += graph.multibar.y()(data[i].values[e.pointIndex], e.pointIndex);
                        }
                    }

                    return '<h3>' + seriesLabel + '</h3>'
                        + '<p>' + (e.series.bar ? y : formatter(point[dc.avgKey])) + ' in ' + x + '</p>'
                        + (pctAndAbove ? '<p>' + pctFormat(pctAndAbove) + ' at or above ' + seriesLabel + '</p>' : '')
                        + '<p>' + formatter(point[dc.avgKey]) + ' ' + scoreToGrade(point[dc.avgKey], getGradingScale())
                            + ' overall average score</p>'
                        + '<p>Click to see all ' + (e.series.bar ? point[dc.countKey] : point['totalCount']) + ' ' + datasetDisplay + '</p>'
//                            + '<p>Double-click to drill down</p>'
                    ;
                });
        } else {
            var gradingScale = getGradingScale() || this.gradingScale,
                colorScale = Functions.getBackgroundColorScale(gradingScale.levels);

            chart.pie.dispatch.on('elementClick', chartElementClick(chart));
            chart.color(function(d, i) { return colorScale(i); })
                .tooltipContent(function(key, x, y, e, graph) {
                    var point = e.point,
                        d = e.d,
                        seriesLabel = graph.label()(point),
                        dc = nv.radioVal(graph.showDataControls()),
                        datasetDisplay = data[0].dataset,
                        count = y,
                        pct = Math.round(100 * (d.endAngle - d.startAngle) / (2 * Math.PI)),
                        pctAndAbove = Math.round(100 * d.endAngle / (2 * Math.PI)),
                        formatter = (getGradingScaleIsNoPct() ? numericFormat : pctFormat),
                        avgScore = data[0][dc.avgKey];

                    return '<h3>' + seriesLabel + '</h3>'
                        + '<p>' + pct + '% in ' + x + '</p>'
                        + (e.index && e.index != data[0].values.length - 1 ? '<p>' + pctAndAbove + '% at or above ' + seriesLabel + '</p>' : '')
                        + '<p>' + formatter(avgScore) + ' ' + scoreToGrade(avgScore, getGradingScale())
                            + ' overall average score</p>'
                        + '<p>Click to see all ' + count + ' ' + datasetDisplay + '</p>'
//                            + '<p>Double-click to drill down</p>'
                    ;
                });
        }

        if (chart.yAxis) chart.yAxis.tickFormat(pctFormat);
        if (chart.yAxis2) {
            chart.lines.yDomain(function() {
                if (!getGradingScaleIsNoPct()) {
                    return chart.multibar.yScale().domain();
                }

                var maxMinValue = getGradingScale().levels[0].min_value;

                return [chart.stacked() ? -maxMinValue : 0, maxMinValue];
            });
            chart.yAxis2.tickFormat(y2Format);
        }

        return chart;
    },
    augmentControls: function(controls) {
        controls.dataControls = [
            {
                label: 'Raw',
                key: 'value',
                avgKey: 'avgScore',
                countKey: 'count',
                totalCountKey: 'totalCount',
                colorKey: 'avgColor',
                recordsKey: 'records'
            },
            {
                label: 'Gradebook',
                key: 'curvedValue',
                avgKey: 'curvedScore',
                countKey: 'curvedCount',
                totalCountKey: 'totalCurvedCount',
                colorKey: 'curvedColor',
                recordsKey: 'curvedRecords'
            },
        ];

        // FIXME remove keys that aren't in our map, use keys in the original map that are, add keys that are in our map but not in orig
        controls.sortControls = [
            {label: 'Default', sortCallback: sortCallback('breakoutIndex')},
            {label: 'A-Z', sortCallback: sortCallback('label')},
            {label: 'Avg', sortCallback: sortCallback('avgScore')},
            {label: 'Pivot', sortCallback: sortCallback('pivot')},
        ];
    }
}

function ObjectiveModel() {
    return GradeModel.apply(this); // super();
}

// inheritance! give sub-class the prototype members of the super class and then override with the child methods
inheritPrototype(GradeModel, ObjectiveModel, {
    getValue: function(record, control) {
        var that = this;

        if (control.key == 'lastValue') {
            var key = record.student_id + '_' + record.objective_id;

            (that.seenKeys || (that.seenKeys = {}));

            if (key in that.seenKeys && that.seenKeys[key] !== record.assessment_id) return null;

            that.seenKeys[key] = record.assessment_id;
        }

        var avgScore = ObjectiveModel.prototype.parent.getValue.call(this, record, control);

        return avgScore;
    },
    addOneValue: function(record, breakoutVal, control, value) {
        if (breakoutVal === 'None') return null;

        return ObjectiveModel.prototype.parent.addOneValue.call(this, record, breakoutVal, control, value);
    },
    augmentControls: function(controls) {
        ObjectiveModel.prototype.parent.augmentControls.call(this, controls);

        controls.dataControls = [
            {
                label: 'All',
                key: 'value',
                avgKey: 'avgScore',
                countKey: 'count',
                totalCountKey: 'totalCount',
                colorKey: 'avgColor',
                title: 'All Assessment Results',
                recordsKey: 'records'
            },
            {
                label: 'Last',
                key: 'lastValue',
                avgKey: 'lastAvgScore',
                countKey: 'lastCount',
                totalCountKey: 'totalLastCount',
                colorKey: 'lastColor',
                title: 'Most Recent Assessment Only',
                recordsKey: 'lastRecords'
            },
        ];
    }
});

function getSortedIndex(data, point) {
    if (!data.length) return 0;

    var series = data[0],
        values = series.values;

    if (series.bar) {
        for (var j = 0; j < values.length; j++) {
            if (point.key == values[j].key) {
                return j;
            }
        }
    }

    return 0;
}

function chartElementClick(chart) {
    return function(e) {
        var point = e.point,
            series = e.series || {},
            dc = nv.radioVal(chart.showDataControls()),
            key = dc.recordsKey || 'records',
            records = point[key],
            wasVisible = ($('.dataAccordion').accordion('option', 'active') !== false);

        // if they clicked the average get all records for this breakoutVal
        if (series.bar === false) {
            var data = chart.data(),
                index = getSortedIndex(data, point);

            records = [];

            $.each(data, function(j, s) {
                if (!s.bar) return true; // continue;

                records = records.concat(s.values[index][dc.recordsKey] || []);
            });
        }

        if (!wasVisible) {
            $('.filterColumn').data('preventRedraw', true);
            $('.dataAccordion').accordion({active: 0});
            $('.filterColumn').data('preventRedraw', null);
        }

        updateDataTable($('.filterColumn:first'), records);

        if (wasVisible) {
            scrollDataAccordionIntoView();
        }
    };
}

function BucketModel(bucketOption) {
    var bucketField = bucketOption.val() || '',
        bucketParts = bucketField.split('.'),
        bucketDisplayField = bucketOption.data('display') || bucketField.replace(/^([^\.]+\.)*/, ''),
        bucketDisplayFilter = nameFilter(bucketDisplayField),
        bucketDisplayInput = $('.filterColumn:first [name]').filter(bucketDisplayFilter).closest('.radio, .multi, select').parent().first()
            .find('[name]').filter(bucketDisplayFilter), // only return multiple inputs if they have the same parent, e.g. checkboxes/radio buttons
        allInputs = bucketDisplayInput.find('[value]').add(bucketDisplayInput.filter('[value]')),
        defaultInputVal = allInputs.first().val(),
        dateFormat;

    if (bucketField) {
        bucketParts[bucketParts.length - 1] = bucketOption.data('cache_key') || bucketParts[bucketParts.length - 1];
        if (bucketParts[0] == 'date') {
            dateFormat = bucketParts[bucketParts.length - 1];
        }
    }

    return {
        defaultVal: bucketOption.data('default_value') || (defaultInputVal == '' || defaultInputVal == '0' ? defaultInputVal : 'None'), // if the first val is a real val, use None
        bucketField: bucketField,
        bucketParts: bucketParts,
        dataCacheField: (bucketParts.length ? bucketParts[bucketParts.length - 1] : '').replace('_id', ''),
        bucketDisplayInput: bucketDisplayInput,
        bucketDisplayOptions: bucketDisplayInput.find('option'),
        displayCache: {},
        bucketBy: bucketOption.data('bucketby'),
        bucketByValCache: {},
        isValid: function() {
            return this.bucketField;
        },
        getLastBucketField: function() {
            return this.bucketParts.length ? this.bucketParts[this.bucketParts.length - 1] : '';
        },
        getBucketVal: function(record, value, gradingScale) {
            if (!this.isValid()) return '';

            var val = getBreakoutVal(record, this.bucketParts, value, gradingScale) || this.defaultVal;

            if (this.bucketBy && this.dataCacheField in dataCache && val in dataCache[this.dataCacheField]) {
                    var obj = dataCache[this.dataCacheField][val],
                        bucketByVal = obj[this.bucketBy];

                // semi-hack: if we've seen this objective code before, use the first bucketBal (objective_id) we saw
                // that way we effectively bucket by objective code
                if (bucketByVal in this.bucketByValCache) {
                    var result = this.bucketByValCache[bucketByVal];

                    return result;
                }

                this.bucketByValCache[bucketByVal] = val;
            }

            return val;
        },
        getBucketDisplay: function(bucketVal) {
            if (bucketVal in this.displayCache) return this.displayCache[bucketVal];

            var text,
                index;

            if (dateFormat) {
                if (dateFormat == 'day_of_week') {
                    index = bucketVal; // Mon = 1, Sun = 7
                    text = $.datepicker.regional[''].dayNamesShort[bucketVal == 7 ? 0 : bucketVal];
                } else {
                    var date = bucketVal ? dateStringToDate(bucketVal) : null;

                    index = date ? date.getTime() : 0;
                    if (dateFormat == 'month') {
                        text = $.datepicker.formatDate('M', date);
//                    } else if (dateFormat == 'week') {
//                        text = $.datepicker.formatDate('m/d', date);
                    } else {
                        text = $.datepicker.formatDate('m/d/y', date);
                    }
                }
            } else {
                if (this.dataCacheField in dataCache && bucketVal in dataCache[this.dataCacheField]) {
                    var obj = dataCache[this.dataCacheField][bucketVal];

                    text = obj.display_name || obj.name || obj.short_name; // or any property like _name?
                    index = parseInt(obj.order_key);
                }

                if (!text) {
                    var filter = '[value="' + bucketVal + '"]',
                        option = this.bucketDisplayInput.find(filter);

                    if (option.length) {
                        text = option.text();
                        index = this.bucketDisplayOptions.index(option);
                    } else {
                        option = this.bucketDisplayInput.filter(filter);
                        text = option.parent().find('[for="' + option.attr('id') + '"]').text();
                        index = this.bucketDisplayInput.index(option);
                    }
                }
            }

            var result = this.displayCache[bucketVal] = {
                display_name: text || (bucketVal !== undefined ? bucketVal : 'None'),
                order_key: index + 2 // always > 0
            };

            return result;
        },
        getRecordDisplay: function(record) {
            return this.getBucketDisplay(this.getBucketVal(record)).display_name || 'None';
        },
        getSortedVals: function(keys) {
            var that = this,
                displayMap = {};

            $.each(keys, function(i, key) {
                displayMap[key] = that.getBucketDisplay(key);
            });

            return Object.keys(displayMap).sort(function(aKey, bKey) {
                var aObj = displayMap[aKey],
                    a = aObj.order_key,
                    bObj = displayMap[bKey],
                    b = bObj.order_key;

                if (a == b) {
                    a = aObj.display_name;
                    b = bObj.display_name;
                }

                return (a == b ? 0 : (a < b ? -1 : 1));
            });
        },
        getAllInputs: function() { return allInputs; }
    };
}

function clearTotals() {
    var obj = this;

    obj.value = 0;

    $.each(Object.keys(obj), function(i, k) {
        var v = obj[k];

        if (_.isNumber(v) && k !== 'order_key') {
            obj[k] = 0;
        } else if (k === 'records') {
            obj.records = [];
        }
    });
}

function SimpleModel() {
    return $.extend(this, {
        counts: [],
        initialData: {},
        data: {},
        breakoutModel: null,
        seriesBucketModel: null,
        chartType: null,
        isBar: null,
        defaults: {},
        options: {}
    });
}

SimpleModel.prototype = {
    constructor: SimpleModel,
    init: function(chartType, breakout, seriesBucket, results, options) {
        var that = this;

        that.chartType = chartType;
        that.breakoutModel = new BucketModel(breakout);
        that.seriesBucketModel = new BucketModel(seriesBucket);
        that.isBar = isBarChart(chartType);
        $.extend(that.options, that.defaults, options);

        // if there's no breakoutVal, just have everything lump into one bucket
        if (that.seriesBucketModel.isValid()) {
            that.seriesBucketModel.getAllInputs().each(function() { // create the initial buckets in order
                var input = $(this),
                    key = input.val(),
                    displayInfo = that.seriesBucketModel.getBucketDisplay(key) || {},
                    displayName = displayInfo.display_name || input.text() || $('[for="' + input.attr('id') + '"]').text();

                if (!key && displayName == 'All') return true; // continue;

                that.initialData[key] = {key: key, label: displayName, value: 0};
            });
        } else {
            var key = '';

            that.initialData[key] = {key: key, label: key || 'All', value: 0};
        }

        that.data = {};
    },
    addValue: function(record) {
        var that = this,
            seriesBucketVals = that.seriesBucketModel.getBucketVal(record),
            breakoutVals = that.breakoutModel.getBucketVal(record),
            i,
            j;

        if (!$.isArray(breakoutVals)) {
            breakoutVals = [breakoutVals];
        }

        if (!$.isArray(seriesBucketVals)) {
            seriesBucketVals = [seriesBucketVals];
        }

        var numBreakoutVals = breakoutVals.length,
            numSeriesBucketVals = seriesBucketVals.length,
            numValues = numBreakoutVals * numSeriesBucketVals,
            multiplier = 1 / numValues;
        for (i = 0; i < numBreakoutVals; i++) {
            for (j = 0; j < numSeriesBucketVals; j++) {
                that.addOneValue(record, breakoutVals[i], seriesBucketVals[j], multiplier);
            }
        }
    },
    getDefaultMap: function(key, bucketModel) {
        var model = bucketModel || this.seriesBucketModel,
            displayInfo = model.getBucketDisplay(key) || {};

        return {
            key: key,
            label: displayInfo.display_name || key || 'All',
            value: 0
        };
    },
    addOneValue: function(record, breakoutVal, seriesBucketVal, multiplier) {
        var that = this,
            key = seriesBucketVal,
            defaultMap,
            existingKeys;

        if (that.isBar) {
            if (!(key in that.data)) {
                defaultMap = (key in that.initialData ? that.initialData[key] : that.getDefaultMap(key));

                that.data[key] = $.extend({}, defaultMap);
            }
        }

        var seriesData = that.isBar ? that.data[key] : that.data;

        if (that.breakoutModel.isValid()) {
            if (that.isBar) {
                if (!seriesData.displayMap || !(breakoutVal in seriesData.displayMap)) {
                    var breakoutDisplay = that.breakoutModel.getBucketDisplay(breakoutVal);

                    clearTotals.apply(breakoutDisplay);

                    defaultMap = {};
                    existingKeys = Object.keys(this.data);
                    arrayRemove(existingKeys, key);
                    if (existingKeys.length) {
                        defaultMap = $.extend(true, {}, this.data[existingKeys[0]].displayMap); // all present keys should be in all maps
                        $.each(defaultMap, clearTotals);
                    }

                    $.each(that.data, function(k, oneSeriesData) {
                        if (!(oneSeriesData.displayMap)) oneSeriesData.displayMap = defaultMap;

                        if (!(breakoutVal in oneSeriesData.displayMap)) {
                            oneSeriesData.displayMap[breakoutVal] = $.extend({}, breakoutDisplay);
                        }
                    });
                }

                seriesData = seriesData.displayMap[breakoutVal];
            } else {
                if (!(breakoutVal in seriesData)) {
                    defaultMap = {};
                    if ((existingKeys = Object.keys(seriesData)).length) {
                        defaultMap = $.extend(true, {}, seriesData[existingKeys[0]]); // all present keys should be in all maps
                        $.each(defaultMap, clearTotals);
                    }

                    seriesData[breakoutVal] = defaultMap;//$.extend(true, {}, that.initialData);
                }

                seriesData = seriesData[breakoutVal];

                if (!(key in seriesData)) {
                    defaultMap = (key in that.initialData ? that.initialData[key] : that.getDefaultMap(key));

                    that.initialData[key] = $.extend({}, defaultMap);

                    $.each(that.data, function(breakoutVal, oneSeriesData) { // make sure this key exists in all maps
                        oneSeriesData[key] = $.extend({}, defaultMap);
                    });
                }
            }
        }

        var valueMap;

        if (that.isBar) {
            valueMap = seriesData;
        } else {
            if (!(key in seriesData)) {
                defaultMap = (key in that.initialData ? that.initialData[key] : that.getDefaultMap(key));

                seriesData[key] = $.extend({}, defaultMap);
            }

            valueMap = seriesData[key];
        }

        (valueMap.records = valueMap.records || []).push(record);
        that.addRecordToSeries(record, valueMap, breakoutVal, multiplier);
    },
    addRecordToSeries: function(record, valueMap, breakoutVal, multiplier) {
        var that = this;

        valueMap.value += multiplier;
        that.counts[breakoutVal] = (that.counts[breakoutVal] || 0) + multiplier;
    },
    augmentSeries: function(series) { return series; },
    addLineSeries: function(allSeries, index) {},
    augmentValue: function(value, v) {
        if (this.isBar) {
            var breakoutVal = (value.breakoutVal || ''),
                count = this.counts[breakoutVal] || 0,
                val = v.value || 0;

            value.records = v.records;
            value.value = val;
            value.count = val;
            value.totalCount = count;
        }

        return value;
    },
    augmentChart: function(chart, data) {
        var that = this;

        if (that.isBar) {
            chart.multibar.dispatch.on('elementClick', chartElementClick(chart));
            chart.lines.dispatch.on('elementClick', chartElementClick(chart));
            chart.tooltipContent(function(key, x, y, e, graph) {
                var point = e.point,
                    series = e.series || e.point,
                    seriesLabel = graph.label()(series),
                    datasetOption = getDatasetOption(getFilterColumn(point.seriesIndex)),
                    datasetDisplay = datasetOption.data('display') || datasetOption.text();

                return '<h3>' + seriesLabel + '</h3>'
                    + '<p>Click to see all ' + (e.series.bar ? point.records.length : point['totalCount']) + ' ' + datasetDisplay + '</p>'
                    + that.augmentTooltip(e, graph)
                ;
            });
        } else {
            chart.pie.dispatch.on('elementClick', chartElementClick(chart));
            chart.tooltipContent(function(key, x, y, e, graph) {
                var point = e.point,
                    d = e.d,
                    seriesLabel = graph.label()(point),
                    datasetDisplay = data[0].dataset,
                    count = point.records.length,
                    pct = Math.round(100 * (d.endAngle - d.startAngle) / (2 * Math.PI));

                return '<h3>' + seriesLabel + '</h3>'
                    + '<p>' + pct + '% in ' + x + '</p>'
                    + '<p>Click to see all ' + count + ' ' + datasetDisplay + '</p>'
                    + that.augmentTooltip(e, graph)
                ;
            });
        }

        if (chart.yAxis) chart.yAxis.tickFormat(that.yAxisFormatter);

        return chart;
    },
    augmentTooltip: function(e, graph) {
        return '';
    },
    augmentControls: function(controls) {
        controls.dataControls = [
            {label: 'Count', key: 'value'},
        ];

        controls.sortControls = [
            {label: 'Default', sortCallback: sortCallback('breakoutIndex')},
            {label: 'A-Z', sortCallback: sortCallback('label')},
            {label: 'Total', sortCallback: sortCallback('totalCount')},
        ];
    },
    yAxisFormatter: function(v) { return Math.abs(v) < 2 ? v : Tss.Number.toCommas(v); },
    getSortedSeriesVals: function() {
        return this.seriesBucketModel.getSortedVals(Object.keys(this.data));
    }
}

function AbsenceModel() {
    return SimpleModel.apply(this); // super();
}

// inheritance! give sub-class the prototype members of the super class and then override with the child methods
inheritPrototype(SimpleModel, AbsenceModel, {
    addRecordToSeries: function(record, valueMap, breakoutVal) {
        var that = this;

        valueMap.value += 100 * record.value;
        that.counts[breakoutVal] = (that.counts[breakoutVal] || 0) + 1;
    },
    augmentValue: function(value, v) {
        AbsenceModel.prototype.parent.augmentValue.call(this, value, v);

        if (this.isBar) {
            var breakoutVal = (value.breakoutVal || ''),
                breakoutField = this.breakoutModel.bucketParts[this.breakoutModel.bucketParts.length - 1];

            value.value /= getDaysInTimePeriod(breakoutVal, breakoutField);
        }

        return value;
    },
    yAxisFormatter: function(v) { return (Math.round(v * 100) / 100) + '%'; }
});

function getSettingAsOf(key, asof) {
    var historicalSetting = historicalSettings[key] || {},
        historicalSettingsKeys = Object.keys(historicalSetting).sort(), // date
        setting = settings[key] || null,
        result = null;

    $.each(historicalSettingsKeys, function(i, date) {
        if (asof < date) {
            result = historicalSetting[date];
            return false; //break;
        }
    });

    return result || setting;
}

function DemeritModel() {
    var that = SimpleModel.apply(this); // super();

    that.fields = ['cash_value', 'demerit_value', 'detention_value', 'lunch_detention_value', 'third_detention_value'];

    return that;
}

inheritPrototype(SimpleModel, DemeritModel, {
    addRecordToSeries: function(record, valueMap, breakoutVal, multiplier) {
        var that = this,
            demeritType = (dataCache.demerit_type[record.demerit_type_id] || {});

        valueMap.value += multiplier;
        valueMap.amount = (valueMap.amount || 0) + multiplier * record.multiplier;

        $.each(that.fields, function(i, field) {
            valueMap[field] = (valueMap[field] || 0)
                + (multiplier * parseFloat(record[field] || 0));
        });

        that.counts[breakoutVal] = (that.counts[breakoutVal] || 0) + multiplier;
    },
    augmentValue: function(value, v) {
        var that = this;

        DemeritModel.prototype.parent.augmentValue.call(this, value, v);

        if (v) {
            value.amount = v.amount || 0;
            $.each(that.fields, function(i, field) {
                value[field] = v[field] || 0;
            });
        }

        $.each(that.fields, function(i, field) {
            value['signed_' + field] = value[field];
            value[field] = Math.abs(value[field]); // NB: take abs here so all bucket are positive and draw/stack up from zero!
        });

        return value;
    },
    augmentTooltip: function(e, graph) {
        var dc = nv.radioVal(graph.showDataControls()),
            key = 'signed_' + dc.key,
            point = e.point;

        return key in point ? '<p>' + point[key] + ' ' + dc.label + '</p>' : '';
    },
    augmentControls: function(controls) {
        var that = this;

        DemeritModel.prototype.parent.augmentControls.call(that, controls);

        controls.dataControls = [
            {label: 'Count', key: 'value'},
            {label: 'Amount', key: 'amount'},
            {label: that.options.cash_title, key: 'cash_value'},
        ];

        if (that.options.has_demerits) {
            controls.dataControls.push({label: that.options.demerit_title, key: 'demerit_value'});
        }

        if (that.options.has_eod_detention) {
            controls.dataControls.push({label: that.options.detention_title, key: 'detention_value'});
        }

        if (that.options.has_lunch_detention) {
            controls.dataControls.push({label: that.options.lunch_detention_title, key: 'lunch_detention_value'});
        }

        if (that.options.has_third_detention) {
            controls.dataControls.push({label: that.options.third_detention_title, key: 'third_detention_value'});
        }
    }
});

function getDataModelForDataset(dataset) {
    if (dataset == 'assessments' || dataset == 'assessment_students' || dataset == 'course_grades') {
        return new GradeModel();
    } else if (dataset == 'objectives') {
        return new ObjectiveModel();
    } else if (dataset == 'absences') {
        return new AbsenceModel();
    } else if (dataset == 'demerits') {
        return new DemeritModel();
    }

    return new SimpleModel();
}

if (typeof Object.create !== 'function') {
    Object.create = function (prototype) {
        function F() {}
        F.prototype = prototype;
        return new F();
    };
}

function inheritPrototype(parentObject, childObject, childPrototype) {
    var copyOfParent = Object.create(parentObject.prototype), // clone parent
        addToChildPrototype = function(k, v) { childObject.prototype[k] = v; };

    $.each(copyOfParent, addToChildPrototype); // add parent methods to child
    $.each(childPrototype || {}, addToChildPrototype); // add new or override methods for child
    $.each({constructor: childObject, parent: copyOfParent}, addToChildPrototype); // add generic methods
}

window.addGraph = addGraph;
window.initControl = initControl;
window.initControls = initControls;
window.sortCallback = sortCallback;
window.sortByKey = sortByKey;
window.cmp = cmp;
window.setupGradingScales = setupGradingScales;
window.setupPivotDropdown = setupPivotDropdown;
window.getPivot = getPivot;
window.pivotChange = pivotChange;
window.pivotOffset = pivotOffset;
window.posNegOffset = posNegOffset;
window.redrawAllCharts = redrawAllCharts;
window.scoreToGradeLevel = scoreToGradeLevel;
window.levelToKey = levelToKey;
window.levelToDisplay = levelToDisplay;
window.scoreToGrade = scoreToGrade;
window.scoreToLevelKey = scoreToLevelKey;
window.getGradingScale = getGradingScale;
window.getGradingScaleIsNoPct = getGradingScaleIsNoPct;
window.hexToRgb = hexToRgb;
window.removeAlpha = removeAlpha;
window.addAlpha = addAlpha;
window.cleanFind = cleanFind;
window.isEqual = isEqual;
window.getIntersection = getIntersection;
window.getDiff = getDiff;
window.isBarChart = isBarChart;
window.GradeModel = GradeModel;
window.ObjectiveModel = ObjectiveModel;
window.getSortedIndex = getSortedIndex;
window.chartElementClick = chartElementClick;
window.BucketModel = BucketModel;
window.clearTotals = clearTotals;
window.SimpleModel = SimpleModel;
window.AbsenceModel = AbsenceModel;
window.getSettingAsOf = getSettingAsOf;
window.DemeritModel = DemeritModel;
window.getDataModelForDataset = getDataModelForDataset;
window.inheritPrototype = inheritPrototype;

var Tooltips = {
    selectors: '[tss-tooltip], [tss-tooltip-side]',
    delayInterval: 500,
    init: function() {
        Tooltips.setBindings();
    },

    setBindings: function(selector) {
        Tooltips.selectors = selector ? selector : Tooltips.selectors;
        $(document).off('mouseenter.tooltip').on('mouseenter.tooltip', Tooltips.selectors, Tooltips.showIntent);
    },

    showIntent: function() {
        var $this = $(this);

        $this.hoverIntent({
            interval: Tooltips.delayInterval,
            over: function(e) {
                Tooltips.show.apply(this, e);
            },
            out: function(e) {
                Tooltips.hide.apply(this, e);
            }
        });

        $this.click(function(e) {
            Tooltips.hide.apply(this, e);
        });

        if (!$this.data('intentShow')) {
            Tooltips.setTitle($this, $this.attr("title"));
            $this.data('intentShow', true);
            $this.trigger('mouseenter');
        }
    },

    show: function(e) {
        var $this = $(this),
            isSide = !_.isUndefined($this.attr('tss-tooltip-side')),
            isCentered = !_.isUndefined($this.attr('tss-tooltip-centered')),
            autoSize = !_.isUndefined($this.attr('tss-tooltip-auto-size')),
            offsetY = $this.attr('tss-tooltip-offset-y') || 0,
            offsetX = $this.attr('tss-tooltip-offset-x') || 0,
            size = $this.attr('tss-tooltip-size'),
            force = $this.attr('tss-tooltip-force-align');

        //make sure there's content
        var content = Tooltips.getContent($this);

        //do nothing if no content found
        if (!content || $this.hasClass('has-tss-tooltip')) {
            return;
        }
        $this.addClass('has-tss-tooltip');

        //get coordinates of event element
        var toolTip = $("<div>").addClass("tss-tooltip has-" + $this.data("content")),
            coords = $this.offset(),
            outerWidth = $this.outerWidth(),
            outerHeight = $this.outerHeight(),
            windowWidth = $(window).width(),
            tooltipCss = {
                top: coords.top + outerHeight
            },
            xCoord = coords.left + outerWidth;

        toolTip.html(content);
        Tooltips.setSize(toolTip, size, autoSize);
        toolTip.html(content).appendTo('body');

        //side-aligned
        if (isSide) {
            toolTip.addClass('tss-tooltip-side');
            tooltipCss.top = coords.top;
            if (force == 'left' || (!force && (windowWidth - xCoord) < (windowWidth / 2))) {
                tooltipCss.right = (windowWidth - coords.left) + 'px';
                toolTip.addClass('arrow-left');
            } else {
                tooltipCss.left = (coords.left + outerWidth) + 'px';
                toolTip.addClass('arrow-right');
            }
        }
        //bottom-aligned
        else {
            if (force == 'right' || (!force && (windowWidth - xCoord) < (windowWidth / 2))) {
                tooltipCss.right = (windowWidth - xCoord) + 'px';
                toolTip.addClass('arrow-right');
            } else {
                tooltipCss.left = coords.left + 'px';
                toolTip.addClass('arrow-left');
            }
        }

        if (isCentered) {
            var centerOfContainer = coords.left + (outerWidth / 2)
            var halfOfTooltip = toolTip.outerWidth() / 2;
            tooltipCss.left = centerOfContainer - halfOfTooltip;
            toolTip.addClass('arrow-center');
        }

        tooltipCss.top += parseInt(offsetY);
        if (tooltipCss.left) {
            tooltipCss.left = parseInt(tooltipCss.left) + parseInt(offsetX);
        } else {
            tooltipCss.right = parseInt(tooltipCss.right) - parseInt(offsetX);
        }

        toolTip.css(tooltipCss);

        //so we can see the animation when it opens
        setTimeout(function() {
            toolTip.addClass('open');
        }, 1);
    },

    hide: function() {
        $('.tss-tooltip').remove();
        $(this).removeClass('has-tss-tooltip');
    },

    getContent: function(element) {
        var template = element.attr("tss-tooltip-template");
        var data = element.attr("tss-tooltip-data") || {};

        if (data && _.isString(data) && _.get(data, 0) == '{') {
            data = JSON.parse(data);
        }

        var content = template
            ? Handlebars.renderTemplate(template, data)
            : Tooltips.getTitle(element);

        element.data("content", template ? "template" : "title");
        return content;
    },

    getTitle: function (element) {
        var title = element.attr("title");
        if (title) {
            Tooltips.setTitle(element, title);
        } else {
            title = element.data("title");
        }
        return title;
    },

    setTitle: function(element, title) {
        element.removeAttr("title");
        element.data("title", title);
    },

    setSize: function(toolTip, size, autoSize = false) {
        var html = toolTip.html(),
            isTitle = toolTip.hasClass("has-title"),
            dummyEl = $(isTitle ? "<span>" : "<div>").html(html).css({ opacity: "0" }).appendTo("body"),
            width = dummyEl.width();

        dummyEl.remove();

        if (size) {
            toolTip.addClass(size);
        } else if (autoSize) {
            toolTip.width(width + 30);
        } else if (width < 90) {
            toolTip.addClass('small');
        } else if (width < 190) {
            toolTip.addClass('medium');
        } else {
            toolTip.addClass('large');
        }
    }

};

$(Tooltips.init);

window.Tooltips = Tooltips;

var Functions = {

    imageExtensions: [
        'jpg',
        'jpeg',
        'png',
        'bmp',
        'gif'
    ],

    fontColors: [
        'rgb(82, 193, 119)', // green
        'rgb(240, 202, 77)', // yellow
        'rgb(225, 90, 73)' // red
    ],

    backgroundColors: [
        'rgb(87, 187, 138)',
        'rgb(255, 214, 102)',
        'rgb(230, 124, 115)'
    ],

    getColors: function(length, reverse, greenYellowRed) {
        var colors = greenYellowRed.slice(0); // copy

        if (length <= 2) {
            colors.splice(1, 1); // remove yellow
        }

        if (length <= 1) {
            colors[1] = colors[0]; // set both endpoints to green
        }

        return reverse ? colors.reverse() : colors;
    },

    _getColorScale: function(levels, reverse, colors) {
        var length = levels.length;
        var floorOrCeil = reverse ? Math.ceil : Math.floor;
        var domain = length <= 2 ? [0, 1] : [0, floorOrCeil((length - 1) / 2), length - 1];

        return function(i) {
            var colorOverrides = _.map(levels, 'color_override');
            var colorScale = d3.scale.linear().domain(domain).range(colors);

            if (reverse) {
                colorOverrides = colorOverrides.reverse();
            }

            return colorOverrides[i] || colorScale(i);
        };
    },

    getFontColorScale: function(levels, reverse) {
        var colors = this.getColors(levels.length, reverse, this.fontColors);

        return this._getColorScale(levels, reverse, colors);
    },

    getBackgroundColorScale: function(levels, reverse) {
        var colors = this.getColors(levels.length, reverse, this.backgroundColors);

        return this._getColorScale(levels, reverse, colors);
    },

    getColor: function(scoreCell, defaultColor, shouldAddAlpha) {
        if (defaultColor) {
            return this.addAlpha(defaultColor, shouldAddAlpha);
        }

        var numLevels = scoreCell.data('num_levels');
        var index = scoreCell.data('index');
        var colorOverride = scoreCell.data('color_override');

        return colorOverride
            ? this.addAlpha(colorOverride, shouldAddAlpha)
            : this.getBackgroundColorByIndex(index, numLevels, colorOverride, shouldAddAlpha);
    },

    addAlpha: function(color, shouldAddAlpha) {
        return shouldAddAlpha !== false ? addAlpha(color, $.isNumeric(shouldAddAlpha) ? shouldAddAlpha : '.9') : color;
    },

    getBackgroundColorByIndex: function(index, numLevels, colorOverride, shouldAddAlpha) {
        if (!numLevels) {
            return undefined;
        }

        if (colorOverride) {
            return colorOverride;
        }

        var colorScale = this.getBackgroundColorScale(new Array(parseInt(numLevels)));
        var color = colorScale(index);

        return this.addAlpha(color, shouldAddAlpha);
    },

    getScoreLevel: function (
        score,
        gradingScaleLevels,
        alreadyParsed = false,
        alreadyOrdered = false
    ) {
        var parsedGradingScaleLevels = alreadyParsed
            ? gradingScaleLevels
            : _.map(gradingScaleLevels, this.parseFloatMinValue);

        // levels must be ordered by value descending, so that the first match we find
        // is necessarily the highest level possible (i.e. the correct one) for this score
        var orderedGradingScaleLevels = alreadyOrdered
            ? parsedGradingScaleLevels
            : _.orderBy(parsedGradingScaleLevels, 'min_value', 'desc');

        var foundLevel = _.chain(orderedGradingScaleLevels)
            .find(level => parseFloat(score) >= parseFloat(level.min_value))
            .value();

        return foundLevel || _.last(orderedGradingScaleLevels);
    },

    getScoreColor: function (
        score,
        gradingScaleLevels,
        colorArray = null, // FIXME why passing this in?
        alreadyParsed = false,
        alreadyOrdered = false
    ) {
        var parsedGradingScaleLevels = alreadyParsed
            ? gradingScaleLevels
            : _.map(gradingScaleLevels, this.parseFloatMinValue);

        var orderedGradingScaleLevels = alreadyOrdered
            ? parsedGradingScaleLevels
            : _.orderBy(parsedGradingScaleLevels, 'min_value', 'desc');

        var colorScale = this.getFontColorScale(parsedGradingScaleLevels, false, colorArray);
        var gradingScaleLevel = this.getScoreLevel(score, orderedGradingScaleLevels, true, true);

        return colorScale(_.findIndex(orderedGradingScaleLevels, gradingScaleLevel));
    },

    parseFloatMinValue: function (gradingScaleLevel) {
        var minVal = _.isNull(gradingScaleLevel.min_value)
            ? 0
            : parseFloat(gradingScaleLevel.min_value);
        return _.extend({}, gradingScaleLevel, { min_value: minVal });
    },

    removeArrayItemByIndex: function(index, array) {
        if(typeof array == 'object' && array[index]) {
            array.splice(index, 1);
        }

        return array;
    },

    /**
     * Function.get({a: {b: {c: 'foo'}}}, ['a', 'b']) => {c: 'foo'}
     * Function.get({a: {b: {c: 'foo'}}}, ['x', 'b']) => null
     */
    get: function(obj, keys) {
        var that = this;

        if (!obj) {
            return null;
        }

        if ($.isArray(keys)) {
            var subObj = obj;

            $.each(keys, function(i, key) {
                subObj = that.get(subObj, key);
            });

            return subObj;
        } else {
            var key = keys;

            if (key in obj) {
                return obj[key];
            }
        }

        return null;
    },

    /**
     * Function.set({a: {b: {c: 'foo'}}}, 'bar', ['a', 'b', 'd']) will change arg1 to {a: {b: {c: 'foo', d: 'bar'}}}
     * Function.set({a: {b: {c: 'foo'}}}, 'bar', ['x', 'b']) will change arg1 to {a: {b: {c: 'foo'}}, x: {b: 'bar'}}
     */
    set: function(obj, value, keys) {
        if (obj) {
            if ($.isArray(keys)) {
                $.each(keys, function(i, key) {
                    if (i != keys.length - 1) {
                        obj[key] = obj[key] || {};
                        obj = obj[key];
                    } else {
                        obj[key] = value;
                    }
                });
            } else {
                obj[keys] = value;
            }
        }
    },

    executeCallback: function(callback, argument) {
        //validate callback as method
        if(callback && typeof callback === 'function') {
            callback(argument);
        }
    },

    stringifiedValsEqual: function(val1, val2) {
        //great for arrays and objects
        return JSON.stringify(val1) == JSON.stringify(val2);
    },

    waitForAjaxDone: function(callback, e) {
        var that = this,
            $el = typeof e === 'object' ? $(e.target) : null;

        if($.active === 0) {
            if($el) {
                $el.removeClass('disabled');
            }
            this.executeCallback(callback);
        } else {
            if($el) {
                $el.addClass('disabled');
            }
            setTimeout(function() {
                that.waitForAjaxDone(callback, e);
            }, 500);
        }
    },

    scrollMultiselectOnOpen: function(el) {
        el = $(el);

        var checked = el.multiselect('getChecked');

        if(checked.length !== 0) {
            $(checked[0]).scrollintoview({
                yoffset: 250
            });
        }

    },

    isImage: function(filename) {
        return this.hasExtension(filename, this.imageExtensions);
    },

    hasExtension: function(filename, validExtensions) {
        var extension = String(filename).split('.').pop().toLowerCase();

        return _.indexOf(validExtensions, extension) !== -1;
    },

    humanizeFieldName: function(fieldName) {
        fieldName = this.removeIdFromFieldName(fieldName);
        return this.toTitleCase(this.replaceAll(fieldName, '_', ' '));
    },

    removeIdFromFieldName: function(fieldName) {
        return fieldName.replace('_id', '');
    },

    toTitleCase: function(string) {
        return string.replace(/\w\S*/g, function(str){
            return str.charAt(0).toUpperCase() + str.substr(1).toLowerCase();
        });
    },

    convertType: function (type) {
        var self = this, words = type.split("-"), converted = "";
        $.each(words, function (i, word) {
            converted += self.toTitleCase(word);
        });
        return converted;
    },

    replaceAll: function(string, find, replace) {
        return string.replace(new RegExp(find, 'g'), replace);
    },

    charToInt: function (c) {
        return "" + (c.charCodeAt(0) - "A".charCodeAt(0) + 1);
    },

    /**
     * This method allows us to bind many elements together by a single
     * attribute and specify the event used for binding
     */
    setTwoWayBindings: function() {
        $('[data-bind]').each(function() {
            var el = $(this),
                attr = el.data('bind') || 'name',
                event = el.data('event') || 'change.twoWayBinding';

            //bind to linked elements
            $('[' + attr + '="' + el.attr(attr) + '"]').not(el).each(function() {
                var self = $(this);

                self.on(event, function(e, noTrigger) {
                    if(!noTrigger) {
                        el.val(self.val()).trigger(event, true);
                    }
                });
            });
        });
    },

    createGradingScaleSetLabel: function(gradingScales, gradingScaleSetLabel) {
        var scaleDesc = [];

        $.each(gradingScales, function(i, gradingScale) {
            var levelDesc = [],
                name = gradingScale.name,
                noPct = (gradingScale.is_pct == 0),
                levels = gradingScale.levels;

            $.each(levels, function(i, level) {
                levelDesc.push(level.name + (level.min_value > 0 ? ': ' + level.min_value + (noPct ? '' : '%') : ''));
            });
            scaleDesc.push(name + ' (' + levelDesc.join(', ') + ')');
        });

        var maxToDisplay = 1,
            tooMany = scaleDesc.length > maxToDisplay,
            sep = '<br/>',
            t = '<span>' + scaleDesc.slice(0, maxToDisplay).join(sep) + (tooMany ? ' ...' : '') + '</span>',
            orig = '<span>' + scaleDesc.join(sep) + '</span>';

        if (tooMany) {
            gradingScaleSetLabel.tipsy({gravity: 'n', className: 'white medium left', title: function() { return orig; }, html: true, opacity: 1});
        }

        var alert = Handlebars.renderTemplate("alert", {
            type: "info",
            message: t
        });

        gradingScaleSetLabel.html(alert).show();
    },

    /**
     * Clones table and replaces colspans and rowspans with empty cells
     * to allow for proper CSV export formatting
     *
     * @param  object $table
     * @returns object
     */
    handleTableSpansForExport: function($table) {
        var $tableClone = $table.clone(), // we clone the table so can can modify it without disrupting the UI
            $rows = $tableClone.find('tr'),
            rowSpanRepeat = 1,
            rowSpanCol,
            $rowSpanColClone;

        //iterate over rows
        for (var iRow = 0; iRow < $rows.length; iRow++) {
            var $row = $rows.eq(iRow),
                $cols = $row.find('th, td');

            //iterate over columns in row
            for (var iCol = 0; iCol < $cols.length; iCol++) {
                var $col = $cols.eq(iCol),
                    colSpan = $col.attr('colspan') || 1,
                    rowSpan = $col.attr('rowspan') || 1;

                //clone the column and remove colspan and rowspan
                var $colClone = $col.removeAttr('colspan').removeAttr('rowspan').clone();

                //if colspan found, append empty columns after current columns accordlingly
                for(var i = 1; i < colSpan; i++) {
                    $col.after($col.is('th') ? $('<th />') : $('<td />'));
                }

                //add empty columns to handle rowspans
                if(rowSpanRepeat > 1 && rowSpanCol == iCol) {
                    $col.before($rowSpanColClone.is('th') ? $('<th />') : $('<td />'));
                    rowSpanRepeat--;
                    continue;
                }

                //if rowspan found, store info for iteration later
                if(rowSpan > 1) {
                    rowSpanRepeat = rowSpan;
                    rowSpanCol = iCol;
                    $rowSpanColClone = $colClone;
                }
            }
        }

        //remove classes to make smaller in memory
        $tableClone.find('td, th').removeAttr('class');

        return $tableClone;
    },

    /**
     * Get Position of jquery element
     * @param jquery $element
     * @return object
     */
    getPosition: function ($element) {
        var offset = $element.offset();
        return {
            top: offset.top,
            left: offset.left
        };
    },

    getUriParams: function (uri) {
        var params = (uri || document.location.search).replace(/(^\?)/,"").split("&").map(function (n) {
            return n = n.split("="), this[n[0]] = n[1], this
        }.bind({}))[0];
        return params;
    },

    getGroupedOptions: function(options, groupKey) {
        var lastKey = undefined;
        var defaultGroupNum = 0;
        var allGroups = [];
        var groupedOptions = _.groupBy(options, x => {
            let key = _.get(x, groupKey);
            let label = key;

            if (key != lastKey) {
                lastKey = key;
                defaultGroupNum++;
            }

            if (!key) {
                key = `__group__${defaultGroupNum}`;
                label = '';
            }

            allGroups.push({key, label});

            return key;
        });
        var groupedOptionKeys = _.uniqBy(allGroups, 'key');

        return {
            groupedOptions,
            groupedOptionKeys,
        };
    },

};

if (typeof window !== "undefined") {
    window.Functions = Functions;
} else {
    module.exports = Functions;
}

(function ($, window, undefined) {"use strict";

    var Homerooms = {
        studentGroupsApi: '/api/v1/students/',
        unassignedStudentsApi: '/api/v1/student-groups/students-without-homerooms',
        exportUrl: '/setup/export/homerooms/download',
        defaults: {
            fixed: true, // display growls forever until user manually X-es them out
        },

        init: function(opts) {
            this.config = $.extend({}, this.defaults, opts);

            this.setBindings();
            this.getUnassignedStudentCounts();
        },

        getUnassignedStudentCounts: function() {
            var self = this;

            $.get(this.unassignedStudentsApi, function(data) {
                var thisSchoolCount = data.results.this_school_count;
                var otherSchoolsCount = data.results.other_schools_count;
                var defaultMessage = `All your ${self.config.studentsTitle} are assigned to ${self.config.homeroomsTitle}. Nice!`;
                var thisSchoolWarning = thisSchoolCount
                    ? `You have ${thisSchoolCount} ${self.config.studentsTitle} not assigned to ${self.config.homeroomsTitle} at this school.`
                    : '';
                var otherSchoolsWarning = otherSchoolsCount
                    ? `You have ${otherSchoolsCount} ${self.config.studentsTitle} not assigned to ${self.config.homeroomsTitle} at other schools.`
                    : '';

                if (!thisSchoolCount) {
                    Growl.success({ message: defaultMessage, fixed: self.config.fixed });
                } else {
                    Growl.warning({ message: thisSchoolWarning, fixed: self.config.fixed });
                }

                if (otherSchoolsCount) {
                    Growl.warning({ message: otherSchoolsWarning, fixed: self.config.fixed });
                }
            });
        },

        setBindings: function() {
            var self = this;

            $('ul.tabs-with-borders').tssTabs();

            $('input[type="checkbox"]').tssCheckbox({
                onChange: function() {
                    $('[rel-show-hide="has_homeroom_overrides"]').toggle();

                    // school ids are ignored if you've selected sections so make that clearer
                    if ($('[rel-show-hide="has_homeroom_overrides"]:visible').length) {
                        $('input[name="school_ids"]').attr('disabled', 'disabled').closest('.tss-switch').addClass('disabled');
                    } else {
                        $('input[name="school_ids"]').removeAttr('disabled').closest('.tss-switch').removeClass('disabled');
                    }
                }
            });

            $('[rel="assign-from-sections"]').click(function(e) {
                var params = self.getParams();

                if (!params) return;

                $.post(self.exportUrl, params, function(t) {
                    var data = CsvToArray(t, ','); // handles commas within fields as long as they're surrounded in double-quotes

                    $('[data-tab="import"]').click().scrollintoview();
                    handleData(data); // as if we'd dropped the csv file into the import tab
                });
            });

            $('[rel="export-data"]').click(function(e) {
                var params = self.getParams();

                if (!params) return;

                return genericDownload(self.exportUrl, null, params)(e);
            });

            $('#import-form').on('response', function(e, data) {
                if (!_.get(data, 'meta.what_if')) {
                    self.getUnassignedStudentCounts(); // refresh
                }
            });
        },

        getParams: function() {
            var self = this;
            var params = serializeObject();

            if (!$('[rel-show-hide="has_homeroom_overrides"]:visible').length) {
                delete params['section_ids'];
            } else if (!params['section_ids']) {
                var errorMessage = `Please select all sections representing your ${self.config.homeroomsTitle}`;

                displayWarning('section_ids', errorMessage);
                return null;
            } else {
                clearNotifications();
            }

            return _.mapValues(params, function(v) { return _.isArray(v) ? _.join(v) : v; });
        }
    };

    window.Tss = window.Tss || {};
    window.Tss.Homerooms = Homerooms;

})(jQuery, window);

(function ($, window, undefined) {"use strict";

	var absences = window.absences = {};

	absences.config = {
		sections: {},
		scan: {
			absencePath: "/absence/add/",
			lookupPath: "/student/lookup/",
			dialogOptions: {
				width: 600,
				modal: true,
		        autoOpen: false,
		        resizable: false,
		        draggable: false,
		        dialogClass: "minimal-dialog",
		        open: function () {
		        	absences.config.studentSelect.nextAll("ul").find(".icon-remove").click();
		        	absences.config.studentSelect.parent().nextAll(".add-edit").remove();
		        	absences.renderScanModeStatus("ready");
		        }
	    	},
	    	statusDefaultText: "Status: ",
	    	statusCodeDefaultText: "Attendance code: ",
	    	statusTypes: {
				ready: { statusText: "Ready", statusClass: "gray", iconClass: "icon-barcode" },
				success: { statusText: "Scanned", statusClass: "green", iconClass: "icon-ok green" },
				error: { statusText: "Error", statusClass: "red", iconClass: "icon-ban-circle red" }
			},
			delay: 2000
		},
		defaultAbsenceTypeId: null
	};

	absences.init = function (config) {
		if (!!config) {
			$.extend(true, this.config, config);
		}

		this.initView();
		this.initViewActions();
	};

	absences.initView = function () {
		var self = this;
		this.config.form = $("#main_form");
		this.config.studentSelect = $("select[name=\"student_id\"]");
		this.config.absenceTypeSelect = $("select[name=\"absence_type_id\"]");
		this.config.absenceTable = $("#absences-table");
        this.initDate();
        this.initScanMode();
		this.resetDefaultView();
	};

	absences.initViewActions = function () {
		var self = this;
		this.config.viewActions = $("#view-actions");
		this.config.sections.options = $("section.options");
		this.config.sections.options.on("tss.expandible.closed", function () {
            self.config.viewActions.hide();
		});
		this.config.sections.options.on("tss.expandible.open", function () {
            self.config.viewActions.show();
		});
		this.config.scan.button = $("#scan-btn");
		this.config.scan.button.on("click", _.debounce(function () {
			self.config.scan.dialog.dialog("open");
		}, 200));
	};

	absences.initDate = function () {
		var dateInput = $("#date-input"), dateStatus = $("#date-status");
		dateInput.on("change", _.debounce(this.handleDateChange, 500)).change();
	};

	absences.initScanMode = function () {
		var self = this;
		this.config.scan.dialog = $("#scan-mode-dialog");
		this.config.scan.icon = $("#scan-mode-icon");
		this.config.scan.status = $("#scan-mode-status");
		this.config.scan.code = $("#scan-mode-code");
		this.config.scan.input = $("#scan-mode-input");
		this.config.scan.message = $("#scan-mode-message").hide();
       	this.config.scan.dialog.dialog(this.config.scan.dialogOptions);
       	this.config.scan.dialog.on("click", function () {
       		self.config.scan.input.focus();
       	});
		this.config.scan.input.on("keypress", _.debounce(function () {
			var input = $(this), value = input.val().replace(/\s+/g, "");
			if (value) {
				self.config.scan.input.attr("disabled", true);
				self.handleScanModeValue(value, self.submitScanModeData);
			} else {
				input.val("");
			}
		}, 200));
		$(document).on("click", ".ui-widget-overlay", function () {
			self.config.scan.dialog.dialog("close");
		});
	};

	absences.handleScanModeValue = function (value, callback) {
		var self = this, barcodeType = this.config.scan.input.data("barcodeType"),
		lookup = $.post(this.config.scan.lookupPath + barcodeType + "/" + value);
		lookup.done(function (data) {
			if (data.success && data.results) {
				if ($.isFunction(callback)) {
					callback.call(self, data.results.student);
				}
			} else {
				self.renderScanModeStatus("error", "Student not found");
				_.delay(function () { self.resetScanMode() }, self.config.scan.delay);
			}
		});
	};

	absences.submitScanModeData = function (student) {
		var self = this, options = { url: this.config.scan.absencePath, data: this.formatScanModeData(student.student_id) }, hasMessage = this.config.scan.message.is(":visible");
		$.ajax(options).done(function (data) {
			if (data.success && data.results) {
				self.renderScanModeStatus("success", data.info);
				self.config.absenceTable.trigger("filtering.go");
				_.delay(function () {
					self.renderScanModeMessage("success", "Last updated " + student.display_name + " at " + moment().format("h:mma"));
				}, hasMessage ? 0 : self.config.scan.delay);
			} else {
				self.renderScanModeStatus("error", data.error);
			}
			_.delay(function () { self.resetScanMode(); }, self.config.scan.delay);
		});
	};

	absences.formatScanModeData = function (value) {
		var data = {}, formValues = this.config.form.serializeArray();
		$.each(formValues, function (i, formValue) {
			data[formValue.name] = formValue.value;
		});
		return $.extend(data, { student_id: value });
	};

	absences.renderScanModeStatus = function (type, code) {
		var type = this.config.scan.statusTypes[type],
		status = $("<span>").text(this.config.scan.statusDefaultText).append($("<span>", { text: type.statusText }).addClass(type.statusClass)),
		code = code ? $("<span>").addClass(type.statusClass).text(code) : this.returnScanModeStatusCodeDefault();
		this.config.scan.icon.removeClass().addClass(type.iconClass + " icon-big");
		this.config.scan.status.html(status);
		this.config.scan.code.html(code);
	};

	absences.returnScanModeStatusCodeDefault = function () {
		var code = this.config.absenceTypeSelect.find("option:selected").text(),
		html = $("<span>", { text: this.config.scan.statusCodeDefaultText }).append($("<span>", { text: code }).addClass("gray"));
		return html;
	};

	absences.renderScanModeMessage = function (type, message) {
		var type = this.config.scan.statusTypes[type], message = $("<span>", { text: message }).addClass(type.statusClass);
		this.config.scan.message.html(message).show();
	};

	absences.resetScanMode = function (removeMessage) {
		this.config.scan.input.attr("disabled", false).val("").focus();
		this.renderScanModeStatus("ready");
		if (removeMessage) {
			this.config.scan.message.empty().hide();
		}
	};

	absences.resetDefaultView = function () {
		$('.options.is-tss-expandable').removeClass('closed');
		this.config.absenceTypeSelect.val(this.config.defaultAbsenceTypeId).change();
	};

	absences.handleDateChange = function () {
		var schoolId = $(this).data("schoolId"), date = dateStringToServer($(this).val()),
		data = { absence_filter: "school_id=[\"" + schoolId + "\"]&date=[\"" + date + "\",\"" + date + "\"]&active=1" };
        $.post("/absences/data/", data).done(absences.handleStudentAbsenceCode);
        $.post("/date/desc/" + dateStringToServer(date)).done(absences.handleDateDescription);
	};

	absences.handleDateDescription = function (data) {
		var dateStatus = $("#date-status");
		if (data.results) {
			dateStatus.html(data.results.is_school_day ? "" : data.results.holiday_description);
        }
	};

	absences.handleStudentAbsenceCode = function (data) {
		var select = $("select[name=\"student_id\"]"), options = select.find("optgroup").first().find("option"), rows = select.parent().nextAll(".add-edit");
		options.each(function () {
			var option = $(this), studentId = option.val(), record = _.find(data.results.records, { student_id: studentId });
			absences.handleStudentAbsenceCodeUpdate(option, data, studentId);
		});
		rows.each(function () {
			var row = $(this), studentId = row.find("input[type=\"hidden\"]").val(), record = _.find(data.results.records, { student_id: studentId });
			absences.handleStudentAbsenceCodeUpdate(row.find(".btn-group .btn:first-child"), data, studentId);
		});
        select.trigger("refresh-options");
	};

	absences.handleStudentAbsenceCodeUpdate = function (element, data, studentId) {
		var record = _.find(data.results.records, { student_id: studentId }), text = element.text();
		element.text(text.replace(/\s*\[\w*\]/, "")).append(record ? " [" + data.results.absence_types[record.absence_type_id].code + "]" : "");
	};

})(jQuery, window);

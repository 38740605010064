/**
 * 
 * @author cmitchell
 */
(function($) {
    $.fn.tssClearableInput = function(options){
        //set default options
        var defaultOptions = {
            additionalClasses: 'control'
        };
        
        //merge with passed options
        options = $.extend(defaultOptions, (typeof options == 'object' ? options : {}));
        
        return this.each(function(){
            //get input into scoped variable
            var inputElement = $(this);
            
            //check if has already had this plugin applied or is not input
            if (inputElement.parent('.tss-clearable-input').length || !inputElement.is('input')) {
                return inputElement;
            }
            
            //wrap
            inputElement.wrap('<div class="tss-clearable-input ' + options.additionalClasses + '" />');
            
            //add clear icon
            var clearIcon = $('<i class="icon-remove" />');
            inputElement.after(clearIcon);
            
            //functions
            var clearInput = function() {
                inputElement.val('').trigger('change');
            }
            
            //bindings
            clearIcon.click(clearInput);
        });
    };
}(jQuery));
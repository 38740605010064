var TssForm = {

    forms: null,

    /**
     *
     */
    init: function() {
        TssForm.forms = $('.tss-form');
        TssForm.setBindings();
    },

    /**
     *
     */
    setBindings: function() {
        var that = this;

        $(document).on('click', '.inline-edit > i, .inline-edit > .label', this.toggleInlineEdit)
            .on('change', 'select, input, textarea', this.updateValue)
            .on('click', '[rel="toggle-tss-form-expand"]', this.toggleExpandableAreas)
            .on('click', '[rel="fetch-edit-object"]', this.initFetchAndPopulateForm)
            .on('click', '[rel="tss-form-begin"]', this.beginForm)
            .on('click', '[rel="remove-alert"]', function () {
                $(this).closest('.alert').fadeOut('fast', function() {
                    $(this).remove();
                });
            })
            .on('click', '[rel="remove-attachment"]', this.removeAttachment)
            .on('click', '[rel="remove-add-edit"]', this.removeAddEdit)
            .on('click', '[rel="expand-add-edit"]', this.expandAddEdit)
            .on('change', '[tss-add-edit]', this.handleAddEditChange)
            .on('click', '[rel="tss-toggle-filters"]', this.toggleFilters)
            .on('click', '[rel="tss-toggle-list-view"]', this.toggleListView)
            .on('click', '[rel="tss-object-edit-property"]', this.handlePropertyChange)
            .on('click', '[rel="tss-reset-form"]', function() {
                var form = $('form[name="' + $(this).data('form-name') + '"]');

                if(form.length) {
                    that.resetForm(form);
                }
            });

        Functions.setTwoWayBindings();
        this.handleHideableControls();
    },

    /**
     *
     */
    toggleInlineEdit: function() {
        $(this).closest('.inline-edit').toggleClass('edit-mode');
    },

    /**
     *
     */
    updateValue: function() {
        var el = $(this);

        //what kind of control?
        if(el.is('input')) {
            //tss switch or tss icon toggle
            if(el.hasClass('is-tss-switch')) {
                var value = el.siblings('.selected').text();
            }
        }

        //set value
        el.closest('.inline-edit').find('.value').text(value);
    },

    /**
     *
     */
    toggleExpandableAreas: function(e) {
        var el = $(this),
            icon = $(this).children('i'),
            tssForm = el.closest('.tss-form'),
            form = tssForm.closest('form'),
            title = el.attr('title') ? el.attr('title') : (el.data('title') ? el.data('title') : ''),
            toggleTitle = el.data('toggle-title');

        if(tssForm.length) {
            TssForm.resetForm(form);
            $(form).find('[name="student_id"][tss-add-edit-disabled]').removeAttr('tss-add-edit-disabled').attr('tss-add-edit', true).change();
            tssForm.find('.expandable').fadeToggle();
            $('#view-actions.expandable').fadeToggle();
            TssForm.handleHideableControls();
            el.removeAttr('title');

            var btnToggleClass = el.data('toggle-class');
            if(btnToggleClass) {
                el.data('toggle-class', el.attr('class'));
                el.attr('class', btnToggleClass);
            }

            if(title) {
                el.data('toggle-title', title);
                el.data('title', toggleTitle);
            }

            if(icon.length) {
                var iconToggleClass = icon.data('toggle-class');
                if(iconToggleClass) {
                    icon.data('toggle-class', icon.attr('class'));
                    icon.attr('class', iconToggleClass);
                }
            }

            //clear alerts
            Tss.Alerts.removeAllFormAlerts(tssForm);
        }
    },

    /**
     *
     */
    handleHideableControls: function() {
        $('.hideable-control').each(function() {
            var el = $(this),
                button = el.find('.btn'),
                label = el.find('label'),
                control = el.find('.control'),
                topMargin = label ? label.outerHeight() : 0;

            //don't hide if it has a value
            if(control.val().length) {
                el.children().show();
                button.hide();
                return;
            }

            //hide all but button
            el.children().hide();
            button.show();

            //set top margin
            if(el.hasClass('respect-top-margin')) {
                button.css({'margin-top': topMargin + 'px'});
            }

            //bindings
            button.off('click').on('click', function() {
                el.children().show();
                $(this).hide();

                //first try control
                var val = control.data('value');
                //next try chilren in case input was wrapped such as tss-clearable-input
                if(!val) {
                    val = control.find('input, select').eq(0).data('value');
                }

                control.val(val ? val : '');
            });
        });
    },

    addHumanizedFieldName: function(fieldName, message) {
        return Functions.humanizeFieldName(fieldName) + ': ' + message.toLowerCase();
    },

    initFetchAndPopulateForm: function() {
        var el = $(this),
            formName = el.data('form-name'),
            form = $('form[name="' + formName + '"]'),
            className = el.data('class'),
            objectId = el.data('object-id'),
            url = '/obj/' + className + '/' + objectId;

        //overlay!
        if(!formName || !form.length) {
            edit(null, null, el.data('modal-url'));
            return;
        }

        TssForm.fetchAndPopulateForm(form, url);
    },


    fetchAndPopulateForm: function(form, url) {
        $.getJSON(url, function(data) {
            if(!data.errors.length && data.results.object) {
                TssForm.populateFormFromJson(data.results, form);
            }
        });
    },

    populateFormFromJson: function(results, form, noScroll) {
        var object = results.object || results.obj;

        //clear alerts
        Tss.Alerts.removeAllFormAlerts(form);

        //see if form is expanded
        //if not, expand
        this.expandExpandables(form);

        //clear add edits
        this.removeAllAddEdits(form);

        //disable add edit
        $(form).find('[name="student_id"][tss-add-edit]').removeAttr('tss-add-edit').attr('tss-add-edit-disabled', true);

        //clear all attachments
        this.removeAllAttachments(form);

        //add change log icon
        if(results.change_log) {
            this.addChangeLogIcon(form, results.change_log);
        }

        //append attachments
        if(results.attachments) {
            this.addAttachments(results.attachments, form);
        }

        //fill in form
        $.each(object, function(k, v) {
            fillInForm(k, v, form);
        });

        //scroll
        if(noScroll !== false) {
            $.scrollTo(form, 500, {offset: -100});
        }

        //add edit class to form
        form.addClass('edit-mode');

        //handle hideable controls
        this.handleHideableControls();

        $('body').trigger('loaded');
    },

    expandExpandables: function(form) {
        var $expandables = $('.expandable', form);
        if($expandables.length && !$expandables.first().is(':visible')) {
            $('[rel="toggle-tss-form-expand"]', form).first().click();
        }

        //new expandibles
        $expandables = $('.is-tss-expandable', form);
        $expandables.removeClass('closed');
    },

    collapseExpandables: function(form) {
        var $expandables = $('.expandable', form);
        if($expandables.length && $expandables.first().is(':visible')) {
            $('[rel="toggle-tss-form-expand"]', form).first().click();
            $(form).find('[name="student_id"][tss-add-edit-disabled]').removeAttr('tss-add-edit-disabled').attr('tss-add-edit', true).change();
        }

        //new expandibles
        $expandables = $('.is-tss-expandable', form);
        $expandables.addClass('closed');
    },

    handleInPlaceEdit: function(results) {
        var tssForm = $('.tss-form[data-class="' + String(results.class).toLowerCase() + '"]'),
            form = tssForm.closest('form'),
            idField = String(results.class).toLowerCase() + '_id',
            idFormFieldVal = tssForm.find('[name="' + idField + '"]').val(),
            object = results.obj || results.object;

        if(tssForm.length && form.length && idField.length && idFormFieldVal == results.obj[idField]) {
            this.populateFormFromJson(results, form, false);
        }
    },

    beginForm: function(e) {
        e.preventDefault();

        var el = $(this),
            form = $('form[name="' + el.data('form-name') + '"]'),
            object = el.data('fill-ins');

        //overlay!
        if(!form.length) {
            Overlay.appendOverlay({});
            return;
        }

        //handle any fill-ins
        TssForm.populateFormFromJson({'object': object}, form);
    },

    resetForm: function(form) {
        form.find('[data-reset-to]').each(function() {
            $(this).val($(this).data('reset-to')).change();
        });

        form.find('input, select, textarea')
            .not('.hjsel_txtbox,[tss-no-reset],[data-reset-to], [tss-reset-on-update]')
            .each(function() {
                $(this).val('').prop('checked', false).prop('selected', false).change();
        });

        //remove attachments
        this.removeAllAttachments(form);

        //remove change log icon
        this.removeChangeLogIcon(form);
    },

    addAttachment: function(object, target) {
        var attachmentContainer = $(Handlebars.renderTemplate('tss-form-attachment', object));
        target.find('.extras').append(attachmentContainer);
        attachmentContainer.find('input').val(JSON.stringify(object));
    },

    addAttachments: function(attachments, form) {
        var that = this;
        _.each(attachments, function(attachment) {
            if(attachment.active == 1) {
                that.addAttachment(attachment, form);
            }
        });
    },

    addChangeLogIcon: function(form, url) {
        //remove change log icon first
        this.removeChangeLogIcon(form);

        form.find('.allowed-actions').prepend('<i class="icon-time" href="' + url + '" tooltip></i>');
    },

    removeChangeLogIcon: function(form) {
        form.find('.allowed-actions i.icon-time').remove();
    },

    removeAttachment: function() {
        if($(this).hasClass('btn-red') && !confirm('Are you sure you want to remove this attachment?')) { return; }

        var el = $(this),
            id = el.data('id'),
            attachmentClass = el.data('class');

        el.closest('.row.attachment').fadeOut('fast', function() {
            $(this).remove();
        });

        if(id && String(id).length && attachmentClass && attachmentClass.length) {
            $.post('/activate/?type=' + attachmentClass + '&active=0&id=' + id);
        }
    },

    removeAllAttachments: function(form) {
        form.find('.row.attachment').each(this.removeAttachment);
    },

    getAttachmentClassFromObject: function(object) {
        var objectClass;

        _.each(_.keys(object), function(key) {
            if(key.indexOf('attachment_id') !== -1) {
                objectClass = _.first(key.split('_'));
            }
        });

        return objectClass;
    },

    removeAddEdit: function() {
        var that = $(this);
        that.closest('.row.add-edit').fadeOut('fast', function() {
            var fieldName = that.data('field');
            $(this).remove();
            $('[name="' + fieldName + '"]').trigger('change');
        });
    },

    resetAddEdit: function() {
        var $this = $(this),
            fieldName = $this.attr('name'),
            addEditValues = $('[data-field="' + fieldName + '"]');

        addEditValues.each(function() {
            $(this).closest('.row.add-edit').remove();
        });

        $this.trigger('change');
    },

    expandAddEdit: function() {
        var el = $(this),
            row = el.closest('.row.add-edit'),
            id = el.data('student-group-id');

        if(!TssForm.isStudentGroupId(id)) { return; }

        var expandedRows = '';
        $.getJSON('/studentgroup/members/' + id, function(data) {
            var templateData = data.map(function(student) {
                return {
                    value: student.student_id,
                    display_name: student.display_name,
                    field_name: 'student_id'
                }
            });
            row.replaceWith(Handlebars.renderTemplate('tss-form-add-edit', templateData));
        });
    },

    removeAllAddEdits: function(form) {
        form.find('.row.add-edit').each(this.removeAddEdit);
    },

    handleAddEditChange: function(e) {
        var el = $(this),
            templateData = {
                value: el.val(),
                display_name: el.is('select') ? el.find('option[value="' + this.value + '"]').html() : this.value
            };

        if(!templateData.value.length) { return; }

        //reset the field and trigger change event
        el.val('').trigger('change');

        //render and prepend template
        templateData.field_name = el.attr('name');
        el.closest('.col').append(Handlebars.renderTemplate('tss-form-add-edit', [templateData]));
    },

    toggleFilters: function() {
        $(this).closest('.tss-filters').toggleClass('minimized');
    },

    toggleListView: function() {
        $(this).closest('.filtering-container').find('.table-column').toggle();
    },

    handlePropertyChange: function(e) {
        var el = $(this),
            data = {
                class: el.data('class'),
                id: el.data('id'),
                with_related: el.data('with-related')
            };
        data[el.data('property')] = el.data('value');

        $.post('/obj/edit/', data, handleInPlaceEdit, "json")
            .fail(function(jqXHR, textStatus, errorThrown) {
                if (jqXHR.responseJSON) {
                    displayNotifications(jqXHR.responseJSON);
                } else {
                    Growl.error({ message: "Something went wrong." });
                }
            });

        return true;
    },

    isStudentGroupId: function(studentGroupId) {
        return _.startsWith(studentGroupId, 'g_');
    },

    handleUpdateResponse: function(data, form, noReset) {
        if(data.success) {
            this.collapseExpandables(form);
            if(!noReset) {
                this.resetForm(form);
                this.handleUpdateResets(form);
            }
        }
        Tss.Alerts.handleFormAlerts(data, form);

        //reload the filtered list to show newly added....
        Functions.executeCallback(window.filterPage || function(){}, data);
    },

    handleUpdateResets: function(form) {
        var that = this;
        form.find('[tss-reset-on-update]').each(function() {
            var $this = $(this);

            if($this.is('[tss-add-edit]')) {
                that.resetAddEdit.apply(this);
            }
        });

    }
};

$(TssForm.init);

window.TssForm = TssForm;
import _ from 'tss/lodash'
import axios from 'axios'
import buildUrl from 'axios/lib/helpers/buildURL'

let api = axios.create()

api.buildUrl = buildUrl

api.post = async function(url, params, options = {}) {
    const response = await axios.post(url, params, options)

    return _.get(response, 'data')
}

api.put = async function(url, params, options = {}) {
    const response = await axios.put(url, params, options)

    return _.get(response, 'data')
}

api.delete = async function(url, params, options = {}) {
    const response = await axios.delete(url, params, options)

    return _.get(response, 'data')
}

// handle paginated APIs
api.get = async function(url, params, options = {}) {
    let pagedUrl = url
    let hasPageOrLimitParam = _.get(params, 'page') || _.get(params, 'limit')
    let rawResponse = await axios.get(url, _.extend({ params }, options))
    let response = _.get(rawResponse, 'data') || {}
    let hasMeta = !!Object.keys(response.meta || {}).length
    let page = hasMeta ? parseInt(response.meta.page, 10) : 0
    let totalPages = hasMeta ? parseInt(response.meta.total_pages, 10) : 0
    let resultsKeys = Object.keys(response.results || {})

    // either: error, or single ID, or non-standard API, or we asked for a particular page
    if (hasPageOrLimitParam || !hasMeta || !page || !totalPages || page >= totalPages || !resultsKeys.length) {
        return response
    }

    let resultsKey = resultsKeys[0] // FIXME return this in meta?
    let records = _.get(response, `results.${resultsKey}`) || []
    let separator = url.indexOf('?') >= 0 ? '&' : '?'

    for (let i = page + 1; i <= totalPages; i++) {
        let pagedUrl = url + separator + 'page=' + i
        let pagedResponse = await axios.get(pagedUrl, { params })
        let pagedRecords = _.get(pagedResponse, `data.results.${resultsKey}`) || []

        records = _.concat(records, pagedRecords)
    }

    if (response && resultsKey) {
        response.meta.page = 1
        response.meta.total_pages = 1
        response.meta.records = response.meta.total_records
        response.meta.limit = response.meta.total_records
        response.results[resultsKey] = records
    }

    return response
}

api.all = axios.all
api.spread = axios.spread

export default api

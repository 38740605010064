(function($, window) { "use strict";
    var Filtering = function() {

        var defaultOptions = {
                requireFilters: false,
                pagerElement: false,
                alwaysFilter: false,
                exportTitle: 'Export',
                emptyStateTitle: 'Your filters returned no records',
                emptyStateDetails: 'Try removing some filters or adding new records!',
                useFilterKey: true,
                resultsKey: 'records',

            },
            $table,
            options = {},
            cache = null;

        function go() {
            var filterStr = getSerializedFilters(),
                filterKey = options.container.data('filter-key') || 'filter',
                postData = {},
                studentFilter = getStudentFilter(),
                shouldFilter = (filterStr || studentFilter.length);

            if (!shouldFilter) { return; }

//            $('input[name="filter"]').val(filterStr);

//            postData.known_ids = Filtering.getAllRows(table).map(function() { return rowIdToId($(this)); }).get().join(',');
            if (options.useFilterKey) {
                postData[filterKey] = filterStr;
                postData['student_filter'] = studentFilter;
            } else {
                postData = getFilters(options.container);
            }

            // show loader by default
            if (!$table.nextAll('.loading-state').length) {
                var loadingState = Handlebars.renderTemplate('spinner');
                $table.after(loadingState);
            }
            $table.nextAll('.loading-state').show();
            $table.hide();
            $table.nextAll('.empty-state').hide();
            $table.prevAll('.tablesorter-pager').hide();

            var url = options.url;
            $.post(url, postData, handleResponse);
        }

        function getStudentFilter() {
            var $studentInput = $(options.studentInput),
                studentIds = [],
                studentGroupIds = [],
                gradeLevelNames = [],
                studentFilters = [];

            $studentInput.each(function() {
                var ids = $(this).val(),
                    isGroup = $(this).attr('name').match(/student_group_id$/),
                    isNumber = function(v) { return !isNaN(v); };

                if (ids) {
                    _.each(_.filter(_.isArray(ids) ? ids : [ids]), function(id) {
                        var val = id;

                        if (isGroup || (id.startsWith('g_') && isNumber(val = id.replace('g_', '')))) {
                            studentGroupIds.push(val);
                        } else if (isNumber(id)) {
                            studentIds.push(id);
                        } else if (id !== 'all') {
                            gradeLevelNames.push(val);
                        }
                    });
                }
            });

            if (studentIds.length) {
                studentFilters.push("student_id=" + JSON.stringify(studentIds));
            }

            if (studentGroupIds.length) {
                studentFilters.push("active=1");
                studentFilters.push("student_group_id=" + JSON.stringify(studentGroupIds));
            }

            if (gradeLevelNames.length) {
                studentFilters.push("active=1");
                studentFilters.push("grade_level_name=" + JSON.stringify(gradeLevelNames));
            }

            return studentFilters.join('&');
        }

        function handleResponse(data) {
            var hasResults = !_.isEmpty(_.get(data, `results.${options.resultsKey}`, [])),
                showEmptyState = !hasResults && (options.emptyStateTitle || options.emptyStateDetails);

            cache = _.clone(data, true); // this used to be a cloneDeep--do we need it to still be? do we need to clone at all?

            // show empty state by default
            if (showEmptyState && !$table.nextAll('.empty-state').length) {
                var emptyState = Handlebars.renderTemplate('empty-state', {
                    title: options.emptyStateTitle,
                    details: options.emptyStateDetails
                });
                $table.after(emptyState);
            }
            $table.nextAll('.empty-state')[showEmptyState ? 'show' : 'hide']();
            $table[!showEmptyState ? 'show' : 'hide']();
            $table.nextAll('.loading-state').hide();

            renderResults(data);
            renderPager(data, false);
            setTimeout(function() {
                generateSummary();
                if (options.responseCallback) {
                    Functions.executeCallback(options.responseCallback, data);
                }
            }, 10);
        }

        function renderResults(data) {
            if(!data.results || !data.results[options.resultsKey]) { return; }

            var rows = [],
                templateData = _.clone(data.results, true),
                records = data.results[options.resultsKey],
                row = null;

            _.each(records, function(record) {
                templateData.record = record;
                row = renderRow(templateData);
                rows.push(row);
            });

            $table.find('tbody').html(rows.join('')).trigger('update').trigger('pageMoved');
            Bindings.setupUIBindings();
            if (options.renderEvent) {
                $(document).trigger(options.renderEvent)
            }
        }

        function renderRow(data) {
            // Add session school id
            // Works for things like hiding edit links for records not from current session school
            data.sessionSchoolId = options.sessionSchoolId;
            data.printable = options.printable;

            return Handlebars.renderTemplate(options.template, data);
        }

        function renderPager(data, force) {
            if(options.hidePager) { return; }

            if(!force && (!options.pagerElement.length || !data.results || !data.results[options.resultsKey] || !data.results[options.resultsKey].length)) {
                options.pagerElement.hide();
                return;
            }

            options.pagerElement.show();
            var config = $table.get(0).config,
                sortList = config ? config.sortList : null;

            $table.tablesorter().tablesorterPager({
                container: options.pagerElement,
                output: '{page} of {totalPages}',
                size: $('select.pagesize', options.pagerElement).val(),
                page: config ? config.page : 0
            });

            //reorder to before we updated
            if(sortList) {
                $table.trigger('sorton', [sortList]);
            }
        }

        function handleInlineEdit(e, rowId, results) {
            var fakeTable = $('<table/>').append(getAllRows($table)),
                templateData = results ? results : _.clone(cache.results, true);

            _.each(templateData[options.resultsKey], function(record) {
                templateData.record = record;
                let row = renderRow(templateData);
                if (rowId == $(row).attr('id')) {
                    fakeTable.find('#' + rowId).replaceWith(row);
                }
            });

            var rows = fakeTable.find('> tbody > tr');

            $table.find('> tbody').html(rows).trigger('update').trigger('pageMoved');
            renderPager(null, true);
            Bindings.setupUIBindings();
        }

        function getSerializedFilters() {
            var $filterContainer = options.container.find('.tss-filters'),
                filters = getFilters($filterContainer),
                filterState = getFilterState(filters);

            //figure out if we have any worthwhile filters
            if (options.requireFilters) {
                var numFilters = _.keys(filters).length;

                if(filters['school_id']) {
                    numFilters--;
                }
                if(!_.isUndefined(filters['active']) && filters['active'] == '1') {
                    numFilters--;
                }

                //if not, return false
                if(numFilters < 1) { return false; }
            }

            return filterState.join('&');
        }

        function getRemoteDownloadMethod(url, extraParams, httpMethod) {
            return function(e) {
                //get array of empty objects keyed by object id
                var dataArray = getGenericDownloadIds(),
                    method = httpMethod || 'post';

                //create temp form
                var $tempform = $('body').append(
                    "<form name='tempform' id='tempform' action='" + url + "' method='" + method + "'>"
                        + "</form>").find('#tempform');

                //add hidden input to form with data
                if (dataArray) {
                    $tempform.append("<input type='hidden' name='data'>").find('input[name="data"]').val(JSON.stringify(dataArray));
                }

                //extras
                $.each(extraParams || {}, function(k, v) {
                    $tempform.append("<input type='hidden' name='" + k + "'>").find('input[name="' + k + '"]').val(v);
                });

                $tempform.submit().remove();
                pauseOnBeforeUnload();
                return stopPropagation(e);
            };
        }

        function getGenericDownloadIds() {
            var trs = getAllRows($table),
                rows = {};

            trs.each(function() {
                rows[rowIdToId($(this))] = {};
            });

            return {'rows': rows};
        }

        function generateSummary() {
            var ths = $table.find('th.filterSummary'),
                summaryTable = options.container.find('.summary').find('table'),
                numResultsContainer = options.container.find('.summary').find('.numResults'),
                valueFilter = summaryTable.data('filter_summary_value'),
                hasCols = summaryTable.data('has_cols'),
                rows = getAllRows($table),
                hasVisibleRows = $table.find('tbody > tr').length; //getAllRows uses cache which isn't accurate when zero

            if (!hasCols) {
                summaryTable.empty();
            } else {
                summaryTable.find('td:nth-child(2)').empty(); // remove the values, not the headers
            }

            if(!hasVisibleRows) {
                numResultsContainer.text('0');
                return;
            }

            //set total
            numResultsContainer.text(Tss.Number.toCommas(rows.length, 0));

            ths.each(function(j) {
                var th = $(this),
                    totals = {},
                    index = th.index() + 1;

                rows.children('td:nth-child(' + index + ')').each(function() {
                    var td = $(this),
                        tr = td.parent(),
                        keys = td.data('filter_summary_values'),
                        key,
                        i;

                    if (!keys) {
                        key = _.trim(td.text());
                        if(!_.isEmpty(key)) {
                            totals[key] = ((totals[key] || 0) + 1);
                        }
                    } else if ($.isArray(keys)) {
                        for (i = 0; i < keys.length; i += 2) {
                            if (valueFilter && valueFilter != keys[i]) continue; // only want a sub-set of the values

                            key = keys[i + 1];
                            if (_.isObject(key)) {
                                $.each(key, function(k, v) {
                                    totals[k] = ((totals[k] || 0) + parseInt(v));
                                });
                            } else {
                                totals[key] = ((totals[key] || 0) + 1);
                            }
                        }
                    } else if (_.isObject(keys)){
                        $.each(keys, function(key, val) {
                            totals[key] = ((totals[key] || 0) + parseFloat(val));
                        });
                    } else {
                        if(!_.isEmpty(keys)) {
                            totals[keys] = ((totals[keys] || 0) + 1);
                        }
                    }
                });

                var orderedKeys = Object.keys(totals);

                orderedKeys.sort();

                $.each(orderedKeys, function(i, key) {
                    if (hasCols) {
                        var elem = summaryTable.find('#' + key);

                        elem.html(formatByClass(totals[key], elem));
                    } else {
                        summaryTable.append('<tr' + (i == orderedKeys.length - 1 && j != ths.length - 1 ? ' class="sectionEnd"' : '')
                            + '><td class="label">' + key + ':</td><td class="text-right">' + Tss.Number.toCommas(totals[key], 0) + '</td></tr>');
                    }
                });
            });

            summaryTable.trigger('summary.generated', cache.results);
        }

        function setupDownloads() {
            //iterate through all download links and set bindings based on attrs
            $('[rel="download-export"]', options.container).each(function() {
                var el = $(this),
                    type = el.data('type'),
                    url = el.data('url'),
                    includeFilters = el.data('include-filters'),
                    extraParams = {};

                if (includeFilters) {
                    extraParams.filters = getSerializedFilters();
                }

                switch(type) {
                    case 'generic':
                        el.click(getRemoteDownloadMethod(url, extraParams));
                        break;
                    case 'table':
                        el.click(function(e) {
                            var dataArray = [headerRowsToValues($table)].concat(rowsToValues(getAllRows($table)));
                            return genericExport(e, $(this).data('title'), dataArray);
                        });
                };
            });
        }

        function setBindings() {
            $table.on('filtering.go', go);
            $table.on('filtering.handleInlineEdit', handleInlineEdit);
        }

        return {

            init: function($t, opts) {
                $table = $t;
                options = _.merge(defaultOptions, opts);

                // Add class
                options.container.addClass('filtering-container');
                $table.addClass('filtering-init');

                // Hide pager
                if(options.pagerElement) {
                    options.pagerElement.hide();
                }

                // Setup downloads and bindings
                setupDownloads();
                setBindings();
            },

            getDebounceFilterMethod: function(method) {
                return _.debounce(method, 1000);
            },

            getOptions: function() {
                return options;
            },

            getRemoteDownloadMethod: function(url, extraParams, httpMethod) {
                return getRemoteDownloadMethod(url, extraParams, httpMethod);
            }

        };

    };

    window.Tss = window.Tss || {};
    window.Tss.Filtering = Filtering;

})(jQuery, window);

/**
 * 
 * @author cmitchell
 */
(function($) {
    $.fn.tssLoad = function(url, params, callback, emptyCallback) {
        var type,
            targetElement = this,
            requestMap = targetElement.data(),
            cb = callback,
            isEmptyCallback = emptyCallback,
            key = url.split('?')[0];
        
        if (!$(targetElement).length) return;
        
        if ($.isFunction(params)) {
            cb = params;
            params = undefined;
        } else if (params && typeof params === 'object') {
            type = "POST";
        }
 
        if (requestMap[key]) {
            requestMap[key].abort();
        }

        var spinner = Handlebars.renderTemplate('spinner');
        $(targetElement).html(spinner);
        requestMap[key] = $.ajax({
            url: url,
            dataType: 'html',
            type: type,
            success: function(responseText, textStatus, jqXHR) {
                delete requestMap[key];

                // apply no results found empty state if the results are empty
                // or deemed 'empty' by the callback.
                if (!responseText || (isEmptyCallback && isEmptyCallback(responseText))) {
                    var noResultsTemplate = targetElement.data('no-results');
                    if (noResultsTemplate) {
                        responseText = Handlebars.renderTemplate(noResultsTemplate, {
                            title: targetElement.data('no-results-title') || 'No results found',
                            details: targetElement.data('no-results-details')
                        });
                    }
                }

                $(targetElement).html(responseText).trigger('loaded');
                if (cb && typeof cb === 'function') {
                    cb.apply(targetElement, [textStatus, jqXHR]);
                }
            }
         });
    };
}(jQuery));
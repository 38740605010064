(function($, window) { "use strict";
    var BehaviorTypeGroups = {
        initAddEdit: function(action, id) {
            var self = this;

            self.form = $('#main-form');
            Tss.Types.initAddEdit("BehaviorTypeGroup", "name", action, id, {
                extraApiParams: {
                    expand: 'demerit_types',
                },
                submitCallback: _.bind(self.submitCallback, self),
            });
            self.defaultSuccessUrl = self.form.data('referrer') || "/setup/configure/behavior-type-groups";
            
            self.form.on('FillInForm', _.bind(self.fillInBehaviorTypes, self));
            self.form.on('click', '.manage-behavior-types', _.bind(self.saveAndManageBehaviorTypes, self));
            self.form.on('submitComplete', _.bind(self.popSuccessUrl, self));
        },

        /**
         * If they clicked Manage Behavior Types, stash the desired URL to go to,
         * then save, then redirect to that URL on success.
         * 
         * @param  Object e event
         * @return false to prevent the default behavior of clicking a link
         */
        saveAndManageBehaviorTypes: function(e) {
            var self = this;
            var name = self.form.find('[name="name"]').val();
            var successUrl = $(e.currentTarget).attr('href') + encodeURIComponent(name);

            stopPropagation(e);

            self.pushSuccessUrl(successUrl);
            self.form.trigger('submit');

            return false;
        },

        pushSuccessUrl: function(successUrl) {
            var self = this;

            self.tempSuccessUrl = successUrl; // push
        },

        popSuccessUrl: function(demeritTypeId) {
            var self = this;

            self.tempSuccessUrl = undefined; // pop
        },

        /**
         * Go to manage behavior types if that's what they clicked, otherwise
         * go wherever you would normally go.
         */
        submitCallback: function() {
            var self = this;
            var successUrl = self.tempSuccessUrl || self.defaultSuccessUrl;

            window.location.assign(successUrl);
        },

        /**
         * Display all behavior types in this group and add some menu options for
         * modifying them.
         * 
         * @param  Object e      event
         * @param  Object type   BehaviorTypeGroup returned by the API
         */
        fillInBehaviorTypes: function(e, type) {
            var self = this;
            var demeritTypes = _.get(type, 'demerit_types');
            var content = '<a class="menu-action manage-behavior-types" href="/setup/configure/behavior-types?filter="><i class="icon-pencil"></i>Manage Behavior Types</a>';
            var links = _.chain(demeritTypes)
                .sortBy(['order_key'])
                .map(function(t) {
                    return '<a'
                        + ' href="/setup/configure/behavior-types/edit/' + t.demerit_type_id + '"'
                        + ' class="' + (t.active == 1 ? '' : 'deactivated') + '">'
                        + t.name
                        + '</a>';
                })
                .join(', ')
                .value();

            self.form.find('[name="demerit_types"]')
                .html(links)
                .before('<div class="options" style="float: right"></div>');
            self.form.find('.options').tssDropdown({
                content,
                icon: 'icon-cog',
                right: true
            });
        },

        initList: function(options) {
            var self = this;

            self.options = options;

            Tss.Types.initList("BehaviorTypeGroup", "name", true, {
                extraApiParams: {
                    expand: 'demerit_types',
                    school_ids: options.schoolId,
                    system: 0, // only non-system behavior groups
                }
            });
            $('#types-table').on("click", "[rel=\"deactivate-all\"]", _.bind(self.deactivateAll, self));
        },

        deactivateAll: function(e) {
            var link = $(e.currentTarget);
            var row = link.closest('tr');
            var menuAction = link.closest('.menu-action');
            var demeritTypeIds = _.filter((menuAction.data('demerit_type_ids') + '').split(','));
            var demeritTypeNames = menuAction.data('demerit_type_names');
            var bulkData = _.map(demeritTypeIds, function(id) {
                return {
                    demerit_type_id: id,
                    active: 0,
                }
            });

            if (!demeritTypeIds.length) {
                alert("There are no active behavior types in this group. Nothing to do.");
                return;
            }

            if (!confirm("This will deactivate the following behavior types: " + demeritTypeNames + ".\n\nAre you sure?")) {
                return;
            }

            $.ajax({
                url: '/api/v1/bulk/demerit-types',
                type: 'PUT',
                data: {
                    bulk_data: bulkData,
                },
                success: function(data) {
                    if (data && data.length && data[0].success) {
                        row.find('td.demerit-types').html(''); // remove links
                        menuAction.data('demerit_type_ids', ''); // don't let them do it twice
                        menuAction.data('demerit_type_names', '');
                    }
                }   
            });
        }
    };

    window.Tss = window.Tss || {};
    window.Tss.BehaviorTypeGroups = BehaviorTypeGroups;

})(jQuery, window);

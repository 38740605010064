(function() {
    'use strict';

    var UndoService = {
        callbacks: {},

        init: function() {
            $('body').on('click', '.undo a', UndoService._undoClickHandler)
        },

        addClickHandler: function(className, callback) {
            this.callbacks[className] = callback;
        },

        getCallback: function(className) {
            return UndoService.callbacks[className];
        },

        _undoClickHandler: function(e) {
            stopPropagation(e);

            var url = '/changelog/undo/' + $(this).data('change_log_id')
                    + '?show_student=' + $('input[name="show_student"]').val()
                    + '&redirect=' + (isInIFrame() || $(this).closest('.ajaxtooltip').length ? 0 : 1);
            var data = $(this).data();
            var onAfterFunc = UndoService.getCallback(data.class) || function() {};

            return $.post(url, handlePost)
                .done(_.partial(onAfterFunc, data))
                .fail(function(jqXHR, textStatus, errorThrown) {
                    if (jqXHR.responseJSON) {
                        displayNotifications(jqXHR.responseJSON);
                    } else {
                        Growl.error({ message: "Something went wrong." });
                    }
                });
        },
    }

    $(document).ready(function() {
        window.UndoService = UndoService;
        UndoService.init();
    })
}());
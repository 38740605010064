/**
 * 
 * @author cmitchell
 */
(function($) {
    $.fn.tssMoodSelect = function() {
        return this.each(function(){
            var el = $(this);
            if(!el.is('select') || el.hasClass('is-tss-mood-select')) { return el; }
            
            var list = $('<ul class="tss-mood-select" />'),
                moodOptions = el.find('option'),
                listItems = null;
            
            //add class and hide select
            el.addClass('is-tss-mood-select').hide();
            
            //build list and add moodOptions to DOM
            buildMoodList();
            el.after(list);
            
            //prepare options list
            prepareOptionsList();
                
            function buildMoodList() {
                list.empty();
                moodOptions.each(function() {
                    list.append('<li><i class="' + $(this).data('icon-class') + '" data-mood-id="' + $(this).attr('value') + '"></i></li>')
                });
                listItems = list.find('li');
            }
            
            function prepareOptionsList() {
                if($(moodOptions).find('[value=""]').length) { return; }
                
                el.prepend('<option value="" selected />');
            }
            
            function handleChange() {
                var val = $(this).val();
                listItems.removeClass('selected');
                listItems.find('i[data-mood-id="' + val + '"]').parent().addClass('selected');
            }
            
            function handleClick(e) {
                e.preventDefault();
                if($(this).parent().hasClass('selected')) {
                    el.val('').change();
                } else {
                    el.val($(this).data('mood-id')).change();
                }
            }
            
            //bindings
            el.change(handleChange).change();
            listItems.find('i').click(handleClick);
            
        });
    };
}(jQuery));
(function ($, window, undefined) {
    "use strict";

    var Types = {

        // Config
        config: {},
        defaultConfig: {
            api: "/api/v1/",
            permissionsApi: "/api/v1/me?expand=permissions",
            pager: {
                output: "{page} of {filteredPages}"
            },
            sortable: {
                helper: function (event, ui) {
                    ui.children().each(function () {
                        $(this).width($(this).width());
                        $(this).height($(this).height());
                    });
                    return ui;
                },
                // fix a jQuery UI bug where the top doesn't get set properly
                // if a parent element has position: relative on it.
                // See: https://stackoverflow.com/questions/2451528/jquery-ui-sortable-scroll-helper-element-offset-firefox-issue/20225012#20225012
                sort: function (event, ui) {
                    var $target = $(event.target);
                    if (!/html|body/i.test($target.offsetParent()[0].tagName)) {
                        var top = event.pageY - $target.offsetParent().offset().top - (ui.helper.outerHeight(true) / 2);
                        ui.helper.css({ 'top': top + 'px' });
                    }
                },
                items: "> .draggable"
            },
            table: {
                widgets: ["zebra", "filter"],
                widgetOptions: {
                    filter_external: '.tss-search input',
                    filter_columnFilters: false,
                    filter_saveFilters: false
                }
            },
            templatePath: "setup/configure/",
            templates: {
                loadingState: 'spinner',
                emptyState: 'empty-state',
            },
            nameKey: "name",
            extraApiParams: {},
            extraTemplateParams: {},
        },

        /**
         * Get all types
         */
        getAll: function () {
            var self = this;
            var params = $.extend(
                {
                    school_ids: Tss.Env.get('school_id'),
                    with_related: 1,
                },
                this.config.extraApiParams
            );

            self.$mainContent = $('.mainBody section');
            self.$loadingState = self.$mainContent.parent().find(`[template="${self.config.templates.loadingState}"]`);
            self.$emptyState = self.$mainContent.parent().find(`[template="${self.config.templates.emptyState}"]`);

            if (!self.$loadingState.length) {
                self.$loadingState = $(Handlebars.renderTemplate(self.config.templates.loadingState))
                    .attr('template', self.config.templates.loadingState);

                self.$mainContent.first().before(self.$loadingState);
            }

            if (!self.$emptyState.length) {
                var hasAddButton = self.$mainContent.parent()
                    .find('button, .btn')
                    .filter(':visible')
                    .filter((i, elem) => $(elem).text().includes('Add'))
                    .length;
                var emptyStateArgs = {
                    title: 'No records found',
                    details: hasAddButton
                        ? 'Use the Add button above to create one!'
                        : '',
                };

                self.$emptyState = $(Handlebars.renderTemplate(self.config.templates.emptyState, emptyStateArgs))
                    .attr('template', self.config.templates.emptyState);
                self.$mainContent.first().before(self.$emptyState.hide());
            }

            self.$mainContent.hide();
            self.$emptyState.hide();
            self.$toggleShowInactive.hide();
            self.$loadingState.show();

            Promise.all([
                Api.get(this.config.api + this.config.type.endpoint, params),
                Api.get(this.config.permissionsApi)
            ])
                .then(responses => this.handleGetAllSuccess(responses[0], responses[1]))
                .catch(this.handleGetAllError)
                .finally(function () {
                    if (self.unloaded) {
                        return;
                    }

                    if (self.$table.find("tbody tr").length) {
                        self.$mainContent.show();
                        self.$emptyState.hide();
                        self.$toggleShowInactive.show();
                    } else {
                        self.$emptyState.show();
                    }

                    self.$loadingState.hide();
                });
        },

        /**
         * Get one type
         */
        getOne: function (id) {
            var self = this;

            self.$loadingState = self.$form.parent().find(`[template="${self.config.templates.loadingState}"]`);

            if (!self.$loadingState.length) {
                self.$loadingState = $(Handlebars.renderTemplate(self.config.templates.loadingState))
                    .attr('template', self.config.templates.loadingState);

                self.$form.before(self.$loadingState);
            }

            self.$form.hide();
            self.$loadingState.show();

            $.get(this.config.api + this.config.type.endpoint + "/" + id, this.config.extraApiParams)
                .done(this.handleGetOneSuccess)
                .fail(this.handleGetOneError)
                .always(function () {
                    self.$form.show();
                    self.$loadingState.hide();
                });
        },

        /**
         * Get duplicated type data
         * @param type
         * @return object duplicate
         */
        getDuplicate: function (type) {
            var duplicate = _.cloneDeep(type);

            // Set name
            duplicate[this.config.nameKey] += " (copy)";

            // Remove id, from_date, thru_date
            delete duplicate[this.config.type.underscored + "_id"];
            delete duplicate.from_date;
            delete duplicate.thru_date;
            delete duplicate.external_id;

            // Remove uniques
            this.$form.find("[unique]").each(function () {
                var name = $(this).attr("name");
                delete duplicate[name];
            });

            return duplicate;
        },

        /**
         * Get type translations
         * @param string type
         * @return object
         */
        getTranslations: function (type) {
            return {
                clazz: type,
                humanized: _.humanize(type),
                dasherized: _.kebabCase(type),
                underscored: _.snakeCase(type),
                resultsKey: _.pluralize(_.snakeCase(type)),
                endpoint: _.pluralize(_.kebabCase(type)),
                reorderEndpoint: _.pluralize(_.kebabCase(type)),
            };
        },

        /**
         * Handles get all error
         * @param object jqXHR
         * @param string textStatus
         * @param string errorThrown
         */
        handleGetAllError: function (jqXHR, textStatus, errorThrown) {
            var self = Types;

            console.log(jqXHR);
            console.log(textStatus);
            console.log(errorThrown);
            Growl.error({ message: "Error: Could not load " + _.pluralize(self.config.type.humanized) });
        },

        /**
         * Handles get all success
         * @param object data
         */
        handleGetAllSuccess: function (data, permissions) {
            var self = Types;

            var oldSearchVal = this.$search.val();
            self.initPermissions(permissions);
            if (self.renderTable(data)) {
                self.initTable();
                this.$search.val(oldSearchVal); // val is respected but gets lost from the UI so just put it back
            }
        },

        /**
         * Handles get one error
         * @param object jqXHR
         * @param string textStatus
         * @param string errorThrown
         */
        handleGetOneError: function (jqXHR, textStatus, errorThrown) {
            var self = Types;

            console.log(jqXHR);
            console.log(textStatus);
            console.log(errorThrown);
            Growl.error({ message: "Error: Could not load " + _.pluralize(self.config.type.humanized) });
        },


        /**
         * Handles get one success
         * @param object data
         */
        handleGetOneSuccess: function (data) {
            var self = Types,
                type,
                record,
                elem,
                resultsKeySingular = _.singularize(self.config.type.resultsKey);
            if (data.results) {
                type = self.config.type.resultsKey in data.results
                    ? data.results[self.config.type.resultsKey]
                    : data.results[resultsKeySingular];
                type = (_.isArray(type) && type.length ? type[0] : type);
                record = self.config.action == "duplicate" ? self.getDuplicate(type) : type;
                record.default_value_select = record.default_value
                _.each(record, function (value, key) {
                    if (!_.isObject(value)) { // filter out related record objects
                        fillInForm(key, value, undefined, true);
                    }
                });
                self.$form.trigger('FillInForm', type);
                self.disableSyncedFields(record.external_id);
            }
        },

        disableSyncedFields: function (isSyncedRecord) {
            var self = this;

            if (isSyncedRecord) {
                self.$form.find('.synced-field')
                    .attr('disabled', 1)
                    .addClass('disabled')
                    .attr('title', "Can't modify fields in Schoolrunner that are sync'ed in from your SIS!")
                    .change()
                    .filter('select')
                    .trigger('disable');
            }
        },

        /**
         * Handles search keyup
         */
        handleSearchKeyup: function () {
            var self = this;
            var value = self.$search.val();

            // Show/hide reset icon
            self.$searchReset.toggle(!!value);
        },

        /**
         * Handles search reset click
         */
        handleSearchResetClick: function () {
            var self = Types;

            // Hide reset icon
            $(this).hide();

            // Clear search value
            self.$search.val("").focus().change();
        },

        /**
         * Handles sort
         */
        handleSort: function () {
            var self = Types,
                ids = [];

            self.$table.find("tbody tr").each(function () {
                ids.push($(this).data("id"));
            });

            // reorderEndpoint: by default we post to /api/v1/whatever-types
            // with magic custom_method in the body params which we catch in
            // the crud API create/post handler and pass off to the reorder method.
            // Allow for overriding this with something more rational like
            // /api/v1/communication-types/reorder so that we can just have
            // a dedicated/named route and we don't have to worry about that
            // custom_method garbage.
            $.post(self.config.api + self.config.type.reorderEndpoint, {
                ids: ids,
                custom_method: "reorder"
            })
                .success(self.handleSortSuccess)
                .fail(self.handleSortError);
        },

        /**
         * Handles sort error
         */
        handleSortError: function () {
            var self = Types;

            // Reset sort
            self.$table.find("tbody").sortable("cancel");

            // Update tablesorter striping
            self.$table.trigger("applyWidgets");

            // Add alert
            Growl.error({ message: "Error: " + _.pluralize(self.config.type.humanized) + " could not be sorted." });
        },

        /**
         * Handles sort success
         */
        handleSortSuccess: function () {
            var self = Types;

            // Add alert
            $('#growls').empty();
            Growl.success({ message: _.pluralize(self.config.type.humanized) + " successfully reordered!", duration: 3000 });
        },

        /**
         * Handles sort start
         */
        handleSortStart: function () {
            // Close open dropdowns
            $(".dropdown.open").tssDropdown("close");
        },

        /**
         * Handles sort stop
         */
        handleSortStop: function () {
            var self = Types;

            // Update tablesorter striping
            self.$table.trigger("applyWidgets");
        },

        /**
         * Handles toggle active click
         */
        handleToggleActiveClick: function () {
            var self = Types,
                id = $(this).data("id"),
                active = $(this).data("active"),
                options = {
                    type: "PUT",
                    url: self.config.api + self.config.type.endpoint + "/" + id,
                    data: _.extend({
                        active: active == 1 ? 0 : 1
                    }, self.config.extraApiParams)
                };

            $.ajax(options)
                .done(self.handleToggleActiveClickSuccess)
                .fail(self.handleToggleActiveClickError);
        },

        /**
         * Handles toggle active click error
         * @param object jqXHR
         * @param string textStatus
         * @param string errorThrown
         */
        handleToggleActiveClickError: function (jqXHR, textStatus, errorThrown) {
            var self = Types;

            // Add alert
            Growl.error({ message: "Error: " + self.config.type.humanized + " could not be updated." });
        },

        /**
         * Handles toggle active click success
         * @param object data
         */
        handleToggleActiveClickSuccess: function (data) {
            var self = Types;

            if (data.results) {
                let type = data.results[self.config.type.underscored];
                let id = type[self.config.type.underscored + "_id"];

                // Render row
                self.renderRow(type, id);

                // Add alert
                Growl.success({ message: "Successfully " + (type.active == 1 ? "activated \"" : "deactivated \"") + _.get(type, self.config.nameKey) + "\"" });

                self.$table
                    .each(setupUI)
                    .trigger('update')
                    .trigger('SingleRowRendered', data);
            }
        },

        /**
         * Handles show inactive click
         */
        handleToggleShowInactiveClick: function () {
            var self = Types,
                $icon = $(this).find("i"),
                $span = $(this).find("span"),
                showActiveOnly = self.$activeFilter.val() == '';

            self.$activeFilter.val(showActiveOnly ? '1' : '').change();
            $icon.attr("class", "icon-eye-" + (showActiveOnly ? "open" : "close"));
            $span.text((showActiveOnly ? "Show" : "Hide") + " inactive");
        },

        initConfig: function (type, options) {
            this.config = {
                type: this.getTranslations(type),
            };

            $.extend(true, this.config, this.defaultConfig, options);
        },

        /**
         * Initialize add/edit/duplicate view
         * @param string type
         * @param string nameKey
         * @param string action
         * @param int id
         * @param object options
         */
        initAddEdit: function (type, nameKey, action, id, options) {
            this.initConfig(type, options);
            this.setupAddEdit(type, nameKey, action, id);
            this.setAddEditBindings();
            if (id) {
                this.getOne(id);
            }
        },

        /**
         * Initialize list view
         * @param string type
         * @param string nameKey
         * @param boolean isSortable
         */
        initList: function (type, nameKey, isSortable, options) {
            this.initConfig(type, options);
            this.setupList(type, nameKey, isSortable);
            this.setListBindings();
            this.getAll();
        },

        /**
         * Initialize table
         */
        initTable: function () {
            var self = this;

            if (self.config.isSortable) {
                self.$table.find("th").attr('metadata', '{sorter:false}').addClass('cursor-default');
            }

            // Init Tablesorter
            self.$table.tablesorter(_.extend({ sortList: self.config.isSortable ? [] : [[0, 0]] }, self.config.table));
            self.$table.tablesorter().tablesorterPager(_.extend({ container: self.$pager }, self.config.pager));
            self.$search.val(self.$search.attr('value'));
            self.handleSearchKeyup();
            self.$activeFilter.val(1).change(); // initial state is active only

            // Init jQuery UI Sortable
            if (self.config.isSortable) {
                self.$table.find("tbody").sortable(_.extend({ update: _.debounce(self.handleSort, 3000) }, self.config.sortable));
                self.$table.find("tbody").on("sortstart", self.handleSortStart);
                self.$table.find("tbody").on("sortstop", self.handleSortStop);
            }

            self.updatePager(); // update pager based on saved settings and initial filters (e.g. active only)
        },

        /**
         * Save permissions that should affect what we can do on this page.
         * @param  array permissions results => me => permissions => permission_type => bool
         */
        initPermissions: function (permissions) {
            var self = this;

            self.hasMultiSchool = _.get(permissions, 'results.me.permissions.multi_school');
        },

        /**
         * Renders table
         * @param array types
         */
        renderTable: function (data) {
            var self = this;
            var types = _.get(data, `results.${self.config.type.resultsKey}`);

            // school setup switched the page out before our results came back
            self.unloaded = types === undefined;
            if (self.unloaded) {
                return false;
            }

            _.each(types, function (type) {
                var id = type[self.config.type.underscored + "_id"];

                self.renderRow(type, id);
            });

            self.$table
                .each(setupUI)
                .trigger('update')
                .trigger('TableRendered', data);

            return true;
        },

        /**
         * Renders row
         * @param object type
         * @param int id
         */
        renderRow: function (type, id) {
            var self = this;
            var canEdit = !('school_id' in type) || self.hasMultiSchool || type.school_id;
            var $row = $(Handlebars.renderTemplate(self.config.templates.row, _.extend({}, type, self.config.extraTemplateParams)))
                .addClass(self.config.templates.row.replace(/[/]/g, '_'));
            var firstCell = $row.find('td:first');
            var dragIcon = firstCell.find('.icon-ellipsis-vertical');

            if (!dragIcon.length) {
                var newFirstCellContents = $('<span class="flex"><i class="icon-ellipsis-vertical inline-flex"></i></span>')
                    .append($('<span class="inline-flex"></span>').html(firstCell.html()));

                firstCell.html(newFirstCellContents);
                dragIcon = firstCell.find('.icon-ellipsis-vertical');
            }

            // style row if it's sortable and/or editable
            if (this.config.isSortable) {
                if (canEdit) {
                    $row.addClass('draggable');
                } else {
                    $row.removeClass('draggable');
                }
            } else {
                dragIcon.addClass('hidden');
            }

            if (!canEdit) {
                var link = firstCell.find('a');

                $row.addClass('disabled');
                $row.find('.options').remove();
                dragIcon.replaceWith('<i class="icon-lock" title="You don\'t have the \'multi_school\' permission which is required to edit this record." tss-tooltip></i>&nbsp;');
                link.replaceWith(link.text());
            } else if (!this.config.isSortable) {
                dragIcon.remove();
            }

            // Remove tooltips
            $(".tss-tooltip").remove();

            var historyUrl = '/changelog/object/' + this.config.type.clazz + '/' + id;
            var historyAction = '<div class="menu-action" iframeHeight="400" iframeWidth="900" iframehref="#' + historyUrl + '"><i class="icon-time"></i>History</div>';
            $row.find(".options").tssDropdown({
                content: historyAction + Handlebars.renderTemplate(this.config.templates.rowOptions, _.extend({}, type, self.config.extraTemplateParams)),
                icon: 'icon-cog',
                right: true
            });

            var existingRow = this.$table.find("#" + this.config.type.dasherized + "-" + id);
            if (existingRow.length) {
                existingRow.replaceWith($row);
            } else {
                this.$table.find("tbody").append($row);
            }
        },

        /**
         * Set bindings on add/edit/duplicate view
         */
        setAddEditBindings: function () {
            var self = this;

            self.setSubmitHandler();
            self.$form.on('submit', _.bind(self.showSavingIndicator, self));
            self.$form.on('submitComplete', _.bind(self.hideSavingIndicator, self));

            // add a floating save button if there isn't one already but there
            // is a save button at the bottom of the page
            if ($('[rel="submit-form"]').length && !$('#view-actions').length) {
                self.$form.before(Handlebars.renderTemplate('sticky-save-button'));
                $('[rel="tss-sticky"]').tssSticky();
            }
        },

        setSubmitHandler: function () {
            var self = this;
            var isEdit = self.config.action == "edit";
            var url = self.config.api
                + self.config.type.endpoint + "/"
                + (isEdit ? self.config.id : "")
                + (self.config.extraApiParams.expand ? `?expand=${self.config.extraApiParams.expand}` : '');

            self.$form.off('submit.save').on('submit.save', onSubmit(url, async function (data, form) {
                if (data.success) {
                    if (self.config.submitCallback) {
                        await self.config.submitCallback(data, form);
                    } else {
                        self.redirectToReferrer();
                    }
                } else {
                    handlePost(data, undefined, undefined, form);
                }
            }, isEdit ? "PUT" : "POST", true));
        },

        redirectToReferrer: function (defaultUrl) {
            var self = this;
            var referrer = self.$form.data('referrer');

            referrer = referrer && window.location != referrer
                ? referrer
                : (defaultUrl || ("/setup/configure/" + self.config.type.endpoint));

            window.location.assign(referrer);
        },

        showSavingIndicator: function (e) {
            if (e && e.validationFailed) {
                return; // short circuit because validation failed
            }

            var self = this;
            var savingIndicator = self.$form.find('.types-saving-indicator');

            if (!savingIndicator.length) {
                savingIndicator = $(Handlebars.renderTemplate('saving-indicator'));
                self.$form.append(savingIndicator.addClass('types-saving-indicator'));
            }

            self.$form.find('section .tab-btn-group').children()
                .filter('[rel="submit-form"]').text('Saving...');
            savingIndicator.show();
        },

        hideSavingIndicator: function () {
            var self = this;
            var savingIndicator = self.$form.find('.types-saving-indicator');

            self.$form.find('section .tab-btn-group').children()
                .filter('[rel="submit-form"]').text('Save');
            savingIndicator.hide();
        },

        showLoadingIndicator: function () {
            var self = this;
            var content = self.$form || self.$mainContent;

            content.hide();
            self.$loadingState.show();
        },

        hideLoadingIndicator: function () {
            var self = this;
            var content = self.$form || self.$mainContent;

            content.show();
            self.$loadingState.hide();
        },

        /**
         * Setup add/edit/duplicate view
         * @param string type
         * @param string nameKey
         * @param string action
         * @param int id
         */
        setupAddEdit: function (type, nameKey, action, id) {
            this.config.nameKey = nameKey || this.config.nameKey;
            this.config.action = action;
            this.config.id = id;
            this.$form = $("#main-form");
        },

        /**
         * Set bindings on list view
         */
        setListBindings: function () {
            this.$table.on("click", "[rel=\"toggle-active\"]", this.handleToggleActiveClick);
            this.$search.on("keyup", _.bind(this.handleSearchKeyup, this));
            this.$searchReset.on("click", this.handleSearchResetClick);
            this.$toggleShowInactive.on("click", this.handleToggleShowInactiveClick);
        },

        /**
         * Setup list view
         * @param string type
         * @param string nameKey
         * @param boolean isSortable
         */
        setupList: function (type, nameKey, isSortable) {
            this.config.nameKey = nameKey || this.config.nameKey;
            this.config.isSortable = !!isSortable;
            this.config.templates.row = this.config.templatePath + this.config.type.dasherized + "-table-row";
            this.config.templates.rowOptions = this.config.templatePath + this.config.type.dasherized + "-table-row-options";
            this.$table = $("#types-table");
            this.$pager = $("#types-table-pager");
            this.$search = $("#types-search");
            this.$searchReset = $("#types-search-reset");
            this.$toggleShowInactive = $("#toggle-show-inactive");

            // setup search
            this.$activeFilter = $('<input type="hidden" data-column="1"/>'); // HACK: active has to always be the second column
            this.$search.attr('data-column', 'all'); // make this search field search all columns
            this.$search.after(this.$activeFilter);
        },

        /**
         * Updates pager
         */
        updatePager: function () {
            var pageSize = this.$table.data().pagerLastSize;

            this.$table.trigger("pageSize", pageSize);
            this.$pager.find(".pagesize").val(pageSize).change();
        }
    };

    window.Tss = window.Tss || {};
    window.Tss.Types = Types;

})(jQuery, window);

(function ($, window, undefined) {"use strict";

	var defaults = {
		api: "/api/v1/tour/",
		hook: null,
		overlay: true,
		close: true,
		timer: false,
		onStep: $.noop,
		onComplete: $.noop
	};

	function TssTour(element, options) {
		this._options = $.fn.tssOptions("tss-tour", element, defaults, options);
		this.document = element; 
		this.$document = $(element);	
		setup.call(this);
		setBindings.call(this);
	}

	function setup() {
		if (!this._options.hook) {
			return $.error("TssTour hook must be provided.");
		}
		getTour.call(this);
	}

	function setBindings() {
		var self = this;
		$(document).on("click", "[tss-tour-restart]", function () {
			$(".tss-tooltip").remove();
			self.restart();
		});
	}

	function getTour() {
		var self = this;
		$.get(this._options.api, {
			hook: this._options.hook
		}).done(function (data) {
			if (data.results.tours) {
				self.tour = data.results.tours[0];
				render.call(self, self.tour.tour_steps, !self.tour.tour_user);
			}
		});
	}

	function getOptions() {
		var self = this,
		options = {
			expose: true,
			modal: this._options.overlay,
			timer: this._options.timer ? 3000 : 0,
			template: {
				link: this._options.close ? Handlebars.renderTemplate("tss-tour-link") : "",
				button: Handlebars.renderTemplate("tss-tour-button"),
				tip: Handlebars.renderTemplate("tss-tour-tip", self.tour)
			},
			preStepCallback: function (index, $step) {
				self._options.onStep.call($step.get(0), index, self.tour.tour_steps);
			},
			postRideCallback: function (index, $step) {
				self.seen(self.tour.tour_id);
				self._options.onComplete.call($step.get(0), index, self.tour.tour_steps);
			}
		};
		return options;
	}

	function render(steps, start) {
		this.$tour = $(Handlebars.renderTemplate("tss-tour", { steps: steps }));
		this.$tour.appendTo(this.$document.find("body"));
		this.$tour.joyride(getOptions.call(this));
		if (start) {
			this.restart();
		}
	}

	TssTour.prototype.restart = function () {
		this.$tour.joyride("restart");
	}

	TssTour.prototype.seen = function (tourId) {
		if (!tourId) {
			return $.error("TssTour Tour ID must be provided.");
		}
		$.post(this._options.api + "seen", { tour_ids: [tourId] });
	}

	$.fn.tssTour = $.fn.tssExtend(TssTour);

})(jQuery, window);

(function ($, window, undefined) {"use strict";

    var defaults = {};

    function TssExpandable(element, options) {
        this.expandable = element;
        this.$expandable = $(element);
        this.options = $.fn.tssOptions("tss-expandable", element, defaults, options);
        setup.apply(this);
    };

    function setup() {
        var self = this;
        if(this.$expandable.hasClass('is-tss-expandable')) { return; }
        this.$expandable.addClass('is-tss-expandable');

        // start expanded if we're linking straight to this element
        if (window.location.hash == '#' + this.expandable.id) {
            this.$expandable.removeClass('closed');
        }

        this.$expandable.toggleClass("closed");
        this.$expandable.on('click', 'h5:first-child', function () {
            self.toggle();
        }).click();
        this.$expandable.find("h5").first().click();

        $('.is-tss-expandable').each(function() {
            if ($(this).next().is('.is-tss-expandable')
                    && !$(this).prev().is('.is-tss-expandable')
                    && !$(this).prevAll('.tss-expand-collapse-all').length) {
                var links = $(`
                    <div class="tss-expand-collapse-all">
                        <a rel="expand-all" href="#">Expand All</a>
                        /
                        <a rel="collapse-all" href="#">Collapse All</a>
                    </div>
                `);
                $(this).parent().css({ position: 'relative' }).prepend(links);
                $('.tss-expand-collapse-all')
                    .off('click.tss-expand-collapse-all')
                    .on('click.tss-expand-collapse-all', 'a', handleExpandCollapseAllClick);
                $('body')
                    .off('tss-expandable-open.tss-expand-collapse-all')
                    .on('tss-expandable-open.tss-expand-collapse-all', handleOpenCloseEvent);
                $('body')
                    .off('tss-expandable-closed.tss-expand-collapse-all')
                    .on('tss-expandable-closed.tss-expand-collapse-all', handleOpenCloseEvent);
            }
        });
    };

    function handleExpandCollapseAllClick(e) {
        e.preventDefault();

        var expandables = $(this)
            .closest('.tss-expand-collapse-all')
            .nextAll('.is-tss-expandable');
        var open = $(this).attr('rel') == 'expand-all';

        expandables[open ? 'removeClass' : 'addClass']('closed');
        expandables.first().trigger(open
            ? 'tss-expandable-open'
            : 'tss-expandable-closed');
    };

    function handleOpenCloseEvent(e) {
        $('.tss-expand-collapse-all').each(function() {
            var expandables = $(this).nextAll('.is-tss-expandable');
            var closedExpandables = $(this).nextAll('.is-tss-expandable.closed');
            var allClosed = expandables.length == closedExpandables.length;
            var allOpen = closedExpandables.length == 0;
            var expandAllLink = $(this).find('a[rel="expand-all"]');
            var collapseAllLink = $(this).find('a[rel="collapse-all"]');

            if (allOpen) {
                expandAllLink.addClass('disabled');
                collapseAllLink.removeClass('disabled');
            } else if (allClosed) {
                expandAllLink.removeClass('disabled');
                collapseAllLink.addClass('disabled');
            } else {
                expandAllLink.removeClass('disabled');
                collapseAllLink.removeClass('disabled');
            }
        });
    };

    function isOpen(expandable) {
        return !expandable.hasClass('closed');
    };

    function toggle(expandable, open) {
        var wasOpen = isOpen(expandable);

        if (open !== undefined && open === wasOpen) {
            return;
        }

        open = !wasOpen;
        expandable[open ? 'removeClass' : 'addClass']('closed');
        expandable.trigger(open
            ? 'tss-expandable-open'
            : 'tss-expandable-closed');
    };

    TssExpandable.prototype.isOpen = function () {
        return isOpen(this.$expandable);
    };

    TssExpandable.prototype.toggle = function () {
        return toggle(this.$expandable, open);
    };

    TssExpandable.prototype.open = function () {
        this.toggle(true);
    };

    TssExpandable.prototype.close = function () {
        this.toggle(false);
    };

    $.fn.tssExpandable = $.fn.tssExtend(TssExpandable);

})(jQuery, window);

/**
 * 
 * @author cmitchell
 * @deprecated
 * @see jquery.tss-select-search.js
 */
(function($) {
    $.fn.tssSelect = function(options){
        //set default options
        var defaultOptions = {
            placeholder: 'No Selection'
        };
        
        //merge with passed options
        options = $.extend(defaultOptions, (typeof options == 'object' ? options : {}));
        
        //close on body click
        $('html').click(function() {
            $('.tss-select ul').hide();
        });
        
        return this.each(function(){
            //get input into scoped variable
            var inputElement = $(this);
            
            //check if has already had this plugin applied or is missing multiple attribute
            if (inputElement.siblings('div.tss-select-btn').length !== 0 || inputElement.attr('multiple')) {
                return inputElement;
            }
            
            //get selected value as string
            var selectedValue = inputElement.val();
            
            //get selected text as string
            var selectedText = inputElement.find('option:selected').text();
            
            //get selected class as string
            var selectedClass = inputElement.find('option:selected').data('class') || '';
            
            //wrap input
            inputElement.wrap('<div class="tss-select" />');
            
            //so body click will close only if not within the unordered list
            inputElement.parent().click(function(e){
                e.stopPropagation();
            });

            //hide input
            inputElement.hide();
            
            //add button
            var toggleButton = $('<div class="tss-select-btn" />');
            inputElement.after(toggleButton);
            
            //create options list
            var optionsList = $('<ul />');
            
            //add no value selection
            $('<li data-value="">' + defaultOptions.placeholder + '</li>').appendTo(optionsList);
            
            //populate options list
            inputElement.find('option').each(function(i, option) {
                option = $(option);
                if (option.text().length > 0) {
                    $('<li data-value="' + option.val() + '" class="' + option.data('class') + '">' + option.text() + '</li>').appendTo(optionsList);
                }
            });
            toggleButton.after(optionsList);
            
            //get options list
            var listItems = optionsList.find('li');
            
            //update selected value
            updateSelectedValue();
            
            //bindings
            toggleButton.click(function(e){
                e.preventDefault();
                if (optionsList.is(':visible')) {
                    optionsList.hide();
                } else {
                    //first hide all others
                    $('.tss-select ul').hide();
                    
                    //then show
                    optionsList.show();
                }
            });
            optionsList.find('li').click(function(e){
                e.preventDefault();
                var item = $(this);
                
                //hide options list
                optionsList.hide();
                
                //set selected value
                selectedValue = item.data('value');
                selectedText = item.text();
                selectedClass = item.attr('class') || '';
                
                //set original select list option and trigger change
                inputElement.val(selectedValue).trigger('change');
                
                //update
                updateSelectedValue();
            });
            
            //functions
            function updateSelectedValue() {
                //set button text
                var text = selectedText;
                if (text.length === 0) {
                    text = defaultOptions.placeholder;
                }
                toggleButton.text(text).attr('class', 'tss-select-btn ' + selectedClass);
                
                //remove selected class from all list items
//                listItems.removeClass('selected');
                
                //add selected class to correct item
//                optionsList.find('li[data-value="' + selectedValue + '"]').addClass('selected');
            }
        });
    };
}(jQuery));
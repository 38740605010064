var lodashInflection = require("lodash-inflection");
var _ = require('lodash-backports').register();

_.mixin(lodashInflection);
_.mixin({
    'titleize': titleize,
    'humanize': humanize,
    'indexBy': indexBy,
    'scrollBarWidth': scrollBarWidth,
    'includesCaseInsensitive': includesCaseInsensitive
});

function titleize(string) {
    return string.toLowerCase().replace(/(^([a-zA-Z\p{M}]))|([ -][a-zA-Z\p{M}])/g, function(str) {
        return str.toUpperCase();
    });
}

function humanize(string) {
    return _.capitalize(_.trim(_.snakeCase(string).replace(/_id$/, '').replace(/_/g, ' ')));
}

function indexBy(...args) {
    return _.keyBy(...args);
}

function scrollBarWidth() {
    var inner, outer, w1, w2;

    inner = document.createElement('p');
    inner.style.width = "100%";
    inner.style.height = "200px";

    outer = document.createElement('div');
    outer.style.position = "absolute";
    outer.style.top = "0px";
    outer.style.left = "0px";
    outer.style.visibility = "hidden";
    outer.style.width = "200px";
    outer.style.height = "150px";
    outer.style.overflow = "hidden";
    outer.appendChild(inner);

    document.body.appendChild(outer);
    w1 = inner.offsetWidth;
    outer.style.overflow = 'scroll';
    w2 = inner.offsetWidth;
    if (w1 == w2) {
        w2 = outer.clientWidth;
    }

    document.body.removeChild(outer);

    return (w1 - w2);
}

function includesCaseInsensitive(value, search) {
    return _.includes(_.lowerCase(value), _.lowerCase(search));
}

module.exports = _;
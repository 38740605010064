/**
 *
 * @author cmitchell
 */
(function($) {
    $.fn.tssMultiSelectSearch = function(baseOptions){
        //set default options
        var defaultOptions = {
            placeholder: 'No Options Selected',
            displayLimit: 0,
            displayOptionText: false,
            inputPlaceholder: 'Filter',
            caseSensitive: false,
            openFocusFirstSelected: true,
            allowEmptyValue: false,
            tooltip: ''
        };

        //merge with passed options
        baseOptions = $.extend(defaultOptions, (typeof baseOptions === 'object' ? baseOptions : {}));

        //close on body click
        $('body').off('click.tss-multiselect-search')
                 .on('click.tss-multiselect-search', function(e) {
            if($(e.target).hasClass('no-tss-select-search-hide') || $(e.target).hasClass('no-tss-multiselect-search-hide')) {
                return;
            }
            hideAll();
        });

        return this.each(function(){
            //get input into scoped variable
            var inputElement = $(this),
                lastEvent = {};

            //check if has already had this plugin applied or is missing multiple attribute
            if (inputElement.siblings('.tss-multiselect-search-btn').length !== 0) {
                return inputElement;
            }

            inputElement.addClass('is-tss-multiselect-search');

            //handle plugin options in data attrs
            var options = handleOptionAttrs(inputElement, baseOptions);

            //options
            var allOptions = [];
            getAllOptions();

            //get values as array
            var inputVal = inputElement.val(),
                selectedValues = inputVal ? ($.isArray(inputVal) ? inputVal : JSON.parse(inputVal)) : [];

            //wrap input
            inputElement.wrap('<div class="tss-multiselect-search" />');
            var wrapper = inputElement.parent();

            //so body click will close only if not within the unordered list
            inputElement.parent().click(function(e){
                e.stopPropagation();
            });

            //add button
            var toggleButton = $("<div>", { tabindex: 0 })
                .addClass("tss-multiselect-search-btn")
                .attr('for', inputElement.attr('name'));
            inputElement.after(toggleButton);

            var spinner = $('<i class="icon-spinner icon-spin show-when-loading"></i><span></span>');
            toggleButton.append(spinner);

            //create options list
            var $optionsListWrapper = $('<div class="options-list-wrapper" />'),
                $optionsList = $('<ul />'),
                searchDataFeature = inputElement.data('feature') + ' Filter',
                searchClearDataFeature = inputElement.data('feature') + ' Clear',
                searchField = $('<div class="search-field"><input placeholder="' + options.inputPlaceholder + '"data-feature="' + searchDataFeature + '" /><div class="icons"><i class="icon-ok"></i><i class="icon-remove"></i></div></div>').appendTo($optionsListWrapper),
                searchFieldHeight = searchField.outerHeight(),
                selectAllIcon = searchField.find('i.icon-ok'),
                clearAllIcon = searchField.find('i.icon-remove').data({
                    'feature': searchClearDataFeature,
                });
            searchField = searchField.find('input');
            buildOptionsList();
            $optionsList.appendTo($optionsListWrapper);
            toggleButton.after($optionsListWrapper);

            //get options list
            var listItems = $optionsList.find('li');

            //update selected values
            updateSelectedValues();

            setBindings();

            if (inputElement.attr('disabled')) {
                disable();
            }

            var validate = function() { 
                var invalid = inputElement.is('[required]') && !inputElement.get(0).checkValidity();

                toggleButton[invalid ? 'addClass' : 'removeClass']('error');
                toggleButton.attr('title', invalid
                    ? 'Please fill out this field.'
                    : toggleButton.data('title'));
            };
            
            inputElement.change(validate);
            searchField.blur(validate);

            searchField.on('keydown', function(e) {
                if (e.keyCode == 13) { // enter
                    return false; // if we're inside a form, don't submit it
                }
            });

            //functions
            function handleChange() {
                //update
                selectedValues = inputElement.val();
                if (!_.isArray(selectedValues)) {
                    selectedValues = selectedValues === null
                        ? []
                        : [selectedValues];
                }
                updateSelectedValues();
            }

            function updateSelectedValues() {
                //set button text
                setButtonTextAndTooltip();

                //remove selected class from all list items
                listItems.removeClass('selected');

                //iterate through all list items and add select class if in selectedValues array
                listItems.each(function(i, item){
                    item = $(item);
                    if (item.data('value') && selectedValues.contains(item.data('value'))) {
                        item.addClass('selected');
                    }
                });
            }

            function setButtonTextAndTooltip() {
                var buttonTextValues = [];
                $.each(selectedValues, function(i, value){
                    if (options.displayOptionText) {
                        // show text value
                        buttonTextValues.push(inputElement.find('[value="' + value + '"]').text().trim());
                    } else {
                        // show raw option value
                        buttonTextValues.push(value);
                    }
                });

                //concat
                var text = buttonTextValues.join(', ');

                // if display limit set
                if (options.displayLimit !== 0) {
                    var tempSelectedValues = [];
                    $.each(buttonTextValues, function(i, value){
                        if (value.length - 3 > options.displayLimit) {
                            tempSelectedValues.push(
                                value.substr(0, options.displayLimit - 3) + '...'
                            );
                        } else {
                            tempSelectedValues.push(value);
                        }
                    });
                    text = tempSelectedValues.join(', ');
                }

                var hasPlaceholder = !text.length;

                // if nothing selected, use placeholder
                if (hasPlaceholder) {
                    text = options.placeholder;
                }

                toggleButton.attr('tss-tooltip', true);
                toggleButton.attr('title', text);
                toggleButton.data('title', text);

                toggleButton.toggleClass("has-placeholder", hasPlaceholder)
                    .find('span').text(text);
            }

            function clearAll() {
                listItems.each(function() {
                    selectedValues.splice(selectedValues.indexOf($(this).data('value')), 1);
                });
                selectedValues = [];
                updateSelectedValues();
                inputElement.val(selectedValues).trigger('change');
                searchField.trigger('keyup').focus();
            }

            function selectAll() {
                listItems.each(function() {
                    if (!$(this).hasClass('optgroup')) {
                        var val = $(this).data('value');
                        var isHidden = $(this).is('.hidden');

                        if (_.indexOf(selectedValues, val) == -1
                                && !isHidden) {
                            selectedValues.push(val);
                        }
                    }
                });
                updateSelectedValues();
                inputElement.val(selectedValues).trigger('change');
                searchField.trigger('keyup').focus();
            }

            function removeFromSelectValuesArray(value) {
                selectedValues = _.filter(selectedValues, function(val) {
                    return value != val;
                });
                inputElement.val(selectedValues).trigger('change');
                searchField.focus();
            }

            function addValueToSelectValuesArray(value) {
                selectedValues.push(value);
                inputElement.val(selectedValues).trigger('change');
                searchField.focus();
            }

            function searchOptions(searchBy) {
                var toSearchBy = options.caseSensitive ? searchBy : searchBy.toLowerCase();

                var results = $.grep(allOptions, function(option) {
                    var optionText = $(option).text();
                    var optgroupText = $(option).closest('optgroup').attr('label') || '';

                    if (!options.caseSensitive) {
                        optionText = optionText.toLowerCase();
                        optgroupText = optgroupText.toLowerCase();
                    }

                    var optionContainsText = optionText.indexOf(toSearchBy) !== -1;
                    var optgroupContainsText = optgroupText.indexOf(searchBy) !== -1;

                    return optionContainsText || optgroupContainsText;
                });
                buildOptionsList(results);
            }

            function buildOptionsList(data) {
                data = data || allOptions;

                //empty options
                emptyOptionsList();

                //populate options list
                var lastOptGroup = null,
                    optionCount = 0;

                $.each(data, function() {
                    var option = $(this),
                        value = option.attr('value'),
                        classes = option.attr("class"),
                        iconClass = option.attr("icon-class"),
                        selected = option.prop('selected') || value == inputElement.data('value');

                    if (option.is('option')) {
                        if((!value || !value.length) && !options.allowEmptyValue) { return; }
                        var text = option.text().trim();
                        var title = option.data('title') || option.attr('title') || text;
                        var icon = iconClass ? '&nbsp;<i class="' + iconClass + '"></i>' : null;
                        var isLocked = option.attr('data-locked') || null;

                        if (!option.parent().is('optgroup')) {
                            lastOptGroup = null;
                        }

                        var li = $('<li/>')
                            .attr('data-locked', isLocked)
                            .attr('data-key', optionCount)
                            .attr('data-value', value)
                            .attr('title', title)
                            .addClass(classes)
                            .text(text);

                        if (icon) {
                            li.append(icon);
                        }

                        if (lastOptGroup) {
                            li.attr('data-group', lastOptGroup);
                        }

                        li.appendTo($optionsList);
                        optionCount++;

                        if(selected && selectedValues.indexOf(value) === -1) {
                            selectedValues.push(value);
                        }
                    } else {
                        lastOptGroup = option.attr('label');
                        $('<li class="optgroup">' + lastOptGroup + '</li>').appendTo($optionsList);
                    }
                });

                //get new options list
                listItems = $optionsList.find('li').not('.search-field');

                //if only one, focus
                if (listItems.length === 1) {
                    listItems.first().addClass('focused');
                }

                updateSelectedValues();
            }

            function emptyOptionsList() {
                if (listItems) {
                    listItems.remove();
                }
            }

            function traverseList(event) {
                //get currently focused option
                var $focusedOption = $('li.focused', $optionsList);

                $focusedOption.removeClass('focused');

                if ($focusedOption.length === 0) {
                    listItems.first().addClass('focused');
                }
                //up
                else if (event.keyCode === 38) {
                    //get prev option
                    var prevOption = $focusedOption.prev();

                    //if prev option, add focused class
                    if (prevOption.length) {
                        $focusedOption.removeClass('focused');
                        prevOption.addClass('focused');
                    }
                    //end of list
                    else {
                        listItems.last().addClass('focused');
                    }
                }
                //down
                else {
                    //get next option
                    var nextOption = $focusedOption.next();

                    //if next option, add focused class
                    if (nextOption.length) {
                        $focusedOption.removeClass('focused');
                        nextOption.addClass('focused');
                    }
                    //end of list
                    else {
                        listItems.first().addClass('focused');
                    }
                }

                if($('li.focused', $optionsList).hasClass('optgroup')) {
                    traverseList(event);
                } else {
                    scrollOptionIntoView();
                }
            }

            function scrollOptionIntoView() {
                var $focusedOption = $optionsList.find('li.focused');

                if(!$focusedOption.length) {
                    $optionsList.scrollTop(0);
                    return;
                }

                var top = $focusedOption.position().top,
                    optionHeight = $focusedOption.outerHeight(),
                    optionPosition = $focusedOption.data('key'),
                    listHeight = $optionsList.height() - searchFieldHeight;

                if(top > listHeight || top < optionHeight) {
                    $optionsList.scrollTop(optionPosition * optionHeight);
                }
            }

            function getAllOptions() {
                allOptions = $(inputElement.find('option, optgroup'));
            }

            function refreshOptions(event, update) {
                var oldVal = inputElement.val();

                inputElement.val('');
                getAllOptions();
                buildOptionsList();
                listItems.removeClass('focused').removeClass('selected');
                searchField.val('');
                close();
                inputElement.val(oldVal); // FIXME what to do if some oldVals are not in the new set of options? see tss-select-search.

                if (update) {
                    updateSelectedValues();
                } else {
                    clearAll();
                }
            }

            function disable() {
                wrapper.addClass('disabled').removeClass('open');
                toggleButton.addClass('disabled');
            }

            function enable() {
                wrapper.removeClass('disabled');
            }

            function handleClickFocus(e) {
                if(wrapper.hasClass('disabled') || (e.type === 'click' && lastEvent.type === 'focus')) {
                    lastEvent = {};
                    return;
                }
                lastEvent = e;

                if (wrapper.hasClass('open')) {
                    close();
                } else {
                    //first hide all others
                    hideAll();

                    //then show
                    open();
                }
                return false;
            }

            function scrollToFirstSelected() {
                var $firstSelected = $optionsList.find('li.selected').first(),
                    optionHeight = $firstSelected.outerHeight(),
                    optionPosition = $firstSelected.data('key');

                //nothing selected
                if(!$firstSelected.length) { return; }

                //scroll to top of first selected
                $firstSelected.addClass('focused');
                $optionsList.scrollTop(optionPosition * optionHeight);
            }

            function open() {
                if(wrapper.hasClass('open')) { return; }

                $optionsList.find('li').removeClass('focused');
                wrapper.addClass('open');

                //scroll to first selected
                if(options.openFocusFirstSelected) {
                    scrollToFirstSelected();
                } else {
                    $optionsList.scrollTop(0);
                }

                searchField.focus().selText().select();
                inputElement.trigger('tss.select.is.open');
            }

            function close() {
                if(wrapper.hasClass('open')) {
                    wrapper.removeClass('open');
                    inputElement.trigger('tss.select.is.closed');
                }
            }

            function setBindings() {
                //toggle show/hide options list
                toggleButton.on('click focus', function (e) {
                    if (e.type == 'click') {
                        //put this trigger here instead of on close function
                        //because close is called multiple times on page load
                        inputElement.trigger('gaEvent');
                    }
                    handleClickFocus(e);
                });

                //select all text when clicking the input
                searchField.on('click', function(){
                    searchField.trigger('gaEvent');
                    searchField.selText().select();
                });

                //click an option
                $optionsList.on('click', 'li:not(.optgroup)', function(e){
                    e.preventDefault();
                    var item = $(this);

                    //remove if has class selected
                    if (item.hasClass('selected')) {
                        removeFromSelectValuesArray(item.data('value'));
                    }
                    //add if doesn't
                    else {
                        addValueToSelectValuesArray(item.data('value'));
                    }

                    //update
                    updateSelectedValues();
                });

                //click an optgroup
                $optionsList.on('click', 'li.optgroup', function(e){
                    e.preventDefault();
                    var optgroup = $(this),
                        children = optgroup.nextUntil('.optgroup'),
                        selectedCount = children.filter('.selected').length,
                        noneSelected = selectedCount == 0,
                        allSelected = selectedCount == children.length;

                    $.each(children, function() {
                        var item = $(this);

                        if (allSelected) { // deselect all
                            removeFromSelectValuesArray(item.data('value'));
                        } else { // select all the unselected ones
                            if (!item.hasClass('selected')) {
                                addValueToSelectValuesArray(item.data('value'));
                            }
                        }
                    });

                    updateSelectedValues();
                });

                searchField.on('keyup', function(e){
                    switch (e.keyCode) {
                        case 37: return; //left
                        case 39: return; //right
                        case 9: return; //tab
                        case 13: //enter
                            //trigger click
                            $optionsList.find('.focused').trigger('click', false);
                            return;
                        case 38: //up
                            //traverse
                            traverseList(e);
                            return;
                        case 40: //down
                            //traverse
                            traverseList(e);
                            return;
                        case 27: //escape
                            close();
                            return;
                    };

                    //search
                    searchOptions($(this).val());
                });

                //bind to select all
                clearAllIcon.on('click', function () {
                    $(this).trigger('gaEvent');
                    clearAll();
                })

                //bind to select all
                selectAllIcon.on('click', function () {
                    $(this).trigger('gaEvent');
                    selectAll();
                })

                //bind to change
                inputElement.change(handleChange);

                //refresh options
                inputElement.on('refresh-options', refreshOptions);

                //open & close
                inputElement.on('tss.select.open', function () {
                    $(this).trigger('gaEvent');
                    open();
                });
                inputElement.on('tss.select.close', close);

                //disable and enable
                inputElement.on('disable', disable);
                inputElement.on('enable', enable);
                inputElement.on('clear', clearAll);
            }
        });

        function hideAll(inputElement) {
            if(inputElement) {
                $('.tss-select-search.open, .tss-multiselect-search.open').find('select').not(inputElement).trigger('tss.select.close');
            } else {
                $('.tss-select-search.open, .tss-multiselect-search.open').find('select').trigger('tss.select.close');
            }
        }

        function handleOptionAttrs(el, baseOptions) {
            var options = _.clone(baseOptions);
            _.each(el.data(), function(value, key) {
                options[key] = value;
            });

            return options;
        }
    };
}(jQuery));

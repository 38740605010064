(function ($) {
    $.fn.offsetRelative = function (top) {
        var $this = $(this);
        var $parent = $this.offsetParent();
        var offset = $this.position();

        // add scroll
        offset.top += $this.scrollTop() + $parent.scrollTop();
        offset.left += $this.scrollLeft() + $parent.scrollLeft();
        if (!top) {
            // Didn't pass a 'top' element
            return offset;
        } else if ($parent.get(0).tagName == "BODY") {
            // Reached top of document
            return offset;
        } else if ($(top, $parent).length) {
            // Parent element contains the 'top' element we want the offset to be relative to
            return offset;
        } else if ($parent[0] == $(this).closest(top)[0]) {
            // Reached the 'top' element we want the offset to be relative to
            return offset;
        } else {
            // Get parent's relative offset
            var parent_offset = $parent.offsetRelative(top);
            offset.top += parent_offset.top;
            offset.left += parent_offset.left;
            return offset;
        }
    };
    $.fn.positionRelative = function (top) {
        return $(this).offsetRelative(top);
    };
}(jQuery));
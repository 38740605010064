var ManualAssessment = {
    courseId: $('select[name="course_id[]"]').val(),
    asof: $('input[name="date"]').val(),
    staffMemberId: $('input[name="staff_member_id"]').val(),
    studentsJsonEndpoint: '/student/all/json',
    objectivesJsonEndpoint: '/objective/all/json',
    assessmentDefinitionConfig: {},
    objectivesPicklistTemplate: 'manual-assessment-objectives-picklist',
    questions: [],
    students: [],
    enrollment: [],
    sectionStudents: [],
    studentPicklistOptions: [],
    renderedStudentsPicklist: '',
    objectives: [],
    objectiveOptions: [],
    config: {
        groupStudentsBySection: false,
        groupObjectivesByParent: false,
    },
    opts: {
        termId: null,
        standardsBasedGrading: false,
    },
    errorTimeoutDuration: 5000,
    arrayForTransport: [],
    defaultPointValue: 100,
    includeUnchanged: false,
    processingDropData: false,
    forceSaveEnrollment: false,
    traverseKeyCodes: [37, 38, 39, 40], //left, up, right, down
    countsForGrades: {},

    init: function(opts) {
        ManualAssessment.opts = opts;

        if ($('#assessment-results-tab').length) {
            ManualAssessment.$container = $('#input-assessment');
            ManualAssessment.$mainForm = $('#main-form');
            ManualAssessment.$scrollContainer = $('.manual-assessment-container');
            ManualAssessment.setBindings();
        }
    },

    /**
     *
     * @param {object} data
     */
    setDefinitionConfig: function(data) {
        this.assessmentDefinitionConfig = data;
    },

    /**
     * in order to update two ways, we have to set hidden input fields with the
     * correct data for both questions and answers then submit main-form
     */
    applyChanges: function() {
        var self = ManualAssessment;

        // first, set input elements with correct data
        // set `assessment_questions` and `assessment_answers` and clear `assessment_drop_data
        // so we only send one source of truth to the backend
        self.$mainForm.find('input[name="assessment_questions"]').val(JSON.stringify(self.prepareQuestionsForTransport()));
        self.$mainForm.find('input[name="assessment_answers"]').val(JSON.stringify(self.prepareAnswersForTransport(self.includeUnchanged)));
        self.$mainForm.find('input[name="assessment_drop_data"]').val(null); //we don't want drop data to overwrite input data

        // only send over active statuses for things that have changed
        $('input[name^="assessment_students\["][name$="\]\[active\]"]').each(function() {
            var elem = $(this);
            var studentId = elem.attr('name').replace('assessment_students[', '').replace('][active]', '');
            var allAssessmentStudents = [].concat(self.enrollment).concat(self.students);

            _.chain(allAssessmentStudents)
                .filter({student_id: studentId})
                .each(function(assessmentStudent) {
                    if (!assessmentStudent.activeChanged) {
                        $(elem).remove();
                    }
                })
                .value();
        });
    },

    prepareQuestionsForTransport: function() {
        var arrayForTransport = [['Question Number', 'Question Name', 'Correct Answer', 'Point Value', 'Objective Code', 'Objective Description', 'Tags', 'Weight']];

        _.each(this.questions, function(question) {
            let $objectiveIdInput = $('[data-question-num="' + question.question_number + '"]').find('input[data-key="objective_id"]');
            let objectiveId = $objectiveIdInput.val();
            let objectiveCode = null;
            let objectiveDescription = null;

            if (objectiveId && objectiveId.length) {
                // this is the normal case: they selected an objective from the
                // dropdown so grab the corresponding code for this id
                let objective = ManualAssessment.getObjectiveFromId(objectiveId) || {};
                objectiveCode = objective.code;
                objectiveDescription = objective.description;
            } else if (question.objective
                        && !question.objective.objective_id
                        && (question.objective.code || question.objective.description)) {
                // this handles the "Create new standards upon import" setting.
                // the server parsed out a code and/or description but it
                // doesn't exist in the database yet so it has no id and nothing
                // is selected in the dropdown. just send over whatever the
                // server parsed.
                objectiveCode = question.objective.code;
                objectiveDescription = question.objective.description;
            }

            arrayForTransport.push([
                question.question_number + 1,
                question.question_name || question.question_number + 1,
                question.correct_answer || '',
                question.point_value + '',
                objectiveCode || '',
                objectiveDescription || '',
                ManualAssessment.getQuestionTags(question),
                ManualAssessment.getQuestionWeight(question)
            ]);
        });
        return arrayForTransport;
    },

    prepareAnswersForTransport: function(includeUnchanged) {
        var self = ManualAssessment;

        self.includeAverageScore = self.assessmentDefinitionConfig.manualAssessmentOptions.includeAvg;
        self.arrayForTransport = !self.includeAverageScore
            ? [
                ['Schoolrunner - Simple Import v1', 'Question:'],
                ['Scholar Number', 'Answer/Point Value']
            ]
            : [
                ['Schoolrunner - Simple Import v1', '', 'Question:'],
                ['Scholar Number', 'Overall Score', 'Answer/Point Value']
            ];

        // add question config
        _.each(self.questions, function(question, index) {
            self.arrayForTransport[0].push(question.question_number + 1);
            self.arrayForTransport[1].push(question.correct_answer || question.point_value);
        });

        var allStudents = self.getAllStudentsWithCourseAndSectionPeriodIds();

        // now students from enrollment
        _.each(self.enrollment, function(student) {
            self.addAnswersToArrayForTransport(student, includeUnchanged, allStudents);
        });

        // now add manually entered students
        _.each(self.students, function(student) {
            self.addAnswersToArrayForTransport(student, includeUnchanged, allStudents);
        });

        return self.arrayForTransport;
    },

    addAnswersToArrayForTransport: function(student, includeUnchanged, allStudents) {
        var self = ManualAssessment;

        if (!includeUnchanged && !student.changed && student.assessment_student_id && !self.includeAverageScore) {
            return;
        }

        // if I'm only showing my sections when I hit submit, only change data
        // for my students
        var isVisible = _.find(allStudents, s => s.student_id == student.student_id);

        if (!isVisible && !includeUnchanged) {
            return;
        } else if (!self.canISeeSchool(student.school_id)) {
            return;
        }

        // build student info/answers array
        var studentData = [student.school_id_code];
        if (self.includeAverageScore) {
            studentData.push(student.avg_score);
        }
        studentData.push(student.display_name);

        _.each(ManualAssessment.questions, function(question, i) {
            var answer = (student.answers || [])[i];
            studentData.push(!_.isNull(answer) && !_.isUndefined(answer) ? String(answer) : '');
        });

        //store answers array
        ManualAssessment.arrayForTransport.push(studentData);
    },

    resetContainer: function() {
        ManualAssessment.$scrollContainer.empty();
    },

    setIncludeUnchanged: function () {
        if (Assessment.fillingInForm) {
            return;
        }

        ManualAssessment.includeUnchanged = true;
    },

    saveInput: function() {
        //get data
        var self = ManualAssessment,
            $this = $(this),
            saveData = {
                inputValue: $this.val(),
                object: $this.data('object'),
                key: $this.data('key'),
                schoolIdCode: $this.data('school-id-code'),
                index: $this.data('index'),
                questionNum: $this.parent().data('question-num')
            };

        //determine where to save
        if (saveData.schoolIdCode || saveData.object == 'students' || saveData.object == 'enrollment') {
            self.saveStudentAnswer(saveData);
        } else {
            self.saveQuestion(saveData);
        }

        //highlight incorrect answers
        ManualAssessment.highlightIncorrectAnswers();
    },

    addQuestion: function($element) {
        var numQuestions = 1;
        if($element) {
            var $popover = $element.closest('.tss-popover'),
                $input = $popover.find('input');
            if($input.length) {
                numQuestions = $input.val();
                numQuestions = numQuestions && numQuestions > 0 ? numQuestions : 1;
            }

            $popover.removeClass('open');
        }

        //add new question
        var maxQuestionName = this.getMaxQuestionName();
        for(var i = 0; i < numQuestions; i++) {
            maxQuestionName += 1;
            this.questions.push({
                question_number: this.questions.length,
                question_name: maxQuestionName,
                point_value: 100
            });
        }

        //render
        this.renderTemplateFromData();
    },

    saveQuestion: function(saveData) {
        //ignore if not changed
        if(this.questions[saveData.index][saveData.key] == saveData.inputValue) { return; }

        //save value
        this.questions[saveData.index][saveData.key] = saveData.inputValue;

        //update all error classes on inputs...
        if(saveData.key == 'correct_answer') {
            ManualAssessment.highlightIncorrectAnswers();
        }

    },

    saveStudentAnswer: function(saveData) {
        //if no student id found, return
        if (!saveData.schoolIdCode) {
            return;
        }

        var newArr = [],
            self = this;

        _.each(this[saveData.object], function(student) {
            var oldAnswers;

            if (student.school_id_code == saveData.schoolIdCode) {
                if (saveData.key === 'avg_score') {
                    student.avg_score = saveData.inputValue;
                } else {
                    // make sure answers arrays exist!
                    student.answers = student.answers || [];

                    // clone so we can look at changes later
                    var oldAnswers = _.clone(student.answers);

                    // update this one answer
                    student.answers[saveData.questionNum] = saveData.inputValue;

                    // set active if was inactive and now has answers
                    // set changed if answers != origAnswers
                    self.handleAnswersChange(student, oldAnswers);
                }
            }

            newArr.push(student);
        });

        this[saveData.object] = newArr;
    },

    /**
     * Returns if question is multiple choice
     * @param {int} questionNum
     * @returns {boolean}
     */
    questionIsMultipleChoice: function(questionNum) {
        var question = (this.questions || {})[questionNum] || {};
        return !!question.correct_answer;
    },

    setStudentAnswers: function(schoolIdCode, newAnswers) {
        // if no student id found, return
        if (!schoolIdCode) {
            return;
        }

        // get the student
        var key = {school_id_code: String(schoolIdCode)},
            studentToModify = _.find(this.enrollment, key) || _.find(this.students, key);

        // check if student exists
        if (!studentToModify) {
            return; // FIXME add to this.students?
        }

        // Look for changes the active state based on answers:
        var oldAnswers = _.clone(studentToModify.answers || []);
        studentToModify.answers = newAnswers;

        this.handleAnswersChange(studentToModify, oldAnswers);

        return studentToModify;
    },

    isChanged(oldAnswers, newAnswers) {
        oldAnswers = oldAnswers || []
        newAnswers = newAnswers || []

        var maxLen = Math.max(oldAnswers.length, newAnswers.length)

        for (var i = 0; i < maxLen; i++) {
            var oldAnswer = i < oldAnswers.length ? (oldAnswers[i] + '').toLowerCase() : '';
            var newAnswer = i < newAnswers.length ? (newAnswers[i] + '').toLowerCase() : '';

            if (oldAnswer != newAnswer) {
                return true;
            }
        }

        return false;
    },

    /**
     * If the student is inactive, and new responses are supplied,
     * we should activate the assessment student.
     * @param  object assessmentStudent
     * @param  array  oldAnswers
     */
    handleAnswersChange: function(assessmentStudent, oldAnswers) {
        var self = ManualAssessment;
        var newAnswers = assessmentStudent.answers || [];

        // on the first change to this student's answers,
        // set origAnswers so we can detect if
        // this record is changed and thus we should save it
        assessmentStudent.origAnswers = assessmentStudent.origAnswers || oldAnswers;

        var answersChanged = self.isChanged(oldAnswers, newAnswers);
        var changedFromOrig = self.isChanged(assessmentStudent.origAnswers, newAnswers);
        var active = parseInt(assessmentStudent.active, 10);

        assessmentStudent.changed = changedFromOrig; // only save changed rows

        // See if student is inactive and inputValue has length.
        //  - activate student
        if (!active) {
            var inputHasLength = _.some(newAnswers, function (answer) {
                answer = answer + '';
                return answer && answer.trim().length;
            });

            if (inputHasLength && answersChanged) {
                assessmentStudent.active = 1;

                // toggle checkmarks in view:
                var checkmarkSelector = '[assessment-student-active-status]'
                    + '[data-student-id="' + assessmentStudent.student_id + '"]';
                $(checkmarkSelector).each(Assessment.toggleStudentActiveStatus);
            }
        }
    },

    getRowIndex: function(element) {
        return element.index();
    },

    addStudent: function(element, value) {
        var newSchoolIdCode = value || element.val(),
            newStudent,
            option,
            newDisplayName,
            newStudentId,
            existing;

        if (!newSchoolIdCode || !newSchoolIdCode.length) {
            return false;
        }

        //check for existing...
        option = element.find('[value="' + newSchoolIdCode + '"]');
        newDisplayName = option.html();
        newStudentId = option.data('student_id');
        existing = _.find(this.students, {school_id_code: newSchoolIdCode}) || _.find(this.enrollment, {school_id_code: newSchoolIdCode});

        if (!existing) {
            //build and add new student object
            newStudent = {
                inStudents: true,
                school_id_code: newSchoolIdCode,
                display_name: newDisplayName,
                student_id: newStudentId,
                answers: []
            };
            this.students.push(newStudent);

            //render
            this.renderTemplateFromData();
        }

        //set to blank and focus on first input
        element.val('').change();
        $('input[data-school-id-code="' + newSchoolIdCode + '"]').first().scrollintoview().focus();
    },

    setQuestionObjective: function(element) {
        //get question number and objective code
        var questionNum = element.closest('.question').data('question-num'),
            objective_id = element.val()[0];

        //validate question exists in questions array
        if (!this.questions[questionNum]) {
            return;
        }

        //set question objective code and description
        this.questions[questionNum].objective_id = objective_id;
        this.questions[questionNum].objective = {
            description: element.find('option:selected').text(),
            code: objective_id
        };

        //render
        this.renderTemplateFromData();
    },

    clearAllStudentAnswers: function() {
        _.each(this.students, function(student, i) {
            ManualAssessment.students[i].answers = [];
        });
    },

    clearQuestionAnswers: function(element) {
        //get question number
        var questionNum = element.closest('.col').data('question-num');

        //remove from students array
        this.deleteQuestionAnswers(questionNum);

        //render
        this.renderTemplateFromData();
    },

    deleteQuestionColIntent: function(element) {
        //get question number
        var questionNum = element.closest('.col').data('question-num');

        //if last question, reset questions array
        if (questionNum == 0 && this.questions.length == 1) {
            this.resetQuestionsArray();
        } else {
            this.deleteQuestionCol(questionNum);
        }

        //remove from students array
        this.deleteQuestionAnswers(questionNum);

        //render
        this.renderTemplateFromData();
    },

    deleteQuestionCol: function(questionNum) {
        var newQuestionsArray = [],
            count = 0;

        _.each(this.questions, function(question, index) {
            //make sure this isn't the question to be deleted
            if (index != questionNum) {
                question.question_number = count;
                newQuestionsArray.push(question);
                count++;
            }
        });

        //set questions array
        this.questions = newQuestionsArray;
    },

    deleteQuestionAnswers: function(questionNum) {
        _.each(this.students, function(student, index) {
            if (ManualAssessment.students[index].answers) {
                ManualAssessment.students[index].answers.splice(questionNum, 1);
            }
        });
        _.each(this.enrollment, function(student, index) {
            if (ManualAssessment.enrollment[index].answers) {
                ManualAssessment.enrollment[index].answers.splice(questionNum, 1);
            }
        });
    },

    deleteStudentRowIntent: function(element) {
        //get student index
        var studentIndex = element.data('index');

        //delete student row
        this.deleteStudentRow(studentIndex);

        //render
        this.renderTemplateFromData();
    },

    deleteStudentRow: function(studentIndex) {
        var newStudentsArray = [];

        _.each(this.students, function(student, index) {
            //make sure this isn't the question to be deleted
            if (index != studentIndex) {
                newStudentsArray.push(student);
            }
        });

        //set questions array
        this.students = newStudentsArray;
    },

    resetQuestionsArray: function() {
        this.questions = [{
            question_number: 0,
            point_value: this.defaultPointValue
        }];
    },

    saveEnrollment: function(data) {
        //verify enrollment actually changed
        var courseId = $('select[name="course_id[]"]').val(),
            asof = $('input[name="date"]').val(),
            staffMemberId = $('input[name="staff_member_id"]').val(),
            sectionIds = Assessment.getSelectedSections();

        //make sure we have a results object
        data.results = data.results ? data.results : {};

        //want to make sure that we intend to save the enrollment
        if (((this.courseId == courseId && this.asof == asof && this.staffMemberId == staffMemberId && _.isEqual(this.sectionIds, sectionIds))
                || !data.results) && !this.forceSaveEnrollment) {
            this.forceSaveEnrollment = false;
            return;
        }

        //save for later comparison
        this.courseId = courseId;
        this.asof = asof;
        this.staffMemberId = staffMemberId;
        this.sectionIds = sectionIds;
        this.sectionStudents = data.results.sectionStudents;

        //get student data
        var students = data.results.students ? data.results.students : [];

        //merge enrollment
        this.mergeEnrollment(students);

        //render
        this.renderTemplateFromData();
    },

    answerMeetsTarget: function(questionData, studentAnswer) {
        var target = parseInt(questionData.correct_answer);
        studentAnswer = parseInt(studentAnswer);

        return _.isNaN(target) ? true : studentAnswer >= target;
    },

    isCorrectAnswer: function(questionData, studentAnswer) {
        //parse to string
        var correctAnswer = String(questionData ? questionData.correct_answer || '' : '');
        studentAnswer = String(studentAnswer);

        if (correctAnswer.length === 0) {
            return true;
        }

        return (correctAnswer.toLowerCase() === studentAnswer.toLowerCase());
    },

    setStudentArrayKeys: function(students, objectKey) {
        if (typeof students != 'array') {
            return students;
        }

        //iterate
        var newStudentsArray = [];
        _.each(students, function(student, i) {
            if (students[objectKey]) {
                newStudentsArray[students[objectKey]] = student;
            }
        });

        return students;
    },

    /**
     * Rendering
     */

    renderTemplateFromData: function(force) {
        var self = this;

        // update the download popover when questions change
        $('[name="number_of_questions"]').val(Math.max(this.questions.length, 1));

        if (!force && !$('#scan-assessment').is(':visible') && !$('#input-assessment').is(':visible')) {
            return;
        }

        //we have to have at least one question to render properly
        if (self.questions.length === 0) {
            self.addQuestion();
        }

        // section breakout
        var allStudents = self.getAllStudentsWithCourseAndSectionPeriodIds();
        var sectionStudents = [];
        var sectionStudentIds = [];

        if (self.config.groupStudentsBySection) {
            _.each(self.sectionStudents, function(section, sectionId) {
                var data = {
                    sortOrder: section.sort_order,
                    displayName: section.display_name,
                    students: _.filter(allStudents, function(student) {
                        return _.find(section.students, x => x == student.student_id);
                    }),
                };

                // if we've filtered out to only my sections,
                // don't show empty section headers for other people's sections
                if (data.students && data.students.length) {
                    sectionStudents.push(data);
                }

                // keep track of students in buckets
                sectionStudentIds = _.union(sectionStudentIds, _.map(data.students, 'student_id'));
            });

            // bucket left over students
            var sectionlessStudents = _.filter(allStudents, function(student) {
                return !_.find(sectionStudentIds, x => x == student.student_id);
            });

            // add left over students to "No Section" bucket
            if (sectionlessStudents.length) {
                sectionStudents.push({
                    displayName: 'No Section',
                    students: sectionlessStudents
                });
            }
        } else {
            // No bucketing:
            // Now, sort the students in alpha order b/c we have munged the api response.
            allStudents = _.sortBy(allStudents, function(student) {
                return student.display_name;
            });

            sectionStudents.push({
                displayName: 'All Sections',
                students: allStudents
            });
        }

        // Sort buckets, in alpha order, then students in alpha order.
        sectionStudents = _.sortBy(sectionStudents, function(section) {
            return section.sortOrder;
        });

        //render students
        var renderingData = {
            questions: self.questions,
            sectionStudents: sectionStudents,
            renderedStudentsPicklist: self.renderedStudentsPicklist,
            schoolStudentStr: Assessment.schoolStudentStr,
            disableDefinitionInputs: self.opts.allSchoolsICanAccessById
                ? 'disabled'
                : '',
        };
        var renderedTemplate = Handlebars.renderTemplate(self.assessmentDefinitionConfig.templates.input, renderingData);

        self.$scrollContainer[0].innerHTML = renderedTemplate; //faster than $.html

        //highlight incorrect answers
        self.highlightIncorrectAnswers();

        self.validateCountsForGradesFromState();
    },

    getAllStudentsWithCourseAndSectionPeriodIds: function() {
        var self = this;
        var allStudents = self.getAllStudentsICanSee();
        var allCourseIds = Assessment.getAllSelectedCourses();
        var defaultCourseId = allCourseIds && allCourseIds.length == 1
            ? allCourseIds[0]
            : null;

        // if there's only one course, just make sure every student is assigned
        // to that course
        _.each(allStudents, function(student) {
            student.courseId = defaultCourseId;
        });

        // assign section period and course IDs to students so they get
        // rendered in the template and sent to the server to be saved
        // on assessment student records
        _.each(self.sectionStudents, function(section, sectionId) {
            var oneSectionPeriodDisplayMap = Assessment.getSectionPeriodDisplayMapForSectionId(sectionId);
            var sectionPeriodId = _.get(oneSectionPeriodDisplayMap, 'section_period_id');
            var courseId = _.get(oneSectionPeriodDisplayMap, 'course_id');
            var staffMemberId = _.get(oneSectionPeriodDisplayMap, 'staff_member_id');

            _.each(allStudents, function(student) {
                if (_.find(section.students, x => x == student.student_id)) {
                    student.sectionPeriodId = sectionPeriodId;
                    student.courseId = courseId;
                    student.staffMemberId = staffMemberId;
                }
            });
        });

        if (self.getTeacherSectionsOnly()) {
            var filteredStudents = _.filter(allStudents, student => {
                return student.staffMemberId == self.opts.loggedInStaffMemberId;
            });

            // if none of these sections are mine, show all
            if (filteredStudents && filteredStudents.length) {
                allStudents = filteredStudents;
            }
        }

        return allStudents;
    },

    getTeacherSectionsOnly: function() {
        return $('input[type="checkbox"][name="teacher_sections_only"]').is(':checked');
    },

    handleTeacherSectionsOnlyChanged: function() {
        ManualAssessment.renderTemplateFromData();
    },

    updateSelectedObjectives: function() {
        var $inputs = $('.manual-assessment-row.objective').find('.input');

        $inputs.each(function() {
            var $input = $(this),
                $select = $input.find('select'),
                questionIndex = $input.data('question-num'),
                question = ManualAssessment.questions[questionIndex],
                objectiveId = question.objective_id,
                objectiveCode = question.objective ? question.objective.code : null;

            if(objectiveCode) {
                objectiveId = $select.find('option[data-code="' + objectiveCode + '"]').attr('value');
            }
            $select.val(objectiveId).change();

        });
    },

    /**
     *
     * @param {object} data
     */
    saveImportData: function(data) {
        //first, we have to get our incoming data in the correct format
        this.questions = [];
        this.sectionStudents = data.sectionStudents;

        //questions
        _.each(data.assessmentQuestions, function(value, i) {
            var valueCopy = $.extend(true, {}, value);

            valueCopy['question_number'] = i - 1;

            if (valueCopy.objective) {
                valueCopy['objective_id'] = valueCopy.objective_id;
            }

            ManualAssessment.questions.push(valueCopy);
        });

        //if answers and more assessment answers as in students array, build students from data
        if (Object.keys(data.assessmentAnswers).length !== 0) {
            //reset students array
            this.students = [];

            //iterate
            _.each(data.assessmentAnswers, function(assessmentAnswer, i) {
                //get all student data in correct format
                var studentId = assessmentAnswer.student_id,
                    student = assessmentAnswer;

                // Format student answers
                student.answers = _.chain(student.answers || [])
                    .map(answer => answer || {})
                    .map(answer => _.isEmpty(answer.answer) ? answer.points : answer.answer)
                    .value()

                //try to update enrollment first, if not add the student to the students array
                if (!ManualAssessment.updateEnrollmentAnswers(studentId, student)) {
                    student.inStudents = true;
                    ManualAssessment.students.push(student);
                }

            });
        }

        ManualAssessment.validateCountsForGradesFromState();
    },

    studentHasAnswers: function(studentData) {
        //answers start at third cell
        if (!studentData || studentData.length < 3) {
            return false;
        }

        //iterate, return true as soon as we see an answer
        for (var i = 2, count = studentData.length; i < count; i++) {
            if (String(studentData[i]).length > 0) {
                return true;
            }
        }

        return false;
    },

    getStudentAnswerVal: function(data, studentId, questionNum, answer) {
        var gradeByLevel = $('[name="enter_grades_by_level"]').val();

        if(gradeByLevel) {
            return answer[1] || answer[0];
        }

        if (!data.assessmentQuestions[questionNum] || !data.assessmentQuestions[questionNum].correct_answer) {
            return answer[0];
        }
        return answer[1];
    },

    /**
     * Returns largest integer value from question names.
     * @return int
     */
    getMaxQuestionName: function() {
        var max = 0;

        _.each(this.questions, function(question) {
            var nameInt = parseInt(question.question_name, 10);
            if (nameInt > max) {
                max = nameInt;
            }
        });

        return max;
    },

    getQuestionTags: function(question) {
        //this will exist if the tags were modified using manual input
        if(!_.isUndefined(question.tags)) {
            return question.tags;
        }

        if(!question.questionTags) {
            return '';
        }

        //iterate and get all question tags
        var tags = [];
        _.each(question.questionTags, function(questionTag) {
            tags.push(questionTag.tag);
        });

        return tags.join(', ');
    },

    getQuestionWeight: function(question) {
        //this will exist if the weight was modified using manual input
        if(!_.isUndefined(question.weight)) {
            return question.weight;
        }

        if(!question.questionTags) {
            return '';
        }

        //iterate and get all question tags
        var weight = '';
        _.each(question.questionTags, function(questionTag) {
            if(questionTag.weight && String(questionTag.weight).length !== 0) {
                weight = questionTag.weight;
            }
        });

        return weight;
    },

    getAllStudents: function () {
        var allStudents = this.enrollment.slice(0).concat(this.students);
        return allStudents;
    },

    getAllStudentsICanSee: function() {
        var self = this;
        var allStudents = self.getAllStudents();

        return _.filter(allStudents, s => self.canISeeSchool(s.school_id));
    },

    /**
     * Reacts to changes in sections associated with the assessment.
     * We only care about enrolled students or students with answers.
     * @param  array students Array of \Student objects.
     */
    mergeEnrollment: function(students) {
        //get mapped enrollment and student arrays
        //get merged array of all current students
        var mappedEnrollment = _.indexBy(this.enrollment, 'student_id'),
            mappedStudents = _.indexBy(this.students, 'student_id'),
            mergedStudents = _.merge(mappedEnrollment, mappedStudents),
            newEnrollment = [],
            mappedNewEnrollment,
            newStudents = [];

        // Keep students enrolled in the selected sections (students parameter)
        _.each(students, function(student) {
            if (mergedStudents[student.student_id]) {
                mergedStudents[student.student_id].inStudents = false;
                newEnrollment.push(mergedStudents[student.student_id]);
            } else {
                // Convert this into an assessment student record.
                // FIXME: this is not complete!
                student.answers = [];
                newEnrollment.push(student);
            }
        });
        mappedNewEnrollment = _.indexBy(newEnrollment, 'student_id');

        // Compare existing assessment students to enrolled student ids,
        // keeping enrolled || has answers || has assessment student record, only.
        _.each(_.keys(mergedStudents), function(studentId) {
            var onlyEmptyResponses = true;

            if (!mappedNewEnrollment[studentId]) {
                onlyEmptyResponses = _.every(mergedStudents[studentId].answers, function(answer) {
                    return answer === null || answer === '';
                });
                if (onlyEmptyResponses && !(parseInt(mergedStudents[studentId].assessment_student_id) || 0)) {
                    // drop this student -- no responses, no longer in enrollment, no stored record in db.
                } else {
                    mergedStudents[studentId].inStudents = false;
                    newEnrollment.push(mergedStudents[studentId]);
                }
            }
        });

        // set
        this.enrollment = newEnrollment;
        this.students = newStudents;
    },

    /**
     * @param  int-ish studentId
     * @param  object  newAssessmentStudent
     * @return bool Whether or not this student was found in the enrollments array.
     */
    updateEnrollmentAnswers: function(studentId, newAssessmentStudent) {
        var found = false,
            newAnswers = newAssessmentStudent.answers || [];

        _.each(this.enrollment, function(student, i) {
            var oldAnswers;

            if (student.student_id == studentId) {
                if (newAnswers) {
                    oldAnswers = _.clone(student.answers || []);
                    $.extend(student, newAssessmentStudent);

                    // HACK: set in assessment view to tell us to update
                    // active status when answers for this student have
                    // changed via drag-n-drop
                    if (ManualAssessment.processingDropData) {
                        ManualAssessment.handleAnswersChange(student, oldAnswers);
                    }
                }
                found = true;
            }
        });

        return found;
    },

    getObjectiveFromId: function(objectiveId) {
        return _.find(this.objectives, {objective_id: objectiveId});
    },

    getObjectiveFromQuestion: function(question) {
        var questionObjective = question.objective || {};

        if(!questionObjective || !question.objective_id) { return null; }

        //first we try to find it in the cached objectives...
        var foundObjective = _.find(this.objectives, function(objective) {
            return (questionObjective.objective_id == objective.objective_id)
                    || (question.objective_id == objective.objective_id)
                    || (questionObjective.code && questionObjective.code == objective.code);
        });

        //next, if we can't find it, we'll use the question objective record (if exists)
        //and add it to the cached objectives
        if(!foundObjective && !_.isEmpty(questionObjective)) {
            foundObjective = questionObjective;
            this.objectives.push(foundObjective);
        }

        return foundObjective;
    },

    getObjectiveDisplayName: function(objective) {
        if(!objective) { return ''; }

        return objective.display_name && objective.display_name.length ? objective.display_name
                : ((objective.code && objective.code.length ? '[' + objective.code + '] ' : '') + objective.description);
    },

    getObjectiveCodeFromId: function(objectiveId) {
        var objective = this.getObjectiveFromId(objectiveId);
        return objective ? objective.code : '';
    },

    highlightIncorrectAnswers: function() {
        $.each(ManualAssessment.$container.find('input.response'), function() {
            var $this = $(this);

            if($this.hasClass('ignore-if-incorrect')) { return; }

            var questionNum = $this.parent().data('question-num'),
                val = $this.val(),
                isCorrect = $this.hasClass('is-target')
                            ? ManualAssessment.answerMeetsTarget(ManualAssessment.questions[questionNum], val)
                            : ManualAssessment.isCorrectAnswer(ManualAssessment.questions[questionNum], val);

            $this[isCorrect ? 'removeClass' : 'addClass']('error');
        });
    },

    handleKeydown: function(e) {
        //enter
        if(e.keyCode === 13) {
            //prevent submit
            e.preventDefault();

            //save input
            ManualAssessment.saveInput.apply(this);

            //apply
            Assessment.triggerMainFormSubmit(true);
        } else if (_.indexOf(ManualAssessment.traverseKeyCodes, e.keyCode) !== -1){
            //traverse
            ManualAssessment.handleTraverse(e);
        }
    },

    handleFillDown: function(e) {
        var self = ManualAssessment,
            $this = $(this),
            $rows = ManualAssessment.$scrollContainer.find('.row'),
            $col = $this.closest('.col'),
            rowIndex = $this.closest('.row').index(),
            colIndex = $col.index(),
            val = $col.find('input').val();

        e.preventDefault();
        for(var rowNum = rowIndex + 1; rowNum < $rows.length; rowNum++) {
            var $input = $rows.eq(rowNum).find('.col').eq(colIndex).find('input.response');
            ManualAssessment.saveStudentAnswer({
                inputValue: val,
                object: $input.data('object'),
                key: $input.data('key'),
                schoolIdCode: $input.data('school-id-code'),
                questionNum: colIndex - (
                    self.assessmentDefinitionConfig.manualAssessmentOptions.includeAvg ? 2 : 1
                )
            });
            $input.val(val).addClass("fill");
        }
        _.delay(function () {
            $rows.find("input").removeClass("fill");
        }, 500);
        ManualAssessment.highlightIncorrectAnswers();
    },

    handleFillRight: function(e) {
        var $this = $(this),
            $row = $this.closest('.row'),
            $cols = $row.find('.col'),
            $col = $this.closest('.col'),
            colIndex = $col.index(),
            val = $col.find('input').val();

        e.preventDefault();
        for(var colNum = colIndex + 1; colNum < $cols.length; colNum++) {
            var $input = $cols.eq(colNum).find('input[type="text"]');
            ManualAssessment.saveQuestion({
                inputValue: val,
                object: $input.data('object'),
                key: $input.data('key'),
                schoolIdCode: $input.data('school-id-code'),
                index: $input.data('index'),
                questionNum: $input.parent().data('question-num')
            });
            $input.val(val).addClass("fill");
        }
        _.delay(function () {
            $cols.find("input").removeClass("fill");
        }, 500);
    },

    handleTraverse: function(e) {
        e.preventDefault();
        var $this = $(e.target),
            $row = $this.closest('.row'),
            cellNum = $this.closest('.col').index(),
            $targetRow = $row, targetCellNum = cellNum;

        switch(e.keyCode) {
            case 37: //left
                targetCellNum = cellNum - 1;
                break;
            case 38: //up
                $targetRow = $row.prevAll('.manual-assessment-row:first');
                break;
            case 39: //right
                targetCellNum = cellNum + 1;
                break;
            case 40: //down
                $targetRow = $row.nextAll('.manual-assessment-row:first');
                break;
        };

        var $targetCell = $targetRow.find('.col').eq(targetCellNum),
            $targetInput = $targetCell.find('.tss-select-search-btn');
        $targetInput = $targetInput.length ? $targetInput : $targetCell.find('input');

        //objective code
        if($targetInput.data('key') === 'objective_code') {
            ManualAssessment.handleClickObjectiveInput.apply($targetInput.closest('.objective-wrapper'));
        } else if($targetInput.length) {
            $targetInput.focus().selText().select();
        }
    },

    handleToggleExcludeRow: function(e) {
        e.preventDefault();
        var $this = $(this),
            $row = $this.closest('.row');
        $row.find('[rel="assessment-student-toggle-active"]').click();
    },

    saveObjectives: function(results) {
        this.objectives = results;
        this.renderObjectivesPicklist();
    },

    renderObjectivesPicklist: function() {
        var $tempDiv = $('<div />');
        var groupKey = this.config.groupObjectivesByParent
            ? 'parent_objective.display_name'
            : '';
        var optionsAndGroups = Functions.getGroupedOptions(this.objectives, groupKey);

        $tempDiv.append(Handlebars.renderTemplate('manual-assessment-objectives-picklist', optionsAndGroups));
        $tempDiv.find('select').tssSelectSearch();
        this.$objectivesPicklist = $tempDiv.find('.tss-select-search');

        var $select = this.$objectivesPicklist.find('select');

        $select.off('tss.select.is.closed').on('tss.select.is.closed', this.handleObjectivePicklistClose);
    },

    handleClickObjectiveInput: function(e) {
        var $parent = $(this),
            $input = $parent.find('input[type="text"]'),
            objectiveCode = $input.val(),
            objectiveId = $parent.find('input[type="hidden"]').val() || initialOptions.objective_id,
            $select = ManualAssessment.$objectivesPicklist.find('select');

        if ($input.is('[disabled]')) {
            return;
        }

        //close any open select and set value of its hidden input
        $select.trigger('tss.select.close');

        //hide placeholder
        $input.addClass('hidden');

        //choose value of the new select, if possible
        $select.val(objectiveId).change();
        if(!$select.val()) {
            objectiveId = $select.find('option').filter(function () { return $(this).html() == objectiveCode; }).val();
        }

        //append and open select
        $parent.append(ManualAssessment.$objectivesPicklist);
        $select.trigger('tss.select.open');
    },


    handleObjectivePicklistClose: function(e) {
        var $parent = ManualAssessment.$objectivesPicklist.parent(),
            $input = $parent.find('input[type="text"]'),
            $hiddenInput = $parent.find('input[type="hidden"]'),
            val = ManualAssessment.$objectivesPicklist.find('select').val(),
            text = ManualAssessment.$objectivesPicklist.find('option[value="' + val + '"]').text().trim();

        //detach
        ManualAssessment.$objectivesPicklist.detach();

        //set input values
        $hiddenInput.val(val).change();
        $input.val(text).attr('title', text);

        //show placeholder
        $input.removeClass('hidden');
    },

    setBindings: function() {
        $('[data-tab="input-assessment"]').click(function() {
            ManualAssessment.renderTemplateFromData(true);
        });

        $('[rel="manual-assessment-add-question"]').on('click', function(e) {
            ManualAssessment.addQuestion($(this));
        });

        $('#input-assessment').on('click', '[rel="clear-question-answers"]', function(e) {
            e.preventDefault();

            if(window.confirm('Are you sure you want to clear all answers?')) {
                //delete question column
                ManualAssessment.clearQuestionAnswers($(this));
            }
        })
        .on('click', '[rel="delete-student-row"]', function(e) {
            e.preventDefault();

            if(window.confirm('Are you sure you want to remove this student and all associated answers?')) {
                //delete question column
                ManualAssessment.deleteStudentRowIntent($(this));
            }
        })
        .on('click', '[rel="delete-question-col"]', function(e) {
            e.preventDefault();

            if(window.confirm('Are you sure you want to remove this question and all associated answers?')) {
                //delete question column
                ManualAssessment.deleteQuestionColIntent($(this));
            }
        })
        .on('change', 'input.response, input.question, input[data-key="objective_id"]', ManualAssessment.saveInput)
        // if you changed the question, update student assessment answers on save
        // this is also done in assessment.php on assessment import
        .on('change', 'input.question', ManualAssessment.setIncludeUnchanged)
        .on('change', 'input[data-key="objective_id"]', ManualAssessment.validateCountsForGradesFromState)
        .on('keydown', 'input.response, input.question', ManualAssessment.handleKeydown)
        .on('change', '[rel="manual-assessment-add-student"]', function(e) {
            //set row school id code
            ManualAssessment.addStudent($(this));
        })
        .on('mousedown', '[rel="fill-down"]', ManualAssessment.handleFillDown)
        .on('mousedown', '[rel="fill-right"]', ManualAssessment.handleFillRight)
        .on('click', '.objective-wrapper', ManualAssessment.handleClickObjectiveInput)
        .on('keydown', 'input.response', 'shift+x', ManualAssessment.handleToggleExcludeRow)
        .on('change', '[type="checkbox"][name="teacher_sections_only"]', ManualAssessment.handleTeacherSectionsOnlyChanged);

        $(document).on('click', '[rel="manual-assessment-apply"]', function(e) {
            e.preventDefault();

            //apply changes
            Assessment.triggerMainFormSubmit(true);
        });
    },

    /**
     * Sets any value for a student in enrollment
     * @param int studentId
     * @param string key
     * @param mixed value
     */
    setAssessmentAnswerValue: function(studentId, key, value) {
        var found = _.some(ManualAssessment.enrollment, function(student) {
            var retval = false;
            if (student.student_id == studentId) {
                student[key] = value;

                // if we manually set an active flag for a student, make a note
                // of that here so we can only send over those active statuses
                // when we save
                if (key == 'active') {
                    student.activeChanged = true;
                }

                retval = true;
            }
            return retval;
        });

        return found;
    },

    validateCountsForGrades: async function(force = false) {
        if (Assessment.fillingInForm) return;

        var self = ManualAssessment;
        var courseId = $(`select[name="school_ids[]"] option:selected[value="${self.opts.schoolId}"]`).data('courseId')
            || $('select[name="course_id[]"]').val();
        var oldCourseId = ManualAssessment.countsForGrades.courseId;

        self.countsForGrades.courseId = courseId;

        if (courseId && (courseId != oldCourseId || force)) {
            var url = '/api/v1/grading-methodology-buckets'
                + '?course_ids=' + courseId
                + '&term_ids=' + self.opts.termId
                + '&active=1'
                + '&term_bin_active=1'
                + '&expand=grading_methodology_bucket_contents.assessment_type,term_bin,course';

            self.showGradesAlert('spinner', 'Loading...', 'Checking to see if this assessment counts for grades...');

            const response = await Api.get(url);
            const buckets = _.get(response, 'results.grading_methodology_buckets');

            self.countsForGrades.buckets = buckets;
        }

        self.validateCountsForGradesFromState();
    },

    validateCountsForGradesFromState: function() {
        var self = ManualAssessment;

        // get assessment state for validation
        var assessmentTypeId = $('[name="assessment_type_id"]').val();
        var assessmentTypeName = $('[name="assessment_type_id"]').find('option[value="' + assessmentTypeId + '"]').text();
        var courseId = $('select[name="course_id[]"]').val();
        var isActive = $('[name="active"]').val() == 1;
        var courseName = $('select[name="course_id[]"]').find('option[value="' + courseId + '"]').text();
        var asof = dateStringToServer($('[name="date"]').val());
        var buckets = self.countsForGrades.buckets;

        // status messages
        var doesNotCountDesc = "Doesn't count for grades!";
        var partiallyCountsDesc = "Partially counts for grades!";
        var countsDesc = "Counts for grades!";

        var noAssessmentType = "Please select an assessment type.";
        var noCourse = "This assessment isn't tied to a course.";
        var inactiveAssessment = "This assessment is inactive.";
        var noMethodologies = "This course doesn't have any grading methodologies.";
        var noMethodologiesForDate = "This course doesn't have a grading methodology that includes " + dateStringToDisplay(asof) + ".";
        var typeNotInMethodology = assessmentTypeName + " isn't part of the grading methodology for %termbinnames%.";
        var noStandards = courseName + " is a standards-based course and there are no standards on this assessment.";
        var missingStandards = courseName + " is a standards-based course and there are missing standards on %numquestions%.";

        // validate date & assessment type for grading methodology buckets
        var bucketsByDate = _.chain(buckets)
            .each(gmb => gmb.term_bin_length = (serverToDate(gmb.term_bin.end_date) - serverToDate(gmb.term_bin.start_date)) / (1000 * 60 * 60 * 24))
            .sortBy('term_bin_length')
            .filter(gmb => asof >= gmb.term_bin.start_date && asof <= gmb.term_bin.end_date)
            .value();
        var termBinsForDate = _.chain(bucketsByDate)
            .map('term_bin.short_name')
            .uniq()
            .join(', ');
        var bucketsByAssessmentType = _.filter(
            bucketsByDate,
            gmb => _.filter(gmb.grading_methodology_bucket_contents, { assessment_type_id: assessmentTypeId }).length
        );
        var bucket = _.get(bucketsByAssessmentType, 0) || {};
        var assessmentTypeNames = _.chain(bucket.grading_methodology_bucket_contents)
            .filter(gmbc => gmbc.active == 1)
            .map(gmbc => gmbc.assessment_type.name)
            .reduce((result, name, i, names) => {
                return result
                    + (result ? (i == names.length - 1 ? ', & ' : ', ') : '')
                    + name;
            }, '');
        var countsForGradesExplain = 'The '
            + (bucket.display_name || assessmentTypeNames)
            + ' bucket counts for ' + bucket.weight + '% of '
            + _.get(bucket, 'term_bin.short_name')
            + ' grades.';

        // validate standards-based grading (if applicable to this course)
        var courseSbg = _.get(bucket, 'course.standards_based_grading')
        var schoolSbg = self.opts.standardsBasedGrading;
        var isSbg = courseSbg == 1 || (courseSbg === null && schoolSbg);
        var questionsWithStandards = _.filter(self.questions, row => row.objective_id || row.objective_code || row.objective_description);
        var numQuestionsMissingStandards = self.questions.length - questionsWithStandards.length;
        var numQuestionsDesc = displayItems(numQuestionsMissingStandards, 'question');

        // show a status message explaining why an assessment counts for grades (or not)
        if (!assessmentTypeId) {
            self.showGradesAlert('warning', doesNotCountDesc, noAssessmentType);
        } else if (!courseId) {
            self.showGradesAlert('warning', doesNotCountDesc, noCourse);
        } else if (!isActive) {
            self.showGradesAlert('warning', doesNotCountDesc, inactiveAssessment);
        } else if (!buckets) {
            return; // wait for buckets to come back
        } else if (!buckets.length) {
            self.showGradesAlert('warning', doesNotCountDesc, noMethodologies);
        } else if (!bucketsByDate.length) {
            self.showGradesAlert('warning', doesNotCountDesc, noMethodologiesForDate);
        } else if (!bucketsByAssessmentType.length) {
            self.showGradesAlert('warning', doesNotCountDesc, typeNotInMethodology.replace('%termbinnames%', termBinsForDate));
        } else if (isSbg && !questionsWithStandards.length) {
            self.showGradesAlert('warning', doesNotCountDesc, noStandards);
        } else if (isSbg && numQuestionsMissingStandards > 0) {
            self.showGradesAlert('warning', partiallyCountsDesc, missingStandards.replace('%numquestions%', numQuestionsDesc));
        } else {
            self.showGradesAlert('success', countsDesc, countsForGradesExplain);
        }
    },

    showGradesAlert: function(type, buttonText, detailedMessage) {
        var key = type + '_' + buttonText + '_' + detailedMessage;

        // only refresh the alert if it's something new so we don't
        // double-flash the checkmark for any reason
        if (ManualAssessment.countsForGrades.lastAlert == key) {
            return;
        }

        ManualAssessment.countsForGrades.lastAlert = key;

        var elem = $('#grades-alert-warning');
        var icon = '';
        var splash = '';
        var color = '';

        if (type == 'spinner') {
            color = 'gray';
            icon = 'spinner icon-spin';
        } else if (type == 'warning') {
            color = 'orange';
            icon = 'warning-sign';
        } else {
            color = 'green';
            icon = 'ok';
            splash = 'splash';
        }

        elem.find('i:first').replaceWith('<i class="icon-' + icon + ' ' + splash + '"></i>');
        elem.removeClass('btn-gray btn-orange btn-green').addClass('btn-' + color);
        elem.find('.popover-label').removeClass('splash').addClass(splash).html(buttonText);
        elem.find('.popover-desc').text(detailedMessage);
    },

    canISeeSchool: function(schoolId) {
        // if this is empty it means show all. if I really didn't have access
        // to any schools I wouldn't be able to load this page.
        if (!ManualAssessment.opts.allSchoolsICanAccessById) {
            return true;
        }

        return schoolId in ManualAssessment.opts.allSchoolsICanAccessById;
    },

};

window.ManualAssessment = ManualAssessment;

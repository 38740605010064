(function ($, window, undefined) {"use strict";

    window.Tss = window.Tss || {};
	var assessmentStudent = window.Tss.AssessmentStudent = {};

	assessmentStudent.config = {
		inputs: {},
		alerts: {}
	};

	assessmentStudent.init = function (id, data, form) {
		this.config.id = id;
		this.config.data = data;
		this.config.form = form;
		this.config.table = $("#assessment-student-table");
		this.config.alerts = $("#assessment-student-alerts");
		this.config.inputs.missing = this.config.form.find("input[name=\"missing\"]");
		this.config.inputs.active = this.config.form.find("input[name=\"active\"]");
		this.config.inputs.hasOverride = this.config.form.find("input[name=\"has_override\"]");
		this.config.inputs.scoreOverride = this.config.form.find("input[name=\"score_override\"]");
		this.config.alerts.hasOverride = this.config.alerts.find(".alert-has-override");
		this.config.alerts.missing = this.config.alerts.find(".alert-missing");
        this.defaultSeparatorValue = '|';
        this.originalAnswerValues = '';
        
		this.setBindings();
		this.setupHtml();
	};

	assessmentStudent.setBindings = function () {
		var self = this,
            answerInputs = this.getQuestionAnswerInputs(true);

		this.config.form.submit(onSubmit("/assessmentstudent/save/" + this.config.id));
		this.getQuestionAnswerInputs(true).keyup(function () {
			var showMissingAlert = self.showMissingAlert();
			self.config.alerts.missing.toggle(showMissingAlert);
		}).change(function() {
            // if inputsHasLength and answers != oldAnswers
            // then toggle active to true
            var newAnswers = self.getAnswerValues(self.defaultValueSeparator);
            
            if (self.originalAnswerValues != newAnswers && self.getAnswerValues('').length) {
                self.config.inputs.active.val(1).change();
            }
        });
		this.config.inputs.missing.change(function () {            
            var inputs = self.getQuestionAnswerInputs(),
           	showMissingAlert = self.showMissingAlert();
            inputs.prop("disabled", this.checked);
			self.config.alerts.missing.toggle(showMissingAlert);
        });
        this.config.inputs.hasOverride.change(function (e) {
        	var inputs = self.getQuestionAnswerInputs(),
        	showMissingAlert = self.showMissingAlert();
            self.config.inputs.scoreOverride.prop("disabled", !this.checked);
            self.config.inputs.missing.prop("disabled", this.checked)
            if (this.checked) {
            	self.config.inputs.missing.prop("checked", false);
            	inputs.prop("disabled", false);
            }
            self.config.alerts.hasOverride.toggle(this.checked);
            self.config.alerts.missing.toggle(showMissingAlert);
        });
	};

	assessmentStudent.setupHtml = function () {
		var self = this, showMissingAlert;
		_.each(this.config.data, function (value, key) {
            fillInForm(key, value, self.config.form);
        });
        if (!_.isNull(this.config.data.score_override)) {
        	this.config.inputs.hasOverride.click();
        }
		this.config.alerts.missing.toggle(this.showMissingAlert());
		_.delay(function () {
            self.config.table.trigger("update");
        }, 1);
        snapshotForChanges(this.config.form);
        this.originalAnswerValues = this.getAnswerValues(this.defaultSeparatorValue);
	};
    
    assessmentStudent.getAnswerValues = function(sep) {
        var answerInputs = this.getQuestionAnswerInputs(true);
        
        return answerInputs.map(function() { return $(this).val().trim(); }).get().join(sep);
    };

	assessmentStudent.getQuestionAnswers = function () {
		var answers = [], inputs = this.getQuestionAnswerInputs(true);
		inputs.each(function () {
			answers.push($(this).val());
		});
		return answers;
	};

	assessmentStudent.getQuestionAnswerInputs = function (excludeHidden) {
		var inputs = this.config.table.find(".assessment-student-answer").find("input");
		if (excludeHidden) {
			inputs = inputs.not("[type=\"hidden\"]");
		}
		return inputs;
	};

	assessmentStudent.showMissingAlert = function () {
		var answers = this.getQuestionAnswers(), hasAnswers = _.some(answers),
		missingSelected = this.config.inputs.missing.is(":checked"), hasOverrideSelected = this.config.inputs.hasOverride.is(":checked");
		return !hasAnswers && !missingSelected && !hasOverrideSelected;
	};

})(jQuery, window);

/**
 * Aysnc Task Manager 
 * 
 * A simple tool that displays a  window in the bottom right of the users browser
 * to track and update the user on long running tasks.
 *
 * Progress update gets called every second while the task is running. Your callback
 * should trigger the "update" event on the element passed to the callback with 
 * an object with a "current" integer and  a "total" integer. When progress is called 
 * internally it will pass the element which points to the progress bar. Therefore all update 
 * needs to do is call elem.trigger('update', {'current': <the number of data points processed>, 'total' <the total number of data points>}). 
 * If this is being used for a bulk api call a 'ping' endpoint should also be present which you can call to get the current progress. 
 * 
 * Done is called when the task finishes. 
 * 
 * Fail is called when the task returns an error code. If that error returns html we can display that with 
 * error: function(jqXHR, error, errorMessage) {
 *                           var customHeaderMessage = jqXHR.getResponseHeader('X-App-Custom-Status') || "";
 *                           // Eliminate the first three characters from the message, which contain the status code.
 *                           var clientFacingMessage = customHeaderMessage.substr(3).trim() || errorMessage;
 *                           Growl.error({
 *                               message: clientFacingMessage,
 *                               static: true
 *                           });
 *                       }
 * 
 *
 * Usage Example 1:
 *  - one long running request
 *  - progress check which long polls server to check current state
 *
 * var name      = 'Task 1';
 * var request   = $.post('/test/123', { param: 'one' });
 * var total     = 10;
 * var current   = 0
 * var callbacks = [
 *     progress: function (rowElement) {
 *         rowElement.trigger('update', { current: current++, total: total });
 *     },
 *     done: function () {
 *         alert('Your task is done!');
 *     },
 *     error: function () {
 *         alert('Your task failed!');
 *     }
 * ];
 * 
 * var task = new AsyncTask(name, request, callbacks);
 * 
 * var manager = new AsyncTaskManager('Async Task Manager');
 * manager.add(task);
 *
 * ----
 * 
 * Usage Example 2:
 *     - Individual tasks wrapped in $.when() so we know when all are done
 *     - progress check simple checks state of all individual requests
 *
 * var subrequests = [
 *     $.post('/test/123', { param: 'one' }),
 *     $.post('/test/456', { param: 'two' }),
 *     $.post('/test/789', { param: 'three' });
 * ];
 *
 * var name      = 'Task 2';
 * var request   = $.when.apply($, subrequests);
 * var callbacks = [
 *     progress: function (rowElement) {
 *         var subrequestsDone = _.filter(subrequests, { readyState: 4 });
 *         rowElement.trigger('update', { current: subrequestsDone.length, total: subrequests.length });
 *     },
 *     done: function () {
 *         alert('Your task is done!');
 *     },
 *     error: function () {
 *         alert('Your task failed!');
 *     }
 * ];
 *
 * var task2 = new AsyncTask(name, request, callbacks);
 *
 * var manager2 = new AsyncTaskManager('Async Task Manager');
 * manager.add(task2);
 */
(function() {
    'use strict';
    
    var taskManagerTmpl = 
        '<section id="async-task-manager"><header>{{name}}</header></div>';

    var taskManagerRowTmpl = 
        '<div class="async-task-row"> \
            <a class="cancel" title="Cancel" href="#">&times;</a> \
            <p><span class="name"></span> \
            <span class="initializing">Initializing</span> \
            <span class="counter">(<span class="current">0</span> of <span class="total">0</span>)</span></p> \
            <div class="task-progress-bar"><div class="task-progress"></div></div> \
        </div>';
    
    var taskManagerCancelledTmpl =
        '<span class="cancelled">Your request has been canceled.</span>';
    
    function AsyncTaskManager(name) {
        this.managerElem = $(taskManagerTmpl)
            .appendTo('body');
        this.setName(name);
    }
    
    _.extend(AsyncTaskManager.prototype, {
        add: asyncTaskManagerAdd,
        remove: asyncTaskManagerRemove,
        setName: asyncTaskManagerSetName,
    });
    
    function asyncTaskManagerAdd(task) {
        // anytime we have a task ensure manager is shown
        this.managerElem.show();
        
        // get the row template and add it to the manager
        // list for the "update" event triggered by progress update
        // update the row with the task name
        var elem = $(taskManagerRowTmpl).clone()
            .appendTo(this.managerElem)
            .on('update', _asyncTaskHandleUpdate)
            .on('click', '.cancel', _.partialRight(_asyncTaskStop, this, task))
            .find('.name')
            .html(task.name)
            .end();
        
        // start task and when complete, call done callbacks and then remove from manager
        task.start(elem)
            .then(task.callbacks.done)
            .fail(task.callbacks.error)
            .always(_.bind(asyncTaskManagerRemove, this, elem));
    }
    
    function asyncTaskManagerRemove(elem) {
        elem.remove();
        
        if (this.managerElem.find('.async-task-row').length < 1) {
            this.managerElem.hide();
        }
    }

    function asyncTaskManagerSetName(name) {
        this.managerElem.find('header').html(name);
    }

    function _asyncTaskHandleUpdate(event, data) {
        $(this)
            .find('.initializing').remove().end()
            .find('.counter').show().end()
            .find('.current').html(data.current).end()
            .find('.total').html(data.total).end()
            .find('.task-progress').css('width', (data.current / data.total) * 100 + '%');
    }
    
    function _asyncTaskStop(event, taskManager, task) {
        event.preventDefault();
        
        // get the current row, remove progress meter 
        // and replace with message saying it was cancelled
        var row = $(this).parents('.async-task-row')
            .find('.task-progress-bar').remove().end()
            .find('.cancel').remove().end()
            .append(taskManagerCancelledTmpl);
        
        // stop the task
        task.stop();
        
        // remove row after 3 seconds
        setTimeout(_.bind(asyncTaskManagerRemove, taskManager, row), 3000);
    }
    
    window.AsyncTaskManager = AsyncTaskManager;
}());